import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Footer, Header, ItemTypes } from '../../../shareable';

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: '100%'
    }
});

export default ({ config, params, getComponent, preview, Droppable, data }) => {
    const classes = useStyles();

    if (!config) return null;

    return (
        <div className={classes.root}>
            <Header 
                noSticky={preview}
                data={params}
                categories={data.categories}
                spread={true}
            />
            
            { Droppable && <Droppable type={ItemTypes.HEADER} id={'page-header'} index={-1} /> }
            
            { config.elements.map(getComponent) }

            <Footer
                data={params}
            />
        </div>
    );
};