import {TABS} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';

export default function formProductAdd(currentLang, categories, customAttributes, metaAttributes, imageAttributes, categoriesSelected, allGroups, errors, seeErrors,handleMediaPicker,variantGroup,handleToggleDrawer,variantsValidated, isVariant, rightButtonCallback, allowedTypes) {
    let typeInput = '';
    
    let attributeSetup = attribute => {
        let isEmail = false;
        const defaultLang = attribute.node.translation.translationDatas.edges[0];

        const langSelected = attribute.node.translation.translationDatas.edges.find(
            lang => lang.node.locale.code === currentLang
        );

        switch(attribute.node.attributeType.input) {
            case 'textarea':
                typeInput = 'textarea';
                break;
            case 'number': 
            case 'decimal': 
                typeInput = 'decimal';
                break;
            case 'text':
                typeInput = 'text';
                break;
            case 'mail':
                typeInput = 'text';
                isEmail = true;
                break;
            case 'link':
                typeInput = 'text';
                break;
            case 'select':
                typeInput = 'select';
                break;
            case 'image':
                typeInput = 'mediaPicker';
                break;
            case 'file':
                typeInput = 'file';
                break;
            case 'date':
                typeInput = 'date';
                break;
            default: typeInput = null;
        };
        return ({
            type: typeInput,
            label: langSelected?.node.value ?? defaultLang.node.value,
            handleMediaPicker:typeInput === 'mediaPicker' || typeInput === 'file' ? handleMediaPicker:null,
            allowedTypes: typeInput === 'file' ? allowedTypes  : null,
            translated: true,
            helper: {
                text: 'Helper non disponible pour le moment',
                link: false,
            },
            isSystem: false,
            currentLang,
            required: typeInput === "mediaPicker" && isVariant ? false : attribute.node.isRequired,
            stateName: attribute.node.identifier,
            email: isEmail,
            value: attribute.node.attributeOptions.edges.map((values) => {
                const langSelectedValuesDefault = values.node.translation.translationDatas.edges[0];

                const langSelectedValues = values.node.translation.translationDatas.edges.find(
                    lang => lang.node.locale.code === currentLang
                );

                return ({
                    value: values.node.id,
                    label: langSelectedValues?.node.value ?? langSelectedValuesDefault?.node?.value
                });
            })
        });
    };

    let generalInputs = [
        {
            type: 'select',
            label: `Groupe d'attributs`,
            helper: {
                text: variantsValidated && isVariant ? `Une variante produit est déjà utlisée vous ne pouvez changer de groupe d'attributs` : `Indiquez le groupe d'attributs`,
                link: false,
            },
            translated: false,
            required: true,
            disabled: variantsValidated && isVariant ? true : false,
            stateName: 'groupAttribut',
            value: allGroups.map(group => {
                const langSelectedValuesDefault = group.node.translation.translationDatas.edges[0];

                const langSelectedValues = group.node.translation.translationDatas.edges.find(
                    lang  => lang.node.locale.code === currentLang
                );

                return ({
                    value: group.node.id,
                    label: langSelectedValues?.node.value ?? langSelectedValuesDefault?.node.value ?? group.node.identifier
                });
            })
        },
        {
            type: 'switch',
            label: 'Groupe d\'attributs configurable',
            helper: {
                label: 'État',
                text: 'Indiquez si le groupe d\'attributs est configurable',
                link: false,
            },
            // required: true,
            stateName: 'isVariant',
            value: [
                {
                    value: true,
                    label: 'Oui',
                },
                {
                    value: false,
                    label: 'Non',
                },
            ]
        },
        {
            type: 'text',
            label: 'Sku',
            helper: {
                text: 'Numéro sku du produit',
                link: false,
            },
            
            required: true,
            stateName: 'sku',
            disabled: false,
        },
        {
            type: 'text',
            label: 'EAN',
            helper: {
                text: 'Code EAN',
                link: false,
            },
            rightButton: {
                textTooltip: 'Cliquer pour utiliser l\'autocomplétion',
                textTooltipDisabled: 'Entrer un code EAN',
                icon: 'icon-search',
                callback: rightButtonCallback
            },
            translated: true,
            required: true,
            stateName: 'product_ean',
            disabled: false,
        },
        {
            type: 'decimal',
            label: 'Prix',
            helper: {
                text: 'Prix du produit',
                link: false,
            },
            translated: true,
            required: isVariant ? false : true,
            stateName: 'product_price',
            disabled: false,
        },
        {
            type: 'text',
            label: 'Titre du produit',
            helper: {
                text: 'Indiquez le nom du produit ici',
                link: false,
            },
            translated: true,
            required: true,
            stateName: 'product_name',
        },
        {
            type: 'selectTree',
            label: 'Catégories',
            translated: false,
            helper: {
                text: 'Modifier les catégories du produit',
                link: false,
            },
            required: true,
            stateName: 'categories',
            multiselect: true,
            data: (() => {
                let data = categories.filter(e => e.parent === null && e.catalog === null && e.libelle === 'Root');
                let populateChildren = (cats, parent) => {

                    let catSelected = categoriesSelected.find(e => e.id === parent.id);
                    
                    let allNames    = parent.categoryDatas.edges.filter(e => e.node.attribute.identifier === 'category_name');

                    const defaultValue  = allNames.find(e => e.node.locale.code === currentLang);
                    const value         = allNames.find(e => e.node.locale.code === currentLang);

                    let finalValue = value?.node.value ?? defaultValue?.node.value ?? parent.libelle;


                    parent.value    = parent.id;
                    parent.label    = finalValue;
                    parent.checked  = catSelected ? true : false;

                    parent.expanded = true;
                    parent.children = cats.filter(e => e.parent !== null && e.parent.id === parent.id);

                    for (let child of parent.children)
                        populateChildren(cats, child);
                };

                for (let parent of data)
                    populateChildren(categories, parent);

                return data;
            })()
        },
        {
            type: 'textarea',
            label: 'Description',
            helper: {
                text: 'Décrivez votre produit',
                link: false,
            },
            translated: true,
            required: false,
            stateName: 'product_description',
        },
        {
            type: 'buttonGroup',
            label: 'État',
            helper: {
                label: 'État',
                text: 'Indiquez l\'état',
                link: false,
            },
            required: false,
            stateName: 'status',
            value: [
                {
                    value: true,
                    label: 'Actif',
                },
                {
                    value: false,
                    label: 'Inactif',
                },
            ]
        }
    ];

    let imageInputs     = imageAttributes.map(attributeSetup);
    let metaInputs      = metaAttributes.map(attributeSetup);
    let customInputs    = customAttributes.filter(e => e.node.attributeType.input !== 'image' && e.node.status).map(attributeSetup);

    let variantProduct    = [
        {
            type: 'variantAdd',
            required: isVariant ? true : false,
            stateName: 'variantsValidated',
            disabled: false,
            handleClick: handleToggleDrawer,
        }
    ]

    let hasErrors = (inputs) => {
        for (let input of inputs) {
            if (errors[input.stateName])
                return true;
        }

        return false;
    };

    let children = [
        {
            labelName: 'Général',
            error: hasErrors(generalInputs),
            optionsInputs: generalInputs
        },
        {
            labelName: 'Médias',
            isOptionnal: false,
            error: hasErrors(imageInputs),
            optionsInputs: imageInputs
        }
    ];

    if(metaInputs.length){
        children.push({
            labelName: 'Métas',
            isOptionnal: false,
            error: hasErrors(metaInputs),
            optionsInputs: metaInputs
        });
    }
    if(customInputs.length){
        children.push({
            labelName: 'Attributs',
            isOptionnal: false,
            error: hasErrors(customInputs),
            optionsInputs: customInputs
        })
    }

    children.push(
        {
            labelName: 'Variante Produit',
            isOptionnal: false,
            disabled: !variantGroup,
            error: hasErrors(variantProduct),
            optionsInputs: variantProduct
        });

    var obj = {
        titleForm: 'Ajouter un produit',
        subTitleForm: 'Veuillez compléter les champs ci-dessous pour créer votre produit',
        langSelect: true,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: TABS,
            seeErrors,
            children
        }
    };

    return obj;
}