import React from 'react';
import Tab from '@material-ui/core/Tab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import styled from 'styled-components';

import colors from '../../../../../config/theme/colors';
import _ from 'lodash';


const TabCustom = styled(Tab)`
    min-width: inherit;
    padding: 6px 18px;
    // padding-bottom: 30px;
    // margin-bottom: -24px;
    min-height: 72px;
`;

const SpanColor = styled.span`
    color: ${colors.blue.lighter.hue300};
`

export default function TabContainer(props) {
    let getTotal = 0;
    let skuListTotal = [];
    const getProductNb = (categorie, prevTotal) => {
        for (let cat of categorie.productSkusArray){
            if (skuListTotal.includes(cat)){
            }else{
                skuListTotal.push(cat)
            }
        }
        if (categorie.childrens?.length > 0 ){
            for(let child of categorie.childrens){
                getProductNb(child, getTotal)
            }
        }
        getTotal = skuListTotal.length + (prevTotal ? prevTotal : 0);
        let finalValue      = `${getTotal}`;
        return finalValue;
    }

    let catName = props.categorie.categoryDatas?.filter(e => e.attribute.identifier === "category_name");
    catName = catName?.find(e => e.locale.code === props.currentLang)?.value;
    return (
        <TabCustom
            key={`TabContainer-Cat${props.categorie.libelle}-${props.index}`}
            onMouseEnter = { 
                !props.isDiffusion ? 
                    props.categorie.childrens?.length ? () => props.handleOpen(props.categorie, props.index + 1) : null
                : 
                    props.categorie.node.assetTypes.edges?.length > 0 ? () => props.handleOpen(props.categorie.node, props.index + 1) : null
            }
            onMouseLeave = { 
                !props.isDiffusion ? 
                    props.categorie.childrens?.length > 0 ? props.handleClose : null 
                : 
                    props.categorie.node.assetTypes.edges?.length > 0 ? props.handleClose : null 
            }
            onClick={
                (event) => { setTimeout(() => {
                    if (props.isDiffusion){
                        props.handleChangeTab(event, 1, props.categorie.node.id, props.categorie.node, props.handleBreadCrumb(props.categorie.node))
                    }else{
                        props.handleChangeTab(event, 1, props.categorie.id, props.categorie, props.handleBreadCrumb(props.categorie))
                    }
                    props.handleChange(null, props.index + 1)
                },50);
            }}
            value={props.isDiffusion ? props.categorie.node.id : props.categorie.id}
            label={[
                props.isDiffusion ? 
                    props.categorie.node.assetTypes.edges.length > 0
                    ? (
                        <React.Fragment key={`TabContainer-Cat${props.isDiffusion ? props.categorie.node.libelle : props.categorie.libelle}-${props.index}`}>{catName ? catName : props.isDiffusion ? props.categorie.node.libelle : props.categorie.libelle}&nbsp;{props.isDiffusion ? null : <SpanColor>({props.typeContent ? props.categorie.contentsTotal : props.isMedia ? props.categorie.mediasTotal : getProductNb(props.categorie)})</SpanColor>}<ExpandMoreIcon /></React.Fragment>
                    ) 
                    : catName ? <React.Fragment key={`TabContainer-Cat${props.isDiffusion ? props.categorie.node.libelle : props.categorie.libelle}-${props.index}`}>{catName}&nbsp;{props.isDiffusion ? null : <SpanColor>({props.typeContent ? props.categorie.contentsTotal : props.isMedia ? props.categorie.mediasTotal : getProductNb(props.categorie)})</SpanColor>}</React.Fragment> : <React.Fragment key={`TabContainer-Cat${props.isDiffusion ? props.categorie.node.libelle : props.categorie.libelle}-${props.index}`}>{props.isDiffusion ? props.categorie.node.libelle : props.categorie.libelle}&nbsp;{props.isDiffusion ? null : <SpanColor>({props.typeContent ? props.categorie.contentsTotal : props.isMedia ? props.categorie.mediasTotal : getProductNb(props.categorie)})</SpanColor>}</React.Fragment>
                : props.categorie.childrens.length > 0 
                ? (
                    <React.Fragment key={`TabContainer-Cat${props.isDiffusion ? props.categorie.node.libelle : props.categorie.libelle}-${props.index}`}>{catName ? catName : props.isDiffusion ? props.categorie.node.libelle : props.categorie.libelle}&nbsp;{props.isDiffusion ? null : <SpanColor>({props.typeContent ? props.categorie.contentsTotal : props.isMedia ? props.categorie.mediasTotal : getProductNb(props.categorie)})</SpanColor>}<ExpandMoreIcon /></React.Fragment>
                ) 
                : catName ? <React.Fragment key={`TabContainer-Cat${props.isDiffusion ? props.categorie.node.libelle : props.categorie.libelle}-${props.index}`}>{catName}&nbsp;{props.isDiffusion ? null : <SpanColor>({props.typeContent ? props.categorie.contentsTotal : props.isMedia ? props.categorie.mediasTotal : getProductNb(props.categorie)})</SpanColor>}</React.Fragment> : <React.Fragment key={`TabContainer-Cat${props.isDiffusion ? props.categorie.node.libelle : props.categorie.libelle}-${props.index}`}>{props.isDiffusion ? props.categorie.node.libelle : props.categorie.libelle}&nbsp;{props.isDiffusion ? null : <SpanColor>({props.typeContent ? props.categorie.contentsTotal : props.isMedia ? props.categorie.mediasTotal : getProductNb(props.categorie)})</SpanColor>}</React.Fragment>]} 
        /> 
    );
}