import React, { useState } from 'react';
import styled from 'styled-components';

import { Box } from '@material-ui/core';

import Typography from '../../../ui/typography/Typography';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../../config/theme/colors';
import videoLogo from '../../../../assets/pictos/video.png';
import pngBackground from '../../../../assets/images/medias/pixelBackground.jpg';
import Chip from '@material-ui/core/Chip';
import DialogModal from '../../../ui/dialog/DialogModal';
import MediaModal from '../../../screens/dashboard/medias/components/MediaModal'

import axios from '../../../../js/utils/axios';
import { ALERT_ERROR } from '../../../../js/constants/alert-types';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { getFileConfig } from '../../../../js/helpers/files';
import { connect } from "react-redux";
import { DOCUMENTS, IMAGES, CSV } from "../../../../js/constants/medias-types";


const BigImage=styled(Box)`
    cursor:pointer;
    height:230px;
    position:relative;
    .bigImageContainer{
        overflow: hidden;
        // border : 1px #EAECEE solid;
        text-align:center;
        height:70%;
        position: relative;
        background-size:100%;
        background-position:center;
        background-repeat:no-repeat; 
        background-image: url(${props => props.imagebackground}), url(${props => props.typeimage === "PNG" ? pngBackground : ''});
        img{
            max-width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .bigImageTitle{
        // border : 1px #EAECEE solid;
        // padding:0px 20px;
        width:100%;
        display:flex;
        align-items:center;
        border-top:none;
        font-style:italic;
        // background : transparent linear-gradient(135deg, #425E7C 0%, #15181C 100%) 0% 0% no-repeat padding-box;
        // background-size:0 100%;
        // transition: background-size .5s;
        // will-change:background-size;
        height:30%;
    }
    .editicon{
        @media screen and (max-width: 850px){
            display: flex !important;
            z-index: 99;
        }
    }
    &:hover{
        .bigImageTitle{
            // background-size:100% 100%;   
        }
        h3{
            // color:white !important; 
        }
        .editicon{
            display:flex;
            z-index: 99;
        }
        
    }
`;
const Title=styled(Typography)`
    transition :color .5s;
    width:100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow:ellipsis;
    font-size:13px;
    text-align:left;
`;

const useStyles = makeStyles((theme) => ({
    editIcon:{
        backgroundColor : colors.blue.regular,
        position : 'absolute',
        width:25,
        height:25,
        color:colors.white,
        borderRadius:'50%',
        display :'flex',
        alignItems : 'center',
        justifyContent : 'center',
        fontSize:15,
        border : '2px solid white',
        boxShadow : '0px 0px 11px 2px rgba(0,0,0,0.15)',
        top:10,
        right:10,
        transform:'scale(1)',
        transition:'all .5s',
        display: 'none',
        '&:hover':{
            transform:'scale(1.1)',
        }
    },
}));

function getMediaType(type){
    var res=type.match(/\.[0-9a-z]+$/i)[0].replace('.', '').toUpperCase()
    return res;
}


function CardMedia(props){
    const [ openModal, setOpenModal ]       = useState(false);
    const [ openDeleteModal, setOpenDeleteModal ]       = useState(false);
    const [ modalData, setModalData ]       = useState(null);
    const [ toDelete, setToDelete ]       = useState(null);

    const classes   = useStyles();
    
    let media     = props.media;
    if(media.node){
        media = media.node;
    }
    let type = {};
    let isVideo = false;
    type        = {
        value: getMediaType(media.filePath),
        style: {
            background: getFileConfig(media.type).color,
            color: 'white',
            fontWeight: 'bold'
        }
    }
    if(['video/mpeg','video/webm','video/mp4'].includes(media.type)){
        isVideo=true;
    }

    //#region MODALS
    const handleCloseModal = () => {
        setOpenModal(!openModal)
    }

    const handleMediaModal = (media,index,isNav=false) =>{
        let setupModalData = {
            ...media,
            index : index
        }

        if(!isNav){
            setOpenModal(!openModal)
        }
        setModalData(setupModalData)
    }

    // handleNextMedia = (step,index) => {
    //     let next;
        
    //     switch(step){
    //         case 'next':
    //             next = index +1;
    //             break;

    //         case 'prev':
    //             next = index-1;
    //             break;

    //         default :
    //             next = next;
    //             break;
    //     }
        
        
    //     let media = this.state.medias[next];
    //     this.handleMediaModal(media,next,true);
    // }

    const handleDeleteModal =(id)=>{
        setToDelete(id);
        setOpenDeleteModal(!openDeleteModal);
        
    }

    const closeAllModal = () =>{
        setOpenModal(false);
        setOpenDeleteModal(false);
    }
    //#endregion

    const deleteMedia = async(action)=>{
        switch(action){
            case 'delete':
                await axios(`${process.env.REACT_APP_API}/media-objects/${toDelete.replace('/api/media-objects/', '')}`, 'delete');
                   
                let currentList = props.allMedias;
                let newList     = currentList.filter(media=> props.isDashboard ? media.id !== toDelete : media.node.id !== toDelete)
                
                props.callback(newList);
                props.snack(ALERT_ERROR,'Média supprimé');

                closeAllModal(); 

                /*Reload en arrière plan de la liste des médias*/
                // this.handleGetAllMedias(true);
                // props.callback(newList);
                
                break;

            case 'cancel':
                // this.setState({
                //     openDeleteModal : !this.state.openDeleteModal

                // })    
                setOpenDeleteModal(!openDeleteModal);
            default:
                return null;
        }
        setToDelete(null);
    }


    return (
        <>
            <BigImage 
                onClick={() => handleMediaModal(media, props.currentIndex)} 
                className={classes.cardMedia} 
                typeimage={type.value}
                // imagebackground={isVideo==true ? videoLogo : process.env.REACT_APP_API_ROOT+'/medias/'+media.filePath }
            >
                {
                    props.handleEdit ? (
                        <Box onClick={(event)=>{props.handleEdit(media);event.stopPropagation()}} className={`${classes.editIcon} editicon`}>
                            <EditIcon fontSize='inherit'/>
                        </Box>
                    ) : null
                }
                <Box className="bigImageContainer">
                    <img src={isVideo === true ? videoLogo : getFileConfig(media.type).image ? getFileConfig(media.type).image : process.env.REACT_APP_API_ROOT+'/medias/'+media.filePath } style={{height: getFileConfig(media.type).image ? '100%' : ''}}/>
                </Box>
                <Box className="bigImageTitle">
                    <Title variant="h3" title={media.name ? media.name : media.filePath}>
                        {media.name ? media.name : media.filePath}
                    </Title>                    
                    <Chip label={type.value} style={type.style}/>                  
                </Box>
            </BigImage> 
            {modalData         
            ? 
                <MediaModal
                    open                = {openModal}
                    onClose             = {handleCloseModal}
                    modalData           = {modalData}
                    // handleNav           = {handleNextMedia}
                    // lastMedia           = {this.state.medias?.length-1}
                    handleDeleteModal   = {handleDeleteModal}
                    readOnly            = {props.readOnly}
                    windowWidth         = {props.windowWidth}
                />

            : null}
            <DialogModal
                open            = {openDeleteModal}
                secondaryAction = {()=>{deleteMedia('cancel')}}
                primaryAction   = {()=>{deleteMedia('delete')}}
                title           = 'Supprimer ce média' 
            >
                <Typography variant="body2">Êtes-vous sûr de vouloir supprimer ce média ? <strong>Cette action est irréversible</strong></Typography>
            </DialogModal>
        </>           
    )
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardMedia);