import React from 'react';
import { withRouter } from 'react-router';
import { withApollo } from 'react-apollo';
import { connect } from "react-redux";
import { Grid } from '@material-ui/core';
import * as moment from 'moment';
import styled from 'styled-components';
import colors from '../../../../config/theme/colors';
import { eventService } from '../../../../js/services/event.service';
import request from '../../../../js/utils/fetch';
import slugify from 'slugify';

import { hasRights } from '../../../../js/utils/rights';
import { CATALOGS, CATALOGS_CATALOGS, VIEW, CREATE } from '../../../../js/constants/constant-rights';
import { ROUTE_HOME, ROUTE_CATALOGS_DETAIL } from '../../../../js/constants/route-names';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { GET_CATALOGS_PAGINATION, GET_CATALOG_TYPES, ADD_CATALOG, GET_CATALOGS_CURSOR } from '../../../../queries/catalogs';

import CardCustom from '../../../layouts/Card/CardCustom';
import CardCatalog from '../../../layouts/Card/cardContent/CardCatalog';
import TabCatalogs from './components/TabCatalogs';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import EmptyCard from "../../../ui/empty-card/EmptyCard";
import EmptyCatalogue from '../../../../assets/pictos/empty-picto/empty_catalogs.png';
import PageLoader from '../../../ui/loadings/page-loader/PageLoader';

import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import catalogConfig from './config/catalogs.config';
import catalogAdd from './config/catalogAdd.config';

import pictoPermanent from '../../../../assets/pictos/icon-ajout-cata-permanent.svg';
import pictoEphemere from '../../../../assets/pictos/icon-ajout-cata-ephemere.svg';
import pictoThematique from '../../../../assets/pictos/icon-ajout-cata-thematique.svg';

import * as Pagination from '../../../../js/utils/pagination';


const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
`;
const ContainerCatalogs = styled(Grid)`
    margin-top: 1.5rem;
`;

class Catalogs extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isEmpty : false,
            openForm : false,
            openFormAdd : false,
            catalogFormData : {},
            allCatalogs : [],
            imageCatalog : null,
            catalogId : '',
            catalogName : '',
            catalogIdentifier : '',
            catalogDescription  : '',
            catalogIsActive : true,
            catalogAutosync : true,
            allCatalogsTypes : [],
            typeOfCatalogs : [],
            drawerWidthModified : props.drawerWidth,
            libelle:'',
            currentLang: props.locales[0].node.code,
            errors: {},
            seeErrors: false,
            ready: false,
            catalogStartAt: "2020-08-01",
            catalogEndAt: "2020-08-05",
            noResult: false,
            pagination: {
                page: 0,
                perPage: 6,
                count: 0,
            },
            skulist: [],
            searchValue: '',
            arrayCatFilter: [],
            typeTesting: {
                type: 'catalog',
                testingState: ['catalogName', 'catalogIdentifier'],
                testingTypingState: 'catalogName',
                identifierState: 'catalogIdentifier'
            },
        };
        this.typingTimer = null;
        this.typingSearchTimer = null;
    }

    doneTyping = (stateName) => {
        let typeTesting = this.state.typeTesting;
        if(stateName === typeTesting.testingTypingState){
            this.setState({
                [typeTesting.identifierState]: slugify(this.state[typeTesting.testingTypingState], {replacement :'_', lower: true, remove: /[^\w\-\s]+/g}),
            });
        }
        
        if(this.state[typeTesting.identifierState]){
            request(`${process.env.REACT_APP_API}/unique/${typeTesting.type}/${this.state[typeTesting.identifierState]}`, 'get').then(
                (data) => {
                    if(data.success){
                        eventService.fire({stateName: typeTesting.identifierState, errorMessage: 'Cet identifiant est déjà utilisé et n\'est donc pas valide.'});
                    }
                }
            );
        }
        this.forceUpdate();
    };

    checkIdentifier = (stateName) => {
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(() => {this.doneTyping(stateName)}, 500);
    };
    
    setValue = (stateName, value, translated) => {
        if (translated) {
            let values = this.state[this.state.currentLang];

            if (!values) {
                values = {};
            }

            values[stateName] = value;

            this.setState({
                [this.state.currentLang]: values,
            });
        } else {
            this.setState({
                [stateName]: value,
            });
        }
        if(this.state.typeTesting.testingState.includes(stateName))
            this.checkIdentifier(stateName);
    };

    handleLang = (event) => {
        this.setState({ currentLang: event.target.value }, () => {
            eventService.fire();
        });
    };

    handleButtonGroupChange = (stateName, value) => {
        this.setState({ [stateName]: value });
    };

    handleSearch = () => {
        this.props.startLoading();
        this.handleGetCatalogs();
    };

    handleChange(e, value) {
        if(e?.target?.value){
            clearTimeout(this.typingSearchTimer);
            this.setState({searchValue: e.target.value});
            this.typingSearchTimer = setTimeout(() => {
                this.handleSearch();
            }, 500);
        } 
        else if(value){
            this.handleSearch();
        }
        else {
            this.setState({
                searchValue: ''
            }, () => {
                if(this.state.activeCategorie.length <= 1){
                    this.handleGetCatalogs();
                }
                else{
                    this.handleChangeTab(null,null,this.state.idActiveCat);
                }
            });
        }
    };

    handleChangeTab = async (event, newValue, id, libelle, category) => {
        this.props.startLoading();
        let pagination = await Pagination.resetPagination(this.state.pagination.perPage);
        this.setState({pagination})
        if(id) {
            this.setState({
                idActiveCat: id,
                arrayCatFilter: [id],
            }, () => {
                this.handleGetCatalogs();
            });
        } else {
            this.setState({
                arrayCatFilter: [],
                activeCategorie: ['Tout voir'],
            }, () => {
                this.handleChange(null, true);
            });
        }
    };

    resetStateCatalog = () => {
        this.setState({
            imageCatalog : null,
            catalogName: '',
            catalogIdentifier: '',
            catalogDescription: '',
            catalogStartAt: '',
            catalogEndAt: '',
            catalogIsActive: true,
            catalogAutosync: true,
            secondTreeData: [],
            errors: {},
            seeErrors: false,
        });
    };

    handleInputChange = (stateName, evt, custom, translated ) => {
        if(custom){
            this.setState({...custom})
        }
        else{
            const value = evt?.target?.value ?? evt;
            this.setValue(stateName, value, translated);
        }
    };

    handleMediaPicker=(selected,stateName)=>{
        if(stateName === 'imageCatalog'){
            this.handleInputChange(stateName,selected,null,false);  
        }
        else{
            this.handleInputChange(stateName,selected,null,true);  
        }
    }

    handleToggleDrawer = (stateDrawer, changeWidth) => {
        this.setState({ 
            [stateDrawer]: !this.state[stateDrawer]
        });

        if (changeWidth) {
            this.handleDrawerWidthChange(300);
        } 
    };
    
    handleDrawerWidthChange = (width) => {
        this.setState({ drawerWidthModified: width });
    };

    handleFormAdd = (type, description, title) => {
        this.setState({
            catalogFormData : {
                type : type,
                description : description,
                title : title,
            }
        }, () => {
            this.resetStateCatalog();
            this.handleToggleDrawer('openFormAdd', false);
        });
    };

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;
        errors[stateName] = error;
        this.setState({ errors });
    };

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }
        return false;
    };

    handlerMutation = async () => {
        this.props.startLoading();

        let startDateConvert    = moment(this.state.catalogStartAt).format();
        let endDateConvert      = moment(this.state.catalogEndAt).format();

        let variables = {
            "identifier": this.state.catalogIdentifier,
            "libelle": this.state.catalogName,
            "description": this.state.catalogDescription ? this.state.catalogDescription : null,
            "type": this.state.catalogFormData.type.node.id,
            "status": this.state.catalogIsActive, 
            "media": (this.state.imageCatalog?.id?? null) ? this.state.imageCatalog.id : null, 
            "autosync": this.state.catalogAutosync,
            "startAt": this.state.catalogFormData.type.node.identifier === "permanent" ? moment().format('YYYY-MM-DD') : startDateConvert,
            "endAt": this.state.catalogFormData.type.node.identifier === "permanent" ? moment().format('YYYY-MM-DD') : endDateConvert,
        };
        this.props.client.mutate({
            mutation: ADD_CATALOG,
            variables,
        }).then(result => {
            this.props.snack(ALERT_SUCCESS, 'Catalogue ajouté avec succès');
            this.props.stopLoading();
            if(result.data.createCatalog.catalog.identifier){
                this.goToCatalog(ROUTE_CATALOGS_DETAIL.replace(':id', result.data.createCatalog.catalog.identifier), result.data.createCatalog.catalog.id)
            }
        });
    };

    handleGetCatalogs = async () => {
        this.props.startLoading();
        let paginationVars = await Pagination.paginationQueryData(this.state.pagination);

        let variables = { ...paginationVars };

        if(this.state.arrayCatFilter.length > 0){
            variables.typeList = this.state.arrayCatFilter;
        }
        if(this.state.searchValue !== ''){
            variables.libelle = this.state.searchValue;
        }

        this.props.client.query({
            query: GET_CATALOGS_PAGINATION,
            variables,
            fetchPolicy: 'no-cache'
        }).then( async result =>{
            let pagination = await Pagination.updatePaginationData(this.state.pagination, result.data.catalogs);
            this.setState({
                pagination,
                listCatalogs: result.data.catalogs.edges,
                noResult: result.data.catalogs.edges.length > 0 ? false : true,
                ready: true,
            })
            this.props.stopLoading();
        });
    };
    
    changePage = async(newPage)=>{
        let pagination = await Pagination.changePage(this.state.pagination,newPage);
        this.setState({pagination},()=>this.handleGetCatalogs());   
    };
    changePerPage = async (perPage) => {
        let pagination = await Pagination.updatePerPage(this.state.pagination,perPage);
        this.setState({pagination}, () => { this.handleGetCatalogs(true) });
    };

    componentDidMount() {
        const getRights = hasRights(CATALOGS, CATALOGS_CATALOGS, VIEW)
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }
        this.handleGetCatalogs();
    };
    
    componentDidUpdate(prevProps, prevState) {
        if(prevState.ready !== this.state.ready){
            this.initCatalogsTypes();
        }
    }

    render() {
        return (
            <div style={{width: this.state.openForm ? `calc(100% - ((50% - ${this.props.drawerWidth}px / 2) + (${this.props.drawerWidth}px / 2)))` : "100%", transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms', paddingBottom: 32}}>
                <TopPanel 
                    icomoon="icon-catalogue" 
                    colorIcomoon={colors.blue.lighter.hue300}
                    title={process.env.REACT_APP_MODE_SPREAD !== "hub" ? "Gérer mes catalogues" : "Gérer mes entrepôts"}
                    subtitle={process.env.REACT_APP_MODE_SPREAD !== "hub" ? "Gestion de vos catalogues produits (création / modification / suppression)" : "Gestion de vos entrepôts produits (création / modification / suppression)"}
                    handlerAdd={process.env.REACT_APP_MODE_SPREAD !== "hub" ? () => this.handleToggleDrawer('openForm') : () => this.handleFormAdd(this.state.allCatalogsTypes.find(e => e.node.identifier === 'permanent'), this.state.typeOfCatalogs.catalogDescription, 'permanent')} 
                    textAdd={process.env.REACT_APP_MODE_SPREAD !== "hub" ? hasRights(CATALOGS, CATALOGS_CATALOGS, CREATE) ? "Créer un catalogue" : null : null}
                    searchHandler={
                        ((this.state.listCatalogs?.length > 0 && this.state.ready) || this.state.searchValue || this.state.arrayCatFilter.length > 0) ? (e, value) => {this.handleChange(e, value)} : null
                    } 
                    gradientColor1={colors.menu.regular} 
                    gradientColor2={colors.menu.darker} 
                    openForm={this.state.openForm}
                    buttonAvailable={this.state.ready}
                    windowWidth={this.props.windowWidth}
                    hasBorder={((this.state.listCatalogs?.length > 0 && this.state.ready) || this.state.searchValue || this.state.arrayCatFilter.length > 0) && process.env.REACT_APP_MODE_SPREAD !== "hub" ? false : true}
                />
                {
                    ((this.state.listCatalogs?.length > 0 && this.state.ready) || this.state.searchValue || this.state.arrayCatFilter.length > 0) && process.env.REACT_APP_MODE_SPREAD !== "hub" ? 
                        (<TabCatalogs catalogs={this.state.allCatalogsTypes} rootid={this.state.rootid} handleChangeTab={this.handleChangeTab} />) 
                    : 
                        null
                }
                <ContainerCatalogs container direction="column" justify="center" spacing={0}>
                    <Grid container direction="row" spacing={2}>
                        {this.state.isEmpty ? 
                            <EmptyCard 
                                title={process.env.REACT_APP_MODE_SPREAD !== "hub" ? "Vous n'avez pas encore configuré de Catalogues" : "Vous n'avez pas encore configuré d'Entrepôts"} 
                                subtitle={"Cliquez sur le bouton ci-dessous pour en ajouter un"} 
                                textButton={process.env.REACT_APP_MODE_SPREAD !== "hub" ? "Créer un catalogue" : "Créer un entrepôt"} 
                                picto={EmptyCatalogue} 
                                onClick={process.env.REACT_APP_MODE_SPREAD !== "hub" ? () => this.handleToggleDrawer('openForm') : () => this.handleFormAdd(this.state.allCatalogsTypes.find(e => e.node.identifier === 'permanent'), this.state.typeOfCatalogs.catalogDescription, 'permanent')}
                                openForm={this.state.openForm}
                            />
                        :
                        this.state.listCatalogs?.length > 0 ?
                            this.state.listCatalogs.map((type, index) =>
                                <GridCustom item lg={this.props.windowWidth > 1500 ? (this.state.openForm ? 6 : 4) : (this.state.openForm ? 12 : 6)} md={this.state.openForm ? 12 : 6} xs={12} key={`ListCatalogs${index}`}>
                                    <CardCustom paddingbottom={0} paddingtop={0} style={{width: "100%"}} contentpadding={'0 0px 10px 0px'} >
                                        <CardCatalog data={type} textButton={process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'Voir le catalogue' : "Voir l'entrepôt"} openForm={this.state.openForm} windowWidth={this.props.windowWidth}/>
                                    </CardCustom>
                                </GridCustom>
                            )
                        : 
                        !this.state.ready ? 
                            (<PageLoader />)
                        :
                        this.state.noResult ?
                            (<EmptyCard title={"Aucun résultat pour cette recherche"} textButton={process.env.REACT_APP_MODE_SPREAD !== "hub" ? hasRights(CATALOGS, CATALOGS_CATALOGS, CREATE) ? "Ajouter un catalogue" : null : "Ajouter un entrepôt"} onClick={process.env.REACT_APP_MODE_SPREAD !== "hub" ? () => this.handleToggleDrawer('openForm') : () => this.handleFormAdd(this.state.allCatalogsTypes.find(e => e.node.identifier === 'permanent'), this.state.typeOfCatalogs.catalogDescription, 'permanent')} picto={EmptyCatalogue} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />)
                        : 
                            (<EmptyCard title={process.env.REACT_APP_MODE_SPREAD !== "hub" ? hasRights(CATALOGS, CATALOGS_CATALOGS, CREATE) ? "Vous n'avez pas encore configuré de catalogue" : "Vous n'avez pas les droits pour créer un catalogue" : "Vous n'avez pas encore configuré d'entrepôt"} subtitle={hasRights(CATALOGS, CATALOGS_CATALOGS, CREATE) ? "Cliquez sur le bouton ci-dessous pour en ajouter un" : "Faite une demande auprès d'un administrateur"} textButton={process.env.REACT_APP_MODE_SPREAD !== "hub" ? hasRights(CATALOGS, CATALOGS_CATALOGS, CREATE) ? "Ajouter un catalogue" : null : "Ajouter un entrepôt"} onClick={process.env.REACT_APP_MODE_SPREAD !== "hub" ? () => this.handleToggleDrawer('openForm') : () => this.handleFormAdd(this.state.allCatalogsTypes.find(e => e.node.identifier === 'permanent'), this.state.typeOfCatalogs.catalogDescription, 'permanent')} picto={EmptyCatalogue} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />)
                        }
                    </Grid>
                    <Pagination.CursorPagination
                        label = 'Catalogues par page'
                        pagination = {this.state.pagination}
                        type = "table"
                        changePageCallback = {this.changePage}
                        perPageOptions = {[6,18,30]}
                        rowLabel="Catalogues par page :"
                        changePerPageCallback={this.changePerPage}
                    />
                </ContainerCatalogs>
                <LayoutBuilder 
                    opened={this.state.openForm} 
                    forClose={() => this.handleToggleDrawer('openForm')} 
                    handlerSetup={this.handlerSetup} 
                    dataLayout={catalogConfig} 
                    dataCard={this.state.typeOfCatalogs} 
                    drawerWidth={this.props.drawerWidth} 
                />
                {this.state.catalogFormData.type ? 
                    <LayoutBuilder
                        handleCancel={this.resetStateCatalog}
                        opened={this.state.openFormAdd}
                        validateButton={true}
                        handlerMutation={this.handlerMutation} 
                        icomoon={
                            this.state.catalogFormData.type.node.identifier === 'ephemeral' 
                                ? 'ico-ajouter-cata-ephemere' 
                                : this.state.catalogFormData.type.node.identifier === 'permanent' 
                                    ? 'ico-ajouter-cata-permanent' 
                                    : 'icon-ico-ajouter-cata-thematique'
                        }
                        keepColorIcomoon={true}
                        dataLayout={catalogAdd(this.state.catalogFormData, this.handleMediaPicker)} 
                        allState={this.state} 
                        stateCallback={this.handleInputChange} 
                        errorCallback={this.handleFormError}
                        drawerWidth={this.state.drawerWidthModified}
                        handleButtonGroupChange={this.handleButtonGroupChange}
                        forClose={() => this.handleToggleDrawer('openFormAdd', true)} 
                        dataCard={this.state.typeOfCatalogs}
                    />
                : null
                }
            </div>
        );
    }


    initCatalogsTypes = () => {
        this.props.client.query({
            query: GET_CATALOG_TYPES,
            fetchPolicy: 'no-cache'
        }).then(result => {
            let allCatalogsTypes    = result.data.catalogTypes.edges;
            let getPermanent        = allCatalogsTypes.find(e => e.node.identifier === 'permanent');
            let getEphemere         = allCatalogsTypes.find(e => e.node.identifier === 'ephemeral');
            let getThematique       = allCatalogsTypes.find(e => e.node.identifier === 'thematic');

            this.setState({
                isEmpty: allCatalogsTypes.length === 0,
                activeCategorie: ['Tout voir'],
                allCatalogsTypes,
                typeOfCatalogs: [
                    {
                        'libelle': 'Créer un',
                        'bicoloreText': 'catalogue permanent',
                        'colorhover': colors.blue.darker.hue300,
                        'picto' : pictoPermanent,
                        'textButton' : 'Créer',
                        'description': 'Association de catégories et produits qui peuvent être présents toute l’année car n’ont pas d’interaction avec la saisonnalité', 
                        'catalogDescription' : 'Veuillez compléter les champs ci-dessous pour créer votre catalogue',
                        'onClick': () => {
                            this.handleFormAdd(getPermanent, this.state.typeOfCatalogs.catalogDescription, 'permanent')
                        },
                    },
                    {
                        'libelle': 'Créer un',
                        'bicoloreText': 'catalogue éphémère',
                        'colorhover': colors.purple.regular,
                        'picto' : pictoEphemere, 
                        'textButton' : 'Créer',
                        'description': 'Association de catégories et produits dont la durée d’existence (généralement de quelques jours, quelques semaines ou quelques mois) est stratégiquement programmée.', 
                        'catalogDescription' : 'Veuillez compléter les champs ci-dessous pour créer votre catalogue',
                        'onClick': () => {
                            this.handleFormAdd(getEphemere, this.state.typeOfCatalogs.catalogDescription, 'éphémère')
                        },
    
                    },
                    {
                        'libelle': 'Créer un',
                        'bicoloreText': 'catalogue thématique',
                        'colorhover': colors.pink.regular,
                        'picto' : pictoThematique, 
                        'textButton' : 'Créer',
                        'description': 'Choix de mise en avant d’une catégorie /produits en particulier sur un laps de temps programmé.', 
                        'catalogDescription' : 'Veuillez compléter les champs ci-dessous pour créer votre catalogue',
                        'onClick': () => {
                            this.handleFormAdd(getThematique, this.state.typeOfCatalogs.catalogDescription, 'thématique')
                        },
                    },
                ]
            });
        });
    };

    goTo = route => {
        this.props.history.push(route);
    };

    goToCatalog = (route, id) => {
        this.props.history.push({
            pathname : route,
            state: {catalogId : id}
        });
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes, 
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    };
};

export default withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(Catalogs)));