import React from "react";
import colors from '../../../colors';

const H3 = ({ data, preview, component, block, inputCallback, className }) => {
    return (
        <h3 
            className={className} 
            onClick={() => inputCallback ? inputCallback(component, block) : null}
            style={{ 
                fontSize: data.blocks[block].inputs.size?.value,
                color: data.blocks[block].inputs.color?.value,
                textAlign: data.blocks[block]?.inputs.textAlign?.value,
                backgroundColor: data.blocks[block].inputs.backgroundColor?.value,
                lineHeight: '1.2',
                border: !preview ? `1px dashed ${colors.blue.lighter.hue150}` : null
            }}
            dangerouslySetInnerHTML={{ __html: data.blocks[block].inputs.value.value }}
        />
    );
};

export default H3;