import {STEPPER} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';

export default function assetsAdd(currentLang,customAttributes,attributesSelected,defaultPrice,variantsValidated,defaultImage,updatePage,handleMediaPicker){
    let generalInputs  = [];
    let varianteInputs  = [];
    let attrSelected = [];
    generalInputs = [
        {
            type: 'selectTree',
            label: 'Sélection des attributs',
            translated: false,
            helper: {
                text: variantsValidated  ? `Une variante produit est déjà utlisée vous ne pouvez changer les attributs sélectionnés` : 'Sélectionner les attributs',
                link: false,
            },
            required: true,
            stateName: 'attributesSelected',
            multiselect: true,
            // showSelected: true,
            disabled: variantsValidated ? true : false,
            disabled: false,
            data: (() => {
                // let data = customAttributes.filter(e => e.node.attributeType.input === 'select');
                let data = customAttributes;
                let populateChildren = (cats, parent) => {
                    attrSelected = attributesSelected?.find(e => e.node.id === parent.node.id);
                    const defaultLang = parent.node.translation.translationDatas.edges[0];

                    const langSelected = parent.node.translation.translationDatas.edges.find(
                        lang => lang.node.locale.code === currentLang
                    );
                    // let allNames    = parent.node.categoryDatas.edges.filter(e => e.node.attribute.identifier === 'category_name');

                    // const defaultValue  = parent.node.translation.translationDatas.find(e => e.node.locale.code === currentLang);
                    // const value         = parent.node.translation.translationDatas.find(e => e.node.locale.code === currentLang);

                    let finalValue = langSelected?.node.value ?? defaultLang?.node.value ?? parent.node.libelle;


                    parent.value    = parent.node.id;
                    parent.label    = finalValue;
                    parent.checked  = attrSelected ? true : false;

                    parent.expanded = true;
                    // parent.children = cats.filter(e => e.node.parent !== null && e.node.parent.id === parent.node.id);

                    // for (let child of parent.children)
                    //     populateChildren(cats, child);
                    };

                for (let parent of data)
                    populateChildren(customAttributes, parent);

                return data;
            })()
        }
    ];
    varianteInputs = [
        {
            type: 'rowsVariant',
            translated: false,
            required: true,
            attributes: attributesSelected,
            defaultPrice: defaultPrice ? defaultPrice : '',
            defaultImage: defaultImage,
            stateName: 'variantsProduct',
            // variantsValidated: variantsValidated,
            currentLang,
            // handleMediaPicker: handleMediaPicker,
        }
    ]

    let steps = [];

    steps = steps.concat(
        [
            {
                labelName: 'Choix des attributs',
                isOptionnal: false,
                optionsInputs:
                    // customInputs.length ? customInputs.concat(generalInputs) : generalInputs
                    generalInputs
                    // generalInputs.concat(customInputs.length ? customInputs : []),
            },
            {
                labelName: 'Variante',
                isOptionnal: false,
                optionsInputs:
                    // customInputs.length ? customInputs.concat(generalInputs) : generalInputs
                    varianteInputs
                    // generalInputs.concat(customInputs.length ? customInputs : []),
            },

        ]
    )

    var obj=
        {
            titleForm:`Ajout de variations produit`,
            subTitleForm: 'Veuillez compléter ces étapes pour ajouter vos variations du produit',
            // langSelect: true,
            drawerType: 'swipeable',
            noForm: true,
            component: Button,
            formConfig: {
                type: STEPPER,
                finalStep: {
                    title: 'Et voilà !',
                    subtitle: 'Vos variation de produits sont configurer',
                    textButton: 'Retour a l\'ajout de mon produit'
                },
                children: steps
            },

        }    
    return obj;
}
