import Typography from '../../../../ui/typography/Typography';
import {Grid,Checkbox,List,ListItem} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import colors from '../../../../../config/theme/colors';
import {connect} from 'react-redux';
import { withStyles} from '@material-ui/core/styles';
import ButtonCustom from '../../../../ui/button/Button';
import PageLoader from "../../../../ui/loadings/page-loader/PageLoader";
import {PRODUCTS_BY_CAT_PAGINATION} from '../../../../../queries/products';
import { withApollo } from 'react-apollo';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../../js/constants/action-types';

import * as Pagination from '../../../../../js/utils/pagination';
import TreeView from '../../../../ui/tree-view/TreeView';
import notFound from '../../../../../assets/images/not-found.png';
import _ from 'lodash';


const ListCustom = styled(List)`
    padding: 2rem 2rem 2rem 0;
    &>div{
        padding: 24px;
        background: ${colors.white};
    }
`;
const ListItemCustom = styled(ListItem)`
    padding: 4px 8px 12px 8px !important;
    cursor: pointer;
`;
const SelectedProductsContainer = styled(Grid)`
    margin: 0;
`;
const CheckboxCustom = styled(Checkbox)`
    justify-content: flex-start;
    position: absolute;
    background: white;
    border-radius: 5px;
    width: 35px;
    height: 27px;
    margin-top: 10px;
    svg{
        fill: ${colors.blue.lighter.hue300}
    }
    &:hover{
        background: white!important;
        svg{
            fill: ${colors.blue.lighter.hue150}
        }
    }
    input{
        width: 40px;
    }
`;
const ContainerImage = styled(Grid)`
    min-height: 150px;
    max-height: 150px;
    overflow: hidden;
    flex-wrap: nowrap;
`;
const ImageProduct = styled.img`
    object-fit: contain;
`;
const TextProduct = styled(Typography)`
    text-align: center;
    margin-top: -10px;
    font-size: 12px;
`;
const GridCustom = styled(Grid)`
    .blockContainer{
        box-shadow: none;
        background: ${colors.grey.lighter.hue980};
        .containerTree{
            background: ${colors.white};
            &>div{
                background: ${colors.white};
            }
        }
    }
`;

const useStyles = theme => ({
    container: {
        backgroundColor: colors.grey.lighter.hue980,
        padding : 20,
        overflow:'scroll',
        marginTop:20,
        position:'relative',
    },
    
});

class ProductSelectorByCategories extends React.Component { 
    constructor(props){
        super(props)
        this.state = {
            targetCatId: this.props.allState.selectedCategorie,
            allCategories : this.props.allState.allCategories,
            currentLang : this.props.allState.currentLang,
            selectedProducts : [],
            selectAll: [],
            uniqueSelectedProducts : [],
            activeCategories:[],
            nbProductsTotal: 0,
            openSearch:false,
            results:[],
            productsToShow:[],
            pagination: {
                page: 0,
                perPage: 8,
                count: 0,
            },
            productByCategorie: {},
            selectedProductByCategorie: {},
            formatedDatas: {},
            load: true,
        }
    }

    handleFormatedDatas = () => {
        let targetCatId = parseInt(this.state.targetCatId.replace('/api/categories/', ''));
        let formatedDatas = this.state.formatedDatas;
        formatedDatas.targetCatId = targetCatId;
        formatedDatas.categories = [];
        let selectAll = this.state.selectAll;
        for (const [key, value] of Object.entries(selectAll)) {
            let element = {};
            element.idCategory = parseInt(key.replace('/api/categories/', ''));
            element.all = value;
            let idList = _.clone(this.state.selectedProductByCategorie[key]);
            let i = 0;
            if(idList){
                for(let id of idList){
                    idList[i] = parseInt(idList[i].replace('/api/products/', ''));
                    i++;
                }
            }
            element.products = idList;
            formatedDatas.categories.push(element);
        }
    }

    handlePrepareRecap = () => {
        let selectedProductByCategorie = this.state.selectedProductByCategorie;
        let recap = [];
        for (const [key, value] of Object.entries(selectedProductByCategorie)) {
            let categorie = this.state.allCategories.find(element => element.id === key);
            categorie.nbProductsSelected = this.state.selectedProductByCategorie[key].length;
            recap.push(categorie);
        }
        this.setState({recap})
    }

    changePage = async(newPage)=>{
        let pagination = await Pagination.changePage(this.state.pagination,newPage);
        this.setState({pagination, load: true}, () => this.handleGetProductsPagination());
    }

    changePerPage = async (perPage) => {
        let pagination = await Pagination.updatePerPage(this.state.pagination,perPage);
        this.setState({pagination, load: true}, () => this.handleGetProductsPagination());
    }

    handleChangeCategorie = async (id) => {
        let pagination = await Pagination.resetPagination(this.state.pagination.perPage);
        let selectAll = this.state.selectAll;
        if(typeof selectAll[id] === 'undefined'){
            selectAll[id] = false;
        }
        this.setState({
            pagination,
            selectAll,
            selectedCategorie: id,
            load: true,
        }, async () => {
            this.handleGetProductsPagination();
        }); 
    };

    handleGetProductsPagination = async () => {
        let paginationVars = await Pagination.paginationQueryData(this.state.pagination);
        let variables = {...paginationVars};
        variables.id = this.state.idCatalog;
        if(this.state.selectedCategorie){
            variables.idlist = this.state.selectedCategorie.replace('/api/categories/', '');
        }
        this.props.client.query({
            query: PRODUCTS_BY_CAT_PAGINATION,
            variables
        }).then(async result =>{
            if(result.data.categories.length > 0){
                let pagination = await Pagination.updatePaginationData(this.state.pagination, result.data.categories[0].products);
                let productByCategorie = this.state.productByCategorie;
                let selectedProductByCategorie = this.state.selectedProductByCategorie;
                if(!selectedProductByCategorie[this.state.selectedCategorie]){
                    selectedProductByCategorie[this.state.selectedCategorie] = [];
                }
                if(productByCategorie[this.state.selectedCategorie]){
                    productByCategorie[this.state.selectedCategorie] = result.data.categories[0].products.edges;
                }
                else{
                    productByCategorie = {...productByCategorie, [this.state.selectedCategorie]: result.data.categories[0].products.edges};
                }
                this.setState({productByCategorie, pagination, selectedProductByCategorie, activeCategories: result.data.categories})
            }
            this.setState({load: false})
        });
    }

    handleToggle = (value) => {
        let currentIndex  = this.state.selectedProductByCategorie[this.state.selectedCategorie].indexOf(value.node.id);
        let newChecked    = [...this.state.selectedProductByCategorie[this.state.selectedCategorie]];
        let selectedProductByCategorie = this.state.selectedProductByCategorie;
        let nbProductsTotal = this.state.nbProductsTotal;

        if (currentIndex === -1) {
            newChecked.push(value.node.id);
            if(this.state.selectAll[this.state.selectedCategorie]){
                nbProductsTotal--;
            }else{
                nbProductsTotal++;
            }
        } else {
            newChecked.splice(currentIndex, 1);
            if(this.state.selectAll[this.state.selectedCategorie]){
                nbProductsTotal++;
            }else{
                nbProductsTotal--;
            }
        }

        selectedProductByCategorie[this.state.selectedCategorie] = newChecked;

        
        this.setState({
            selectedProductByCategorie,
            nbProductsTotal,
        }, () => {
            this.handlePrepareRecap();
            this.handleFormatedDatas();
        });
    };

    handleSelectAll = () => {
        let selectAll = this.state.selectAll;
        let selectedProductByCategorie = this.state.selectedProductByCategorie;
        let nbProductsTotal = this.state.nbProductsTotal;

        if(selectAll[this.state.selectedCategorie]){
            selectAll[this.state.selectedCategorie] = !selectAll[this.state.selectedCategorie];
            nbProductsTotal += selectedProductByCategorie[this.state.selectedCategorie].length || 0;
            nbProductsTotal -= this.state.pagination.count;
            selectedProductByCategorie[this.state.selectedCategorie] = [];
        }else{
            selectAll[this.state.selectedCategorie] = true;
            nbProductsTotal -= selectedProductByCategorie[this.state.selectedCategorie].length || 0;
            nbProductsTotal += this.state.pagination.count;
            selectedProductByCategorie[this.state.selectedCategorie] = [];
        }
        this.setState({selectAll, selectedProductByCategorie, nbProductsTotal}, () => {
            this.handlePrepareRecap();
            this.handleFormatedDatas();
        });
    }


    render(){
        const { classes } = this.props;

        return(
            <Grid container spacing={4} style={{paddingBottom: 80, background: colors.grey.lighter.hue980, marginTop: 10}}>
                <Grid item xs={12} style={{position: 'fixed', bottom: 0, zIndex: '99', background: 'white', display: 'block', width: 'calc(50% + 360px)'}}>
                    <Grid container justify="flex-end">
                        <ButtonCustom icon="add" onClick={() => {this.props.stateCallback(this.state.formatedDatas)} } disabled={this.state.nbProductsTotal === 0 ? true : false}>
                            {
                                this.state.nbProductsTotal === 0 ? 
                                    'Aucun produit'
                                :   `Ajouter ${this.state.nbProductsTotal} ${this.state.nbProductsTotal > 1 ? 'produits' : 'produit'}`
                            }   
                        </ButtonCustom>
                    </Grid>
                </Grid>
                <GridCustom item xs={6}>
                    <TreeView 
                        typeOfTree={'categorieFilter'} 
                        handleChangeCategorie={this.handleChangeCategorie} 
                        reducedTree={true} 
                        dataTree={this.props.allState.treeData} 
                        expand={this.props.allProps.expandNodes}
                        stateName={"treeData"}
                        onChange={treeData => this.props.allProps.handleTreeChange('treeData', treeData)}
                    /> 
                </GridCustom>
                <Grid item xs={6}>
                    <ListCustom dense className={classes.root}>
                        <Grid container direction="row">
                            {
                                !this.state.selectedCategorie ? 
                                    <div>Veuillez choisir une catégorie</div> 
                                : this.state.productByCategorie[this.state.selectedCategorie]?.length > 0 ? 
                                    <>
                                        <Grid item xs={12} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                            {this.state.activeCategories[0].libelle}
                                            <ButtonCustom onClick={() => {this.handleSelectAll()}}>{this.state.selectAll[this.state.selectedCategorie] ? `Désélectionner tout` :  `Sélectionner tout`}</ButtonCustom>
                                        </Grid>
                                        {
                                            this.state.productByCategorie[this.state.selectedCategorie].map((value, index) => {
                                                const labelId   = `checkbox-list-secondary-label-${value}`;
                                                const datas     = value.node.productDatas?.edges;
                                                if(datas && !value.new){
                                                    let allNames    = datas.filter(e => e.node.attribute.identifier === 'product_name');
                                                    let name        = allNames.find(e => e.node.locale.code === this.props.currentLang);
                                    
                                                    let allImages   = datas.filter(e => e.node.attribute.identifier === 'product_image');
                                                    let image       = allImages.find(e => e.node.locale.code === this.props.currentLang);
        
                                                    let nameValue   = name ? name.node.value : ((allNames[0]?.node.value ?? null) || value.node.identifier);
                                                    let imageValue  = image ? image.node.media?.filePath ?? '' : allImages[0]?.node.media?.filePath ?? null;
        
                                                    return (
                                                        <Grid item xs={3} key={`ItemCheckbox${index}`}>
                                                            <ListItemCustom onClick={() => this.handleToggle(value)}>
                                                                <Grid container direction="column">
                                                                    <CheckboxCustom
                                                                        edge="start"
                                                                        checked={this.state.selectAll[this.state.selectedCategorie] ? (this.state.selectedProductByCategorie[this.state.selectedCategorie]?.indexOf(value.node.id) === -1 ? true : false) : (this.state.selectedProductByCategorie[this.state.selectedCategorie]?.indexOf(value.node.id) === -1 ? false : true)}
                                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                                    />
                                                                    <ContainerImage container justify={"center"}>
                                                                        <ImageProduct src={!imageValue ? notFound : `${process.env.REACT_APP_MEDIAS}/${imageValue}`} width="100%" alt={`Image product ${index}`} />
                                                                    </ContainerImage>
                                                                    <TextProduct variant ={'body2'}>
                                                                        {nameValue}
                                                                    </TextProduct>
                                                                </Grid>
                                                            </ListItemCustom>
                                                        </Grid>
                                                    );
                                                }
                                                else if(!datas && !value.new){
                                                    return (<div>Aucun produit pour cette catégorie</div>);
                                                }
                                            }) 
                                        }
                                    </>
                                : this.state.load ?
                                    <PageLoader /> 
                                : <div>Aucun produit pour cette catégorie</div>
                            }
                            {
                                !this.state.productByCategorie[this.state.selectedCategorie] ? 
                                    null 
                                : this.state.productByCategorie[this.state.selectedCategorie].length > 0 ? 
                                    <Pagination.CursorPagination
                                        label = 'Produits par page'
                                        pagination = {this.state.pagination}
                                        type = "table"
                                        changePageCallback = {this.changePage}
                                        perPageOptions = {[8,12]}
                                        rowLabel="Produits par page :"
                                        changePerPageCallback={this.changePerPage}
                                    />
                                : null
                            }
                        </Grid>
                    </ListCustom>
                </Grid>
                {
                    this.state.nbProductsTotal > 0 && this.state.recap ? (
                        <Grid item xs={12} style={{padding: '0 32px'}}>
                            <Typography variant="h3" style={{paddingLeft: 20}}>Récapitulatif ({this.state.nbProductsTotal}) :</Typography>
                            <SelectedProductsContainer className={classes.container} container spacing={2}>
                                <div style={{width: '100%'}}>
                                    {this.state.recap.map((value) => (
                                        this.state.selectAll[value.id] && (value.products.totalCount - value.nbProductsSelected > 0) || !this.state.selectAll[value.id] && (value.nbProductsSelected > 0) ? 
                                            (
                                                <div style={{background: colors.blue.regular, color: colors.white, margin: 8, borderRadius: 25, padding: '0 8px', display: 'inline-block', width: 'max-content'}}>
                                                    {`${value.libelle} : ${this.state.selectAll[value.id] ? (value.products.totalCount - value.nbProductsSelected) : value.nbProductsSelected}/${value.products.totalCount}`}
                                                </div>
                                            ) : null
                                    ))}
                                </div>
                            </SelectedProductsContainer>
                        </Grid>
                    ) : null 
                }
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes, 
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    };
};

export default withApollo(withStyles(useStyles)(connect(mapStateToProps,mapDispatchToProps)(ProductSelectorByCategories)));