import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactQuill from 'react-quill';

const useStyles = makeStyles((theme) => ({
  textarea: {
    backgroundColor: '#fff',
    marginBottom: theme.spacing(2),
    width: '100%',
    '& > div':{
      borderRadius: '0!important',
    }
  }
}));

export default function Textarea({ onChange, value, data }) {
  const classes   = useStyles();
  let timer       = null;

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ 'size': ['small', false, 'large', 'huge'] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['link']
    ],
  };

  const formats = [
    'bold', 'italic', 'underline', 'size', 'list', 'link'
  ];

  return (
    <ReactQuill 
      value={value}
      theme="snow"
      modules={modules}
      formats={formats}
      className={classes.textarea}
      onChange={(content, delta, source, editor) => {
          clearTimeout(timer);

          timer = setTimeout(() => {
            onChange(content);
          }, 100);
      }}
    />
  );
}
