import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";

import { Grid, Box, CircularProgress } from '@material-ui/core';
import CardCustom from '../../../layouts/Card/CardCustom';
import CardCatalog from '../../../layouts/Card/cardContent/CardCatalog';

import Typography from '../../../ui/typography/Typography';
import {ROUTE_HOME, ROUTE_CATALOGS} from '../../../../js/constants/route-names';
import { withApollo } from 'react-apollo';
import { GET_CATALOG_TYPES } from '../../../../queries/catalogs';
import styled from 'styled-components';
import request from '../../../../js/utils/fetch';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import colors from '../../../../config/theme/colors';
import IcomoonReact from "icomoon-react";
import iconSet from "../../../../assets/selection.json";
import SliderDashboard from "../../../layouts/Slider/SliderDashboard";
import Image1 from '../../../../../src/assets/images/dashboard/Visuels-Dashboard-connecteur-RS.png';
import { v4 as uuidv4 } from 'uuid';
import { hasRights } from '../../../../js/utils/rights';
import { CATALOGS, CATALOGS_DASHBOARD, CATALOGS_CATALOGS, CREATE, VIEW} from '../../../../js/constants/constant-rights';
import { ALERT_ERROR } from '../../../../js/constants/alert-types';
import { SNACK } from '../../../../js/constants/action-types';


const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
`;


class DashboardCatalogs extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            catalogs: []
        };
    }

    goTo = (route, status) => {

        this.props.history.push({
            pathname : route,
            state: {formOpen : status}
        });
    };

    componentDidMount(){
        const getRights = hasRights(CATALOGS, CATALOGS_DASHBOARD, VIEW)
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }
        this.initDashboard();
    }

    initDashboard = () => {
        this.props.client.query({
            query: GET_CATALOG_TYPES,
            fetchPolicy: 'no-cache',
        }).then(result =>{
            request(`${process.env.REACT_APP_API}/dashboards/stats/catalog`, 'get', false).then(
                (data) => {
                    if(data.success){
                        this.setState({
                            catalogs: data.datas
                        }, () => {
                            let i = 0;
                            let catalogs = this.state.catalogs;
                            for(let catalog of catalogs.activeCatalogs){
                                for(let catalogType of result.data.catalogTypes.edges){
                                    if(catalogType.node.id === catalog.type){
                                        catalogs.activeCatalogs[i].type = {id: catalogType.node.id, identifier: catalogType.node.identifier};
                                    }
                                }
                                i++;    
                            }
                            this.setState({catalogs});
                        });
                    }
                }
            );
        });
    }

    render() {
        let sliderData = [
            hasRights(CATALOGS, CATALOGS_CATALOGS, CREATE) ?
            {
                title: 'Pourquoi créer un catalogue ?',
                text: 'Un catalogue est la base pour la création d’un asset. Vous pouvez créer 3 types de catalogues : le catalogue permanent, qui pourra être présent toute l’année ; le catalogue éphémère, ou la durée d’existence est programmée (quelques jours, mois, semaines) ; le catalogue thématique, pour mettre en avant une catégorie ou des produits pour une durée programmée.',
                image: Image1,
                id: uuidv4(),
                buttons: [
                    {
                        text: 'Créer un catalogue',
                        action: () => this.goTo(ROUTE_CATALOGS, true),
                    },
                ]
            } : null,
        ];
        sliderData = sliderData.filter(function (el) {
            return el != null;
        });

        let stats = [
            {
                'icon': 'icon-catalogue',
                'data': this.state.catalogs.nbPermanentCatalogs,
                'dataColor': colors.blue.lighter.hue150,
                'subtitle': this.state.catalogs.nbPermanentCatalogs > 1 ? 'Catalogues Permanents' : 'Catalogue Permanent',
            },
            {
                'icon': 'icon-catalogue',
                'data': this.state.catalogs.nbEphemeralCatalogs,
                'dataColor': colors.purple.regular,
                'subtitle': this.state.catalogs.nbEphemeralCatalogs > 1 ? 'Catalogues Éphémères' : 'Catalogue Éphémère',
            },
            {
                'icon': 'icon-catalogue',
                'data': this.state.catalogs.nbThematicCatalogs,
                'dataColor': colors.pink.regular,
                'subtitle': this.state.catalogs.nbThematicCatalogs > 1 ? 'Catalogues Thématiques' : 'Catalogue Thématique',
            },
        ];


        let lastDatas = [
            {
                'icon': 'icon-catalogue',
                'data': this.state.catalogs.nbCatalogEnable,
                'dataColor': colors.blue.regular,
                'subtitle': 'Catalogue(s) Actif(s)',
            },
            {
                'icon': 'icon-catalogue',
                'data': this.state.catalogs.nbCatalogDisable,
                'dataColor': colors.blue.regular,
                'subtitle': 'Catalogue(s) Inactif(s)',
            },
            {
                'icon': 'icon-catalogue',
                'data': this.state.catalogs.nbCatalogWithAsset,
                'dataColor': colors.blue.regular,
                'subtitle': 'Catalogue(s) lié(s) à un asset',
            },
            {
                'icon': 'icon-catalogue',
                'data': this.state.catalogs.nbCatalogWithoutAsset,
                'dataColor': colors.blue.regular,
                'subtitle': 'Catalogue(s) sans asset',
            },
        ];

        return (
            <div style={{width: "100%"}}>
                <TopPanel 
                    icomoon="icon-dashboard" 
                    colorIcomoon={colors.blue.lighter.hue300}
                    title="Bienvenue sur le dashboard" 
                    strongText="Catalogues"
                    subtitle="Petite phrase de description OU heure de Mise à jour" 
                    gradientColor1={colors.menu.regular} 
                    gradientColor2={colors.menu.darker} 
                    openForm={this.state.openForm}
                    hasBorder={true}
                    stats={stats}
                    windowWidth={this.props.windowWidth}
                />
                <Grid container direction="column" justify="center" spacing={0} style={{marginTop: 24}}>
                    <Grid item xs={12}>
                        <Typography variant="h4" style={{fontWeight: 'bold'}}>Dernières Datas</Typography>
                        <Grid container direction="row" style={{width: '100%', margin: 0, marginTop: 8}} spacing={2}>
                            {
                                lastDatas.map((data, index) => {
                                    return(
                                        <Grid key={`LastDatas${index}`} style={{marginRight: 16, marginBottom: 16}}>
                                            <CardCustom>
                                                <Grid container justify="space-between" alignItems="center">
                                                    <Grid>
                                                        <Grid container direction="row" alignItems="center">
                                                            <Box mr={2}>
                                                                <IcomoonReact iconSet={iconSet} color="#6EAED1" size={25} icon={data.icon} />
                                                            </Box>
                                                            <Typography variant="h4" style={{fontSize: this.props.windowWidth < 1400 ? 14 : 16 }}>{data.subtitle}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Box ml={2}>
                                                        <Grid container justify="flex-end">
                                                            {
                                                                data.data || data.data === 0 ?
                                                                    <Typography variant="h2" colortext={colors.blue.lighter.hue150} style={{fontSize: this.props.windowWidth < 1400 ? 20 : 23}}>{data.data}</Typography>
                                                                : <CircularProgress size={"18px"} color={"inherit"}/>
                                                            }
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            </CardCustom>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Grid>
                    {
                        sliderData.length > 0 ?
                            <Grid item xl={sliderData.length > 1 ? 12 : 6} lg={sliderData.length > 1 ? 12 : 8} md={sliderData.length > 1 ? 12 : 8} xs={12} style={{marginTop: 12}}>
                                <Typography variant="h4" style={{fontWeight: 'bold'}}>Slider d'actions</Typography>
                                <Grid container direction="row" style={{marginTop: 8, marginBottom: 12}}>
                                    <CardCustom contentpadding="16px 0 0 0">
                                        <SliderDashboard sliderData={sliderData} windowWidth={this.props.windowWidth}/>
                                    </CardCustom>
                                </Grid>
                            </Grid>
                        : null
                    }
                    {
                        this.state.catalogs.activeCatalogs?.length > 0 ?
                            <Grid item xs={12} style={{marginTop: 20}}>
                                <Typography variant="h4" style={{fontWeight: 'bold'}}>Catalogue(s) Actif(s)</Typography>
                                <Grid container direction="row" style={{marginTop: 8}} spacing={2}>
                                    {
                                        this.state.catalogs.activeCatalogs.map((catalog, index) => {
                                            let type = {
                                                node: {
                                                    ...catalog
                                                }
                                            }
                                            return(
                                                <GridCustom item lg={4} md={6} xs={12} key={`ListFlux${index}`}>
                                                    <CardCustom paddingbottom={0} paddingtop={0} style={{width: "100%"}} contentpadding={'0 0px 10px 0px'} >
                                                        <CardCatalog 
                                                            data={type} 
                                                            textButton={'Voir le catalogue'} 
                                                            openForm={this.state.openForm} windowWidth={this.props.windowWidth}
                                                            nbProducts={this.state.catalogs.activeProductsByCatalog[catalog.id]}
                                                        />
                                                    </CardCustom>
                                                </GridCustom>
                                            )
                                        })
                                    }
                                    
                                </Grid>
                            </Grid>
                        : null
                    }
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }}),
    }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(DashboardCatalogs)));
