import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: '100%'
    }
});

export default ({ config, params, getComponent, preview, Droppable, data }) => {
    const classes = useStyles();

    if (!config) return null;

    return (
        <div className={classes.root}>
            { config.elements.map(getComponent) }
        </div>
    );
};