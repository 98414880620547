import React, { Component } from 'react';
import SortableTree from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import './TreeView.scss';

import EditRoundedIcon from '@material-ui/icons/EditRounded';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import FolderOpenRoundedIcon from '@material-ui/icons/FolderOpenRounded';
import FolderIcon from '@material-ui/icons/Folder';
import LocalOfferRoundedIcon from '@material-ui/icons/LocalOfferRounded';
import Badge from '@material-ui/core/Badge';

import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import colors from '../../../config/theme/colors';
import PageLoader from '../loadings/page-loader/PageLoader';

const BadgeCustom = styled(Badge)`
  display: block;
  margin-right: 15px; 
  &>span{
      background-color: ${props => props.bgcolor ? props.bgcolor : colors.white};
      color: ${props => props.bgcolor ? colors.white : colors.black};
  }
`;

export default class Tree extends Component {
  constructor(props) {
    super(props);
    this.state = {
        treeData: this.props.dataTree,
        selectedNodeId: this.props.selectedCategorie,
        selected: null,
    };

    this.updateTreeData = this.updateTreeData.bind(this);
    this.expandAll = this.expandAll.bind(this);
    this.collapseAll = this.collapseAll.bind(this);
  }

  nodeClicked = (event, rowInfo) => {
    if (event.target.className.includes && (event.target.className.includes('collapseButton') || event.target.className.includes('expandButton'))) {
      // ignore the event
    } else {
      this.setState({selectedNodeId: rowInfo.node.id});
    }
    if(this.props.handleChangeCategorie){
      this.props.handleChangeCategorie(rowInfo.node.id,rowInfo.node.children,rowInfo.node.parent,rowInfo.node);      
    }
  };

  updateTreeData(treeData) {
    this.setState({ treeData });
  }

  expandAll() {
    this.props.expand(true, this.props.stateName);
  }

  collapseAll() {
    this.props.expand(false, this.props.stateName);
  }

  customSearchMethod = ({ node, searchQuery }) =>
      searchQuery &&
      node?.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;

  renderSwitch(param) {
    switch(param){      
      case 'categorie':
        return(
          <div className={this.props.reducedTree ? "treeContent reducedTree": "treeContent"}>
              <SortableTree
                theme={FileExplorerTheme}
                treeData={this.props.dataTree}
                onChange={(treeData) => {
                  this.props.onChange(treeData);
                }}
                canDrag={({ node }) => this.props.canDrag !== undefined ? this.props.canDrag : !node.dragDisabled}
                canDrop={({ nextParent, node }) => {
                  return nextParent === null ? false : this.props.canDrop !== undefined ? this.props.canDrop : !nextParent || nextParent.isDirectory;
                }}
                searchQuery={this.props.searchQuery}
                searchMethod={this.customSearchMethod}
                dndType={'dndTree'}
                shouldCopyOnOutsideDrop={this.props.shouldCopy}
                generateNodeProps={rowInfo => ({
                  onClick: event => this.nodeClicked(event, rowInfo),
                  icons: rowInfo.node.isDirectory || rowInfo.node.mainCat
                    ? [
                      rowInfo.node.expanded ? 
                        <FolderOpenRoundedIcon className={"icons"}/> : <FolderIcon className={"icons"}/>,
                      ]
                    : [
                        <div>
                        </div>,
                      ],
                  buttons:
                    rowInfo.node.isDirectory ? [
                      this.props.canModify ?
                        rowInfo.node.isRoot ? null : <div //rowInfo.node.isRoot ? null : 
                          className={"treeOptions"}
                          onClick={() => this.props.editCat(rowInfo.node)}
                        >
                          <EditRoundedIcon/>
                          <Typography variant={"h5"}>Modifier</Typography>
                        </div>
                      : null,
                      this.props.canAdd ?
                        <div 
                          className={"treeOptions"}
                          onClick={() => this.props.addSubcategory(rowInfo.node)}
                        >
                          <AddCircleRoundedIcon/>
                          <Typography variant={"h5"}>Ajouter une sous-catégorie</Typography>
                        </div>
                      : null
                    ]
                    : null,
                })}
              />
          </div>
        );
      case 'selectCategorie':
        return(
          <div className={this.props.reducedTree ? "treeContent reducedTree": "treeContent"}>
              <SortableTree
                theme={FileExplorerTheme}
                treeData={this.props.dataTree}
                onChange={(treeData) => {
                  this.props.onChange(treeData);
                }}
                canDrag={false}
                canDrop={false}
                dndType={'dndTree'}
                searchQuery={this.props.searchQuery}
                searchMethod={this.customSearchMethod}
                generateNodeProps={rowInfo => ({
                  icons: 
                    rowInfo.node.isDirectory || rowInfo.node.mainCat ? 
                      [ rowInfo.node.expanded ? <FolderOpenRoundedIcon className={"icons"}/> : <FolderIcon className={"icons"}/>]
                    : [<div></div>],
                  className: this.state.selected === rowInfo.node.id ? 'rst__selected-node' : null,
                  buttons:[
                    <div className={"treeOptions"}
                      onClick={() => {this.props.selectCategorie(rowInfo); this.setState({selected: rowInfo.node.id})}}
                    >
                      <Typography variant={"h5"}>Sélectionner cette catégorie</Typography>
                    </div>
                  ],
                })}
              />
          </div>
        );
      case 'categorieFilter':
        return(
          <div className={this.props.reducedTree ? "treeContent reducedTree": "treeContent"}>
              <SortableTree
                theme={FileExplorerTheme}
                treeData={this.props.dataTree}
                onChange={(treeData) => {
                  this.props.onChange(treeData);
                }}
                canDrag={({ node }) => this.props.canDrag !== undefined ? this.props.canDrag : !node.dragDisabled}
                canDrop={({ nextParent, node }) => nextParent === null ? false : this.props.canDrop !== undefined ? this.props.canDrop : !nextParent || nextParent.isDirectory}
                dndType={'dndTree'}
                shouldCopyOnOutsideDrop={this.props.shouldCopy}
                generateNodeProps={rowInfo => {
                  let nodeProps =  {
                    onClick: event => this.nodeClicked(event, rowInfo),
                    icons: 
                      rowInfo.node.isDirectory || rowInfo.node.mainCat ? 
                        [ rowInfo.node.expanded ? <FolderOpenRoundedIcon className={"icons"}/> : <FolderIcon className={"icons"}/>]
                      : [ <div></div> ],
                  };
                  if (this.state.selectedNodeId === rowInfo.node.id) {
                    nodeProps.className = 'rst__selected-node';
                  }
                  return nodeProps;
                }}
                style={{overflowX: 'hidden!important'}}
              />
          </div>
        );
      case 'attribut':
        return(
          <div className={this.props.reducedTree ? "treeContent reducedTree": "treeContent"}>
              {
                this.props.dataTree?.length ? 
                  null 
                  : <span style={{marginLeft: 5}}>{ this.props.none }</span>
              }
              <SortableTree
                theme={FileExplorerTheme}
                treeData={this.props.dataTree}
                onChange={this.props.onChange}
                canDrag={({ node }) => !node.dragDisabled}
                canDrop={({ nextParent, node }) => !nextParent || nextParent.isDirectory}
                generateNodeProps={
                  rowInfo => ({
                    icons: rowInfo.node.isDirectory || rowInfo.node.mainCat ? [rowInfo.node.expanded ? <LocalOfferRoundedIcon className={"icons"}/> : <LocalOfferRoundedIcon className={"icons"}/>] : [<div></div>],
                    buttons: rowInfo.node.noAction ? [] 
                    : rowInfo.node.isItem ? 
                      [
                        this.props.canModify ?
                          <div className={"treeOptions"} onClick={() => {this.props.editItem(rowInfo.node)}}>
                            <EditRoundedIcon/>
                            <Typography variant={"h5"}>Modifier</Typography>
                          </div>
                        : null, 
                          <div className={"treeOptions type-info"}>
                            <BadgeCustom bgcolor={colors.green.regular} badgeContent={rowInfo.node.node.attributeType.input} />
                          </div>,
                          !rowInfo.node.node.status ? 
                            <div className={"treeOptions type-info"}>
                              <Typography variant={"h5"} style={{color: colors.red.regular, textDecoration: 'none'}}>Attribut désactivé</Typography>
                            </div>
                          : null
                          
                      ] 
                    : rowInfo.node.isDirectory ? 
                        [
                          this.props.canModify ?
                              <div className={"treeOptions"} onClick={() => this.props.editCat(rowInfo.node)}>
                                <EditRoundedIcon/>
                                <Typography variant={"h5"}>Modifier</Typography>
                              </div>
                            : null,
                          this.props.canAdd ?
                            <div 
                              className={"treeOptions"}
                              onClick={() => this.props.addSubcategory(rowInfo.node.node)}
                            >
                              <AddCircleRoundedIcon/>
                              <Typography variant={"h5"}>{this.props.content ? 'Ajouter une variable de modèle' :'Ajouter un attribut'}</Typography>
                            </div>
                          : null,
                        ] 
                      : [], 
                    style: {color: !rowInfo.node.node.status && rowInfo.node.isItem ? colors.grey.lighter.hue900 : null}
                })}
              />
          </div>
        );
      case 'classic':
        return(
          <div className={this.props.reducedTree ? "treeContent reducedTree": "treeContent"}>
              <SortableTree
                theme={FileExplorerTheme}
                treeData={this.props.dataTree}
                onChange={this.props.onChange}
                canDrag={({ node }) => {
                  return this.props.canDrag !== undefined ? this.props.canDrag : !node.dragDisabled;
                }}
                canDrop={({ nextParent, node }) => {
                  return this.props.canDrop !== undefined ? this.props.canDrop : !nextParent || nextParent.isDirectory;
                }}
                dndType={'dndTree'}
                shouldCopyOnOutsideDrop={this.props.shouldCopy}
                searchQuery={this.props.searchQuery}
                searchMethod={this.customSearchMethod}
                generateNodeProps={rowInfo => ({
                  icons: 
                    rowInfo.node.isDirectory || rowInfo.node.mainCat ? 
                      [ rowInfo.node.expanded ? <FolderOpenRoundedIcon className={"icons"}/> : <FolderIcon className={"icons"}/>]
                    : [<div></div>],
                  buttons: this.props.shouldCopy ? [
                    <div className={"treeOptions"}
                      onClick={() => this.props.copyCategories(rowInfo)}
                    >
                      <AddCircleRoundedIcon/>
                      <Typography variant={"h5"}>Ajouter au catalogue</Typography>
                    </div>
                  ] : null,
                })}
              />
          </div>
        );
      default: return null;
    }
  }

  render() {
    return (
      this.props.loading ? (
        <div className={"blockContainer"}>
          <div className={this.props.marginTop ? "containerTree topSpace" : "containerTree"}>
            <PageLoader style={{height: '100%'}} />
          </div>
        </div>
      ) : (
        <div className={`blockContainer ${this.props.typeOfTree}`}>
          <div className={this.props.marginTop ? "containerTree topSpace" : "containerTree"}>
            {this.props.allButton ? 
              <button className={"buttonAll"} onClick={this.props.allButton}><Typography variant={"h4"}>Tout voir</Typography></button> 
            : null}
            { this.props.noExpand ? null : 
              <div className={"containerButtons"}>
                <button onClick={(e) => {
                  e.preventDefault(); 
                  this.expandAll();
                }}>
                  <Typography variant={"h4"}>Tout ouvrir</Typography>
                </button>
                <button onClick={(e) => {
                  e.preventDefault(); 
                  this.collapseAll();
                }}>
                  <Typography variant={"h4"}>Tout réduire</Typography>
                </button>
              </div>
            }
            { this.renderSwitch(this.props.typeOfTree) }
          </div>
        </div>
      )
    );
  }
}