import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Box } from '@material-ui/core';

const BigImage=styled(Box)`
    cursor:pointer;
    position:relative;
`;
const ChipCustom = styled(Box)`
    border-radius: 0px;
    background-color: ${props => props.color ? props.color : '#FFFFFF'};
    display: block;
    height: 120px;
`;

export default function CardColor(props){
    const [color, setColor] = useState(props.color);
    const [codeColor, setCodeColor] = useState({});

    useEffect(() => {
        setColor(props.color)
        if (color && color.includes('#')){
            let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
            let hex = color.replace(shorthandRegex, function(m, r, g, b) {
                return r + r + g + g + b + b;
            });
    
            let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            setCodeColor(result ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
            } : null);
        }
    }, [color, props.color]);

    return (
        color ?
            (
                <BigImage>
                    <Box className="bigImageTitle">
                        <ChipCustom style={{transition: 'all ease 500ms'}} color={color} />
                    </Box>
                    <Box style={{padding: '0 16px'}}>
                        <p style={{textTransform: 'uppercase'}}><strong>HEX :</strong> {color}</p>
                        <p><strong>RGB :</strong> {codeColor.r}, {codeColor.g}, {codeColor.b}</p>
                    </Box>
                </BigImage>    
            ) 
        : null
    )
}