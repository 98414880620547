import { gql } from 'apollo-boost';

//----- Récupération FULL  catégories > types > assets
export const GET_FULL_ASSETS = gql`
{
  assetCategories{
    edges{
      node{
        id
        libelle
        logo
        description
        assetTypes{
          edges{
            node{
              id
              libelle
              logo
              description
              channelRequired
              retailerRequired
              localeRequired
              multiLocale
              hasFeed
              isActive
              isWidget
              hasWidget
              hasCatalog
              identifier
              assetCategory{
                id
                libelle
              }
              attributeGroup{
                id
                identifier
              }
              assets{
                edges{
                  node{
                    id
            				identifier
                    status
                    stageUrl
                    prodUrl
                    stageHostUrl
                    prodHostUrl
                    startAt
                    endAt
                    channel{
                      id
                      libelle
                      logo
                      description
                    }
                    catalog{
                      id
                      identifier
                    }
                    assetGameType{
                      id
                      libelle
                      identifier
                      logo
                      description
                    }
                    assetDatas{
                      edges{
                        node{
                          value
                          id
                          attributeOption{
                            identifier
                            translation{
                              translationDatas{
                                edges{
                                  node{
                                    locale{
                                      id
                                      code
                                    }
                                    value
                                  }
                                }
                              }
                            }
                          }
                          attribute{
                            id
                            identifier
                            isSystem
                            attributeType {
                              id
                              input
                            }
                          }
                          media{
                            id
                            filePath
                            type
                          }
                          locale{
                            id
                            code
                          }
                        }
                      }
                    }
                    assetCrons{
                      edges{
                        node{
                          id
                          rate
                          eavType{
                            id
                          	code
                          }
                        }
                      }
                    }
                    assetMinisitePages{
                      edges{
                        node{
                          id
                          identifier
                          libelle
                          status
                          content
                          createdAt
                          assetMinisitePageType{
                            id
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const GET_ASSETS_CURSOR = gql`
{
  assets{
    totalCount
    edges{
      cursor
      node{
        identifier
      }
    }
  }
}

`;

export const GET_ASSETS_PAGINATION = gql`
query assets ($nbperpage: Int, $cursor: String, $assetType_list: [String], $id_list: [Int]){
  assets (first: $nbperpage, after: $cursor, assetType_list: $assetType_list, id_list: $id_list){
    totalCount
    edges{
      cursor
      node{
        id
        identifier
        status
        stageUrl
        prodUrl
        stageHostUrl
        prodHostUrl
        startAt
        endAt
        assetType{
          libelle
          identifier
          logo
          hasFeed
          isActive
          isWidget
          hasWidget
          hasCatalog
          assetCategory{
            identifier
          }
        }
        assetGameType{
          id
          libelle
          identifier
          logo
          description
        }
        assetDatas{
          edges{
            node{
              value
              id
              media{
                id
                filePath
                type
                size
                category{
                  edges{
                    node{
                      id
                      libelle
                    }
                  }
                }
              }
              attribute{
                id
                identifier
                isSystem
                attributeType {
                  id
                  input
                }
              }
              locale{
                id
                code
              }
            }
          }
        }
      }
    }
  }
}
`;
//----- Total assets
export const GET_ASSETS_TOTAL = gql`
{
  assets{
    totalCount
  }
}
`;

//----- Récupération FULL  catégories > types > assets
export const GET_ASSETS_FOR_CALENDAR = gql`
{
  assets{
  edges{
    node{
      identifier
      startAt
      assetType{
            logo
      }
    }
  }
  }
}`;

//----- Récupération des catégories d'asset
export const GET_ASSET_CATEGORIES = gql`
{
  assetCategories{
    edges{
      node{
        id
        identifier
        libelle
        logo
        description
        assetTypes{
          edges{
            node{
              id
              libelle
              logo
              description
              channelRequired
              retailerRequired
              localeRequired
              multiLocale
              hasFeed
              isActive
              isWidget
              hasWidget
              hasCatalog
              identifier
              attributeGroup{
                id
                identifier
                isSystem
              }
              assetCategory{
                id
                libelle
              }
            }
        	}
        }
      }
    }
  }
}
`;

//----- Récupération des type d'asset
export const GET_ASSET_TYPE_WIDGET = gql`
{
  assetTypes(isWidget:true){
    edges{
      node{
        libelle
        isWidget
        hasWidget
        hasCatalog
        assets{edges{node{id}}}
      }
    }
  }
}
`

//----- Récupération des type d'asset
export const GET_ASSET_TYPES = gql`
{
  assetTypes{
    edges{
      node{
				id
        libelle
        logo
        description
        assetCategory{
          id
        	libelle
        }
        attributeGroup{
          id
          identifier
        }
        assets{
          edges{
            node{
              id
              identifier
              status
              stageUrl
              prodUrl
              stageHostUrl
              prodHostUrl
              startAt
              endAt
              catalog{
                id
                identifier
              }
              assetDatas{
                edges{
                  node{
                    value
                    id
                    attributeOption{
                      identifier
                      translation{
                        translationDatas{
                          edges{
                            node{
                              locale{
                                id
                                code
                              }
                              value
                            }
                          }
                        }
                      }
                    }
                    attribute{
                      id
                      identifier
                      isSystem
                      attributeType {
                        id
                        input
                      }
                    }
                    media{
                      id
                      filePath
                      type
                    }
                    locale{
                      id
                      code
                    }
                  }
                }
              }
              assetCrons{
                edges{
                  node{
                    id
                    rate
                    eavType{
                      id
                      code
                    }
                  }
                }
              }
              assetMinisitePages{
                edges{
                  node{
                    id
                    identifier
                    libelle
                    status
                    content
                    createdAt
                  	assetMinisitePageType{
                      id
                    }
                  }
                }
              }
              assetGameType{
                id
                libelle
                identifier
                logo
                description
              }
            }
          }
        }
      }
    }
  }
}
`;

//----- Récupération des assets d'un type
/*
 Exemple de variables
  {
    "id" : /api/asset-types/11"
  }
*/
export const GET_ASSETS_BY_TYPE = gql`
query assetCategory($id: ID!) {
  assetType(id: $id) {
    id
    libelle
    logo
    description
    attributeGroup{
      id
      identifier
    }
    assets{
      edges{
        node{
          id
          identifier
          status
          catalog{
            id
          	identifier
          }
          assetDatas{
            edges{
              node{
                value
                id
                attributeOption{
                  identifier
                  translation{
                    translationDatas{
                      edges{
                        node{
                          locale{
                            id
                            code
                          }
                          value
                        }
                      }
                    }
                  }
                }
                attribute{
                  id
                  identifier
                  isSystem
                  attributeType {
                    id
                    input
                  }
                }
                locale{
                  id
                  code
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

//----- Récupération d'un asset
/*
 Exemple de variables
  {
    "id" : /api/assets/17"
  }
*/
export const GET_ASSET_BY_ID = gql`
query assetDatas($id: ID!) {
  asset(id: $id){
    id
    identifier
    status
    startAt
    stageUrl
    prodUrl
    stageHostUrl
    prodHostUrl
    endAt
    token
    currencies{
      edges{
        node{
          id
          code
          libelle
        }
      }
    }
    locales{
      edges{
        node{
          id
          code
          libelle
        }
      }
    }
    assetWidgets{
      edges{
        node{
          id
          identifier
          status
          assetType{
            identifier
            libelle
          }
          assetGameType{
            libelle
            identifier
          }
          assetDatas{
            edges{
              node{
                value
                id
                attributeOption{
                  id
                  identifier
                  translation{
                    translationDatas{
                      edges{
                        node{
                          locale{
                            id
                            code
                          }
                          value
                        }
                      }
                    }
                  }
                }
                attribute{
                  id
                  identifier
                  isSystem
                  translation{
                    id
                    translationDatas{
                      edges{
                        node{
                          id
                          value
                          locale{
                            id
                            code
                          }
                        }
                      }
                    }
                  }
                  attributeType {
                    id
                    input
                  }
                }
                media{
                  id
                  filePath
                  type
                  size
                  category{
                    edges{
                      node{
                        id
                        libelle
                      }
                    }
                  }
                }
                locale{
                  id
                  code
                }          
              }
            }
          }
        }
      }
    }
    channel{
      id
      identifier
      libelle
      logo
      description
      attributeGroup{
        id
      	identifier
      }
    }
    retailer{
      id
      identifier
      libelle
      attributeGroup{
        id
        identifier
      }
      logo
      description
    }
    catalog{
      id
      identifier
      libelle
      description
      type{
        id
        identifier
      }
      status
      media{
        filePath
        id
      }
      autosync
      startAt
      endAt
      categories{
        id
        libelle
        parent {
          id
          libelle
        }
        categoryDatas{
          edges{
            node{
              id
              value
              attribute{
                id
                identifier
                attributeType {
                  id
                  input
                }
              }
              media{
                id
                filePath
                type
                size
                category{
                  edges{
                    node{
                      id
                      libelle
                    }
                  }
                }
              }
              locale{
                id
                code
              }
            }
          }
        }
        products{
          totalCount
        }
      }
    }
    assetType{
      id
      logo
      libelle
      description
      channelRequired
      retailerRequired
      localeRequired
      multiLocale
      hasFeed
      isActive
      isWidget
      hasWidget
      hasCatalog
      identifier
      attributeGroup{
        id
      }
      assetCategory{
        id
        logo
      }
    }
    assetDatas{
      edges{
        node{
          value
          id
          attributeOption{
            id
            identifier
            translation{
              translationDatas{
                edges{
                  node{
                    locale{
                      id
                      code
                    }
                    value
                  }
                }
              }
            }
          }
          attribute{
            id
            identifier
            isSystem
            translation{
              id
              translationDatas{
                edges{
                  node{
                    id
                    value
                    locale{
                      id
                      code
                    }
                  }
                }
              }
            }
            attributeType {
              id
              input
            }
          }
          media{
            id
            filePath
            type
            size
            category{
              edges{
                node{
                  id
                  libelle
                }
              }
            }
          }
          locale{
            id
            code
          }          
        }
      }
    }
    assetCrons{
      edges{
        node{
          id
          rate
          eavType{
            id
            code
          }
        }
      }
    }
    assetMinisitePages{
      edges{
        node{
          id
          libelle
          status
          content
          createdAt
          updatedAt
          assetMinisitePageType{
            id
            identifier
            image
          }
          asset{
            id
            identifier
          }
        }
      }
    }
    assetGamePages{
      edges{
        node{
          id
          identifier
          asset {
            id
          }
          assetGamePageType{
            id
            identifier
            image
          }
          libelle
          status
          content
          createdAt
          updatedAt
        }
      }
    }
    assetGameType{
      id
      libelle
      identifier
      logo
      description
    }
  }
}
`;

//----- Ajouter un asset 
/*
 Exemple de variables
  {
    "identifier": "test",
    "assetType": "/api/asset-types/11",
    "catalog": "/api/catalogs/1",
    "channel": "/api/channels/1",
    "startAt": "2020-08-01"
  }
*/
export const ADD_ASSET = gql`
mutation AddAsset($identifier: String!, $assetType: String!, $catalog: String, $channel: String, $retailer: String, $startAt: String, $endAt: String,$stageUrl: String, $prodUrl: String, $stageHostUrl: String, $prodHostUrl: String, $status: Boolean!, $assetGameType: String, $content: String, $locales: [String], $currencies: [String], $assetWidgets:[String], $updatedAt: String!){
	createAsset(input: {identifier:$identifier, assetType:$assetType, catalog:$catalog, channel:$channel, retailer:$retailer, startAt:$startAt, endAt:$endAt,stageUrl: $stageUrl, prodUrl: $prodUrl, stageHostUrl: $stageHostUrl, prodHostUrl: $prodHostUrl, status:$status, assetGameType:$assetGameType, content:$content, locales: $locales, currencies: $currencies, assetWidgets:$assetWidgets, updatedAt: $updatedAt}){
  	asset{
      id
      identifier
    }
  }
}`;

//----- Modifier un asset 
/*
 Exemple de variables
  {
    "id" : "/api/assets/3",
    "identifier": "test",
    "assetType": "/api/asset-types/11",
    "catalog": "/api/catalogs/1"
  }
*/
export const UPDATE_ASSET = gql`
mutation UpdateAsset($id: ID!, $identifier: String, $assetType: String, $catalog: String, $channel: String,$retailer: String, $startAt: String, $endAt: String, $stageUrl: String, $prodUrl: String, $stageHostUrl: String, $prodHostUrl: String, $status: Boolean, $assetGameType: String, $content: String, $locales: [String], $currencies: [String], $assetWidgets: [String]){
	updateAsset(input: {id:$id, identifier:$identifier,assetType:$assetType, catalog:$catalog, channel:$channel, retailer:$retailer, startAt:$startAt, endAt:$endAt, stageUrl: $stageUrl, prodUrl: $prodUrl, stageHostUrl: $stageHostUrl, prodHostUrl: $prodHostUrl, status:$status, assetGameType:$assetGameType, content:$content, locales:$locales, currencies: $currencies, assetWidgets: $assetWidgets}){
  	asset{
      id
      identifier
    }
  }
}`;


//----- Supprimer un asset
/*
Exemple de variables
  {
    "id" : "/api/assets/1"
  }
*/
export const DELETE_ASSET = gql`
mutation DeleteAsset($id: ID!){
	deleteAsset(input: {id:$id}){
    asset{
      id
    }
  }  
}`;

//----- Ajouter une valeur d'attribut à un Asset
/*
Exemple de variables
  {
    "value": "MON_URL_DE_TEST",
    "asset": "/api/assets/3",
    "attribute": "/api/attributes/42", 
    "locale": "/api/locales/3"
  }
*/
export const ADD_ASSET_DATA = gql`
mutation AddAssetData($value: String, $asset: String!, $attribute: String!, $locale: String!, $attributeOption: String, $media: String){
	createAssetData(input: {value:$value, asset:$asset, attribute:$attribute, locale:$locale, attributeOption:$attributeOption, media:$media}){
    assetData{
      id
      value
      asset{
        id
      }
      attribute{
        id
      }
      media{
        id
      }
      locale{
        id
      }
      attributeOption{
        id
        identifier
      }
    }
  }  
}`;



//----- Modifier une valeur d'attribut d'un asset

/*
 Exemple de variables
 {
  "id": "/api/asset-datas/3",
  "value": "MON_URL_DE_TEST_EDITED",
	"asset": "/api/assets/3",
  "attribute": "/api/attributes/42", 
	"locale": "/api/locales/3"
}
*/
export const UPDATE_ASSET_DATA = gql`
mutation UpdateAssetData($id:ID!, $value: String, $asset: String, $attribute: String, $locale: String, $attributeOption: String, $media: String){
	updateAssetData(input: {id:$id, value:$value, asset:$asset, attribute:$attribute, locale:$locale, attributeOption:$attributeOption, media:$media}){
    assetData{
      id
      value
      asset{
        id
      }
      attribute{
        id
      }
      locale{
        id
      }
      media{
        id
      }
      attributeOption{
        id
        identifier
      }
    }
  }  
}`;


//----- Supprimer une valeur d'attribut d'un asset
/*
 Exemple de variables
  {
    "id" : "/api/asset-datas/3"
  }
*/
export const DELETE_ASSET_DATA = gql`
mutation DeleteAssetData($id: ID!){
	deleteAssetData(input: {id:$id}){
    assetData{
      id
    }
  }  
}`;






//----- Ajouter un cron à un asset 
/*
 Exemple de variables
  {
    "eavType": "/api/eav-types/1",
    "rate": "*SLASH5 * * * *",
    "asset": "/api/assets/1"
  }
*/
export const ADD_CRON = gql`
mutation AddAssetCron($eavType: String, $rate : String!, $asset: String){
	createAssetCron(input: {eavType:$eavType, rate:$rate, asset:$asset}){
  	assetCron{
      id
      rate
    }
  }
}`;

//----- Modifier un cron lié à un asset 
/*
 Exemple de variables
  {
    "id" : "/api/asset-crons/1",
    "eavType": "/api/eav-types/1",
    "rate": "*SLASH5 * * * *",
    "asset": "/api/assets/1"
  }
*/
export const UPDATE_CRON = gql`
mutation UpdateAssetCron($id: ID!, $eavType: String, $rate : String, $asset: String){
	updateAssetCron(input: {id:$id, eavType:$eavType, rate:$rate, asset:$asset}){
  	assetCron{
      id
      rate
    }
  }
}`;

//----- Supprimer un cron lié à un asset 
/*
 Exemple de variables
  {
    "id" : "/api/asset-crons/3"
  }
*/
export const DELETE_CRON = gql`
mutation DeleteAssetCron($id: ID!){
	deleteAssetCron(input: {id:$id}){
    assetCron{
      id
    }
  }  
}`;

export const GET_ASSET_BY_IDENTIFIER = gql`
query asset($identifier: String!) {
  assets(identifier: $identifier) {
    edges {
      node {
        id
        identifier
        status
        startAt
        stageUrl
        prodUrl
        stageHostUrl
        prodHostUrl
        endAt
        content
        token
        catalog {
          id
          identifier
          libelle
          description
          type {
            id
            identifier
          }
          status
          media{
            filePath
          }
          autosync
          startAt
          endAt
          categories {
            id
            libelle
            parent {
              id
              libelle
            }
            categoryDatas {
              edges {
                node {
                  id
                  value
                  attribute {
                    id
                    identifier
                    attributeType {
                      id
                      input
                    }
                  }
                  media {
                    id
                    filePath
                    type
                    size
                    category {
                      edges {
                        node {
                          id
                          libelle
                        }
                      }
                    }
                  }
                  locale {
                    id
                    code
                  }
                }
              }
            }
            products {
              totalCount
              edges {
                node {
                  id
                  sku
                  categories {
                    id
                    libelle
                  }
                  productDatas {
                    edges {
                      node {
                        id
                        value
                        attributeOption {
                          id
                          identifier
                        }
                        media {
                          filePath
                        }
                        locale {
                          code
                        }
                        attribute {
                          identifier
                          isSystem
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        assetType {
          id
          logo
          libelle
          description
          channelRequired
          retailerRequired
          localeRequired
          multiLocale
          hasFeed
          isActive
          isWidget
          hasWidget
          hasCatalog
          identifier
          attributeGroup {
            id
          }
          assetCategory {
            id
            logo
          }
        }
        assetDatas {
          edges {
            node {
              value
              id
              attributeOption {
                id
                identifier
                translation {
                  translationDatas {
                    edges {
                      node {
                        locale {
                          id
                          code
                        }
                        value
                      }
                    }
                  }
                }
              }
              attribute {
                id
                identifier
                isSystem
                translation {
                  id
                  translationDatas {
                    edges {
                      node {
                        id
                        value
                        locale {
                          id
                          code
                        }
                      }
                    }
                  }
                }
                attributeType {
                  id
                  input
                }
              }
              media {
                id
                filePath
                type
                size
                category {
                  edges {
                    node {
                      id
                      libelle
                    }
                  }
                }
              }
              locale {
                id
                code
              }
            }
          }
        }
        assetMinisitePages{
          edges{
            node{
              id
              libelle
              status
              content
              createdAt
              updatedAt
              assetMinisitePageType{
                id
                identifier
                image
              }
              asset{
                id
                identifier
              }
            }
          }
        }
        assetGamePages{
          edges{
            node{
              id
              libelle
              status
              content
              createdAt
              updatedAt
              assetGamePageType{
                id
                identifier
                image
              }
              asset{
                id
                identifier
              }
            }
          }
        }
        assetGameType{
          id
          libelle
          identifier
          logo
          description
        }
      }
    }
  }
}
`;

//----- Récupération des cursors des wishlists d'un asset
/*
Exemple de variables
  {
    "asset":"/api/assets/2"
  }
*/
export const GET_ASSET_WISHLISTS_CURSORS = gql`
query wishlistsByAssetCursors($asset: String){
  assetWishlists(first: 100000000,asset:$asset){
    totalCount
    edges{
      cursor
    }
  }
}
`;

//----- Récupération des wishlists d'un asset
/*
Exemple de variables
  {
    "asset":"/api/assets/2"
  }
*/
export const GET_ASSET_WISHLISTS = gql`
query wishlistsByAsset($nbperpage: Int, $cursor: String, $asset: String){
  assetWishlists(first: $nbperpage, after: $cursor, asset:$asset){
    totalCount
    edges{
      node{
        id
        username
        origin
        createdAt
        origin
        assetWishlistItems{
          edges{
            node{
              product{
                sku
                productDatas{
                  edges{
                    node{
                      id
                      media{
                        id
                        filePath
                      }
                      attribute{
                        identifier
                      }
                      value
                      locale{
                        id
                        code
                        libelle
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

//----- Récupération du contenu d'une Wishlist
/*
 Exemple de variables
  {
    "id":"/api/asset-wishlists/2"
  }
*/
export const GET_WISHLIST_DETAILS = gql`
query wishlistDetails($id: ID!){
  assetWishlist(id:$id){
    username
    createdAt
    assetWishlistItems{
      edges{
        node{
          product{
            sku
          }
        }
      }
    }
  }
}
`;

export const GET_ASSET_EXPORT = gql`
{
  assetExports{
    edges{
      node{
        id
        position
        keyName
        keyValue
        asset{
          id
        }
        attribute{
          id
        }
      }
    }
  }
}
`;

export const GET_ASSET_BY_CATALOG_ID = gql`
query assets ($catalog: String){
  assets (catalog: $catalog){
    edges{
      node{
        identifier
        id
        catalog{
          id
        }
       	assetDatas{
          edges{
            node{
              value
              id
              attribute{
                id
                identifier
              }
            }
          }
        }
      }
    }
  }
}
`

export const ADD_ASSET_EXPORT = gql`
mutation AddAssetExport($position: Int!, $keyName: String!, $keyValue: String, $attribute: String, $asset: String!){
	createAssetExport(input: {position: $position, keyName: $keyName, keyValue: $keyValue, attribute: $attribute, asset: $asset}){
  	assetExport{
      id
      position
      keyName
      keyValue
      asset{
        id
      }
      attribute{
        id
      }
    }
  }
}
`;

export const UPDATE_ASSET_EXPORT = gql`
mutation UpdateAssetExport($id: ID!, $position: Int!, $keyName: String!, $keyValue: String, $attribute: String, $asset: String!){
	updateAssetExport(input: {id: $id, position: $position, keyName: $keyName, keyValue: $keyValue, attribute: $attribute, asset: $asset}){
  	assetExport{
      id
      position
      keyName
      keyValue
      asset{
        id
      }
      attribute{
        id
      }
    }
  }
}
`;

export const DELETE_ASSET_EXPORT = gql`
mutation DeleteAssetExport($id: ID!){
	deleteAssetExport(input: {id:$id}){
    assetExport{
      id
    }
  }  
}
`;
