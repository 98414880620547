import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import colors from '../../../../../config/theme/colors';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: '100%',
      background: 'white'
    },
  }));

const TabsCustom = styled(Tabs)`
  button{
    text-transform: initial;
    & > span:first-child{
      svg{
        margin-left:15px;  
        margin-bottom:0px!important;
        display:block;
      }
      flex-direction: row-reverse;
      align-items:center;
    }
  }
  .MuiSvgIcon-fontSizeSmall{
    font-size: 30px;
    fill: ${colors.blue.lighter.hue300};
  }
  &> div > span{
    background-color: ${colors.blue.lighter.hue300};
    height: 3px;
    &.MuiTouchRipple-root{
      background-color: transparent;
    }
  }
`;

const TabCustom = styled(Tab)`
  min-width: inherit;
  padding: 6px 18px;
`;

const NavLevel1 = styled.div`
  margin-bottom: 25px;
  flex: 0 1 auto;
  padding: 0 20px;  
`;

const TextNav1 = styled.div`
cursor: pointer;
font-weight: bold;
&:hover{
  color: ${colors.blue.lighter.hue300}
}
`;

const DropdownContainer = styled.div`
  padding-top: 15px;
  margin-top: -15px;
  position: absolute; 
  z-index: 80;
  width: 100%;
  top: 75px;
`;

const WrapperContent = styled.div`
  background: white;
  padding: 10px 20px;
  padding-top: 32px;
  width: 100%;
  height: 100%;
  box-shadow: 0px 9px 22px #D4D9DC;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  max-height: 320px !important;
`;

export default function TabCategories(props){

    const classes                                 = useStyles();

    const [value, setValue]                       = React.useState(0); 
    const [isOpen, setIsOpen]                     = React.useState(false); 
    const [categorieOpened, setCategorieOpened]   = React.useState(null); 
    const [indexMain, setIndexMain]               = React.useState(null); 

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const handleBreadCrumb = (mainCat, subCat, subCat2) => {
      let newArray = []
      if(mainCat)
        newArray.push(mainCat)
      if(subCat)
        newArray.push(subCat)
      if(subCat2)
        newArray.push(subCat2)
      return newArray
    }

    const handleOpen = (categorie, index) => {
      setIsOpen(true)
      if (categorie){
        setCategorieOpened(categorie)
        setIndexMain(index)
      }
    }

    const handleClose = () => {
        setIsOpen(false)
    };

    return(
            <div className={classes.root}>
                <TabsCustom  
                    value           = {value}
                    indicatorColor  = "primary"
                    textColor       = "primary"
                    variant         = "scrollable"
                    scrollButtons   = "auto"
                >
                    <TabCustom 
                      label   = "Tout voir" 
                      onClick = {(event) => { props.handleChangeTab();handleChange(null, 0)}}
                      key     = "TabCategoriesAll" 
                    />
                    {
                      props.categories ? 
                        props.categories.map((categorie,i)=>
                          
                            <TabCustom 
                              onClick = {
                                (event) => { setTimeout(() => {
                                    props.handleChangeTab(event, 1, categorie.node.id, categorie.node, handleBreadCrumb(categorie.node))
                                    handleChange(null, categorie.node.id)
                                },50);
                            }}
                              onMouseEnter = { () => handleOpen(categorie.node, i + 1)}
                              onMouseLeave = { handleClose}
                              label   = {categorie.node.libelle} 
                              key     = {`TabCategorie-${categorie.node.libelle}-${i}`}
                              value   = {categorie.node.id}
                              icon    = {categorie.node.assetTypes?<ExpandMoreIcon />:null}
                            />
    
                        ) 
                      : null
                    }
                </TabsCustom > 
                <DropdownContainer style={{display: isOpen ? 'flex' : 'none'}} 
                  onMouseEnter = { () => handleOpen() }
                  onMouseLeave = { () => handleClose() }
                >
                  <WrapperContent>
                    {
                      categorieOpened?.assetTypes.edges.map((sub, index) => {
                          let subCatName = sub.node.categoryDatas?.filter(e => e.attribute.identifier === "category_name");
                          subCatName = subCatName?.find(e => e.locale.code === props.currentLang)?.value
                          return(
                              <NavLevel1 key={`NavLevel-${sub.node.id}-${index}`}>
                                <TextNav1 onClick={(event) => {props.handleChangeTab(event, indexMain, sub.node.id, sub.node, handleBreadCrumb(categorieOpened, sub.node)); handleChange(null, categorieOpened.id)}}>
                                  <>{subCatName ? subCatName : sub.node.libelle}</>
                                </TextNav1>
                              </NavLevel1>
                          )
                      })
                    }
                  </WrapperContent>
                </DropdownContainer>
                {/* <MenuSubCat
                    open            = {open}
                    anchorEl        = {anchorEl}
                    onClose         = {handleClose}
                    anchorOrigin    = {{

                      vertical    : "bottom",
                      horizontal  : "right"

                    }}
                    transformOrigin = {{

                      vertical    : "top",
                      horizontal  : "center"

                    }}
                >

                  {
                    subCats.map((subcat)=>

                      <MenuItem 
                        onClick = {() => (handleChange(null,subcat.node.assetCategory.id),props.handleChangeTab(subcat.node,null,true,subcat.node.assetCategory.id), handleClose())}
                      >

                        {subcat.node.libelle}

                      </MenuItem>    
                                              
                    )
                  }

                </MenuSubCat>               */}
            </div>
         


    )
}