import React from 'react';
import { Box, Typography} from '@material-ui/core';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

const ContainerCard = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;
const ContainerButton = styled(Box)`
    display: flex;
    justify-content: flex-end;

`;

const CircularProgressCustom = styled(CircularProgress)`
    color: ${props => props.value > 30 
            ? props.value > 65 
                ? colors.green.regular : "#f6d607"
            : colors.red.regular};
    margin-left: ${props => props.marginleft};
`;

export default function CardCompletudeDashboard(props){
    return(
        <ContainerCard p={2}>
            <ContainerButton style={{justifyContent: "flex-start", backgroundColor: colors.grey.lighter.hue980, flexDirection: props.layout === 3 ? 'column' : 'row'}}>
                    {
                        props.percent === 0 || props.percent ?
                        (
                            <>
                            <Box display="flex" style={{alignItems: "center", padding: props.layout === 1 ? 32 : 16, justifyContent: props.layout === 3 ? 'center' : null}}>
                                <Box position="relative" display="inline-flex" mr={2} style={{position: 'relative'}}>
                                    <CircularProgress
                                        variant="determinate"
                                        size={props.layout === 1 ? 200 : 100}
                                        style={{position: 'absolute', color: colors.grey.lighter.hue900}}
                                        thickness={3}
                                        value={100}
                                    />
                                    <CircularProgressCustom variant="determinate" thickness={3} value={props.percent} size={props.layout === 1 ? 200 : 100}/>
                                    <Box
                                        top={0}
                                        left={0}
                                        bottom={0}
                                        right={0}
                                        position="absolute"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Typography variant="h5" component="div" style={{fontSize: props.layout === 1 ? 40 : 20, color: '#2B4553', fontWeight: 'bold'}}>
                                            {props.percent > 0 ? `${Math.round(props.percent)}%` : `${props.percent}%`}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box style={{display:'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'self-start', padding: props.layout === 3 ? '24px 0' : null, background: props.layout === 3 ? 'white' : null}}>
                                <Typography variant="body1" style={{fontWeight: 'bold', color: '#2B4553', display: 'block', fontSize: 16, lineHeight: '22px', paddingBottom: 8}}>{props.percent}% des {props.type} ont 100% de complétudes</Typography>
                                <Typography variant="body2">Temps estimé avant les 100% : {props.time} min</Typography>
                            </Box>
                        </>
                    ) : null
                }
            </ContainerButton>
        </ContainerCard>
    );
}