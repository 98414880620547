import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";

import { Grid } from '@material-ui/core';
import CardCustom from '../../../layouts/Card/CardCustom';
import CardSetup from '../../../layouts/Card/cardContent/CardSetup';
import Typography from '../../../ui/typography/Typography';
import {ROUTE_CHANNELS_MARKETPLACES, ROUTE_CHANNELS_SOCIALS, ROUTE_CHANNELS_CMS, ROUTE_CHANNELS_MEDIAS, ROUTE_CHANNELS_MAILER, ROUTE_HOME} from '../../../../js/constants/route-names';
import { withApollo } from 'react-apollo';
import styled from 'styled-components';
import request from '../../../../js/utils/fetch';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import colors from '../../../../config/theme/colors';
import SliderDashboard from "../../../layouts/Slider/SliderDashboard";
import Image1 from '../../../../../src/assets/images/dashboard/Visuels-Dashboard-connecteur-RS.png';
import Image2 from '../../../../../src/assets/images/dashboard/Visuels-Dashboard-connecteur-marketplace.png';
import { v4 as uuidv4 } from 'uuid';
import { hasRights } from '../../../../js/utils/rights';
import { CONNECTORS, CONNECTORS_DASHBOARD, CONNECTORS_CMS, CONNECTORS_EMAILS, CONNECTORS_MARKETPLACES, CONNECTORS_MEDIAS, CONNECTORS_SOCIAL_NETWORK, CREATE, VIEW} from '../../../../js/constants/constant-rights';
import { ALERT_ERROR } from '../../../../js/constants/alert-types';
import { SNACK } from '../../../../js/constants/action-types';
import ImportHistory from '../settings/ImportHistory';
import CronNotifications from './components/CronNotifications';


const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
`;

class DashboardChannels extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            channels: [],
            listCrons: null,
        };
    }

    goTo = (route, status) => {

        this.props.history.push({
            pathname : route,
            state: {formOpen : status}
        });
    };

    componentDidMount(){
        const getRights = hasRights(CONNECTORS, CONNECTORS_DASHBOARD, VIEW)
        
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }
        this.initDashboard();
    }

    initDashboard = () => {
        request(`${process.env.REACT_APP_API}/dashboards/stats/connector`, 'get', false).then(
            (data) => {
                if(data.success){
                    this.setState({
                        channels: data.datas
                    });
                }
            }
        );
        if ( process.env.REACT_APP_MODE_SPREAD === "hub"){
            request(`${process.env.REACT_APP_API}/warehousePreference`, 'get', false).then(
                (data) => {
                    if(data.success){
                        this.setState({
                            preferences: data.preferences
                        });
                    }
                }
            );
        }
    }
    
    getListCronByRetailer = (id, retailer) => {
        this.setState({
            listCrons: null,
            drawerCron: true,
            retailerCron: retailer
        });

        try {
            request(`${process.env.REACT_APP_API}/scheduler/retailer/${id}`, 'get', false).then(
                (data) => {
                    if(data.success){
                        this.setState({listCrons: data.crons})
                    }
                }
            )
        } catch(e) {
            console.log(e);
        }
    }

    render() {
        let sliderData = [
            hasRights(CONNECTORS, CONNECTORS_MARKETPLACES, CREATE) ?
            {
                title: 'Pourquoi ajouter un connecteur marketplaces ?',
                text: 'Ajouter des marketplaces vous permet de diffuser vos produits avec les informations associés sur chaque marketplace. Ainsi, vous pourrez garder le contrôle de tous vos produits. De nombreux connecteurs de marketplaces sont disponibles : Google Shopping, Amazon, Ebay, Cdiscount ...',
                image: Image1,
                id: uuidv4(),
                buttons: [
                    {
                        text: 'Ajouter un connecteur Marketplace',
                        action: () => this.goTo(ROUTE_CHANNELS_MARKETPLACES, true),
                    },
                ]
            } : null,
            hasRights(CONNECTORS, CONNECTORS_SOCIAL_NETWORK, CREATE) ?
            {
                title: 'Pourquoi ajouter un connecteur réseau social ?',
                text: 'Connecter vos réseaux sociaux vous permet de remonter le feed de vos postes sur les assets de votre choix.\n De nombreux connecteurs de réseaux sociaux sont disponibles : Instagram, Facebook, LinKedin ...',
                image: Image2,
                id: uuidv4(),
                buttons: [
                    {
                        text: 'Ajouter un connecteur Réseau social',
                        action: () => this.goTo(ROUTE_CHANNELS_SOCIALS, true),
                    },
                ]
            } : null,
            hasRights(CONNECTORS, CONNECTORS_CMS, CREATE) ?
            {
                title: 'Pourquoi ajouter un connecteur CMS ?',
                text: "L'ajout d'un CMS ou la connexion d’un CMS existant vous permet de bénéficier du contenu (produits, catégories ..) présents dans Spread Suite pour pouvoir le diffuser.\n Un large choix de CMS disponible : Magento 2, Wordpress, Prestashop ...",
                image: Image1,
                id: uuidv4(),
                buttons: [
                    {
                        text: 'Ajouter un connecteur CMS',
                        action: () => this.goTo(ROUTE_CHANNELS_CMS, true),
                    },
                ]
            } : null,
            hasRights(CONNECTORS, CONNECTORS_EMAILS, CREATE) ?
            {
                title: 'Pourquoi ajouter un connecteur routeur de mails ?',
                text: 'Ajouter un connecteur email permet de connecter votre routeur de mail avec SpreadSuite et envoyer les emails transactionnels ainsi que vos Newsletter. Vous pouvez construire vos mails et les diffuser.',
                image: Image2,
                id: uuidv4(),
                buttons: [
                    {
                        text: 'Ajouter un connecteur Mail',
                        action: () => this.goTo(ROUTE_CHANNELS_MAILER, true),
                    },
                ]
            } : null,
            hasRights(CONNECTORS, CONNECTORS_MEDIAS, CREATE) ?
            {
                title: 'Pourquoi ajouter un connecteur de médias ?',
                text: 'Via Google Drive, OneDrive ou Dropbox, vous pouvez importer vos médias pour ensuite les utiliser sur SpreadSuite pour vos produits ou dans un asset.',
                image: Image1,
                id: uuidv4(),
                buttons: [
                    {
                        text: 'Ajouter un connecteur Média',
                        action: () => this.goTo(ROUTE_CHANNELS_MEDIAS, true),
                    },
                ]
            } : null,
        ];
        sliderData = sliderData.filter(function (el) {
            return el != null;
        });

        let stats = 
            [
                {
                    'icon': 'picto-stock',
                    'data': this.state.channels.channelActifs,
                    'dataColor': colors.blue.regular,
                    'subtitle': process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'Connecteurs Actifs' : 'Channels Actifs',
                    'gradientColor1': colors.pink.regular,
                    'gradientColor2': colors.pink.lighter,
                },
                {
                    'icon': 'picto-produit',
                    'data': this.state.channels.channelInactifs,
                    'dataColor': colors.blue.regular,
                    'subtitle': process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'Connecteurs Inactifs' : 'Channels Inactifs',
                    'gradientColor1': colors.pink.regular,
                    'gradientColor2': colors.pink.lighter,
                },
            ];
        // let lastDatas = [
        //     {
        //         'icon': 'picto-stock',
        //         'data': this.state.channels.channelActifs,
        //         'dataColor': colors.blue.regular,
        //         'subtitle': 'Channels Actifs',
        //     },
        //     {
        //         'icon': 'picto-produit',
        //         'data': this.state.channels.channelInactifs,
        //         'dataColor': colors.blue.regular,
        //         'subtitle': 'Channels Inactifs',
        //     },
        // ]
        return (
            <div style={{width: "100%", paddingBottom: 60}}>
                <TopPanel 
                    icomoon="icon-dashboard" 
                    colorIcomoon={colors.blue.lighter.hue300}
                    title="Bienvenue sur le dashboard" 
                    strongText={process.env.REACT_APP_MODE_SPREAD !== "hub" ? "Connecteurs" : "Channels"}
                    subtitle="Petite phrase de description OU heure de Mise à jour" 
                    gradientColor1={colors.menu.regular} 
                    gradientColor2={colors.menu.darker} 
                    openForm={this.state.openForm}
                    hasBorder={true}
                    stats={process.env.REACT_APP_MODE_SPREAD !== "hub" ? stats : null}
                />
                <Grid container direction="column" justify="center" spacing={0} style={{marginTop: 24}}>
                    {
                        sliderData.length > 0 && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                            <Grid item xl={sliderData.length > 1 ? 12 : 6} lg={sliderData.length > 1 ? 12 : 8} md={sliderData.length > 1 ? 12 : 8} xs={12} style={{marginTop: 12}}>
                                <Typography variant="h4" style={{fontWeight: 'bold'}}>Slider d'actions</Typography>
                                <Grid container direction="row" style={{marginTop: 8, marginBottom: 18}}>
                                    <CardCustom contentpadding="16px 0 0 0">
                                        <SliderDashboard sliderData={sliderData} windowWidth={this.props.windowWidth}/>
                                    </CardCustom>
                                </Grid>
                            </Grid>
                        : null
                    }
                    {
                        this.state.channels.cms?.length > 0 && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                            <Grid item xs={12} style={{marginTop: 8}}>
                                <Typography variant="h4" style={{fontWeight: 'bold'}}>{process.env.REACT_APP_MODE_SPREAD !== "hub" ? "Connecteurs CMS" : "Boutiques"}</Typography>
                                <Grid container direction="row" style={{marginTop: 0}} spacing={2}>
                                    {
                                        this.state.channels.cms.map((cms, index) => {
                                            let type = {
                                                node: {
                                                    ...cms,
                                                    preferences: this.state.preferences?.[cms.id]
                                                }
                                            }
                                            return(
                                                <GridCustom item lg={4} md={6} xs={12} key={`ListCMS${index}`}>
                                                    <CardCustom paddingbottom={0} paddingtop={0} style={{width: "100%"}} contentpadding={'0 0px 10px 0px'}>
                                                        <CardSetup 
                                                            type={type} 
                                                            textButton={'Modifier'}
                                                            isDashboard={true} 
                                                            // handlerEdit={() => this.editChannel(cms.id, cms.attributeGroup.id)} 
                                                        />
                                                    </CardCustom>
                                                </GridCustom>
                                            )
                                        })
                                    }
                                    
                                </Grid>
                            </Grid>
                        : null
                    }
                    {
                        this.state.channels.connectors?.length > 0 && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                            <Grid item xs={12} style={{marginTop: 8}}>
                                <Typography variant="h4" style={{fontWeight: 'bold'}}>{process.env.REACT_APP_MODE_SPREAD !== "hub" ? "Connecteurs Flux" : "Logisticiens"}</Typography>
                                <Grid container direction="row" style={{marginTop: 0}} spacing={2}>
                                    {
                                        this.state.channels.connectors.map((connector, index) => {
                                            let type = {
                                                node: {
                                                    ...connector
                                                }
                                            }
                                            return(
                                                <GridCustom item lg={4} md={6} xs={12} key={`ListFlux${index}`}>
                                                    <CardCustom paddingbottom={0} paddingtop={0} style={{width: "100%"}} contentpadding={'0 0px 10px 0px'}>
                                                        <CardSetup 
                                                            type={type} 
                                                            textButton={'Modifier'} 
                                                            isDashboard={true} 
                                                            // handlerEdit={() => this.editChannel(connector.id, connector.attributeGroup.id)} 
                                                        />
                                                    </CardCustom>
                                                </GridCustom>
                                            )
                                        })
                                    }
                                    
                                </Grid>
                            </Grid>
                        : null
                    }
                    {
                        this.state.channels.contents?.length > 0 && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                            <Grid item xs={12} style={{marginTop: 8}}>
                                <Typography variant="h4" style={{fontWeight: 'bold'}}>Connecteurs Contenus</Typography>
                                <Grid container direction="row" style={{marginTop: 0}} spacing={2}>
                                    {
                                        this.state.channels.contents.map((content, index) => {
                                            let type = {
                                                node: {
                                                    ...content
                                                }
                                            }
                                            return(
                                                <GridCustom item lg={4} md={6} xs={12} key={`ListContents${index}`}>
                                                    <CardCustom paddingbottom={0} paddingtop={0} style={{width: "100%"}} contentpadding={'0 0px 10px 0px'}>
                                                        <CardSetup 
                                                            type={type} 
                                                            textButton={'Modifier'} 
                                                            isDashboard={true} 
                                                            // handlerEdit={() => this.editChannel(connector.id, connector.attributeGroup.id)} 
                                                        />
                                                    </CardCustom>
                                                </GridCustom>
                                            )
                                        })
                                    }
                                    
                                </Grid>
                            </Grid>
                        : null
                    }
                    {
                        this.state.channels.marketplaces?.length > 0 && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                            <Grid item xs={12} style={{marginTop: 8}}>
                                <Typography variant="h4" style={{fontWeight: 'bold'}}>Connecteurs Marketplaces</Typography>
                                <Grid container direction="row" style={{marginTop: 0}} spacing={2}>
                                    {
                                        this.state.channels.marketplaces.map((marketplace, index) => {
                                            let type = {
                                                node: {
                                                    ...marketplace,
                                                    preferences: this.state.preferences?.[marketplace.id]
                                                }
                                            }
                                            return(
                                                <GridCustom item lg={4} md={6} xs={12} key={`ListMarketplaces${index}`}>
                                                    <CardCustom paddingbottom={0} paddingtop={0} style={{width: "100%"}} contentpadding={'0 0px 10px 0px'}>
                                                        <CardSetup 
                                                            type={type} 
                                                            textButton={'Modifier'} 
                                                            isDashboard={true} 
                                                            // handlerEdit={() => this.editChannel(connector.id, connector.attributeGroup.id)} 
                                                        />
                                                    </CardCustom>
                                                </GridCustom>
                                            )
                                        })
                                    }
                                    
                                </Grid>
                            </Grid>
                        : null
                    }
                    {
                        this.state.channels.medias?.length > 0 && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                            <Grid item xs={12} style={{marginTop: 8}}>
                                <Typography variant="h4" style={{fontWeight: 'bold'}}>Connecteurs Medias</Typography>
                                <Grid container direction="row" style={{marginTop: 0}} spacing={2}>
                                    {
                                        this.state.channels.medias.map((media, index) => {
                                            let type = {
                                                node: {
                                                    ...media
                                                }
                                            }
                                            return(
                                                <GridCustom item lg={4} md={6} xs={12} key={`ListMedias${index}`}>
                                                    <CardCustom paddingbottom={0} paddingtop={0} style={{width: "100%"}} contentpadding={'0 0px 10px 0px'}>
                                                        <CardSetup 
                                                            type={type} 
                                                            textButton={'Modifier'} 
                                                            isDashboard={true} 
                                                            // handlerEdit={() => this.editChannel(connector.id, connector.attributeGroup.id)} 
                                                        />
                                                    </CardCustom>
                                                </GridCustom>
                                            )
                                        })
                                    }
                                    
                                </Grid>
                            </Grid>
                        : null
                    }
                    {
                        this.state.channels.newsletters?.length > 0 && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                            <Grid item xs={12} style={{marginTop: 8}}>
                                <Typography variant="h4" style={{fontWeight: 'bold'}}>Connecteurs Emails</Typography>
                                <Grid container direction="row" style={{marginTop: 0}} spacing={2}>
                                    {
                                        this.state.channels.newsletters.map((newsletter, index) => {
                                            let type = {
                                                node: {
                                                    ...newsletter
                                                }
                                            }
                                            return(
                                                <GridCustom item lg={4} md={6} xs={12} key={`ListFlux${index}`}>
                                                    <CardCustom paddingbottom={0} paddingtop={0} style={{width: "100%"}} contentpadding={'0 0px 10px 0px'}>
                                                        <CardSetup 
                                                            type={type} 
                                                            textButton={'Modifier'} 
                                                            isDashboard={true} 
                                                            // handlerEdit={() => this.editChannel(connector.id, connector.attributeGroup.id)} 
                                                        />
                                                    </CardCustom>
                                                </GridCustom>
                                            )
                                        })
                                    }
                                    
                                </Grid>
                            </Grid>
                        : null
                    }
                    {
                        this.state.channels.retailers?.length > 0 && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                            <Grid item xs={12} style={{marginTop: 8}}>
                                <Typography variant="h4" style={{fontWeight: 'bold'}}>{process.env.REACT_APP_MODE_SPREAD !== "hub" ? "Connecteurs Retailers" : "Entrepôts / Usines / ERP"}</Typography>
                                <Grid container direction="row" style={{marginTop: 0}} spacing={2}>
                                    {
                                        this.state.channels.retailers.map((retailer, index) => {
                                            let type = {
                                                node: {
                                                    ...retailer
                                                }
                                            }
                                            return(
                                                <GridCustom item lg={4} md={6} xs={12} key={`ListFlux${index}`}>
                                                    <CardCustom paddingbottom={0} paddingtop={0} style={{width: "100%"}} contentpadding={'0 0px 10px 0px'}>
                                                        <CardSetup 
                                                            type={type} 
                                                            textButton={'Modifier'} 
                                                            isDashboard={true} 
                                                            isRetailer={true}
                                                            cronListOpening={this.getListCronByRetailer}
                                                            // handlerEdit={() => this.editChannel(connector.id, connector.attributeGroup.id)} 
                                                        />
                                                    </CardCustom>
                                                </GridCustom>
                                            )
                                        })
                                    }
                                    
                                </Grid>
                            </Grid>
                        : null
                    }
                    <CronNotifications closeDrawer={()=> this.setState({drawerCron: false})} drawerCron={this.state.drawerCron} retailerCron={this.state.retailerCron} listCrons={this.state.listCrons} />
                    {
                        this.state.channels.socials?.length > 0 && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                            <Grid item xs={12} style={{marginTop: 8}}>
                                <Typography variant="h4" style={{fontWeight: 'bold'}}>Connecteurs Réseaux Sociaux</Typography>
                                <Grid container direction="row" style={{marginTop: 0}} spacing={2}>
                                    {
                                        this.state.channels.socials.map((social, index) => {
                                            let type = {
                                                node: {
                                                    ...social
                                                }
                                            }
                                            return(
                                                <GridCustom item lg={4} md={6} xs={12} key={`ListFlux${index}`}>
                                                    <CardCustom paddingbottom={0} paddingtop={0} style={{width: "100%"}} contentpadding={'0 0px 10px 0px'}>
                                                        <CardSetup 
                                                            type={type} 
                                                            textButton={'Modifier'} 
                                                            isDashboard={true} 
                                                            // handlerEdit={() => this.editChannel(connector.id, connector.attributeGroup.id)} 
                                                        />
                                                    </CardCustom>
                                                </GridCustom>
                                            )
                                        })
                                    }
                                    
                                </Grid>
                            </Grid>
                        : null
                    }
                    <Grid item xs={12} style={{marginTop: 8}}>
                        <Grid container direction="row" style={{marginTop: 0}} spacing={2}>
                            <ImportHistory used="dashboard" />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        // startLoading: () => dispatch({ type: START_LOADING }),
        // stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(DashboardChannels)));
