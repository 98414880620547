import {
    withStyles
} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React from "react";

const Custom = withStyles((theme) => ({
    root: {
        '&': {
            width: '100%',
            color: '#fff',
            marginBottom: theme.spacing(2),
            backgroundColor: '#fff',
            marginBottom: 20,
            borderRadius: 0,
            '& > div':{
                borderRadius: 0
            }
        },
    },
}))(TextField);

export default (props) => {
    return (
        <>
            <Typography gutterBottom style={{fontSize: 14}}>
                { props.label }
            </Typography>
            <Custom {...props} label={false} variant="outlined" />
        </>
    );
};