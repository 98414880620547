import { v4 as uuidv4 } from "uuid";
import { FormInputTypes, EditableTypes } from '../../../shareable/types';
import { getDefaultText, getDefaultTextarea, getImageDefault, getDefaultButton } from '../../helpers';

export default {
    name: "Section du récapitulatif",
    inputs:{
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#F0F7FA'
        },
        backgroundImage: {
            label: 'Image de fond',
            type: FormInputTypes.IMAGE,
            value: null
        },
    },
    blocks: {
        explanation: {
            id: uuidv4(),
            name: "Bloc récapitulatif",
            type: EditableTypes.MINI_BLOCKS,
            inputs: {
                backgroundColor: {
                    label: 'Couleur de fond',
                    type: FormInputTypes.COLOR,
                    value: '#fff'
                },
                backgroundImage: {
                    label: 'Image de fond',
                    type: FormInputTypes.IMAGE,
                    value: null
                },
                border: {
                    type: FormInputTypes.GROUP,
                    label: "Border",
                    inputs: {
                        borderColor: {
                            type: FormInputTypes.COLOR,
                            value: '#53839D',
                            label: "Couleur de la bordure"
                        },
                        borderWidth: {
                            type: FormInputTypes.SLIDER,
                            value: 0,
                            label: "Largeur de la bordure :",
                            params: {max: 5, min: 0, step: 1}
                        },
                        borderRadius: {
                            type: FormInputTypes.SLIDER,
                            value: 0,
                            label: "Arrondi des angles :",
                            params: {max: 50, min: 0, step: 1}
                        }
                    }
                },
            },
            blocks:{
                title: getDefaultText("Titre", "Félicitations !", 35, "#53839D", 'rgba(255,255,255,0)'),
                subtitle: getDefaultTextarea("Sous-titre", "Entrez votre adresse e-mail pour valider votre liste et votre inscription au tirage au sort", true, "#53839D", "rgba(0,0,0,0)", 'initial', 'center', 18),
                button: getDefaultButton("Boutton", "Participer", 20, '#fff', '#53839D', 0, '#fff', 0),
                labelConsent: getDefaultText("Libellé de consentement", "J'accepte le réglement du jeu", 20, '#53839D', null, 'initial', 'initial'),
                conditions: getDefaultTextarea("Conditions du jeu", "Conditions du jeu", true, "#53839D", "rgba(0,0,0,0)", 'initial', 'initial', 12),
            }
        },
        recap: {
            id: uuidv4(),
            name: "Récapitulatif de la liste",
            type: EditableTypes.MINI_BLOCKS,
            inputs: {
                backgroundColor: {
                    label: 'Couleur de fond',
                    type: FormInputTypes.COLOR,
                    value: '#fff'
                },
                backgroundImage: {
                    label: 'Image de fond',
                    type: FormInputTypes.IMAGE,
                    value: null
                },
            },
            blocks:{
                titleRecap: getDefaultText("Titre", "Votre liste :", 35, "#53839D", 'rgba(255,255,255,0)'),
            }
        },
    }
};
