import React from 'react';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { ROUTE_CRM_CONTACTS_ADD, ROUTE_CRM_CONTACTS_CLIENTS, ROUTE_CRM_CONTACTS_LEADS, ROUTE_CRM_CONTACTS_LIST, ROUTE_HOME } from '../../../../js/constants/route-names';
import colors from '../../../../config/theme/colors';
import { withApollo } from 'react-apollo';
import { GET_CUSTOMERS, GET_CUSTOMERS_CURSOR, SEARCH_CUSTOMERS } from '../../../../queries/customers';
import { SEARCH_COMPANIES_CUSTOMERS } from '../../../../queries/companies';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import importCustomersTypesConfig from './config/importCustomersTypes.config';
import importCustomersConfig from './config/importCustomers.config';
import importFichier from '../../../../assets/pictos/icon-import-fichier.svg';
import importAPI from '../../../../assets/pictos/icon-import-api.svg';
import importFlux from '../../../../assets/pictos/icon-import-flux.svg';
import request from '../../../../js/utils/fetch';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_SUCCESS, ALERT_ERROR } from '../../../../js/constants/alert-types';
import { eventService } from '../../../../js/services/event.service';
import ContactList from '../contacts/components/ContactList';
import TraductionSelect from '../../../layouts/TopPanel/TraductionSelect';
import EmptyCard from "../../../ui/empty-card/EmptyCard";
import emptyProduct from '../../../../assets/pictos/empty-picto/product.svg';
import CardCustom from '../../../layouts/Card/CardCustom';

import { Grid, Tooltip, TextField} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
// import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import clsx from 'clsx';
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { hasRights } from '../../../../js/utils/rights';
import { CRM_CONTACT, CRM_CONTACT_CLIENTS, CRM_CONTACT_CONTACTS, CRM_CONTACT_LEADS, CREATE, IMPORT, VIEW} from '../../../../js/constants/constant-rights';

const ViewModuleIconCustom = styled(ViewModuleIcon)`
    fill: ${colors.grey.regular};
    cursor: pointer;
    &.active{
        fill: ${colors.blue.darker.hue300};
        cursor: inherit;
    }
`;
const ViewListIconCustom = styled(ViewListIcon)`
    fill: ${colors.grey.regular};
    cursor: pointer;
    &.active{
        fill: ${colors.blue.darker.hue300};
        cursor: inherit;
    }
`;
const TextFieldCustom = styled(TextField)`
    input, .MuiSelect-select{
        font-size: 16px;
        padding: 8px;
    }
    & > p{
        margin: 0;
    }
`;

const TableCellCustom = styled(TableCell)`
    border: none;
    display: inline-block;
    width: calc(100% / 9);
    padding: 10px 16px;
    svg{
        display: none;
    }
`


const styles = theme => ({
});

const useStylesBootstrap = makeStyles(theme => ({
    arrow: {
        color: colors.blue.darker.hue300,
    },
    tooltip: {
        backgroundColor: colors.blue.darker.hue300,
        fontSize: 14,
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

class ListCustomers extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            currentLang: props.locales[0].node.code,
            openForm: false,
            openFormImport: false,
            catalogFormData : {},
            page: 1,
            nbperpageCustomers: 6,
            countPage: 0,
            cursor: null,
            skulist: [],
            searchValue: '',
            arrayCatFilter: [],
            currentStatus : props.typeContact,
            isEmpty: false,
            viewMode: 'card',
        }
        this.handleChange = this.handleChange.bind(this);
        this.typingSearchTimer = null;
    }

    handleInitData = (typeContact) => {
        let content;
        let showTab = false;
        switch(typeContact){
            case 'client':
                content = {
                    title: "Clients",
                    subtitle: "Gestion de vos clients (création / modification / suppression)",
                    picto: "icon-clients",
                    textAdd: "Ajouter un client",
                    textImport: "Importer des clients",
                    route: ROUTE_CRM_CONTACTS_CLIENTS
                };
                importCustomersConfig.titleForm = 'Importer des clients';
                importCustomersConfig.subTitleForm = 'Veuillez compléter les champs ci-dessous pour importer vos clients';
            break;
            case 'lead':
                content = {
                    title: "Leads",
                    subtitle: "Gestion de vos leads (création / modification / suppression)",
                    picto: "icon-leads",
                    textAdd: "Ajouter un lead",
                    textImport: "Importer des leads",
                    route: ROUTE_CRM_CONTACTS_LEADS
                };
                importCustomersConfig.titleForm = 'Importer des leads';
                importCustomersConfig.subTitleForm = 'Veuillez compléter les champs ci-dessous pour importer vos leads';
            break;

            case 'contact':
                content = {
                    title: "Contacts",
                    subtitle: "Gestion de vos contacts (création / modification / suppression)",
                    picto: "icon-contacts",
                    textAdd: "Ajouter un contact",
                    textImport: "Importer des contacts",
                    route :ROUTE_CRM_CONTACTS_LIST
                };
                importCustomersConfig.titleForm = 'Importer des contacts';
                importCustomersConfig.subTitleForm = 'Veuillez compléter les champs ci-dessous pour importer vos contacts';
                break;
            default :
                return null;
        }
        this.setState({
            showTab: showTab,
            content: content,
        })
        this.handleGetCustomers();
    }

    handleRecursiveCat = (id) => {
        for(let categorie of this.state.categories) {
            if(categorie.id === id) {
                if(categorie.parent) {
                    this.handleRecursiveCat(categorie.parent.id)
                    this.state.activeCategorie.push(` > ${categorie.libelle}`);
                } else {
                    this.state.activeCategorie.push('Home');
                }
            }
        }
    };

    handleSearch = () => {
        let listID=[];
        let value = this.state.searchValue;
        if (value !== "") {
            this.props.client.query({
                query: SEARCH_CUSTOMERS,
                variables: {"needle": value,"attribute":null},
                fetchPolicy: 'no-cache'
            }).then(result =>{
                let customers = result.data.customerDatas.edges.map(
                    e=> {
                        return parseInt(e.node.customer.id.replace('/api/customers/',''),10)
                    }
                );
                customers.filter(customer=>{
                    if(!listID.includes(customer)){
                        listID.push(customer);
                        return listID;
                    }else{
                        return listID;
                    }
                });

                this.props.client.query({
                    query: SEARCH_COMPANIES_CUSTOMERS,
                    variables: {"needle": value,"attribute":null},
                    fetchPolicy: 'no-cache'
                }).then(resultSearch => {
                    for(let company of resultSearch.data.companyDatas.edges){
                        if (company.node.company.customers.edges.length > 0){
                            for (let customerData of company.node.company.customers.edges){
                                let customerId = parseInt(customerData.node.id.replace('/api/customers/',''),10)
                                if(!listID.includes(customerId)){
                                    listID.push(customerId);
                                    // return customerId;
                                }
                            }
                        }
                    }
                    this.setState({
                        activeSearch :listID
                    }, () => this.handleGetCustomers())
                })
            })
        }
    }
    handleChange(e, value) {
        this.setState({
            currentPage : 1,
            customerIsReady:false,
        })
        clearTimeout(this.typingSearchTimer);
        if(e?.target?.value){
            this.setState({searchValue: e.target.value});
            this.typingSearchTimer = setTimeout(() => {
                this.handleSearch();
            }, 500);
        } 
        else{
            this.setState({activeSearch:false},()=>this.handleGetCustomers())
        }
    }

    getChildrenCat = (id) => {
        for(let categorie of this.state.categories) {
            if(categorie.parent?.id === id) {
                let id2 = categorie.id.replace('/api/categories', '');
                    id2 = id2.replace('/', '');
                    id2 = parseInt(id2);

                this.state.arrayCatFilter.push(id2);
                this.getChildrenCat(categorie.id);
            }
        }
    }

    handleGetCursorsCustomers= () =>{
        return new Promise((resolve) =>{
            this.props.client.query({
                query: GET_CUSTOMERS_CURSOR,
                fetchPolicy: 'no-cache'
            }).then(result => {
                
                this.setState({
                    listCursorsCustomers: result.data.customers.edges,
                    loading: this.state.loading,
                });
            });
            resolve();
        });
    }

    handleGetCustomers() {
        return new Promise((resolve, reject) => {
            let variables = 
            {
                "nbperpage": this.state.nbperpageCustomers, 
                status : this.state.currentStatus
            };
            if(this.state.cursor && this.state.listCursorsCustomers !== this.state.listCursorsCustomers[0].cursor){
                variables.cursor = this.state.cursor;
            }

            if(this.state.activeSearch){
                variables.id_list = this.state.activeSearch;
            }
            this.props.client.query({
                query: GET_CUSTOMERS,
                variables,
                fetchPolicy: 'no-cache'
            }).then(result =>{
                let countPage;
                countPage = Math.ceil(result.data.customers.totalCount / this.state.nbperpageCustomers)
                this.listCustomers = result.data.customers.edges;  
                
                this.setState({
                    listCustomers: this.listCustomers,
                    allCustomers : this.allCustomers,
                    currentCustomers : this.currentCustomers,
                    countPageCustomers: countPage,
                    customerIsReady : true,
                    isEmpty: result.data.customers.edges.length === 0 ? true : false,
                });
                this.props.stopLoading();
                resolve();
            });
        });
    }

    handleCategory = (category)=>{
        this.setState({
            customerIsReady: false,
            currentStatus: category,
            currentPage: 1,
            cursor : null
        },()=>{
            this.handleGetCustomers();
        })
    };

    checkAccess() {
        let type = null;
        switch(this.state.currentStatus){
            case 'contact' :
                type = CRM_CONTACT_CONTACTS;
            break;
            case 'client' :
                type = CRM_CONTACT_CLIENTS;
            break;
            case 'lead' :
                type = CRM_CONTACT_LEADS;
            break;
            default :
                return null;
        }
        const getRights = hasRights(CRM_CONTACT, type, VIEW)
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }else{
            this.setState({
                accessType: type,
            })
        }
    }

    componentDidMount() {   
        this.checkAccess();
        this.getAll();
        if(this.props.location.state?.hasMessage){
            this.props.snack(ALERT_SUCCESS,this.props.location.state.message)
        }   
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.typeContact !== prevProps.typeContact){
            this.setState({
                customerIsReady: false,
                currentStatus: this.props.typeContact,
                openFormImport: false,
                openForm: false,
                page: 1,
                currentPage: 1,
                countPage: 0,
                cursor: null,
            }, 
                ()=>{
                    this.checkAccess();
                    this.handleGetCustomers();
                    this.handleInitData(this.props.typeContact);
            })
        }
    }

    async getAll() {
        await this.handleInitData(this.state.currentStatus);
        await this.handleGetCursorsCustomers();
        await this.handleGetCustomers();
    }

    handleToggleDrawer = (stateDrawer) => {
        this.setState({ 
            [stateDrawer] : !this.state[stateDrawer]
        });
    };

    handleFormImport = (type, title) => {
        this.setState({
            catalogFormData: {
                type,
                title
            },
            mapper: [],
            media: null,
            headers: null,
            importFile: null,
            importSep: ';',
            importValues: {},
            importLang: this.props.locales[0].node.id
        });

        this.handleToggleDrawer('openFormImport');
    };

    handleLang = (event) => {
        this.setState({ currentLang: event.target.value }, () => {
            eventService.fire();

            this.forceUpdate();
        });
    };

    handlerMutation = async () => {
        this.props.startLoading();

        let importConfig = {
            "url": `${process.env.REACT_APP_API_ROOT}${this.state.media?.contentUrl ?? null}`,
            "mapper": this.state.mapper,
            "eavType": this.props.attributes.eavTypes.find(e => e.node.code === 'customer').node.id,
            "locale": this.state.importLang,
            "delimiter":this.state.importSep
        };

        try {
            await request(`${process.env.REACT_APP_API_ROOT}/api/file-import-crms`, 'post', importConfig, undefined, true);
            this.props.snack(ALERT_SUCCESS, `Votre fichier a été importé avec succès, son intégration dans SpreadSuite sera exécutée lors du passage du CRON (Tâche automatique).`,6000);
            this.getAll();
            this.handleToggleDrawer('openForm');
            this.handleToggleDrawer('openFormImport');
            this.props.stopLoading();
        } catch(e) {
            this.props.snack(ALERT_ERROR, `L'import a échoué !`);
            this.props.stopLoading();
        }
    };

    stateCallback = (stateName, value, custom, translated, callback) => {
        this.setState({
            [stateName]: value?.target?.value ?? value
        }, callback);
    };

    changePage = (event, page) => {
        let index = 0;
        let status = this.state.currentStatus;
        if(page > 1){
            index = ( page * this.state.nbperpageCustomers ) - this.state.nbperpageCustomers - 1
            // alert(index)
        }
        this.setState({
            customerIsReady:false,
            cursor: this.state.listCursorsCustomers[index].cursor,
            currentPage: page,
        },()=>{
            this.handleGetCustomers(status);
        })
    };

    changeViewMode = (mode) => {
        this.props.startLoading();
        if (mode === "list"){
            this.setState({
                nbperpageCustomers : this.state.nbperpageCustomers === 6 || 18 || 30 ? 10 : this.state.nbperpageCustomers,
                viewMode : 'list',
                page: 1,
                cursor: null,
            }, () => this.handleGetCustomers())
        }else{
            this.setState({
                nbperpageCustomers : 6,
                viewMode : 'card',
                page: 1,
                cursor: null,
            }, () => this.handleGetCustomers())
        }
    }

    handleChangePerPage = (event) => {
        this.setState({
            nbperpageCustomers: event.target.value
        }, this.changeViewMode(this.state.viewMode))
    }

    render() {
        const headCells = [
            { id: 'image', alignement: true, style: {width: 'calc((100%/9) * 1.5)'}, disablePadding: true, label: 'Image' },
            { id: 'nom', alignement: false, style: {width: 'calc((100%/9) * 1.5)'}, disablePadding: false, label: 'Prénom & Nom' },
            { id: 'phone', alignement: false, style: null, disablePadding: false, label: 'Téléphone' },
            { id: 'email', alignement: false, style: {width: 'calc((100%/9) * 1.5)'}, disablePadding: false, label: 'Email' },
            { id: 'societies', alignement: true, style: {width: 'calc((100%/9) * 2)'}, disablePadding: false, label: 'Société(s)' },
            { id: 'button', alignement: false, style: {width: 'calc((100%/9) * 1.5)'}, disablePadding: false, label: '' },
        ];
        let productBypage = [];
        this.state.viewMode === 'list' ? productBypage = [10,20,50] : productBypage = [6,18,30];
        const { classes } = this.props;
        return (
            <div>
                {this.state.content ? (
                    <TopPanel 
                        icomoon={this.state.content.picto}
                        colorIcomoon={colors.blue.lighter.hue300}
                        title={this.state.content.title}
                        subtitle={this.state.content.subtitle}
                        handlerAdd={() => this.goTo(ROUTE_CRM_CONTACTS_ADD,this.state.currentStatus,'typeContact')} 
                        textAdd={hasRights(CRM_CONTACT, this.state.accessType, CREATE) ? this.state.content.textAdd : null}
                        handlerImport={() => this.handleToggleDrawer('openForm')} 
                        textImport={hasRights(CRM_CONTACT, this.state.accessType, IMPORT) ? this.state.content.textImport : null}
                        searchHandler={this.handleChange} 
                        gradientColor1={colors.menu.regular} 
                        gradientColor2={colors.menu.darker}
                        windowWidth={this.props.windowWidth}
                        openForm={this.state.openForm}
                        buttonAvailable={this.state.customerIsReady}
                        // currentLang={this.state.currentLang} 
                        // handleLang={this.handleLang} 
                        // locales={this.props.locales}
                        hasBorder={true}
                    />
                    ) : '' 
                }
                <TraductionSelect 
                    currentLang={this.state.currentLang} 
                    handleLang={this.handleLang} 
                    locales={this.props.locales}
                />
                {
                    this.state.customerIsReady ? 
                        <Grid container justify="flex-end" alignItems="center">
                            <BootstrapTooltip title="Vue carte" className={clsx(classes.tooltip)}>
                                <ViewModuleIconCustom 
                                style={{ fontSize: 30 }} 
                                className={this.state.viewMode === "card" ? 'active': null}
                                onClick={this.state.viewMode === "card" ? null : () => this.changeViewMode('card')}
                                />
                            </BootstrapTooltip>
                            <BootstrapTooltip title="Vue liste" className={clsx(classes.tooltip)}>
                                <ViewListIconCustom 
                                style={{ fontSize: 30, marginLeft: 8 }} 
                                className={this.state.viewMode === "list" ? 'active': null}
                                onClick={this.state.viewMode === "list" ? null : () => this.changeViewMode('list')}
                                />
                            </BootstrapTooltip>
                            {
                                <FormControl style={{width: 60, marginLeft: 8}}>
                                        <TextFieldCustom
                                            id='product-by-page'
                                            variant="outlined"
                                            color="secondary"
                                            select
                                            fullWidth
                                            value={this.state.nbperpageCustomers}
                                            onChange={this.handleChangePerPage}
                                            >
                                            {productBypage.map((pages, index) =>{
                                                return(
                                                    <MenuItem value={pages} key={`MenuItem${index}`}>{pages}</MenuItem>
                                                )
                                            })}
                                        </TextFieldCustom>
                                </FormControl>
                            }
                            {/* <BootstrapTooltip title="Click to export products" className={clsx(classes.tooltip)}>
                                <GetAppOutlinedIcon 
                                style={{ fontSize: 30, marginLeft: 8, cursor:'pointer', fill: colors.blue.darker.hue300 }} 
                                onClick={() => this.handleToggleDrawer('openFormExport')}
                                />
                            </BootstrapTooltip> */}
                        </Grid>
                    : null
                }
                
                {
                    this.state.viewMode === "list" ? 
                        <Grid container direction="column" justify="center" spacing={0} style={{marginTop: '1.5rem'}}>
                            <CardCustom style={{width: "100%", height: "100%",  padding: "0"}} cardContentStyle={{height: "100%"}} contentpadding={'0'} hovercard={false}>
                                <TableContainer>
                                    <Table aria-label="headerList">
                                        <TableHead>
                                            <TableRow>
                                                {headCells.map((headCell) => (
                                                    <TableCellCustom
                                                    key={headCell.id}
                                                    align={headCell.alignement ? 'center' : 'left'}
                                                    style={{width: `${headCell.style?.width}`}}
                                                    // sortDirection={orderBy === headCell.id ? order : false}
                                                    >
                                                        <TableSortLabel
                                                        //   active={orderBy === headCell.id}
                                                        //   direction={orderBy === headCell.id ? order : 'asc'}
                                                        //   onClick={createSortHandler(headCell.id)}
                                                        >
                                                        {headCell.label}
                                                        {/* {orderBy === headCell.id ? (
                                                            <span className={classes.visuallyHidden}>
                                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                            </span>
                                                        ) : null} */}
                                                        </TableSortLabel>
                                                    </TableCellCustom>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                </TableContainer>
                            </CardCustom>
                        </Grid>
                    : null
                }

                { (this.state.isEmpty) ?  
                    <EmptyCard title={"Aucun résultat trouvé"} subtitle={hasRights(CRM_CONTACT, this.state.accessType, CREATE) ? "Ajouter un contact en cliquant ci-dessous" : "Faite une demande auprès d'un administrateur"} textButton={hasRights(CRM_CONTACT, this.state.accessType, CREATE) ? "Ajouter un contact" : null} onClick={() => this.goTo(ROUTE_CRM_CONTACTS_ADD)} picto={emptyProduct} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />
                :
                    <ContactList
                        handleCategory={this.handleCategory}
                        showTab={this.state.showTab}
                        items={this.state.listCustomers}
                        isReady={this.state.customerIsReady}
                        currentStatus={this.state.currentStatus}
                        select={false}
                        handleSelect={()=>{alert('on selectionne')}}
                        pagination={true}
                        paginationSettings = {{'page':this.state.currentPage ? this.state.currentPage:1,'count':this.state.countPageCustomers,'changePage' : this.changePage}}
                        currentLang={this.state.currentLang}
                        search={false}
                        isCard={this.state.viewMode === 'card' ? true : false}
                        // handleSearch={this.handleSearch}
                    />
                }
                
                <LayoutBuilder 
                    opened={this.state.openForm} 
                    forClose={() => this.handleToggleDrawer('openForm')} 
                    dataLayout={importCustomersTypesConfig} 
                    drawerWidth={this.props.drawerWidth} 
                    dataCard={[
                        {
                            'libelle': 'Importer via un',
                            'bicoloreText': 'flux',
                            'colorhover': '#6EAED1',
                            'picto': importFlux,
                            'disabled': true,
                            'textButton': 'Importer',
                            'description': 'Votre import contacts facilité en renseignant simplement votre flux', 
                            'catalogDescription' : 'Veuillez compléter les champs ci-dessous',
                            'onClick': () => this.handleFormImport(null, 'flux')
                        },
                        {
                            'libelle': 'Importer via une',
                            'bicoloreText': 'API',
                            'colorhover': '#6EAED1',
                            'picto': importAPI,
                            'disabled': true,
                            'textButton': 'Importer',
                            'description': 'Votre import contacts facilité en renseignant simplement votre API', 
                            'catalogDescription': 'Veuillez compléter les champs ci-dessous',
                            'onClick': () => this.handleFormImport(null, 'api')
                        },
                        {
                            'libelle': 'Importer un',
                            'bicoloreText': 'fichier',
                            'colorhover': '#6EAED1',
                            'picto': importFichier,
                            'disabled': false,
                            'textButton': 'Importer',
                            'description': 'Votre import contacts facilité en important simplement votre fichier', 
                            'catalogDescription': 'Veuillez compléter les champs ci-dessous',
                            'onClick': () => this.handleFormImport(null, 'fichier')
                        }
                    ]}
                />

                <LayoutBuilder 
                    opened={this.state.openFormImport} 
                    icomoon={'ico-import-fichier'}
                    forClose={() => this.handleToggleDrawer('openFormImport')}  
                    dataLayout={importCustomersConfig} 
                    drawerWidth={this.state.drawerWidth}
                    handleCancel={this.handleCancel}
                    handlerMutation={this.handlerMutation} 
                    allState={this.state} 
                    stateCallback={this.stateCallback}
                    stepperButtonDisabled={[() => this.state.headers === null, null]}
                    stepperButtonAction={[null, null]}
                    backStepperButtonAction={[null, null, null]}
                />      

            </div>
        );
    }

    goTo = (route, id,target) => {
        let variables = {};
        variables[target] = id ;
        this.props.history.push({
            pathname : route,
            state: variables
        });
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
        attributes: state.attributes
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};

export default withApollo(withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ListCustomers))));
