import PropTypes from "prop-types";
import React from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";

const excludedIdentifiers = ['product_name', 'product_description', 'product_image', 'product_metatitle', 'product_metadesc', 'product_price'];

const ProductDescriptionTab = ({ spaceBottomClass, product, attributes, data, params }) => {
  let allNames = product.attributes.product_name;
  let name = allNames?.['fr_FR'] ?? 'n/a';

  let allDesc = product.attributes.product_description;
  let desc = allDesc?.['fr_FR'] ?? 'n/a';

  let allPrices = product.attributes.product_price;
  let price = allPrices?.['fr_FR'] ?? 'n/a';

  let attrs = []

  for (let attribute in product.attributes) {
    if (excludedIdentifiers.indexOf(attribute) > -1)
      continue;

    attrs.push({
      key: attribute,
      value: product.attributes[attribute]['fr_FR']
    });
  }

  return (
    <div className="builder-template-scope">
      <div className={`description-review-area ${spaceBottomClass}`}>
        <div className="container">
          <div className="description-review-wrapper">
            <Tab.Container defaultActiveKey="productDescription">
              <Nav variant="pills" className="description-review-topbar">
                <Nav.Item>
                  <Nav.Link eventKey="additionalInfo">
                    {data.inputs['moreInfo'].value}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="productDescription">
                    {data.inputs['description'].value}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content className="description-review-bottom">
                <Tab.Pane eventKey="additionalInfo">
                  <div className="product-anotherinfo-wrapper">
                    <ul>
                      {/* {
                          filteredAttributes.map((attribute, i) => {
                            let name  = attribute.node.translation.translationDatas.edges[0].node.value;
                            let data  = attrs.find(e => e.key === attribute.node.identifier)?.value ?? 'n/a';

                            return (
                              <li>
                                <span>{ name }</span> { data }
                              </li>
                            )
                          })
                      } */}

                      {
                        attrs.map((attribute, i) => {
                          let name = attributes?.[attribute.key]?.['fr_FR'] ?? attribute.key;

                          return (
                            <li key={i}>
                              <span>{name}</span> <span dangerouslySetInnerHTML={{ __html: attribute.value }} />
                            </li>
                          )
                        })
                      }
                    </ul>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="productDescription">
                  <div dangerouslySetInnerHTML={{ __html: desc }}></div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </div>
    </div>
  );
};

ProductDescriptionTab.propTypes = {
  productFullDesc: PropTypes.string,
  spaceBottomClass: PropTypes.string
};

export default ProductDescriptionTab;
