import React, { Component } from 'react';
import styled from 'styled-components';
import { Grid, Tooltip, TextField, Typography} from '@material-ui/core';
import request from '../../../js/utils/fetch';
import CardCustom from '../../layouts/Card/CardCustom';
import { GET_CATEGORIES_LIGHT } from '../../../queries/categories';
import { withApollo } from 'react-apollo';
import colors from '../../../config/theme/colors';
import PageLoader from '../loadings/page-loader/PageLoader';

const CategorieName = styled(Typography)`
    cursor: pointer;
    &:hover{
        color: ${colors.blue.lighter.hue300};
    }
`
const BackCategorie = styled(Typography)`
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
`

class TreeCategories extends Component {

    constructor(props){
        super(props);
        this.state = {
            categories: null,
            ready: false,
        }
    }

    processChildren = async (categories, parent, allIdentifiers) => {

        parent.children = categories.filter(e => e.parent?.id === parent?.id);
        for (let child of parent.children) {
            await this.processChildren(categories, child);
        }
    };

    handleGetCategories() {
        this.props.client.query({
            query: this.props.query,
            variables: this.props.variablesQuery,
            fetchPolicy: "no-cache"
        }).then((result) => {
            let allCategories = result.data[this.props.resultVar];
            let root = this.props.isCatalog ? allCategories[0] : allCategories?.find(e => e.parent === null);
            if (allCategories.length > 0 ){
                this.processChildren(allCategories, root);
            }
            this.setState({
                categories : allCategories,
                activeCategorie : root,
                ready : true,
                root,
            })
        })
    }

    async componentDidMount(){
        await this.handleGetCategories()
    }

    handleChangeCat(newCat, parentCat){
        let newCategorie = null
        if (parentCat){
            newCategorie = this.state.categories?.find(cat => cat.id === parentCat?.parent?.id)
        }else {
            newCategorie = newCat
        }
        this.setState({
            lastCategorie : this.state.activeCategorie,
            activeCategorie : newCategorie
        })
        this.props.callbackCategorie(newCategorie.id !== this.state.root.id ? newCategorie.id.replace('/api/categories/', '') : null)
    }

    render() {
        let categoryType = [];
        switch(this.props.typeCategory){
            case "product":
                categoryType = "categoryDatas";
                break;
            case "content":
                categoryType = "contentCategoryDatas";
                break;
        }
        let getCategorie = this.state.categories?.find(cat => cat.id === this.state.activeCategorie.id)
        let lastCat = this.state.categories?.find(cat => cat.id === this.state.activeCategorie?.parent?.id)
        let lastCatName = null
        if (lastCat){
            let filterLast = lastCat[categoryType]?.edges.filter(e => e.node.attribute.identifier === "category_name");
            if (filterLast.length > 0){
                if (filterLast?.find(e => e.node.locale.code === this.props.currentLang)){
                    lastCatName = filterLast?.find(e => e.node.locale.code === this.props.currentLang)?.node.value
                }else{
                    lastCatName = lastCat.libelle
                }
            }else{
                lastCatName = lastCat.libelle
            }
        }
        let activeCatName = this.state.activeCategorie?.[categoryType]?.edges.filter(e => e.node.attribute.identifier === "category_name");
        if (activeCatName?.find(e => e.node.locale.code === this.props.currentLang)){
            activeCatName = activeCatName?.find(e => e.node.locale.code === this.props.currentLang)?.node.value
        }else{
            activeCatName = this.state.activeCategorie?.libelle
        }
        return (
            <CardCustom style={{position: 'sticky',top: 100, maxHeight: "45vh", overflow: "auto"}}>
                <Grid container>
                    {
                        this.state.root?.id === this.state.activeCategorie?.id ?
                            <Typography variant="h3" style={{marginBottom: 8}}>Catégories</Typography>
                        : 
                            <Grid container direction="column">
                                <BackCategorie variant="h5" onClick={() => this.handleChangeCat(null, this.state.activeCategorie)}> &lt; {this.state.activeCategorie?.parent?.id === this.state.root.id ? "Catégories" : lastCatName}</BackCategorie>
                                <Typography variant="h3" style={{marginTop: 8}}>{activeCatName}</Typography>
                            </Grid>
                    }
                    <Grid container direction="column" >
                        {
                            this.state.ready ?
                                getCategorie?.children.length > 0 ?
                                    getCategorie?.children.map((cat, index) => {
                                        let catName = cat[categoryType]?.edges.filter(e => e.node.attribute.identifier === "category_name");
                                        catName = catName?.find(e => e.node.locale.code === this.props.currentLang)?.node.value
                                        return(
                                            <CategorieName key={`categorie-name${index}`} variant="h5" style={{width: "100%", marginTop:8}} onClick={() => this.handleChangeCat(cat)}>
                                                {catName || cat.libelle} <span style={{color: colors.blue.lighter.hue300}}>({cat.products?.totalCount ?? cat.contents?.totalCount})</span>
                                            </CategorieName>
                                        )
                                    })
                                : "Pas de catégorie"
                            : <PageLoader />
                        }
                    </Grid>
                </Grid>
            </CardCustom>
        )
    }
}

export default withApollo((TreeCategories));