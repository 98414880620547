import React from 'react';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { withApollo } from 'react-apollo';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_ERROR } from '../../../../js/constants/alert-types';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import CardCustom from '../../../layouts/Card/CardCustom';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import Button from '../../../ui/button/Button';
import Typography from '../../../ui/typography/Typography';
import { Grid, Box, Select, MenuItem, FormControl, Chip } from '@material-ui/core';
import { ROUTE_CRM_OFFERS_EDIT, ROUTE_CRM_OFFERS_ADD,ROUTE_CRM_OFFERS_MODELES, ROUTE_HOME } from '../../../../js/constants/route-names';
import colors from '../../../../config/theme/colors';
import EmptyProduct from '../../../../assets/pictos/empty-picto/empty_products.png';
import EmptyCard from "../../../ui/empty-card/EmptyCard";
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import { GET_OFFERS_CURSOR, GET_OFFERS_PAGINATION, SEARCH_OFFERS } from '../../../../queries/crm_offers';
import styled from 'styled-components';
import modelesConfig from './config/modelesType';
import { GET_MODELES,  } from '../../../../queries/crm_modeles';
import { hasRights } from '../../../../js/utils/rights';
import { CRM_OFFERS, CRM_OFFERS_OFFERS, CREATE, VIEW} from '../../../../js/constants/constant-rights';
import TablePagination from '../../../ui/pagination/TablePaginationCustom';
import * as moment from "moment";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import SearchBar from '../../../ui/search/SearchBar';
import GetAppIcon from '@material-ui/icons/GetApp';


const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 1400px){
        padding: 16px 8px 8px!important;
    }
`;

const LineColor = styled(Box)`
    background: ${colors.blue.darker.hue300};
`;

const Description = styled(Typography)`
    p{
        margin: 0;
    }
`;

const StyledTableCell = styled(TableCell)`
    font-size: 14px;
    &.MuiTableCell-head{
        color: white;
        font-weight: bold
    }
`;

const StyledTableRow = styled(TableRow)`
    &:nth-of-type(odd){
        background-color: rgb(110, 174, 209, 0.06);
    },
`;
const SelectCustom = styled(Select)`
    border: 1px solid ${colors.grey.regular};
    border-radius: 5px;
    background: #FAFAFA;
    position: relative;
    .MuiSelect-root{
        padding: 12px 32px;
        padding-left: 16px;
    }
    .MuiInput-underline:before, .MuiInput-underline:after{
        display: none;
    }
    fieldset{
        border: none;
    }
`;
const ChipCustom = styled(Chip)`
    background-color: ${props => props.color === 'processing' ? colors.blue.lighter.hue300 : '#000000'};
    color: white;
    text-transform: capitalize;
    span{
        line-height: initial;
    }
`;

class ListOffers extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            currentLang: props.locales[0].node.code,
            offers: [],
            modelesTypesData: null,
            openForm: false,
            typeOf: null,
            page: 0,
            nbperpage: 5,
            countItems: 0,
            cursor: null,
            cursorLast: null,
            offerList: [],
        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        const getRights = hasRights(CRM_OFFERS, CRM_OFFERS_OFFERS, VIEW)
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }else{
            this.initOffers();
        }
    }

    handleSearch = () => {
        this.props.startLoading();
        let value = this.state.searchValue;
        if (value !== "") {
            this.props.client.query({
                query: SEARCH_OFFERS,
                variables: {"title": value},
                fetchPolicy: 'no-cache'
            }).then(result =>{
                this.setState({
                    offerList: [],
                    page: 0,
                    cursor: null,
                    cursorLast: null,
                }, () => {
                    for(let item of result.data.offers.edges){
                        let stripId = item.node.id.replace('/api/offers/', '')
                        this.state.offerList.push(stripId);
                    }
                    this.handleGetOffers()
                });
            });
        } else {
            this.setState({
                offerList: [],
                noResult: false
            },() => {
                this.handleGetOffers();
            });
        }
    }

    handleChange(e, value) {
        clearTimeout(this.typingSearchTimer);
        if(e?.target?.value){
            this.setState({searchValue: e.target.value});
            this.typingSearchTimer = setTimeout(() => {
                this.handleSearch();
            }, 500);
        } 
        else if(value){
            this.handleSearch();
        }
        else {
            this.setState({
                offerList: [],
                noResult: false,
                searchValue: ''
            }, () => {
                this.handleGetOffers();
            });
        }
    }


    handleGetOffers() {
        return new Promise((resolve, reject) => {
            let variables = { "nbperpage": this.state.nbperpage };
            if (this.state.cursor) {
                variables.cursor = this.state.cursor;
            }
            if (this.state.cursorLast) {
                variables.cursorLast = this.state.cursorLast;
            }
            if(this.state.offerList.length > 0){
                variables.idList = this.state.offerList;
            }
            console.log('variables',variables)
            this.props.client.query({
                query: GET_OFFERS_PAGINATION,
                variables,
                fetchPolicy: 'no-cache'
            }).then(result =>{
                this.setState({
                    cursor: result.data.offers.pageInfo.startCursor,
                    cursorLast: result.data.offers.pageInfo.endCursor,
                    countItems: result.data.offers.totalCount,
                    offers: result.data.offers.edges,
                })
                if (result.data.offers.edges.length > 0){
                    this.setState({ isEmpty: false })
                }else{
                    this.setState({ isEmpty: true })
                }
                this.setState({ ready: true })
                this.props.stopLoading();
                resolve();
            });
        });
    }

    handleGetModeles(){
        this.props.client.query({
            query: GET_MODELES,
            fetchPolicy: 'no-cache'
        }).then(result =>{
            this.setState({
                dataLayout: modelesConfig,
                ready: true,
            },()=>{
                let newTypeOf = null
                result.data.models.edges.length > 0 ?
                    newTypeOf = result.data.models.edges.map(model=>({
                        ...model.node,
                        libelle: 'Modèle de devis',
                        bicoloreText: model.node.name,
                        colorhover: '#6EAED1',
                        isTextArea: true,
                        textButton:'Générer',
                        logo: model.node.media?.filePath,
                        customLogo: true,
                        onClick: () => this.goTo(ROUTE_CRM_OFFERS_ADD, null, model.node.id),
                        // isLocaleImage   : true
                    }))
                : 
                    newTypeOf = {
                        textNoData: 'Aucun modèle configuré',
                        textButtonNoData: 'Voir les modèles',
                        onClickNoData : () => this.goTo(ROUTE_CRM_OFFERS_MODELES)
                    }
                this.setState({
                    typeOf : newTypeOf
                })
            });
        });
    }

    handleToggleDrawer = () => {
        this.setState({ 
            openForm        : !this.state.openForm,
        });
    }

    changePage = (event, page) => {
        this.props.startLoading();
        // let index = 0;
        // if(page > 0){
        //     index = ( page * this.state.nbperpage ) - this.state.nbperpage - 1
        // }
        this.setState({
            cursor: page > this.state.page ? this.state.cursorLast : null,
            cursorLast: page > this.state.page ? null : this.state.cursor,
            page: page
        },()=>{
            this.handleGetOffers();
        });
    };

    handleChangePerPage = (event) => {
        this.setState({
            nbperpage: event.target.value,
            page: 0,
            cursor: null,
            cursorLast: null,
        }, () => this.handleGetOffers())
    }

    render() {
        console.log('countItems', this.state.countItems)
        console.log('page', this.state.page)
        console.log(this.state)
        return (
            <div>
                <TopPanel 
                    icomoon="picto-produit"
                    colorIcomoon={colors.blue.lighter.hue300}
                    title="Gérer les devis" 
                    subtitle="Gestion de vos devis (création / modification / suppression)" 
                    handlerAdd={() => this.handleToggleDrawer()} 
                    // textAdd={hasRights(CRM_OFFERS, CRM_OFFERS_OFFERS, CREATE) ? "Ajouter un devis" : null}
                    // handlerImport={() => this.handleToggleDrawer('openForm')} 
                    // textImport="Importer des produits" 
                    // searchHandler={this.handleChange} 
                    // searchPlaceholder={'Rechercher par nom'} 
                    gradientColor1={colors.menu.regular} 
                    gradientColor2={colors.menu.darker}
                    windowWidth={this.props.windowWidth}
                    openForm={this.state.openForm}
                    buttonAvailable={this.state.ready}
                    hasBorder="true"
                    // currentLang={this.state.currentLang} 
                    // handleLang={this.handleLang} 
                    // locales={this.props.locales}
                />
                <Grid container direction="column" justify="center" style={{marginTop: 16}} spacing={0}>
                    <Grid container direction="row" spacing={2} style={{marginTop: 0, marginBottom: 0}}>
                        {
                            this.state.ready ?
                            this.state.offers.length > 0 ?
                                <>
                                    <Grid container style={{marginBottom: 16}} spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant={'h3'}>Filtrer par :</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <SearchBar onChange={this.handleChange} placeholder={'Recherche par nom client'}/>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <SearchBar onChange={() => console.log('Search')} placeholder={'Recherche par N°Devis'}/>
                                        </Grid>
                                        {/* <Grid item xs={3}>
                                            <SearchBar onChange={() => console.log('Search')} placeholder={'Recherche par N°Devis'}/>
                                        </Grid> */}
                                        <Grid item >
                                            <FormControl variant="outlined">
                                                <Grid container direction="row" alignItems="center">
                                                    <Typography id={`menu-attribute-select`} style={{marginRight: 8}}>Status:</Typography>
                                                    <SelectCustom 
                                                        onChange={() => console.log('Select')} 
                                                        label="Status" 
                                                        variant="outlined" 
                                                        value={'process'}
                                                    >
                                                        <MenuItem key={1} value={'process'}>
                                                            Processing
                                                        </MenuItem>
                                                        <MenuItem key={2} value={'finished'}>
                                                            Finished
                                                        </MenuItem>
                                                        <MenuItem key={3} value={'canceled'}>
                                                            Canceled
                                                        </MenuItem>
                                                    </SelectCustom>
                                                </Grid>
                                            </FormControl>
                                        </Grid>
                                        {/* <Grid item xs={3}>
                                            <SearchBar onChange={() => console.log('Search')} placeholder={'Recherche par montant'}/>
                                        </Grid> */}
                                    </Grid>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="customized table">
                                            <TableHead>
                                            <TableRow style={{background: '#2B4553', color: 'white'}}>
                                                <StyledTableCell>Nom du client</StyledTableCell>
                                                <StyledTableCell align="center">Montant (€)</StyledTableCell>
                                                <StyledTableCell align="center">Reste à facturer (€)</StyledTableCell>
                                                <StyledTableCell align="center">Date validité</StyledTableCell>
                                                <StyledTableCell align="center">Status</StyledTableCell>
                                                {/* <StyledTableCell align="center"></StyledTableCell> */}
                                            </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {

                                                this.state.offers.map((offer, index) => {
                                                    return(
                                                        <StyledTableRow key={index}>
                                                            <StyledTableCell component="th" scope="row">
                                                                {offer.node.nameClient}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">{offer.node.totalPrice}</StyledTableCell>
                                                            <StyledTableCell align="center">{offer.node.needPayment}</StyledTableCell>
                                                            <StyledTableCell align="center">{moment(offer.node.validityDate).format('DD/MM/YYYY')}</StyledTableCell>
                                                            <StyledTableCell align="center"><ChipCustom label={offer.node.status} color={offer.node.status}/></StyledTableCell>
                                                            {/* <StyledTableCell align="center">
                                                                <Button 
                                                                    onClick={() => this.goTo(ROUTE_CRM_OFFERS_EDIT.replace(':id', offer.node.title),offer.node.id)} 
                                                                    style={{margin: 0}} 
                                                                    variant         = "contained" 
                                                                    bgcolor         = {colors.white} 
                                                                    bgcolorhover    = {colors.blue.regular}
                                                                    color           = {colors.blue.regular}
                                                                    colorhover      = {colors.white}
                                                                    border          = {`1px solid ${colors.blue.regular}`}
                                                                    text = {<GetAppIcon/>} 
                                                                />
                                                            </StyledTableCell> */}
                                                        </StyledTableRow>
                                                    )
                                                })
                                            }
                                            </TableBody>
                                        </Table>
                                    </TableContainer> 
                                    {
                                        this.state.ready ?
                                            <TablePagination
                                                count={this.state.countItems}
                                                page={this.state.page}
                                                component="div"
                                                onChangePage={(event, page) => { this.changePage(event, page) }}
                                                color="primary"
                                                rowsPerPage={this.state.nbperpage}
                                                rowsPerPageOptions={[5, 10, 15]}
                                                onChangeRowsPerPage={this.handleChangePerPage}
                                                labelDisplayedRows={ (from=this.state.page) => (
                                                    `${from.from}-${from.to === -1 ? from.count : from.to} sur ${from.count}`
                                                )}
                                                labelRowsPerPage="Devis par pages : "
                                            />
                                        :
                                            null
                                    }  
                                </> 
                            :<EmptyCard title={hasRights(CRM_OFFERS, CRM_OFFERS_OFFERS, CREATE) ? "Vous n'avez pas encore configuré d'offre" : "Vous n'avez aucun droit de création sur cette page"} subtitle={hasRights(CRM_OFFERS, CRM_OFFERS_OFFERS, CREATE) ? "Cliquez sur le bouton ci-dessous pour en ajouter une" : "Faite une demande auprès d'un administrateur"} textButton={hasRights(CRM_OFFERS, CRM_OFFERS_OFFERS, CREATE) ? "Ajouter une offre" : null} onClick={() => this.handleToggleDrawer()} picto={EmptyProduct} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />
                            : <PageLoader />
                        }
                    </Grid>
                </Grid>
                {/* {
                    this.state.dataLayout ?
                    (
                        <LayoutBuilder 
                            opened={this.state.openForm} 
                            forClose={() => {this.handleToggleDrawer('form')}} 
                            dataLayout={this.state.dataLayout} 
                            dataCard={this.state.typeOf} 
                        />
                    ) : ''
                } */}
            </div>
        );
    }

    async initOffers() {
        // await this.handleGetCursorsOffers()
        await this.handleGetOffers()
        this.handleGetModeles()
    }

    goTo = (route, id, model) => {
        this.props.history.push({
            pathname : route,
            state: { 
                offerId : id,
                modelId : model
            }
        });
    };

}

const mapDispatchToProps = dispatch => {
    return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes, 
        locales: state.locales,
    };
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(ListOffers)));
