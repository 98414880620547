import React from 'react';
import { Box, Grid, Typography, Tooltip } from '@material-ui/core';
import Button from '../../../ui/button/Button';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import NoImage from '../../../../assets/images/not-found.png';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getTraductionAttributs } from '../../../../js/utils/functions';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

const ContainerCard = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;
const ButtonCustom = styled(Button)`
    margin: 0;
    text-transform: unset;
    font-weight: bold;
`;
const Title = styled(Typography)`
    font-weight: bold;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;
const Sku = styled(Typography)``;

const SpanColor = styled.span`
    color: ${colors.blue.lighter.hue300};
    font-weight: bold;
    display: block;
    width: 100%;
`;
const TableCellCustom = styled(TableCell)`
    border: none;
    display: inline-block;
    width: calc(100% / 9);
    padding: 3px 16px;
`;
const CircularProgressCustom = styled(CircularProgress)`
    color: ${props => props.value > 30 
            ? props.value > 65 
                ? colors.green.regular : "#f6d607"
            : colors.red.regular};
    margin-left: ${props => props.marginleft};
`;


export default function CardProductList(props){

    const useStylesBootstrap = makeStyles(theme => ({
        arrow: {
            color: colors.blue.regular,
        },
        tooltip: {
            backgroundColor: colors.blue.regular,
            fontSize: 14,
            padding: 10,
            textAlign: 'left',
        },
    }));

    function BootstrapTooltip(props) {
        const classes = useStylesBootstrap();
        return <Tooltip arrow classes={classes} {...props} />;
    }

    const product = props.product;
    const attributes = product.productDatas.edges;

    let name = '';
    let image= '';
    let imageSelected = [];
    let getImageByTranslation = '';

    let statsCompletion = product.stats?.infoStats.percentByLangs[props.currentLang] 
                            ? product.stats.infoStats.percentByLangs[props.currentLang] 
                            : product.stats?.infoStats.percentByLangs[0] 
                                ? product.stats.infoStats.percentByLangs[0] 
                                : 0;
    
    for(let attribute of attributes){
        if(attribute.node.attribute.isSystem){
            imageSelected = attributes.filter(e => e.node.media !== null)
            getImageByTranslation = imageSelected.filter(e => e.node.locale.code === props.currentLang)
            name = getTraductionAttributs('product_name', attributes, props.currentLang);
            if(attribute.node.attribute.identifier === 'product_image' && image === ''){
                image = getTraductionAttributs('product_image', attributes, props.currentLang, 'image');
            }
        }
    }

    let catalogList = []
    for (let category of props.product.categories){
        if (category.catalog){
            if (catalogList.indexOf(category.catalog.libelle) === -1){
                catalogList.push(category.catalog.libelle)
            }
        }
    }

    const goTo = props.routeProduct;

    return(
        <ContainerCard>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableBody>
                        <TableRow key={'rowProduct'}>
                            <TableCellCustom component="th" scope="row" align="center">
                                {
                                    image && image !== '' ? 
                                        (<img src={`${process.env.REACT_APP_MEDIAS}/${image.filePath}`} alt={name} style={{maxWidth: '100%', maxHeight: 40}}/>) 
                                    : 
                                        (<img src={NoImage} style={{maxWidth: '100%', maxHeight: 40}} alt={name}/>)
                                }
                            </TableCellCustom>
                            <TableCellCustom>
                                <Sku variant="body2">{product.sku}</Sku>
                            </TableCellCustom>
                            <TableCellCustom style={process.env.REACT_APP_MODE_SPREAD !== "hub" ? null : {width: 'calc(100%/3.5)'}}>
                                <Title variant="body1">{name || 'N/A'}</Title>
                            </TableCellCustom>
                            <TableCellCustom align="center" style={process.env.REACT_APP_MODE_SPREAD !== "hub" ? null : {width: 'calc(100%/4)'}}>
                            {/* {
                                product.categories ? (
                                    <ProductAttr variant="body1">
                                        { product.categories.filter(e => e.catalog === null).map((categorie, index) => {
                                            if(categorie.catalog === null){
                                                if (categorie.categoryDatas.edges.length > 0 && categorie.categoryDatas.edges.find(e => e.node.attribute.identifier === "category_name")){
                                                    let getCategorieByLang = getTraductionAttributs('category_name', categorie.categoryDatas.edges, props.currentLang);
                                                    return(index !== 0 ? ` / ${getCategorieByLang}` : `${getCategorieByLang}`);
                                                }else{
                                                    return(index !== 0 ? ` / ${categorie.libelle}` : `${categorie.libelle}`);
                                                }
                                            }else{
                                                return null;
                                            }                            
                                        })}
                                    </ProductAttr>
                                ) : ''
                            } */}
                                <BootstrapTooltip 
                                    title={ product.categories.filter(e => e.catalog === null).map((categorie, index) => {
                                        if(categorie.catalog === null){
                                            if (categorie.categoryDatas.edges.length > 0 && categorie.categoryDatas.edges.find(e => e.node.attribute.identifier === "category_name")){
                                                let getCategorieByLang = getTraductionAttributs('category_name', categorie.categoryDatas.edges, props.currentLang)
                                                return(<span key={`CategorieName${index}`} style={{display: 'block'}}>{index + 1} - {getCategorieByLang}</span>);
                                            } else {
                                                return(<span key={`CategorieName${index}`} style={{display: 'block'}}>{index + 1} - {categorie.libelle}</span>);
                                            }
                                        }else{
                                            return null
                                        }                            
                                    })}
                                    arrow
                                >
                                    <SpanColor> {product.categories.filter(e => e.catalog === null).length}</SpanColor>
                                </BootstrapTooltip>
                            </TableCellCustom>
                            <TableCellCustom align="center">
                                <BootstrapTooltip 
                                    title={catalogList.map((catalogName, index) =>{
                                        return(<span key={`CatalogName${index}`} style={{display: 'block'}}>{index + 1} - {catalogName}</span>)
                                    })} 
                                    arrow
                                >
                                    <SpanColor> {props.nbCatalog ? props.nbCatalog : 0}</SpanColor>
                                </BootstrapTooltip>
                            </TableCellCustom>
                            <TableCellCustom align="center" style={{width: 'calc(100%/9)'}}>
                                <StatusInfo status={product.status} noText={true} justify={"center"}/>
                            </TableCellCustom>
                            {
                                process.env.REACT_APP_MODE_SPREAD !== "hub" ? 
                                    <>
                                    <TableCellCustom align="center">
                                    {
                                        statsCompletion === 0 || statsCompletion ?
                                        (
                                            <Box position="relative" display="inline-flex">
                                                <CircularProgressCustom variant="determinate" thickness={4} value={statsCompletion} size={40}/>
                                                <Box
                                                    top={0}
                                                    left={0}
                                                    bottom={0}
                                                    right={0}
                                                    position="absolute"
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    <Typography variant="h5" component="div" color="textSecondary">
                                                        {statsCompletion > 0 ? `${Math.round(statsCompletion)}%` : `${statsCompletion}%`}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        ) : null
                                    }
                                </TableCellCustom>
                                <TableCellCustom style={{width: 'calc(100%/9)'}} align="center">
                                    {
                                        imageSelected ? 
                                        (
                                            <Grid container alignItems="center" justify="center">
                                                {/* <PhotoLibraryOutlinedIcon  style={{color: colors.grey.regular}}/> */}
                                                <Typography variant="body1"><SpanColor style={{width: "auto", display:"inline"}}>{getImageByTranslation.length}</SpanColor>/{props.nbImageTotal}</Typography>
                                            </Grid>
                                        ) : null
                                    }
                                </TableCellCustom>
                                <TableCellCustom align="right" style={{width: 'calc(100%/9)'}}>
                                    <ButtonCustom disableElevation={true} text={"Voir"} arrow="right" bgColor={colors.green.regular}  onClick={goTo} />
                                </TableCellCustom>
                                </>
                                : null
                            }
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </ContainerCard>
    );
}