import PropTypes from "prop-types";
import React, { useState } from "react";
import H1 from '../../../../shared/components/CustomH1';
import H2 from '../../../../shared/components/CustomH2';
import Pg from '../../../../shared/components/CustomParagraph';
import Button from '../../../../shared/components/CustomLink';

const EndedGame = (props) => {
    const [hover, setHover] = useState(false);
    const [hoverBlock, setHoverBlock] = useState(false);

    let {
        preview = false,
        id = null, 
        data = null,
        inputCallback = null,
    } = props;

    let image = data.inputs['backgroundImage'].value;

    let border = null;
    if (data.blocks.middleBox.inputs.border) {
        border = data.blocks.middleBox.inputs.border;
    }

    return (
        <div class="game_index alreadyplayed">
            <section 
                className="game"
                style={{
                    backgroundColor: data.inputs['backgroundColor'].value,
                    backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                    position: 'relative',
                }}
                onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
            >
                { !preview && props.toolbarSection }
                <div 
                    className="popup play"
                >
                    {
                        data.blocks.logo.inputs.value?.value?.filePath || typeof(data.blocks.logo.inputs.value?.value) === "string" ?
                        (
                            <img className="logo" onClick={() => inputCallback(id, 'logo')} src={data.blocks.logo.inputs.value?.value.filePath ? ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.logo.inputs.value.value.filePath}` : typeof(data.blocks.logo.inputs.value.value) === "string" ? data.blocks.logo.inputs.value.value : "http://via.placeholder.com/250x80/CCCCCC/AAAAAA?text=Logo"} />
                        ) : null
                    }
                    <div 
                        className="popup_content"
                        onMouseEnter={() => {setHoverBlock(true); setHover(false);}} 
                        onMouseLeave={() => {setHoverBlock(false); setHover(true)}}
                        style={{ 
                            paddingTop: data.blocks.middleBox.blocks.image.inputs.value?.value?.filePath || typeof(data.blocks.middleBox.blocks.image.inputs.value?.value) === "string" ? 'calc(250px / 2 + 12px)' : null,
                            paddingBottom: 0,
                            paddingLeft: 10,
                            paddingRight: 10,
                            top: data.blocks.middleBox.blocks.image.inputs.value?.value?.filePath || typeof(data.blocks.middleBox.blocks.image.inputs.value?.value) === "string" ? '60%' : '50%',
                            borderBottom: 0,
                            borderStyle: 'solid',
                            borderWidth: border?.inputs?.borderWidth?.value ? border.inputs.borderWidth.value : '0px',
                            borderColor: border?.inputs?.borderColor?.value ? border.inputs.borderColor.value : null,
                            borderRadius: data.blocks.middleBox.blocks.redirectionButtonText.inputs.border?.inputs?.borderRadius?.value,
                            backgroundColor: data.blocks.middleBox.inputs.backgroundColor.value ? data.blocks.middleBox.inputs.backgroundColor.value : null,
                            paddingBottom: data.blocks.middleBox.inputs.positionButton.value === 'initial' ? 0 : 20,
                            paddingTop:  data.blocks.middleBox.inputs.positionTitle.value === 'fullwidth' ? 0 : null,
                        }}
                    >
                        { !preview && hoverBlock && props.toolbarBlock }
                        {
                            data.blocks.middleBox.blocks.image.inputs.value?.value?.filePath || typeof(data.blocks.middleBox.blocks.image.inputs.value?.value) === "string" ? 
                            (
                                <img className="img_center" onClick={() => inputCallback(id, 'image')} src={data.blocks.middleBox.blocks.image.inputs.value?.value?.filePath ? ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.middleBox.blocks.image.inputs.value.value.filePath}` : typeof(data.blocks.middleBox.blocks.image.inputs.value.value) === "string" ? data.blocks.middleBox.blocks.image.inputs.value.value : "http://via.placeholder.com/250x80/CCCCCC/AAAAAA?text=Logo"} />
                            ) : null
                        }
                        <H1 data={data.blocks.middleBox} preview={preview} component={id} block={'title'} inputCallback={inputCallback} style={{width: data.blocks.middleBox.inputs.positionTitle.value === 'fullwidth' ? 'calc(100% + 20px)' : null, margin: data.blocks.middleBox.inputs.positionTitle.value === 'fullwidth' ? 0 : null, left: data.blocks.middleBox.inputs.positionTitle.value === 'fullwidth' ? -10 : null, position: data.blocks.middleBox.inputs.positionTitle.value === 'fullwidth' ? 'relative' : null}} />
                        <H2 data={data.blocks.middleBox} preview={preview} component={id} block={'subtitle'} inputCallback={inputCallback} />
                        <Pg data={data.blocks.middleBox} preview={preview} component={id} block={'text'} inputCallback={inputCallback} />
                        <hr style={{borderColor: data.blocks.middleBox.blocks.title.inputs.color.value}}/>
                        <div class="inline_div">
                            <img 
                                onClick={() => inputCallback(id, 'redirectionImage')} 
                                src={data.blocks.middleBox.blocks.redirectionImage.inputs.value?.value?.filePath  ? ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.middleBox.blocks.redirectionImage.inputs.value.value.filePath}` : typeof(data.blocks.middleBox.blocks.redirectionImage.inputs.value?.value) === "string" ? data.blocks.middleBox.blocks.redirectionImage.inputs.value.value : "http://via.placeholder.com/120x90/53839D/FFFFFF?text=Image"} 
                            />
                            
                            {
                                data.blocks.middleBox.inputs.positionButton.value === 'withText' ? (
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <Pg data={data.blocks.middleBox} preview={preview} component={id} block={'redirectionText'} inputCallback={inputCallback} nopadding={true} />
                                        <Button data={data.blocks.middleBox} preview={preview} component={id} block={'redirectionButtonText'} inputCallback={inputCallback} className="as_button" nopadding={true} style={{margin: '10px 0'}} />
                                    </div>
                                ) : 
                                (
                                    <Pg data={data.blocks.middleBox} preview={preview} component={id} block={'redirectionText'} inputCallback={inputCallback} nopadding={true} />
                                )
                            }
                        </div>
                        {
                            data.blocks.middleBox.inputs.positionButton.value === 'initial' ? (
                                <Button data={data.blocks.middleBox} preview={preview} component={id} block={'redirectionButtonText'} inputCallback={inputCallback} className="as_button" nopadding={true} fullwidth={true} />
                            ) : null
                        }
                    </div>
                </div>
            </section>
        </div>
    );
};

EndedGame.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingclassName: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default EndedGame;