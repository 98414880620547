import { 
    ROUTE_STATS,
    ROUTE_STATS_RETAILERS,
    ROUTE_STATS_ASSETS,
    ROUTE_STATS_CAMPAIGNS,
    ROUTE_DIFFUSION,
    ROUTE_DIFFUSION_CALENDAR,
    ROUTE_DIFFUSION_PERMANENT,
    ROUTE_DIFFUSION_PERMANENTS_ADD_POST,
    ROUTE_DIFFUSION_PERMANENTS_POSTS,
    ROUTE_DIFFUSION_PERMANENTS_PAGE,
    ROUTE_DIFFUSION_CAMPAIGNS,
    ROUTE_DIFFUSION_CAMPAIGNS_ADD,
    ROUTE_DIFFUSION_CAMPAIGNS_DETAIL,
    ROUTE_DIFFUSION_RETAILERS,
    ROUTE_ENGINE,
    ROUTE_ENGINE_ASSETS,
    ROUTE_ENGINE_ASSETS_DETAIL_STATS,
    ROUTE_ENGINE_ASSETS_DETAIL,
    ALL_ROUTE_CATALOGS,
    ROUTE_CATALOGS,
    ROUTE_CATALOGS_LIST,
    ROUTE_CATALOGS_DETAIL,
    ROUTE_MEDIAS,
    ROUTE_MEDIAS_CATEGORIES,
    ROUTE_MEDIAS_LIST,
    ROUTE_CHANNELS_MEDIAS,
    ROUTE_MEDIAS_TAGS,
    ROUTE_CONTENU,
    ROUTE_CONTENU_CATEGORIES,
    ROUTE_CONTENU_LIST,
    ROUTE_CONTENU_LIST_DETAIL,
    ROUTE_CONTENU_ADD,
    ROUTE_CONTENU_ATTRIBUTES,
    ROUTE_PRODUCTS,
    ROUTE_PRODUCTS_CATEGORIES,
    ROUTE_PRODUCTS_LIST,
    ROUTE_PRODUCTS_ADD,
    ROUTE_PRODUCTS_LIST_DETAIL,
    ROUTE_PRODUCTS_ATTRIBUTES,
    ROUTE_ORDERS,
    ROUTE_ORDERS_LIST,
    ROUTE_ORDERS_LIST_DELAY,
    ROUTE_ORDERS_LIST_RETURN,
    ROUTE_ORDERS_LIST_PREORDER,
    ROUTE_ORDERS_DETAIL,
    ROUTE_CHANNELS,
    ROUTE_CHANNELS_RETAILERS,
    ROUTE_CHANNELS_BRANDS,
    ROUTE_CHANNELS_MARKETPLACES,
    ROUTE_CHANNELS_SOCIALS,
    ROUTE_CHANNELS_CMS,
    ROUTE_CHANNELS_MAILER,
    ROUTE_CHANNELS_CONTENTS,
    ROUTE_CHANNELS_CONNECTORS,
    ROUTE_SETTINGS,
    ROUTE_SETTINGS_LANGUAGES,
    ROUTE_SETTINGS_CURRENCIES,
    ROUTE_SETTINGS_USERS,
    ROUTE_SETTINGS_ACCESS,
    ROUTE_BRAND_GUIDELINE,
    ROUTE_BRAND_GUIDELINE_ADD,
    ROUTE_BRAND_GUIDELINE_UPDATE,
    ROUTE_BRAND_FLOW,
    ALL_ROUTE_STATS,
    ALL_ROUTE_ENGINE,
    ALL_ROUTE_MEDIAS,
    ALL_ROUTE_PRODUCTS,
    ALL_ROUTE_CHANNELS,
    ALL_ROUTE_BRAND,
    ALL_ROUTE_SETTINGS,
    ALL_ROUTE_DIFFUSION,
    ALL_ROUTE_CONTENU,
    ALL_ROUTE_ORDERS,
    ROUTE_SETTINGS_DETAIL_USER,
} from '../../js/constants/route-names';

import{
    ROUTE_CRM_STATS,
    ROUTE_CRM_STATS_CLIENTS,
    ROUTE_CRM_STATS_RESSOURCES,
    ROUTE_CRM_STATS_CAMPAIGNS,
    ROUTE_CRM_AGENCY,
    ROUTE_CRM_PRICES,
    ROUTE_CRM_TAXES,
    ROUTE_CRM_PAYMENT_TERMS,
    ROUTE_CRM_PAYMENT_DEADLINES,
    ROUTE_CRM_CGV,
    ROUTE_CRM_OFFERS,
    ROUTE_CRM_OFFERS_ADD,
    ROUTE_CRM_OFFERS_EDIT,
    ROUTE_CRM_OFFERS_LIST,
    ROUTE_CRM_OFFERS_PROJECTS,
    ROUTE_CRM_OFFERS_SETTINGS,
    ROUTE_CRM_OFFERS_MODELES,
    ROUTE_CRM_OFFERS_MODELES_ADD,
    ROUTE_CRM_OFFERS_MODELES_EDIT,
    ROUTE_CRM_OFFERS_MODELES_PRESENTATIONS,
    ROUTE_CRM_OFFERS_PRESENTATIONS,
    ROUTE_CRM_MEDIAS,
    ROUTE_CRM_MEDIAS_CATEGORIES,
    ROUTE_CRM_CONTACTS,
    ROUTE_CRM_CONTACTS_SOCIETIES,
    ROUTE_CRM_CONTACTS_SOCIETIES_DETAILS,
    ROUTE_CRM_CONTACTS_CLIENTS,
    ROUTE_CRM_CONTACTS_LEADS,
    ROUTE_CRM_CONTACTS_LIST,
    ROUTE_CRM_SETTINGS,
    ROUTE_CRM_SETTINGS_LANGUAGES,
    ROUTE_CRM_SETTINGS_USERS,
    ROUTE_CRM_SETTINGS_DETAIL_USER,
    ALL_ROUTE_CRM_STATS,
    ALL_ROUTE_CRM_OFFERS,
    ALL_ROUTE_CRM_OFFERS_SETTINGS,
    ALL_ROUTE_CRM_MEDIAS,
    ALL_ROUTE_CRM_CONTACTS,
    ALL_ROUTE_CRM_SETTINGS,
    ALL_ROUTE_CRM_AGENCY
} from '../../js/constants/route-names';


import {hasRights} from '../../js/utils/rights';
import {
    VIEW,
    DASHBOARD,
    DASHBOARD_ASSETS,
    DASHBOARD_CAMPAIGN,
    DASHBOARD_RETAILERS,
    DASHBOARD_STATISTICS,
    PRODUCTS, 
    PRODUCTS_PRODUCTS, 
    PRODUCTS_DASHBOARD,
    PRODUCTS_CATEGORIES,
    PRODUCTS_ATTRIBUTES,
    BROADCAST_CALENDAR,
    BROADCAST,
    BROADCAST_CAMPAIGN,
    BROADCAST_RETAILERS,
    BUILDER,
    BUILDER_DASHBOARD,
    BUILDER_ASSETS,
    CATALOGS,
    CATALOGS_DASHBOARD,
    CATALOGS_CATALOGS,
    MEDIAS,
    MEDIAS_DASHBOARD,
    MEDIAS_CATEGORIES,
    MEDIAS_MEDIAS,
    MEDIAS_TAGS,
    CONTENTS,
    CONTENTS_DASHBOARD,
    CONTENTS_CATEGORIES,
    CONTENTS_CONTENTS,
    CONTENTS_MODELS,
    CONNECTORS,
    CONNECTORS_DASHBOARD,
    CONNECTORS_RETAILERS,
    CONNECTORS_MARKETPLACES,
    CONNECTORS_SOCIAL_NETWORK,
    CONNECTORS_CMS,
    CONNECTORS_EMAILS,
    CONNECTORS_CONTENTS,
    CONNECTORS_MEDIAS,
    CONNECTORS_CONNECTORS,
    BRAND,
    BRAND_GUIDELINE,
    BRAND_FLOW,
    SETTINGS,
    SETTINGS_LANGUAGES,
    SETTINGS_CURRENCIES,
    SETTINGS_USERS,
    CRM_AGENCIES,
    CRM_AGENCIES_POLES,
    CRM_AGENCIES_RATES,
    CRM_AGENCIES_TAX_RATES,
    CRM_AGENCIES_PAYMENT_TERMS,
    CRM_AGENCIES_PAYMENT_DEADLINES,
    CRM_OFFERS,
    CRM_OFFERS_DASHBOARD,
    CRM_OFFERS_OFFERS,
    CRM_OFFERS_MODELES,
    CRM_OFFERS_PROJECTS,
    CRM_MEDIAS,
    CRM_MEDIAS_DASHBOARD,
    CRM_MEDIAS_DAM,
    CRM_CONTACT,
    CRM_CONTACT_DASHBOARD,
    CRM_CONTACT_SOCIETIES,
    CRM_CONTACT_CLIENTS,
    CRM_CONTACT_LEADS,
    CRM_CONTACT_CONTACTS,
    CRM_SETTINGS,
    CRM_SETTINGS_LANGUAGES,
    CRM_SETTINGS_USERS,
    CRM_STATISTICS,
    CRM_STATISTICS_MY_STATS,
    CRM_STATISTICS_BY_CUSTOMER,
    CRM_STATISTICS_BY_RESOURCE,
    CRM_STATISTICS_BY_CAMPAIGN,
} from '../../js/constants/constant-rights';

export default function setUpDrawer(isCRM) {
    let menuItems;
    if(isCRM){
        menuItems=[
            {
                name : 'Statistiques',
                route: ROUTE_CRM_STATS,
                allRoutes : ALL_ROUTE_CRM_STATS, 
                childrens:[
                    hasRights(CRM_STATISTICS, CRM_STATISTICS_MY_STATS, VIEW) ?
                    {
                        name : 'Mes statistiques',
                        route:ROUTE_CRM_STATS,
                        icone:'icon-dashboard'   
                    } : null,
                    hasRights(CRM_STATISTICS, CRM_STATISTICS_BY_CUSTOMER, VIEW) ?
                    {
                        name : 'Par clients',
                        route:ROUTE_CRM_STATS_CLIENTS,
                        icone:'icon-clients'   
                    } : null,
                    hasRights(CRM_STATISTICS, CRM_STATISTICS_BY_RESOURCE, VIEW) ?
                    {
                        name : 'Par ressources',
                        route:ROUTE_CRM_STATS_RESSOURCES,
                        icone:'icon-ressources'   
                    } : null,
                    hasRights(CRM_STATISTICS, CRM_STATISTICS_BY_CAMPAIGN, VIEW) ?
                    {
                        name : 'Par campagnes',
                        route:ROUTE_CRM_STATS_CAMPAIGNS,
                        icone:'icon-campagne'   
                    } : null,
                ]
                
            },
            // {
            //     name : 'Agence',
            //     route: ROUTE_CRM_AGENCY,
            //     allRoutes : ALL_ROUTE_CRM_AGENCY, 
            //     childrens:[
            //         hasRights(CRM_AGENCIES, CRM_AGENCIES_POLES, VIEW) ?
            //         {
            //             name : 'Gestion des pôles',
            //             route: ROUTE_CRM_AGENCY,
            //             icone:'ico_gestion-pole'   
            //         } : null,
            //         hasRights(CRM_AGENCIES, CRM_AGENCIES_RATES, VIEW) ?
            //         {
            //             name : 'Gestion des tarifs',
            //             route: ROUTE_CRM_PRICES,
            //             icone:'picto-prix'   
            //         } : null,
            //         hasRights(CRM_AGENCIES, CRM_AGENCIES_TAX_RATES, VIEW) ?
            //         {
            //             name : 'Gestion des taxes',
            //             route: ROUTE_CRM_TAXES,
            //             icone:'ico_gestion-taxe'   
            //         } : null,
            //         hasRights(CRM_AGENCIES, CRM_AGENCIES_PAYMENT_TERMS, VIEW) ?
            //         {
            //             name : 'Conditions de règlement',
            //             route: ROUTE_CRM_PAYMENT_TERMS,
            //             icone:'icon-costs'   
            //         } : null,
            //         hasRights(CRM_AGENCIES, CRM_AGENCIES_PAYMENT_DEADLINES, VIEW) ?
            //         {
            //             name : 'Délais de paiement',
            //             route: ROUTE_CRM_PAYMENT_DEADLINES,
            //             icone:'icon-costs'   
            //         } : null,
            //         // hasRights(CRM_AGENCIES, CRM_AGENCIES_PAYMENT_DEADLINES, VIEW) ?
            //         {
            //             name : 'Gestion des CGVs',
            //             route: ROUTE_CRM_CGV,
            //             icone:'picto-content'   
            //         } 
            //         // : null,
            //     ]
            // },
            {
                name : 'Offres commerciales',
                route: ROUTE_CRM_OFFERS,
                allRoutes : ALL_ROUTE_CRM_OFFERS, 
                childrens:[
                    hasRights(CRM_OFFERS, CRM_OFFERS_DASHBOARD, VIEW) ?
                    {
                        name : 'Dashboard',
                        route: ROUTE_CRM_OFFERS,
                        icone:'icon-dashboard',
                    }: null,
                    hasRights(CRM_OFFERS, CRM_OFFERS_OFFERS, VIEW) ?
                    {
                        name : 'Gestion des devis',
                        route:ROUTE_CRM_OFFERS_LIST,
                        icone:'icon-catalogue',
                        childrens:[ROUTE_CRM_OFFERS_ADD, ROUTE_CRM_OFFERS_EDIT]
                    } : null,
                    // hasRights(CRM_OFFERS, CRM_OFFERS_MODELES, VIEW) ?
                    // {
                    //     name : 'Gérer les modèles de devis',
                    //     route:ROUTE_CRM_OFFERS_MODELES,
                    //     icone:'icon-costs',  
                    //     childrens:[ROUTE_CRM_OFFERS_MODELES_ADD, ROUTE_CRM_OFFERS_MODELES_EDIT]
                    // } : null,
                    {
                        name : 'Gérer les modèles d\'offres',
                        route:ROUTE_CRM_OFFERS_MODELES_PRESENTATIONS,
                        icone:'ico_gestion-modeles',  
                        // childrens:[ROUTE_CRM_OFFERS_MODELES_ADD, ROUTE_CRM_OFFERS_MODELES_EDIT]
                    },
                    {
                        name : 'Gestion des offres',
                        route:ROUTE_CRM_OFFERS_PRESENTATIONS,
                        icone:'picto-content',  
                        // childrens:[ROUTE_CRM_OFFERS_MODELES_ADD, ROUTE_CRM_OFFERS_MODELES_EDIT]
                    },
                    hasRights(CRM_OFFERS, CRM_OFFERS_PROJECTS, VIEW) ?
                    {
                        name : 'Gestion des projets',
                        route:ROUTE_CRM_OFFERS_PROJECTS,
                        icone:'icon-abonement'   
                    } : null,
                    // {
                    //     name : 'Paramètres',
                    //     route:ROUTE_CRM_OFFERS_SETTINGS,
                    //     // icone:'picto-content',  
                    //     isAccordion: true,
                    //     allRoutes : ALL_ROUTE_CRM_OFFERS_SETTINGS, 
                    //     childrens: [
                    //         {
                    //             name : 'Gestion des offres',
                    //             route:ROUTE_CRM_OFFERS_PRESENTATIONS,
                    //             icone:'picto-content',  
                    //             // childrens:[ROUTE_CRM_OFFERS_MODELES_ADD, ROUTE_CRM_OFFERS_MODELES_EDIT]
                    //         },
                    //     ]
                    //     // childrens:[ROUTE_CRM_OFFERS_MODELES_ADD, ROUTE_CRM_OFFERS_MODELES_EDIT]
                    // },
                ]
                
            },
            {
                name : 'Médias',
                route: ROUTE_CRM_MEDIAS,
                allRoutes : ALL_ROUTE_CRM_MEDIAS, 
                childrens:[
                    hasRights(CRM_MEDIAS, CRM_MEDIAS_DASHBOARD, VIEW) ?
                    {
                        name : 'Dashboard',
                        route: ROUTE_CRM_MEDIAS,
                        icone:'icon-dashboard'   
                    } : null,
                    hasRights(CRM_MEDIAS, CRM_MEDIAS_DAM, VIEW) ?
                    {
                        name : 'Digital Assets Manager',
                        route: ROUTE_CRM_MEDIAS_CATEGORIES,
                        icone:'icon-medias'   
                    } : null,

                ]
            },
            {
                name : 'Contacts',
                route: ROUTE_CRM_CONTACTS,
                allRoutes : ALL_ROUTE_CRM_CONTACTS, 
                childrens:[
                    hasRights(CRM_CONTACT, CRM_CONTACT_DASHBOARD, VIEW) ?
                    {
                        name : 'Dashboard',
                        route: ROUTE_CRM_CONTACTS,
                        icone:'icon-dashboard'   
                    } : null,
                    hasRights(CRM_CONTACT, CRM_CONTACT_SOCIETIES, VIEW) ?
                    {
                        name : 'Sociétés',
                        route: ROUTE_CRM_CONTACTS_SOCIETIES,
                        icone:'icon-business',
                        childrens:[ROUTE_CRM_CONTACTS_SOCIETIES_DETAILS]   
                    } : null,
                    hasRights(CRM_CONTACT, CRM_CONTACT_CLIENTS, VIEW) ?
                    {
                        name : 'Clients',
                        route: ROUTE_CRM_CONTACTS_CLIENTS,
                        icone:'icon-clients'   
                    } : null,
                    hasRights(CRM_CONTACT, CRM_CONTACT_LEADS, VIEW) ?
                    {
                        name : 'Leads',
                        route: ROUTE_CRM_CONTACTS_LEADS,
                        icone:'icon-leads'   
                    }: null,
                    hasRights(CRM_CONTACT, CRM_CONTACT_CONTACTS, VIEW) ?
                    {
                        name : 'Contacts',
                        route: ROUTE_CRM_CONTACTS_LIST,
                        icone:'icon-contacts'   
                    } : null,

                ]
            },
            {
                name : 'Paramètres',
                route: ROUTE_CRM_SETTINGS,
                allRoutes : ALL_ROUTE_CRM_SETTINGS, 
                childrens:[
                    hasRights(CRM_SETTINGS, CRM_SETTINGS_LANGUAGES, VIEW) ?
                    {
                        name : 'Gestion des langues',
                        route: ROUTE_CRM_SETTINGS_LANGUAGES,
                        icone:'picto-langues'   
                    } : null,
                    hasRights(CRM_SETTINGS, CRM_SETTINGS_USERS, VIEW) ?
                    {
                        name : 'Gestion des utilisateurs',
                        route: ROUTE_CRM_SETTINGS_USERS,
                        icone:'picto-profil', 
                        childrens: [ROUTE_CRM_SETTINGS_DETAIL_USER]
                    } : null,
                    hasRights(CRM_AGENCIES, CRM_AGENCIES_POLES, VIEW) ?
                    {
                        name : 'Gestion des pôles',
                        route: ROUTE_CRM_AGENCY,
                        icone:'ico_gestion-pole'   
                    } : null,
                    hasRights(CRM_AGENCIES, CRM_AGENCIES_RATES, VIEW) ?
                    {
                        name : 'Gestion des tarifs',
                        route: ROUTE_CRM_PRICES,
                        icone:'picto-prix'   
                    } : null,
                    hasRights(CRM_AGENCIES, CRM_AGENCIES_TAX_RATES, VIEW) ?
                    {
                        name : 'Gestion des taxes',
                        route: ROUTE_CRM_TAXES,
                        icone:'ico_gestion-taxe'   
                    } : null,
                    hasRights(CRM_AGENCIES, CRM_AGENCIES_PAYMENT_TERMS, VIEW) ?
                    {
                        name : 'Conditions de règlement',
                        route: ROUTE_CRM_PAYMENT_TERMS,
                        icone:'icon-costs'   
                    } : null,
                    hasRights(CRM_AGENCIES, CRM_AGENCIES_PAYMENT_DEADLINES, VIEW) ?
                    {
                        name : 'Délais de paiement',
                        route: ROUTE_CRM_PAYMENT_DEADLINES,
                        icone:'icon-costs'   
                    } : null,
                    // hasRights(CRM_AGENCIES, CRM_AGENCIES_PAYMENT_DEADLINES, VIEW) ?
                    {
                        name : 'Gestion des CGVs',
                        route: ROUTE_CRM_CGV,
                        icone:'picto-content'   
                    } 
                    // : null,
                ]
            },
        ]
    }

    else{
        menuItems = [
            // {
            //     name : 'Spread Suite',
            //     route: ROUTE_HOME,
            //     allRoutes : ALL_ROUTE_SPREADSUITE, 
            //     isHome : true,
            //     childrens:[
            //         {
            //             name : 'Accueil',
            //             route:ROUTE_HOME,
            //             icone:'icon-accueil'   
            //         },
            //         {
            //             name : 'Calendrier',
            //             route:ROUTE_CALENDAR,
            //             icone:'icon-calendrier'   
            //         },
            //     ]
            // },
            {
                name : 'Dashboard',
                route: ROUTE_STATS,
                allRoutes : ALL_ROUTE_STATS, 
                childrens:[
                    hasRights(DASHBOARD, DASHBOARD_STATISTICS, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                    {
                        name : 'Statistiques',
                        route: ROUTE_STATS,
                        icone:'icon-statistique'   
                    } : null,
                    hasRights(DASHBOARD, DASHBOARD_RETAILERS, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                    {
                        name : 'Par retailers',
                        route:  ROUTE_STATS_RETAILERS,
                        icone:'picto-retailer'   
                    } : null,
                    hasRights(DASHBOARD, DASHBOARD_ASSETS, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                    {
                        name : 'Par assets',
                        route:  ROUTE_STATS_ASSETS,
                        icone:'icon-asset'   
                    } : null,
                    hasRights(DASHBOARD, DASHBOARD_CAMPAIGN, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                    {
                        name : 'Par campagnes',
                        route:  ROUTE_STATS_CAMPAIGNS,
                        icone:'icon-campagne'
                    } : null,
                ]
            },
            {
                name : 'Diffusion',
                route: ROUTE_DIFFUSION,
                allRoutes : ALL_ROUTE_DIFFUSION, 
                childrens:[
                    hasRights(BROADCAST, BROADCAST_CALENDAR, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                    {
                        name : 'Calendrier de diffusion',
                        route: ROUTE_DIFFUSION_CALENDAR,
                        icone: 'icon-calendrier'
                    } : null,
                    hasRights(BUILDER, BUILDER_ASSETS, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                    {
                        name : 'Assets actifs',
                        route: ROUTE_DIFFUSION_PERMANENT,
                        icone:'icon-permanent',
                        childrens:[ROUTE_DIFFUSION_PERMANENTS_PAGE, ROUTE_DIFFUSION_PERMANENTS_ADD_POST, ROUTE_DIFFUSION_PERMANENTS_POSTS]
                    } : null,
                    hasRights(BROADCAST, BROADCAST_CAMPAIGN, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                    {
                        name : 'Campagne',
                        route: ROUTE_DIFFUSION_CAMPAIGNS,
                        icone:'icon-campagne',
                        childrens:[ROUTE_DIFFUSION_CAMPAIGNS_ADD, ROUTE_DIFFUSION_CAMPAIGNS_DETAIL]

                    } : null,
                    hasRights(BROADCAST, BROADCAST_RETAILERS, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                    {
                        name : 'Retailers',
                        route:  ROUTE_DIFFUSION_RETAILERS,
                        icone:'picto-retailer'   
                    } : null,
                ]
            },
            {
                name : 'Spread Builder',
                route: ROUTE_ENGINE,
                allRoutes : ALL_ROUTE_ENGINE, 
                childrens:[
                    hasRights(BUILDER, BUILDER_DASHBOARD, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                    {
                        name : 'Dashboard',
                        route: ROUTE_ENGINE,
                        icone:'icon-dashboard'   
                    } : null,
                    hasRights(BUILDER, BUILDER_ASSETS, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                    {
                        name : 'Vue des assets',
                        route: ROUTE_ENGINE_ASSETS,
                        icone:'icon-asset',
                        childrens:[ROUTE_ENGINE_ASSETS_DETAIL_STATS,ROUTE_ENGINE_ASSETS_DETAIL] 
                    } : null,
                ]
            },
            {
                name : 'Commandes',
                route: ROUTE_ORDERS,
                allRoutes : ALL_ROUTE_ORDERS,
                childrens: [
                    process.env.REACT_APP_MODE_SPREAD === "hub" ?
                    {
                        name : 'Commandes',
                        route: ROUTE_ORDERS_LIST,
                        icone:'icon-costs',
                        childrens:[ROUTE_ORDERS_DETAIL]   
                    }
                    : null,
                    process.env.REACT_APP_MODE_SPREAD === "hub" ?
                    {
                        name : 'Précommande',
                        route: ROUTE_ORDERS_LIST_PREORDER,
                        icone:'icon-costs',
                        // childrens:[ROUTE_ORDERS_DETAIL]   
                    }
                    : null,
                    process.env.REACT_APP_MODE_SPREAD === "hub" ?
                    {
                        name : 'Retard',
                        route: ROUTE_ORDERS_LIST_DELAY,
                        icone:'icon-costs',
                        // childrens:[ROUTE_ORDERS_DETAIL]   
                    }
                    : null,
                    process.env.REACT_APP_MODE_SPREAD === "hub" ?
                    {
                        name : 'Retour',
                        route: ROUTE_ORDERS_LIST_RETURN,
                        icone:'icon-costs',
                        // childrens:[ROUTE_ORDERS_DETAIL]   
                    }
                    : null,   
                ]
            },
            {
                name : process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'Catalogues' : 'Entrepôts',
                route: ROUTE_CATALOGS,
                allRoutes: ALL_ROUTE_CATALOGS, 
                // notAccordion: true,
                childrens:[
                    hasRights(CATALOGS, CATALOGS_DASHBOARD, VIEW) ?
                    {
                        name : 'Dashboard',
                        route:  ROUTE_CATALOGS,
                        icone:'icon-dashboard',
                    } : null,
                    hasRights(CATALOGS, CATALOGS_CATALOGS, VIEW) ?
                    {
                        name : process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'Catalogues' : 'Entrepôts',
                        route:  ROUTE_CATALOGS_LIST,
                        icone:'icon-catalogue',
                        childrens:[ROUTE_CATALOGS_DETAIL]
                    } : null,
                ]
            },
            {
                name : 'Médias',
                route: ROUTE_MEDIAS,
                allRoutes : ALL_ROUTE_MEDIAS, 
                childrens:[
                    hasRights(MEDIAS, MEDIAS_DASHBOARD, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                    {
                        name : 'Dashboard',
                        route: ROUTE_MEDIAS,
                        icone:'icon-digital-assets-manager'   
                    } : null,
                    hasRights(MEDIAS, MEDIAS_CATEGORIES, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                    {
                        name : 'Catégories',
                        route: ROUTE_MEDIAS_CATEGORIES,
                        icone:'picto-categorie'   
                    } : null,
                    hasRights(MEDIAS, MEDIAS_MEDIAS, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                    {
                        name : 'Médias',
                        route: ROUTE_MEDIAS_LIST,
                        icone:'icon-medias'   
                    } : null,
                    hasRights(MEDIAS, MEDIAS_TAGS, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                    {
                        name : 'Tags',
                        route: ROUTE_MEDIAS_TAGS,
                        icone:'picto-label'   
                    } : null,
                ]
            },
            {
                name : 'Contenu',
                route: ROUTE_CONTENU,
                allRoutes : ALL_ROUTE_CONTENU, 
                childrens:[
                    hasRights(CONTENTS, CONTENTS_DASHBOARD, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                    {
                        name : 'Dashboard',
                        route: ROUTE_CONTENU,
                        icone:'icon-dashboard'   
                    } : null,
                    hasRights(CONTENTS, CONTENTS_CATEGORIES, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                    {
                        name : 'Catégories',
                        route: ROUTE_CONTENU_CATEGORIES,
                        icone:'picto-categorie'   
                    } : null,
                    hasRights(CONTENTS, CONTENTS_CONTENTS, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                    {
                        name : 'Contenu',
                        route: ROUTE_CONTENU_LIST,
                        icone:'picto-content',
                        childrens:[ROUTE_CONTENU_ADD, ROUTE_CONTENU_LIST_DETAIL] 
                    } : null,
                    hasRights(CONTENTS, CONTENTS_MODELS, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                    {
                        name : 'Modèles',
                        route:  ROUTE_CONTENU_ATTRIBUTES,
                        icone:'picto-template'   
                    } : null,

                ]
            },
            {
                name : 'Produits',
                route: ROUTE_PRODUCTS,
                allRoutes : ALL_ROUTE_PRODUCTS, 
                childrens:[
                    hasRights(PRODUCTS, PRODUCTS_DASHBOARD, VIEW) ?
                    {
                        name : 'Dashboard',
                        route: ROUTE_PRODUCTS,
                        icone:'icon-dashboard'   
                    } : null,
                    hasRights(PRODUCTS, PRODUCTS_CATEGORIES, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                    {
                        name : 'Catégories',
                        route: ROUTE_PRODUCTS_CATEGORIES,
                        icone:'picto-categorie'   
                    } : null,
                    hasRights(PRODUCTS, PRODUCTS_PRODUCTS, VIEW) ?
                        {
                            name : 'Produits',
                            route: ROUTE_PRODUCTS_LIST,
                            icone:'picto-produit',
                            childrens:[ROUTE_PRODUCTS_ADD, ROUTE_PRODUCTS_LIST_DETAIL]   
                        }
                    : null,
                    hasRights(PRODUCTS, PRODUCTS_ATTRIBUTES, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                    {
                        name : 'Attributs',
                        route: ROUTE_PRODUCTS_ATTRIBUTES,
                        icone:'picto-attribut'   
                    } : null,

                ]
            },
            {
                name : process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'Connecteurs' : 'Channels',
                route: ROUTE_CHANNELS,
                allRoutes : ALL_ROUTE_CHANNELS, 
                childrens:[
                    hasRights(CONNECTORS, CONNECTORS_DASHBOARD, VIEW) ?
                    {
                        name : 'Dashboard',
                        route: ROUTE_CHANNELS,
                        icone:'icon-dashboard'   
                    } : null,
                    hasRights(CONNECTORS, CONNECTORS_RETAILERS, VIEW) ?
                    {
                        name : process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'Retailers' : "Entrepôts",
                        route: ROUTE_CHANNELS_RETAILERS,
                        icone:'picto-retailer'
                    } : null,
                    hasRights(CONNECTORS, CONNECTORS_RETAILERS, VIEW) ?
                    {
                        name : process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'Marques' : "ERP",
                        route: ROUTE_CHANNELS_BRANDS,
                        icone:'icon-business'   
                    } : null,
                    hasRights(CONNECTORS, CONNECTORS_MARKETPLACES, VIEW) ?
                    {
                        name : 'Marketplaces',
                        route: ROUTE_CHANNELS_MARKETPLACES,
                        icone:'picto-marketplace'   
                    } : null,
                    hasRights(CONNECTORS, CONNECTORS_SOCIAL_NETWORK, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                    {
                        name : 'Réseaux Sociaux',
                        route: ROUTE_CHANNELS_SOCIALS,
                        icone:'picto-rs'   
                    } : null,
                    hasRights(CONNECTORS, CONNECTORS_CMS, VIEW) ?
                    {
                        name : process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'CMS' : "Ventes",
                        route: ROUTE_CHANNELS_CMS,
                        icone:'icon-cms'   
                    } : null,
                    hasRights(CONNECTORS, CONNECTORS_EMAILS, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                    {
                        name : 'Emails',
                        route: ROUTE_CHANNELS_MAILER,
                        icone:'picto-newsletter'   
                    } : null,
                    hasRights(CONNECTORS, CONNECTORS_CONTENTS, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                    {
                        name : 'Contenus',
                        route:  ROUTE_CHANNELS_CONTENTS,
                        icone:'picto-connector-content'   
                    } : null,
                    hasRights(CONNECTORS, CONNECTORS_MEDIAS, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                    {
                        name : 'Medias',
                        route:  ROUTE_CHANNELS_MEDIAS,
                        icone: 'picto-media'   
                    } : null,
                    hasRights(CONNECTORS, CONNECTORS_CONNECTORS, VIEW) ?
                    {
                        name : process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'Connector\'s' : 'Logisticiens',
                        route:  ROUTE_CHANNELS_CONNECTORS,
                        icone: 'picto-connector'   
                    } : null,
                ]
            },
            {
                name : 'Brand',
                route: ROUTE_BRAND_GUIDELINE,
                allRoutes : ALL_ROUTE_BRAND, 
                childrens:[
                    hasRights(BRAND, BRAND_GUIDELINE, VIEW) ?
                    {
                        name : 'Guideline',
                        route: ROUTE_BRAND_GUIDELINE,
                        icone: 'icon-guideline',
                        childrens: [ROUTE_BRAND_GUIDELINE_ADD, ROUTE_BRAND_GUIDELINE_UPDATE]  
                    } : null,
                    hasRights(BRAND, BRAND_FLOW, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                    {
                        name : 'Mes flux',
                        route: ROUTE_BRAND_FLOW,
                        icone: 'icon-flux'   
                    } : null,

                ]
            },
            {
                name : 'Paramètres',
                route: ROUTE_SETTINGS,
                allRoutes : ALL_ROUTE_SETTINGS, 
                childrens:[
                    hasRights(SETTINGS, SETTINGS_LANGUAGES, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                    {
                        name : 'Gestion des langues',
                        route: ROUTE_SETTINGS_LANGUAGES,
                        icone:'picto-langues'   
                    } : null,
                    hasRights(SETTINGS, SETTINGS_CURRENCIES, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                    {
                        name : 'Gestion des devises',
                        route: ROUTE_SETTINGS_CURRENCIES,
                        icone:'picto-devises'
                    } : null,
                    hasRights(SETTINGS, SETTINGS_USERS, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                    {
                        name : 'Gestion des utilisateurs',
                        route: ROUTE_SETTINGS_USERS,
                        icone:'picto-profil',
                        childrens:[ROUTE_SETTINGS_DETAIL_USER] 
                    } : null,
                    process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                    {
                        name : 'Gestion des droits',
                        route: ROUTE_SETTINGS_ACCESS,
                        icone:'verified',
                    } : null,
                ]
            },
        ]
    }
    return menuItems
}