import {FORM} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';

export default function exportProducts(allLangs, typeExport){
    let allLanguages =[
        {
            value: 'all',
            label: 'Toutes les langues',
            disabled: true
        }
    ]
    for (let lang of allLangs){
        allLanguages.push(
            {
                value: lang.node.code,
                label: lang.node.libelle
            }
        )
    }
    // allLanguages.concat(getLanguages)
    var obj = {
        titleForm: typeExport === "products" ? "Exporter les produits" : "Exporter les catégories",
        subTitleForm: typeExport === "products" ? "Veuillez compléter les champs ci-dessous pour exporter vos produits" : "Veuillez compléter les champs ci-dessous pour exporter vos catégories",
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: FORM,
            children: [
                {
                    optionsInputs: [
                        {
                            type: 'text',
                            label: 'FileName',
                            helper: {
                                text: 'Nom du fichier',
                                link: false,
                            },
                            required: true,
                            stateName: 'exportName',
                            translated: false,
                        },
                        {
                            type: 'select',
                            label: typeExport === "products" ? 'Langue des produits' : 'Langue des catégories',
                            translated: false,
                            helper: {
                                text: typeExport === "products" ? 'Indiquez la langue des produits exportés' : 'Indiquez la langue des catégories exportées',
                                link: false,
                            },
                            required: true,
                            stateName: 'exportLang',
                            value: allLanguages
                        },
                        {
                            type: 'select',
                            label: 'Type d\'exportation',
                            translated: false,
                            helper: {
                                text: 'Indiquez le type d\'exportation',
                                link: false,
                            },
                            required: true,
                            stateName: 'exportType',
                            value: 
                                [
                                    {
                                        value : 'csv',
                                        label : '.csv', 
                                    },
                                    {
                                        value : 'xls',
                                        label : '.xls', 
                                    }
                                ]
                        },
                    ]
                },
            ]
        }
    }
    return obj;
}