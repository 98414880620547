import React from 'react';

import { Box, Grid } from '@material-ui/core';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import Typography from '../../../ui/typography/Typography';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components'
import iconSet from "../../../../assets/selection.json";
import IcomoonReact from "icomoon-react";

const SeeMore=styled(Box)`
    width: 100%;
    transition: all .5s;
    transform:translateX(0px);
    &:hover{
        transform:translateX(-4px);
        .see-more{
            transform:translateX(10px);
        }
        .MuiTypography-body1{
            color: ${props => props.colortexthover ? props.colortexthover : ''};
        }
        .MuiTypography-h5{
            color: ${props => props.colortexthover ? props.colortexthover : ''};
        }
    }
    .see-more{
        transition: all .5s;
        transform:translateX(0px);
    }
`
    export default function CardActions(props){
    const action = props.action;

    return(
        <SeeMore style={{cursor:'pointer'}} onClick={() => {props.goTo(action.route, action.routeState ? action.routeState : null)}} colortexthover={action.colorHover ? action.colorHover : action.color ? action.color : colors.white}>
            <Box pl={2} pb={2}>
                <IcomoonReact iconSet={iconSet} color={action.colorPicto ? action.colorPicto : colors.white} size={65} icon={action.icon} className="actionIcone" />
            </Box>
            <Typography component="div"  variant="body1" colortext={action.color ? action.color : colors.white} style={{fontWeight: 'bold'}}>
                <Box pl={2} pb={1} pt={2}>
                    <Grid container justify="space-between">
                        <Grid item xs={10}>
                            {action.title}
                        </Grid>
                        <Grid item xs={2}>
                            <ArrowForwardIosRoundedIcon fontSize="small"  className="see-more"/>
                        </Grid>
                    </Grid>
                </Box>
            </Typography>
            <Typography component="div" colortext={action.color ? action.color : colors.white} variant="h5" style={{fontStyle: 'italic'}}>
                <Box pl={2} pb={2}>
                    {action.subtitle}
                </Box>
            </Typography>
        </SeeMore>
    );
}