import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import FolderIcon from '@material-ui/icons/Folder';
import Button from '../../../../ui/button/Button';
import Chip from '@material-ui/core/Chip';
import colors from '../../../../../config/theme/colors'
import styled from 'styled-components';
import GetAppIcon from '@material-ui/icons/GetApp';
import WarningIcon from '@material-ui/icons/Warning';

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: '60%',
        backgroundColor: theme.palette.background.paper,
        border: '0px solid transparent',
        padding:50,
        top:'50%',
        left:'50%',
        transform:'translate(-50%,-50%)',
        outline:'none',
        minHeight:400
    },
    navContainer: {
        top:'50%',
        left:'50%',
        transform:'translate(-50%,-50%)',
        position:'absolute',
        width:'100%',
        fontSize:70,
        color:'white',  
    },

    arrow : {
        position:'absolute',
        cursor:'pointer',
        transform : 'scale(1)',
        transition : 'all .5s',
        "&:hover" : {
            transform : 'scale(1.1)',
        }
    },

    prevArrow : {
        left : -100,
    },

    nextArrow : {
        right : -100,
    }
    
}));

const ImageContainer = styled(Grid)`
    max-height:800px;
    overflow:scroll;
    img,video{
        width:100%;
    }
`;

const CloseModal=styled(CloseIcon)`
    position : absolute;
    top:20px;
    right:20px;
    cursor:pointer;
    transform : scale(1);
    transition : all .5s;

    &:hover{
        transform : scale(1.1);
    }
`
const MediaOption=styled(Grid)`
    position : absolute;
    right:50px;
    bottom:50px;
`

function getMediaType(type){
    var res=type.substring(type.indexOf('/')+1).toUpperCase();
    return res;
}
export default function MediaModal(props) {
    
    
    let modalData       = props.modalData; 
    let size            = modalData.size;
    let currentIndex    = modalData.index;
    let round           = 0; 
    let isVideo         = false;
    if(['video/mpeg','video/webm','video/mp4'].includes(modalData.type)){
        isVideo=true;
    }
    let mimeType        = modalData.type;
    let type            = getMediaType(modalData.type);
    let convertSize;
    let unit;

    
    

    if(size >= 1048576){
        convertSize = size/1048576;
        unit        = 'Mo';
        round       = 1;
    }
    else if(size >= 1024){
        convertSize = size/1024;
        unit        = 'Ko';
    }
    convertSize=convertSize.toFixed(round);
    const classes   = useStyles();
    

    return (
        
        <Modal
            open                = {props.open}
            onClose             = {props.onClose}
            aria-labelledby     = "simple-modal-title"
            aria-describedby    = "simple-modal-description"
            style               = {{border:'0px',outline:'none'}}
            BackdropComponent   = {Backdrop}
            BackdropProps       = {{timeout: 500}}
        >
            <Fade in={props.open}>

                <div className={classes.paper}>
                    <CloseModal onClick={props.onClose} />
                    <Grid item xs={12} className={classes.navContainer}>
                        {currentIndex !== 0 ? 
                            <ArrowBackIosIcon 
                                fontSize    = {'inherit'} 
                                color       = {'inherit'} 
                                className   = {`${classes.arrow} ${classes.prevArrow}`}
                                onClick     = {()=>{props.handleNav('prev',currentIndex)}}
                            />
                        :null}
                        {currentIndex<props.lastMedia ? 
                            <ArrowForwardIosIcon 
                                fontSize    = {'inherit'} 
                                color       = {'inherit'} 
                                className   = {`${classes.arrow} ${classes.nextArrow}`}
                                onClick     = {()=>{props.handleNav('next',currentIndex)}}
                            />
                        :null}                        
                    </Grid>

                    <Grid container spacing={4}>

                        <ImageContainer item lg={7} >
                            {isVideo ?
                            <video controls autoPlay>

                        
                            <source src={`${process.env.REACT_APP_API_ROOT}/medias/${modalData.filePath }`}
                                    type={mimeType}/>
                        
                            Sorry, your browser doesn't support embedded videos.
                            </video>
                        
                            :
                            <img src={`${process.env.REACT_APP_API_ROOT}/medias/${modalData.filePath }`} />
                            }
                            
                        </ImageContainer>
                        <Grid item lg={5}>
                            <Typography variant="h3" style={{overflowWrap:'break-word'}}>{modalData.name ? modalData.name : modalData.filePath  }</Typography>
                            <Chip label={type} style={{marginTop:25}}/>
                            {modalData.category.edges?.length>0 ?
                                <Typography variant="h4" style={{display:'flex',alignItems:'center',marginTop:20}}>
                                    <FolderIcon size="inherit" style={{marginRight:10}}/> 
                                    {modalData.category.edges.map((category,index)=>
                                        <>
                                            {category.node.libelle}
                                            {index+1<modalData.category.edges.length ?
                                            <span style={{marginRight:5}}>,</span>
                                            :null
                                            }
                                       </> 
                                    )}
                                   
                                </Typography>                            
                            :null
                            }
                            <Typography variant="h4" style={{marginTop:20,display:'flex',alignItems:'center'}}>
                                Texte Alternatif : 
                 


                            </Typography>
                            <Typography variant="body2" style={{fontStyle:'italic'}}>
                                {modalData.alt 
                                    ? 
                                    modalData.alt
                                    :
                                    <Tooltip title="Vous n'avez pas définis de texte alternatif pour cette image" placement="right" arrow>
                                        <WarningIcon style={{color:colors.red.regular}}/>
                                    </Tooltip>
                                }
                            </Typography>
                            <Typography variant="body2" style={{marginTop:25}}>{convertSize} {unit}</Typography>

                            
                            <MediaOption>
                           
                                    <Button 
                                    variant         = "contained" 
                                    bgcolor         = {colors.white} 
                                    bgcolorhover    = {colors.blue.regular}
                                    color           = {colors.blue.regular}
                                    colorhover      = {colors.white}
                                    border          = {`1px solid ${colors.blue.regular}`}
                                    onClick         = {()=>{ window.open(`${process.env.REACT_APP_API_ROOT}/medias/${modalData.filePath }`, '_blank');}}
                                    text            = {<GetAppIcon/> }
                                    />
                                        
                                            
                                    
                                {props.readOnly === true || !props.handleDeleteModal ?
                                null
                                :
                                    <Button 
                                        variant         = "contained" 
                                        bgcolor         = "#a62626" 
                                        bgcolorhover    = "#c24848" 
                                        style           = {{marginLeft:20}} 
                                        onClick         = {()=>props.handleDeleteModal(modalData.id)}
                                        text            = 'Supprimer'
                                    />                                   
                                }
                          
                            </MediaOption>

                        </Grid> 
                    </Grid>

                </div>

            </Fade>
        </Modal>

    );
}