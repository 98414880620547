import React, { useEffect, useState } from "react";
import Swiper from "react-id-swiper";
import request from "../../../../../tools/Fetch";

const ImageRow = (props) => {
    const [hover, setHover] = useState(false);
    const [posts, setPosts] = useState([]);
    const [error, setError] = useState(true);

    const { 
        preview = false,  
        id = null, 
        inputCallback = null,
        update,
        children,
        spread = false
    } = props;

    const settings = {
        loop: false,
        grabCursor: true,
        shouldSwiperUpdate: true,
        rebuildOnUpdate: false,
        breakpoints: {
            1024: {
                slidesPerView: 5
            },
            768: {
                slidesPerView: 4
            },
            640: {
                slidesPerView: 3
            },
            320: {
                slidesPerView: 2
            }
        }
    };

    useEffect(() => {
        const fetch = async () => {
            try {
                let result = await request(`${process.env.REACT_APP_API}/assets/instagram/instagram_demo/${props.data.inputs.identifier.value}`);

                if (result.posts?.length > 0) {
                    props.data.posts = result.posts;

                    if (update)
                        update();
                }
                
                setPosts(result.posts.slice(0, 10));
                setError(false);
            } catch(e) {
                setError(true);
            }
        };

        if (props.data.posts?.length > 4)
            setPosts(props.data.posts);
        
        fetch();
    }, [props.data?.inputs?.identifier?.value]);

    const style = {
        // boxShadow: !preview && hover ? '#2B4553 0 0 0px 2px inset' : 'none',
        marginBottom: 20,
        position: 'relative',
        width: '100%'
    };

    return (
        <div 
            className="builder-template-scope image-slider-area"
            onMouseEnter={() => setHover(true)} 
            onMouseLeave={() => setHover(false)}
            style={{
                ...style,
                minHeight: 150
            }}
        >
            { !preview && hover && children }

            { error && spread && (
                <>
                    <h4 style={{
                        padding: 40,
                        paddingTop: 35,
                        paddingBottom: 10,
                        textAlign: 'center'
                    }}>Configuration requise</h4>
                    <h5 style={{
                        textAlign: 'center'
                    }}>Veuillez paramétrer un compte Instagram dans Spread et vérifier que le nom du compte est correct</h5>
                </>
            )}

            { (!error && !posts && !posts.length) && (
                <h4 style={{
                    padding: 40,
                    paddingTop: 35,
                    textAlign: 'center'
                }}>Chargement...</h4>
            ) }

            <div className="image-slider-active">
                <Swiper {...settings}>
                    {
                        posts && posts.map((single, key) => {
                            return (
                                <div className={`single-image swiper-slide`} key={key}>
                                    <a onClick={(e) => e.preventDefault()} href={single.link}>
                                        <img src={single.thumbnail} style={{ width: 'auto', height: 260 }} alt="" />
                                    </a>
                                </div>
                            );
                        })
                    }
                </Swiper>
            </div>
        </div>
    );
};

export default ImageRow;
