import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import { v4 as uuidv4 } from "uuid";
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import {
    fade,
    withStyles
} from '@material-ui/core/styles';
import './OfferManager.scss';
import { getTotals } from './Calculator';

// Styles 

const Accordion = withStyles({
    root: {
        padding: '0 !important',
        margin: '0 0 16px 0 !important',
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#333333',
        minHeight: 56,
        borderRadius: 4,
        '&$expanded': {
            minHeight: 56,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionSummaryPink = withStyles({
    root: {
        backgroundColor: '#CF3657',
    },
    expanded: {},
})(AccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        flexDirection: 'column'
    },
}))(MuiAccordionDetails);

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        width: 'auto',
        padding: props => props.mini ? '4px 4px' : '8px 8px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}))(InputBase);

const BootstrapReadonlyInput = withStyles((theme) => ({
    input: {
        backgroundColor: '#ccc',
        border: 'none',
        '&:focus': {
            boxShadow: 'none'
        },
    }
}))(BootstrapInput);

const PhaseInput = withStyles((theme) => ({
    input: {
        color: '#fff',
        borderColor: '#fff',
        '&:focus': {
            borderColor: '#fff',
        },
    },
}))(Input);

const OutlinedInputCustom = withStyles((theme) => ({
    root: {
        width: '100%'
    },
    input: {
        padding: '10px 8px',
        width: '100%'
    },
}))(OutlinedInput);

const FullWidthSelect = withStyles((theme) => ({
    root: {
        width: '100%'
    },
}))(Select);

// Sortable

const DragHandle = sortableHandle(() => {
    return <MenuIcon style={{ color: '#000', marginRight: 10, cursor: 'pointer' }} />;
});

const SortableContainer = sortableContainer(({ children }) => {
    return <div>{children}</div>;
});

const SortableSubtask = sortableElement(({ subtask, onChange, listTaxes, poles }) => (
    <div className="subtask">
        <div className="subtask-name">
            <DragHandle />

            <Input value={subtask.name} onChange={(e) => {
                subtask.name = e.target.value;
                subtask.updated = true;
                onChange();
            }} />

            <Input value={subtask.ref} style={{ marginLeft: 10 }} onChange={(e) => {
                subtask.ref = e.target.value;
                subtask.updated = true;
                onChange();
            }} />

            <DeleteIcon style={{ color: 'red', marginLeft: 10 }} className="tache-delete" onClick={() => {
                subtask.deleted = true;
                onChange();
            }} />
        </div>

        <OfferManagerTaskLine tache={subtask} mini={true} onChange={onChange} listTaxes={listTaxes} poles={poles} />
    </div>
));

// Components

class OfferManagerTaskLine extends React.Component {
    _calculate = (task) => {
        if (isNaN(task.qty))
            task.qty = 0;

        if (isNaN(task.price))
            task.price = 0;

        if (isNaN(task.remise))
            task.remise = 0;

        let HT = task.qty * task.price;
        let total = HT;

        if (task.typeRemise === '€') {
            total = total - task.remise;
        } else if (task.typeRemise === '%') {
            const DISCOUNT_coef = 1 - task.remise / 100;
            total = total * DISCOUNT_coef;
        }

        return total;
    };

    render() {
        const { tache, onChange, mini, listTaxes, poles } = this.props;

        return (
            <div className="tache-line">
                <FormControl className="input">
                    <InputLabel shrink htmlFor={`${tache.id}-qty`}>
                        QTÉ
                    </InputLabel>
                    <BootstrapInput id={`${tache.id}-qty`} style={{ width: 50, padding: 0 }} mini={mini} value={tache.qty} onChange={(e) => {
                        tache.qty = e.target.value;
                        tache.total = this._calculate(tache);
                        tache.updated = true;
                        onChange();
                    }} />
                </FormControl>

                <FormControl className="input">
                    <InputLabel shrink htmlFor={`${tache.id}-price`}>
                        PU HT
                    </InputLabel>
                    <BootstrapInput id={`${tache.id}-price`} style={{ width: 60 }} mini={mini} value={tache.price} onChange={(e) => {
                        tache.price = e.target.value;
                        tache.total = this._calculate(tache);
                        tache.updated = true;
                        onChange();
                    }} />
                </FormControl>

                <FormControl className="input">
                    <InputLabel id={`${tache.id}-tva`}>TVA</InputLabel>
                    <Select
                        labelId={`${tache.id}-tva`}
                        value={tache.tva}
                        onChange={(e) => {
                            tache.tva = e.target.value;
                            tache.total = this._calculate(tache);
                            tache.updated = true;
                            onChange();
                        }}
                        input={<BootstrapInput mini={mini} />}
                    >
                        {listTaxes.map((tax, i) => <MenuItem value={tax.node.id} key={i}>{tax.node.name}</MenuItem>)}
                    </Select>
                </FormControl>

                <FormControl className="input small-margin">
                    <InputLabel shrink htmlFor={`${tache.id}-remise`}>
                        REMISE
                    </InputLabel>
                    <BootstrapInput id={`${tache.id}-remise`} style={{ width: 60 }} mini={mini} value={tache.remise} onChange={(e) => {
                        tache.remise = e.target.value;
                        tache.total = this._calculate(tache);
                        tache.updated = true;
                        onChange();
                    }} />
                </FormControl>

                <FormControl className="input">
                    <InputLabel htmlFor={`${tache.id}-type-remise`}></InputLabel>
                    <Select
                        labelId={`${tache.id}-type-remise`}
                        value={tache.typeRemise}
                        onChange={(e) => {
                            tache.typeRemise = e.target.value;
                            tache.total = this._calculate(tache);
                            tache.updated = true;
                            onChange();
                        }}
                        input={<BootstrapInput mini={mini} />}
                    >
                        <MenuItem value={"%"}>%</MenuItem>
                        <MenuItem value={"€"}>€</MenuItem>
                    </Select>
                </FormControl>

                <FormControl className="input">
                    <InputLabel shrink htmlFor={`${tache.id}-total`}>
                        T. HT
                    </InputLabel>
                    <BootstrapReadonlyInput
                        id={`${tache.id}-total`}
                        readOnly={true}
                        mini={mini}
                        value={tache.total}
                        style={{ width: 60 }} />
                </FormControl>

                <FormControl className="input">
                    <InputLabel id={`${tache.id}-poles`} shrink={true}>PÔLES</InputLabel>
                    <Select
                        labelId={`${tache.id}-poles`}
                        multiple
                        value={tache.poles}
                        onChange={(event) => {
                            const { value } = event.target;
                            tache.poles = value;
                            tache.updated = true;
                            onChange();
                        }}
                        input={<BootstrapInput mini={mini} />}
                        displayEmpty={true}
                        renderValue={(selected) => {
                            if (selected.length === 0)
                                return <span>Choisir...</span>;
                            
                            return selected.map((value, index) => `${poles.find(e => e.node.id === value)?.node.name}${index === selected.length - 1 ? '': ', '}`) 
                        }}
                    >
                        { poles.map((pole) => (
                            <MenuItem key={pole.node.id} value={pole.node.id} style={{
                                fontWeight: tache.poles.find(e => e === pole.node.id) ? 'bold' : 'normal'
                            }}>
                                { pole.node.name }
                            </MenuItem>
                        )) }
                    </Select>
                </FormControl>
            </div>
        );
    }
}

class OfferManagerTask extends React.Component {
    _onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex === newIndex) return;

        let { tache, onChange } = this.props;

        tache.taches = arrayMove(tache.taches, oldIndex, newIndex);

        // for (let tache of tache.tache)
        //     tache.ordered = true;

        onChange();
    };

    render() {
        const { tache, onChange, defaultPrice, listTaxes, poles } = this.props;

        let allSubTaches = tache.taches.filter(e => !e.deleted);

        return (
            <div className="tache">
                <div className="tache-name">
                    <Input value={tache.name} onChange={(e) => {
                        tache.name = e.target.value;
                        tache.updated = true;
                        onChange();
                    }} />

                    <Input value={tache.ref} style={{ marginLeft: 10 }} onChange={(e) => {
                        tache.ref = e.target.value;
                        tache.updated = true;
                        onChange();
                    }} />

                    <DeleteIcon style={{ color: 'red', marginLeft: 10 }} className="tache-delete" onClick={() => {
                        tache.deleted = true;
                        onChange();
                    }} />
                </div>

                <OfferManagerTaskLine tache={tache} onChange={onChange} listTaxes={listTaxes} poles={poles} />

                <SortableContainer onSortEnd={this._onSortEnd} helperClass='sortable-helper' useDragHandle>
                    {allSubTaches.map((subtask, i) => (
                        <SortableSubtask
                            index={i}
                            key={`item-${subtask.id}`}
                            subtask={subtask}
                            listTaxes={listTaxes}
                            poles={poles}
                            onChange={onChange}
                        />
                    ))}
                </SortableContainer>

                <div className="add-sub-task" onClick={() => {
                    tache.taches.push({
                        id: uuidv4(),
                        new: true,
                        qty: 0,
                        price: defaultPrice,
                        tva: listTaxes?.[0]?.node.id,
                        remise: 0,
                        typeRemise: '%',
                        total: 0,
                        name: "Nouvelle sous-tâche",
                        ref: 'Référence',
                        taches: [],
                        poles: []
                    });

                    onChange();
                }}>
                    <AddIcon style={{ fontSize: 15 }} />
                    Ajouter une sous-tâche
                </div>
            </div>
        );
    }
}

class OfferManagerPhase extends React.Component {
    render() {
        const { phase, onChange, defaultPrice, listTaxes, poles } = this.props;

        let allTaches = phase.taches.filter(e => !e.deleted);

        return (
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ArrowDropDownIcon style={{ color: '#fff', fontSize: 30 }} />}
                >
                    <div className="phase-name">
                        <PhaseInput
                            value={phase.name}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            onChange={(e) => {
                                phase.name = e.target.value;
                                phase.updated = true;
                                onChange();
                            }}
                        />

                        <span className="delete-phase" onClick={(e) => {
                            e.stopPropagation();
                            phase.deleted = true;
                            onChange();
                        }}>Supprimer</span>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="taches">
                        {allTaches.map((tache, i) => <OfferManagerTask tache={tache} key={i} onChange={onChange} defaultPrice={defaultPrice} listTaxes={listTaxes} poles={poles} />)}
                    </div>

                    <div className="add-something add-tache" onClick={() => {
                        phase.taches.push({
                            id: uuidv4(),
                            qty: 0,
                            new: true,
                            price: defaultPrice,
                            tva: listTaxes?.[0]?.node.id,
                            remise: 0,
                            typeRemise: '%',
                            total: 0,
                            name: "Nouvelle tâche",
                            ref: 'Référence',
                            taches: [],
                            poles: []
                        });

                        onChange();
                    }}>
                        <AddIcon style={{ fontSize: 15 }} />
                        Ajouter une tâche
                    </div>
                </AccordionDetails>
            </Accordion>
        )
    }
}

class OfferManager extends React.Component {
    state = {
        defaultPrice: 600,
        footer: null,
        phases: []
    };

    componentDidMount() {
        this.setState({
            phases: this.props.allState.phases,
            footer: this.props.allState.footer,
            isModel: this.props.allState.isModel
        });
    }

    _save = () => {
        this.setState({ phases: this.state.phases, footer: this.state.footer });
        this.props.stateCallback('phases', this.state.phases);
        this.props.stateCallback('footer', this.state.footer);
    };

    render() {
        const { defaultPrice, phases, footer, isModel } = this.state;

        let allPhases = phases.filter(e => !e.deleted);

        let { ht, ttc, totals, remises } = getTotals(allPhases, this.props.allState.listTaxes);

        if (!phases || (!isModel && !footer)) return null;

        return (
            <div className="offer-manager">
                {allPhases.map((phase, i) => <OfferManagerPhase
                    phase={phase}
                    key={i}
                    onChange={this._save}
                    defaultPrice={defaultPrice}
                    listTaxes={this.props.allState.listTaxes}
                    poles={this.props.allState.listAgencePoles}
                />)}

                <div className="add-something add-phase" onClick={() => {
                    this.state.phases.push({
                        id: uuidv4(),
                        new: true,
                        name: "Nouvelle phase",
                        taches: []
                    });

                    this._save();
                }}>
                    <AddIcon style={{ fontSize: 15 }} />
                    Ajouter une phase
                </div>

                {
                    !isModel && (
                        <>
                            <hr />

                            <Accordion defaultExpanded={true}>
                                <AccordionSummaryPink
                                    expandIcon={<ArrowDropDownIcon style={{ color: '#fff', fontSize: 30 }} />}
                                >
                                    <div className="phase-name">
                                        Pied de page
                                    </div>
                                </AccordionSummaryPink>
                                <AccordionDetails>
                                    <Grid container spacing={2} justify="center" alignItems="center">
                                        <Grid item xs={6}>
                                            Remise fixe
                                        </Grid>
                                        <Grid item xs={6}>
                                            <OutlinedInputCustom
                                                value={footer.discountFixed}
                                                endAdornment={<InputAdornment position="end">€</InputAdornment>}
                                                onChange={(e) => {
                                                    footer.discountFixed = e.target.value;
                                                    this._save();
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            Remise pourcentage
                                        </Grid>
                                        <Grid item xs={6}>
                                            <OutlinedInputCustom
                                                value={footer.discountPercent}
                                                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                onChange={(e) => {
                                                    footer.discountPercent = e.target.value;
                                                    this._save();
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            Accompte
                                        </Grid>
                                        <Grid item xs={6}>
                                            <OutlinedInputCustom
                                                value={footer.advancePayment}
                                                endAdornment={<InputAdornment position="end">€</InputAdornment>}
                                                onChange={(e) => {
                                                    footer.advancePayment = e.target.value;
                                                    this._save();
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            Condition de règlement
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FullWidthSelect
                                                labelId="reg"
                                                value={footer.paymentTerm}
                                                onChange={(e) => {
                                                    footer.paymentTerm = e.target.value;
                                                    this._save();
                                                }}
                                                input={<BootstrapInput style={{ width: '100%' }} />}
                                            >
                                                {this.props.allState.listPayments.map((reg, i) => <MenuItem value={reg.node.id} key={i}>{reg.node.name}</MenuItem>)}
                                            </FullWidthSelect>
                                        </Grid>
                                        <Grid item xs={6}>
                                            Délais de paiement
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FullWidthSelect
                                                labelId="deadline"
                                                value={footer.paymentDeadline}
                                                onChange={(e) => {
                                                    footer.paymentDeadline = e.target.value;
                                                    this._save();
                                                }}
                                                input={<BootstrapInput style={{ width: '100%' }} />}
                                            >
                                                {this.props.allState.listDeadlines.map((deadline, i) => <MenuItem value={deadline.node.id} key={i}>{deadline.node.name}</MenuItem>)}
                                            </FullWidthSelect>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </>
                    )
                }

                <div className="total">
                    <span className="label">Total HT</span>
                    <span className="amount">{ht.toFixed(2)} €</span>
                </div>

                {
                    totals.map((total, i) => (
                        <div className="total" key={i}>
                            <span className="label">{total.tax.name}</span>
                            <span className="amount">{total.total.toFixed(2)} €</span>
                        </div>
                    ))
                }

                <div className="total">
                    <span className="label">Remises</span>
                    <span className="amount">{remises.toFixed(2)} €</span>
                </div>

                <div className="total">
                    <span className="label">Total TTC</span>
                    <span className="amount">{ttc.toFixed(2)} €</span>
                </div>
            </div>
        );
    }
}

export default OfferManager;