import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { Grid, Tooltip, TextField} from '@material-ui/core';
import styled from 'styled-components';
import { withApollo } from 'react-apollo';
import * as moment from 'moment';
import CardCustom from '../../../layouts/Card/CardCustom';
import CardCompany from '../../../layouts/Card/cardContent/CardCompany';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import EmptyCard from "../../../ui/empty-card/EmptyCard";
import EmptyCatalogue from '../../../../assets/pictos/empty-picto/empty_catalogs.png';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import TraductionSelect from '../../../layouts/TopPanel/TraductionSelect';
import companyAdd from './config/companyAdd.config';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import colors from '../../../../config/theme/colors';
import { GET_COMPANIES_CURSOR,GET_COMPANIES_PAGINATION,ADD_COMPANY,ADD_COMPANY_DATA,SEARCH_COMPANIES } from '../../../../queries/companies';
import { UPDATE_CUSTOMER } from '../../../../queries/customers';
import importFichier from '../../../../assets/pictos/icon-import-fichier.svg';
import importAPI from '../../../../assets/pictos/icon-import-api.svg';
import importFlux from '../../../../assets/pictos/icon-import-flux.svg';
import emptyCatalog from '../../../../assets/pictos/empty-picto/empty_catalogs.png';
import { eventService } from '../../../../js/services/event.service';
import request from '../../../../js/utils/fetch';
import slugify from 'slugify';
/*import Pagination from '@material-ui/lab/Pagination';*/
import { GET_CUSTOMERS, GET_CUSTOMERS_CURSOR,SEARCH_CUSTOMERS } from '../../../../queries/customers';
import importCompaniesConfig from './config/importCompanies.config'
import importCompaniesTypesConfig from './config/importCompaniesTypes.config'
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
// import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import clsx from 'clsx';
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import CardCompanyList from '../../../layouts/Card/cardContent/CardCompanyList';
import { ROUTE_HOME } from '../../../../js/constants/route-names';
import { hasRights } from '../../../../js/utils/rights';
import { CRM_CONTACT, CRM_CONTACT_SOCIETIES, VIEW, CREATE, IMPORT} from '../../../../js/constants/constant-rights';
import * as Pagination from '../../../../js/utils/pagination';

const ViewModuleIconCustom = styled(ViewModuleIcon)`
    fill: ${colors.grey.regular};
    cursor: pointer;
    &.active{
        fill: ${colors.blue.darker.hue300};
        cursor: inherit;
    }
`;
const ViewListIconCustom = styled(ViewListIcon)`
    fill: ${colors.grey.regular};
    cursor: pointer;
    &.active{
        fill: ${colors.blue.darker.hue300};
        cursor: inherit;
    }
`;
const TextFieldCustom = styled(TextField)`
    input, .MuiSelect-select{
        font-size: 16px;
        padding: 8px;
    }
    & > p{
        margin: 0;
    }
`;

const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
`;

const ContainerCatalogs = styled(Grid)`
    margin-top: 1.5rem;
`;



const TableCellCustom = styled(TableCell)`
    border: none;
    display: inline-block;
    width: calc(100% / 9);
    padding: 10px 16px;
    svg{
        display: none;
    }
`


const styles = theme => ({
});

const useStylesBootstrap = makeStyles(theme => ({
    arrow: {
        color: colors.blue.darker.hue300,
    },
    tooltip: {
        backgroundColor: colors.blue.darker.hue300,
        fontSize: 14,
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

class ContactCompanies extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            styleInnerContainer : {
                boxShadow: 'none',
                backgroundInner: colors.white,
                backgroundActions: colors.grey.lighter.hue980,
                padding: '0px',
                height: '630px'
            },
            backgroundDrawer : '',
            isEmpty : false,
            openForm : false,
            openFormAdd : false,
            openFormCategories : false,
            catalogFormData : {},
            filteredCatalogs : [],
            filtered : [],
            allCatalogs : [],
            catalogId : '',
            isChildren:false,
            companyChildrens:[],
            companyAddForm:false,
            companyAddChildrenForm:false,
            companyParent:null,
            companyCustomers:[],
            companyChildrenDraft:{
                companyChildrens:[],
                companyCustomers:[]
            },
            catalogIdentifier : '',
            catalogDescription  : '',
            catalogIsActive : true,
            catalogAutosync : true,
            allCatalogsTypes : [],
            getAllCatalogs: [],
            typeOfCatalogs : [],
            drawerWidthModified : props.drawerWidth,
            treeData: [],
            secondTreeData: [],
            offlineCategories: [],
            editForm: false,
            cat_id: '',
            parent: '',
            libelle:'',
            currentLang: props.locales[0].node.code,
            errors: {},
            seeErrors: false,
            ready: false,
            catalogStartAt: "2020-08-01",
            catalogEndAt: "2020-08-05",
            expanded: {},
            noResult: false,
            page: 1,
            nbperpageCompanies: 6,
            countPage: 0,
            cursor: false,
            skulist: [],
            searchValue: '',
            arrayCatFilter: [],
            formSettings:{},
            nbperpageCustomers: 6,
            customersToAdd:[],
            openImportForm : false,
            openImportTypesForm : false,
            viewMode: 'card',
            pagination : {
                page : 0,
                perPage : 6
            }

        };
        this.typingTimer = null;
        this.typeTesting = 'catalog';
        this.typingSearchTimer = null;
    }

    

    doneTyping = (stateName) => {
        if(stateName === 'catalogName'){
            this.setState({
                catalogIdentifier : slugify(this.state.catalogName, {replacement :'_', lower: true, remove: /[^\w\-\s]+/g})
            })
        }
        if(this.state.catalogIdentifier){
            request(`${process.env.REACT_APP_API}/unique/${this.typeTesting}/${this.state.catalogIdentifier}`, 'get').then(
                (data) => {
                    if(data.success){
                        eventService.fire({stateName: 'catalogIdentifier', errorMessage: 'Cet identifiant est déjà utilisé et n\'est donc pas valide.'});
                    }
                }
            );
        }
        this.forceUpdate();
    };

    checkIdentifier = (stateName) => {
        if(stateName === 'catalogName' || stateName === 'catalogIdentifier'){
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(() => {this.doneTyping(stateName)}, 500);
        }
    };

    handleLang = (event) => {
        let companyChildrenDraft = this.state.companyChildrenDraft;
        companyChildrenDraft['currentLang'] = event.target.value;
        this.setState({ 
            currentLang: event.target.value,
            companyChildrenDraft : companyChildrenDraft
        }, () => {
            eventService.fire();
        });
    };

    handleButtonGroupChange = (stateName, value) => {
        this.setState({
            [stateName]: value
        });
    };

    handleSearch = () => {
        
        let value = this.state.searchValue;
        if (value !== "") {
            this.props.client.query({
                query: SEARCH_CUSTOMERS,
                variables: {"needle": value,"attribute":null},
                fetchPolicy: 'no-cache'
            }).then(result =>{
                let customers = result.data.customerDatas.edges.map(e=>parseInt(e.node.customer.id.replace('/api/customers/',''),10));
                let listID=[];
                let uniqueCustomers = customers.filter(customer=>{
                    if(!listID.includes(customer)){
                        listID.push(customer);
                    }
                    return listID;
                })
                this.setState({
                    activeSearch :uniqueCustomers
                },()=>this.getCustomers())
                
            })
            
        }
    }
    handleChangeCustomer=(e,value) =>{
        let formSettings = this.state.formSettings;
        formSettings.isReady = false;

        this.setState({
            currentPage : 1,
            formSettings : formSettings
        });

        clearTimeout(this.typingSearchTimer);

        if(e?.target?.value){
            this.setState({searchValue: e.target.value});
            this.typingSearchTimer = setTimeout(() => {
                this.handleSearch();
            }, 500);
        } 
        else{
            this.setState({activeSearch:false},()=>this.getCustomers())
        }

    }

    handleChangeCompanies = (e,value)=>{
        this.setState({
            currentPage : 1,
            listCompanies : [],
            ready:false,
        });

        clearTimeout(this.typingSearchTimer);

        if(e?.target?.value){
            this.setState({searchValueCompanies: e.target.value});
            this.typingSearchTimer = setTimeout(() => {
                this.handleSearchCompanies();
            }, 500);
        } 
        else{
            this.setState({activeSearchCompanies:false},()=>this.handleGetCompanies())
        }
    }

    handleSearchCompanies = () =>{
        let value = this.state.searchValueCompanies;
        if (value !== "") {
            this.props.client.query({
                query: SEARCH_COMPANIES,
                variables: {"needle": value,"attribute":null},
                fetchPolicy: 'no-cache'
            }).then(result =>{
                let companies = result.data.companyDatas.edges.map(e=>parseInt(e.node.company.id.replace('/api/companies/',''),10));
                let listID=[];
                let uniqueCompanies = companies.filter(company=>{
                    if(!listID.includes(company)){
                        listID.push(company);
                    }
                    return company;
                })
                this.setState({
                    activeSearchCompanies :uniqueCompanies
                },()=>this.handleGetCompanies())
                
            })
            
        }
    }

    handleChangeTab = (event, newValue, id, libelle, category) => {
        this.props.startLoading();
        if(id) {
            this.setState({
                idActiveCat: id,
                arrayCatFilter: [id],
            }, () => {
                this.handleGetCompanies();
            });
        } else {
            this.setState({
                arrayCatFilter: [],
                activeCategorie: ['Tout voir'],
            }, () => {
                this.handleChange(null, true);
            });
        }
    };

    setValue = (stateName, value, translated) => {
        let isChildren = this.state.isChildren
        let companyChildrenDraft=this.state.companyChildrenDraft;

        if (translated) {
            let values = isChildren===true ? this.state.companyChildrenDraft[this.state.currentLang] : this.state[this.state.currentLang];

            if (!values) {
                values = {};
            }
            values[stateName] = value;

            if(isChildren===true){
                companyChildrenDraft[this.state.currentLang]=values;
                this.setState({
                    companyChildrenDraft: companyChildrenDraft                    
                })
            }
            else{
                this.setState({
                    [this.state.currentLang]: values,
                });               
            }

        } else {

            if(isChildren===true){           
                companyChildrenDraft[stateName]=value;
                this.setState({
                    companyChildrenDraft:companyChildrenDraft
                })
            }
            else{
                this.setState({
                    [stateName]: value,
                });
            }
        }
    };

    handleToggleDrawer = (stateDrawer,reset=false) => {
        this.setState({ 
            [stateDrawer]: !this.state[stateDrawer]
        });

        if(reset){
            this.resetState()
        }
    };
    
    handleDrawerWidthChange = (width) => {
        this.setState({ 
            drawerWidthModified: width
        });
    };

    handleFormAdd = (type, description, title) => {
        this.setState({
            catalogFormData : {
                type : type,
                description : description,
                title : title,
            }
        }, () => {
            this.resetStateCatalog();
            this.handleToggleDrawer('openFormAdd', false);
        });
    };

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;
        errors[stateName] = error;
        this.setState({ errors });
    };

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }

        return false;
    };

    handleGetCursorsCompanies = () => {
        this.props.client.query({
            query: GET_COMPANIES_CURSOR,
            fetchPolicy: 'no-cache'
        }).then(result => {
            
            this.setState({
                listCursors: result.data.companies.edges
            }, () => {
                this.handleGetCompanies();
            });
        });
    }

    handleNextStep = () =>{
        if (this.hasErrors()) {
            this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
            this.setState({ seeErrors: true });
            eventService.fire();
            return false;
        }

        this.handleDrawerWidthChange(-350)

        return true;
    }

    handleBackStep = () => {
        this.setState({ errors: {} });
    }

    // SAVE TREE
    getAttributeTranslatedValue = (attributes, id, lang) => {
        let attribute = attributes.find(e => e.id === id);

        if (!attribute)
            return null;

        let translation = attribute.locales.find(e => e.id === lang);

        if (!translation)
            return null;

        return translation;
    };

    getTranslatedValueFromData = (datas, id, lang) => {
        let data = datas.find(e => e.node.locale.id === lang && e.node.attribute.id === id);

        if (!data)
            return null;

        return data.node;
    };

    handleGetCompanies() {
        return new Promise(async(resolve, reject) => {
            let pageInfos = await Pagination.paginationQueryData(this.state.pagination)
            let variables = {...pageInfos,parent:false}

            if(this.state.activeSearchCompanies){
                variables.id_list = this.state.activeSearchCompanies
            }
            console.log('variables',variables)
            this.props.client.query({
                query: GET_COMPANIES_PAGINATION,
                variables,
                fetchPolicy: 'no-cache'
            }).then( async result =>{
                let pagination = await Pagination.updatePaginationData(this.state.pagination,result.data.companies);
                console.log(pagination,result)
                this.setState({
                    pagination,
                    listCompanies: result.data.companies.edges,
                    noResult: result.data.companies.edges.length > 0 ? false : true,
                    ready: true,
                })
                this.props.stopLoading();
                resolve();
            });
        });
    }

    changePage = (event, page,isForm) => {
        // this.props.startLoading();
        let index = 0;
        if(isForm){
            let formSettings = this.state.formSettings;
            let status = this.state.currentStatus;
            formSettings.isReady = false;
            if(page > 1){
                index = ( page * this.state.nbperpageCustomers ) - this.state.nbperpageCustomers - 1
            }
            formSettings.page=page

            this.setState({
                customerCursor: this.state.listCursorsCustomers[index].cursor,
                formSettings:formSettings
            },()=>{
                
                this.getCustomers(status);
            });
        }
        else{
            if(page > 1){
                index = ( page * this.state.nbperpageCompanies ) - this.state.nbperpageCompanies - 1
            }
            this.setState({
                cursor: this.state.listCursors[index].cursor,
                page:page,
                isReady:false
            },()=>{
                this.handleGetCompanies();
            });
        }

    };

    handleCompanyChildren = (action='add',index=null)=>{
        
        this.setState({
            isChildren:true,
            companyChildrenAction : action
        },()=>{
            switch(action){
                case 'add' :
                    this.handleToggleDrawer('companyAddChildrenForm');
                    break;
                case 'edit':
                    this.setState({
                        companyChildrenDraft : this.state.companyChildrens[index],
                        childrenToEdit : index,
                    })
                    this.handleToggleDrawer('companyAddChildrenForm');
                    break;

    
                case 'delete':
                    this.handlerChildrenMutation('delete');
                    this.setState({
                        childrenToEdit : index,
                    })
                    break;
                default:
                    return null;
            }
        })
    }

    handleMediaPicker=(selected,stateName,translated)=>{
        this.handleInputChange(stateName,selected,null,translated);  
    }

    handleInputChange = (stateName, evt, custom, translated ) => {
        const value = evt?.target?.value ?? evt;
        this.setValue(stateName, value,translated);
    };

    handlerChildrenMutation = () =>{
        let action =this.state.companyChildrenAction;
        const companyChildrens=this.state.companyChildrens;
        let children = {...this.state.companyChildrenDraft};
        
        switch(action){
            case 'add':
                companyChildrens.push(children);
                this.handleToggleDrawer('companyAddChildrenForm',true);
                break;

            case 'edit':
                companyChildrens[this.state.childrenToEdit]=children;
                this.handleToggleDrawer('companyAddChildrenForm',true);
                
                break;

            case 'delete':
                companyChildrens.splice(this.state.childrenToEdit,1);
                this.resetState();
                break;
            default:
                return null;
        }
       
        this.setState({
            companyChildrens : companyChildrens
        },()=>{
            this.resetCompanyDraft();
        })
    }

    resetCompanyDraft(){
        let companyChildrenDraft = this.state.companyChildrenDraft;
        companyChildrenDraft.companyChildrens = [];
        companyChildrenDraft.companyCustomers = [];
        companyChildrenDraft['companyIdentifier']=null;

        for (let locale of this.props.locales){
            companyChildrenDraft[locale.node.code]={};
        }
        this.setState({ companyChildrenDraft })
    }

    resetState = () =>{
        this.setState({
            editChildren:false,
            childrenToEdit:null,
            isChildren:false
        })
    }
    
    setUpAttributes = async()=>{

        await this.prepareAttributes();
        this.prepareAttributeValues()
    }

    prepareAttributeValues() {
        let companyChildrenDraft = this.state.companyChildrenDraft;

        for (let locale of this.props.locales) {
            let values = {};

            for (let attribute of this.state.customAttributes) {
                if (attribute.node.attributeType.input === 'select') {
                    if (attribute.node.attributeOptions.edges.length){
                        values[attribute.node.identifier] = attribute.node.attributeOptions.edges[0].node.id
                    }
                }
            }
            companyChildrenDraft[locale.node.code] = {...values}
            
            this.setState({
                [locale.node.code]: {...values},
                companyChildrenDraft:companyChildrenDraft,
            });
        }
    }

    prepareAttributes = ()=>{
        return new Promise(async (resolve, reject) => {
            let companyChildrenDraft=this.state.companyChildrenDraft;
            let isSystemAttributes  = this.props.attributes.company.attributes.edges.filter(e => e.node.isSystem);

            companyChildrenDraft['currentLang']=this.state.currentLang;
            companyChildrenDraft['isSystemAttributes']=isSystemAttributes;
            this.setState({
                isSystemAttributes,
                customAttributes : this.props.attributes.company.attributes.edges,
                companyChildrenDraft :  companyChildrenDraft,
                isReady : true,
            },()=>{
                resolve()
            })

        })
    }

    handlerCompanyMutation= async(action='add',allStates=this.state,parent=null,attributes=this.state.isSystemAttributes)=>{
        this.props.startLoading();
        switch(action){
            case 'add':
                let variables={
                    libelle : this.state.companyIdentifier.replace(' ','_').toLowerCase(),
                    parent : parent,
                    createdAt: moment().format('YYYY-MM-DD'),
                    updatedAt: moment().format('YYYY-MM-DD'),
                }
                const ADD_COMPANY_RESULT = await this.props.client.mutate({
                    mutation : ADD_COMPANY,
                    variables : variables
                })
                await this.saveAttributes(ADD_COMPANY_RESULT.data.createCompany.company.id,allStates,attributes);
                if(allStates.companyCustomers.length >0){
                    for(let customer of allStates.companyCustomers){
                        let companies   = customer.node.companies.edges.map((company)=>company.node.id);
                        let id          = customer.node.id;
                        companies.push(ADD_COMPANY_RESULT.data.createCompany.company.id);
                        await this.props.client.mutate({
                            mutation : UPDATE_CUSTOMER,
                            variables : {
                                companies : companies,
                                id : id
                            }
                        })

                    }
                }
                if(allStates.companyChildrens.length > 0)
                    for(let company of allStates.companyChildrens)
                        this.handlerCompanyMutation('add',company,ADD_COMPANY_RESULT.data.createCompany.company.id);
                
                await this.handleGetCompanies();
                this.handleToggleDrawer('companyAddForm')
                this.props.stopLoading();
                this.props.snack(ALERT_SUCCESS, 'Sociétée ajouté avec succès');
                break;
            default:
                return null;
        }
    }

    saveAttributes(company,allStates,attributes){
            return new Promise(async (resolve, reject) => {
                let attributes = allStates.isSystemAttributes;
                console.log('attribtutes',attributes)
                let getProductData = [];
                for (let attribute of attributes) {
                    for (let locale of this.props.locales) { 
                        let formValue   = allStates[locale.node.code][attribute.node.identifier];
                        let isMedia     = attribute.node.attributeType.input === 'image' || attribute.node.attributeType.input === 'file';

    
                        if (formValue) {

                            let variables = {
                                "attributeOption": attribute.node.attributeType.input === 'select' ? formValue : null,
                                "company": company, 
                                "attribute": attribute.node.id,
                                "locale": locale.node.id
                            };
    
                            if (isMedia){
                                variables.media = formValue.id;
                            }
                                
                            
                            if (!isMedia)
                                if (attribute.node.attributeType.input !== 'select')
                                        variables.value = formValue;
                                
                            let resultMutation = await this.props.client.mutate({
                                mutation: ADD_COMPANY_DATA,
                                variables
                            })
                            getProductData.push(resultMutation.data.createCompanyData.companyData)
                        }
                    }
                }
                resolve(getProductData);
            });
    }

    getCustomers(status){
        
            let formSettings = this.state.formSettings;
            let variables =
            {
                "nbperpage": this.state.nbperpageCustomers,
            };
            if(this.state.customerCursor && this.state.listCursorsCustomers!== this.state.listCursorsCustomers[0].cursor){
                variables.cursor = this.state.customerCursor;
            }
            if(this.state.activeSearch){
                variables.id_list = this.state.activeSearch;
            }
            if(status)
                    variables.status = status

            this.props.client.query({
                query: GET_CUSTOMERS,
                variables: variables,
                fetchPolicy: 'no-cache'
            }).then(result =>{
                formSettings.customers = result.data.customers.edges;
                formSettings.count = Math.ceil(result.data.customers.totalCount / this.state.nbperpageCustomers);
                formSettings.isReady = true;

                this.setState({
                    formSettings : formSettings,

                });
                this.props.stopLoading();
                
            });
    }

    handleGetCursorsCustomers= (status) =>{
        this.props.startLoading()
        this.props.client.query({
            query: GET_CUSTOMERS_CURSOR,
            fetchPolicy: 'no-cache'
        }).then(result => {
            this.setState({
                listCursorsCustomers: result.data.customers.edges,
            },() => this.getCustomers(status));
        });
    }

    handleCategory = (category)=>{
        let formSettings = this.state.formSettings;
        formSettings.isReady = false;

        this.setState({
            formSettings: formSettings,
            currentStatus: category,
            cursor: null,
            page: 1,
        },()=>{
            this.getCustomers(category);
        })
    }

    handleSelect = (checked,contact)=>{
        let companyChildrenDraft    = {...this.state.companyChildrenDraft}; 
        let companyCustomers        =  this.state.companyCustomers;
        let isChildren              = this.state.isChildren;
        let index;

        if(checked){
            if (isChildren){
                companyChildrenDraft['companyCustomers'].push(contact)                
            }
            else{
                companyCustomers.push(contact)                
            }
        }
        else{
            if(isChildren){
                index =companyChildrenDraft['companyCustomers'].indexOf(contact);
                companyChildrenDraft['companyCustomers'].splice(index,1);
            }
            else{
                index =companyCustomers.indexOf(contact);
                companyCustomers.splice(index,1);
            }
        }

        
        this.setState({
            companyCustomers : companyCustomers,
            companyChildrenDraft : companyChildrenDraft
        })
    }

    handleFormImport = (type, title) => {
        this.setState({
            catalogFormData: {
                type,
                title
            },
            mapper: [],
            media: null,
            headers: null,
            importFile: null,
            importSep: ';',
            importValues: {},
            importLang: this.props.locales[0].node.id
        });

        this.handleToggleDrawer('openImportForm');
    };

    handlerImportMutation= async () => {
        this.props.startLoading();

        let importConfig = {
            "url": `${process.env.REACT_APP_API_ROOT}${this.state.media?.contentUrl ?? null}`,
            "mapper": this.state.mapper,
            "eavType": this.props.attributes.eavTypes.find(e => e.node.code === 'company').node.id,
            "locale": this.state.importLang,
            "delimiter":this.state.importSep
        };

        try {
            await request(`${process.env.REACT_APP_API_ROOT}/api/file-import-crms`, 'post', importConfig, undefined, true);
            this.props.snack(ALERT_SUCCESS, `Votre fichier a été importé avec succès, son intégration dans SpreadSuite sera exécutée lors du passage du CRON (Tâche automatique).`,6000);
            this.handleGetCursorsCompanies();
            this.setUpAttributes(); 
            this.handleToggleDrawer('openImportTypesForm');
            this.handleToggleDrawer('openImportForm');
            this.props.stopLoading();
        } catch(e) {
            this.props.snack(ALERT_ERROR, `L'import a échoué !`);
            this.props.stopLoading();
        }
    };

    stateCallback = (stateName, value, custom, translated, callback) => {
        this.setState({
            [stateName]: value?.target?.value ?? value
        }, callback);
    };

    initForm=()=>{
        let formSettings = this.state.formSettings;
        formSettings.changePage = this.changePage;
        formSettings.handleCategory = this.handleCategory;
        formSettings.handleSearch = this.handleChangeCustomer;
        formSettings.handleSelect= this.handleSelect;

        this.handleGetCursorsCustomers();
        this.setState({
            formSettings : formSettings
        })
        this.handleToggleDrawer('companyAddForm');
    }

    async componentDidMount() {
        const getRights = hasRights(CRM_CONTACT, CRM_CONTACT_SOCIETIES, VIEW);
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }else{
            await this.handleGetCursorsCompanies();
            await this.setUpAttributes();
            if(this.props.location.state?.openForm){
                this.initForm()
            }
            if(this.props.location.state?.hasMessage){
                this.props.snack(ALERT_SUCCESS,this.props.location.state.message)
            }
        }
    };

    changeViewMode = (mode) => {
        this.props.startLoading();
        if (mode === "list"){
            this.setState({
                nbperpageCompanies : this.state.nbperpageCompanies === 6 || 18 || 30 ? 10 : this.state.nbperpageCompanies,
                viewMode : 'list',
                page: 1,
                cursor: null,
            }, () => this.handleGetCompanies())
        }else{
            this.setState({
                nbperpageCompanies : 6,
                viewMode : 'card',
                page: 1,
                cursor: null,
            }, () => this.handleGetCompanies())
        }
    }

    handleChangePerPage = (event) => {
        this.setState({
            nbperpageCompanies: event.target.value
        }, this.changeViewMode(this.state.viewMode))
    }

    changePageCompanies = async(newPage) =>{
        let pagination = await Pagination.changePage(this.state.pagination,newPage);
        this.setState({pagination},()=>this.handleGetCompanies())
    }

    render() {
        console.log('ContactCompanies',this.state)
        const headCells = [
            { id: 'image', alignement: true, style: {width: 'calc((100%/9) * 1.5)'}, disablePadding: true, label: 'Image' },
            { id: 'typeSociety', alignement: true, style: null, disablePadding: false, label: 'Type de société' },
            { id: 'nom', alignement: false, style: {width: 'calc((100%/9) * 2)'}, disablePadding: false, label: 'Nom' },
            { id: 'date', alignement: false, style: {width: 'calc(100%/9)'}, disablePadding: false, label: 'Date' },
            { id: 'contact', alignement: true, style: null, disablePadding: false, label: 'Contact(s)' },
            { id: 'filiale', alignement: true, style: null, disablePadding: false, label: 'Filiale(s)' },
            { id: 'button', alignement: false, style: {width: 'calc((100%/9) * 1.5)'}, disablePadding: false, label: '' },
        ];
        let companiesByPage = [];
        this.state.viewMode === 'list' ? companiesByPage = [10,20,50] : companiesByPage = [6,18,30];
        const { classes } = this.props;
        return (
            <div style={{width: this.state.openForm ? `calc(100% - ((50% - ${this.props.drawerWidth}px / 2) + (${this.props.drawerWidth}px / 2) + 32px))` : "100%", transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms'}}>
                <TopPanel 
                    icomoon="icon-business" 
                    colorIcomoon={colors.blue.lighter.hue300}
                    title="Sociétés" 
                    subtitle="Gestion de vos sociétés (création / modification / suppression)" 
                    handlerAdd={()=>this.initForm()} 
                    textAdd={hasRights(CRM_CONTACT, CRM_CONTACT_SOCIETIES, CREATE) ? "Ajouter une société" : null}
                    searchHandler={
                        this.handleChangeCompanies
                    } 
                    gradientColor1={colors.menu.regular} 
                    gradientColor2={colors.menu.darker} 
                    openForm={this.state.openForm}
                    buttonAvailable={!this.state.companyAddForm}
                    // currentLang={this.state.currentLang} 
                    // handleLang={this.handleLang} 
                    // locales={this.props.locales}
                    handlerImport={() => this.handleToggleDrawer('openImportTypesForm')} 
                    textImport={hasRights(CRM_CONTACT, CRM_CONTACT_SOCIETIES, IMPORT) ? "Importer des sociétés" : null}
                    hasBorder={true}
                />
                <TraductionSelect 
                    currentLang={this.state.currentLang} 
                    handleLang={this.handleLang} 
                    locales={this.props.locales}
                />
                {
                    this.state.ready ? 
                        <Grid container justify="flex-end" alignItems="center">
                            <BootstrapTooltip title="Vue carte" className={clsx(classes.tooltip)}>
                                <ViewModuleIconCustom 
                                style={{ fontSize: 30 }} 
                                className={this.state.viewMode === "card" ? 'active': null}
                                onClick={this.state.viewMode === "card" ? null : () => this.changeViewMode('card')}
                                />
                            </BootstrapTooltip>
                            <BootstrapTooltip title="Vue liste" className={clsx(classes.tooltip)}>
                                <ViewListIconCustom 
                                style={{ fontSize: 30, marginLeft: 8 }} 
                                className={this.state.viewMode === "list" ? 'active': null}
                                onClick={this.state.viewMode === "list" ? null : () => this.changeViewMode('list')}
                                />
                            </BootstrapTooltip>
                            {
                                <FormControl style={{width: 60, marginLeft: 8}}>
                                        <TextFieldCustom
                                            id='product-by-page'
                                            variant="outlined"
                                            color="secondary"
                                            select
                                            fullWidth
                                            value={this.state.nbperpageCompanies}
                                            onChange={this.handleChangePerPage}
                                            >
                                            {companiesByPage.map((pages, index) =>{
                                                return(
                                                    <MenuItem value={pages} key={`MenuItem${index}`}>{pages}</MenuItem>
                                                )
                                            })}
                                        </TextFieldCustom>
                                </FormControl>
                            }
                            {/* <BootstrapTooltip title="Click to export products" className={clsx(classes.tooltip)}>
                                <GetAppOutlinedIcon 
                                style={{ fontSize: 30, marginLeft: 8, cursor:'pointer', fill: colors.blue.darker.hue300 }} 
                                onClick={() => this.handleToggleDrawer('openFormExport')}
                                />
                            </BootstrapTooltip> */}
                        </Grid>
                    : null
                }

                <ContainerCatalogs container direction="column" justify="center" spacing={0}>
                    {
                        this.state.viewMode === "list" ? 
                            <CardCustom style={{width: "100%", height: "100%",  padding: "0"}} cardContentStyle={{height: "100%"}} contentpadding={'0'} hovercard={false}>
                                <TableContainer>
                                    <Table aria-label="headerList">
                                        <TableHead>
                                            <TableRow>
                                                {headCells.map((headCell) => (
                                                    <TableCellCustom
                                                        key={headCell.id}
                                                        align={headCell.alignement ? 'center' : 'left'}
                                                        style={{width: `${headCell.style?.width}`}}
                                                        // sortDirection={orderBy === headCell.id ? order : false}
                                                    >
                                                        <TableSortLabel
                                                            //   active={orderBy === headCell.id}
                                                            //   direction={orderBy === headCell.id ? order : 'asc'}
                                                            //   onClick={createSortHandler(headCell.id)}
                                                        >
                                                            {headCell.label}
                                                            {/* {orderBy === headCell.id ? (
                                                                <span className={classes.visuallyHidden}>
                                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                                </span>
                                                            ) : null} */}
                                                        </TableSortLabel>
                                                    </TableCellCustom>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                </TableContainer>
                            </CardCustom>
                        : null
                    }
                    <Grid container direction="row" spacing={this.state.viewMode === 'card' ? 4 : 0}>
                        {this.state.isEmpty ? 
                            <EmptyCard 
                                title={"Vous n'avez pas encore configuré de Catalogues"} 
                                subtitle={"Cliquez sur le bouton ci-dessous pour en ajouter un"} 
                                textButton={"Créer un catalogue"} 
                                picto={EmptyCatalogue} 
                                onClick={() => this.handleToggleDrawer('openForm')}
                                openForm={this.state.openForm}
                            />
                        : 
                        this.state.listCompanies?.length > 0 ?
                            this.state.listCompanies.map((type, index) =>{
                                if(this.state.viewMode === "card"){
                                    return(
                                        <GridCustom item lg={this.props.windowWidth > 1500 ? (this.state.openForm ? 6 : 4) : (this.state.openForm ? 12 : 6)} md={this.state.openForm ? 12 : 6} xs={12} key={`ListCatalogs${index}`}>
                                            <CardCustom style={{width: "100%", padding: 0}} cardContentStyle={{padding: 0}} >
                                                <CardCompany data={type} textButton={'Voir la société'} openForm={this.state.openForm} windowWidth={this.props.windowWidth} currentLang={this.state.currentLang} isList={true}/>
                                            </CardCustom>
                                        </GridCustom>
                                    )
                                }else{
                                    return(
                                        <GridCustom item xs={12} key={`ListSocieties${index}`} style={{marginTop: 8}}>
                                            <CardCustom paddingbottom={0} paddingtop={0} style={{width: "100%"}} contentpadding={'8px 0'} >
                                                <CardCompanyList data={type} textButton={'Voir la société'} openForm={this.state.openForm} windowWidth={this.props.windowWidth} currentLang={this.state.currentLang} isList={true}/>
                                            </CardCustom>
                                        </GridCustom>
                                    )
                                }
                            }
                                
                            )
                        : 
                        !this.state.ready ? 
                            (<PageLoader />)
                        : 
                        this.state.noResult ?
                            (<EmptyCard title={"Aucun résultat pour cette recherche"} textButton={"Ajouter une société"} onClick={() => this.handleToggleDrawer('openForm')} picto={emptyCatalog} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />)
                        : 
                            (<EmptyCard title={"Vous n'avez pas encore configuré de catalogue"} subtitle={"Cliquez sur le bouton ci-dessous pour en ajouter un"} textButton={"Ajouter un catalogue"} onClick={() => this.handleToggleDrawer('openForm')} picto={emptyCatalog} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />)
                        }
                    </Grid>
                    {
                        this.state.ready ? 
                            <Pagination.CursorPagination
                                label = 'Produits par page'
                                pagination = {this.state.pagination}
                                type = "table"
                                changePageCallback = {this.changePageCompanies}
                                load = {true}
                            />
                        : null
                    }
                </ContainerCatalogs>
                {this.state.isReady ? (
                    <div>
                            <LayoutBuilder
                                icomoon="icon-business"
                                opened={this.state.companyAddForm} 
                                forClose={()=>this.handleToggleDrawer('companyAddForm')} 
                                handlerSetup={()=>{}} 
                                dataLayout={companyAdd(this.state.currentLang,this.state.customAttributes,'add',this.handleMediaPicker,this.handleCompanyChildren,this.state.formSettings)} 
                                drawerWidth={this.props.drawerWidth}     
                                allState={this.state}    
                                stateCallback={this.handleInputChange} 
                                errorCallback={this.handleFormError}    
                                stepperButtonAction={[
                                    this.handleNextStep,
                                    this.handleNextStep,
                                    this.handleNextStep,
                                ]}
                                handlerMutation={this.handlerCompanyMutation}
                                currentLang={this.state.currentLang}
                                handleLang={this.handleLang}
                            />

                            <LayoutBuilder
                                icomoon="icon-business"
                                colorIcomoon={colors.blue.lighter.hue300}
                                opened={this.state.companyAddChildrenForm} 
                                forClose={()=>this.handleToggleDrawer('companyAddChildrenForm',true)} 
                                handlerSetup={()=>{}} 
                                dataLayout={companyAdd(this.state.currentLang,this.state.customAttributes,'addChildren',this.handleMediaPicker,null,this.state.formSettings)} 
                                drawerWidth={this.props.drawerWidth}     
                                allState={this.state.companyChildrenDraft}    
                                stateCallback={this.handleInputChange} 
                                stepperButtonAction={[
                                    this.handleNextStep,
                                    this.handleNextStep
                                ]}
                                handlerMutation={this.handlerChildrenMutation}
                                currentLang={this.state.currentLang}
                                handleLang={this.handleLang}
                            />

                            <LayoutBuilder 
                                opened={this.state.openImportTypesForm} 
                                forClose={() => this.handleToggleDrawer('openImportTypesForm', true)} 
                                dataLayout={importCompaniesTypesConfig} 
                                drawerWidth={this.props.drawerWidth} 
                                dataCard={[
                                    {
                                        'libelle': 'Importer via un',
                                        'bicoloreText': 'flux',
                                        'colorhover': '#6EAED1',
                                        'picto': importFlux,
                                        'disabled': true,
                                        'textButton': 'Importer',
                                        'description': 'Votre import contacts facilité en renseignant simplement votre flux', 
                                        'catalogDescription' : 'Veuillez compléter les champs ci-dessous',
                                        'onClick': () => this.handleFormImport(null, 'flux')
                                    },
                                    {
                                        'libelle': 'Importer via une',
                                        'bicoloreText': 'API',
                                        'colorhover': '#6EAED1',
                                        'picto': importAPI,
                                        'disabled': true,
                                        'textButton': 'Importer',
                                        'description': 'Votre import contacts facilité en renseignant simplement votre API', 
                                        'catalogDescription': 'Veuillez compléter les champs ci-dessous',
                                        'onClick': () => this.handleFormImport(null, 'api')
                                    },
                                    {
                                        'libelle': 'Importer un',
                                        'bicoloreText': 'fichier',
                                        'colorhover': '#6EAED1',
                                        'picto': importFichier,
                                        'disabled': false,
                                        'textButton': 'Importer',
                                        'description': 'Votre import contacts facilité en important simplement votre fichier', 
                                        'catalogDescription': 'Veuillez compléter les champs ci-dessous',
                                        'onClick': () => this.handleFormImport(null, 'fichier')
                                    }
                                ]}
                            />

                            <LayoutBuilder 
                                icomoon={'ico-import-fichier'}
                                opened={this.state.openImportForm} 
                                forClose={() => this.handleToggleDrawer('openImportForm', true)}  
                                dataLayout={importCompaniesConfig} 
                                drawerWidth={this.state.drawerWidth}
                                handleCancel={this.handleCancel}
                                handlerMutation={this.handlerImportMutation} 
                                allState={this.state} 
                                stateCallback={this.stateCallback}
                                stepperButtonDisabled={[() => this.state.headers === null, null]}
                                stepperButtonAction={[null, null]}
                                backStepperButtonAction={[null, null, null]}
                            />  
                        </div>
                    )
                :null}

            </div>
        );
    }

    // be careful, only breaks references at objects level
    copyArrayOfObjects = array => array.map(a => a.node ? ({...a, node: {...a.node}}) : ({...a})); 

    goTo = route => {
        this.props.history.push(route);
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes, 
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    };
};

export default withApollo(withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ContactCompanies))));