import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDrop } from 'react-dnd';
import Rules from './Rules';
import DotationsACV from './DotationsACV';

const ExplanationsACVGame = (props) => {
    const [hover, setHover] = useState(false);
    const [hoverBlock, setHoverBlock] = useState(false);
    
    let { 
        preview = false,  
        id = null, 
        data = null,
        inputCallback = null,
        update = null
    } = props;

    let border = null;
    if (data.blocks.explanation.inputs.border) {
        border = data.blocks.explanation.inputs.border;
    }

    let image = data.inputs['backgroundImage'].value;
    let image2 = data.blocks.explanation.inputs['backgroundImage'].value;
    
    return (
        <section 
            className="how-container"
            style={{
                backgroundColor: data.inputs['backgroundColor'].value,
                backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                backgroundSize: "100%",
                backgroundPosition: "top center",
                backgroundRepeat: "no-repeat",
                position: 'relative',
                padding: 0,
            }}
            onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
            >
            { !preview && props.toolbarSection }
            <section 
                class="how" 
                style={{
                    backgroundColor: data.blocks.explanation.inputs['backgroundColor'].value,
                    backgroundImage: typeof image2 === 'string' ? `url(${image2})` : image2?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image2.filePath})` : 'none',
                    backgroundSize: "100%",
                    backgroundPosition: "top center",
                    backgroundRepeat: "no-repeat",
                    position: 'relative',
                    borderStyle: 'solid',
                    borderWidth: border?.inputs?.borderWidth?.value ? border.inputs.borderWidth.value : '0px',
                    borderColor: border?.inputs?.borderColor?.value ? border.inputs.borderColor.value : null,
                    borderRadius: border?.inputs?.borderRadius?.value ? border.inputs.borderRadius.value : null,
                    marginTop: 40,
                }}
                onMouseEnter={() => {setHover(false); setHoverBlock(true)}} onMouseLeave={() => {setHover(true);setHoverBlock(false)}}
            >
                { props.test }
                { !preview && hoverBlock && props.toolbarBlock }
                {
                    data.blocks.explanation.blocks.rules.config.length > 0 ? (
                        <Rules data={data.blocks.explanation} preview={preview} component={id} block={'rules'} inputCallback={inputCallback} update={update} />
                    ) : null
                }
                <DotationsACV data={data.blocks.explanation} preview={preview} component={id} block={'dotations'} inputCallback={inputCallback} update={update} />
            </section>
            {
                data.blocks.image?.inputs?.value?.value ? (
                    <img 
                        src={
                            typeof data.blocks.image?.inputs?.value?.value === 'string' ? 
                                data.blocks.image.inputs.value.value : 
                                data.blocks.image?.inputs?.value?.value?.filePath ? 
                                    ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.image.inputs.value.value.filePath}` 
                                    : null
                        }
                        style={{    
                            width: '100%',
                            maxWidth: '100%',
                            position: 'relative',
                            bottom: '-5px'
                        }}
                        alt="Décoration jeu"
                    />
                ) : null
            }
        </section>
    );
};

ExplanationsACVGame.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingclassName: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default ExplanationsACVGame;
