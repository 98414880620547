import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";

import { Grid, Box, CircularProgress } from '@material-ui/core';
import CardCustom from '../../../layouts/Card/CardCustom';
import CardCompletudeDashboard from '../../../layouts/Card/cardContent/CardCompletudeDashboard';
import Typography from '../../../ui/typography/Typography';
import {ROUTE_HOME, ROUTE_PRODUCTS_CATEGORIES, ROUTE_PRODUCTS_ATTRIBUTES, ROUTE_PRODUCTS_ADD} from '../../../../js/constants/route-names';
import { withApollo } from 'react-apollo';
import request from '../../../../js/utils/fetch';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import colors from '../../../../config/theme/colors';
import IcomoonReact from "icomoon-react";
import iconSet from "../../../../assets/selection.json";
import SliderDashboard from "../../../layouts/Slider/SliderDashboard";
import Image1 from '../../../../../src/assets/images/dashboard/Visuels-Dashboard-connecteur-RS.png';
import Image2 from '../../../../../src/assets/images/dashboard/Visuels-Dashboard-connecteur-marketplace.png';
import { v4 as uuidv4 } from 'uuid';
import { hasRights } from '../../../../js/utils/rights';
import { PRODUCTS, PRODUCTS_DASHBOARD, VIEW, CREATE, PRODUCTS_CATEGORIES, PRODUCTS_PRODUCTS, PRODUCTS_ATTRIBUTES} from '../../../../js/constants/constant-rights';
import { ALERT_ERROR } from '../../../../js/constants/alert-types';
import { SNACK } from '../../../../js/constants/action-types';


class DashboardProducts extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            products: []
        };
    }

    goTo = (route, status) => {

        this.props.history.push({
            pathname : route,
            state: {formOpen : status}
        });
    };

    componentDidMount(){
        const getRights = hasRights(PRODUCTS, PRODUCTS_DASHBOARD, VIEW)
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }
        this.initDashboard();
    }

    initDashboard = () => {
        request(`${process.env.REACT_APP_API}/dashboards/stats/product`, 'get', false).then(
            (data) => {
                if(data.success){
                    this.setState({
                        products: data.datas
                    }, () => {
                        let time = this.state.products.nbProducts - ((this.state.products.percentCompleteProducts * this.state.products.nbProducts) / 100);
                        this.setState({time});
                    });
                }
            }
        );
    }

    render() {
        let sliderData = [
            hasRights(PRODUCTS, PRODUCTS_PRODUCTS, CREATE) ?
            {
                title: 'Pourquoi créer un produit ?',
                text: 'Créer ou importer vos produits est une étape incontournable pour ensuite construire vos catalogues, vos mini-sites... Vos produits seront directement disponibles avec toutes leurs informations (descriptions, photos...).',
                image: Image1,
                id: uuidv4(),
                buttons: [
                    {
                        text: 'Créer un produit',
                        action: () => this.goTo(ROUTE_PRODUCTS_ADD, true),
                    },
                ]
            } : null,
            hasRights(PRODUCTS, PRODUCTS_CATEGORIES, CREATE) ?
            {
                title: 'Pourquoi créer une catégorie ?',
                text: "Créer des catégories va vous permettre de trier vos produits pour les trouver plus rapidement. Quand vous aurez besoin de créer des catalogues ou autre, en quelques clics vous aurez la catégorie que vous voulez intégrer et les produits associés.\nLes catégories permettent la construction de l’arborescence de vos assets.",
                image: Image2,
                id: uuidv4(),
                buttons: [
                    {
                        text: 'Créer une catégorie',
                        action: () => this.goTo(ROUTE_PRODUCTS_CATEGORIES, true),
                    },
                ]
            } : null,
            hasRights(PRODUCTS, PRODUCTS_ATTRIBUTES, CREATE) ?
            {
                title: 'Pourquoi créer des attributs ?',
                text: "Les attributs seront essentiels dans l’import ou la création de vos produits. Ils vont vous permettre de définir une multitude de paramètres produit, tel que, le prix du produits, sa taille, sa couleur, sa composition, sa description...",
                image: Image1,
                id: uuidv4(),
                buttons: [
                    {
                        text: 'Créer des attributs',
                        action: () => this.goTo(ROUTE_PRODUCTS_ATTRIBUTES, true),
                    },
                ]
            } : null
        ];
        sliderData = sliderData.filter(function (el) {
            return el != null;
        });

        let stats = 
            [
                {
                    'icon': 'picto-categorie',
                    'data': this.state.products.nbCategories,
                    'dataColor': colors.blue.regular,
                    'subtitle': 'Total Catégories',
                },
                {
                    'icon': 'picto-produit',
                    'data': this.state.products.nbProducts,
                    'dataColor': colors.blue.regular,
                    'subtitle': 'Total Produits',
                },
        ];
        let lastDatas = [
            {
                'icon': 'picto-stock',
                'data': this.state.products.nbProductsEnable,
                'dataColor': colors.blue.regular,
                'subtitle': 'Produit Actif',
            },
            {
                'icon': 'picto-produit',
                'data': this.state.products.nbProductsDisable,
                'dataColor': colors.blue.regular,
                'subtitle': 'Produits Inactif',
            },
            {
                'icon': 'picto-produit',
                'data': this.state.products.productsNotInCatalog,
                'dataColor': colors.blue.regular,
                'subtitle': 'Produits sans catalogue',
            },
            {
                'icon': 'picto-produit',
                'data': this.state.products.productsInCatalog,
                'dataColor': colors.blue.regular,
                'subtitle': 'Produits avec catalogue',
            },
            {
                'icon': 'picto-produit',
                'data': this.state.products.productsWithoutImg,
                'dataColor': colors.blue.regular,
                'subtitle': 'Produits sans image',
            },
            {
                'icon': 'picto-produit',
                'data': this.state.products.productsWithImg,
                'dataColor': colors.blue.regular,
                'subtitle': 'Produits avec toutes les images',
            },
        ];

        return (
            <div style={{width: "100%"}}>
                <TopPanel 
                    icomoon="icon-dashboard" 
                    colorIcomoon={colors.blue.lighter.hue300}
                    title="Bienvenue sur le dashboard" 
                    strongText="Produits"
                    subtitle="Petite phrase de description OU heure de Mise à jour" 
                    gradientColor1={colors.menu.regular} 
                    gradientColor2={colors.menu.darker} 
                    openForm={this.state.openForm}
                    hasBorder={true}
                    stats={stats}
                />
                <Grid container direction="column" justify="center" spacing={0} style={{marginTop: 24}}>
                    <Grid item xs={12}>
                        <Typography variant="h4" style={{fontWeight: 'bold'}}>Dernières Datas</Typography>
                        <Grid container direction="row" style={{width: '100%', margin: 0, marginTop: 8}} spacing={2}>
                            {
                                lastDatas.map((data, index) => {
                                    return(
                                        <Grid key={`LastDatas${index}`} style={{marginRight: 16, marginBottom: 16}}>
                                            <CardCustom contentpadding={this.props.windowWidth > 1200 ? null : '8px'}>
                                                <Grid container justify="space-between" alignItems="center">
                                                    <Grid>
                                                        <Grid container direction="row" alignItems="center">
                                                            <Box mr={2}>
                                                                <IcomoonReact iconSet={iconSet} color="#6EAED1" size={this.props.windowWidth > 1200 ? 25 : 20} icon={data.icon} />
                                                            </Box>
                                                            <Typography variant="h4" style={{fontSize: this.props.windowWidth > 1200 ? 16 : 14}}>{data.subtitle}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Box ml={2}>
                                                        <Grid container justify="flex-end">
                                                            {
                                                                data.data || data.data === 0 ?
                                                                    <Typography variant="h2" colortext={colors.blue.lighter.hue150} style={{fontSize: this.props.windowWidth > 1200 ? 23 : 16}}>{data.data}</Typography>
                                                                : <CircularProgress size={"18px"} color={"inherit"}/>
                                                            }
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            </CardCustom>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Grid>
                    {
                        sliderData.length > 0 && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                            <Grid item xl={sliderData.length > 1 ? 12 : 6} lg={sliderData.length > 1 ? 12 : 8} md={sliderData.length > 1 ? 12 : 8} xs={12} style={{marginTop: 12, marginBottom: 16}}>
                                <Typography variant="h4" style={{fontWeight: 'bold'}}>Slider d'actions</Typography>
                                <Grid container direction="row" style={{marginTop: 8, marginBottom: 12}}>
                                    <CardCustom contentpadding="16px 0 0 0">
                                        <SliderDashboard sliderData={sliderData} windowWidth={this.props.windowWidth}/>
                                    </CardCustom>
                                </Grid>
                            </Grid>
                        : null
                    }
                    {
                        this.state.products && process.env.REACT_APP_MODE_SPREAD !== "hub" ? (
                            <Grid item xs={12} style={{marginTop: 12}}>
                                <Typography variant="h4" style={{fontWeight: 'bold'}}>Complétude</Typography>
                                <Grid container direction="row" style={{marginTop: 8}}>
                                    <Grid item xs={6} style={{ marginTop: 12, paddingRight: 8}}>
                                        <CardCustom contentpadding="0">
                                            <CardCompletudeDashboard percent={this.state.products.percentCompleteProducts} type={'produits'} time={this.state.time} layout={this.props.windowWidth > 1200 ? 1 : 3} />
                                        </CardCustom>
                                    </Grid>
                                    {/* <Grid item xs={6} style={{marginTop: 12, paddingLeft: 8}}>
                                        <CardCustom contentpadding="0">
                                            <CardCompletudeDashboard percent={this.state.products.percentCompleteProducts} type={'produits'} time={this.state.time} layout={1} />
                                        </CardCustom>
                                    </Grid>
                                    <Grid item xs={6} style={{ marginTop: 12, paddingRight: 8}}>
                                        <CardCustom contentpadding="0">
                                            <CardCompletudeDashboard percent={this.state.products.percentCompleteProducts} type={'produits'} time={this.state.time} layout={2} />
                                        </CardCustom>
                                    </Grid>
                                    <Grid item xs={6} style={{marginTop: 12, paddingLeft: 8}}>
                                        <CardCustom contentpadding="0">
                                            <CardCompletudeDashboard percent={this.state.products.percentCompleteProducts} type={'produits'} time={this.state.time} layout={2}/>
                                        </CardCustom>
                                    </Grid>
                                    <Grid item xs={4} style={{marginTop: 12, paddingRight: 8}}>
                                        <CardCustom contentpadding="0">
                                            <CardCompletudeDashboard percent={this.state.products.percentCompleteProducts} type={'produits'} time={this.state.time} layout={3}/>
                                        </CardCustom>
                                    </Grid>
                                    <Grid item xs={4} style={{marginTop: 12, paddingLeft: 8, paddingRight: 8}}>
                                        <CardCustom contentpadding="0">
                                            <CardCompletudeDashboard percent={this.state.products.percentCompleteProducts} type={'produits'} time={this.state.time} layout={3}/>
                                        </CardCustom>
                                    </Grid>
                                    <Grid item xs={4} style={{marginTop: 12, paddingLeft: 8}}>
                                        <CardCustom contentpadding="0">
                                            <CardCompletudeDashboard percent={this.state.products.percentCompleteProducts} type={'produits'} time={this.state.time} layout={3}/>
                                        </CardCustom>
                                    </Grid> */}
                                </Grid>
                            </Grid>
                        ) : null
                    }
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }}),
    }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(DashboardProducts)));