import PropTypes from "prop-types";
import React, { useState } from "react";
import H1 from '../../../../shared/components/CustomH1';
import H2 from '../../../../shared/components/CustomH2';
import Label from '../../../../shared/components/CustomLabel';
import Button from '../../../../shared/components/CustomButton';
import colors from '../../../../../colors';

const HomeGame = (props) => {
    const [hover, setHover] = useState(false);

    let { 
        preview = false,  
        id = null, 
        data = null,
        inputCallback = null,
    } = props;

    let image = data.inputs['backgroundImage'].value;

    return (
        <div 
            style={{
                backgroundColor: data.inputs['backgroundColor'].value,
                backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                backgroundSize: "100%",
                backgroundPosition: "bottom center",
                backgroundRepeat: "no-repeat",
                position: 'relative'
            }}
            className={'typeSection'}
            onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
        >
            { !preview && props.children }

            <a className="reglement">Règlement du jeu</a>
            <section 
                className="first overflow_hidden" 
                style={{
                    minHeight: ((data.blocks['title'].inputs.value?.value && data.blocks['title'].inputs.value?.value !== "<p><br></p>") || (data.blocks['subtitle'].inputs.value?.value && data.blocks['subtitle'].inputs.value?.value !== '<p><br></p>')) ? '500px' : 'auto',
                    padding: ((data.blocks['title'].inputs.value?.value && data.blocks['title'].inputs.value?.value !== "<p><br></p>") || (data.blocks['subtitle'].inputs.value?.value && data.blocks['subtitle'].inputs.value?.value !== '<p><br></p>')) ? '50px 0' : '10px 0',
                    display: 'flex',
                    flexDirection: 'column', 
                    justifyContent: 'space-around', 
                    alignItems: 'center'
                }}
            >
                <img className="logo" onClick={() => inputCallback(id, 'logo')}
                    style={{
                        width: 'auto',
                        maxWidth: '45%', 
                        minWidth: 330,
                        maxHeight: ((data.blocks['title'].inputs.value?.value && data.blocks['title'].inputs.value?.value !== "<p><br></p>") && (data.blocks['subtitle'].inputs.value?.value && data.blocks['subtitle'].inputs.value?.value !== '<p><br></p>')) ? null : '300px',
                        border: !preview ? `1px dashed ${colors.blue.lighter.hue150}` : null
                    }}
                    src={
                        typeof data.blocks.logo.inputs.value.value === 'string' ? 
                            data.blocks.logo.inputs.value.value : 
                            data.blocks.logo.inputs.value?.value?.filePath ? 
                                ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.logo.inputs.value.value.filePath}` 
                                : "http://via.placeholder.com/400x250/FFFFFF/53839D?text=Logo"
                        }
                    alt="Logo jeu"
                />
                {
                    data.blocks['title'].inputs.value?.value && data.blocks['title'].inputs.value?.value !== "<p><br></p>" ? (
                        <H1 data={data} preview={preview} component={id} block={'title'} inputCallback={inputCallback} nopadding={true} />
                    ) : null
                }
                {
                    data.blocks['subtitle'].inputs.value?.value && data.blocks['subtitle'].inputs.value?.value !== '<p><br></p>' ? (
                        <H2 data={data} preview={preview} component={id} block={'subtitle'} inputCallback={inputCallback} nopadding={true} />
                    ) : null
                }
                {
                    data.blocks.labelEmail?.inputs.value.value ?
                    
                    (
                        <form 
                            id="form_login" 
                            method="POST"
                        >
                            <Label data={data} preview={preview} component={id} block={'labelEmail'} inputCallback={inputCallback} nopadding={true} forInput="inputEmail" />
                            <div className="inputZone">
                                <input style={{
                                    fontSize: data.blocks['button'].inputs.size.value,
                                    lineHeight: '2',
                                    borderStyle: 'solid',
                                    borderWidth: data.blocks.button.inputs?.border?.inputs?.borderWidth?.value ? data.blocks.button.inputs.border.inputs.borderWidth.value : '0px',
                                    borderColor: data.blocks.button.inputs?.border?.inputs?.borderColor?.value ? data.blocks.button.inputs.border.inputs.borderColor.value : null,
                                    borderRadius: data.blocks.button.inputs?.border?.inputs?.borderRadius?.value ? data.blocks.button.inputs.border.inputs.borderRadius.value : null,
                                }} type="email" value="" name="email" id="inputEmail" placeholder="Votre adresse email..." pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required autofocus />
                                <Button data={data} preview={preview} component={id} block={'button'} inputCallback={inputCallback} className="button" nopadding={true} type="submit" />
                            </div>
                            <div className="consent">
                                <input required id="consentement" type="checkbox" />
                                <Label data={data} preview={preview} component={id} block={'labelConsent'} inputCallback={inputCallback} nopadding={true} forInput="consentement" />    
                            </div>
                        </form>
                    ) : (
                        <Button data={data} preview={preview} component={id} block={'button'} className="button" inputCallback={inputCallback} nopadding={true}/>
                    )
                }
            </section>
        </div>
    );
};

HomeGame.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingclassName: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default HomeGame;
