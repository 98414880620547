import React from 'react';

import { Box, Grid } from '@material-ui/core';
import Typography from '../../../ui/typography/Typography';
import styled from 'styled-components'

import JohnDoe from '../../../../assets/images/john-doe.png';
import Stats from '../../Stats/Stats';

const ContainerImage = styled(Grid)`
    max-width: 48px;
    max-height: 48px;
    width: 48px;
    height: 48px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    flex-basis:auto;
    img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: inline-block;
        width: 100%;
        max-height: 48px;
        max-width: 48px;
    }
`;

export default function CardHomepageStats(props){
    const stats = props.statsHomepage;

    return(
        <Box pt={ props.windowWidth < 1400 ? 0 : 2 } pb={props.windowWidth < 1400 ? 2 : 4} pl={props.windowWidth < 1400 ? 0 : 2} pr={props.windowWidth < 1400 ? 0 : 2}>
            <Grid container justify={'space-between'} wrap={props.windowWidth > 1100 ? "nowrap" : "wrap"}>
                <Grid item style={{flexGrow: 1}}>
                    <Grid container justify={"flex-start"} alignItems="center" spacing={2}>
                        <ContainerImage item xs={4}>
                            <img src={props.user.image ? `${process.env.REACT_APP_API_ROOT}/${props.user.image}` : JohnDoe} style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)'
                            }} />
                        </ContainerImage>
                        <Grid item xs={8} style={{marginLeft: 8}}>
                            <Typography variant={"body2"} style={{fontWeight: "bold"}}>Bienvenue</Typography>
                            <Box>
                                <Typography variant={"h3"}>{props.user.firstname} {props.user.lastname}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item style={{flexGrow: 1}}>
                    <Grid container alignItems="center" justify={props.windowWidth > 1100 ? "flex-end" : "flex-start"}  wrap="wrap" style={{height: '100%', marginTop: props.windowWidth > 1100 ? 0 : 16}}>
                        {stats.map((stat,index) => {
                            if (stat){
                                return(
                                    <Grid item key={`StatHomepage${index}`} style={{marginLeft: index === 0 ? 0 : 16}}>
                                        <Stats stat={stat} windowWidth={props.windowWidth}/>
                                    </Grid>
                                )
                            }
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}