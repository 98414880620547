import React from 'react';
import {Tabs,Tab} from '@material-ui/core';
import styled from 'styled-components';
import colors from '../../../../../config/theme/colors'

const TabsCustom = styled(Tabs)`
  background: white;
  border-bottom: 1px solid ${colors.grey.lighter.hue800};
  button{
    text-transform: initial;
    & > span:first-child{
      flex-direction: row;
    }
  }
  &> div > span{
    background-color: ${colors.blue.lighter.hue300};
  }
`;

export default function TabCalendar(props){
    const categories=props.categories;
    const [value, setValue] = React.useState(props.defaultTab?props.defaultTab:0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return(
        <TabsCustom
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
        >   
            
            {categories.map((categorie,index)=>
                <Tab key={`tab-calendar${index}`} label={categorie.libelle} onClick={(event) => { props.handleChangeTab(categorie.type);}}></Tab>
            )}
            
        </TabsCustom> 
    )
}