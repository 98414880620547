import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';   
import MenuItem from '@material-ui/core/MenuItem';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';   
import { SNACK, START_LOADING, STOP_LOADING } from '../../../../../js/constants/action-types';
import { ALERT_ERROR } from '../../../../../js/constants/alert-types';
import CardCustom from '../../../../layouts/Card/CardCustom';
import TypographyCustom from '../../../../ui/typography/Typography';
import styled, {css} from 'styled-components';
import colors from '../../../../../config/theme/colors';
import Button from '../../../../ui/button/Button';

import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Divider from '@material-ui/core/Divider';


import { Grid, TextField, Box } from '@material-ui/core';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction"; 
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ListIcon from '@material-ui/icons/List';
import EventIcon from '@material-ui/icons/Event';

import * as moment from 'moment';

const TextFieldCustom = styled(TextField)`
    input, .MuiSelect-select{
        font-size: 16px;
        padding: 16px;
    }
    & > p{
        margin: 0;

    }
`;

const PanelItem = styled.div `
    padding-top: 10px
`
const CustomTab = styled(Tab) `
    .MuiTab-wrapper{
        flex-direction: row;
        svg{
            margin-bottom: 0px !important;
            margin-right: 10px;
        }
    }
`

const calendarButton=css`
        background-color:white;
        color:#AAB4BA;
        font-style:italic;
        border-color:#EAECEE;
        // transform:translate(110px,-70px);
        &:not(:disabled){
            &.fc-button-active{
                background-color:#6EAED1;
                border-color:#6EAED1;
                color:white;
                &:focus{
                    box-shadow:none;
                }   
                            
            }
        }
        &:focus{
            box-shadow:0 0 0 0.2rem rgba(110, 174, 209, 0.5)
        } 
        &:active:focus{
            box-shadow:0 0 0 0.2rem rgba(110, 174, 209, 0.5);
            background-color:#6EAED1;
            border-color:transparent;
        } 
        
`
const calendarButtonArrow=css`
        background-color:transparent;
        color:#6EAED1;
        border:transparent;
        &:not(:disabled):active{
            background-color:transparent;
            color:#6EAED1;
            box-shadow:0 0 0 0.2rem rgba(110, 174, 209, 0.5);
        }
`
const CalendarContainer=styled(Grid)`
    background-color:white;
    .fc-day-grid-event{
        border-radius:15px;
        padding:0px 20px;
        margin-top: 0 !important;
        margin-bottom: 1px !important;
        font-style:italic;
    }
    .fc-row .fc-content-skeleton{
        z-index: auto;
    }
    .fc-day-header{
        padding:20px 0px;
    }
    .fc-head-container thead{
        border-top:transparent;
    }
    .fc-day-top{
        padding:3px 10px 2px 10px;
        .fc-day-number{
            float:left!important;
            font-weight:bold;
            padding:5px;
        }
    }
    color:#6EAED1;
    font-style:italic;
    .fc-unthemed td.fc-today{
        background-color:transparent;
        .fc-day-number{
            background-color:#FF6565;
            color:white;
            border-radius:50%;  
            width:15px;
            height:15px;
            line-height:15px;
            text-align:center;
        }
    }
    // .fc-center{
    //     transform:translateX(110px);
    // }
    .fc-dayGridMonth-button{
        ${calendarButton};
    }
    .fc-timeGridDay-button{
        ${calendarButton};
    }
    .fc-timeGridWeek-button{
        ${calendarButton};
    }
    .fc-next-button{
        ${calendarButtonArrow}
    }
    .fc-prev-button{
        ${calendarButtonArrow}
    }
    .fc-event{
        cursor:pointer;
        margin-bottom:5px;
        img{
            vertical-align : middle;
            margin : 0 5px;
        }
        span{
            vertical-align : middle;
        }      
    }
    td{
        cursor: pointer;
    }
    a.fc-more{
        position: absolute;
        z-index: 10;
    }
    .selectedDate{
        background-color: #6eaed133 !important;
        opacity: 1;
        position: relative;
        &:after{
            content: "+";
            position: absolute;
            font-size: 25px;
            line-height: 0.8;
            font-style: initial;
            top: 3px;
            right: -11px;
            text-align: center;
            -webkit-transform: translate(-50%);
            -ms-transform: translate(-50%);
            transform: translate(-50%);
            width: 25px;
            height: 25px;
            color: #FFFFFF;
            border-radius: 50%;
            background: ${colors.green.regular};
        }
    }
`
const drawerWidth = 400;

const styles = theme => ({
    root: {
      width: 200,
      height: 200,
      cursor: 'pointer',
      display: 'flex', 
      justifyContent: 'center',
      alignItems: 'center',
      background: 'transparent',
      margin: '0 auto',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat'
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start'
    },
    drawerPaper: {
        width: drawerWidth,
        transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    },
});

let calendarInputType = null;
let calendarProduct = null;
let calendarDate = null;
let calendarInstantId = null;
let calendarDateClicked = null;
class GameInstantCreator extends React.Component {  
    constructor(props){
        super(props);

        this.state = {
            hover: false,
            file: null,
            uploading: false,
            errorMessage : null,
            seeError : false,
            openMediaPicker: false,
            rowVariantValue: null,
            tabValue:0,
            // calendarInputType: null,
            // calendarProduct: null,
            // calendarDate: null,
            // calendarInstantId: null,
            openModal: false
        };
        this.calendarOptions={
            buttonText:{     
                today:    'Aujourd\'hui',
                month:    'Mois',
                week:     'Semaine',
                day:      'Jour',
                list:     'Liste'        
            },
            header: {
                left:   'prev',
                center: 'title',
                right:  'next',
            },
            height: 850,
            columnHeaderFormat: {
                weekday: 'long'
            }
        }
    }
    

    componentDidMount() {
        let selectedInstants = _.cloneDeep(this.props.allState.instantWinListInitial)
        this.setState({
            instantWinListSelected : selectedInstants
        });
        this.handleCalendarEvent(selectedInstants)
    }

    componentDidUpdate(prevProps, prevState){
        if (prevProps.allState.instantWinList !== this.props.allState.instantWinList){
            this.setState({
                instantWinListSelected : _.cloneDeep(this.props.allState.instantWinList)
            });
            this.handleCalendarEvent(this.props.allState.instantWinList)
        }
        // if (prevState.instantWinListSelected !== this.state.instantWinListSelected){
        //     this.handleCalendarEvent(this.state.instantWinListSelected)
        // }
    }

    handleEventsTooltip = (info) => {
        ReactDOM.render(
            // <Tooltip title={`Date: ${moment(info.event.start).format('LLL')} / Lot: ${info.event.extendedProps.productName}`} arrow={true}>
                <div className="fc-content" style={{padding:'3px 0px 3px 0px', display:'flex', flexDirection:'column'}}>
                    <span className="fc-time">{moment(info.event.start).format('LLL')}</span>
                    <span className="fc-title">Lot: {info.event.extendedProps.productName}</span>
                </div> 
            // </Tooltip>
            ,
            info.el,
        );
        return info.el
        
    };

    handleCalendarEvent = (instantList) => {
        let allEvents = [];
        let counter = 0;
        let productName = null;
        for(let instant of instantList){
            if (instant.productLink){
                let getName = this.props.allState.giftSelected.find(e => e.idGift === instant.productLink)

                getName = getName.product.productDatas.edges.find(e => e.node.attribute.identifier === 'product_name')
                productName = getName.node.value
            }
            counter++;
            allEvents.push(
            {
                title: `Instant Gagnant - #${counter}`,
                start: instant.dateSelected ? instant.dateSelected : null,
                end: instant.dateSelected ? instant.dateSelected : null,
                backgroundColor: colors.blue.lighter.hue300,
                borderColor:'transparent',
                productName: productName,
                productId: instant.productLink,
                idInstant: instant.idInstant
            });
        }
        this.setState({
            events: allEvents
        })
    }
    
    handleOpenMediaPicker = () => {
        this.setState({
            openMediaPicker : !this.state.openMediaPicker,
        });
    };

    setRowVariantValue = (row) => {
        this.setState({
            rowVariantValue : row,
        });
    }

    error = (error) => {
        this.props.snack(ALERT_ERROR, error);
        this.setState({ hover: false });
    };

    handleChangeTab=(event,value)=>{
        this.setState({
            tabValue : value
        })
    }

    handleCalendarInput = () => {
        if(calendarInputType === 'edit'){
            let getInstant = this.state.instantWinListSelected.find(e => e.idInstant === calendarInstantId)
            getInstant.productLink = calendarProduct;
            getInstant.dateSelected = calendarDate;
            getInstant.updated = true;
        }else {
            this.state.instantWinListSelected.push({
                idInstant: uuidv4(),
                new: true,
                productLink: calendarProduct,
                dateSelected: calendarDate,
                isWin: false
            })
        }
        this.props.stateCallback('instantWinList',this.state.instantWinListSelected);
        this.handleCalendarEvent(this.state.instantWinListSelected);
        this.handleClose();
    }
    handleModal = () => {
        this.setState({
            openModal: !this.state.openModal
        })
    }
    handleClose = () => {
        this.setState({
            openModal: false,
        })
        this.resetState()
    }

    resetState = () => {
        // this.setState({
        //     calendarProduct: null,
        //     dateSelected: null,
        //     calendarInstantId: null,
        //     calendarInputType: null
        // })
        calendarProduct = null;
        // this.state.dateSelected = null;
        // calendarInstantId = null;
        calendarInputType = null;
    }

    handleDateClick = (info) => {
        var days = document.querySelectorAll(".selectedDate");
        days.forEach(function(day) {
            day.classList.remove("selectedDate");
            day.onclick = () => {return(false)};
        });
        // AVOID RE-RENDER
        // this.setState(function(state) {
        //     return {
        //         calendarInputType: 'add',
        //         calendarProduct: null,
        //         calendarDate: moment(info.dateStr).format().slice(0,19),
        //         calendarDateClicked: info.dateStr
        //     };
        // });
        calendarInputType = 'add';
        calendarProduct = null;
        calendarDate = moment(info.dateStr).format().slice(0,19);
        calendarDateClicked = info.dateStr;
        
        info.dayEl.classList.add("selectedDate");
        info.dayEl.onclick = () => this.handleModal()
    };

    handleEventClick = (info) => {

        var days = document.querySelectorAll(".selectedDate");
        days.forEach(function(day) {
            day.classList.remove("selectedDate");
            day.onclick = () => {return(false)};
        });

        // AVOID RE-RENDER
        calendarInputType = 'edit';
        calendarDate = moment(info.event.start).format().slice(0,19);
        calendarProduct = info.event.extendedProps.productId;
        calendarInstantId = info.event.extendedProps.idInstant;
        calendarDateClicked= null;
        this.handleModal()

    };

    render() {
        const { seeError, errorMessage } = this.state;
        const { allState, stateCallback } = this.props;
        const TabPanel=(props)=>{
            return (
                props.index === this.state.tabValue 
                    ? 
                    <PanelItem>{props.children}</PanelItem>
                :null 
                
            );
        }
        const { classes } = this.props;

        return (
            <div style={{ padding: '0px 32px 32px 32px', backgroundColor: 'rgb(250, 251, 251)' }}>
                <Grid container style={{marginTop: 12}} direction="column">
                    <Tabs
                        value={this.state.tabValue}
                        onChange={this.handleChangeTab}
                        scrollButtons="on"
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="scrollable force tabs example"
                        variant="fullWidth"
                        >
                        <CustomTab label="Vue Liste" icon={<ListIcon/>}/>
                        <CustomTab label="Vue Calendrier" icon={<EventIcon/>}/>
                    </Tabs>
                    <TabPanel index={0}>
                    {
                        this.state.instantWinListSelected?.length > 0 ?
                        this.state.instantWinListSelected.map((row, i) => {
                                if (row.deleted)
                                    return null;

                                return (
                                        <Grid key={i} item xs={12} style={{ marginBottom: 15 }}>
                                            <CardCustom paddingbottom="16px" paddingtop="16px">
                                                <Grid container direction={"column"}>
                                                    <TypographyCustom variant="body2" colortext="#333333" style={{marginBottom: "18px"}}>Instant Gagnant - #{row.deleted ? i-1 : i+1}</TypographyCustom>
                                                    {row.isWin ? 
                                                    <TypographyCustom variant="body2" colortext={colors.green.regular} style={{marginTop: "-10px", marginBottom: "8px"}}>Cet instant est déjà remporté, impossible de le supprimer ou de le modifier.</TypographyCustom>
                                                    : null}
                                                </Grid>
                                                <Grid container direction="row" spacing={0} style={{position: 'relative'}}>
                                                    <Grid item xs={5} style={{ paddingRight: 20, marginTop: 8 }}>
                                                        <TypographyCustom variant="body2" colortext={colors.blue.lighter.hue300} style={{marginBottom: 8}}>Produit :</TypographyCustom>
                                                        {/* <TypographyCustom variant="h5" colortext="#333333">{row.productLink}</TypographyCustom> */}
                                                        <TextFieldCustom
                                                            id={`ProductInstant${i}`}
                                                            variant="outlined"
                                                            color="secondary"
                                                            select
                                                            fullWidth
                                                            value={row.productLink ? row.productLink : ''}
                                                            onChange={(evt) => {
                                                                row.productLink = evt.target.value; 
                                                                row.updated = true; 
                                                                stateCallback('instantWinList', this.state.instantWinListSelected)}}
                                                            helperText={seeError ? errorMessage : null}
                                                            disabled={row.isWin ? true : false}
                                                            error={seeError && errorMessage ? true : false}
                                                            >
                                                            {allState.giftSelected.map((option,index) => {
                                                                let getLabel = option.product.productDatas.edges.find(e => e.node.attribute.identifier === "product_name")
                                                                return(
                                                                    <MenuItem key={`OptionSelect${index}`} value={option.idGift} disabled={option.disabled}>
                                                                        {getLabel.node.value}
                                                                    </MenuItem>
                                                                )
                                                            })}
                                                        </TextFieldCustom>
                                                    </Grid>
                                                    <Grid item xs={5} style={{ paddingRight: 20, marginTop: 8 }}>
                                                        <TypographyCustom variant="body2" colortext={colors.blue.lighter.hue300} style={{marginBottom: 8}}>Date :</TypographyCustom>
                                                        <TextFieldCustom
                                                            id={`DateInstant${i}`}
                                                            type="datetime-local"
                                                            variant="outlined"
                                                            fullWidth
                                                            inputProps={{
                                                                step: "1",
                                                                min: this.props.allState.paramStartAt ? this.props.allState.paramStartAt : null,
                                                                max: this.props.allState.paramEndAt ? this.props.allState.paramEndAt : null
                                                            }}
                                                            disabled={row.isWin ? true : false}
                                                            defaultValue={row.dateSelected ? row.dateSelected : ''}
                                                            error={seeError && errorMessage ? true : false}
                                                            onChange={(evt) => {
                                                                row.dateSelected = evt.target.value; 
                                                                row.updated = true; 
                                                                stateCallback('instantWinList', this.state.instantWinListSelected)}
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2} style={{
                                                        position: 'absolute',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        right: '-20px',
                                                        top: '50%',
                                                        transform: 'translate(-50%)'
                                                    }}> 
                                                        <Tooltip 
                                                            title={row.isWin ? "Instant gagnant déjà remporté" : "Supprimer" }
                                                            style={{marginTop: "8px"}}
                                                        >
                                                            {row.isWin ? 
                                                            <CheckCircleIcon style={{ color: colors.green.regular}} /> 
                                                            : <DeleteIcon 
                                                                style={{ color: '#FF6565', cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    row.deleted = true;
                                                                    // this.handleCalendarEvent(this.state.instantWinListSelected)
                                                                    stateCallback('instantWinList', this.state.instantWinListSelected.filter(
                                                                        e =>  e.idInstant !== row.idInstant
                                                                    ));
                                                                }} 
                                                            />
                                                            }
                                                            
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </CardCustom>
                                        </Grid>
                                );
                            })
                        : <Box mb={2} style={{width: "100%"}}><TypographyCustom variant="body2">Vous n’avez pas encore d'instant gagnant</TypographyCustom></Box>
                    }
                    <div 
                        style={{
                            height: 21,
                            color: '#59C870',
                            textDecoration: 'underline',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            this.state.instantWinListSelected.push({
                                idInstant: uuidv4(),
                                new: true,
                                productLink: null,
                                dateSelected: null,
                                isWin: false
                            })
                            this.props.stateCallback(this.state.instantWinListSelected);
                        }}
                    >
                        <AddCircleIcon fontSize="small" style={{ marginRight: 10 }} />
                        <span>Ajouter un instant gagnant</span>
                    </div>
                    </TabPanel>

                    <TabPanel index={1}>
                        <CalendarContainer>
                            <Box pt={2}>
                                <FullCalendar
                                    defaultView="dayGridMonth"
                                    plugins={[ dayGridPlugin,timeGridPlugin, interactionPlugin]}
                                    buttonText={this.calendarOptions.buttonText }
                                    header={this.calendarOptions.header}
                                    columnHeaderFormat={this.calendarOptions.columnHeaderFormat}
                                    height={this.calendarOptions.height}
                                    month={1}
                                    weekends={true}
                                    events={this.state.events}
                                    eventRender={this.handleEventsTooltip}
                                    eventClick={this.handleEventClick}
                                    dateClick={this.handleDateClick}
                                    eventLimit={2}
                                    eventLimitText="Voir plus"
                                    locale="fr"
                                    firstDay={1}
                                    contentHeight='auto'
                                    dayRender={(item) => {
                                        if(moment(calendarDateClicked).isSame(item.date)){
                                            if(!item.el.onclick){
                                                item.el.onclick = () => this.handleModal()
                                            }
                                            item.el.classList.add("selectedDate");
                                        }
                                    }}
                                />  
                            </Box>
                        </CalendarContainer>
                    </TabPanel>
                </Grid>
                <Drawer 
                    anchor={'right'}
                    open={this.state.openModal}
                    // onClose={() => this.handleClose()}
                    className={classes.drawer}
                    classes={{ paper: classes.drawerPaper }}
                    hideBackdrop={true}
                >
                    <div className={classes.drawerHeader}>
                        <IconButton onClick={() => this.handleClose()}>
                            <ChevronRightIcon />
                        </IconButton>
                    </div>
                    <Divider />
                    <Grid container direction="column" spacing={0} style={{position: 'relative', padding: '0 20px'}}>
                        <Grid item xs={12} style={{ marginTop: 8 }}>
                            <TypographyCustom variant="body2" colortext={colors.blue.lighter.hue300} style={{marginBottom: 8}}>Produit :</TypographyCustom>
                            {/* <TypographyCustom variant="h5" colortext="#333333">{row.productLink}</TypographyCustom> */}
                            <TextFieldCustom
                                id={`ProductInstantCalendar`}
                                variant="outlined"
                                color="secondary"
                                select
                                fullWidth
                                value={calendarProduct ? calendarProduct : ''}
                                onChange={(evt) => {
                                    this.setState({
                                        calendarProduct: evt.target.value
                                    }); 
                                }}
                                // helperText={seeError ? errorMessage : null}
                                // disabled={row.isWin ? true : false}
                                // error={seeError && errorMessage ? true : false}
                                >
                                {allState.giftSelected.map((option,index) => {
                                    let getLabel = option.product.productDatas.edges.find(e => e.node.attribute.identifier === "product_name")
                                    return(
                                        <MenuItem key={`OptionSelect${index}`} value={option.idGift} disabled={option.disabled}>
                                            {getLabel.node.value}
                                        </MenuItem>
                                    )
                                })}
                            </TextFieldCustom>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: 8 }}>
                            <TypographyCustom variant="body2" colortext={colors.blue.lighter.hue300} style={{marginBottom: 8}}>Date :</TypographyCustom>
                            <TextFieldCustom
                                id={`DateInstantCalendar`}
                                type="datetime-local"
                                variant="outlined"
                                fullWidth
                                inputProps={{
                                    step: "1"
                                }}
                                // disabled={row.isWin ? true : false}
                                defaultValue={calendarDate ? calendarDate : ''}
                                error={seeError && errorMessage ? true : false}
                                onChange={(evt) => {
                                    this.setState({
                                        calendarDate: evt.target.value
                                    }); 
                                    // row.dateSelected = evt.target.value; 
                                    // row.updated = true; 
                                    // stateCallback('instantWinList', this.state.instantWinListSelected)
                                }}
                            />
                        </Grid>
                        <Box mt={2} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Button onClick={() => this.handleCalendarInput()}>
                                {calendarInputType === 'edit' ? 'Modifier' : 'Ajouter'}
                            </Button>
                            {calendarInputType === 'edit' ?
                                <DeleteIcon 
                                style={{ color: '#FF6565', cursor: 'pointer', marginLeft: 10 }}
                                onClick={() => {
                                    this.handleClose()
                                    stateCallback('instantWinList', this.state.instantWinListSelected.filter(
                                        e =>  e.idInstant !== calendarInstantId
                                    ));
                                }} 
                                />
                                :null
                            }
                        </Box>
                    </Grid>
                </Drawer> 
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};

export default withStyles(styles)(connect(null, mapDispatchToProps)(GameInstantCreator));