import PropTypes from "prop-types";
import React, { useEffect, useState, useContext } from "react";
import NavMenu from "../_internals/header/NavMenu";
import IconGroup from "../_internals/header/IconGroup";
import MobileMenu from "../_internals/header/MobileMenu";
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Context } from '../../../../../store.js';

const style = {
  width: '100%'
};

const LinkCustom = (props) => {
    if (props.spread) {
        return(
          <>
              { props.children }
          </>
        );
    } else {
        return <Link href={props.href} as={props.as} shallow={true}>{ props.children }</Link>
    }
}

const Header = ({
  data = null,
  noSticky = null,
  categories = [],
  spread = false
}) => {
    const [scroll, setScroll] = useState(0);
    const [headerTop, setHeaderTop] = useState(0);
    const context = useContext(Context);

    useEffect(() => {
        const header = document.querySelector(".sticky-bar");
        setHeaderTop(header.offsetTop);
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleScroll = () => {
        setScroll(window.scrollY);
    };

  return (
    <div style={style} className="builder-template-scope">
      <header className={`header-area clearfix`}>
        <div
          className={`sticky-bar header-res-padding clearfix ${
            (scroll > headerTop) && !noSticky ? "stick" : ""
          }`}
        >
          <div className={"container"}>
            <div className="row">
              <div className="col-xl-2 col-lg-2 col-md-6 col-4">
                {/* header logo */}
                <div className={`logo`}>
                    <LinkCustom spread={spread} href="/" key={'image-header'}>
                        <a href="#">
                            <img 
                                alt=""
                                style={{ maxHeight: 50 }}
                                src={ typeof data.logo.value === 'string' ? data.logo.value : `${process.env.REACT_APP_MEDIAS}/${data.logo.value.filePath}`}
                            />
                        </a>
                    </LinkCustom>
                </div>  
              </div>
              <div className="col-xl-8 col-lg-8 d-none d-lg-block">
                {/* Nav menu */}
                <NavMenu menu={data.menu.value} categories={categories} spread={spread} />
              </div>
              <div className="col-xl-2 col-lg-2 col-md-6 col-8">
                {/* Icon group */}
                <IconGroup spread={spread} data={data} products={context?.products} />
              </div>
            </div>
          </div>
          {/* mobile menu */}
          <MobileMenu menu={data.menu.value} categories={categories} spread={spread} />
        </div>
      </header>
    </div>
  );
};

Header.propTypes = {
  borderStyle: PropTypes.string,
  headerPaddingClass: PropTypes.string,
  layout: PropTypes.string,
  top: PropTypes.string, 
  noSticky: PropTypes.bool
};

export default Header;
