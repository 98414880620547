import PropTypes from "prop-types";
import React, { useState } from "react";
import AmazingBlock from '../../../../shared/components/AmazingBlock';


const AmazingBlocGame = (props) => {
    const [hover, setHover] = useState(false);
    const [hoverBlock, setHoverBlock] = useState(false);
    

    let { 
        preview = false,  
        id = null, 
        data = null,
        inputCallback = null,
        update = null
    } = props;

    let border = null;
    if (data.blocks.insideBlock.inputs.border) {
        border = data.blocks.insideBlock.inputs.border;
    }

    let image = data.inputs['backgroundImage'].value;
    let image2 = data.blocks.insideBlock.inputs['backgroundImage'].value;
    
    return (
        <section 
            className="how-container"
            style={{
                backgroundColor: data.inputs['backgroundColor'].value,
                backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                backgroundSize: "100%",
                backgroundPosition: "top center",
                backgroundRepeat: "no-repeat",
                position: 'relative',
            }}
            onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
            >
            { !preview && props.toolbarSection }
            <section 
                className="how" 
                style={{
                    backgroundColor: data.blocks.insideBlock.inputs['backgroundColor'].value,
                    backgroundImage: typeof image2 === 'string' ? `url(${image2})` : image2?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image2.filePath})` : 'none',
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    position: 'relative',
                    borderStyle: 'solid',
                    borderWidth: border?.inputs?.borderWidth?.value ? border.inputs.borderWidth.value : '0px',
                    borderColor: border?.inputs?.borderColor?.value ? border.inputs.borderColor.value : null,
                    borderRadius: border?.inputs?.borderRadius?.value ? border.inputs.borderRadius.value : null,
                    width: `${data.blocks.insideBlock.inputs['width'].value}%`,
                    maxWidth: data.blocks.insideBlock.inputs['maxWidth'].value,
                    paddingTop: data.blocks.insideBlock.inputs['paddingTop'].value,
                    paddingBottom: data.blocks.insideBlock.inputs['paddingBottom'].value,
                }}
                onMouseEnter={() => {setHover(false); setHoverBlock(true)}} onMouseLeave={() => {setHover(true);setHoverBlock(false)}}
            >
                { !preview && hoverBlock && props.toolbarBlock }
                
                {
                    data.blocks.insideBlock.blocks.length > 0 ? (
                        data.blocks.insideBlock.blocks.map((value, index) => (
                            <AmazingBlock data={value} preview={preview} />
                        ))
                    ) : null
                }

                { !preview && props.children}
            </section>
        </section>
    );
};

AmazingBlocGame.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingclassName: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default AmazingBlocGame;
