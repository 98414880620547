import PropTypes from "prop-types";
import React, {useState} from "react";
import Link from 'next/link';
import H1 from '../../../../shared/components/CustomH1';
import H2 from '../../../../shared/components/CustomH2';
import Button from '../../../../shared/components/CustomLink';

const LinkCustom = (props) => {
    if (props.spread) {
        return <>
            { props.children }
        </>
    } else {
        return <Link href={props.href} as={props.as} shallow={true}>{ props.children }</Link>
    }
}

const TopHomepageBook = (props) => {
    const [hover, setHover] = useState(false);
    let {
        preview = false,
        id = null, 
        data = null,
        noSticky,
        inputCallback = null,
        spread = false
    } = props;

    let image = data.inputs['backgroundImage'].value;

    return (
        <div 
            className="topHomepage"
            style={{
                backgroundColor: data.inputs['backgroundColor'].value,
                backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                backgroundSize: "100%",
                backgroundPosition: "bottom center",
                backgroundRepeat: "no-repeat",
                position: 'relative'
            }}
            onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
        >
            { !preview && props.children }
            <div className="title">
                <img class="logo" onClick={() => inputCallback(id, 'logo')}
                    style={{
                        width: 'auto',
                        maxWidth: '45%', 
                        minWidth: 330,
                    }}
                    src={
                        typeof data?.blocks.logo.inputs.value.value === 'string' ? 
                            data.blocks.logo.inputs.value.value : 
                            data?.blocks.logo.inputs.value?.value?.filePath ? 
                                ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.logo.inputs.value.value.filePath}` 
                                : "http://via.placeholder.com/200x64/FFFFFF/AAAAAA?text=Titre"
                        }
                    alt="Logo jeu"
                />
            </div>
                {
                    data?.blocks['title'].inputs.value?.value && data?.blocks['title'].inputs.value?.value !== "<p><br></p>" ? (
                        <H1 data={data} preview={preview} component={id} block={'title'} inputCallback={inputCallback} nopadding={true} />
                    ) : null
                }
                {
                    data?.blocks['subtitle'].inputs.value?.value && data?.blocks['subtitle'].inputs.value?.value !== '<p><br></p>' ? (
                        <H2 data={data} preview={preview} component={id} block={'subtitle'} inputCallback={inputCallback} nopadding={true} />
                    ) : null
                }
            <div className="wrapper_button">
                <Button data={data} preview={preview} component={id} block={'button'} className="as_button" inputCallback={inputCallback} nopadding={true} />
            </div>
        </div>
    );
};

TopHomepageBook.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingClass: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default TopHomepageBook;
