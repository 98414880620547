import PropTypes from "prop-types";
import React, { useState } from "react";
import Pg from '../../../../shared/components/CustomParagraph';
import Button from '../../../../shared/components/CustomLink';

const BannerLink = (props) => {
    const [hover, setHover] = useState(false);

    let {
        preview = false,
        id = null, 
        data = null,
        noSticky,
        inputCallback = null,
        link = '',
        spread = false
    } = props;

    let image = data.inputs['backgroundImage'].value;
    
    return (
        <div 
            className="banner_link_lego"
            style={{
                backgroundColor: data.inputs['backgroundColor'].value,
                backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                position: 'relative',
            }}
            onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
        >
            <div className="wrapper_text">
                { !preview && props.children }
                <Pg data={data} preview={preview} component={id} block={'text'} inputCallback={inputCallback} nopadding={true} />
                <Button data={data} preview={preview} component={id} block={'button'} className="as_button" inputCallback={inputCallback} nopadding={true} />
                {/* <a href={link} onClick={e => {
                    if (spread)
                        e.preventDefault();
                }} target="__blank">C'est par ici &nbsp;!</a> */}
            </div>
        </div>
    );
};

BannerLink.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingClass: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default BannerLink;
