import React from 'react';
import {Box, Grid} from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import Typography from '../../../../ui/typography/Typography';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import * as moment from 'moment';
import colors from '../../../../../config/theme/colors';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import CheckIcon from '@material-ui/icons/Check';
import Drawer from '@material-ui/core/Drawer';
import CardCustom from '../../../../layouts/Card/CardCustom';
import PageLoader from '../../../../ui/loadings/page-loader/PageLoader';

const WrapperButton = styled.a`
    border-radius: 4px;
    color: white;
    padding: 4px 8px;
    margin-top: 15px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    background: ${colors.blue.lighter.hue150};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    svg{
        width: 0.8em;
    }
    &:hover{
        background: ${colors.blue.regular};
    }
    &.error{
        background: ${colors.orange.regular};
        &:hover{
            background: ${colors.orange.darker};
        }
    }
`;

const ContainerBlocNotification = styled(Box)`
    border-radius: 10px;
    position: relative;
    width: 300px;
`;
const ContainerNotification = styled(Box)`
    background: ${colors.blue.lighter.hue900};
    border-radius: 10px;
    position: relative;
    padding-top: 36px;
    padding-bottom: 16px;
`;

const BadgeCustom = styled(Badge)`
    display: block;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    &>span{
        border-radius: 10px 0 0 0;
        background-color: ${props => props.bgcolor ? props.bgcolor : colors.white};
        color: ${props => props.bgcolor ? colors.white : colors.black};
        width: 100px;
        display: flex;
        position: relative;
        transform: inherit;
        transform-origin: inherit;
        height: 28px;
    }
    &+p{
        font-weight: normal;
        position: absolute;
        top: 0;
        left: 0;
        padding-left: 110px;
        background: ${colors.blue.lighter.hue600};
        width: 100%;
        border-radius: 10px 10px 0;
        height: 28px;
        margin: 0;
        padding: 4px 10px 4px 110px;
        text-align: end;
        svg{
            fill: white;
        }
    }
`;

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: colors.blue.regular,
    },
    tooltip: {
        backgroundColor: colors.blue.regular,
        fontSize: 16
    },
}));

const DrawerCustom = styled(Drawer)`
    .MuiBackdrop-root{
        opacity: 0;
    }
    min-width: 200px;
`;

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

export default function CronNotifications(props) {
    return (
        <DrawerCustom transitionDuration={400} anchor="right" open={props.drawerCron} onClose={props.closeDrawer}>
            <Grid item xs={12}>
                <Box pt={3} pb={2}>
                    <CardCustom style={{height: 'calc(100vh - 40px)', overflow: 'auto', boxShadow: 'inherit'}}>
                        {
                        props.listCrons !== null ?
                            <div>
                                {
                                    props.retailerCron ? (
                                        <Box mt={2} mb={3} pb={1} style={{borderBottom: `1px solid ${colors.blue.regular}`}}>
                                            <Typography variant="h2" colortext={colors.blue.regular}>{props.retailerCron?.libelle}</Typography>
                                        </Box>
                                    ) : null
                                }
                                <Box mt={2} mb={2}>
                                    <Typography variant="h3" colortext={colors.blue.lighter.hue300}>Liste des crons :</Typography>
                                </Box>
                                <ContainerBlocNotification>
                                    {
                                        props.listCrons?.length > 0 ? 
                                        (
                                            props.listCrons.map((crons,index) => {
                                                let value = {
                                                    file: (crons.details.errorFile ? crons.details.errorFile : crons.details.file ? crons.details.file : null), 
                                                    type: (crons.details.errorFile ? 'error' : crons.details.file ? 'file' : null)
                                                };

                                                return(
                                                    <ContainerNotification p={2} mt={2} pb={3} pt={1} key={`Notification${index}`}>
                                                        <Box>
                                                            {crons.status ?
                                                                (
                                                                    <BadgeCustom
                                                                        bgcolor={
                                                                            crons.status === "success" ? 
                                                                                colors.green.regular 
                                                                            : crons.status === "pending" ? 
                                                                                colors.orange.regular 
                                                                            : crons.status === "skipped" ? 
                                                                                colors.grey.regular
                                                                            : crons.status === "running" ?
                                                                                colors.blue.lighter.hue300
                                                                            :
                                                                                colors.red.regular
                                                                        } 
                                                                        badgeContent={
                                                                            crons.status === "success" ? 
                                                                                "Succès" 
                                                                            : crons.status === "pending" ? 
                                                                                "En attente" 
                                                                            : crons.status === "skipped" ?
                                                                                "Ignoré"
                                                                            : crons.status === "running" ?
                                                                                "En cours"
                                                                            :
                                                                                "Erreur"
                                                                        } 
                                                                    />
                                                                ) : null
                                                            }
                                                            <p>
                                                                <BootstrapTooltip title={<span dangerouslySetInnerHTML={{__html: `Début le : ${moment(crons.start).format('DD/MM/YYYY HH:MM:SS')} ${crons.end ? (`<br /> Terminé le : ${moment(crons.end).format('DD/MM/YYYY HH:MM:SS')}`) : ''}`}} />} 
                                                                    style={{fontSize: 20}}
                                                                >
                                                                    <QueryBuilderIcon />
                                                                </BootstrapTooltip>
                                                            </p>
                                                        </Box>
                                                        {crons.type ? (
                                                            <Typography><strong>Type de cron :</strong> {crons.type}</Typography>
                                                        ) : null}
                                                        {
                                                            value.file ?
                                                            (
                                                                <WrapperButton className={value.type} href={value.type === "error" ? `${process.env.REACT_APP_API_ROOT}/${value.file}` : `${value.file}`} target="_blank" download>
                                                                    {value.type === "error" ? 
                                                                        (
                                                                            <ReportProblemOutlinedIcon style={{marginRight: 12}} />
                                                                        ) : <CheckIcon style={{marginRight: 12}} />
                                                                    }
                                                                    {value.type === "error" ? 
                                                                        (
                                                                            "Télécharger le rapport"
                                                                        ) : "Télécharger le fichier"
                                                                    }
                                                                </WrapperButton>
                                                            )
                                                        : null
                                                        }
                                                    </ContainerNotification>
                                                )
                                            })
                                        ) : null
                                    }
                                </ContainerBlocNotification>
                            </div>
                            : <PageLoader />
                        }
                    </CardCustom>
                </Box>
            </Grid>
        </DrawerCustom>
    )
}