import PropTypes from "prop-types";
import React, { Fragment } from "react";
import notFound from '../../../../../../not-found.png';
import { useDrop } from 'react-dnd';
import Link from 'next/link';

const LinkCustom = (props) => {
    if (props.spread) {
        return <>
            { props.children }
        </>
    } else {
        return <Link href={props.href} as={props.as} shallow={true}>{ props.children }</Link>
    }
}

const ProductGridSingle = ({
    product,
    number = 0,
    update = null,
    mapping,
    spread = false
}) => {
    let name    = null;
    let image   = null;

    let allNames    = product?.attributes.product_name;
    name            = allNames?.['fr_FR'] ?? product?.sku;

    let allImages   = product?.attributes.product_image;
    image           = allImages?.['fr_FR'] ?? null;

    const [{ isOver, canDrop }, drop] = useDrop({
        accept: ['product'],
		drop: (item) => {
            mapping[number] = item.media.id;
        },
		collect: monitor => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
		}),
    });

    if (!product && !spread)
        return null;

    return (
        <Fragment>
            <div
                className={`col-xl-3 col-md-6 col-lg-4 col-sm-6`}
                style={{ marginTop: 30 }}
                ref={drop} 
            >
                <div className={`product-wrap`}>
                    <div className="product-img" style={{
                        borderWidth: isOver ? 6 : 0,
                        borderColor: 'greenyellow',
                        borderStyle: 'solid',
                        borderRadius: isOver ? 4 : 0
                    }}>
                        <LinkCustom spread={spread} href="/product/[slug]" as={`/product/${product?.id.toString().replace('/api/products/', '')}`}>
                            <a>
                                <img
                                    className="default-img"
                                    src={ image ? `${process.env.REACT_APP_MEDIAS}/${image}` : notFound }
                                    alt=""
                                />
                            </a>
                        </LinkCustom>
                    </div>
                    <div className="product-content text-center">
                        <h3>
                            <a href={"/product/" + product?.sku} onClick={(e) => e.preventDefault()}>
                                { name ? name : `Lorem ipsum ${number + 1}` }
                            </a>
                        </h3>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

ProductGridSingle.propTypes = {
  product: PropTypes.object,
  sliderClassName: PropTypes.string,
  spaceBottomClass: PropTypes.string
};

export default ProductGridSingle;
