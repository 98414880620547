import React from 'react';
import {Chip,Grid,TextField,InputAdornment,Typography} from '@material-ui/core/' ;
import { withApollo } from 'react-apollo';
import styled from 'styled-components';
import AddBoxIcon from '@material-ui/icons/AddBox';
import SearchIcon from '@material-ui/icons/Search';
import colors from '../../../config/theme/colors'

import {SEARCH_TAGS} from '../../../queries/medias'



const TextFieldCustom = styled(TextField)`
    position : absolute;
    top:0;
    left :0;
    height:100%;
    z-index:10;
    background:transparent;

    input, .MuiSelect-select{
        font-size: 16px;
        padding: 16px 5px;
        @media screen and (max-width: 850px){
            font-size: 14px;
        }
        &:-webkit-autofill:focus{
            background:none!important;
        }
        &:-internal-autofill-selected{
            background:none!important;
        }
        
    }
    .MuiSelect-select{
        // text-transform: capitalize;
    }
    & > p{
        margin: 0;
    }
    .MuiInputAdornment-positionStart{
        p,svg{
            color : ${colors.grey.lighter.hue800};
        }
        ${props=>props.isfilter === false 
            ? 
            props.searchvalue.length > 0 ?
                `
                    svg{
                        display: block;
                        cursor:pointer;
                        transition:all .2s;
                        color:${colors.blue.darker.hue300}
                    }       
                `
                :
                `
                    svg{
                        display:none;
                        // &:hover{
                        //     color:${colors.blue.darker.hue300}
                        // }
                    } 
                `
            :
            `
                
            `
        }

        
    }

`;

const InputContainer = styled(Grid)`
    position :relative;
    height :50px;
`

const Suggestion = styled(Typography)`
    position : absolute;
    top:1px;
    left :0;
    height:100%;
    width : 100%;
    display : flex;
    align-items:center;
    font-size:16px;
    color : ${colors.grey.lighter.hue600};
    padding-left:37.82px;
    @media screen and (max-width: 850px){
        font-size: 14px;
    }
    span{
        z-index:15;
        cursor:pointer;
        border : dotted 1px ${colors.grey.lighter.hue600};
        padding: 2px 5px;
        margin-left:8px;
        transition : all .1s;
        &:hover{
            color : ${colors.blue.lighter.hue300};
            border-color:${colors.blue.lighter.hue300};
        }
    }
`
class SearchTags extends React.Component{
    constructor(props){
        super(props)
        this.state={

            isFilter:this.props.searchOptions?.isFilter ?? false,

            placeholder : this.props.searchOptions?.placeholder ?? 'Rechercher ou ajouter un tag',
            suggestions:{
                label:'',
                value:null,
            },
            searchValue:this.props.searchOptions?.defaultValue ?? '',
        }
    }

    addTag= (e,isNew)=>{
        let value = isNew ? e : e.value;
        if(value?.length>0){
            let tags = this.state.tags;
            let suggestions = this.state.suggestions;
            if(!isNew){
                suggestions = suggestions.filter(suggestion=>suggestion.value !== e.value)
            }
            tags.push({
                tag: value, 
                id : isNew ? null : e.id,
                mediaObjects : isNew ? null : e.mediaObjects,
            });
            this.setState({
                tags : tags,
                searchValue:isNew ? '' : suggestions?.length >0 ? this.state.searchValue:'',
                suggestions:isNew ? {} :suggestions,
            })
            this.props.stateCallback(this.state.isFilter ? tags:'fileTags',tags);                     
        }
    }

    deleteTag = (index) =>{
        let tags = this.state.tags;
        tags.splice(index,1);
        this.setState({
            tags : tags
        })
        this.props.stateCallback(this.state.isFilter ? tags:'fileTags',tags);                     


    }
    handleChange = (event)=>{
        this.setState({searchValue : event.target.value});
        let suggestions;
        let search = event.target.value

        if(search.length>=3){
            this.props.client.query({
                query : SEARCH_TAGS,
                variables:{
                    tag:search,
                    first :5,
                }
            }).then(result=>{
                let currentTags = this.state.tags.map(tag=>tag.tag);
                let tags = result.data.mediaObjectTags.edges.map(tag=>{
                    return{
                        value : tag.node.tag,
                        id : tag.node.id,
                        mediaObjects : tag.node.mediaObjects.edges.map(media=>media.node.id)                    
                    }

                }).filter(tag=>!currentTags.includes(tag.value)); 
                this.setState({suggestions : tags})
            })
            
        }

        else{
            suggestions = {
                id : null,
                value : null,
            };
            this.setState({suggestions : suggestions})

        }  
        
    }
    handleKeyDown = event =>{
        if(event.keyCode === 13 && !this.state.isFilter){
            event.preventDefault();
            this.addTag(event.target.value,true);
        }
        else if(event.keyCode === 9){
            event.preventDefault();
            this.addTag(this.state.suggestions[0],false);

        }
    }

    componentDidMount(){
        let isFilter = this.state.isFilter;
        let tags = this.props.allState?.fileTags?.length>0 ? this.props.allState.fileTags :[]
        this.setState({
            tags : isFilter ? [] : tags
        })
    }
    render(){
        return(
            <Grid style={{padding:this.state.isFilter ? 0 :32}} container>
                {
                    !this.state.isFilter ?
                        <Typography variant={"h4"} style={{marginBottom: 16}}>
                            Afin d'ajouter un tag cliquez sur l'icône +, ou veuillez appuyer sur la touche entrée de votre clavier.
                        </Typography>
                    : null
                }
                <InputContainer item xs={12}>
                    <TextFieldCustom
                            id="SearchTag"
                            variant="outlined"
                            fullWidth= {true}
                            name="tag_search"
                            placeholder={this.state.placeholder}
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start" >
                                    #
                                </InputAdornment>
                                ),
                                endAdornment:(
                                    <InputAdornment position="start">
                                        {
                                            this.state.isFilter ? 
                                                <SearchIcon/>   
                                            :
                                                <AddBoxIcon style={{fontSize:35}} onClick={()=>this.addTag(this.state.searchValue,true)}/>
                                        }

                                    </InputAdornment>
                                ),

                            }}
                            onChange = {event=>this.handleChange(event)}
                            onKeyDown = {event=>this.handleKeyDown(event)}
                            value = {this.state.searchValue}
                            isfilter = {this.state.isFilter ? 1 : 0}
                            searchvalue = {this.state.searchValue}
                            autoComplete = "off"

        
                    />
                    <Suggestion>
                        {this.state.searchValue}
                        {this.state.suggestions?.length >0 ?
                            this.state.suggestions.map((tag,index)=>(
                                <span onClick={()=>this.addTag(tag,false)} key={`tag-suggestion-${index}`}>
                                    {tag.value}
                                </span>
                            ))      
                        :null}
                    </Suggestion>
                </InputContainer>

                <Grid item xs={12}>
                    <Grid container style={{minHeight:0,margin:'10px 0px'}}>
                        {this.state.tags?.length > 0 ? 
                            <div>
                                {
                                    !this.state.isFilter ?
                                        <div>
                                            Tags de l'image :
                                        </div>
                                    : null
                                }
                                {
                                    this.state.tags.map((tag,index)=>(
                                        <Chip
                                            label={tag.tag}
                                            onDelete={()=>this.deleteTag(index)}
                                            key={`tag-${index}`}
                                            style={{marginRight :5,marginTop:5}}
                                        />  
                                        ))  
                                }
                            </div>
                        :
                            !this.state.isFilter ?
                                <span>Pas encore de tag selectionné pour cette image.</span>
                            : null
                        }                        
                    </Grid>
                </Grid>
            </Grid>

        )
    }        
}
export default withApollo((SearchTags));