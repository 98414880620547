import React from 'react';

const RulesBlock = ({
    fullWidth = false,
    // id = null, 
    // data = null,
    // noSticky,
    // inputCallback = null,
    spread = false,
    isOpen,
    onClose
}) => {
    return(
        <>
            <div className="overlay_reglement" style={{display: isOpen ? 'block' : 'none'}} onClick={onClose}></div>
            <div className="text_reglement" style={{display: isOpen ? 'block' : 'none'}}>
                <h2>Règlement LEGO / Wishlist de Noël 2020</h2>
                
                <h3>Article 1 : Entité organisatrice du concours </h3>
                <p>La société LEGO®, une société par actions simplifiées inscrite au registre du commerce et des sociétés de Paris (75) sous le numéro B 806 220 216, ayant son siège social au 15-17 rue Vivienne à 75002 Paris, a chargé la société SINFIN, au capital de 135 000 €, immatriculée au RCS de Troyes sous le numéro 502 339 864 d’organiser un jeu concours intitulé “Wishlist de Noël” et qui se déroule entre le 19 octobre au 23 décembre 2020.</p>
                
                <h3>Article 2 : Conditions de participation</h3>
                <p>Ce jeu-concours est gratuit et sans obligation d’achat. La participation au jeu concours organisé par la société LEGO® est limitée aux personnes physiques résidant en France métropolitaine et en Corse et disposant d’une adresse mail et d’une connexion Internet valides. Les membres du personnel de la société LEGO®, qu’ils soient employés normaux ou cadres, y compris leurs ascendants et descendants directs, ainsi que les sous-traitants, fournisseurs et partenaires de la société LEGO® et toutes les personnes ayant coopéré directement ou indirectement à l’élaboration et / ou la mise en oeuvre de ce concours, ne sont pas autorisées à participer au concours.</p>
                <p>La participation au jeu-concours s’effectue en ligne sur <a href="http://legonoelmagique.com/">LEGONoelMagique.com</a>.</p>
                <p>Pour participer au concours de la société LEGO®, les mineurs doivent obtenir l’accord préalable de leur représentant légal, qui assume la responsabilité de la participation.</p>
                <p>La participation au jeu concours de la société LEGO® est soumise à une inscription nominale et une seule participation sera comptabilisée pour le tirage au sort.</p>
                <p>Toute tentative de fraude d’un participant entraînera son exclusion définitive. La participation est strictement nominative et le participant ne peut en aucun cas jouer pour le compte d’autres participants. Seules les participations dûment complétées seront prises en compte. Toute indication inexacte, incomplète ou reçue avant ou après la date limite de participation entraînera l’élimination automatique du participant.</p>
                <p>La société LEGO® peut s’engager à effectuer toutes les vérifications nécessaires pour s’assurer que les informations communiquées par le participant sont correctes, notamment en ce qui concerne son identité et son lieu de résidence. Par le seul fait de participer à ce concours, le participant autorise la société LEGO® sans limitation à effectuer ces vérifications.</p>
                <p>Par le simple fait de participer à ce concours, le participant déclare accepter toutes les dispositions du présent règlement et se conformer à toutes les dispositions légales, réglementaires et éthiques applicables aux jeux et aux loteries de concurrence en France.</p>
                
                <h3>Article 3 : Durée du jeu-concours</h3>
                <p>11 gagnants seront tirés au sort parmi les participants. Pour pouvoir participer au tirage au sort, les participants doivent avoir participé entre le 19 octobre 2020 et 23 décembre à minuit, fuseau horaire français UTC+01:00 (Paris).</p>
                
                <h3>Article 4 : Modalités de participation</h3>
                <p>Le dispositif de ce jeu se fera uniquement en ligne.<br/>
                Pour participer à ce jeu concours, il suffit de se rendre sur <a href="http://legonoelmagique.com/">LEGONoelMagique.com</a> entre le 19 octobre et le 23 décembre 2020.</p>
                <p>Le participant, pour lancer le jeu, devra cliquer sur le bouton « créer ma liste de voeux » (wishlist). En s’aidant du catalogue et des thèmes qu’il préfère dans l’univers LEGO®, il pourra choisir 4 LEGO® qui s’ajouteront à sa wishlist au dessus du catalogue. </p>
                <p>Une fois les 4 LEGO® sélectionnés, pour valider sa liste il devra cliquer sur le bouton du “valider la liste”. Ses 4 LEGO® s’afficheront en bas de page et il devra renseigner son adresse e-mail pour être inscrit au tirage au sort du jeu.</p>
                <p>Une fois son adresse e-mail renseignée, il est redirigé vers une page avec pour texte « BRAVO ! adresseemail@email.com tu es maintenant inscrit au tirage au sort. Nous te souhaitons bonne chance l » et le jeu est finalisé. </p>

                <h3>Article 5 : Lots</h3>
                <p>Chaque participant joue pour tenter de gagner les lots suivants (dans les conditions prévues ci-après) : </p>
                <ul>
                    <li>1 séjour en Laponie * d’une durée de 4 jours / 3 nuits pour 4 personnes (valeur d’environ 5300 €).</li>
                    <li>4 Produits de sa wishlist pour 10 gagnants (valeur en fonction des produits indiqués sur sa wishlist).</li>
                </ul>
                <p><i>* Ce séjour comprend :</i></p>
                <ul>
                    <li><i>Les vols aller / retour Paris Orly Transavia - Rovaniemi (Laponie)  en classe économique avec 1 bagage en soute par personne.</i></li>
                    <li><i>Les transferts privatifs aller / retour aéroports – hôtels – aéroports.</i></li>
                    <li><i>Séjour dans un établissement 4 étoiles.</i></li>
                    <li><i>Les dîners – 3 plats – sauf boissons.</i></li>
                    <li><i>Equipement complet de protection contre le froid durant toute la durée du séjour.</i></li>
                    <li><i>Matériel en libre-service : raquettes, skis de fond, luges...</i></li>
                    <li><i>Photo avec le père Noël offerte pour les moins de 15 ans (pour les départs du 03/01/21 au 18/03/21 et du 03/01/22 au 18/03/22).</i></li>
                    <li><i>Toutes les prestations décrites dans le programme sous réserve de disponibilité et prix à la réservation.</i></li>
                </ul>
                <p><i>Ce séjour ne comprend pas :</i></p>
                <ul className="list_tiret">
                    <li><i>Les prestations non mentionnées au programme ci-dessus.</i></li>
                    <li><i>Le participant fera son affaire personnelle de toutes les obligations administratives et légales nécessitées par le voyage.</i></li>
                </ul>
                <p>L’agence de voyage prendra contact avec le gagnant à l’issu du tirage au sort.</p>
                <p>Le gagnant devra réserver au plus tard le 30 avril 2021 son voyage auprès de l’agence.</p>
                <p>Le voyage devra se dérouler entre le 1er janvier 2021 et le 31 décembre 2022.</p>

                <h3>Article 6 : Lots personnalisés</h3>
                <p>Les lots sont personnalisés et ne peuvent pas être réclamés par un tiers. Si un lot est remporté par un mineur, il doit être réclamé par son / ses représentant(s) légal(aux).</p>
                <p>Le gagnant ne pourra réclamer le lot sous aucune autre forme que celle prévue et décrite dans le présent règlement. </p>
                <p>Les lots ne pourront être attribués sous une autre forme que celle prévue par le présent règlement. Il ne sera attribué aucune contre-valeur en espèce, en échange du lot gagné. En cas de force majeure, la société LEGO® se réserve le droit de modifier le lot ou de proposer d’autres lots de nature et de valeur similaires aux gagnants. En participant à ce concours, le participant reconnaît et accepte sans équivoque que la société LEGO® ne saurait en être tenue responsable.</p>
                
                <h3>Article 7 : Tirage au sort des gagnants et remise des lots</h3>
                <p>11 gagnants seront tirés au sort le jeudi 24 décembre 2020 parmi les participants qui répondent aux critères de participation établis en vertu du présent règlement.</p>
                <p>La société organisatrice prendra contact avec les gagnants via l’adresse mail indiquée lors de la participation des joueurs au jeu pour récupérer leurs coordonnées nécessaires pour l’envoi du lot.</p>
                <p>La société organisatrice est en charge de la distribution des lots. Ils seront envoyés aux gagnants entre le mercredi 20  janvier 2021 et le mardi 20 avril 2021.</p>
                <p>La société LEGO® ne pourra être tenue responsable de tout retard dans la livraison du lot attribué, ni de tout retard, détérioration, destruction partielle ou totale ou toute forme de dysfonctionnement pouvant survenir lors de la livraison du lot attribué.</p>
                
                <h3>Article 8 : Données nominatives et personnelles </h3>
                <p>Dans le cadre du jeu concours, la société organisatrice collecte et traite, pour le compte de la société LEGO®, les coordonnées des participants afin de permettre leurs inscriptions au jeu concours. Les adresses mails des gagnants seront transmises à la société organisatrice par la société LEGO® pour l’acheminement des lots aux gagnants du jeu-concours.</p>
                <p>L’utilisation des données personnelles des participants est fondée sur l’exécution du contrat que les participants ont conclu avec la société organisatrice en acceptant le présent règlement.</p>
                <p>Les données personnelles fournies par les participants dans le cadre du jeu-concours sont destinées à la société organisatrice, ou à des sous-traitants et/ou des prestataires pour des besoins de gestion.</p>
                <p>Conformément à la réglementation en vigueur, les informations collectées sont destinées exclusivement à la société organisatrice et elles ne seront ni vendues, ni cédées à des tiers, de quelque manière que ce soit. Les renseignements communiqués par le participant sont destinés à l'usage de la société organisatrice dans le cadre de l'accès à son service conformément aux conditions générales de vente et dans le cadre de la gestion du présent jeu concours.</p>
                <p>Les données personnelles seront détruites dans un délai de quatre mois suivant la fin du jeu concours. </p>
                <p>La société organisatrice s'engage à ne pas divulguer vos données personnelles à un tiers sans obtenir votre autorisation expresse et préalable.</p>
                <p>La société organisatrice peut être amenée à divulguer des données personnelles aux autorités administratives ou judiciaires lorsque leur divulgation est nécessaire à l'identification, l'interpellation ou la poursuite en justice de tout individu susceptible de porter préjudice aux droits de la société organisatrice, de tout autre utilisateur ou d’un tiers. La société organisatrice peut enfin être légalement tenue de divulguer des données personnelles et ne peut dans ce cas s'y opposer.</p>
                <p>Conformément au Règlement Général sur la Protection des Données personnelles, les participants peuvent exercer leurs droits d’accès, de rectification, d’opposition, d’effacement, de limitation du traitement et de portabilité pour toutes les données les concernant traitées par la société organisatrice pour le compte de la société LEGO® en envoyant un mail à l’adresse suivante: <a href="mailto:support@legonoelmagique.com">support@legonoelmagique.com</a><br/>Les participants peuvent également introduire une réclamation auprès de la CNIL.</p>
                
                <h3>Article 9 : Responsabilité et droits de la société LEGO®</h3>
                <p>Le participant s’engage à accepter et à respecter sans réserve toutes les dispositions du présent règlement.</p>
                <p>Tout manquement à se conformer aux dispositions du présent règlement, toute fraude ou tentative de fraude de la part du participant entraînera automatiquement sa récusation.</p>
                <p>La société LEGO® ne pourra être tenue responsable des dommages causés au participant ou à son équipement informatique au cours de sa participation au jeu LEGO®.</p>
                <p>La responsabilité de la société LEGO® ne pourra être engagée si un participant est empêché de participer au concours organisé par la société LEGO®, quelle que soit la cause de cette immobilité.</p>
                <p>La société LEGO® ne peut être tenue responsable des incidents pouvant résulter de l’utilisation du lot attribué. <br/>
                La société LEGO® dégage toute responsabilité en cas de dysfonctionnement du réseau internet, des lignes téléphoniques, du matériel de réception empêchant le bon déroulement du jeu concours. En outre, sa responsabilité ne pourra en aucun cas être retenue pour des problèmes d’acheminement ou de perte de courrier électronique ou postal.</p>
                <p>La société LEGO® dégage toute responsabilité en cas de défaillances techniques, anomalies matérielles et logiciels de quelque nature (virus, bug…) occasionnés sur le système du participant, sur son équipement informatique et sur les données qui y sont stockées ainsi qu’aux conséquences pouvant en découler sur ses activités personnelles, professionnelles ou commerciales.</p>
                <p>Si les circonstances l’exigent, la société LEGO® peut modifier les conditions générales du concours organisé par la société LEGO® ou annuler celui-ci sans que sa responsabilité ne soit engagée.</p>

                <h3>Article 10 : Dépôt et accès au présent règlement</h3>
                <p>Le présent règlement est déposé en l’étude de Maîtres Jean-Marc GOBET et Christophe CLEMENT située à TROYES (10000), 26 Boulevard Gambetta.</p>
                <p>Il pourra être adressé sur simple demande.</p>
                <p>Les frais d’envoi de cette demande seront remboursés sur la base d’un timbre-poste au tarif lent en vigueur en France.</p>
                <p>Il ne sera adressé qu’un seul remboursement par foyer (même nom, même adresse) et envoi. Sur simple demande écrite aux mêmes adresses que l’article 6, les frais de connexion à internet nécessaire, à la lecture du règlement seront remboursés au tarif local en heure pleine en vigueur (frais de connexion remboursés sur la base de 5 minutes de connexion RTC).</p>
                <p>La demande de remboursement doit être accompagnée d’un justificatif de l’opérateur télécom ou du fournisseur d’accès internet, mentionnant la date, l’heure et la durée de l’appel. Il est entendu qu’il n’y a pas de remboursement dans le cas où la participation est effectuée dans le cadre d’un forfait illimité (ADSL, Câble ou autre…).</p>
                <p>Le remboursement des sommes (timbre ou connexion) se fera par virement bancaire ou chèque au choix par la société organisatrice, après fourniture par le plaignant de tous les renseignements nécessaires à l’opération.</p>
                <p>La société organisatrice se réserve la possibilité de proroger, de suspendre ou d’annuler purement et simplement le jeu-concours pour quelque raison que ce soit, sans que sa responsabilité ni celle de la société LEGO® puisse être engagée.</p>
                <p>La participation à ce jeu-concours implique l’acceptation pleine et entière du présent règlement dans son intégralité.</p>

                <h3>Article 11 : Protection des données personnelles et individuelles </h3>
                <p>Les données collectées au cours du concours seront traitées conformément à la loi n° 78-17 du 6 janvier 1978 relative à l’informatique et aux libertés.</p>
                <p>Les participants au concours et leur(s) représentant(s) légal(aux), si le participant est mineur, ont le droit d’accéder, de rectifier et d'effacer les données qu’ils communiquent en vertu des dispositions de la présente loi.</p>
                
                <h3>Article 12 : Réclamations</h3>
                <p>Toute contestation ou réclamation à ce jeu-concours ne sera prise en compte que si elle est adressée avant le mercredi 20 janvier 2021, le cachet de la poste faisant foi.</p>
                <p>Une assistance support pour ce jeu concours est en place à l’adresse suivante : <a href="mailto:support@legonoelmagique.com">support@legonoelmagique.com</a></p>
                <p>Toute contestation ou réclamation devra être formulée par lettre simple adressée à la société organisatrice dont les coordonnées sont : 1 avenue Louis François Armand - 10430 Rosières-près-Troyes. Cette lettre devra indiquer la date précise de participation au jeu-concours, les coordonnées complètes du participant et le motif exact de la contestation. Aucun autre mode de contestation ou réclamation ne pourra être pris en compte. La société organisatrice sera seule souveraine pour trancher toute question d’application ou d’interprétation du règlement ou en cas de lacunes de celui-ci à l’occasion du déroulement du présent jeu-concours.</p>

                <h3>Article 13 : Loi applicable et tribunal compétent</h3>
                <p>Les participants sont soumis au droit français.</p>
                <p>Le concours et le présent règlement sont également régis par le droit français.</p>
                <p>Les partis s’efforcent de résoudre à l’amiable tout différend né de l’interprétation ou de l’exécution du présent règlement.</p>
                <p>Si le désaccord persiste, il sera soumis aux tribunaux compétents. Le participant reconnaît avoir pris connaissance du présent règlement, de l’accepter sans réserves et de s’y conformer.</p>
            </div>
        </>
    )
}

export default RulesBlock;