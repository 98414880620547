import { gql } from 'apollo-boost';



//----- Récupération des produits
//--- FILTRES exclusion produits variantes : products (exists:{parent:false}){
export const GET_PRODUCTS = gql`
{
  products (first: 50){
    edges{
      node{
        id
        sku
        status
        superAttribute{
          edges{
            node{
              id
              identifier
              attributeOptions{
                edges{
                  node{
                    id
                    identifier
                    translation {
                      id
                      translationDatas {
                        edges {
                          node {
                            value
                            locale {
                              id
                              code
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              translation{
                translationDatas{
                  edges{
                    node{
                      locale{
                        code
                        libelle
                      }
                      value
                    }
                  }
                }
              }
            }
          }
        }
        children{
          edges{
            node{
              id
              productDatas(first: 200) {
                edges {
                  node {
                    value
                    id
                    attributeOption {
                      id
                      identifier
                      translation {
                        translationDatas {
                          edges {
                            node {
                              locale {
                                id
                                code
                              }
                              value
                            }
                          }
                        }
                      }
                    }
                    attribute {
                      id
                      identifier
                      isSystem
                      translation {
                        id
                        translationDatas {
                          edges {
                            node {
                              id
                              value
                              locale {
                                id
                                code
                              }
                            }
                          }
                        }
                      }
                      attributeType {
                        id
                        input
                      }
                    }
                    media {
                      id
                      filePath
                      type
                      size
                      category {
                        edges {
                          node {
                            id
                            libelle
                          }
                        }
                      }
                    }
                    locale {
                      id
                      code
                    }
                  }
                }
              }
            }
          }
        }
        parent{
          id
        }
        attributeGroup {
          id
          identifier
        }
        categories{
          id
          libelle
          categoryDatas{
            edges{
              node{
                value
                attribute{
                  identifier
                }
                media{
                  filePath
                }
                locale{
                  code
                }
              }
            }
          }
          catalog{
            id
            libelle
          }
        }
        productDatas(first: 200){
          edges{
            node{
              id
              value
              attributeOption{
                id
                identifier
              }
              attribute{
                identifier
                isSystem
              }
              locale{
                code
              }
              media{
                id
                filePath
                type
                size
                category{
                  edges{
                    node{
                      id
                      libelle
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const GET_PRODUCTS_CURSOR = gql`
{
  products (first: 10000, exists:{parent:false}){
    edges{
      cursor
    }
  }
}
`;

export const GET_PRODUCTS_PAGINATION = gql`
query products ($nbperpage: Int, $cursor: String, $cursorLast: String, $sku: String, $status: Boolean, $idList: [Int], $idListCategories: [Int]){
  products (first: $nbperpage, after: $cursor, before: $cursorLast, sku: $sku, status: $status, id_list: $idList, exists:{parent:false}){
    totalCount
    pageInfo {
      startCursor
      endCursor
    }
    edges{
      cursor
      node{       
        id
        sku
        status
        parent{
          id
        }
        attributeGroup {
          id
          identifier
        }
        categories(id_list: $idListCategories){
          id
          libelle
          categoryDatas{
            edges{
              node{
                value
                attribute{
                  identifier
                }
                locale{
                  code
                }
              }
            }
          }
          catalog{
            id
            libelle
          }
        }
        productDatas(first: 200){
          edges{
            node{
              id
              value
              attribute{
                identifier
                isSystem
              }
              locale{
                code
              }
              media{
                id
                filePath
                type
                size
              }
            }
          }
        }
      }
    }
    totalCount
  }
}
`;

export const GET_PRODUCTS_TOTAL = gql`
{
  products{
    totalCount
  }
}
`;

export const SEARCH_PRODUCTS = gql`
query productDataSearch ($attribute: String, $needle: String) {
  productDatas(attribute: $attribute, value: $needle) {
    edges {
      node {
        id
        value
        product {
          id
          sku
          status
          superAttribute{
            edges{
              node{
                id
                identifier
                attributeOptions{
                  edges{
                    node{
                      id
                      identifier
                      translation {
                        id
                        translationDatas {
                          edges {
                            node {
                              value
                              locale {
                                id
                                code
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                translation{
                  translationDatas{
                    edges{
                      node{
                        locale{
                          code
                          libelle
                        }
                        value
                      }
                    }
                  }
                }
              }
            }
          }
          children{
            edges{
              node{
                id
                productDatas(first: 200) {
                  edges {
                    node {
                      value
                      id
                      attributeOption {
                        id
                        identifier
                        translation {
                          translationDatas {
                            edges {
                              node {
                                locale {
                                  id
                                  code
                                }
                                value
                              }
                            }
                          }
                        }
                      }
                      attribute {
                        id
                        identifier
                        isSystem
                        translation {
                          id
                          translationDatas {
                            edges {
                              node {
                                id
                                value
                                locale {
                                  id
                                  code
                                }
                              }
                            }
                          }
                        }
                        attributeType {
                          id
                          input
                        }
                      }
                      media {
                        id
                        filePath
                        type
                        size
                        category {
                          edges {
                            node {
                              id
                              libelle
                            }
                          }
                        }
                      }
                      locale {
                        id
                        code
                      }
                    }
                  }
                }
              }
            }
          }
          parent{
            id
          }
          categories {
            id
            libelle
            categoryDatas {
              edges {
                node {
                  value
                  attribute {
                    identifier
                  }
                  media {
                    filePath
                  }
                  locale {
                    code
                  }
                }
              }
            }
            catalog {
              id
              libelle
            }
          }
          productDatas(first: 200) {
            edges {
              node {
                value
                id
                attributeOption {
                  id
                  identifier
                  translation {
                    translationDatas {
                      edges {
                        node {
                          locale {
                            id
                            code
                          }
                          value
                        }
                      }
                    }
                  }
                }
                attribute {
                  id
                  identifier
                  isSystem
                  translation {
                    id
                    translationDatas {
                      edges {
                        node {
                          id
                          value
                          locale {
                            id
                            code
                          }
                        }
                      }
                    }
                  }
                  attributeType {
                    id
                    input
                  }
                }
                media {
                  id
                  filePath
                  type
                  size
                  category {
                    edges {
                      node {
                        id
                        libelle
                      }
                    }
                  }
                }
                locale {
                  id
                  code
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const SEARCH_PRODUCTS_MIN = gql`
query productDataSearch ($attribute: String, $needle: String,$first:Int) {
  productDatas(first: $first, attribute: $attribute, value: $needle) {
    edges {
      node {
        product {
          id
          sku
          productDatas(first: 200) {
            edges {
              node {
                value
                id
                attribute {
                  id
                  identifier
                  isSystem
                  attributeType {
                    id
                    input
                  }
                }
                media {
                  filePath
                }
                locale {
                  id
                  code
                }
              }
            }
          }

        }
      }
    }
  }
}`;

export const SEARCH_PRODUCTS_SKU = gql`
query productDataSearch ($attribute: String, $needle: String) {
  productDatas(first: 1000, attribute: $attribute, value: $needle) {
    edges {
      node {
        product {
          id
          sku
        }
      }
    }
  }
}`;

export const SEARCH_PRODUCTS_SKU_BY_CAT = gql`
query categories($idlist: [Int]){
  categories(id_list: $idlist) {
    id
    products {
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
      edges{
        cursor
        node{
          id
          sku
        }
      }
    }
  }
}
`;

export const PRODUCTS_BY_CAT_PAGINATION = gql`
  query categories($idlist: [Int], $nbperpage: Int, $cursor: String, $cursorLast: String){
    categories(id_list: $idlist) {
      id
      libelle
      products(first: $nbperpage, after: $cursor, before: $cursorLast) {
        totalCount
        pageInfo {
          startCursor
          endCursor
        }
        edges{
          cursor
          node{
            id
            sku
            productDatas(first: 200){
              edges{
                node{
                  id
                  value
                  attribute{
                    identifier
                    isSystem
                  }
                  locale{
                    code
                  }
                  media{
                    id
                    filePath
                    type
                    size
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const PRODUCTS_BY_CATALOGS_PAGINATION = gql`
query products($id: ID!, $nbperpage: Int, $cursor: String, $cursorLast: String){
  products(first: $nbperpage, after: $cursor, before: $cursorLast) {
    totalCount
    pageInfo {
      startCursor
      endCursor
    }
    edges{
      cursor
      node{
        id
        sku
        categories{
          id
          libelle
          categoryDatas{
            edges{
              node{
                value
                attribute{
                  identifier
                }
                locale{
                  code
                }
              }
            }
          }
          catalog(id:$id){
            id
            libelle
          }
        }
        productDatas(first: 200){
          edges{
            node{
              id
              value
              attribute{
                identifier
                isSystem
              }
              locale{
                code
              }
              media{
                id
                filePath
                type
                size
              }
            }
          }
        }
      }
    }
  }
}
`;


export const PRODUCTS_BY_CATALOGS_CAT_PAGINATION = gql`
query catalogs($id: ID!, $idlist: [Int], $nbperpage: Int, $cursor: String, $cursorLast: String){
  catalog(id: $id){
    categories(id_list: $idlist){
      id
      products(first: $nbperpage, after: $cursor, before: $cursorLast) {
        totalCount
        pageInfo {
          startCursor
          endCursor
        }
        edges{
          cursor
          node{
            id
            sku
            productDatas(first: 200){
              edges{
                node{
                  id
                  value
                  attribute{
                    identifier
                    isSystem
                  }
                  locale{
                    code
                  }
                  media{
                    id
                    filePath
                    type
                    size
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

//----- Récupération des produits pour le sélecteur
export const GET_SELECT_PRODUCTS = gql`
{
  products{
    edges{
      node{
        id
        sku
        categories{
          id
          libelle
          categoryDatas{
            edges{
              node{
                value
                attribute{
                  identifier
                }
                media{
                  filePath
                }
                locale{
                  code
                }
              }
            }
          }
          catalog{
            id
            libelle
          }
        }
        productDatas{
          edges{
            node{
              value
              attribute{
                identifier
              }
              media{
                filePath
              }
              locale{
                code
              }
            }
          }
        }
      }
    }
  }
}
`;

//----- Récupération des données d'un produit
/*
  Exemple de variables
  {
    "id" : "/api/products/1"
  }
*/
export const GET_PRODUCT_DATA = gql`
query productDatas($id: ID!) {
  product(id: $id) {
    id
    sku
    status
    superAttribute{
      edges{
        node{
          id
          identifier
          attributeType {
            id
            input
          }
          attributeOptions{
            edges{
              node{
                id
                identifier
                translation {
                  id
                  translationDatas {
                    edges {
                      node {
                        value
                        locale {
                          id
                          code
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          translation{
            translationDatas{
              edges{
                node{
                  locale{
                    code
                    libelle
                  }
                  value
                }
              }
            }
          }
        }
      }
    }
    children{
      edges{
        node{
          id
          productDatas(first: 200) {
            edges {
              node {
                value
                id
                attributeOption {
                  id
                  identifier
                  translation {
                    translationDatas {
                      edges {
                        node {
                          locale {
                            id
                            code
                          }
                          value
                        }
                      }
                    }
                  }
                }
                attribute {
                  id
                  identifier
                  isSystem
                  translation {
                    id
                    translationDatas {
                      edges {
                        node {
                          id
                          value
                          locale {
                            id
                            code
                          }
                        }
                      }
                    }
                  }
                  attributeType {
                    id
                    input
                  }
                }
                media {
                  id
                  filePath
                  type
                  size
                  name
                  category {
                    edges {
                      node {
                        id
                        libelle
                      }
                    }
                  }
                }
                locale {
                  id
                  code
                }
              }
            }
          }
        }
      }
    }
    parent{
      id
    }
    attributeGroup{
      id
      identifier
      translation {
        id
        translationKey
        translationDatas {
          edges {
            node {
              id
              value
              locale {
                id
                code
              }
            }
          }
        }
      }
    }
    categories{
      id
      libelle
      categoryDatas{
        edges{
          node{
            id
            value
            attribute{
              id
              identifier
              attributeType {
                id
                input
              }
            }
            locale{
              id
              code
            }
          }
        }
      }
      catalog{
        id
        libelle
      }
    }
    productDatas(first: 200){
      edges{
        node{
          value
          id
          attributeOption{
            id
            identifier
            translation{
              translationDatas{
                edges{
                  node{
                    locale{
                      id
                      code
                    }
                    value
                  }
                }
              }
            }
          }
          attribute{
            id
            identifier
            isSystem
            translation{
              id
              translationDatas{
                edges{
                  node{
                    id
                    value
                    locale{
                      id
                      code
                    }
                  }
                }
              }
            }
            attributeType {
              id
              input
            }
          }
          media{
            id
            filePath
            type
            size
            name
            category{
              edges{
                node{
                  id
                  libelle
                }
              }
            }
          }
          locale{
            id
            code
          }
        }
      }
    }
  }
}
`;

//----- Ajouter un produit
/*
Exemple de variables
  {
    "sku" : "Produit de démo",
    "attributeGroup" : "/api/product_attribute_groups/1",
    "categories": [
      "/api/categories/5",
      "/api/categories/6"
    ]
  }
*/
export const ADD_PRODUCT = gql`
mutation AddProduct($sku: String!, $attributeGroup: String!,  $categories: [String], $createdAt: String!, $updatedAt: String!, $status: Boolean!, $children:[String], $superAttribute: [String]){
	createProduct(input: {sku:$sku, attributeGroup:$attributeGroup, categories:$categories, createdAt:$createdAt, updatedAt:$updatedAt, status:$status, children:$children, superAttribute:$superAttribute}){
    product{
      id
      sku
      attributeGroup{
        id
      }
      categories {
        id
      }
    }
  }  
}`;
  
//----- Modifier un produit
/*
Exemple de variables
  {
    "id" : "/api/products/14",
    "attributeGroup" : "/api/product_attribute_groups/1",
    "categories": [
      "/api/categories/5",
      "/api/categories/6",
      "/api/categories/11"
    ]
  }
*/
export const UPDATE_PRODUCT = gql`
mutation UpdateProduct($id: ID!, $sku: String, $attributeGroup: String,  $categories: [String], $status: Boolean, $children:[String], $superAttribute: [String]){
	updateProduct(input: {id: $id, sku:$sku, attributeGroup:$attributeGroup, categories:$categories, status:$status, children:$children, superAttribute:$superAttribute}){
    product{
      id
      sku
      attributeGroup{
        id
      }
      categories {
        id
      }
    }
  }  
}`;

//----- Supprimer un produit
/*
Exemple de variables
  {
    "id" : "/api/products/1"
  }
*/
export const DELETE_PRODUCT = gql`
mutation DeleteProduct($id: ID!){
	deleteProduct(input: {id:$id}){
    product{
      id
    }
  }  
}`;


//--------------------
//----- DONNEES PRODUITS 
//--------------------

//----- Ajouter une valeur d'attribut à un produit

/*
 Exemple de variables
 {
  "value": "TEST",
	"product": "/api/products/1",
  "attribute": "/api/attributes/12", 
	"locale": "/api/locales/2"
}
*/
export const ADD_PRODUCT_DATA = gql`
mutation AddProductData($value: String, $product: String!, $attribute: String!, $locale: String, $attributeOption: String, $media: String){
	createProductData(input: {value:$value, product:$product, attribute:$attribute, locale:$locale, attributeOption:$attributeOption, media:$media}){
    productData{
      id
      value
      product{
        id
      }
      attribute{
        id
      }
      locale{
        id
      }
      media{
        id
      }
      attributeOption{
        id
      }
    }
  }  
}`;


//----- Modifier une valeur d'attribut d'un produit
/*
 Exemple de variables
 {
	"id": "/api/product-datas/68",
  "value": "TEST edition",
	"product": "/api/products/1",
  "attribute": "/api/attributes/12", 
	"locale": "/api/locales/2"
}
*/
export const UPDATE_PRODUCT_DATA = gql`
mutation UpdateProductData($id: ID!, $value: String, $product: String!, $attribute: String!, $locale: String, $attributeOption: String, $media: String){
	updateProductData(input: {id:$id, value:$value, product:$product, attribute:$attribute, locale:$locale, attributeOption:$attributeOption, media:$media}){
    productData{
      id
      value
      product{
        id
      }
      attribute{
        id
      }
      locale{
        id
      }
      media{
        id
      }
      attributeOption{
        id
      }
    }
  }  
}`;

//----- Supprimer une valeur d'attribut d'un produit
/*
 Exemple de variables
  {
    "id" : "/api/product-datas/68"
  }
*/
export const DELETE_PRODUCT_DATA = gql`
mutation DeleteProductData($id: ID!){
	deleteProductData(input: {id:$id}){
    productData{
      id
    }
  }  
}`;

//----- Récup un ou plusieurs produit depuis son sku ( sku exact )
/*
 Exemple de variables
 {
	"sku": "Couch" //-> SI 1 produit
	//"skuList": ["Couch","Water"] //--> Si plusieurs produits ===> N'EST PLUS DISPO car passage de EXACT a PARTIAL
}
*/
export const GET_PRODUCTS_BY_SKU = gql`
query products($sku: String){
  products(sku: $sku) {
    edges{
      node{
        id
        sku
        status
        attributeGroup {
          id
          identifier
        }
        productDatas(first: 200){
          edges{
            node{
              id
              value
              attributeOption{
                id
                identifier
              }
              attribute{
                identifier
                isSystem
              }
              locale{
                code
              }
              media{
                id
                filePath
                type
                size
                category{
                  edges{
                    node{
                      id
                      libelle
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;