import React from 'react';
import { Box, Grid, Typography,Tooltip } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '../../../ui/button/Button';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import NoImage from '../../../../assets/images/not-found.png';
import iconSet from '../../../../assets/selection.json';
import IcomoonReact from "icomoon-react";
import { getTraductionAttributs } from '../../../../js/utils/functions';
import { ROUTE_CRM_CONTACTS_SOCIETIES_DETAILS,ROUTE_CRM_CONTACTS_DETAILS } from '../../../../js/constants/route-names';
import { withRouter } from 'react-router';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

const CheckboxCustom = styled(Checkbox)`
    margin-top: -20px;
    svg{
        path{
            color: rgb(110, 174, 209);
        }
    }
`
const ContainerCardCRM = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    position : relative;
    ${props => props.selectable ?
    `cursor : pointer;`
    :``}
`
const ButtonCustom = styled(Button)`
    margin: 0;
    text-transform: unset;
    font-weight: bold;
`
const ContainerButton = styled(Box)`
    display: flex;
    justify-content: flex-end;
`
const ProfilePicture = styled(Box)`
    border-radius: 50%;
    overflow: hidden;
    background: url(${props => props.backgroundimage});
    background-size: cover;
    background-position: center;
    background-repeat : no-repeat;
    height: 120px;
    width: 120px;
`
const CompanyPicture = styled(Box)`
    border-radius: 50%;
    overflow: hidden;
    background: url(${props => props.backgroundimage});
    background-size: cover;
    background-position: center;
    background-repeat : no-repeat;
    height: 35px;
    width: 35px;
    margin-right :15px;
`
const ContactStatus = styled(Box)`
    height: 35px;
    width: 35px;
    border-radius: 50%;
    background-color: #6eaed2;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
`
const CompaniesContainer = styled(Grid)`
    margin-top:15px
`
const CompanyItem = styled(Grid)`
    border-bottom : 1px solid ${colors.grey.lighter.hue900};
    padding : 15px 0px;
    cursor:pointer;
    padding-left:0px;
    transition : all .2s;
    ${props => !props.selectable ? 
    `
        &:hover{
            background-color : ${colors.grey.lighter.hue980};
            padding-left:2px;
        }        
    ` 
    :``}
`
const StatusContainer = styled.div`
    position :absolute;
    bottom:8px
`
const NbCompanies = styled(Typography)`
    width: 32px;
    height: 32px;
    margin-right: 15px;
    border-radius: 50%;
    background-color: #FAFBFB;
    text-align:center;
`

const ContainerCard = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`

const Title = styled(Typography)`
    font-weight: bold;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`

const TableCellCustom = styled(TableCell)`
    border: none;
    display: inline-block;
    width: calc(100% / 9);
    padding: 3px 16px;
`

const NoImageWrapper = styled(Box)`
    background: ${colors.grey.regular};
    color: white;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    text-align: center;
    font-size: 18px;
    line-height: 34px;
    display: inline-block;
    cursor: pointer;
    text-transform: uppercase;
    vertical-align: middle;
`;

function CardContact(props){
    const contact           = props.contact;
    const currentCompany    = props.currentCompany;

    const checkParent = (contact,currentCompany)=>{
        if(currentCompany){
            let isActive = null;
            if (props.isForm){
                isActive = contact.node.companies.edges.filter(company => company.node.id === currentCompany.id).length>0;
            }else{
                isActive = contact.node.companies.edges.filter(company => company.node.id === currentCompany.node.id).length>0;
            }
            return isActive;
        }
        else{
            return false
        }
    }

    const icons = {
        client: {
            icon:"icon-clients",
            libelle : "Client"
        },
        lead:{
            icon :"icon-leads" ,
            libelle : 'Lead'
        },
        contact:{
            icon:"icon-contacts",
            libelle : "Contact"
        } 
    };
    

    let isActive = checkParent(contact,currentCompany);
    
    const [checked,setChecked] = React.useState(isActive);

    const attributes = contact.node?.customerDatas?.edges;

    let firstname = getTraductionAttributs('customer_firstname',attributes,props.currentLang);
    let lastname = getTraductionAttributs('customer_lastname',attributes,props.currentLang);
    let phone = getTraductionAttributs('customer_phone',attributes,props.currentLang);
    let email = contact.node.sku;

    let image = getTraductionAttributs('customer_image',attributes,props.currentLang,'image');

    const goTo = (route, id, target) => {
        let variables = {};
        variables[target] = id;
        props.history.push({
            pathname : route,
            state: variables
        });
    };
    const handleCheckbox=(checked,contact,isActive)=>{
        props.handleSelect(!checked,contact,isActive);
        setChecked(!checked)
    }
    return(
        props.isCard ?
            <ContainerCardCRM pt={2} pb={1} onClick={props.select ? ()=>handleCheckbox(checked,contact,isActive):null} selectable={props.select}>
                <Grid container alignItems={"center"} style={{minHeight:180}}>
                    <Grid item xs={props.type === "dashboard" ? 5 : 6}>
                        {
                            props.select ? <CheckboxCustom checked={checked} /> : null
                        }
                        <ProfilePicture style={{margin:'auto'}} backgroundimage={image ? `${process.env.REACT_APP_MEDIAS}/${image.filePath}` : NoImage } />
                    </Grid>
                    <Grid item xs={props.type === "dashboard" ? 7 : 6} style={{wordBreak:"break-word"}}>
                        <Typography variant="h3" style={{fontWeight:'bold',color:'#6eaed2',paddingBottom:8}}>{firstname} {lastname}</Typography>
                        <Typography>
                            <a href={"tel:" + phone} style={{color:"#808F98"}}>{phone}</a>
                        </Typography>
                        <Typography style={{paddingTop:8}} >
                            <a href={`mailto:${email}`} style={{color:"#808F98"}}>{email}</a>
                        </Typography>

                        {contact.node.companies.edges.length > 0 ? 
                            <CompaniesContainer container>
                                <Typography variant="h4" style={{fontWeight:'bold',marginBottom:5}}>{contact.node.companies.edges.length > 1 ? 'Sociétés' : 'Société'}</Typography>
                                
                                {contact.node.companies.edges.slice(-1).map((company)=>{
                                
                                    const companyName = getTraductionAttributs('company_name',company.node.companyDatas.edges,props.currentLang);
                                    const imageCompany = getTraductionAttributs('company_image', company.node.companyDatas.edges, props.currentLang, 'image');

                                    return(
                                        <CompanyItem container alignItems="center" onClick={()=>!props.select ? goTo(ROUTE_CRM_CONTACTS_SOCIETIES_DETAILS.replace(':id', company.node.libelle), company.node.id,'idCompany'):null} selectable={props.select}>
                                            <CompanyPicture backgroundimage={imageCompany ? `${process.env.REACT_APP_MEDIAS}/${imageCompany.filePath}` : NoImage}/>
                                            <Typography style={{fontWeight: 'bold', width: 'calc(100% - 50px)', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{companyName}</Typography>
                                        </CompanyItem>
                                        
                                    )
                                })} 
                                {contact.node.companies.edges.length > 3 ?
                                    (
                                        <CompanyItem container 
                                            style={{width:'100%',alignItems:'center'}}
                                            onClick={() => goTo(ROUTE_CRM_CONTACTS_DETAILS.replace(':id', firstname || lastname ? `${firstname}/${lastname ? lastname : ''}`:contact.node.id), contact.node.id, 'contactId')}
                                            selectable={props.select}>
                                            <NbCompanies variant="h4">+{contact.node.companies.edges.length - 1}</NbCompanies>
                                            <Typography style={{fontWeight:'weight', width: 'calc(100% - 50px)', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>{contact.node.companies.edges.length - 2 < 2 ? 'Société' : 'Sociétés'}</Typography>
                                        </CompanyItem>
                                    ) 
                                : 
                                    null
                                }
                            </CompaniesContainer>
                        :
                            null
                        }

                    </Grid>

                </Grid>
                <ContainerButton pt={3} style={{justifyContent: "flex-end"}}>
                    <ButtonCustom disableElevation={true} text="Voir la fiche contact" arrow="right" bgColor={colors.green.regular}  onClick={() => goTo(ROUTE_CRM_CONTACTS_DETAILS.replace(':id', firstname || lastname ? `${firstname}/${lastname ? lastname : ''}`:contact.node.id), contact.node.id, 'contactId')} />
                </ContainerButton>
                {contact.node.status ? (
                    <StatusContainer>
                        <Tooltip title={icons[contact.node.status].libelle} arrow>
                            <ContactStatus>
                                    <IcomoonReact iconSet={iconSet} size={20} icon={icons[contact.node.status].icon} />
                            </ContactStatus>                        
                        </Tooltip>    
                    </StatusContainer>
                ) : null }
            </ContainerCardCRM>
        :
            <ContainerCard>
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableBody>
                            <TableRow key={'rowProduct'}>
                                <TableCellCustom component="th" scope="row" align="center" style={{width: 'calc((100%/9) * 1.5)'}}>
                                    {
                                        image && image !== '' ?  (
                                            <img src={`${process.env.REACT_APP_MEDIAS}/${image}`} alt={`${firstname} ${lastname}`} style={{maxHeight: 40}}/>
                                        ) : 
                                        (
                                            <img src={NoImage} style={{maxHeight: 40}} alt={`${firstname} ${lastname}`}/>
                                        )
                                    }
                                </TableCellCustom>
                                <TableCellCustom style={{width: 'calc((100%/9) * 1.5)'}}>
                                    <Title variant="body1">{firstname} {lastname ? lastname : 'N/A'}</Title>
                                </TableCellCustom>
                                <TableCellCustom>
                                    <Typography variant="body1">{phone ? <a href={`tel:${phone}`} style={{color: colors.grey.regular}}>{phone}</a> : null}</Typography>
                                </TableCellCustom>
                                <TableCellCustom align="left" style={{width: 'calc((100%/9) * 1.5)'}}>
                                    <Typography variant="body1">{email ? <a href={`mailto:${email}`} style={{color: colors.grey.regular}}>{email ? email : 'N/A'}</a> : null}</Typography>
                                </TableCellCustom>
                                <TableCellCustom align="center" style={{width: 'calc((100%/9) * 2)'}}>
                                    {contact.node.companies.edges.slice(-2).map((company)=>{
                                
                                        const companyName = getTraductionAttributs('company_name',company.node.companyDatas.edges,props.currentLang);
                                        // const imageCompany = getTraductionAttributs('company_image', company.node.companyDatas.edges, props.currentLang, 'image');

                                        return(
                                            <Tooltip title={companyName ? companyName : 'N/A'} arrow>
                                                <NoImageWrapper onClick={()=>!props.select ? goTo(ROUTE_CRM_CONTACTS_SOCIETIES_DETAILS.replace(':id', company.node.libelle), company.node.id,'idCompany'):null} style={{marginRight: 8}}>{companyName ? companyName.slice(0,1) : ''}</NoImageWrapper>
                                            </Tooltip>
                                            
                                        )
                                    })}
                                </TableCellCustom>
                                <TableCellCustom align="right" style={{width: 'calc((100%/9) * 1.5)'}}>
                                    <ButtonCustom disableElevation={true} text="Voir la fiche contact" arrow="right" bgColor={colors.green.regular}  onClick={() => goTo(ROUTE_CRM_CONTACTS_DETAILS.replace(':id', firstname || lastname ? `${firstname}/${lastname ? lastname : ''}`:contact.node.id), contact.node.id, 'contactId')} />
                                </TableCellCustom>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </ContainerCard>
    );
}
export default withRouter(CardContact);
