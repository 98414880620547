import { gql } from 'apollo-boost';

//----- Récupération des currencies
export const GET_CURRENCIES = gql`
{
  currencies{
    edges{
      node{
        id
        code
        libelle
      }
    }
  }
}
`;

//----- Ajouter une currency
/*
 Exemple de variables
  {
    "code" : "DEMO,
    "libelle" : "Libelle de demo"
  }
*/
export const ADD_CURRENCY = gql`
mutation AddCurrency($code: String!, $libelle: String!){
	createCurrency(input: {code:$code, libelle:$libelle}){
    currency{
      id
      code
      libelle
    }
  }
}`;

//----- Modifier une currency
/*
 Exemple de variables
  {
    "id" : "/api/currencies/1",
    "code" : "DEMO,
    "libelle" : "Libelle de demo edited"
  }
*/
export const UPDATE_CURRENCY = gql`
mutation UpdateCurrency($id: ID!,$code: String, $libelle: String){
	updateCurrency(input: {id:$id, code:$code, libelle:$libelle}){
    currency{
      id
      code
      libelle
    }
  }  
}`;

//----- Supprimer une currency
/*
 Exemple de variables
  {
    "id" : "/api/currencies/1"
  }
*/
export const DELETE_CURRENCY = gql`
  mutation deleteCurrency($id: ID!){
    deleteCurrency(input: {id:$id}){
      currency{
        id
      }
    }  
  }`;
