import { v4 as uuidv4 } from "uuid";
import { FormInputTypes, EditableTypes, ItemTypes } from '../../../shareable/types';
import { getDefaultText, getImageDefault, getDefaultSocial } from '../../helpers';

export default {
    name: 'Section gagné',
    inputs:{
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#DEEDF5'
        },
        backgroundImage: {
            label: 'Image de fond',
            type: FormInputTypes.IMAGE,
            value: null
        }
    },
    blocks: {
        middleBox:{
            id: uuidv4(),
            name: "Bloc principal",
            type: EditableTypes.MINI_BLOCKS,
            inputs:{
                backgroundColor: {
                    label: 'Couleur de fond',
                    type: FormInputTypes.COLOR,
                    value: '#FFFFFF'
                },
                backgroundImage: {
                    label: 'Image de fond',
                    type: FormInputTypes.IMAGE,
                    value: null
                }
            },
            blocks: {
                imageDotation: getImageDefault(),
                text: getDefaultText("Texte", "Bravo ! ", 40, '#53839D', null, 'uppercase', 'center'),
                subText: getDefaultText("Texte", "Tu viens de gagner :", 26, '#53839D', null, 'uppercase', 'center'),
                nameDotation: getDefaultText("Nom de la dotation", "Nom de la dotation", 26, '#53839D', null, 'uppercase', 'center'),
                text2: getDefaultText("Texte", "Tu seras contacté par mail dans un délai d’un mois pour récupérer ton cadeau !", 16, '#53839D', null, 'initial', 'center'),
                reglement: getDefaultText("Lien réglement", "Plus d'informations dans le règlement du jeu", 14, '#53839D'),        
            }
        },
        socialBlock:{
            id: uuidv4(),
            name: "Bloc réseaux sociaux",
            type: EditableTypes.MINI_BLOCKS,
            inputs:{
                backgroundColor: {
                    label: 'Couleur de fond',
                    type: FormInputTypes.COLOR,
                    value: '#fffff'
                },
                backgroundImage: {
                    label: 'Image de fond',
                    type: FormInputTypes.IMAGE,
                    value: null
                },
                position: {
                    type: FormInputTypes.SELECT,
                    label: "Position du texte",
                    value: 'inline',
                    params: [{label: 'En ligne', value: 'inline'}, {label: 'Au dessus', value: 'top'}, {label: 'Au dessous', value: 'bottom'}]
                }
            },
            blocks: {
                shareText: getDefaultText("Texte de partage", "Texte de partage", 20, '#53839D', 'rgba(0,0,0,0)'),
                social:{
                    title: "Réseau social",
                    name: "Réseaux sociaux",
                    type: EditableTypes.MULTIPLE_BLOCKS,
                    subtype: ItemTypes.SOCIAL,
                    limit: 3,
                    config: [
                        getDefaultSocial(),
                        getDefaultSocial(),
                    ]
                },
            }
        }
    }
};
