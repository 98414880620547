import React, { useState } from 'react';
import ShopTopbar from '../../wrappers/product/ShopTopbar';
import ShopProducts from '../../wrappers/product/ShopProducts';
import Paginator from 'react-hooks-paginator';

export default ({  
    preview = false,  
    id = null, 
    data = null,
    params = null,
    inputCallback = null,
    catalog = null,
    categories = [],
    products = [],
    spread = false,
    children = null
}) => {
    const [layout, setLayout] = useState('grid three-column');
    const [filterSortType, setFilterSortType] = useState('');
    const [filterSortValue, setFilterSortValue] = useState('');
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const pageLimit = 6;

    const getLayout = (layout) => {
        setLayout(layout)
    };

    const getFilterSortParams = (sortType, sortValue) => {
        setFilterSortType(sortType);
        setFilterSortValue(sortValue);
    };

    let filteredProducts    = [];
    let newOffset           = currentPage === 1 ? 0 : (pageLimit * (currentPage - 1));
    filteredProducts        = products.slice(newOffset, newOffset + pageLimit);

    return (
        <div style={{
            width: '100%',
            marginBottom: 20,
            backgroundColor: data.inputs['backgroundColor'].value,
        }}>
            <div className="builder-template-scope shop-area">
                { !preview && children }

                <div className="container pt-40 pb-20">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* shop topbar default */}
                            <ShopTopbar 
                                getLayout={getLayout} 
                                getFilterSortParams={getFilterSortParams} 
                                productCount={products.length} 
                                sortedProductCount={filteredProducts.length} 
                                data={data}
                            />

                            {/* shop page content default */}
                            <ShopProducts layout={layout} products={filteredProducts} spread={spread} data={data} params={params} />

                            {/* shop product pagination */}
                            <div className="pro-pagination-style text-center mt-30">
                                <Paginator
                                    totalRecords={products.length}
                                    pageLimit={pageLimit}
                                    pageNeighbours={2}
                                    setOffset={setOffset}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    pageContainerClass="mb-0 mt-0"
                                    pagePrevText="«"
                                    pageNextText="»"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};