import React from 'react';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { withApollo } from 'react-apollo';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import CardCustom from '../../../layouts/Card/CardCustom';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import formAddProject from './config/addProject.config';
import Button from '../../../ui/button/Button';
import Typography from '../../../ui/typography/Typography';
import { Grid, Box } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import NoImage from '../../../../assets/images/not-found.png';
import { ROUTE_CRM_CONTACTS_SOCIETIES_DETAILS, ROUTE_HOME } from '../../../../js/constants/route-names';
import { eventService } from '../../../../js/services/event.service';
import colors from '../../../../config/theme/colors';
import EmptyProduct from '../../../../assets/pictos/empty-picto/empty_products.png';
import EmptyCard from "../../../ui/empty-card/EmptyCard";
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import { SEARCH_COMPANIES, GET_COMPANIES_CURSOR, GET_COMPANIES_PAGINATION} from '../../../../queries/companies';
import * as moment from "moment";
import { GET_PROJECTS_PAGINATION, GET_PROJECTS_CURSOR, ADD_PROJECT, UPDATE_PROJECT, DELETE_PROJECT } from '../../../../queries/crm_projects';
import styled from 'styled-components';
import Pagination from '@material-ui/lab/Pagination';
import TablePagination from '../../../ui/pagination/TablePaginationCustom';
import { hasRights } from '../../../../js/utils/rights';
import { CRM_OFFERS, CRM_OFFERS_PROJECTS, CREATE, UPDATE, DELETE, VIEW} from '../../../../js/constants/constant-rights';


const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 1400px){
        padding: 16px 8px 8px!important;
    }
`;

const CompanyPicture = styled(Box)`
    border-radius: 50%;
    overflow: hidden;
    background: url(${props => props.backgroundimage});
    background-size: cover;
    background-position: center;
    background-repeat : no-repeat;
    height: 35px;
    width: 35px;
    margin-right :15px;
`
const CompanyItem = styled(Grid)`
    border-bottom : 1px solid ${colors.grey.lighter.hue900};
    padding : 15px 0px;
    cursor:pointer;
    padding-left:0px;
    transition : all .2s;
    ${props => !props.selectable ? 
    `
        &:hover{
            background-color : ${colors.grey.lighter.hue980};
            padding-left:2px;
        }        
    ` 
    :``}
`
const PaginationCustom = styled(Pagination)`
    ul{
        justify-content: center; 
        margin-top: 24px;
    }
`;

class ListProjects extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            currentLang: props.locales[0].node.code,
            groupAttribut: '',
            customAttributes: [],
            metaAttributes: [],
            imageAttributes: [],
            imagesSelected: [],
            attributes : [],
            maxImageNumber : 0,
            imageSrc: [],
            // productId: this.props.location.state.productId,
            sku: null,
            title: '',
            categories: [],
            categoriesData: [],
            categorieReady: false,
            description:'',
            status:'',
            metaTitle: '',
            metaDesc: '',
            openForm: false,
            openDialog: false,
            allGroups: [],
            errors: {},
            secondErrors: {},
            seeErrors: false,
            projects: [],
            imageProject: null,
            formSettings:{
                page:1,
                count:1,
            },
            companies:{
                current : [],
                toAdd:  [],
                toRemove : [],
            },
            nbperPageCompanies: 6,
            nbperpageProject: 6,
            cursor: false,
            pageProject: 0,
            countPageProject: 0,
            cursorProject: false,
        }
    }

    componentDidMount() {
        const getRights = hasRights(CRM_OFFERS, CRM_OFFERS_PROJECTS, VIEW)
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }else{
            this.initProject();
        }
    }

    addProject = () => {
        this.changePage(null, 1, null)
        this.setState({
            formType: 'add',
            name: null,
            description: null,
            imageProject: null,
            companies:{
                current : [],
                toAdd:  [],
                toRemove : [],
            },
        }, () => this.handleToggleDrawer())
    }

    editProject = (data) => {
        let companies = this.state.companies
        companies.toAdd = data.company.id
        this.changePage(null, 1, null)
        this.setState({
            formType: 'edit',
            idProject: data.id,
            name: data.name,
            description: data.description,
            companies,
            imageProject: data.media,
        }, () => this.handleToggleDrawer())
    }

    handleToggleDrawer = (drawer) => {
        this.setState({ 
            openForm : !this.state.openForm,
        });
        this.setState({ 
            seeErrors: false
        });
    };

    handleToggleDialog = () => {
        this.setState({ 
            openDialog : !this.state.openDialog
        });
    };

    handleLang = (event) => {
        this.setState({ currentLang: event.target.value });
        this.forceUpdate();
    };

    handleGetCompanies = async()=>{
        let variables = {
            nbperpage : this.state.nbperPageCompanies
        }
        if(this.state.cursor && this.state.listCursors !== this.state.listCursors[0]?.cursor){
            variables.cursor = this.state.cursor;
        }
        if(this.state.activeSearchCompanies){
            variables.id_list = this.state.activeSearchCompanies
        }
        this.props.client.query({
            query: GET_COMPANIES_PAGINATION,
            variables : variables,
            fetchPolicy: 'no-cache'
        }).then(result => {
            let formSettings = this.state.formSettings;
            let companies = result.data.companies.edges;
            formSettings.count = Math.ceil(result.data.companies.totalCount / this.state.nbperPageCompanies);
            this.setState({
                allCompanies : companies,
                formSettings : formSettings
            })
        })
        this.props.stopLoading();
    }

    handleSelectCompany = (checked,company,currentAction='add') =>{
        let companies = this.state.companies
        companies.toAdd = company
        this.setState({
            companies : companies
        })
    }

    handleChange = (e, type)=>{
        return new Promise((resolve, reject) => {
            this.setState({
                ready: false
            })
            clearTimeout(this.typingSearchTimer);
            this.setState({searchValue: e.target.value});
            this.typingSearchTimer = setTimeout(() => {
                this.handleGetProjects()
            }, 500);
            resolve();
        })
    }

    handleGetProjects = () => {
        return new Promise((resolve, reject) => {
            let variables = 
                {
                    "nbperpage": this.state.nbperpageProject, 
                };
            if (this.state.cursorProject) {
                variables.cursor = this.state.cursorProject;
            }
            if (this.state.cursorProjectLast) {
                variables.cursorLast = this.state.cursorProjectLast;
            }
            if (this.state.searchValue !== ''){
                variables.name = this.state.searchValue;
            }
            this.props.client.query({
                query: GET_PROJECTS_PAGINATION,
                variables,
                fetchPolicy:'no-cache'
            }).then(result =>{
                this.setState({
                    cursorProject: result.data.projects.pageInfo.startCursor,
                    cursorProjectLast: result.data.projects.pageInfo.endCursor,
                    countPageProject: result.data.projects.totalCount,
                    projects: result.data.projects.edges,
                });
                if (result.data.projects.edges.length > 0){
                    this.setState({
                        isEmpty: false,
                    })
                }else{
                    this.setState({
                        isEmpty: true
                    })
                }
                this.setState({
                    ready: true
                })
                this.props.stopLoading();
                resolve();
            })
        })
    }
    

    changePage = (event, page, type) => {
        this.props.startLoading();
        let index = 0;
        if(page > 1){
            switch (type){
                case 'project':
                    index = ( page * this.state.nbperpageProject ) - this.state.nbperpageProject - 1
                break;
                default:
                    index = ( page * this.state.nbperPageCompanies ) - this.state.nbperPageCompanies - 1
                break;
            }
        }
        switch (type){
            case 'project':
                this.setState({
                    cursorProject: page > this.state.pageProject ? this.state.cursorProjectLast : null,
                    cursorProjectLast: page > this.state.pageProject ? null : this.state.cursorProject,
                    pageProject: page
                },()=>{
                    this.handleGetProjects();
                });
            break;
            default:
                let formSettings = this.state.formSettings;
                formSettings.page = page
                this.setState({
                    allCompanies:null,
                    cursor: this.state.listCursors[index]?.cursor,
                    formSettings : formSettings
                },()=>{
                    this.handleGetCompanies();
                });
            break;
        }
    };

    setValue = (stateName, value, translated) => {
        this.setState({
            [stateName]: value,
        });
    };

    handleInputChange = (stateName, evt, custom, translated ) => {
        const value = evt?.target?.value ?? evt;
        this.setValue(stateName, value, translated);
    };

    resetState() {
        this.setState({
            name: null,
            description: null,
            imageProject: null,
            companies:{
                current : [],
                toAdd:  [],
                toRemove : [],
            },
            errors: {}
        });
    }

    handleButtonGroupChange = (stateName, value) => {
        this.setState({
            [stateName]: value
        });
    };

    deleteMutation = () => {
        let query = null;
        let variables = null;
        this.setState({
            formType: 'delete'
        })

        query = DELETE_PROJECT;
        variables = { id: this.state.idProject };
        this.props.client.mutate({
            mutation: query,
            variables
        }).then(() => {
            this.handleSuccess();
        });
    };

    copy(array) {
        let newArray = [];
        
        for (let elem of array)
            newArray.push(Object.assign({}, elem));

        return newArray;
    }

    copyArrayOfObjects = array => array.map(a => ({...a}));

    getAttributeTranslatedValue = (id, lang) => {
        if (!this.state.attributes)
            return null;

        let attribute = this.state.attributes.find(e => e.id === id);

        if (!attribute)
            return null;

        let translation = attribute.locales.find(e => e.id === lang);

        if (!translation)
            return null;

        return translation;
    };

    handleMediaPicker=(selected,stateName)=>{
        this.handleInputChange(stateName,selected,null,this.state.currentLang);
    }

    handleError = (e) => {
        this.props.snack(ALERT_ERROR, 'Une erreur est survenue');

        this.props.stopLoading();

        if (e.graphQLErrors) {
            for (let error of e.graphQLErrors) {
                console.error('ERROR', `${error.message} =>`, error.debugMessage);
            }
        }
    };

    handleSuccess = async () => {
        this.setState({
            ready: false
        })
        await this.initProject();
        if(this.state.formType === "edit"){
            this.props.snack(ALERT_SUCCESS, 'Projet modifié !');
        }else if(this.state.formType === "add"){
            this.props.snack(ALERT_SUCCESS, 'Projet crée !');
        }else{
            this.props.snack(ALERT_SUCCESS, 'Projet supprimé !');
            this.handleToggleDialog();
        }
        
        this.handleToggleDrawer();
        this.resetState();
        this.props.stopLoading();
    };

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;

        errors[stateName] = error;

        this.setState({ errors });
    };

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }

        return false;
    };

    handleNextStep = () =>{
        if (this.hasErrors()) {
            this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
            this.setState({ seeErrors: true });
            eventService.fire();
            return false;
        }

        return true;
    }

    handlerMutation = async () => {
        try {
            if (this.hasErrors()) {
                this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                this.setState({ seeErrors: true });
                return eventService.fire();
            }

            this.props.startLoading();
            if (this.state.formType === "add"){
                await this.props.client.mutate({
                    mutation: ADD_PROJECT,
                    variables:{
                        'name': this.state.name,
                        'description': this.state.description,
                        'media': this.state.imageProject.id,
                        'company': this.state.companies.toAdd,
                        'createdAt': moment().format('YYYY-MM-DD'),
                    }
                })
            }else if (this.state.formType === "edit"){
                await this.props.client.mutate({
                    mutation: UPDATE_PROJECT,
                    variables:{
                        'id': this.state.idProject,
                        'name': this.state.name,
                        'description': this.state.description,
                        'media': this.state.imageProject.id,
                        'company': this.state.companies.toAdd,
                    }
                })
            }

            this.handleSuccess();
        } catch(e) {
            this.handleError(e);
        }
    };

    render() {
        return (
            <div>
                <TopPanel 
                    icomoon="picto-produit"
                    colorIcomoon={colors.blue.lighter.hue300}
                    title="Gérer les Projets" 
                    subtitle="Gestion de vos projets (création / modification / suppression)" 
                    handlerAdd={() => this.addProject()} 
                    textAdd={hasRights(CRM_OFFERS, CRM_OFFERS_PROJECTS, CREATE) ? "Ajouter un projet" : null}
                    // handlerImport={() => this.handleToggleDrawer('openForm')} 
                    // textImport="Importer des produits" 
                    searchHandler={this.handleChange} 
                    gradientColor1={colors.menu.regular} 
                    gradientColor2={colors.menu.darker}
                    windowWidth={this.props.windowWidth}
                    openForm={this.state.openForm}
                    buttonAvailable={this.state.ready}
                    hasBorder="true"
                    // currentLang={this.state.currentLang} 
                    // handleLang={this.handleLang} 
                    // locales={this.props.locales}
                />
                <Grid container direction="column" justify="center" style={{paddingTop: 8}} spacing={0}>
                    <Grid container direction="row" spacing={2} style={{marginTop: 0, marginBottom: 0}}>
                        {
                            this.state.ready ?
                                this.state.projects.length > 0 ?
                                    this.state.projects.map((project, index) => {
                                        let getCompanyName = project.node.company.companyDatas.edges.find(e => e.node.attribute.identifier === "company_name");
                                        return(
                                            <GridCustom item lg={4} md={6} xs={12} key={`Project${index}`}>
                                                <CardCustom style={{width: "100%", height: "100%",  padding: 0}} cardContentStyle={{height: "100%", padding: 0}} hovercard={true}>
                                                    
                                                    {/* <LineColor height={8}/> */}
                                                    <div style={{padding: 16}}>
                                                        <div style={{maxHeight: 160, height:'160px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                            <img style={{maxHeight: 160}} src={`${process.env.REACT_APP_MEDIAS}/${project.node.media.filePath}`}/>
                                                        </div>
                                                        <Typography variant="h4" colortext={colors.blue.regular} style={{display: 'flex', justifyContent: 'space-between'}} component="div">
                                                            <Box fontWeight="bold">
                                                                {project.node.name}
                                                            </Box>
                                                            <Box style={{fontSize: 14}}>
                                                                Créé le : {moment(project.node.createdAt).format('DD-MM-YYYY')}
                                                            </Box>
                                                        </Typography>
                                                        <Typography dangerouslySetInnerHTML={{__html: project.node.description}} />
                                                        <CompanyItem container alignItems="center" onClick={() => this.goTo(ROUTE_CRM_CONTACTS_SOCIETIES_DETAILS.replace(':id', project.node.company.libelle), project.node.company.id, 'idCompany')} >
                                                            <CompanyPicture backgroundimage={project.node.company.medias?.filePath ? `${process.env.REACT_APP_MEDIAS}/${project.node.company.medias.filePath}` : NoImage}/>
                                                            <Typography style={{fontWeight: 'bold', width: 'calc(100% - 50px)', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>{getCompanyName ? getCompanyName.node.value : project.node.company.libelle}</Typography>
                                                        </CompanyItem>
                                                        {
                                                            hasRights(CRM_OFFERS, CRM_OFFERS_PROJECTS, UPDATE) ?
                                                                <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: 16}}>
                                                                    <Button onClick={() => this.editProject(project.node)}>Modifier</Button>
                                                                </div>
                                                            : null
                                                        }
                                                    </div>
                                                </CardCustom>
                                            </GridCustom>
                                        )
                                    })
                                :<EmptyCard title={hasRights(CRM_OFFERS, CRM_OFFERS_PROJECTS, CREATE) ? "Vous n'avez pas encore configuré de projets" : "Vous n'avez aucun droit de création sur cette page"} subtitle={hasRights(CRM_OFFERS, CRM_OFFERS_PROJECTS, CREATE) ? "Cliquez sur le bouton ci-dessous pour en ajouter un" : "Faite une demande auprès d'un administrateur"} textButton={hasRights(CRM_OFFERS, CRM_OFFERS_PROJECTS, CREATE) ? "Ajouter un projet" : null} onClick={() => {this.addProject()}} picto={EmptyProduct} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />
                            : <PageLoader />
                        }
                    </Grid>
                </Grid>
                {
                    this.state.ready && this.state.countPageProject > 1 ? 
                        <TablePagination
                            count={this.state.countPageProject}
                            page={this.state.pageProject}
                            onChangePage={(event, page) => {this.changePage(event, page, 'project')}}
                            rowsPerPage={6}
                            rowsPerPageOptions={[6]}
                            labelDisplayedRows={ (from=this.state.pagePresentations) => (`${from.from}-${from.to === -1 ? from.count : from.to} sur ${from.count}`)}
                            // onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                        // <PaginationCustom onChange={(event, page) => {this.changePage(event, page, 'project')}} page={this.state.pageProject} count={this.state.countPageProject} color="primary" />
                    :
                        null
                } 
                {
                    this.state.ready ?
                    (
                        <LayoutBuilder 
                            opened={this.state.openForm}
                            // image={this.state.imageForm}
                            // icomoon={this.state.content.picto}
                            forClose={() => {this.handleToggleDrawer()}} 
                            dataLayout={formAddProject(this.state.currentLang,this.handleMediaPicker,this.state.allCompanies,this.handleSelectCompany,this.state.companies,this.state.formSettings, this.state.formType)} 
                            handlerMutation={this.handlerMutation}
                            drawerWidth={this.props.drawerWidth}
                            stateCallback={this.handleInputChange}
                            backStepperButtonAction={[
                                () => {
                                    this.setState({ errors: {} });
                                }, 
                                () => {
                                    this.setState({ errors: {} });
                                },
                                () => {
                                    this.setState({ errors: {} });
                                },
                                null
                            ]}
                            stepperButtonAction={[
                                this.handleNextStep,
                                this.handleNextStep,
                            ]}
                            errorCallback={this.handleFormError}
                            validateButton={true}
                            deleteMutation={this.state.formType === 'edit' && hasRights(CRM_OFFERS, CRM_OFFERS_PROJECTS, DELETE) ? () => this.handleToggleDialog() : null}
                            deleteText={this.state.formType === 'edit' ? 'Supprimer' : null}
                            allState={this.state}
                        />
                    ) : ''
                }
                <Dialog
                        open={this.state.openDialog}
                        onClose={this.handleToggleDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">Êtes-vous sûr de vouloir supprimer ce projet ?</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Si vous supprimez ce projet celui-ci ne sera plus accessible. Si vous ne souhaitez pas le supprimer, annulez la suppression en cliquant sur annuler.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleToggleDialog} color={colors.grey.regular} bgcolor={colors.white} bgcolorhover={colors.grey.lighter.hue900} border={`1px solid ${colors.grey.regular}`}>
                                Annuler
                            </Button>
                            <Button onClick={this.deleteMutation} bgcolor={colors.red.regular} bgcolorhover={colors.red.darker} autoFocus>
                                Supprimer
                            </Button>
                        </DialogActions>
                    </Dialog>
            </div>
        );
    }
    handleMediaPicker=(selected,stateName)=>{
        this.handleInputChange(stateName,selected,null,this.state.currentLang);
    }
    
    handleGetCompaniesCursors  = ()=>{
        this.props.client.query({
            query: GET_COMPANIES_CURSOR,
            fetchPolicy: 'no-cache'
        }).then(result => {
            this.setState({
                listCursors: result.data.companies.edges
            }, () => {
                this.handleGetCompanies();
            });
        });
    }

    handleChangeCompanies = (e,value)=>{
        this.setState({
            currentPage : 1,
            allCompanies : null,
        })
        clearTimeout(this.typingSearchTimer);
        if(e?.target?.value){
            this.setState({searchValueCompanies: e.target.value});
            this.typingSearchTimer = setTimeout(() => {
                this.handleSearchCompanies();

            }, 500);
        } 
        else{
            this.setState({activeSearchCompanies:false},()=>this.handleGetCompanies());
        }
    }

    handleSearchCompanies = () =>{
        let value = this.state.searchValueCompanies;
        if (value !== "") {
            this.props.client.query({
                query: SEARCH_COMPANIES,
                variables: {"needle": value,"attribute":null},
                fetchPolicy: 'no-cache'
            }).then(result =>{
                let companies = result.data.companyDatas.edges.map(e=>parseInt(e.node.company.id.replace('/api/companies/',''),10));
                let listID=[];
                let uniqueCompanies = companies.filter(company=>{
                    if(!listID.includes(company)){
                        listID.push(company);
                        return company;
                    }else{
                        return null;
                    }
                })
                this.setState({
                    activeSearchCompanies :uniqueCompanies
                },()=>this.handleGetCompanies())
                
            })
            
        }
    }

    

    async initProject() {
        await this.handleGetProjects();
        let formSettings = this.state.formSettings;
        formSettings.changePage = this.changePage;
        formSettings.handleSearch = this.handleChangeCompanies;
        this.handleGetCompaniesCursors();
    }

    goTo = (route, id, target) => {
        let variables = {};
        variables[target] = id;
        this.props.history.push({
            pathname : route,
            state: variables
        });
    };

}

const mapDispatchToProps = dispatch => {
    return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes, 
        locales: state.locales,
    };
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(ListProjects)));
