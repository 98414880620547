import {CARD} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';

const CatalogsConfig = 
    {
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: CARD,
        }
    }
export default CatalogsConfig;