import React from 'react';
import { connect } from "react-redux";
import { SNACK, START_LOADING, STOP_LOADING } from '../../../../../js/constants/action-types';
import { Grid } from '@material-ui/core';
import FormBuilder from '../../../../../components/ui/form/FormBuilder';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

class Mapping extends React.Component {  
    state = {
        loading: false,
        headers: [],
        inputs: [],
        options: [],
        import: {}
    };

    componentDidMount() {
        let options = [
                {
                    id: 'sku',
                    label: 'Sku'
                }, {
                    id: 'date',
                    label: 'Date'
                }
            ];


        this.setState({ 
            options,
            headers: (this.props.allState['headers'] || []).map(e => ({
                used: false,
                label: e,
                id: e
            })),
            inputs: [
                {
                    type: 'select',
                    label: 'Langue',
                    translated: false,
                    helper: {
                        text: `Langue de l'import`,
                        link: false,
                    },
                    required: true,
                    stateName: 'importLang',
                    value: this.props.locales.map(locale => {
                        return ({
                            value: locale.node.id,
                            label: locale.node.libelle
                        });
                    })
                }
            ]
        });
    }

    construct = () => {
        const { allState, stateCallback } = this.props;
        const { headers } = this.state;

        let mapper = [];

        for (let header of headers) {
            let value = allState.importValues[header.id];
            mapper.push(!value || value === '' || value === '-1' ? null : value);
        }
        stateCallback('mapper', mapper);
    };

    render() {
        const { inputs, options, headers } = this.state;
        const { allState, stateCallback } = this.props;

        return (
            <div style={{ paddingTop: 15, paddingBottom: 15, backgroundColor: 'rgb(250, 251, 251)' }}>
                { inputs ? <FormBuilder 
                    optionsInputs={inputs} 
                    stateCallback={stateCallback} 
                    allState={allState}
                /> : null }

                {
                    headers.map((header, i) => {
                        let value = allState.importValues[header.id];

                        return (
                            <div key={`attribute_${i}`} style={{
                                padding: 10,
                                backgroundColor: (!value || value === '' || value === '-1') ? '#fff' : 'rgb(224, 241, 251)',
                                width: '90%',
                                margin: '0 auto',
                                marginBottom: 5
                            }}>
                                <Grid container>
                                    <Grid item xs={6} style={{ 
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'flex-end',
                                        paddingRight: 20,
                                        borderRightWidth: 1,
                                        borderRightStyle: 'solid',
                                        fontWeight: (!value || value === '' || value === '-1') ? 'normal' : 'bold'
                                    }}>
                                        { header.label }
                                    </Grid>
                                    <Grid item xs={6} style={{ 
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'flex-start',
                                        paddingLeft: 20
                                    }}>
                                        <FormControl>
                                            <Select
                                                value={allState.importValues[header.id] || '-1'}
                                                onChange={(evt) => {
                                                    this.setState({
                                                        options: options.map(e => {
                                                            if (e.id === evt.target.value)
                                                                e.used = true; // make option unavailable

                                                            if (e.id === allState.importValues[header.id])
                                                                e.used = false; // make old option available
                                                            
                                                            return e;
                                                        })
                                                    });

                                                    let values = allState.importValues;

                                                    values[header.id] = evt.target.value;

                                                    stateCallback('importValues', values, null, null, () => {
                                                        this.construct();
                                                    });
                                                }}
                                            >
                                                <MenuItem value="-1">
                                                    <em>Aucun</em>
                                                </MenuItem>

                                                {
                                                    options.map((e, i) => (
                                                        <MenuItem key={`header-${i}`} disabled={e.used} value={e.id}>{ e.label }</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>
                        );
                    })
                }


            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
      loading: state.loading,
      products: state.products,
      attributes: state.attributes, 
      locales: state.locales,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Mapping);