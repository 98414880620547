import React, { Fragment, useContext, useEffect } from "react";
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Context } from '../../../../../store';
import notFound from '../../../../../not-found.png';
import H3 from "../../../../shared/components/CustomH3";
import PG from "../../../../shared/components/CustomParagraph";
import Button from '../../../../shared/components/CustomButton';

const LinkCustom = (props) => {
    if (props.spread) {
        return <>
            { props.children }
        </>
    } else {
        return <Link href={props.href} as={props.as} shallow={true}>{ props.children }</Link>
    }
}

const Wishlist = ({  
    preview = false,  
    id = null, 
    data = null,
    inputCallback = null,
    spread = false,
    products = [],
    children = null
}) => {
    const context = useContext(Context);
    const router = useRouter();

    const [ items, setItems ] = React.useState([]);

    useEffect(() => {
      let wishlistItems = products.filter(e => context.products?.indexOf(e.id) > -1);

      setItems(wishlistItems);
    }, []);

    let formatted = items.map(product => {
        let allNames    = product.attributes.product_name;
        let name        = allNames?.['fr_FR'] ?? 'n/a';
      
        let allPrices   = product.attributes.product_price;
        let price       = allPrices?.['fr_FR'] ?? 'n/a';

        let allImages   = product?.attributes.product_image;
        let image       = allImages?.['fr_FR'] ?? null;

        return {
            id: product.id,
            name,
            image, 
            price
        };
    });

    return (
      <div 
        className="builder-template-scope cart-main-area"
        style={{
          width: '100%',
          marginBottom: 20
        }}
      >
        { !preview && children }

        <div className="container pt-40 pb-20">
          {formatted && formatted.length >= 1 ? (
            <Fragment>
              <H3 
                data={data} 
                preview={preview} 
                component={id} 
                block={'title'} 
                inputCallback={inputCallback} 
                nopadding={true}
                className="mb-15 cart-page-title"
              />
              <div className="row">
                <div className="col-12">
                  <div className="table-content table-responsive cart-table-content">
                    <table>
                      <thead>
                        <tr>
                          <th>{data.inputs?.['productImage']?.value}</th>
                          <th>{data.inputs?.['productName']?.value}</th>
                          <th>{data.inputs?.['productPrice']?.value}</th>
                          <th>{data.inputs?.['productAction']?.value}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formatted.map((wishlistItem, key) => {
                          return (
                            <tr key={key}>
                              <td className="product-thumbnail">
                                <LinkCustom spread={spread} href="/product/[slug]" as={`/product/${wishlistItem?.id.toString().replace('/api/products/', '')}`}>
                                    <a>
                                        <img
                                            className="img-fluid"
                                            src={wishlistItem.image ? `${process.env.REACT_APP_MEDIAS}/${wishlistItem.image}` : notFound}
                                            alt={wishlistItem.name}
                                        />
                                    </a>
                                </LinkCustom>
                              </td>

                              <td className="product-name text-center">
                                <LinkCustom spread={spread} href="/product/[slug]" as={`/product/${wishlistItem?.id.toString().replace('/api/products/', '')}`}>
                                    <a>
                                        {wishlistItem.name}
                                    </a>
                                </LinkCustom>
                              </td>

                              <td className="product-price-cart">
                                <span className="amount">
                                  {wishlistItem.price} €
                                </span>
                              </td>

                              <td className="product-remove">
                                <button onClick={() => {
                                    let all = context.products;

                                    if (all) {
                                        all = all.filter(e => e !== wishlistItem.id);
                                        context.update(all);
                                    }

                                    localStorage.setItem('products', JSON.stringify(all || null));

                                    let wishlistItems = products.filter(e => all?.indexOf(e.id) > -1);

                                    setItems(wishlistItems);
                                }}>
                                  <i className="fa fa-times"></i>
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="cart-shiping-update-wrapper">
                    <div className="cart-shiping-update">
                      <Button 
                        data={data} 
                        preview={preview} 
                        component={id} 
                        block={'buttonSend'} 
                        inputCallback={inputCallback}
                        nopadding={true} 
                        type="button" 
                        className="mb-20 button"
                      />
                    </div>
                    <div className="cart-clear">
                      <Button 
                        data={data} 
                        preview={preview} 
                        component={id} 
                        block={'buttonClear'} 
                        inputCallback={inputCallback}
                        nopadding={true} 
                        type="button" 
                        className="mb-20 button"
                        onClick={() => {
                          context.update([]);
                          localStorage.setItem('products', JSON.stringify([]));
                          setItems([]);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          ) : (
            <div className="row">
              <div className="col-lg-12">
                <div className="item-empty-area text-center">
                  <div className="item-empty-area__icon mb-30">
                    <i className="pe-7s-like"></i>
                  </div>
                  <div className="item-empty-area__text">
                    <PG 
                      data={data} 
                      preview={preview} 
                      component={id} 
                      block={'noResult'} 
                      inputCallback={inputCallback} 
                      nopadding={true} 
                    />
                    <br />{" "}
                    <Button 
                      data={data} 
                      preview={preview} 
                      component={id} 
                      block={'buttonAdd'} 
                      inputCallback={inputCallback}
                      type="button" 
                      className="mb-20 button"
                      onClick={() => {
                        if (!spread && router)
                          router.push('/');
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
};

export default Wishlist;