import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Footer } from '../shareable';

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: '100%',
        // backgroundColor: '#efefef',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    page: {
        backgroundColor: '#fff',
        width: '85%',
        marginTop: 30,
        padding: 30,
        boxShadow: '5px 5px 10px #cecece',
        border: 'solid 1px #eee'
    }
});

export default ({ config, params, getComponent, preview }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.page}>
                { config.elements.map(getComponent) }
            </div>
        </div>
    );
};