import React from 'react';
import {Grid, Box} from '@material-ui/core';
import Typography from '../../../ui/typography/Typography';
import CardCustom from '../../../layouts/Card/CardCustom';
import Button from '../../../ui/button/Button';
import colors from '../../../../config/theme/colors';
import CardHomepageStats from '../../../layouts/Card/cardContent/CardHomepageStats';
import CardActions from '../../../layouts/Card/cardContent/CardActions';

import { withRouter } from 'react-router';
import { connect } from "react-redux";
import request from '../../../../js/utils/fetch';

import { hasRights } from '../../../../js/utils/rights';

import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import SliderActu from './components/SliderActu';
import { withApollo } from 'react-apollo';

import Image1 from '../../../../../src/assets/images/socials/Stats.png';
import Image2 from '../../../../../src/assets/images/socials/Calendar.png';
import BannerVideo from '../../../../../src/assets/images/banner_video.png';
import PictoVideo from '../../../../../src/assets/images/pictoVideo.svg';

import LineCharts from '../../../layouts/Stats/LineCharts';
import PieCharts from '../../../layouts/Stats/PieCharts';
import { eventService } from '../../../../js/services/event.service';

import {
    VIEW,
    DASHBOARD,
    DASHBOARD_STATISTICS,
    PRODUCTS, 
    PRODUCTS_PRODUCTS,
    BUILDER,
    BUILDER_ASSETS,
    CATALOGS,
    CATALOGS_CATALOGS,
    MEDIAS,
    MEDIAS_DASHBOARD,
    CONTENTS,
    CONTENTS_CONTENTS,
    CONNECTORS,
    CONNECTORS_DASHBOARD,
    BRAND,
    BRAND_GUIDELINE,
    SETTINGS,
    SETTINGS_LANGUAGES,
} from '../../../../js/constants/constant-rights';

import { ROUTE_PRODUCTS_LIST, ROUTE_STATS, ROUTE_CATALOGS_LIST, ROUTE_MEDIAS, ROUTE_CHANNELS, ROUTE_SETTINGS_LANGUAGES, ROUTE_DIFFUSION_PERMANENT, ROUTE_CONTENU_LIST, ROUTE_BRAND_GUIDELINE } from '../../../../js/constants/route-names';
import UpdateIcon from '@material-ui/icons/Update';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import PlayForWorkIcon from '@material-ui/icons/PlayForWork';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';

const notifications = [
    {
        date: '23/06/2020 ',
        text: 'Nouveau ! vous pouvez dès maintenant créer un catalogue éphémère en déterminant une date de début et une date de fin et en y associant les catégories et produits de votre choix',
        id: uuidv4()
    },
    {
        date: '22/06/2020',
        text: 'Agissez sur votre réseau social facebook avec Spread c’est maintenant possible ! Il vous suffit de vous rendre dans l’onglet canaux de diffusion : Réseaux sociaux et d’y ajouter votre connecteur Facebook.',
        id: uuidv4()
    },
];

const sliderData = [
    {
        title: 'Ajuster votre stratégie en consultant vos statistiques en temps réel :',
        text: 'Un découpage de dashboard est en place dans chacun des onglets pour vous permettre d’obtenir en un clic le récapitulatif de vos dernières datas. Un dashboard complet pour orienter vos stratégies commerciales/ Marketing est à votre disposition dans l’onglet statistiques : top des ventes produits, produits les plus vus... Dans la partie produits, vous pouvez avoir une vision sur les dernières mises à jour produits, stocks, prix qui ont été effectuées. Vous possédez à votre disposition tous les leviers sur lesquelles vous pouvez performer.',
        image: Image1,
        id: uuidv4()
    },
    {
        title: 'C’est tout nouveau !',
        text: 'Dans l’onglet Calendrier retrouvez toutes vos activations et les informations associées à celles-ci afin de vous apporter un accompagnement sur la répartition à venir de votre stratégie Marketing. Obtenez une vision globale des activités à venir ou appliquez des filtres par campagne ou retailer pour cibler l’information.',
        image: Image2,
        id: uuidv4()
    },
];

const ContainerBlocNotification = styled(Box)`
    border-radius: 10px;
    position: relative;
`;
const ContainerNotification = styled(Box)`
    background: ${colors.blue.lighter.hue900};
    border-radius: 10px;
    position: relative;
`;
const RemoveNotification = styled(Button)`
    background: ${colors.blue.lighter.hue900};
    border-radius: 10px;
`;

class Homepage extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            notifications,
            statsHomepage: [
                {
                    'icon': 'picto-categorie',
                    'data': '-',
                    'dataColor': colors.blue.darker.hue300,
                    'dataCustomFont': '16px',
                    'subtitle': 'Catégorie',
                    'subtitleCustomFont': '16px',
                    'subtitleColor': colors.blue.darker.hue300,
                    'gradientColor1': colors.blue.lighter.hue300,
                    'gradientColor2': colors.blue.regular,
                },
                process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                {
                    'icon': 'icon-asset',
                    'data': '-',
                    'dataColor': colors.blue.darker.hue300,
                    'dataCustomFont': '16px',
                    'subtitle': 'Asset actif',
                    'subtitleCustomFont': '16px',
                    'subtitleColor': colors.blue.darker.hue300,
                    'gradientColor1': colors.blue.lighter.hue300,
                    'gradientColor2': colors.blue.regular,
                } : null,
                {
                    'icon': 'picto-produit',
                    'data': '-',
                    'dataColor': colors.blue.darker.hue300,
                    'dataCustomFont': '16px',
                    'subtitle': 'Produit',
                    'subtitleColor': colors.blue.darker.hue300,
                    'subtitleCustomFont': '16px',
                    'gradientColor1': colors.blue.lighter.hue300,
                    'gradientColor2': colors.blue.regular,
                },
                process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                {
                    'icon': 'icon-campagne',
                    'data': '-',
                    'dataColor': colors.blue.darker.hue300,
                    'dataCustomFont': '16px',
                    'subtitle': 'Campagne active',
                    'subtitleColor': colors.blue.darker.hue300,
                    'subtitleCustomFont': '16px',
                    'gradientColor1': colors.blue.lighter.hue300,
                    'gradientColor2': colors.blue.regular,
                } : null,
            ],
        };

        this.actions=[];
    }

    removeNotification(id) {
        const newState = this.state;
        const index = newState.notifications.findIndex(a => a.id === id);
    
        if (index === -1) return;
        newState.notifications.splice(index, 1);
    
        this.setState(newState);
    };

    initStats = async () => {
        request(`${process.env.REACT_APP_API}/dashboards/stats/homepage`, 'get', false).then(
            (data) => {
                if(data.success){
                    let getStats = this.state.statsHomepage
                    if (getStats[0]){
                        getStats[0].data = data.datas.nbCategories;
                    }
                    if (getStats[1]){
                        getStats[1].data = data.datas.nbAssetEnable;
                    }
                    if (getStats[2]){
                        getStats[2].data = data.datas.nbProducts;
                    }
                    if (getStats[3]){
                        getStats[3].data = data.datas.nbCampaignEnable;
                    }
                    if(data.datas.nbCategories > 1 && getStats[0]){
                        getStats[0].subtitle = 'Catégories';
                    }
                    if(data.datas.nbAssetEnable > 1 && getStats[1]){
                        getStats[1].subtitle = 'Assets actifs';
                    }
                    if(data.datas.nbProducts > 1 && getStats[2]){
                        getStats[2].subtitle = 'Produits';
                    }
                    if(data.datas.nbCampaignEnable > 1 && getStats[3]){
                        getStats[3].subtitle = 'Campagnes actives';
                    }
                    this.setState({
                        statsHomepage: getStats
                    })
                    this.forceUpdate()
                }
            }
        );

        if(hasRights(DASHBOARD, DASHBOARD_STATISTICS, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub"){
            this.actions.push({
                'icon': 'icon-statistique',
                'title':'Statistiques',
                'subtitle': 'Visionnez l’ensemble de vos statistiques et actionnez les leviers de vos actions marketing.',
                'gradientColor1': colors.white,
                'gradientColor2': colors.white,
                'colorPicto': colors.blue.lighter.hue300,
                'color': colors.blue.regular,
                'colorHover': colors.blue.lighter.hue300,
                'route': ROUTE_STATS,
                // 'routeState': {openMediaForm: true, buttonAvailable: true}
            })
        }
        if(hasRights(BUILDER, BUILDER_ASSETS, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub"){
            this.actions.push({
                'icon': 'icon-permanent',
                'title':'Diffusion des assets',
                'subtitle': 'Diffusez sur l\'ensemble de vos assets mis en place sur la plateforme.',
                'gradientColor1': colors.white,
                'gradientColor2': colors.white,
                'colorPicto': colors.blue.lighter.hue300,
                'color': colors.blue.regular,
                'colorHover': colors.blue.lighter.hue300,
                'route': ROUTE_DIFFUSION_PERMANENT,
                // 'routeState': {openForm: true, buttonAvailable: true}
            })
        }
        if(hasRights(CATALOGS, CATALOGS_CATALOGS, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub"){
            this.actions.push({
                'icon': 'icon-asset',
                'title':'Spread Engine',
                'subtitle': 'Créez votre catalogue produits puis diffusez-le sur les assets de vos choix.',
                'gradientColor1': colors.white,
                'gradientColor2': colors.white,
                'colorPicto': colors.blue.lighter.hue300,
                'color': colors.blue.regular,
                'colorHover': colors.blue.lighter.hue300,
                'route': ROUTE_CATALOGS_LIST,
                // 'routeState': {openForm: true, buttonAvailable: true}
            });  
        }
        if(hasRights(MEDIAS, MEDIAS_DASHBOARD, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub"){
            this.actions.push({
                'icon': 'icon-digital-assets-manager',
                'title':'Médias',
                'subtitle': 'Accédez à l’ensemble des données concernant vos médias et créez en des nouveaux.',
                'gradientColor1': colors.white,
                'gradientColor2': colors.white,
                'colorPicto': colors.blue.lighter.hue300,
                'color': colors.blue.regular,
                'colorHover': colors.blue.lighter.hue300,
                'route': ROUTE_MEDIAS,
                // 'routeState': {openMediaForm: true, buttonAvailable: true}
            });    
        }
        if(hasRights(CONTENTS, CONTENTS_CONTENTS, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub"){
            this.actions.push({
                'icon': 'picto-content',
                'title':'Contenus',
                'subtitle': 'Retrouvez l’ensemble de vos contenus, modifiez les et créez en de nouveaux.',
                'gradientColor1': colors.white,
                'gradientColor2': colors.white,
                'colorPicto': colors.blue.lighter.hue300,
                'color': colors.blue.regular,
                'colorHover': colors.blue.lighter.hue300,
                'route': ROUTE_CONTENU_LIST,
                // 'routeState': {openMediaForm: true, buttonAvailable: true}
            });  
        }
        if(hasRights(PRODUCTS, PRODUCTS_PRODUCTS, VIEW)){
            this.actions.push({
                'icon': 'picto-produit',
                'title':'Produits',
                'subtitle': 'Passez à la création de vos produits ou importez-les directement.',
                'gradientColor1': colors.white,
                'gradientColor2': colors.white,
                'colorPicto': colors.blue.lighter.hue300,
                'color': colors.blue.regular,
                'colorHover': colors.blue.lighter.hue300,
                'route': ROUTE_PRODUCTS_LIST,
                // 'routeState': {openForm: true, buttonAvailable: true}
            });
        }
        if(hasRights(CONNECTORS, CONNECTORS_DASHBOARD, VIEW)){
            this.actions.push({
                'icon': 'icon-cms',
                'title':'Canaux de diffusion',
                'subtitle': 'Obtenez une vision d’ensemble sur vos canaux de diffusion en place (marketplace, réseaux sociaux ..).',
                'gradientColor1': colors.white,
                'gradientColor2': colors.white,
                'colorPicto': colors.blue.lighter.hue300,
                'color': colors.blue.regular,
                'colorHover': colors.blue.lighter.hue300,
                'route': ROUTE_CHANNELS,
                // 'routeState': {openMediaForm: true, buttonAvailable: true}
            });
        }
        if(hasRights(BRAND, BRAND_GUIDELINE, VIEW)){
            this.actions.push({
                'icon': 'icon-guideline',
                'title':'Guideline',
                'subtitle': 'Définissez la charte graphique digitale de votre marque.',
                'gradientColor1': colors.white,
                'gradientColor2': colors.white,
                'colorPicto': colors.blue.lighter.hue300,
                'color': colors.blue.regular,
                'colorHover': colors.blue.lighter.hue300,
                'route': ROUTE_BRAND_GUIDELINE,
                // 'routeState': {openForm: true, buttonAvailable: true}
            }); 
        }
        if(hasRights(SETTINGS, SETTINGS_LANGUAGES, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub"){
            this.actions.push({
                'icon': 'picto-parametre',
                'title':'Paramètres',
                'subtitle': 'Accédez à la gestion de vos paramétrages et personnalisez votre environnement.',
                'gradientColor1': colors.white,
                'gradientColor2': colors.white,
                'colorPicto': colors.blue.lighter.hue300,
                'color': colors.blue.regular,
                'colorHover': colors.blue.lighter.hue300,
                'route': ROUTE_SETTINGS_LANGUAGES,
                // 'routeState': {openForm: true, buttonAvailable: true}
            });
        }
        if(process.env.REACT_APP_MODE_SPREAD === 'hub'){
            await request(`${process.env.REACT_APP_API}/hubStats`, 'post').then(
                (data) => {
                    if(data.success){
                        let date = [];
                        let orderByDate = [];
                        let ordersByStatus = [];
                        let totalOrder = 0;
                        for (let item in data.ordersByDate) {
                            date.push(item)
                            orderByDate.push(data.ordersByDate[item]);
                            totalOrder += data.ordersByDate[item];
                        }
                        for (let item in data.ordersByStatus){
                            let name = '';
                            switch(item){
                                case 'delayed':
                                    name = 'En retard';
                                break;
                                case 'pending':
                                    name = 'En attente';
                                break;
                                case 'preorder':
                                    name = 'Pré-commande';
                                break;
                                case 'preparation':
                                    name = 'En cours de préparation';
                                break;
                                case 'returned':
                                    name = 'Retour';
                                break;
                                case 'shipped':
                                    name = 'Expédiée'
                                break;
                                default: return null;
                            }
                            ordersByStatus.push({name: name, value: data.ordersByStatus[item]})
                        }
                        this.setState({
                            data,
                            totalOrder,
                            date,
                            allDatas : [
                                {
                                    data: orderByDate,
                                    color: '#147FAB',
                                    title: 'Commande/jour',
                                },
                            ],
                            selectDataPie: {
                                type: 'select',
                                required: false,
                                stateName: 'selectedDataPie',
                                label: '',
                                helper: '',
                                value:
                                    [
                                        {
                                            value : ordersByStatus,
                                            label : 'Commandes'
                                        },
                                        {
                                            value : [
                                                {value: data.fullStock, name: 'Stock actuel'},
                                                {value: data.fullReassort, name: 'Stock à venir'},
                                            ],
                                            label : 'Stock'
                                        }
                                    ]
                            },
                        }, () => {
                            this.setState({selectedDataPie: this.state.selectDataPie.value[0].value})
                        })
                    }
                }
            );
        }
    };

    handleSelectPie = (event) => {
        this.setState({ selectedDataPie: event.target.value }, () => {
            eventService.fire();
        });
    };

    componentDidMount(){
        this.initStats();
    };

    render() {
        return (
            <div>
                <Grid container direction="column" justify="center" spacing={0}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} md={9}>
                            <Box pt={3} pb={2}>
                                <CardCustom paddingbottom={this.props.windowWidth > 1100 ? "0px" : 16} paddingtop="0px">
                                    <CardHomepageStats statsHomepage={this.state.statsHomepage} user={this.props.user} windowWidth={this.props.windowWidth}/>
                                </CardCustom>
                                {
                                    process.env.REACT_APP_MODE_SPREAD !== "hub" ? (
                                        <CardCustom paddingbottom="0px" paddingtop="0px" style={{marginBottom: 8, marginTop: 16, background: `url(${BannerVideo})`, height: 160, backgroundSize: 'cover', backgroundPosition: '0px'}}>
                                            <div style={{display: 'flex', justifyContent: 'space-between', alignItem: 'center', flexDirection: 'row', alignItems: 'center', height: '100%', maxWidth: 'calc(100% - 50px)', width: 900, marginLeft: 50}}>
                                                <div>
                                                    <Typography variant="h2" style={{color: colors.white, marginBottom: 8}}>Découvrez le fonctionnement de SpreadSuite</Typography>
                                                    <Typography variant="body1" style={{color: colors.white, maxWidth: 460}}>Spread Suite est une plateforme de Content Marketing Experience à destination des marques.</Typography>
                                                </div>
                                                <div>
                                                    <img src={PictoVideo} />
                                                </div>
                                            </div>
                                        </CardCustom>
                                    ) : null
                                }
                            </Box>
                            <Box>
                                <Grid container direction="row" spacing={2}>
                                    {   
                                        this.actions ? (this.actions.map((action,index)=>
                                            action ?
                                            (
                                                <Grid item md={4} xs={6} key={`CardActions${index}`} style={{marginRight: 0}}>
                                                    <CardCustom  style={{height: '100%'}}>
                                                        <CardActions action={action} goTo={this.goToAction}></CardActions>
                                                    </CardCustom>
                                                </Grid>
                                            ) : null
                                        )): null
                                    }
                                </Grid>
                                <Grid container direction="row" spacing={2}>
                                    {
                                        this.state.date && this.state.allDatas ? (
                                            <Grid item lg={6} xs={12} >
                                                <Box mt={1} mb={2}>
                                                    <CardCustom paddingtop={'48px'}>
                                                        <LineCharts xAxisData={this.state.date} yAxisData={this.state.allDatas} handleSelectPie={this.handleSelectPie}/>
                                                    </CardCustom>
                                                </Box>
                                            </Grid>
                                        ) : null
                                    }
                                    {
                                        this.state.selectedDataPie && this.state.selectDataPie ? (
                                            <Grid item lg={6} xs={12}>
                                                <Box mt={1} mb={2}>
                                                    <CardCustom paddingtop={'48px'} style={{position: 'relative'}}>
                                                        <PieCharts selectedDataPie={this.state.selectedDataPie} selectDataPie={this.state.selectDataPie} handleSelectPie={this.handleSelectPie}/>
                                                    </CardCustom>
                                                </Box>
                                            </Grid>
                                        ) : null
                                    }
                                </Grid>
                                { this.state.data && this.state.totalOrder ? (
                                    <Grid container direction="row" justify="center" spacing={4}>
                                        <Grid item xs={12}>
                                            <Grid container direction="row" justify="center" spacing={0}>
                                                <Grid item xs={4}>
                                                    <CardCustom style={{background: colors.blue.regular, borderRadius: '5px 0 0 5px', borderRight: '1px solid white' }} cardContentStyle={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: '12px 12px 16px'}}>
                                                        <Typography variant="h3" style={{color: 'white', fontSize: 14, lineHeight: '20px'}}>Total commande</Typography>
                                                        <Typography variant="body1" style={{color: 'white', fontSize: 36, lineHeight: '36px'}}>
                                                            <ShoppingCartOutlinedIcon /> {this.state.totalOrder}
                                                        </Typography>
                                                    </CardCustom>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <CardCustom style={{background: colors.blue.regular, borderRadius: '0', borderRight: '1px solid white' }} cardContentStyle={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: '12px 12px 16px'}}>
                                                        <Typography variant="h3" style={{color: 'white', fontSize: 14, lineHeight: '20px'}}>Total stock</Typography>
                                                        <Typography variant="body1" style={{color: 'white', fontSize: 36, lineHeight: '36px'}}>
                                                            <EqualizerIcon /> {this.state.data?.fullStock ? this.state.data.fullStock : null}
                                                        </Typography>
                                                    </CardCustom>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <CardCustom style={{background: colors.blue.regular, borderRadius: '0 5px 5px 0'}} cardContentStyle={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: '12px 12px 16px'}}>
                                                        <Typography variant="h3" style={{color: 'white', fontSize: 14, lineHeight: '20px'}}>Total réassort</Typography>
                                                        <Typography variant="body1" style={{color: 'white', fontSize: 36, lineHeight: '36px'}}>
                                                            <PlayForWorkIcon /> {this.state.data?.fullReassort ? this.state.data.fullReassort : null}
                                                        </Typography>
                                                    </CardCustom>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ) : null}
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box pt={3} pb={2}>
                                <CardCustom style={{height: 'calc(90vh - 50px)', overflow: 'auto'}}>
                                    <Box mt={2} mb={2}>
                                        <Typography variant="h3" colortext={colors.blue.lighter.hue300}>Notifications</Typography>
                                    </Box>
                                    <ContainerBlocNotification>
                                        {
                                            this.state.notifications.map((notification,index) => {
                                                return(
                                                    <ContainerNotification p={2} mt={2} pb={3} key={`Notification${index}`}>
                                                        <Typography 
                                                            style={{fontWeight: 'bold', fontSize: 12, lineHeight: 'inherit'}} 
                                                            colortext={colors.blue.lighter.hue300}
                                                        >{notification.date}</Typography>
                                                        <Box mt={1}>
                                                            <Typography variant={"body2"} style={{fontWeight: 'normal', fontSize: this.props.windowWidth < 1400 ? 12 : this.props.windowWidth < 1800 ? 13 : 14}}>{notification.text}</Typography>
                                                        </Box>
                                                        <RemoveNotification 
                                                            bgcolor={"transparent"} 
                                                            bgcolorhover={"transparent"} 
                                                            color={colors.blue.lighter.hue300} 
                                                            fontsize={16}
                                                            style={{
                                                                padding: '0px 10px',
                                                                position: 'absolute',
                                                                right: '1rem',
                                                                minWidth: 'auto',
                                                            }} 
                                                            onClick={() => this.removeNotification(notification.id)}
                                                        >x</RemoveNotification>
                                                    </ContainerNotification>
                                                )
                                            })
                                        }
                                    </ContainerBlocNotification>
                                    <Box mt={3} mb={2}>
                                        <Typography variant="h3" colortext={colors.blue.lighter.hue300}>C'est tout nouveau !</Typography>
                                    </Box>
                                    <Box>
                                        <SliderActu sliderData={sliderData}/>
                                    </Box>
                                </CardCustom>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    };

    goToAction = (route) => {
        this.props.history.push(route);
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
    };
};

export default withApollo(withRouter(connect(mapStateToProps, null)(Homepage)));
