import React, { useState } from 'react';
import { useDrag } from 'react-dnd';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { ItemTypes } from '../shareable/types';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import SubtitlesIcon from '@material-ui/icons/Subtitles';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import AppsIcon from '@material-ui/icons/Apps';
import ImageIcon from '@material-ui/icons/Image';
import SearchIcon from '@material-ui/icons/Search';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import ViewCompactIcon from '@material-ui/icons/ViewCompact';
import LayersIcon from '@material-ui/icons/Layers';
import CloseIcon from '@material-ui/icons/Close';
import colors from '../../config/theme/colors'; 

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginBottom: 10,
        width: 'calc(48% - 20px)',
        height: 120,
        cursor: 'move',
        flexDirection: 'column',
        textAlign: 'center',
        padding: 10,
        borderRadius: 0,
        boxShadow: 'inherit'
    },
}));

function Draggable(props) {
    let {type} = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [{isDragging}, drag] = useDrag({
        item: { type },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    });


    const getTypeName = type => {
        switch(type) {
            // MINISITE
            case ItemTypes.SLIDER: return "Slider";
            case ItemTypes.SLIDER_MULTIPLE: return "Slider multiple";
            case ItemTypes.SLIDER_WITH_RIGHT_IMAGES: return "Slider avec images";
            case ItemTypes.TAB_PRODUCT: return "Produits";
            case ItemTypes.IMAGE_ROW: return "Images Instagram";
            case ItemTypes.SECTION_TITLE: return "Section de titre";
            case ItemTypes.THREE_IMAGES_GRID: return "Grille d'images";
            case ItemTypes.THREE_IMAGES_ROW: return "Bandeau d'images";
            case ItemTypes.BLOC_TEXT: return "Bloc de texte";
            case ItemTypes.GIFT_FINDER: return "Gift finder";
            // OFFER
            case ItemTypes.OFFER_FRONT_PAGE: return "Page de garde";
            case ItemTypes.OFFER_SECTION_TITLE: return "Section de titre";
            case ItemTypes.OFFER_ABOUT_SLIDE: return "À propos";
            case ItemTypes.OFFER_TRIPLE_BLOCK_LIST: return "Équipe";
            case ItemTypes.OFFER_TRIPLE_BLOCK: return "Division de 3 blocs";
            case ItemTypes.OFFER_TRIPLE_BLOCK_TEXT: return "Division de 3 textes";
            case ItemTypes.OFFER_TEXT_WITH_IMAGE: return "Référence client";
            case ItemTypes.OFFER_TEXT_WITH_IMAGE_SIMPLE: return "Texte et image";
            case ItemTypes.OFFER_DEVIS: return "Devis";
            // GAME
            case ItemTypes.PRE_HEADER_GAME: return "Pre-Header";
            case ItemTypes.HOME_GAME: return "Homepage";
            case ItemTypes.HOME_COMPLETE_FORM_GAME: return "Homepage avec formulaire";
            case ItemTypes.EXPLANATIONS_GAME: return "Explications";
            case ItemTypes.EXPLANATIONS_ACV_GAME: return "Section d'explications ACV";
            case ItemTypes.EXPLANATIONS_BF_GAME: return "Section d'explications BF";
            case ItemTypes.EXPLANATIONS_MORE_DETAILS_GAME: return "Section d'explications complète et détaillée";
            case ItemTypes.BASIC_BLOC: return "Bloc de présentation";
            case ItemTypes.BASIC_BLOC2: return "Bloc de présentation 2";
            case ItemTypes.REDIRECT_ON_GAME: return "Bloc de Redirection";
            case ItemTypes.REDIRECT_ON_GAME2: return "Bloc de Redirection 2";
            case ItemTypes.REDIRECT_ON_GAME3: return "Bloc de Redirection 2";
            case ItemTypes.REDIRECT_ON_GAME4: return "Bloc de Redirection 3";
            // AMAZING GAME BLOC
            case ItemTypes.AMAZING_GAME: return "Bloc à compléter";
            case ItemTypes.TITLE_GAME: return "Titre";
            case ItemTypes.PARAGRAPH_GAME: return "Paragraphe";
            case ItemTypes.RULES_AMAZING_GAME: return "Règles";
            case ItemTypes.RULES_INLINE_FLEX_AMAZING_GAME: return "Règles en ligne";
            case ItemTypes.DOTATIONS_ONE_IMAGE: return "Dotations en une image";
            case ItemTypes.BUTTON_GAME: return "Boutton";
            default: return "n/a";
        }
    }

    const getTypeIcon = (type, fontSize) => {
        switch(type) {
            case ItemTypes.SLIDER: return <ViewCarouselIcon style={{ fontSize, color: colors.menu.regular }} />;
            case ItemTypes.SLIDER_MULTIPLE: return <ViewCarouselIcon style={{ fontSize, color: colors.menu.regular }} />;
            case ItemTypes.SLIDER_WITH_RIGHT_IMAGES: return <ViewCarouselIcon style={{ fontSize, color: colors.menu.regular }} />;
            case ItemTypes.TAB_PRODUCT: return <AppsIcon style={{ fontSize, color: colors.menu.regular }} />;
            case ItemTypes.IMAGE_ROW: return <ViewColumnIcon style={{ fontSize, color: colors.menu.regular }} />;
            case ItemTypes.SECTION_TITLE: return <SubtitlesIcon style={{ fontSize, color: colors.menu.regular }} />;
            case ItemTypes.THREE_IMAGES_GRID: return <ViewCompactIcon style={{ fontSize, color: colors.menu.regular }} />;
            case ItemTypes.THREE_IMAGES_ROW: return <ViewColumnIcon style={{ fontSize, color: colors.menu.regular }} />;
            case ItemTypes.BLOC_TEXT: return <SubtitlesIcon style={{ fontSize, color: colors.menu.regular }} />;
            case ItemTypes.IMAGE: return <ImageIcon style={{ fontSize, color: colors.menu.regular }} />;
            case ItemTypes.TEXT: return <TextFieldsIcon style={{ fontSize, color: colors.menu.regular }} />;
            case ItemTypes.GIFT_FINDER: return <SearchIcon style={{ fontSize, color: colors.menu.regular }} />;
            default: return null;
        }
    }

    return (
        <>
            <Paper 
                elevation={3} 
                className={classes.root} 
                ref={drag} 

                style={{
                    opacity: 1, 
                    border: isDragging ? `1px dashed ${colors.blue.regular}` : '1px solid white',
                    boxShadow: props.children ? '8px 8px 0px -3px rgb(43, 69, 83)' : null,
                    position: 'relative',
                }}
            >
                {
                    props.children && !isDragging ?
                    (
                        <div 
                            style={{position: 'absolute', top: 10, left: 10, display: 'flex', alignContent: 'center', cursor: 'pointer'}}
                            onClick={() => setOpen(!open)}
                        >
                            <LayersIcon style={{fill: colors.blue.regular}} /> <span style={{color: colors.blue.regular}}>{props.children.length ? props.children.length : 1}</span>
                        </div>
                    ) : null
                }
                { isDragging ? null : getTypeIcon(type, 50) }
                <span style={{ fontSize: 14, fontWeight: 'bold', opacity: isDragging ? 0 : 1 }}>{ getTypeName(type) }</span>
            </Paper>
            {props.children && open ? (
                <div style={{
                    padding: open ? 20 : '0 20px', 
                    width: 'calc(100% + 80px)', 
                    display: 'block', 
                    background: colors.blue.regular, 
                    marginLeft: -20, 
                    marginRight: -20,
                    marginBottom: 20,
                    overflow: 'hidden',
                    height: open ? '100%' : 0,
                }}>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10}}>
                        <div style={{display: 'flex'}}>
                            <LayersIcon style={{fill: 'white'}} />
                            <span style={{color: 'white'}}>Groupe</span>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}} onClick={() => setOpen(!open)}>
                            <span style={{color: 'white', fontSize: 12}}>Fermer</span>
                            <CloseIcon style={{fill: 'white', width: '0.7em', height: '0.7em'}} />
                        </div>
                    </div>
                    <div 
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                            overflow: 'auto',
                            
                        }}
                    >

                        {props.children}
                    </div>
                </div>
            ) : null}
        </>
    )
}

export default Draggable;