import { gql } from 'apollo-boost';

//----- Récupération des types d'attributs
export const GET_EAV_TYPES = gql`
{
  eavTypes{
    edges{
      node{
        id
        code
      }
    }
  }
}
`;

//----- Récupération des groups d'attributs
export const GET_ATTR_GROUPE = gql`
query getAttributeGroups($boolIsForContent: Boolean){
  attributeGroups (first: 1000 exists:{isForContent:$boolIsForContent}) {
    edges{
      node{
        id
        identifier
        isSystem
        isForContent
        translation {
          id
          translationKey
          translationDatas {
            edges {
              node {
                id
                value
                locale {
                  id
                  code
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

//----- Récupération des groups d'attributs
export const GET_ATTR_GROUPE_LIGHT = gql`
query getAttributeGroups($boolIsForContent: Boolean){
  attributeGroups (first: 1000 exists:{isForContent:$boolIsForContent}) {
    edges{
      node{
        id
        identifier
      }
    }
  }
}
`;

//----- Récupération des groups d'attributs
export const GET_ATTR_GROUPE_WITH_ATTRIBUTES = gql`
{
  attributeGroups (first: 1000) {
    edges {
      node {
        id
        identifier
        isSystem
        isForContent
        attributes(first: 1000) {
          edges {
            node {
              id
              identifier
              isRequired
              isSystem
              mapperRequired
              status
              eavType {
                id
              }
              translation {
                id
                translationDatas {
                  edges {
                    node {
                      value
                      locale {
                        id
                        code
                      }
                    }
                  }
                }
              }
              attributeType {
                id
                input
              }
              attributeOptions {
                edges {
                  node {
                    id
                    identifier
                    translation {
                      id
                      translationDatas {
                        edges {
                          node {
                            value
                            locale {
                              id
                              code
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              attributeDictionnaries{
                edges {
                  node{
                    id
                    input
                    output
                  }
                }
              }
            }
          }
        }
        translation {
          id
          translationKey
          translationDatas {
            edges {
              node {
                id
                value
                locale {
                  id
                  code
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

//----- Récupération des types input d'attributs
export const GET_ATTRIBUTE_TYPES = gql`
{
  attributeTypes{
    edges{
      node{
        id
        input
        translation {
        	id
          translationKey
          translationDatas {
            edges {
              node {
                id
                value
                locale {
									id
                  code
								}
              }
            }
          }
      	}
      }
    }
  }
}
`;

//----- Récupération des attributs
export const GET_ATTRIBUTES = gql`
query getAttributes($limit: Int){
  attributes(first: $limit){
    edges{
      node{
        id
        identifier
        status
        translation{
          id
          translationDatas{
            edges{
              node{
                id
                value
                locale{
                  id
                  code
                  libelle
                }
              }
            }
          }
        }
        isRequired
        isSystem
        mapperRequired
        attributeType{
          id
          input  
        }
        attributeGroup{
          edges{
            node{
              id
            }
          }
        }
        attributeOptions{
          edges{
						node{
              id
              identifier
            }
          }
        }
        attributeDictionnaries{
          edges {
            node{
              id
              input
              output
            }
          }
        }
        attributeOptionDictionnaries{
          edges {
            node{
              id
              input
              attributeOption{
                id
                identifier
                translation{
                  translationDatas {
                    edges {
                      node {
                        id
                        value
                        locale {
                          id
                          code
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

//----- Ajouter un attribut
export const ADD_ATTRIBUTE = gql`
mutation AddAttribute($identifier: String!, $isSystem: Boolean!, $isRequired: Boolean!,  $isSearchable: Boolean!, $eavType: String!,  $attributeType: String!, $attributeGroup: [String], $translation: String!, $mapperRequired:Boolean, $status:Boolean!){
	createAttribute(input: {identifier:$identifier, isSystem:$isSystem, isRequired:$isRequired, isSearchable:$isSearchable, eavType:$eavType, attributeType:$attributeType, attributeGroup:$attributeGroup, translation:$translation, mapperRequired:$mapperRequired, status: $status}){
  	attribute{
      id
      identifier
      isRequired
      isSearchable
      isSystem
      status
      eavType{
        id
      }
      attributeType{
        id
      }
      attributeGroup{
      	edges{
          node{
        		id  
        	}
        }
      }
      translation{
        id
      }
    }
  }
}`;

//----- Modifier un attribut -- PARAM ID ATTRIBUT ( ex: /api/attributes/1 )
export const UPDATE_ATTRIBUTE = gql`
mutation EditAttribute($id: ID!, $identifier: String, $isRequired: Boolean,  $isSearchable: Boolean, $eavType: String,  $attributeType: String, $attributeGroup: [String], $translation: String, $mapperRequired:Boolean){
	updateAttribute(input: {id: $id, identifier:$identifier, isRequired:$isRequired, isSearchable:$isSearchable, eavType:$eavType, attributeType:$attributeType, attributeGroup:$attributeGroup, translation:$translation, mapperRequired:$mapperRequired}){
  	attribute{
      id
      identifier
      isRequired
      isSearchable
      isSystem
      eavType{
        id
      }
      attributeType{
        id
      }
      attributeGroup{
      	edges{
          node{
        		id  
        	}
        }
      }
      translation{
        id
      }
    }
  }
}`;

export const UPDATE_ATTRIBUTE_STATUS = gql`
mutation EditAttribute($id: ID!, $status: Boolean!){
	updateAttribute(input: {id: $id, status: $status}){
  	attribute{
      id
      status
    }
  }
}`;

//----- Supprimer un attribut
/*
 Exemple de variables
  {
    "id" : "/api/attributes/1"
  }
*/
export const DELETE_ATTRIBUTE = gql`
mutation DeleteAttribute($id: ID!){
	deleteAttribute(input: {id: $id}){
  	attribute{
      id
    }
  }
}`;

//----- Récupération des attributs par group
/*
 Exemple de variables
  {
    "id" : "/api/attribute-groups/1"
  }
*/
export const GET_ATTRIBUTES_BY_GROUPE = gql`
query attributeByGroup($id: ID!){
	attributeGroup(id: $id){
    id
  	identifier
    attributes (first: 1000){
      edges{
        node
      	{
        	id
          identifier
          isRequired
          isSearchable
          isSystem
          status
          translation{
            id
            translationDatas{
              edges{
                node{
                  id
                  value
                  locale{
                    id
                    code
                  }
                }
              }
            }
          }
          attributeGroup{
            edges{
              node{
                id  
              }
            }
          }
          attributeType{
            id
            input  
          }
          attributeOptions{
            edges{
              node{
                id
                identifier
                translation{
                  id
                  translationDatas{
                    edges{
                      node{
                        id
                        value
                      	locale{
                          id
                        	code
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          attributeDictionnaries{
            edges {
              node{
                id
                input
                output
              }
            }
          }
        }
      }
    }
  }
}`;

//----- Récupération des attributs par type d'attributes
/*
 Exemple de variables
  {
    "id" : "/api/eav-types/4"
  }

*/
export const GET_ATTRIBUTES_BY_TYPE = gql`
query attributesByEavType($id: ID!) {
  eavType(id: $id) {
    id
    code
    attributes(first: 1000) {
      edges {
        node {
          id
          identifier
          isRequired
          isSearchable
          isSystem
          status
          translation {
            id
            translationDatas {
              edges {
                node {
                  id
                  value
                  locale {
                    id
                    code
                  }
                }
              }
            }
          }
          attributeGroup {
            edges {
              node {
                id
              }
            }
          }
          attributeType {
            id
            input
          }
          attributeOptions {
            edges {
              node {
                id
                identifier
                translation {
                  id
                  translationDatas {
                    edges {
                      node {
                        id
                        value
                        locale {
                          id
                          code
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          attributeDictionnaries{
            edges {
              node{
                id
                input
                output
              }
            }
          } 
          attributeOptionDictionnaries{
            edges {
              node{
                id
                input
                attributeOption{
                  id
                  identifier
                  translation{
                    translationDatas {
                      edges {
                        node {
                          id
                          value
                          locale {
                            id
                            code
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }         
        }
      }
    }
  }
}`;


//----- Ajouter une option d'attribut 
/*
 Exemple de variables
 {
  "identifier": "red",
  "attribute": "/api/attributes/22",
	"translation": "/api/translations/32"
}
*/
export const ADD_ATTRIBUTE_OPTION = gql`
mutation AddAttributeOption($identifier: String!, $attribute: String!, $translation: String!){
	createAttributeOption(input: {identifier:$identifier, attribute:$attribute, translation:$translation}){
  	attributeOption{
      id
      identifier
      attribute{
        id
        identifier
      }
      translation{
        id
      }
    }
  }
}`;


//----- Modifier une option d'attribut 
/*
 Exemple de variables
 {
  "id": "/api/attribute-options/14",
  "identifier": "red",
  "attribute": "/api/attributes/22",
	"translation": "/api/translations/32"
}
*/
export const UPDATE_ATTRIBUTE_OPTION = gql`
mutation UpdateAttributeOption($id: ID!, $identifier: String, $attribute: String, $translation: String){
	updateAttributeOption(input: {id:$id, identifier:$identifier, attribute:$attribute, translation:$translation}){
  	attributeOption{
      id
      identifier
      attribute{
        id
        identifier
      }
      translation{
        id
      }
    }
  }
}`;

//----- Supprimer une option d'attribut
/*
 Exemple de variables
  {
    "id" : "/api/attribute-options/14"
  }
*/
export const DELETE_ATTRIBUTE_OPTION = gql`
mutation DeleteAttributeOption($id: ID!){
	deleteAttributeOption(input: {id:$id}){
  	attributeOption{
      id
    }
  }
}
`

//----- Ajouter un group d'attribut 
/*
 Exemple de variables
 {
  "identifier": "red",
  "translation": "/api/translations/32",
  "isSystem": false
}
*/
export const ADD_ATTRIBUTE_GROUPE = gql`
mutation AddAttributeGroup($identifier: String!, $attributes: [String], $translation: String!, $isSystem: Boolean!, $isForContent: Boolean){
	createAttributeGroup(input: {identifier:$identifier, attributes:$attributes, translation:$translation, isSystem:$isSystem, isForContent:$isForContent}){
  	attributeGroup{
      id
      identifier
    }
  }
}
`

//----- Modifier un group d'attribut 
/*
 Exemple de variables
 {
  "id": "/api/attribute-groups/5",
  "identifier": "red_edited"
}
*/
export const UPDATE_ATTRIBUTE_GROUPE = gql`
mutation UpdateAttributeGroup($id: ID!, $identifier: String, $attributes: [String], $translation: String, $isSystem: Boolean, $isForContent: Boolean){
	updateAttributeGroup(input: {id:$id, identifier:$identifier, attributes:$attributes, translation:$translation, isSystem:$isSystem, isForContent:$isForContent}){
  	attributeGroup{
      id
      identifier
    }
  }
}
`
//----- Supprimer un group d'attribut 
/*
 Exemple de variables
 {
  "id": "/api/attribute-groups/5"
}
*/
export const DELETE_ATTRIBUTE_GROUPE = gql`
mutation DeleteAttributeGroup($id: ID!) {
	deleteAttributeGroup(input: {id:$id}) {
  	attributeGroup {
      id
    }
  }
}
`

export const GET_ATTRIBUTE = gql`
query attribute($id: ID!) {
  attribute(id: $id) {
    id
    identifier
    isRequired
    isSearchable
    isSystem
    translation {
      id
      translationDatas {
        edges {
          node {
            id
            value
            locale {
              id
              code
            }
          }
        }
      }
    }
    attributeGroup {
      edges {
        node {
          id
        }
      }
    }
    attributeType {
      id
      input
    }
    attributeOptions {
      edges {
        node {
          id
          identifier
          translation {
            id
            translationDatas {
              edges {
                node {
                  id
                  value
                  locale {
                    id
                    code
                  }
                }
              }
            }
          }
        }
      }
    }
    attributeDictionnaries{
      edges {
        node{
          id
          input
          output
        }
      }
    }
    attributeOptionDictionnaries{
      edges {
        node{
          id
          input
        }
      }
    }
  }
}`;


//----- Ajouter un attribute dictionnary 
/*
 Exemple de variables
 {
  "attribute": "/api/attributes/1",
  "input": "T.U",
  "output": Taille unique
}
*/
export const ADD_ATTRIBUTE_DICTIONNARY = gql`
mutation createAttributeDictionnary($attribute: String, $input: Iterable!, $output: String!){
	createAttributeDictionnary(input: {attribute:$attribute, input:$input, output:$output}){
  	attributeDictionnary{
      id
      input
      output
    }
  }
}
`

//----- Modifier un attribute dictionnary 
/*
 Exemple de variables
 {
  "id": "/api/attribute-dictionnaries/1",
  "attribute": "/api/attributes/1",
  "input": "T.U",
  "output": Taille unique
}
*/
export const UPDATE_ATTRIBUTE_DICTIONNARY = gql`
mutation updateAttributeDictionnary($id: ID!, $attribute: String, $input: Iterable, $output: String){
	updateAttributeDictionnary(input: {id:$id, attribute:$attribute, input:$input, output:$output}){
  	attributeDictionnary{
      id
      input
      output
    }
  }
}
`

//----- Supprimer un attribute dictionnary 
/*
 Exemple de variables
 {
  "id": "/api/attribute-dictionnaries/1"
}
*/
export const DELETE_ATTRIBUTE_DICTIONNARY = gql`
mutation deleteAttributeDictionnary($id: ID!){
	deleteAttributeDictionnary(input: {id:$id}){
  	attributeDictionnary{
      id
    }
  }
}
`


//----- Ajouter un attribute option dictionnary 
/*
 Exemple de variables
 {
  "attribute": "/api/attributes/1",
  "input": "T.U",
  "attributeOption": "/api/attribute-options/14"
}
*/
export const ADD_ATTRIBUTE_OPTION_DICTIONNARY = gql`
mutation createAttributeOptionDictionnary($attribute: String!, $input: String!, $attributeOption: String!){
	createAttributeOptionDictionnary(input: {attribute:$attribute, input:$input, attributeOption:$attributeOption}){
  	attributeOptionDictionnary{
      id
      input
      attributeOption{
        id
        identifier
      }
    }
  }
}
`

//----- Modifier un attribute option dictionnary 
/*
 Exemple de variables
 {
  "id": "/api/attribute-option-dictionnaries/1",
  "attribute": "/api/attributes/1",
  "input": "T.U",
  "attributeOption": "/api/attribute-options/14"
}
*/
export const UPDATE_ATTRIBUTE_OPTION_DICTIONNARY = gql`
mutation updateAttributeOptionDictionnary($id: ID!, $attribute: String, $input: String, $attributeOption: String!){
	updateAttributeOptionDictionnary(input: {id:$id, attribute:$attribute, input:$input, attributeOption:$attributeOption}){
  	attributeOptionDictionnary{
      id
      input
      attributeOption{
        id
        identifier
      }
    }
  }
}
`

//----- Supprimer un attribute option dictionnary 
/*
 Exemple de variables
 {
  "id": "/api/attribute-option-dictionnaries/1"
}
*/
export const DELETE_ATTRIBUTE_OPTION_DICTIONNARY = gql`
mutation deleteAttributeOptionDictionnary($id: ID!){
	deleteAttributeOptionDictionnary(input: {id:$id}){
  	attributeOptionDictionnary{
      id
    }
  }
}
`