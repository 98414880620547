import React from 'react';

import { withRouter } from 'react-router';
import { connect } from "react-redux";
import ReactEcharts from 'echarts-for-react';

class LineCharts extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            xAxisData: this.props.xAxisData,
            yAxisData: this.props.yAxisData,
        };
    }


    
    getOption(){
        var options = {
            grid: {
                left: '0',
                bottom: '0',
                top:'15%',
                right:'0',
                containLabel: true,
            },
            tooltip: {
                trigger: 'axis',
                position: 'top',
            },
            legend: {
                align: 'left',
                icon: 'circle',
                right: '0',
                itemGap: 90,
                textStyle: {
                    fontSize: 16,
                    fontFamily: 'Soleil'
                }
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: this.state.xAxisData,
            },
            yAxis: {
                type: 'value',
            },
            series: this.state.yAxisData.map(serie => {
                return {
                    name: serie.title,
                    type: 'line',
                    smooth: false,
                    sampling: 'average',
                    itemStyle: {
                        color: serie.color
                    },
                    lineStyle: {
                        width : 0,
                    },
                    areaStyle: {
                        origin: 'start',
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 0.15, color: serie.color // color at 0% position
                            }, {
                                offset: 1, color: '#FFFFFF' // color at 100% position
                            }],
                            global: false // false by default
                        }
                    },
                    data: serie.data
                }
            })
        };

        return options

    };

    render() {
        return (
            <div>
                <ReactEcharts option={this.getOption()} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
    };
};

export default withRouter(connect(mapStateToProps, null)(LineCharts));
