import React from 'react';
import { Grid, Tooltip, Box} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import CardCustom from '../../../layouts/Card/CardCustom';
import Typography from '../../../ui/typography/Typography';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import styled from 'styled-components';
import { ROUTE_PRODUCTS_LIST_DETAIL, ROUTE_PRODUCTS_ADD, ROUTE_HOME } from '../../../../js/constants/route-names';
import colors from '../../../../config/theme/colors';
import { withApollo } from 'react-apollo';
import { GET_ORDER_BY_ID } from '../../../../queries/orders';
import { SEARCH_PRODUCTS_SKU } from '../../../../queries/products';
import EmptyProduct from '../../../../assets/pictos/empty-picto/empty_products.png';
import EmptyCard from "../../../ui/empty-card/EmptyCard";
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import request from '../../../../js/utils/fetch';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_SUCCESS, ALERT_ERROR } from '../../../../js/constants/alert-types';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import { PRODUCTS, PRODUCTS_PRODUCTS, VIEW, CREATE, IMPORT, EXPORT } from '../../../../js/constants/constant-rights';
import SearchBar from '../../../ui/search/SearchBar';
import Button from '../../../ui/button/Button';
import * as moment from 'moment';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 1400px){
        padding: 16px 8px 8px!important;
    }
`;

const TableCellCustom = styled(TableCell)`
    border: none;
    display: inline-block;
    width: calc(100% / 6);
    padding: 3px 16px;
`

const BoxCustom = styled(Box)`
    width: auto;
`;
const ReturnLink = styled(Typography)`
    color: ${colors.blue.lighter.hue300};
    width: 70px;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
`;

const styles = theme => ({
});

const useStylesBootstrap = makeStyles(theme => ({
    arrow: {
        color: colors.blue.darker.hue300,
    },
    tooltip: {
        backgroundColor: colors.blue.darker.hue300,
        fontSize: 14,
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

class OrderDetails extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            ready : false,
            currentLang: props.locales[0].node.code,
            orderId: props.history.location.state.orderId,
            errors: {},
        }
        this.errors = {}
    }

    handleGetOrderDetail() {
        return new Promise((resolve, reject) => {
            const getOrder = GET_ORDER_BY_ID;
            let variables = 
            {
                "id": this.state.orderId, 
            };
            this.props.client.query({
                query: getOrder,
                variables,
                fetchPolicy: 'no-cache'
            }).then(result =>{
                this.setState({
                    orderInfo:{
                        number: result.data.order.number,
                        paymentMethod: result.data.order.paymentMethod,
                        discountAmount: result.data.order.discountAmount,
                        orderItems: result.data.order.orderItems.edges,
                        source: result.data.order.source,
                        sourceNumber: result.data.order.sourceNumber,
                        status: result.data.order.status,
                        date: result.data.order.createdAt,
                        orderTrackings: result.data.order.orderTrackings.edges,
                    },
                    clientInfo: {
                        firstName: result.data.order.billingFirstname,
                        name: result.data.order.billingName,
                        email: result.data.order.billingEmail,
                        phone: result.data.order.billingPhone,
                        city: result.data.order.billingCity,
                        country: result.data.order.billingCountry,
                        street: result.data.order.billingStreet,
                        zipcode: result.data.order.billingZipcode,
                        company: result.data.order.billingCompany,
                    },
                    clientShipping: {
                        firstName: result.data.order.shippingName,
                        name: result.data.order.shippingFirstname,
                        email: result.data.order.shippingEmail,
                        phone: result.data.order.shippingPhone,
                        city: result.data.order.shippingCity,
                        country: result.data.order.shippingCountry,
                        street: result.data.order.shippingStreet,
                        zipcode: result.data.order.shippingZipcode,
                        company: result.data.order.shippingCompany,
                        amount: result.data.order.shippingAmount,
                        method: result.data.order.shippingMethod,

                    }
                })
                // this.listOrders = result.data.orders.edges;
                // this.setState({countPage: Math.ceil(result.data.orders.totalCount / this.state.nbperpage)})
                // request(`${process.env.REACT_APP_API}/completude/`, 'post', false).then(
                //     (data) => {
                //         if(data.success){
                //             for( var i = 0; i < this.listOrders.length; i++){
                //                 let productSku = this.listOrders[i].node.sku;
                //                 let infoStats = data.products[productSku];
                //                 this.listOrders[i].node.stats = {
                //                     infoStats
                //                 };
                //             }
                //             this.forceUpdate();
                //         }
                //     }
                // );
                // this.setState({
                //     listOrders: this.listOrders
                // });
                this.props.stopLoading();
                resolve();
            });
        });
    }

    componentDidMount() {
        if (process.env.REACT_APP_MODE_SPREAD !== "hub"){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }
        this.getAll();
    }

    async getAll() {
        await this.handleGetOrderDetail();
        // await this.handleGetCategories();
        // await this.handleGetCursorsOrders();
        this.setState({ ready: true });
    }

    render() {
        const headCells = [
            { id: 'sku', image: false, style: null, disablePadding: true, label: 'Sku' },
            { id: 'name', image: false, style: null, disablePadding: false, label: 'Nom du produit' },
            { id: 'qty', image: false, style: null, disablePadding: false, label: 'Quantité' },
            { id: 'qtyExp', image: false, style: null, disablePadding: false, label: 'Quantité expédié' },
            { id: 'price', image: false, style: null, disablePadding: false, label: 'Prix unitaire' },
            { id: 'totalPrice', image: false, style: null, disablePadding: false, label: 'Prix total' },
        ];
        let productBypage = [];
        productBypage = [10,20,50];
        const { classes } = this.props;
        return (
            <div>
                {
                    this.state.ready ?
                        <>
                            <TopPanel 
                                icomoon="icon-costs"
                                colorIcomoon={colors.blue.lighter.hue300}
                                title={`Détails de la commande N°${this.state.orderInfo?.number}`}
                                subtitle="Retrouvez ici le détail de votre commande"
                                // handlerAdd={() => this.goTo(ROUTE_PRODUCTS_ADD)} 
                                // textAdd={process.env.REACT_APP_MODE_SPREAD !== "hub" ? hasRights(PRODUCTS, PRODUCTS_PRODUCTS, CREATE) ? "Ajouter un produit" : null : null}
                                // handlerImport={() => this.handleToggleDrawer('openForm')} 
                                // textImport={process.env.REACT_APP_MODE_SPREAD !== "hub" ? hasRights(PRODUCTS, PRODUCTS_PRODUCTS, IMPORT) ? "Importer des produits" : null : null}
                                // searchHandler={this.handleChange} 
                                gradientColor1={colors.menu.regular} 
                                gradientColor2={colors.menu.darker}
                                windowWidth={this.props.windowWidth}
                                openForm={this.state.openForm}
                                buttonAvailable={this.state.ready}
                                hasBorder={true}
                                // currentLang={this.state.currentLang} 
                                // handleLang={this.handleLang} 
                                // locales={this.props.locales}
                            />
                            <Grid container justify="space-between" alignItems="center">
                                <BoxCustom onClick={this.props.history.goBack} style={{marginBottom: 16, marginTop: 16}}>
                                    <ReturnLink variant={'body2'}>&lt; Retour</ReturnLink>
                                </BoxCustom>
                                <Grid item>
                                    <Grid container>
                                        <Button 
                                            bgcolor={colors.blue.lighter.hue300} 
                                            color={colors.white} 
                                            bgcolorhover={colors.blue.darker.hue300}  
                                            colorhover={colors.white}  
                                            border={`1px solid ${colors.blue.lighter.hue300}`}
                                            onClick={() => console.log('TODO')}
                                        >Réexpédier</Button>
                                        <Button style={{marginLeft: 8}} onClick={() => console.log('TODO')}>Modifier</Button>
                                        <Button 
                                            style={{marginLeft: 8}}
                                            bgcolor={colors.red.regular} 
                                            color={colors.white} 
                                            bgcolorhover={colors.red.darker}  
                                            colorhover={colors.white}  
                                            border={`1px solid ${colors.red.regular}`}
                                            onClick={() => console.log('TODO')}
                                        >Annuler la commande</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container direction="column" justify="center" style={{paddingTop: 8}} spacing={0}>
                                <>
                                    <Grid container direction="row" justify="space-between" spacing={2} style={{marginTop: 8, marginBottom: 16}}>
                                        <Grid item xs={4}>
                                            <CardCustom style={{width: "100%", height: "100%"}} cardContentStyle={{height: "100%"}} hovercard={false}>
                                                <Typography variant="h3">Informations Commande N°{this.state.orderInfo.number}</Typography>
                                                <Typography variant="h4" style={{marginTop: 8, textTransform:'capitalize'}}>Status : {this.state.orderInfo.status}</Typography>
                                                <Typography variant="h4">Origine de la commande : {this.state.orderInfo.source}</Typography>
                                                <Typography variant="h4">N° de source : {this.state.orderInfo.sourceNumber}</Typography>
                                                <Typography variant="h4">Date de commande : {moment(this.state.orderInfo.date).format('DD/MM/YYYY à hh:mm:ss')}</Typography>
                                            </CardCustom>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" justify="space-between" spacing={2} style={{marginTop: 8, marginBottom: 16}}>
                                        <Grid item xs={4}>
                                            <CardCustom style={{width: "100%", height: "100%"}} cardContentStyle={{height: "100%"}} hovercard={false}>
                                                <Typography variant="h3">Informations facturation</Typography>
                                                <Typography variant="h4" style={{marginTop: 8}}><span style={{textTransform: 'uppercase'}}>{this.state.clientInfo.name}</span> {this.state.clientInfo.firstName}</Typography>
                                                <Typography variant="body1" style={{marginTop: 8}}>Téléphone: {this.state.clientInfo.phone}</Typography>
                                                <Typography variant="body1" style={{marginTop: 4}}>Email: {this.state.clientInfo.email}</Typography>
                                                <Typography variant="body1" style={{marginTop: 4}}>
                                                    Adresse: {this.state.clientInfo.street} {this.state.clientInfo.zipcode} {this.state.clientInfo.city}, {this.state.clientInfo.country}
                                                </Typography>
                                            </CardCustom>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <CardCustom style={{width: "100%", height: "100%"}} cardContentStyle={{height: "100%"}} hovercard={false}>
                                                <Typography variant="h3">Informations livraison</Typography>
                                                <Typography variant="h4" style={{marginTop: 8}}><span style={{textTransform: 'uppercase'}}>{this.state.clientShipping.name}</span> {this.state.clientShipping.firstName}</Typography>
                                                <Typography variant="body1" style={{marginTop: 8}}>Téléphone: {this.state.clientShipping.phone}</Typography>
                                                <Typography variant="body1" style={{marginTop: 4}}>Email: {this.state.clientShipping.email}</Typography>
                                                <Typography variant="body1" style={{marginTop: 4}}>
                                                    Adresse: {this.state.clientShipping.street} {this.state.clientShipping.zipcode} {this.state.clientShipping.city}, {this.state.clientShipping.country}
                                                </Typography>
                                            </CardCustom>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <CardCustom style={{width: "100%", height: "100%"}} cardContentStyle={{height: "100%"}} hovercard={false}>
                                                <Typography variant="h3">Paiement et méthode de livraison</Typography>
                                                <Typography variant="h4" style={{marginTop: 8}}>Méthode de paiement : {this.state.orderInfo.paymentMethod}</Typography>
                                                <Typography variant="h4">Méthode de livraison : {this.state.clientShipping.method}</Typography>
                                                <Typography variant="h4">Frais de port : {this.state.clientShipping.amount} €</Typography>
                                            </CardCustom>
                                        </Grid>
                                        {/* <GridCustom item xs={12} key={`HeaderList`} style={{padding: '0px 16px', }}>
                                            <CardCustom style={{width: "100%", height: "100%",  padding: "0", boxShadow: 'none', border: 'none', borderRadius: '0', background: 'none'}} cardContentStyle={{height: "100%"}} contentpadding={'0'} hovercard={false}>
                                                <TableContainer>
                                                    <Table aria-label="headerList">
                                                        <TableHead style={{padding: '5px 0'}}>
                                                            <TableRow>
                                                                {headCells.filter(e => e!== null).map((headCell) => (
                                                                    <TableCellCustom
                                                                    key={headCell.id}
                                                                    align={headCell.image ? 'center' : 'left'}
                                                                    style={{width: `${headCell.style?.width}`}}
                                                                    >
                                                                        {headCell.label}
                                                                    </TableCellCustom>
                                                                ))}
                                                            </TableRow>
                                                        </TableHead>
                                                    </Table>
                                                </TableContainer>
                                            </CardCustom>
                                        </GridCustom> */}
                                        {/* {
                                            !this.state.ready ? (<PageLoader />) : (this.state.listOrders && this.state.listOrders.length > 0 && !this.state.noResult) ? (
                                                this.state.listOrders.map((order, index) => {
                                                    // let truncAttrGroupId = product.node.attributeGroup.id.replace('/api/attribute_groups/', '');
                                                    return(
                                                        <GridItemList item xs={12} key={`ListProduct${index}`} style={{padding: '3px 16px'}}>
                                                            <CardCustom style={{width: "100%", height: "100%",  padding: "0", boxShadow: 'none', borderRadius: 0}} cardContentStyle={{height: "100%"}} contentpadding={'4px 0'} hovercard={false}>
                                                                <CardOrderList
                                                                    // nbCatalog={this.state.nbCatalog?.products[product.node.sku] ?? 0} 
                                                                    // nbImageTotal={this.state.nbImageTotal[truncAttrGroupId]} 
                                                                    orders={order.node} 
                                                                />
                                                            </CardCustom>
                                                        </GridItemList>
                                                    )
                                                })
                                            )
                                            : (this.state.noResult) ?
                                            (<EmptyCard title={"Aucun résultat pour cette recherche"} textButton={hasRights(PRODUCTS, PRODUCTS_PRODUCTS, CREATE) ? "Ajouter un produit" : null} onClick={() => this.goTo(ROUTE_PRODUCTS_ADD)} picto={EmptyProduct} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} mtImg={2} />)
                                            : 
                                            (<EmptyCard title={hasRights(PRODUCTS, PRODUCTS_PRODUCTS, CREATE) ? "Vous n'avez pas encore configuré de produit" : "Vous n'avez pas les droits pour ajouter un produit"} subtitle={hasRights(PRODUCTS, PRODUCTS_PRODUCTS, CREATE) ? "Ajouter un produit en cliquant ci-dessous" : "Faite une demande auprès d'un administrateur"} textButton={hasRights(PRODUCTS, PRODUCTS_PRODUCTS, CREATE) ? "Ajouter un produit" : null} onClick={() => this.goTo(ROUTE_PRODUCTS_ADD)} picto={EmptyProduct} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />)
                                        } */}
                                    </Grid>
                                    <Grid container direction="row" justify="space-between" spacing={2} style={{marginTop: 8, marginBottom: 16}}>
                                        <Grid item xs={12}>
                                            <CardCustom style={{width: "100%", height: "100%"}} cardContentStyle={{height: "100%"}} hovercard={false}>
                                                <Typography variant="h3">Produits commandés</Typography>
                                                <TableContainer style={{marginTop: 16}}>
                                                    <Table aria-label="headerList">
                                                        <TableHead>
                                                            <TableRow>
                                                                {headCells.filter(e => e!== null).map((headCell) => (
                                                                    <TableCellCustom
                                                                        key={headCell.id}
                                                                        align={headCell.image ? 'center' : 'left'}
                                                                        style={{width: `${headCell.style?.width}`}}
                                                                    >
                                                                        {headCell.label}
                                                                    </TableCellCustom>
                                                                ))}
                                                            </TableRow>
                                                        </TableHead>
                                                    </Table>
                                                </TableContainer>
                                                <Grid item xs={12}>
                                                    <TableContainer>
                                                        <Table aria-label="simple table">
                                                            <TableRow key={'rowOrders'}>
                                                            {
                                                                this.state.orderInfo.orderItems.map((orderItem, index) => {
                                                                    let getProductName = orderItem.node.product.productDatas.edges.find((e) => e.node.attribute.identifier === "product_name")
                                                                    return(
                                                                        <>
                                                                            <TableCellCustom>
                                                                                <Typography variant="body1">{orderItem.node.product.sku}</Typography>
                                                                            </TableCellCustom>
                                                                            <TableCellCustom>
                                                                                <Typography variant="body1">{getProductName.node.value}</Typography>
                                                                            </TableCellCustom>
                                                                            <TableCellCustom>
                                                                                <Typography variant="body1">{orderItem.node.qty}</Typography>
                                                                            </TableCellCustom>
                                                                            <TableCellCustom>
                                                                                <Typography variant="body1">{orderItem.node.qtyShipped}</Typography>
                                                                            </TableCellCustom>
                                                                            <TableCellCustom>
                                                                                <Typography variant="body1">{orderItem.node.price} €</Typography>
                                                                            </TableCellCustom>
                                                                            <TableCellCustom>
                                                                                <Typography variant="body1">{orderItem.node.totalPrice} €</Typography>
                                                                            </TableCellCustom>
                                                                        </>
                                                                    //     <Grid item xs={12} key={`ListProduct${index}`} style={{padding: '3px 16px'}}>
                                                                    //         <CardCustom style={{width: "100%", height: "100%",  padding: "0", boxShadow: 'none', borderRadius: 0}} cardContentStyle={{height: "100%"}} contentpadding={'4px 0'} hovercard={false}>
                                                                    //         <ContainerCard>
                                                                    //             <div>{orderItem.node.product.sku}</div>
                                                                    //             <div>{orderItem.node.product.sku}</div>
                                                                    //             <div>{orderItem.node.qty}</div>
                                                                    //             <div>{orderItem.node.qtyShipped}</div>
                                                                    //             <div>{orderItem.node.price}</div>
                                                                    //             <div>{orderItem.node.totalPrice}</div>
                                                                    //         </CardCustom>
                                                                    //     </Grid>
                                                                    )
                                                                })
                                                            }
                                                            </TableRow>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                            </CardCustom>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" justify="space-between" spacing={2} style={{marginTop: 8, marginBottom: 16}}>
                                        <Grid item xs={12}>
                                            <CardCustom style={{width: "100%", height: "100%"}} cardContentStyle={{height: "100%"}} hovercard={false}>
                                                <Typography variant="h3" style={{marginBottom: 8}}>Tracking</Typography>
                                                {
                                                    this.state.orderInfo.orderTrackings.length > 0 ?
                                                        this.state.orderInfo.orderTrackings.map((track) => {
                                                            return(
                                                                <a href={`${track.node.url}`} target="__blank" style={{display: "flex", alignItem:"center"}}><OpenInNewIcon /><span style={{marginLeft: 4}}>{track.node.url}</span></a>
                                                            )
                                                        })
                                                    : <Typography variant="body1">Aucun tracking disponible pour le moment.</Typography>
                                                }
                                            </CardCustom>
                                        </Grid>
                                    </Grid>
                                </>
                            </Grid>
                        </>
                : <PageLoader />
                }
            </div>
        );
    }

    goTo = (route, id) => {
        this.props.history.push({
            pathname : route,
            state: { productId : id }
        });
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
        attributes: state.attributes
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};

export default withApollo(withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(OrderDetails))));
