import PropTypes from "prop-types";
import React, { useState } from "react";
import {MiddleBoxWin, Social} from '../index';
import Pg from '../../../../shared/components/CustomParagraph';

const ResultsWinGame = (props) => {
    const [hover, setHover] = useState(false);
    const [hoverBlock, setHoverBlock] = useState(false);
    const [hoverBlockSocial, setHoverBlockSocial] = useState(false);

    let { 
        preview = false,
        id = null, 
        data = null,
        inputCallback = null,
        update = null,
    } = props;

    let image = data.inputs['backgroundImage'].value;
    let blockSocial = data.blocks.socialBlock;

    return (
        <div class="results" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>        
            <section 
                class="win"
                style={{
                    backgroundColor: data.inputs['backgroundColor'].value,
                    backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                    backgroundPosition: 'bottom center',
                    backgroundSize: '100%',
                    backgroundRepeat: 'no-repeat',
                    position: 'relative',
                }}
                onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
            >
                { !preview && props.toolbarSection }
                <div 
                    className={`welcome-area result_div`}
                    onMouseEnter={() => {setHover(false);setHoverBlock(true)}} 
                    onMouseLeave={() => {setHover(true);setHoverBlock(false)}}
                >
                    <MiddleBoxWin data={data.blocks.middleBox} inputCallback={inputCallback} component={id} update={update} toolbarBlock={props.toolbarBlock} hover={hoverBlock} preview={preview} />
                </div>
                {
                    blockSocial.blocks.shareText.inputs?.value?.value || blockSocial.blocks.social.config.length > 0  ?
                    (
                        <div 
                            style={{position: "relative"}} 
                            onMouseEnter={() => {setHoverBlockSocial(true); setHoverBlock(false); setHover(false);}} 
                            onMouseLeave={() => {setHoverBlockSocial(false); setHover(true);}}
                        >
                            { !preview && hoverBlockSocial && props.toolbarBlockSocial }

                            {
                                blockSocial.inputs.position?.value === 'top' ? 
                                (
                                    <div
                                        style={{
                                            backgroundColor: blockSocial.inputs.backgroundColor.value,
                                            backgroundImage: typeof blockSocial.inputs['backgroundImage'].value === 'string' ? `url(${blockSocial.inputs['backgroundImage'].value})` : blockSocial.inputs['backgroundImage'].value?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${blockSocial.inputs['backgroundImage'].value.filePath})` : 'none',
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                        }}
                                    >
                                        <div className="block_social">
                                            {
                                                blockSocial.blocks.shareText.inputs.value.value ? (
                                                    <div className="social" style={{background: blockSocial.blocks.shareText.inputs.backgroundColor ? blockSocial.blocks.shareText.inputs.backgroundColor.value : null }}>
                                                        <div>
                                                            <Pg data={blockSocial} preview={preview} component={id} block={'shareText'} inputCallback={inputCallback} nopadding={true} style={{height: 'auto'}} />
                                                        </div>
                                                    </div>
                                                ) : null
                                            }
                                        </div>
                                        <div className="block_social">
                                            <Social data={blockSocial} preview={preview} component={id} block={'social'} inputCallback={inputCallback} update={update} />
                                        </div>
                                    </div>
                                ) : blockSocial.inputs.position?.value === "bottom" ? 
                                (
                                    <>
                                        <div 
                                            className="block_social"
                                            style={{
                                                backgroundColor: blockSocial.inputs.backgroundColor.value,
                                                backgroundImage: typeof blockSocial.inputs['backgroundImage'].value === 'string' ? `url(${blockSocial.inputs['backgroundImage'].value})` : blockSocial.inputs['backgroundImage'].value?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${blockSocial.inputs['backgroundImage'].value.filePath})` : 'none',
                                                backgroundPosition: 'center',
                                                backgroundRepeat: 'no-repeat',
                                            }}
                                        >
                                            <Social data={blockSocial} preview={preview} component={id} block={'social'} inputCallback={inputCallback} update={update} />
                                        </div>

                                        <div 
                                            className="block_social"
                                            style={{
                                                backgroundColor: blockSocial.inputs.backgroundColor.value,
                                                backgroundImage: typeof blockSocial.inputs['backgroundImage'].value === 'string' ? `url(${blockSocial.inputs['backgroundImage'].value})` : blockSocial.inputs['backgroundImage'].value?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${blockSocial.inputs['backgroundImage'].value.filePath})` : 'none',
                                                backgroundPosition: 'center',
                                                backgroundRepeat: 'no-repeat',
                                            }}
                                        >
                                            {
                                                blockSocial.blocks.shareText.inputs.value.value ? (
                                                    <div className="social" style={{background: blockSocial.blocks.shareText.inputs.backgroundColor ? blockSocial.blocks.shareText.inputs.backgroundColor.value : null }}>
                                                        <div>
                                                            <Pg data={blockSocial} preview={preview} component={id} block={'shareText'} inputCallback={inputCallback} nopadding={true} style={{height: 'auto'}} />
                                                        </div>
                                                    </div>
                                                ) : null
                                            }
                                        </div>
                                    </>
                                ) :
                                (
                                    <div className="block_social">
                                        {
                                            blockSocial.blocks.shareText.inputs.value.value ? (
                                                <div className="social" style={{background: blockSocial.blocks.shareText.inputs.backgroundColor ? blockSocial.blocks.shareText.inputs.backgroundColor.value : null }}>
                                                    <div>
                                                        <Pg data={blockSocial} preview={preview} component={id} block={'shareText'} inputCallback={inputCallback} nopadding={true} />
                                                    </div>
                                                </div>
                                            ) : null
                                        }
                                    
                                        <Social data={blockSocial} preview={preview} component={id} block={'social'} inputCallback={inputCallback} update={update} />
                                    </div>
                                )
                            }

                        </div>
                    ) : null
                }
            </section>
        </div>
    );
};

ResultsWinGame.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingclassName: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default ResultsWinGame;
