import React from "react";
import Div from '../../../../shared/components/CustomDiv';

export default ({ data, preview, component, block, inputCallback, className }) => {
    var rules = data.blocks[block].config;
    return(
        <div className="regles">
            <div className="list_regles">
                {
                    rules.map((rule, index) =>{
                        return(
                            <div className="regle" onClick={() => inputCallback(component, block)} id={`rule-${index}`} key={`rule-${index}`}>
                                <img className="img_center"
                                    src={
                                        typeof rule.blocks.image.inputs.value.value === 'string' && rule.blocks.image.inputs.value.value !== '' ? 
                                            rule.blocks.image.inputs.value.value : 
                                            rule.blocks.image.inputs.value?.value?.filePath ? 
                                                ` ${process.env.REACT_APP_MEDIAS}/${rule.blocks.image.inputs.value.value.filePath}` 
                                                : `http://via.placeholder.com/120x220/FFFFFF/53839D?text=${index+1}`
                                        }
                                    alt={`Règle ${index+1}`}
                                />
                                <div className="description">
                                    <Div data={rule} preview={preview} component={rule.blocks.text.id} block={'text'} inputCallback={() => inputCallback(component, block)} nopadding={true} />
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
};