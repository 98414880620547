import React from 'react';
import { withRouter } from 'react-router';
import {Grid} from '@material-ui/core'
import { connect } from "react-redux";
import styled from 'styled-components';
import colors from '../../../../config/theme/colors';
import TopPanel from '../../../layouts/TopPanel/TopPanel'
import Typography from '../../../ui/typography/Typography';
import { toggleExpandedForAll } from 'react-sortable-tree';
import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import TreeView from '../../../ui/tree-view/TreeView'
import { withApollo } from 'react-apollo';
import { GET_MEDIA_CATEGORIES, ADD_MEDIA_CATEGORY,UPDATE_MEDIA_CATEGORY,DELETE_MEDIA_CATEGORY} from '../../../../queries/mediaCategories';
import LayoutFormBuilder from '../../../ui/form/LayoutFormBuilder';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_SUCCESS, ALERT_ERROR } from '../../../../js/constants/alert-types';
import mediasCategoriesConfig from './config/mediasCategories.config'
import DialogModal from '../../../ui/dialog/DialogModal';
import {ALLOWED,VIDEOS,IMAGES} from '../../../../js/constants/medias-types';
import { ROUTE_HOME } from '../../../../js/constants/route-names';
import { hasRights } from '../../../../js/utils/rights';
import { MEDIAS, MEDIAS_CATEGORIES, VIEW, CREATE, DELETE, UPDATE } from '../../../../js/constants/constant-rights';

const MediaContainer=styled(Grid)`
    background-color:white;
    display: flex;
    flex-wrap: wrap;
    margin-top:20px;
    &>.MuiGrid-item{
        height: 65vh;
        overflow: auto;
    }
`;

class MediasCategories extends React.Component {
    
    constructor(props){
        super(props)
        this.state = {
            openForm        : props.history.location.state?.openForm ? props.history.location.state.openForm : false,
            openMediaForm   : props.history.location.state?.openMediaForm ? props.history.location.state.openMediaForm : false,
            dataLayout      : null,
            identifier      : '',
            parent          : [],
            parentCat       : '',
            formImage       : null,  
            fileName        : null,
            fileAlt         : 'Image',
            treeCats        : [],
            medias          : null,
            /*allMedias       : null,*/
            filterByType    : props.history.location.state?.types ? props.history.location.state.types : ALLOWED,
            openModal       : false,
            buttonAvailable : props.history.location.state?.buttonAvailable ? false : true,
            toDelete        : null,
            openDeleteModal : false,
            selectedNodes   : [],
            formAction      : 'add',
            currentId       : '',
            openDeleteCatModal : false,
            loading         : false,
            page            : 1,
            nbperpage       : 8,
            countPage       : 0,
            cursor          : null,
            
        };
        this.cats=[
            {
                libelle:'All',
                type:'all',
            },
            {
                libelle:'Images',
                type:IMAGES,
            },
            {
                libelle:'Vidéos',
                type:VIDEOS,
            },
            /*{
                libelle:'360°',
                type:[],
            },
            {
                libelle:'Audio',
                type:[],
            }*/
        ]

    }
    

    /*UTILS*/

    copyArrayOfObjects = array => array.map(a => ({...a}));

    resetState() {
        this.setState({
            cat_id: '',
            identifier: '',
            parentCat: '',
            parent:[],
            action:'',
            formAction:'',
            toDelete:null,
            fileName:'',
            fileAlt:''
        });
    }

    handleDeleteCatModal =()=>{
        this.setState({
            openDeleteCatModal : !this.state.openDeleteCatModal
        })
    }

    /*GET CATEGORIES + TREE*/

    prepareTree(){
        this.props.client.query({
            query: GET_MEDIA_CATEGORIES,
            fetchPolicy: 'no-cache'
        }).then(result=>{
            let cats  = result.data.mediaObjectCategories.edges;
            // let tree  = [];
            let data  = cats.filter(e => e.node.parent === null);

            
            
            this.setState({ categoriesData: cats,defaultRoot:data });
            
            if (this.props.history.location?.state?.formOpen){
                this.addCategory()
            }

            for(let parent of data){
                this.convertToNode(parent,true);
                this.populateChildren(cats,parent);
            }    

            /*root.children=data;
            tree=tree.concat(root);*/



            this.setState({ 
                treeData: this.copyArrayOfObjects(data) ,
                treeCats: this.copyArrayOfObjects(data) 
            });
            
        })
    }

    convertToNode(data, isRoot=false){
        data.title          = data.node.libelle;
        data.isDirectory    = true;
        data.isRoot         = isRoot;
        data.dragDisabled   = true;
        data.expanded       = true;
        data.id             = data.node.id;
        data.libelle        = data.node.libelle;
        data.parent         = data.node.parent;
    }

    populateChildren(cats, parent) {
        parent.children = cats.filter(e => e.node.parent !== null && e.node.parent.id === parent.node.id);
        
        for (let child of parent.children) {
            this.convertToNode(child);
            this.populateChildren(cats, child);
        }
    }



    /*LAYOUT FORM*/

    handleToggleDrawer(form){
        if(form==='addMediaCatForm'){
            this.setState({ 
                openForm        : !this.state.openForm,
                buttonAvailable : !this.state.buttonAvailable

            });            
        }

    }

    handleInputChange = (stateName, evt) => {
        const value = evt?.target?.value ?? evt;
        
        this.setState({
            ...this.state,
            [stateName]: value
        });
    };



    addCategory = () => {
        this.resetState();
        let root = this.state.categoriesData.find(cat => cat.node.parent===null)

        this.setState({
            parentCat: root.node.id
        }, () => this.handleToggleDrawer('addMediaCatForm'));
    }

    editCategory =(nodeInfo)=>{
        
        this.resetState();
        
        this.setState({
            formAction  : 'edit',
            parentCat   : nodeInfo.node.parent?.id,
            identifier  : nodeInfo.node.libelle,
            currentId   : nodeInfo.node.id
            
        },()=>{
            this.handleToggleDrawer('addMediaCatForm');
        })
    }

    addSubcategory = (nodeInfo) => {
        this.resetState();

        this.handleToggleDrawer('addMediaCatForm');
        this.setState({ 
            
            parentCat: nodeInfo.id,
            dataLayout:mediasCategoriesConfig(this.state.categoriesData,nodeInfo.id)
        });
        

    };



    /*MUTATION*/
    handleError = (e) => {
        this.props.snack(ALERT_ERROR, 'Une erreur est survenue');

        this.props.stopLoading();

        if (e.graphQLErrors) {
            for (let error of e.graphQLErrors) {
                console.error('ERROR', `${error.message} =>`, error.debugMessage);
            }
        }
    };

    handleSuccess = async (action) => {
        await this.prepareTree();
        if (action === 'edit')
            this.props.snack(ALERT_SUCCESS, 'Catégorie modifiée !');

        if (action === 'add')
            this.props.snack(ALERT_SUCCESS, 'Catégorie ajoutée !');

        this.handleToggleDrawer('addMediaCatForm');
        if(action === 'delete'){
            this.handleDeleteCatModal();
            this.props.snack(ALERT_SUCCESS, 'Catégorie supprimée !');
        }
        this.resetState();
        this.props.stopLoading();
    };

    handlerMutation = async (action='add') => {
        try {
            let query       = null;
            let variables   = null;
    
            this.props.startLoading();
            
            if(action === 'edit'){
                query = UPDATE_MEDIA_CATEGORY;

                variables = { 
                    libelle: this.state.identifier, 
                    parent: this.state.parentCat,
                    id:this.state.currentId
                };                  
            }
            else if(action === 'delete'){
                query = DELETE_MEDIA_CATEGORY;

                variables = { 
                    id:this.state.currentId
                };
            }
            else{
                query = ADD_MEDIA_CATEGORY;
                
                variables = { 
                    libelle: this.state.identifier, 
                    parent: this.state.parentCat !== 'root'&&this.state.parentCat !== ''?this.state.parentCat:null
                };    
            }

            await this.props.client.mutate({
                mutation: query,
                variables,
                refetchQueries: [{
                    query: GET_MEDIA_CATEGORIES,
                }]
            });

            

            this.handleSuccess(action);
        } catch(e) {
            this.handleError(e);
        }
    };
    expand = (expanded) => {
        this.setState({
            treeData: toggleExpandedForAll({
                treeData: this.state.treeData,
                expanded,
            }),
        });
    };

    async preparePage() {
        this.prepareTree();
        this.setState({ ready: true });
    }
    /*COMPONENTS*/

    componentDidMount(){
        const getRights = hasRights(MEDIAS, MEDIAS_CATEGORIES, VIEW)
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }else{
            this.preparePage();
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.formImage !== this.state.formImage) {
        
        let name=this.state.formImage?.file?.name.replace(/ /g, "_").toLowerCase();
            this.setState({
                fileName : name
            })
        }
    }

    render() {
        return (
            <div>

                <TopPanel 
                    icomoon         = "picto-media" 
                    colorIcomoon    = {colors.blue.lighter.hue300} 
                    title           = "Gérer les médias" subtitle="Gestion de vos médias (création / modification / suppression)" 
                    gradientColor1  = {colors.menu.regular} 
                    gradientColor2  = {colors.menu.darker}  
                    handlerAdd      = {() => {this.addCategory()}} 
                    textAdd         = {hasRights(MEDIAS, MEDIAS_CATEGORIES, CREATE) ? "Créer un répertoire" : null}
                    buttonAvailable = {this.state.treeData ? this.state.buttonAvailable : false}
                    hasBorder={true}
                />
                
                {this.state.treeData ? 
                    <div>
                        <MediaContainer container>
                            <Grid item xs={12}>

                                <TreeView
                                    typeOfTree              = {'categorie'}
                                    dataTree                = {this.state.treeData} 
                                    onChange                = {treeData => this.setState({ treeData })}
                                    canDrag                 = {false}
                                    canDrop                 = {false}
                                    addSubcategory          = {this.addSubcategory} 
                                    expand                  = {this.expand} 
                                    handleChangeCategorie   = {this.handleChangeCategorie}
                                    editCat                 = {this.editCategory}
                                    marginTop               = {true}
                                    allButton               = {() => {
                                                                this.setState({
                                                                    filterByCategory : null,
                                                                    selectedNodeId: null
                                                                }); 
                                                                this.filterByType(this.state.filterByType)}
                                                            }
                                    canModify={hasRights(MEDIAS, MEDIAS_CATEGORIES, UPDATE)}
                                    canAdd={hasRights(MEDIAS, MEDIAS_CATEGORIES, CREATE)}
                                />

                            </Grid>

                        </MediaContainer> 

                        {this.state.openForm ? 

                            <LayoutFormBuilder
                                icomoon         = "ico-ajouter-categorie"
                                opened          = {this.state.openForm} 
                                forClose        = {() => {this.handleToggleDrawer('addMediaCatForm')}}
                                dataLayout      = {mediasCategoriesConfig(this.copyArrayOfObjects(this.state.categoriesData), this.state.parentCat,this.state.formAction)}
                                allState        = {this.state} 
                                stateCallback   = {this.handleInputChange}
                                handlerMutation = {this.state.formAction === 'edit'?()=>{this.handlerMutation('edit')}:()=>{this.handlerMutation('add')}}
                                validateButton  = {true}
                                deleteMutation  = {this.state.formAction === 'edit' && hasRights(MEDIAS, MEDIAS_CATEGORIES, DELETE) ? this.handleDeleteCatModal : null}
                                deleteText      = {this.state.formAction === 'edit' ? 'Supprimer la catégorie' : null}
                            />

                        : null}
                    </div>
                    
                    
                : <PageLoader/>}

                <DialogModal
                    open            = {this.state.openDeleteCatModal}
                    secondaryAction = {()=>{this.handleDeleteCatModal()}}
                    primaryAction   = {()=>{this.handlerMutation('delete')}}
                    title           = 'Supprimer cette catégorie' 
                >
                    <Typography variant="body2">Êtes-vous sûr de vouloir supprimer cette catégorie, ses sous-catégories et ses médias ? <strong>Cette action est irréversible</strong>></Typography>
                </DialogModal>
            </div>
        );
    }

    copyArrayOfObjects = array => array.map(a => ({...a})); // be careful, only breaks references at objects level

    goTo = route => {
        this.props.history.push(route);
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};
export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(MediasCategories)));
