import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
// import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import FolderIcon from '@material-ui/icons/Folder';
import Button from '../../../../ui/button/Button';
import Chip from '@material-ui/core/Chip';
import colors from '../../../../../config/theme/colors'
import styled from 'styled-components';
import pngBackground from '../../../../../assets/images/medias/pixelBackground.jpg'
import GetAppIcon from '@material-ui/icons/GetApp';
import WarningIcon from '@material-ui/icons/Warning';
import ShortTextIcon from '@material-ui/icons/ShortText';
import LabelIcon from '@material-ui/icons/Label';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import { getFileConfig } from '../../../../../js/helpers/files';


const ImageContainer = styled(Grid)`
    max-height: 800px;
    overflow:auto;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 1000px){
        max-height: 400px !important;
        img,video{
            max-height: 400px !important;
        }
    }
    img,video{
        background-image: ${props => props.imagetype === 'PNG' ? 'url(' + pngBackground + ')' : 'none'};
        background-size: cover;
        width: auto;
        max-width: 100%;
        max-height: 100%;
        box-shadow: 1px 1px 10px -3px ${colors.grey.lighter.hue800};
    }
`;

const CloseModal=styled(CloseIcon)`
    position : absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    transform: scale(1);
    transition: all .5s;

    &:hover{
        transform: scale(1.1);
    }
`;

const MediaOption=styled(Grid)``;

function getMediaType(type){
    if(type){
        var res=type.match(/\.[0-9a-z]+$/i)[0].replace('.', '').toUpperCase();
        return res;
    }
}

export default function MediaModal(props) {
    let modalData       = props.modalData; 
    let size            = modalData.size;
    // let currentIndex    = modalData.index;
    let round           = 0; 
    let isVideo         = false;
    if(['video/mpeg','video/webm','video/mp4'].includes(modalData.type)){
        isVideo=true;
    }
    let mimeType        = modalData.type;
    let type        = {
        value: getMediaType(modalData.filePath),
        style: {
            background: getFileConfig(modalData.type).color,
            color: 'white',
            fontWeight: 'bold',
        }
    }
    let convertSize;
    let unit;

    if(size >= 1048576){
        convertSize = size/1048576;
        unit        = 'Mo';
        round       = 1;
    }
    else if(size >= 1024){
        convertSize = size/1024;
        unit        = 'Ko';
    }
    else{
        convertSize = size/1024;
        unit        = 'Ko';
    }

    const useStyles = makeStyles((theme) => ({
        paper: {
            position: 'absolute',
            width: props.windowWidth > 1000 ? '60%' : '80%',
            backgroundColor: theme.palette.background.paper,
            border: '0px solid transparent',
            padding:50,
            top:'50%',
            left:'50%',
            transform:'translate(-50%,-50%)',
            outline:'none',
            minHeight:400
        },
        navContainer: {
            top:'50%',
            left:'50%',
            transform:'translate(-50%,-50%)',
            position:'absolute',
            width:'100%',
            fontSize:70,
            color:'white',  
        },
    
        arrow : {
            position:'absolute',
            cursor:'pointer',
            transform : 'scale(1)',
            transition : 'all .5s',
            "&:hover" : {
                transform : 'scale(1.1)',
            }
        },
    
        prevArrow : {
            left : -100,
        },
    
        nextArrow : {
            right : -100,
        },
        chip : {
            marginRight:8,
            marginTop:8,
            height:25,
            fontSize:10
    
        }
        
    }));

    convertSize=convertSize.toFixed(round);
    const classes   = useStyles();
    return (
        <Modal
            open                = {props.open}
            onClose             = {props.onClose}
            aria-labelledby     = "simple-modal-title"
            aria-describedby    = "simple-modal-description"
            style               = {{border:'0px',outline:'none'}}
            BackdropComponent   = {Backdrop}
            BackdropProps       = {{timeout: 500}}
        >
            <Fade in={props.open}>
                <div className={classes.paper}>
                    <CloseModal onClick={props.onClose} />
                    {/* <Grid item xs={12} className={classes.navContainer}>
                        {currentIndex!=0 ? 
                            <ArrowBackIosIcon 
                                fontSize    = {'inherit'} 
                                color       = {'inherit'} 
                                className   = {`${classes.arrow} ${classes.prevArrow}`}
                                onClick     = {()=>{props.handleNav('prev',currentIndex)}}
                            />
                        :null}
                        {currentIndex<props.lastMedia ? 
                            <ArrowForwardIosIcon 
                                fontSize    = {'inherit'} 
                                color       = {'inherit'} 
                                className   = {`${classes.arrow} ${classes.nextArrow}`}
                                onClick     = {()=>{props.handleNav('next',currentIndex)}}
                            />
                        :null}                        
                    </Grid> */}
                    <Grid container spacing={4} justify={props.windowWidth > 1000 ? null : "center"}>
                        <ImageContainer item xs={props.windowWidth > 1000 ? 7 : 12} imagetype={type.value}>
                            {isVideo ?
                                <video controls autoPlay>
                                    <source src={`${process.env.REACT_APP_API_ROOT}/medias/${modalData.filePath }`} type={mimeType}/>
                                    Sorry, your browser doesn't support embedded videos.
                                </video>
                                :
                                <img src={getFileConfig(modalData.type).image ? getFileConfig(modalData.type).image : `${process.env.REACT_APP_API_ROOT}/medias/${modalData.filePath }`} style={{padding: getFileConfig(modalData.type).image ? 40 : null}}/>
                            }
                            
                        </ImageContainer>
                        <Grid item xs={props.windowWidth > 1000 ? 5 : 12}>
                            <Typography variant="h3" style={{overflowWrap:'break-word'}}>{modalData.name ? modalData.name : modalData.filePath  }</Typography>
                            <Grid container alignItems="center" style={{marginTop:10}}>
                                <Chip label={type.value} style={type.style} />
                                <Typography variant="body2" style={{marginLeft:15,display:'inline-block'}}>{convertSize} {unit}</Typography> 
                            </Grid>
                            
                            <Typography variant="h4" style={{display:'flex',alignItems:'center',marginTop:40}}>
                                <FolderIcon size="inherit" style={{marginRight:4}}/> 
                                Catégories
                            </Typography> 
                            
                            {
                                modalData.category?.edges?.length>0 ?
                                    modalData.category.edges.map((category,index)=>(
                                        <Chip label={category.node.libelle} style={{backgroundColor:colors.blue.lighter.hue600}} className={classes.chip} key={`category-${index}`}/>
                                    ))
                                : 
                                    <Typography variant="body2">Vous n'avez pas définis de catégories</Typography>
                            }

                            <Typography variant="h4" style={{marginTop:20,display:'flex',alignItems:'center'}}>
                                <LabelIcon style={{marginRight:4}}/>Tags
                            </Typography>

                            {
                                modalData.mediaObjectTags?.edges?.length > 0 ? 
                                    modalData.mediaObjectTags?.edges.map((tag,index)=>(
                                        <Chip label={tag.node.tag} className={classes.chip} key={`tag-${index}`}/>
                                    ))                      
                            :
                                <Typography variant="body2">Vous n'avez pas définis de tags pour cette image</Typography>
                            }

                            <Typography variant="h4" style={{marginTop:20,display:'flex',alignItems:'center'}}>
                                <ShortTextIcon style={{marginRight:4}}/>Texte Alternatif 
                            </Typography>
                            <Typography variant="body2" style={{fontStyle:'italic'}}>
                                {modalData.alt 
                                    ? 
                                    modalData.alt
                                    :
                                    <Tooltip title="Vous n'avez pas définis de texte alternatif pour cette image" placement="right" arrow>
                                        <WarningIcon style={{color:colors.red.regular}}/>
                                    </Tooltip>
                                }
                            </Typography>
                            <Typography variant="h4" style={{marginTop:20,display:'flex',alignItems:'center'}}>
                                <TextFieldsIcon style={{marginRight:4}}/>Description
                            </Typography>
                            <Typography variant="body2" style={{fontStyle:'italic'}}>{modalData.description}</Typography>
                        </Grid>
                        <MediaOption container justify="flex-end">
                            <Button 
                                variant         = "contained" 
                                bgcolor         = {colors.white} 
                                bgcolorhover    = {colors.blue.regular}
                                color           = {colors.blue.regular}
                                colorhover      = {colors.white}
                                border          = {`1px solid ${colors.blue.regular}`}
                                onClick         = {()=>{ window.open(`${process.env.REACT_APP_API_ROOT}/medias/${modalData.filePath }`, '_blank')}}
                                text            = {<GetAppIcon/>}
                            />
                            {props.readOnly === true ?
                                null
                            :
                                <Button 
                                    variant         = "contained" 
                                    bgcolor         = "#a62626" 
                                    bgcolorhover    = "#c24848" 
                                    style           = {{marginLeft:20}} 
                                    onClick         = {()=>props.handleDeleteModal(modalData.id)}
                                    text            = 'Supprimer'
                                />                                   
                            }
                        
                        </MediaOption>
                    </Grid>
                </div>
            </Fade>
        </Modal>
    );
}
