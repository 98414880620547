import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ItemTypes } from '../shareable/types';

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: '100%'
    }
});

export default ({ config, params, getComponent, preview, Droppable }) => {
    const classes = useStyles();

    if (!config) return null;

    return (
        <div className={classes.root}>
            { Droppable && <Droppable type={ItemTypes.ALL} id={'first-item'} index={-1} /> }
            
            { config?.elements.map(getComponent) }
        </div>
    );
};