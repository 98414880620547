import React from 'react';
import { Grid } from '@material-ui/core';
import CardMinisite from './CardMinisite';
import CardFeeds from './CardFeed';

export default class CardByAssetType extends React.Component{

    renderSwitch(param){
        switch(param.assetType.identifier){
            case 'flipbook':
            case 'newsletter':
            case 'minisite':
            case 'jeu':
                return <CardMinisite data={this.props.asset} />
            case 'facebook':
                return <CardFeeds data={this.props.asset} handleAdd={this.props.handleAdd} posts={this.props.posts ? this.props.posts : null} handleDelete={this.props.handleDelete}/>
            case 'twitter':
                return <CardFeeds data={this.props.asset} handleAdd={this.props.handleAdd} posts={this.props.posts ? this.props.posts : null} textAdd={'Ajouter un Tweet'} handleDelete={this.props.handleDelete}/>
            case 'wordpress':
                return <CardFeeds data={this.props.asset} handleAdd={this.props.handleAdd} posts={this.props.posts ? this.props.posts : null} textAdd={'Ajouter un post'} handleDelete={this.props.handleDelete}/>
            default:
                return null;
        }
    }
    render() {
        return (
            <Grid container>
                <Grid container direction="row" justify="center">
                    {this.renderSwitch(this.props.asset)}
 
                </Grid>                
            </Grid>

        );
    }
}