import React from 'react';
import { Grid } from '@material-ui/core';
import Typography from '../../../ui/typography/Typography';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import CardCustom from '../CardCustom';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router';
import BlockMinisite from '../../../ui/asset/minisite/BlockMinisite';
import { GET_MINISITE_PAGES } from '../../../../queries/asset_minisites';
import { GET_GAME_PAGES } from '../../../../queries/asset_games';
import { ROUTE_DIFFUSION_PERMANENTS_PAGE } from '../../../../js/constants/route-names';

const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem;
`;

const SpanColor = styled.span`
    color: ${colors.blue.lighter.hue300};
    font-weight: bold;
`;

class CardMinisite extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            minisitePageTypes: [],
            gamePageTypes: [],
            data: this.props.data,
            assetType: '',
        };
    }

    componentDidMount() {
        this.props.data.assetType.identifier === 'jeu'
            ? this.handleGetAttributesAssetGame()
            : this.handleGetAssetPages();
    }

    handleGetAssetPages() {
        this.props.client.query({
            query: GET_MINISITE_PAGES,
            variables: {asset : this.props.data.id}
        }).then(result =>{
            let totalPageNotDone = 0;

            for (let item of result.data.assetMinisitePages.edges) {
                if (item.node.status === '0') {
                    totalPageNotDone++ 
                }
            }

            this.setState({
                minisitePageTypes: result.data.assetMinisitePages.edges,
                totalPageNotDone: totalPageNotDone,
                assetType: 'minisite',
            });
        });
    }

    handleGetAttributesAssetGame() {
        this.props.client.query({
            query: GET_GAME_PAGES,
            variables: { asset: this.props.data.id }
        }).then(result => {
            let totalPageNotDone = 0;

            for (let item of result.data.assetGamePages.edges){
                if (item.status === 0 || 1){
                    totalPageNotDone++
                }
            }

            this.setState({
                minisitePageTypes : result.data.assetGamePages.edges,
                totalPageNotDone  : totalPageNotDone,
                assetType: 'jeu',
            });
        });
    }

    goTo = (route, state) => {
        this.props.history.push({
            pathname : route,
            state: state
        });
    };

    render() {
        return (
            <Grid container>
                <Grid container direction="row" justify="center">
                    <GridCustom item xs={12}>
                        <CardCustom paddingbottom={0} paddingtop={0} style={{width: "100%"}} contentpadding={'16px 32px'}>
                            <Grid container justify="center">
                                <Grid item xs={12} style={{marginBottom:50, marginTop: 20}}>
                                    <Typography variant="h3">
                                        Configurateur de {this.props.data.assetType.identifier === 'jeu' ? 'jeu' : 'minisite'}
                                    </Typography>
                                    {
                                            this.state.totalPageNotDone > 0 ?
                                                <Typography variant="body2" style={{lineHeight: '25px'}}>
                                                    Plus que <SpanColor>{this.state.totalPageNotDone} {this.state.totalPageNotDone > 0 ? 'pages' : 'page'}</SpanColor> à configurer avant de pouvoir mettre en ligne votre {this.state.assetType}.
                                                </Typography>
                                            :
                                                <Typography variant="body2" style={{lineHeight: '25px'}}>
                                                    <SpanColor>Toutes les pages</SpanColor> de votre {this.state.assetType} sont configurées.
                                                </Typography>
                                    }
                                </Grid>
                                <Grid container style={{marginBottom:30}} spacing={4}>
                                    {
                                        this.state.minisitePageTypes.length > 0 ? 
                                            this.state.minisitePageTypes.map((page, index) => {
                                                return (
                                                    <BlockMinisite 
                                                        key={`BlockMinisite${index}`}
                                                        onClick={() => {this.goTo(ROUTE_DIFFUSION_PERMANENTS_PAGE
                                                            .replace(':id', this.props.data.identifier)
                                                            .replace(':page', page.node.assetGamePageType ? page.node.assetGamePageType.identifier : page.node.assetMinisitePageType.identifier), 
                                                            { 
                                                                minisitePageTypes: this.state.minisitePageTypes, 
                                                                minisite: page, 
                                                                asset: this.state.data
                                                            }
                                                        )}} 
                                                        data={page}
                                                        type={this.state.data.assetType.identifier}
                                                    />
                                                )   
                                            })
                                        : null
                                    }                             
                                </Grid>
                            </Grid>
                        </CardCustom>
                    </GridCustom>
                </Grid>                
            </Grid>
        );
    }
}
export default withApollo(withRouter(CardMinisite));