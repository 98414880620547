import { gql } from 'apollo-boost';

//----- Récupération des catégories
export const GET_CATEGORIES = gql`
{
  categories{
    id
    libelle
    status
    parent {
        id
    }
    master{
      id
      libelle
    }
    catalog{
      id
      identifier
    }
    products{
      edges{
        node{
          id
          sku
          status
          attributeGroup {
            id
            identifier
          }
          categories{
            id
            libelle
          }
          productDatas{
            edges{
              node{
                id
                value
                attributeOption{
                  id
                  identifier
                }
                attribute{
                  identifier
                  isSystem
                }
                locale {
                  code
                }
                media{
                  id
                  filePath
                  type
                  size
                  category{
                    edges{
                      node{
                        id
                        libelle
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    
    categoryDatas{
      edges{
        node{
          id
          value
          attribute{
            id
            identifier
            attributeType {
              id
              input
            }
          }
          media{
            id
            filePath
            type
            size
            category{
              edges{
                node{
                  id
                  libelle
                }
              }
            }
          }
          locale{
            id
            code
          }
        }
      }
    }
  } 
}
`;

export const GET_CATEGORIES_LIGHT = gql`
{
  categories{
    id
    libelle
    status
    parent {
        id
    }
    master{
      id
      libelle
    }
    products{
      totalCount
    }
    catalog{
      id
      identifier
    }
    categoryDatas{
      edges{
        node{
          id
          value
          attribute{
            id
            identifier
            attributeType {
              id
              input
            }
          }
          media{
            id
            filePath
            type
            size
            category{
              edges{
                node{
                  id
                  libelle
                }
              }
            }
          }
          locale{
            id
            code
          }
        }
      }
    }
  } 
}
`;

export const GET_CATEGORIES_BY_ID_LIST_LIGHT = gql`
query categoriesByIdList($idList: [Int]) {
  categories(id_list: $idList){
    id
    libelle
    status
    parent {
        id
    }
    master{
      id
      libelle
    }
    products{
      totalCount
    }
    catalog{
      id
      identifier
    }
    categoryDatas{
      edges{
        node{
          id
          value
          attribute{
            id
            identifier
            attributeType {
              id
              input
            }
          }
          media{
            id
            filePath
            type
            size
            category{
              edges{
                node{
                  id
                  libelle
                }
              }
            }
          }
          locale{
            id
            code
          }
        }
      }
    }
  } 
}
`;

export const SEARCH_CATEGORIES = gql`
query categoryDataSearch($attribute: String, $needle: String) {
  categoryDatas(attribute: $attribute, value: $needle) {
    edges {
      node {
        id
        value
        category {
          id
          libelle
          status
          parent {
            id
          }
          master {
            id
            libelle
          }
          catalog {
            id
            identifier
          }
          products {
            edges {
              node {
                id
                sku
                attributeGroup {
                  id
                  identifier
                }
                categories {
                  id
                  libelle
                }
                productDatas {
                  edges {
                    node {
                      id
                      value
                      attributeOption {
                        id
                        identifier
                      }
                      attribute {
                        identifier
                        isSystem
                      }
                      locale {
                        code
                      }
                      media {
                        id
                        filePath
                        type
                        size
                        category {
                          edges {
                            node {
                              id
                              libelle
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          categoryDatas {
            edges {
              node {
                id
                value
                attribute {
                  id
                  identifier
                  attributeType {
                    id
                    input
                  }
                }
                media {
                  id
                  filePath
                  type
                  size
                  category {
                    edges {
                      node {
                        id
                        libelle
                      }
                    }
                  }
                }
                locale {
                  id
                  code
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const GET_CATEGORIES_BY_IDLIST = gql`
query category($id: ID!){
  category(id: $id){
    id
    libelle
    status
    parent {
        id
    }
    master{
      id
      libelle
    }
    catalog{
      id
      identifier
    }
    products{
      totalCount
      edges{
        node{
          id
          sku
          status
          attributeGroup {
            id
            identifier
          }
          categories{
            id
            libelle
          }
          productDatas{
            edges{
              node{
                id
                value
                attributeOption{
                  id
                  identifier
                }
                attribute{
                  identifier
                  isSystem
                }
                locale {
                  code
                }
                media{
                  id
                  filePath
                  type
                  size
                  category{
                    edges{
                      node{
                        id
                        libelle
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    
    categoryDatas{
      edges{
        node{
          id
          value
          attribute{
            id
            identifier
            attributeType {
              id
              input
            }
          }
          media{
            id
            filePath
            type
            size
            category{
              edges{
                node{
                  id
                  libelle
                }
              }
            }
          }
          locale{
            id
            code
          }
        }
      }
    }
  } 
}
`;

//----- Récupération des catégories sans les produits
export const GET_CATEGORIES_ONLY = gql`
{
  categories {
    id
    libelle
    status
    parent {
        id
    }
    master{
      id
      libelle
    }
    catalog{
      id
      identifier
    }
    categoryDatas{
      edges{
        node{
          id
          value
          attribute{
            id
            identifier
            attributeType {
              id
              input
            }
          }
          media{
            id
            filePath
            type
            size
            category{
              edges{
                node{
                  id
                  libelle
                }
              }
            }
          }
          locale{
            id
            code
          }
        }
      }
    }
  }
}
`;


//----- Récupération des données d'une catégorie
/*
 Exemple de variables
  {
    "id" : "/api/categories/5"
  }
*/
export const GET_CATEGORY_DATA = gql`
query categoryDatas($id: ID!) {
  category(id: $id) {
    id
    libelle
    categoryDatas{
      edges{
        node{
          id
          value
          attribute{
            id
            identifier
          }
          media{
            id
            filePath
            type
            size
            category{
              edges{
                node{
                  id
                  libelle
                }
              }
            }
          }
          locale{
            id
            code
          }
        }
      }
    }
  }
}
`;

//----- Ajouter une catégorie
/*
 Exemple de variables
  {
    "libelle" : "Cat de démo",
    "parent" : "/api/categories/1"
  }
*/
export const ADD_CATEGORY = gql`
mutation AddCategory($libelle: String!, $parent: String, $catalog: String, $master: String, $products: [String], $status: Boolean){
	createCategory(input: {libelle:$libelle, parent:$parent, catalog:$catalog, master:$master, products:$products, status: $status}){
    category{
      id
      libelle
      status
      master{
        id
        libelle
      }
      catalog{
        id
        identifier
      }
      parent {
        id
      }
    }
  }  
}`;

//----- Modifier une catégorie
/*
 Exemple de variables
  {
    "id" : "/api/categories/5",
    "libelle" : "libelle edited",
    "parent" : "/api/categories/1"
  }
*/
export const UPDATE_CATEGORY = gql`
mutation UpdateCategory($id: ID!, $libelle: String, $parent: String, $catalog: String, $master: String, $products: [String], $status: Boolean){
	updateCategory(input: {id:$id, libelle:$libelle, parent:$parent, catalog:$catalog, master:$master, products:$products, status: $status}){
    category{
      id
      libelle
      status
      master{
        id
        libelle
      }
      catalog{
        id
        identifier
      }
      parent {
        id
      }
    }
  }  
}`;

//----- Supprimer une catégorie
/*
 Exemple de variables
  {
    "id" : "/api/categories/1"
  }
*/
export const DELETE_CATEGORY = gql`
mutation DeleteCategory($id: ID!){
	deleteCategory(input: {id:$id}){
    category{
      id
    }
  }  
}`;



//--------------------
//----- DONNEES CATEGORIES 
//--------------------

//----- Ajouter une valeur d'attribut à une catégorie

/*
 Exemple de variables
 {
  "value": "Catégorie 1 de test",
	"category": "/api/categories/5",
  "attribute": "/api/attributes/21", 
	"locale": "/api/locales/2"
}
*/
export const ADD_CATEGORY_DATA = gql`
mutation AddCategoryData($value: String, $category: String!, $attribute: String!, $locale: String!, $media: String){
	createCategoryData(input: {value:$value, category:$category, attribute:$attribute, locale:$locale, media: $media}){
    categoryData{
      id
      value
      category{
        id
      }
      attribute{
        id
      }
      media{
        id
      }
      locale{
        id
      }
    }
  }  
}`;



//----- Modifier la valeur d'attribut d'une catégorie

/*
 Exemple de variables
 {
  "id": "/api/category-datas/1",
  "value": "Catégorie 1 de test edited",
	"category": "/api/categories/5",
  "attribute": "/api/attributes/21", 
	"locale": "/api/locales/2"
}
*/
export const UPDATE_CATEGORY_DATA = gql`
mutation UpdateCategoryData($id: ID!, $value: String, $category: String!, $attribute: String!, $locale: String!, $media: String){
	updateCategoryData(input: {id:$id, value:$value, category:$category, attribute:$attribute, locale:$locale, media:$media}){
    categoryData{
      id
      value
      category{
        id
      }
      attribute{
        id
      }
      media{
        id
      }
      locale{
        id
      }
    }
  }  
}`;


//----- Supprimer une valeur d'attribut d'une catégorie
/*
 Exemple de variables
  {
    "id" : "/api/category-datas/68"
  }
*/
export const DELETE_CATEGORY_DATA = gql`
mutation DeleteCategoryData($id: ID!){
	deleteCategoryData(input: {id:$id}){
    categoryData{
      id
    }
  }  
}`;