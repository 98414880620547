import React from 'react';
import { withRouter } from 'react-router';
import {Grid} from '@material-ui/core'
import { connect } from "react-redux";
import styled from 'styled-components';
import colors from '../../../../config/theme/colors';
import TopPanel from '../../../layouts/TopPanel/TopPanel'
import Typography from '../../../ui/typography/Typography';
import TabMedias from './components/TabMedias'
import MediaModal from './components/MediaModal'


import { toggleExpandedForAll } from 'react-sortable-tree';

import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import EmptyCard from "../../../ui/empty-card/EmptyCard";   
import emptyProduct from '../../../../assets/pictos/empty-picto/product.svg';


import TreeView from '../../../ui/tree-view/TreeView'
import CardCustom from '../../../layouts/Card/CardCustom';
import CardMedia from '../../../layouts/Card/cardContent/CardMedia';

import { withApollo } from 'react-apollo';
import { GET_MEDIA_CATEGORIES, ADD_MEDIA_CATEGORY,UPDATE_MEDIA_CATEGORY,DELETE_MEDIA_CATEGORY} from '../../../../queries/mediaCategories';
import { GET_MEDIAS_IMAGES, GET_MEDIAS_CURSOR} from '../../../../queries/medias';
import LayoutFormBuilder from '../../../ui/form/LayoutFormBuilder';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_SUCCESS, ALERT_ERROR } from '../../../../js/constants/alert-types';
import axios from '../../../../js/utils/axios';

import mediasCategoriesConfig from './config/mediasCategories.config'
import mediasConfig from './config/medias.config'
import mediasEditConfig from './config/mediasEdit.config'

import DialogModal from '../../../ui/dialog/DialogModal';
import {ALLOWED,VIDEOS,IMAGES} from '../../../../js/constants/medias-types';
import Pagination from '@material-ui/lab/Pagination';
import { hasRights } from '../../../../js/utils/rights';
import { ROUTE_HOME } from '../../../../js/constants/route-names';
import { CRM_MEDIAS, CRM_MEDIAS_DAM, CREATE, UPDATE, DELETE, VIEW} from '../../../../js/constants/constant-rights';

const MediaContainer=styled(Grid)`
    background-color:white;
    display: flex;
    flex-wrap: wrap;
    margin-top:20px;
    &>.MuiGrid-item{
        height: 65vh;
        overflow: auto;
    }
`;

const PaginationCustom = styled(Pagination)`
    ul{
        justify-content: center; 
        margin-top: 24px;
    }
`;

class MediasCategories extends React.Component {
    
    constructor(props){
        super(props)
        this.state = {
            openForm        : props.history.location.state?.openForm ? props.history.location.state.openForm : false,
            openMediaForm   : props.history.location.state?.openMediaForm ? props.history.location.state.openMediaForm : false,
            dataLayout      : null,
            identifier      : '',
            parent          : [],
            parentCat       : '',
            formImage       : null,  
            fileName        : null,
            fileAlt         : 'Image',
            treeCats        : [],
            medias          : null,
            /*allMedias       : null,*/
            filterByType    : props.history.location.state?.types ? props.history.location.state.types : ALLOWED,
            openModal       : false,
            buttonAvailable : props.history.location.state?.buttonAvailable ? false : true,
            toDelete        : null,
            openDeleteModal : false,
            selectedNodes   : [],
            formAction      : 'add',
            currentId       : '',
            openDeleteCatModal : false,
            loading         : false,
            page            : 1,
            nbperpage       : 8,
            countPage       : 0,
            cursor          : null,
            
        };
        this.cats=[
            {
                libelle:'All',
                type:'all',
            },
            {
                libelle:'Images',
                type:IMAGES,
            },
            {
                libelle:'Vidéos',
                type:VIDEOS,
            },
            /*{
                libelle:'360°',
                type:[],
            },
            {
                libelle:'Audio',
                type:[],
            }*/
        ]

    }
    

    /*UTILS*/

    copyArrayOfObjects = array => array.map(a => ({...a}));

    resetState() {
        this.setState({
            cat_id: '',
            identifier: '',
            parentCat: '',
            parent:[],
            action:'',
            formAction:'',
            toDelete:null,
            fileName:'',
            fileAlt:''
        });
    }

    /*GET MEDIAS*/

    filterByType=(cat)=>{
        let filter;
        if(cat==='all'){
            filter = ALLOWED;
        }
        else{
            filter = cat;     
        }
         this.setState({
            filterByType  : filter
        },()=>{
            this.filterMedias();
        }) 
    }

    filterMedias=()=>{  
        this.setState({
            medias:null
        })

        let variables   = {
            types: this.state.filterByType ? this.state.filterByType : ALLOWED,
            categories:[this.state.filterByCategory],
            nbperpage : this.state.nbperpage
        }
        if(this.state.cursor && this.state.listCursors && this.state.cursor !== this.state.listCursors[0].cursor){
            variables.cursor = this.state.cursor;
        }

        let getMedias    = GET_MEDIAS_IMAGES;
        this.props.client.query({
            query:getMedias ,
            fetchPolicy:'no-cache',
            variables:variables,

        }).then( result =>{
            this.setState({
                medias  : result.data.mediaObjects.edges,
                countPage: Math.ceil(result.data.mediaObjects.totalCount / this.state.nbperpage)
            }) 
        })
        this.props.stopLoading();
    }

    handleChangeCategorie=(categorie,childrens,parent,currentNode)=>{
        this.setState({
            filterByCategory : categorie,
            selectedNodeId : categorie
        },()=>{
            this.filterMedias();
        })

    }
    handleAddMedia= (action='add')=>{
        return new Promise(async(resolve,reject)=>{
            if(action === 'edit' || this.state.formImage.data !== null ){
                this.props.startLoading();
                await this.saveMedia(action);    
                this.handleToggleDrawer('addMediaForm');
                // this.handleGetAllMedias(true);
                this.filterMedias();
                this.props.stopLoading();
                this.props.snack(ALERT_SUCCESS,'Média ajouté !');
                this.setState({
                    formImage:null      
                })
            }
            resolve();
        });
    }


    saveMedia = async(action='add')=>{

        let cats        = this.state.parent;
        /*Retirer les parents des catégories enfantes
        let parentList=cats.map(category=>category.node.parent?.id);
        cats=cats.filter(cat=>!parentList.includes(cat.value))  */
        
        let formData    = new FormData();   
        switch (action){

            case 'add':
                let  media      = this.state.formImage;
                formData.append('file', media.file);
                formData.append('type', media.file.type);
                formData.append('name',this.state.fileName.replace(/ /g, "_").toLowerCase());
                formData.append('alt',this.state.fileAlt);

                if(cats.length>0){
                    let mediaCats = cats.map(cat=>parseInt(cat.replace('/api/media-object-categories/', ''),10));
                    for(let mediaCat of mediaCats){
                        formData.append('categories[]',mediaCat);
                    }         
                }
                await axios(`${process.env.REACT_APP_API}/media-objects`, 'post', formData);


                this.filterMedias()
                break;
            case 'edit':
                let editData = {
                    'alt' : this.state.fileAlt,
                    'name':this.state.fileName.replace(/ /g, "_").toLowerCase()
                };
                await axios(`${process.env.REACT_APP_API_ROOT+this.state.currentId}`, 'PUT', editData,true,'application/json');
                this.filterMedias()
                break;
            default:
                return null;
        }



    }
    addMedia = ()=>{
        this.resetState();
        let defaultCat = this.state.selectedNodeId ? this.state.selectedNodeId : this.state.categoriesData.find(e => e.node.parent === null)?.node.id;
        this.setState({ 
            parent: [defaultCat],
        },()=>{
            this.handleToggleDrawer('addMediaForm');
        });
    }

    editMedia = (media)=>{
        this.resetState();
        if (!media.node){
            media = {
                node : {
                    ...media
                }
            }
        }
        let mediaCat = media.node.category.edges.length > 0 ? media.node.category.edges : [this.state.categoriesData.find(e => e.node.parent === null)];

        mediaCat=mediaCat.map(cat=>cat.node.id)

        this.setState({
            formAction:'edit',
            currentId:media.node.id,
            fileName:media.node.name,
            fileAlt:media.node.alt,
            filePath:media.node.filePath,
            mediaType:media.node.type,
            parent:mediaCat
        },()=>{
            this.handleToggleDrawer('addMediaForm');

        })
    }
    deleteMedia = async(action)=>{
        switch(action){
            case 'delete':
                await axios(`${process.env.REACT_APP_API}/media-objects/${this.state.toDelete.replace('/api/media-objects/', '')}`, 'delete');
                let currentList = this.state.medias;
                let newList     = currentList.filter(media=>media.node.id !== this.state.toDelete)

                this.setState({
                    medias          : newList,
                    currentMedias   : newList,


                },()=>{
                    this.closeAllModal(); 
                    this.props.snack(ALERT_ERROR,'Média supprimé');

                    /*Reload en arrière plan de la liste des médias*/
                    // this.handleGetAllMedias(true);
                    this.filterMedias();

                })
                
                break;

            case 'cancel':
                this.setState({
                    openDeleteModal : !this.state.openDeleteModal

                })
                break;    
            default:
                return null         
        }
        
        this.setState({
            toDelete:null
        })
     
    }

    /*MODAL*/
    handleCloseModal = () => {

        this.setState({
            openModal   : !this.state.openModal 
        })

    }

    handleMediaModal = (media,index,isNav=false) =>{
     
        let modalData = {
            ...media.node,
            index : index
        }

        if(!isNav){
            this.setState({
                openModal   : !this.state.openModal,
            })  
        }
        this.setState({
            modalData   : modalData
        })
    }

    handleNextMedia = (step,index) => {
        let next;
        
        switch(step){
            case 'next':
                next = index +1;
                break;

            case 'prev':
                next = index-1;
                break;

            default :
                next = next;
                break;
        }
        
        
        let media = this.state.medias[next];
        this.handleMediaModal(media,next,true);
    }

    handleDeleteModal =(id)=>{
        this.setState({
            toDelete        : id,
            openDeleteModal : !this.state.openDeleteModal
        })

        
    }

    handleDeleteCatModal =()=>{
        this.setState({
            openDeleteCatModal : !this.state.openDeleteCatModal
        })
    }

    closeAllModal(){
        this.setState({
            openModal       : false,
            openDeleteModal : false
        })
    }


    /*GET CATEGORIES + TREE*/

    prepareTree(){
        this.props.client.query({
            query: GET_MEDIA_CATEGORIES,
            fetchPolicy: 'no-cache'
        }).then(result=>{

            let cats  = result.data.mediaObjectCategories.edges;
            // let tree  = [];
            let data  = cats.filter(e => e.node.parent === null);

            
            
            this.setState({ categoriesData: cats,defaultRoot:data.node });
            
            for(let parent of data){
                this.convertToNode(parent,true);
                this.populateChildren(cats,parent);
            }    

            /*root.children=data;
            tree=tree.concat(root);*/



            this.setState({ 
                treeData: this.copyArrayOfObjects(data) ,
                treeCats: this.copyArrayOfObjects(data) 
            });
            
        })
    }

    convertToNode(data, isRoot=false){
        data.title          = data.node.libelle;
        data.isDirectory    = true;
        data.isRoot         = isRoot;
        data.dragDisabled   = true;
        data.expanded       = true;

        
    }

    populateChildren(cats, parent) {
        parent.children = cats.filter(e => e.node.parent !== null && e.node.parent.id === parent.node.id);
        
        for (let child of parent.children) {
            this.convertToNode(child);
            this.populateChildren(cats, child);
        }
    }



    /*LAYOUT FORM*/

    handleToggleDrawer(form){
        if(form === 'addMediaForm'){
            this.setState({ 
                openMediaForm   : !this.state.openMediaForm,
                buttonAvailable : !this.state.buttonAvailable
            });  
        }
        if(form === 'addMediaCatForm'){
            this.setState({ 
                openForm        : !this.state.openForm,
                buttonAvailable : !this.state.buttonAvailable

            });            
        }

    }

    handleInputChange = (stateName, evt) => {
        const value = evt?.target?.value ?? evt;
        this.setState({
            ...this.state,
            [stateName]: value
        });
    };



    addCategory = () => {
        this.resetState();
        
        let root = this.state.categoriesData.find(cat => cat.node.parent === null)
        this.setState({
            parentCat: root.node.id
        }, () => this.handleToggleDrawer('addMediaCatForm'));
    }

    editCategory =(nodeInfo)=>{
        
        this.resetState();
        
        this.setState({
            formAction  : 'edit',
            parentCat   : nodeInfo.node.parent?.id,
            identifier  : nodeInfo.node.libelle,
            currentId   : nodeInfo.node.id
            
        },()=>{
            this.handleToggleDrawer('addMediaCatForm');
        })
    }

    addSubcategory = (nodeInfo) => {
        this.resetState();

        this.handleToggleDrawer('addMediaCatForm');
        this.setState({ 
            
            parentCat: nodeInfo.id,
            dataLayout:mediasCategoriesConfig(this.state.categoriesData,nodeInfo.id)
        });
        

    };



    /*MUTATION*/
    handleError = (e) => {
        this.props.snack(ALERT_ERROR, 'Une erreur est survenue');

        this.props.stopLoading();

        if (e.graphQLErrors) {
            for (let error of e.graphQLErrors) {
                console.error('ERROR', `${error.message} =>`, error.debugMessage);
            }
        }
    };

    handleSuccess = async (action) => {
        await this.prepareTree();

        this.props.snack('Catégorie ajoutée !');

        this.handleToggleDrawer('addMediaCatForm');
        if(action === 'delete'){
            this.handleDeleteCatModal();
        }
        this.resetState();
        this.props.stopLoading();
    };

    handlerMutation = async (action='add') => {
        try {
            let query       = null;
            let variables   = null;
    
            this.props.startLoading();
            
            if(action === 'edit'){
                query = UPDATE_MEDIA_CATEGORY;

                variables = { 
                    libelle: this.state.identifier, 
                    parent: this.state.parentCat,
                    id:this.state.currentId
                };                  
            }
            else if(action === 'delete'){
                query = DELETE_MEDIA_CATEGORY;

                variables = { 
                    id:this.state.currentId
                };
            }
            else{
                query = ADD_MEDIA_CATEGORY;
                
                variables = { 
                    libelle: this.state.identifier, 
                    parent: this.state.parentCat !== 'root' && this.state.parentCat !== '' ? this.state.parentCat : null
                };    
            }

                   
            await this.props.client.mutate({
                mutation: query,
                variables,
                refetchQueries: [{
                    query: GET_MEDIA_CATEGORIES,
                }]
            });

            

            this.handleSuccess(action);
        } catch(e) {
            this.handleError(e);
        }
    };
    expand = (expanded) => {
        this.setState({
          treeData: toggleExpandedForAll({
            treeData: this.state.treeData,
            expanded,
          }),
        });
    };

    handleGetCursorsMedias = () => {
        return new Promise((resolve,reject)=>{
            const getCursorsMedias = GET_MEDIAS_CURSOR;
            this.props.client.query({
                query: getCursorsMedias,
                fetchPolicy: 'no-cache',
                variables:{types: ALLOWED}
            }).then(result => {
                this.setState({
                    listCursors: result.data.mediaObjects.edges
                });
            });
            
            resolve();
        });
    }

    changePage = (event, page) => {
        this.props.startLoading();
        let index = 0;
        if(page > 1){
            index = ( page * this.state.nbperpage ) - this.state.nbperpage - 1
        }
        this.setState({
            cursor: this.state.listCursors[index].cursor,
            page: page
        },()=>{
            this.filterMedias();
        });
    };

    async preparePage() {
        this.prepareTree();

        await this.handleGetCursorsMedias();
        this.filterMedias()
        // if(this.props.history.location.state?.types){
        //     this.filterMedias();
        // }
        // else{
        //     this.handleGetAllMedias();            
        // }
        this.setState({ ready: true });
    }
    /*COMPONENTS*/

    componentDidMount(){
        const getRights = hasRights(CRM_MEDIAS, CRM_MEDIAS_DAM, VIEW)
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }else{
            this.preparePage();
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.formImage !== this.state.formImage) {
        
        let name=this.state.formImage?.file?.name.replace(/ /g, "_").toLowerCase();
          this.setState({
              fileName : name
          })
        }
      }

    render() {
        return (
            <div>

                <TopPanel 
                    icomoon         = "picto-media" 
                    colorIcomoon    = {colors.blue.lighter.hue300} 
                    title           = "Gérer les médias" subtitle="Gestion de vos médias (création / modification / suppression)" 
                    gradientColor1  = {colors.menu.regular} 
                    gradientColor2  = {colors.menu.darker}  
                    handlerAdd      = {() => {this.addCategory()}} 
                    textAdd         = {hasRights(CRM_MEDIAS, CRM_MEDIAS_DAM, CREATE) ? "Créer un répertoire" : null}
                    handlerImport   = {() => this.addMedia()} 
                    textImport      = {hasRights(CRM_MEDIAS, CRM_MEDIAS_DAM, CREATE) ? "Ajouter un Media" : null}
                    buttonAvailable = {this.state.treeData ? this.state.buttonAvailable : false}
                />

                <TabMedias categories={this.cats} handleChangeTab={this.filterByType} defaultTab={this.props.history.location.state?.index?this.props.history.location.state.index:null}/>
                
                {this.state.treeData ? 
                    <div>
                        <MediaContainer container>
                            <Grid item xs={6} lg={5}>

                                <TreeView
                                    typeOfTree              = {'categorie'}
                                    dataTree                = {this.state.treeData} 
                                    onChange                = {treeData => this.setState({ treeData })}
                                    canDrag                 = {false}
                                    canDrop                 = {false}
                                    addSubcategory          = {this.addSubcategory} 
                                    expand                  = {this.expand} 
                                    handleChangeCategorie   = {this.handleChangeCategorie}
                                    editCat                 = {this.editCategory}
                                    marginTop               = {true}
                                    canModify               ={hasRights(CRM_MEDIAS, CRM_MEDIAS_DAM, UPDATE)}
                                    canAdd                  ={hasRights(CRM_MEDIAS, CRM_MEDIAS_DAM, CREATE)}
                                    allButton               = {() => {this.setState({filterByCategory : null}); 
                                                                this.filterByType(this.state.filterByType)}}
                                    
                                />

                            </Grid>

                            <Grid item xs={6} lg={7} style={{padding:30}}>
                                <Grid container spacing={2}>
                                    {
                                        this.state.medias ?
                                            this.state.medias.length > 0 ?
                                                (
                                                    this.state.medias.map((media,index) => 
                                                        <Grid item sm={12} md={6} lg={3} key={`media-item-${index}`}>
                                                            <CardCustom contentpadding={0} paddingbottom={'0px'}>
                                                                <CardMedia 
                                                                    media={media} 
                                                                    modal={this.handleMediaModal} 
                                                                    currentIndex={index} 
                                                                    handleEdit={hasRights(CRM_MEDIAS, CRM_MEDIAS_DAM, UPDATE) ? this.editMedia : null} 
                                                                    readOnly={!hasRights(CRM_MEDIAS, CRM_MEDIAS_DAM, DELETE)}
                                                                />
                                                            </CardCustom>
                                                        </Grid>
                                                    )
                                                )
                                            :
                                            <EmptyCard title={"Désolé nous n'avons pas trouvé de résultats à votre recherche"} subtitle={"Essayez avec d'autres critères"} picto={emptyProduct} textButton={"Ajouter un media"} onClick={() => {this.handleToggleDrawer('addMediaForm')}}  xsImg={this.state.openForm ? 4 : 2} />

                                        :
                                        <PageLoader/>

                                    }  
                                </Grid>
                                {
                                    this.state.ready && this.state.countPage > 1 && this.state.medias?.length > 0 ?
                                        <PaginationCustom onChange={(event, page) => {this.changePage(event, page)}} page={this.state.page} count={this.state.countPage} color="primary" />
                                        : null
                                } 

                            </Grid>

                        </MediaContainer> 

                        {this.state.openForm ? 

                            <LayoutFormBuilder
                                icomoon         = "ico-ajouter-categorie"
                                opened          = {this.state.openForm} 
                                forClose        = {() => {this.handleToggleDrawer('addMediaCatForm')}}
                                dataLayout      = {mediasCategoriesConfig(this.copyArrayOfObjects(this.state.categoriesData), this.state.parentCat,this.state.formAction)}
                                allState        = {this.state} 
                                stateCallback   = {this.handleInputChange}
                                handlerMutation = {this.state.formAction === 'edit'?()=>{this.handlerMutation('edit')}:()=>{this.handlerMutation('add')}}
                                validateButton  = {true}
                                deleteMutation  = {this.state.formAction === 'edit' ? this.handleDeleteCatModal : null}
                                deleteText      = {this.state.formAction === 'edit' ? 'Supprimer la catégorie' : null}
                                

                            />

                        : null}
                        {this.state.openMediaForm ? 

                            <LayoutFormBuilder
                                icomoon         = "ico-importer-media"
                                opened          = {this.state.openMediaForm} 
                                forClose        = {() => {this.handleToggleDrawer('addMediaForm')}}
                                dataLayout      = {this.state.formAction === 'edit' ? mediasEditConfig(this.state.categoriesData,this.state.parent,this.state.filePath,this.state.mediaType) :mediasConfig(this.state.categoriesData,this.state.parent,this.state.parent)}
                                allState        = {this.state} 
                                stateCallback   = {this.handleInputChange}
                                handlerMutation = {this.state.formAction === 'edit' ? ()=>{this.handleAddMedia('edit')} : ()=>{this.handleAddMedia('add')}}
                                validateButton  = {true}

                            />

                        : null}
                        {this.state.modalData 
                        
                        ? 
                            <MediaModal
                                open                = {this.state.openModal}
                                onClose             = {this.handleCloseModal}
                                modalData           = {this.state.modalData}
                                handleNav           = {this.handleNextMedia}
                                lastMedia           = {this.state.medias?.length-1}
                                handleDeleteModal   = {hasRights(CRM_MEDIAS, CRM_MEDIAS_DAM, DELETE) ? this.handleDeleteModal : null}
                            />

                        : null}
   
                    </div>
                    
                    
                : <PageLoader/>}

                <DialogModal
                    open            = {this.state.openDeleteModal}
                    secondaryAction = {()=>{this.deleteMedia('cancel')}}
                    primaryAction   = {()=>{this.deleteMedia('delete')}}
                    title           = 'Supprimer ce média' 
                >
                    <Typography variant="body2">Êtes-vous sûr de vouloir supprimer ce média ? <strong>Cette action est irréversible</strong></Typography>
                </DialogModal>
                <DialogModal
                    open            = {this.state.openDeleteCatModal}
                    secondaryAction = {()=>{this.handleDeleteCatModal()}}
                    primaryAction   = {()=>{this.handlerMutation('delete')}}
                    title           = 'Supprimer cette catégorie' 
                   
                >
                    <Typography variant="body2">Êtes-vous sûr de vouloir supprimer cette catégorie, ses sous-catégories et ses médias ? <strong>Cette action est irréversible</strong></Typography>
                </DialogModal>
            </div>
        );
    }

    copyArrayOfObjects = array => array.map(a => ({...a})); // be careful, only breaks references at objects level

    goTo = route => {
        this.props.history.push(route);
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};
export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(MediasCategories)));
