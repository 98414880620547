import React from 'react';
import reactCSS from 'reactcss';
import { SketchPicker } from "react-color";
import Checkbox from '@material-ui/core/Checkbox';
import styled from 'styled-components';
import colors from '../../../../../config/theme/colors';
import Button from '../../../../ui/button/Button';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const SketchPickerCustom =  styled(SketchPicker)`
    box-shadow: inherit!important;
    border-radius: 5px 5px 0 0!important;
    border: 1px solid lightgrey!important;
    padding: 10px!important;
    width: calc(100% - 22px)!important;
    position: relative;
    > div:first-of-type{
        width:calc(100% - 100px)!important;
        height: 100px!important;
        padding-bottom: 0!important;
        border-radius: 0 5px 5px 0;
        left: 100px;
    }
    .flexbox-fix{
        &:nth-child(2){
            margin: 10px 0;
            &>div{
                &:first-child{
                    &> div{
                        border-radius: 5px;
                    }
                }
                &:last-child{
                    width: 100px!important;
                    height: 100px!important;
                    position: absolute!important;
                    top: 10px;
                    left: 10px;
                    margin: 0!important;
                    &>div{
                        border-radius: 5px 0 0 5px!important;
                        box-shadow: inherit!important;
                    }
                }
            }
        }
        &:nth-child(3){
            &> div{
                padding-left: 10px;
                &:first-child{
                    padding-left:0;
                }
                &:nth-child(2){
                    margin-left: 10px;
                    border-left: 1px solid lightgrey;
                    padding-left: 10px!important;
                }
                &> div{
                    display: flex;
                    flex-direction: row-reverse;
                    input{
                        font-size: 12px!important;
                        border-radius: 4px;
                        font-weight: bold;
                    }
                    label{
                        font-size: 12px!important;
                        padding-right: 5px;
                        text-transform: uppercase!important;
                        color: #2B4553!important;
                        font-weight: bold;
                    }
                } 
            }
        }
    }
`;

const MarkupPickerCustom = styled.div`
    box-shadow: inherit!important;
    border-radius: 0 0 5px 5px!important;
    border: 1px solid lightgrey!important;
    border-top: 0!important;
    padding: 10px!important;
    width: calc(100% - 22px)!important;
    position: relative;
    box-sizing: initial;
    background: rgb(255, 255, 255);
`;

const DivColor = styled.div`
    border-radius: 5px 5px 0 0!important;
    border: 1px solid lightgrey!important;
    background: white;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
`;

const DivMarkups = styled.div`
    border-radius:  0 0 5px 5px!important;
    border: 1px solid lightgrey!important;
    border-top: 0!important;
    background: white;
    width: 100%;
    padding: 6px 16px;
    &>div:first-child{
        display: flex;
        align-items: center;
        padding: 6px 0px;
    }
`;

const CheckboxCustom = styled(Checkbox)`
    svg{
        path{
            color: rgb(110, 174, 209);
        }
    }
`;

class ColorMarkupPicker extends React.Component {
    state = {
        displayColorPicker: this.props.allState.editForm && this.props.allState.editForm === 'edit' ?  false : true,
        color: this.props.color,
        localColor: this.props.color,
        markups: [],
        listMarkups: this.props.listMarkups,
    };

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };

    setColor = (color) => {
        this.setState({
            color
        }, () => {
            let codeColor = {};
            if (this.state.color && typeof(this.state.color) === 'string') {
                if (this.state.color.includes('#')){
                let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
                let hex = this.state.color.replace(shorthandRegex, function(m, r, g, b) {
                    return r + r + g + g + b + b;
                });
        
                let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
                codeColor = result ? {
                    r: parseInt(result[1], 16),
                    g: parseInt(result[2], 16),
                    b: parseInt(result[3], 16)
                } : null;
                }
                else if (this.state.color.includes('rgba')) {
                    let temp_color = this.state.color.replace(/^(rgb|rgba)\(/,'').replace(/\)$/,'').replace(/\s/g,'').split(',');
                    codeColor.r = parseInt(temp_color[0]);
                    codeColor.g = parseInt(temp_color[1]);
                    codeColor.b = parseInt(temp_color[2]);
                    codeColor.a = parseFloat(temp_color[3]);
                }
                else if (this.state.color.includes('rgb')){
                let temp_color = this.state.color.replace(/^(rgb|rgba)\(/,'').replace(/\)$/,'').replace(/\s/g,'').split(',');
                codeColor.r = parseInt(temp_color[0]);
                codeColor.g = parseInt(temp_color[1]);
                codeColor.b = parseInt(temp_color[2]);
                }
                this.setState({codeColor})
            }
        });

    }

    componentDidUpdate = (prevProps) => {
        if(this.props.color !== prevProps.color){
            this.setColor(this.props.color);
        }
    }
    componentDidMount = () => {
        this.setColor(this.props.color);
    }

    render() {
        const styles = reactCSS({
            'default': {
                color: {
                    width: 30,
                    height: 30,
                    background: 'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMUlEQVQ4T2NkYGAQYcAP3uCTZhw1gGGYhAGBZIA/nYDCgBDAm9BGDWAAJyRCgLaBCAAgXwixzAS0pgAAAABJRU5ErkJggg==") left center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center', 
                    borderRadius: 4,
                },
                bgcolor: {
                    width: 30,
                    height: 30,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 4,
                    backgroundColor: this.state.color?.r && this.state.color?.g && this.state.color?.b && this.state.color?.a ? `rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})` : this.state.color,
                },
                swatch: {
                    padding: '5px 0 0',
                    borderRadius: 4,
                    display: 'flex',
                    flexDirection: 'row',
                    cursor: 'pointer',
                    marginBottom: 0,
                    width: '100%'
                },
                popover: {
                    zIndex: '2',
                    marginBottom: 20,
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
                label: {
                    paddingLeft: 10,
                    fontSize: 14,
                },
            },
        });

        return (
            <div style={{width: '100%', position: 'relative'}}>
                { 
                    this.state.displayColorPicker ? 
                        <div style={ styles.popover }>
                            <div style={ styles.cover } onClick={ this.handleClose }/>
                            <SketchPickerCustom onChange={(color) => {this.setState({ localColor: color.hex })}} onChangeComplete={(color) => {this.props.onChangeComplete(color)}} color={this.state.localColor} presetColors={[]} />
                            <MarkupPickerCustom>
                                <div>
                                    {
                                        this.state.listMarkups.map((markup, index) => (
                                            !markup.node.checked || this.props.listColors[this.props.index]?.markups?.includes(markup.node.id) ?
                                            (
                                                <div style={{display: 'inline-flex', alignItems: 'center'}} onClick={() => {this.props.onCheckedMarkup(markup.node.id, markup.node.checked ? !markup.node.checked : true)}} key={`color-markup-${index}`}>
                                                    <CheckboxCustom checked={markup.node.checked ? markup.node.checked : false} style={{padding: '0px 5px 0 10px'}} />
                                                    <label>{markup.node.libelle}</label>
                                                </div>
                                            ) : null
                                        ))
                                    }
                                </div>
                                <Button onClick={ this.handleClose } bgcolor={colors.white} bgcolorhover={colors.blue.lighter.hue300} color={colors.blue.lighter.hue300} colorhover={colors.white} border={`1px solid ${colors.blue.lighter.hue300}`} fontsize={14} style={{width: '100%'}}>Valider</Button>
                                <Button onClick={ () => {this.props.deleteColor()} } bgcolor={colors.white} bgcolorhover={colors.white} color={colors.red.regular} colorhover={colors.red.darker} fontsize={12} style={{width: '100%', margin: 0}}>
                                    <HighlightOffIcon style={{fontSize: '1rem', marginRight: 5}} /> 
                                    Supprimer
                                </Button>
                            </MarkupPickerCustom>
                        </div> 
                    : 
                        <>
                            <div style={ styles.swatch } onClick={ this.handleClick }>
                                <DivColor>
                                    <div style={ styles.color }>
                                        <div style={ styles.bgcolor}></div>
                                    </div>
                                    <p style={{textTransform: 'uppercase', padding: '0 16px'}}>{this.props.color}</p>
                                    {
                                        this.state.codeColor ? (
                                            <div style={{borderLeft: '1px solid lightgrey', paddingLeft: 10, display: 'flex'}}>
                                                <p style={{padding: '0 16px'}}>R : {this.state.codeColor.r}</p>
                                                <p style={{padding: '0 16px'}}>G : {this.state.codeColor.g}</p>
                                                <p style={{padding: '0 16px'}}>B : {this.state.codeColor.b}</p>
                                            </div>
                                        ) : null
                                    }
                                    <p style={{color: colors.blue.lighter.hue300, textDecoration: 'underline', position: 'absolute', right: 30}}>Modifier</p>
                                </DivColor>
                            </div> 
                            <DivMarkups>
                                {  
                                    this.props.listColors[this.props.index]?.markups?.length > 0 ? (
                                        this.state.listMarkups.map((markup, index) => (
                                            <>
                                                {
                                                    index === 0 ? (
                                                        <span>Assigné à : </span>
                                                    ) : null
                                                }
                                                {
                                                    markup.node.checked && this.props.listColors[this.props.index]?.markups?.includes(markup.node.id) ?
                                                        (
                                                            <div style={{display: 'inline-flex', alignItems: 'center'}} onClick={() => {this.props.onCheckedMarkup(markup.node.id, markup.node.checked ? !markup.node.checked : true)}} key={`color-assign-${index}`}>
                                                                <label style={{paddingLeft: 5}}>- {markup.node.libelle}</label>
                                                            </div>
                                                        ) 
                                                    : null
                                                }
                                            </>
                                        ))
                                    ) : (<span>Aucune balise assignée.</span>)
                                }
                            </DivMarkups>
                        </>
                }
            </div>
        )
    }
}

export default ColorMarkupPicker;