import { v4 as uuidv4 } from "uuid";
import { FormInputTypes, EditableTypes } from '../../../shareable/types';
import { getDefaultText, getDefaultTextarea, getImageDefault, getDefaultLink } from '../../helpers';

export default {
    name: "Section d'explication",
    inputs:{
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#F0F7FA'
        },
        backgroundImage: {
            label: 'Image de fond',
            type: FormInputTypes.IMAGE,
            value: null
        },
    },
    blocks: {
        explanation: {
            id: uuidv4(),
            name: "Bloc d'explication",
            type: EditableTypes.MINI_BLOCKS,
            inputs: {
                backgroundColor: {
                    label: 'Couleur de fond',
                    type: FormInputTypes.COLOR,
                    value: '#fff'
                },
                backgroundImage: {
                    label: 'Image de fond',
                    type: FormInputTypes.IMAGE,
                    value: null
                },
                border: {
                    type: FormInputTypes.GROUP,
                    label: "Border",
                    inputs: {
                        borderColor: {
                            type: FormInputTypes.COLOR,
                            value: '#53839D',
                            label: "Couleur de la bordure"
                        },
                        borderWidth: {
                            type: FormInputTypes.SLIDER,
                            value: 0,
                            label: "Largeur de la bordure :",
                            params: {max: 5, min: 0, step: 1}
                        },
                        borderRadius: {
                            type: FormInputTypes.SLIDER,
                            value: 0,
                            label: "Arrondi des angles :",
                            params: {max: 50, min: 0, step: 1}
                        }
                    }
                },
                fullWidth: {
                    label: 'Largeur du bloc',
                    type: FormInputTypes.SELECT,
                    value: 'auto',
                    params: [{label: 'Auto', value: 'auto'}, {label: 'Fullwidth', value: 'fullwidth'}]
                }
            },
            blocks:{
                rulesTitle: getDefaultText("Titre des règles de jeu", "Comment jouer ?", 35, "#53839D", 'rgba(255,255,255,0)'),
                rules: getDefaultTextarea("Explications du jeu", "Explications du jeu", true, "#53839D", "rgba(0,0,0,0)", 'initial', 'center', 18),
                button: getDefaultLink("Boutton", "C'est parti !", null, 20, '#fff', '#53839D', null, 0, '#fff', 0),
                image: getImageDefault(),
            }
        },
        explanationSupp: getDefaultTextarea("Explications supplémentaires", "*", true, "#53839D", "rgba(0,0,0,0)", 'initial', null, 12),
        copyright: getDefaultTextarea("Copyright", "®", true, "#53839D", "rgba(0,0,0,0)", 'initial', 'initial', 12),
    }
};
