import React from 'react';
import { connect } from "react-redux";
import { SNACK, START_LOADING, STOP_LOADING } from '../../../../../js/constants/action-types';
import { Grid, List, InputLabel } from '@material-ui/core';
import { DELETE_COLOR } from "../../../../../queries/brand_guideline";
import styled from 'styled-components';
import colors from '../../../../../config/theme/colors';
import ColorMarkupPicker from './ColorMarkupPicker';
import AddIcon from '@material-ui/icons/Add';
import { withApollo } from 'react-apollo';


const AddIconCustom = styled(AddIcon)`
    fill: white;
    background: ${colors.blue.lighter.hue150};
    border-radius: 50%;
    padding: 4px;
    width: calc(1em + 10px);
    height: calc(1em + 10px);
    margin-bottom: 10px;
`;
const SpanAddCustom = styled.span`
    color: ${colors.blue.lighter.hue150};
    text-decoration: underline;
`;
const FormCustom = styled(List)`
    padding: 1rem;
    width: 100%;
    background: rgb(250, 251, 251);
`;
const InputLabelCustom = styled(InputLabel)`
    color: ${colors.blue.regular};
    line-height: 20px;
    @media screen and (min-width: 1280px){
        height: 51px;
    }
    display: flex;
    align-items: center;
    word-break: break-word;
`;



class ColorSelector extends React.Component {  
    constructor(props){
        super(props)
        this.state = {
            listColors: this.props.allState.listColors || [],
            listMarkups: this.props.allState.listMarkups || [],
        }
    };

    addColor = (color = colors.blue.lighter.hue150) => {
        let temporaryColor = this.state.listColors;
        temporaryColor.push({hex: color});
        this.setState({
            listColors: temporaryColor
        }, () => {this.props.stateCallback('listColors', this.state.listColors)});
    };

    deleteColor = (index) => {
        let temporaryColor = this.state.listColors;
        if(temporaryColor[index].id){
            this.props.client.mutate({
                mutation: DELETE_COLOR,
                variables: {id: temporaryColor[index].id},
            })
        }
        let temporaryMarkup = this.state.listMarkups;
        let markupsToUncheck = temporaryColor[index].markups;
        let i = 0;
        if(temporaryMarkup && markupsToUncheck){
            for(let item of temporaryMarkup){
                for(let markup of markupsToUncheck){
                    if(item.node.id === markup){
                        temporaryMarkup[i].node.checked = false;
                    }
                }
                i++;
            }
        }
        
        this.setState({
            listMarkups: temporaryMarkup
        }, () => {this.props.stateCallback('listMarkups', this.state.listMarkups)});

        temporaryColor.splice(index, 1);

        this.setState({
            listColors: temporaryColor
        }, () => {this.props.stateCallback('listColors', this.state.listColors)});
    };

    handleChangeComplete = (color, index) => {
        let temporaryColor = this.state.listColors;
        let markups = temporaryColor[index].markups;
        let id = null;
        if(temporaryColor[index].id){
            id = temporaryColor[index].id;
        }
        
        temporaryColor[index] = color;
        temporaryColor[index].markups = markups;
        temporaryColor[index].id = id;
        temporaryColor[index].changed = true;

        this.setState({
            listColors: temporaryColor
        }, () => {this.props.stateCallback('listColors', this.state.listColors)});
    };

    handleChangeMarkups = (markup, status, index) => {
        let temporaryColor = this.state.listColors;
        if(!temporaryColor[index].markups){
            temporaryColor[index].markups = []; 
        }
        if(status){
            temporaryColor[index].markups.push(markup);
        } else {
            let i = 0;
            for(let item of temporaryColor[index].markups){
                if(item === markup){
                    temporaryColor[index].markups.splice(i,1);
                }
                i++;
            }
        }
        this.setState({
            listColors: temporaryColor
        }, () => {this.props.stateCallback('listColors', this.state.listColors)});

        let temporaryMarkup = this.state.listMarkups;
        let i = 0;
        for(let item of temporaryMarkup){
            if(item.node.id === markup){
                temporaryMarkup[i].node.checked = status;
            }
            i++;
        }
        this.setState({
            listMarkups: temporaryMarkup
        }, () => {this.props.stateCallback('listMarkups', this.state.listMarkups)});
    };


    render(){
        return (
            <FormCustom 
                // onDragOver={this.dragOver.bind(this)}
            >
                {
                    this.state.listColors.length > 0 ? (
                        <>
                            {
                                this.state.listColors.map((color, index) => (
                                    <Grid 
                                        container 
                                        direction="row" 
                                        spacing={1} 
                                        id={index} 
                                        key={`color-picker${index}`}
                                        data-id={index}
                                        key={index}
                                        // draggable='true'
                                    >
                                        <Grid item lg={3} xs={12}>
                                            <InputLabelCustom>Couleur n°{index+1}</InputLabelCustom>
                                        </Grid>
                                        <Grid item lg={9} xs={12}>
                                            <ColorMarkupPicker allState={this.props.allState} color={color.hex ? color.hex : color} index={index} listColors={this.state.listColors} listMarkups={this.state.listMarkups} onCheckedMarkup={(markup, status) => {this.handleChangeMarkups(markup, status, index)}} onChangeComplete={ (color) => {this.handleChangeComplete(color, index)} } deleteColor={() => {this.deleteColor(index)}} />
                                        </Grid>
                                    </Grid>
                                ))
                            }
                            <div
                                onClick={() => {this.addColor()}}
                                style={{cursor: 'pointer', width: '100%', height: '100%', display: 'flex', alignItems: 'center', flexDirection: 'row'}}
                            >
                                <AddIconCustom style={{marginBottom: 0, marginRight: 10}} />
                                <SpanAddCustom>Ajouter une couleur</SpanAddCustom>
                            </div>
                        </>
                    ) : (
                        <div
                            onClick={() => {this.addColor()}}
                            style={{cursor: 'pointer', width: '100%', height: '100%', minHeight: '40vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}
                        >
                            <AddIconCustom />
                            <SpanAddCustom>Ajouter une couleur</SpanAddCustom>
                        </div>
                    )
                }
            </FormCustom>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes, 
        locales: state.locales,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};

export default withApollo(connect(mapStateToProps, mapDispatchToProps)(ColorSelector));