import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";

import { Grid, Box, CircularProgress } from '@material-ui/core';
import CardCustom from '../../../layouts/Card/CardCustom';
import CardCompletudeDashboard from '../../../layouts/Card/cardContent/CardCompletudeDashboard';
import Typography from '../../../ui/typography/Typography';
import {ROUTE_CHANNELS_MARKETPLACES, ROUTE_CHANNELS_SOCIALS, ROUTE_HOME} from '../../../../js/constants/route-names';
import { withApollo } from 'react-apollo';
import request from '../../../../js/utils/fetch';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import colors from '../../../../config/theme/colors';
import IcomoonReact from "icomoon-react";
import iconSet from "../../../../assets/selection.json";
import SliderDashboard from "../../../layouts/Slider/SliderDashboard";
import Image1 from '../../../../../src/assets/images/dashboard/Visuels-Dashboard-connecteur-RS.png';
import Image2 from '../../../../../src/assets/images/dashboard/Visuels-Dashboard-connecteur-marketplace.png';
import { v4 as uuidv4 } from 'uuid';
import { hasRights } from '../../../../js/utils/rights';
import { CONTENTS, CONTENTS_DASHBOARD, VIEW} from '../../../../js/constants/constant-rights';
import { ALERT_ERROR } from '../../../../js/constants/alert-types';
import { SNACK } from '../../../../js/constants/action-types';

class DashboardContents extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            contents: []
        };
    }

    goTo = (route, status) => {

        this.props.history.push({
            pathname : route,
            state: {formOpen : status}
        });
    };

    componentDidMount(){
        const getRights = hasRights(CONTENTS, CONTENTS_DASHBOARD, VIEW)
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }
        this.initDashboard();
    }

    initDashboard = () => {
        request(`${process.env.REACT_APP_API}/dashboards/stats/content`, 'get', false).then(
            (data) => {
                if(data.success){
                    this.setState({
                        contents: data.datas
                    }, () => {
                        let time = this.state.contents.nbContents - ((this.state.contents.percentCompleteContents * this.state.contents.nbContents) / 100);
                        this.setState({time});
                    });
                }
            }
        );
    }


    render() {
        const sliderData = [
            {
                title: 'Pourquoi créer un connecteur R.S. ?',
                text: 'en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu’il est prêt ou que la mise en page est achevée. Généralement, on utilise un texte en faux latin, le Lorem ipsum ou Lipsum.Le lorem ipsum.',
                image: Image1,
                id: uuidv4(),
                buttons: [
                    {
                        text: 'Créer un connecteur R.S',
                        action: () => this.goTo(ROUTE_CHANNELS_SOCIALS, true),
                    },
                ]
            },
            {
                title: 'Pourquoi un connecteur par Marketplace ?',
                text: 'en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu’il est prêt ou que la mise en page est achevée. Généralement, on utilise un texte en faux latin, le Lorem ipsum ou Lipsum.Le lorem ipsum.',
                image: Image2,
                id: uuidv4(),
                buttons: [
                    {
                        text: 'Créer un connecteur par Marketplace',
                        action: () => this.goTo(ROUTE_CHANNELS_MARKETPLACES, true),
                    },
                ]
            }
        ];

        let stats = 
            [
                {
                    'icon': 'picto-categorie',
                    'data': this.state.contents.nbCategories,
                    'dataColor': colors.blue.regular,   
                    'subtitle': 'Total Catégories',
                },
                {
                    'icon': 'picto-content',
                    'data': this.state.contents.nbContents,
                    'dataColor': colors.blue.regular,
                    'subtitle': 'Total Contenus',
                },
            ];
        let lastDatas = [
            {
                'icon': 'picto-stock',
                'data': this.state.contents.nbContentsEnable,
                'dataColor': colors.blue.regular,
                'subtitle': 'Contenus Actif',
            },
            {
                'icon': 'picto-produit',
                'data': this.state.contents.nbContentsDisable,
                'dataColor': colors.blue.regular,
                'subtitle': 'Contenus Inactif',
            },
            {
                'icon': 'picto-produit',
                'data': this.state.contents.contentsNotInCatalog,
                'dataColor': colors.blue.regular,
                'subtitle': 'Contenus sans catalogue',
            },
            {
                'icon': 'picto-produit',
                'data': this.state.contents.contentsInCatalog,
                'dataColor': colors.blue.regular,
                'subtitle': 'Contenus avec catalogue',
            },
            {
                'icon': 'picto-produit',
                'data': this.state.contents.contentsWithImg,
                'dataColor': colors.blue.regular,
                'subtitle': 'Contenus sans image',
            },
            {
                'icon': 'picto-produit',
                'data': this.state.contents.contentsWithoutImg,
                'dataColor': colors.blue.regular,
                'subtitle': 'Contenus avec image',
            },
        ]
        return (
            <div style={{width: "100%"}}>
                <TopPanel 
                    icomoon="icon-dashboard" 
                    colorIcomoon={colors.blue.lighter.hue300}
                    title="Bienvenue sur le dashboard" 
                    strongText="Contenu"
                    subtitle="Petite phrase de description OU heure de Mise à jour" 
                    gradientColor1={colors.menu.regular} 
                    gradientColor2={colors.menu.darker} 
                    openForm={this.state.openForm}
                    hasBorder={true}
                    stats={stats}
                />
                <Grid container direction="column" justify="center" spacing={0} style={{marginTop: 24}}>
                    <Grid item xs={12}>
                        <Typography variant="h4" style={{fontWeight: 'bold'}}>Dernières Datas</Typography>
                        <Grid container direction="row" style={{marginTop: 8}} spacing={2}>
                            {
                                lastDatas.map((data, index) => {
                                    return(
                                        <Grid key={`LastDatas${index}`} style={{marginRight: 16, marginBottom: 16}}>
                                            <CardCustom>
                                                <Grid container justify="space-between" alignItems="center">
                                                    <Grid>
                                                        <Grid container direction="row" alignItems="center">
                                                            <Box mr={2}>
                                                                <IcomoonReact iconSet={iconSet} color="#6EAED1" size={25} icon={data.icon} />
                                                            </Box>
                                                            <Typography variant="h4">{data.subtitle}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Box ml={2}>
                                                        <Grid container justify="flex-end">
                                                            {
                                                                data.data || data.data === 0 ?
                                                                    <Typography variant="h2" colortext={colors.blue.lighter.hue150} style={{fontSize: 23}}>{data.data}</Typography>
                                                                : <CircularProgress size={"18px"} color={"inherit"}/>
                                                            }
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            </CardCustom>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Grid>
                    <Grid item xl={sliderData.length > 1 ? 12 : 6} lg={sliderData.length > 1 ? 12 : 8} md={sliderData.length > 1 ? 12 : 8} xs={12} style={{marginTop: 12, marginBottom: 16}}>
                        <Typography variant="h4" style={{fontWeight: 'bold'}}>Slider d'actions</Typography>
                        <Grid container direction="row" style={{marginTop: 8}}>
                            <CardCustom contentpadding="16px 0 0 0">
                                <SliderDashboard sliderData={sliderData} windowWidth={this.props.windowWidth}/>
                            </CardCustom>
                        </Grid>
                    </Grid>
                    {
                        this.state.contents ? (
                            <Grid item xs={12} style={{marginTop: 12, marginBottom: 16}}>
                                <Typography variant="h4" style={{fontWeight: 'bold'}}>Complétude</Typography>
                                <Grid container direction="row" style={{marginTop: 8}}>
                                    <Grid item xs={6} style={{paddingRight: 8}}>
                                        <CardCustom contentpadding="0">
                                            <CardCompletudeDashboard percent={this.state.contents.percentCompleteContents} type={'contenus'} time={this.state.time} layout={1} />
                                        </CardCustom>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : null
                    }
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }}),
    }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(DashboardContents)));