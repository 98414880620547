const colors = ({
    blue:{
        regular : "#2B4553",
        darker : {
            hue300 : "#53839D",
        },
        lighter:{
            hue150: "#53839D",
            hue300 : "#6EAED1",
            hue600 : "#C5DEEC",
            hue900 : "#F0F7FA",
        }
    },
    grey:{
        regular: "#808F98",
        lighter:{
            hue600 : "#AAB4BA",
            hue800 : "#D4D9DC",
            hue900 : "#EAECEE",
            hue980 : "#FAFBFB",
        }
    },
    green:{
        regular: "#59C870",
        lighter: "#ebffef",
        darker: "#4CAA5F"
    },
    red:{
        regular: "#FF6565",
        lighter: "#ffebeb",
        darker: "#D95656"
    },
    orange:{
        regular: "#F67817",
        lighter: "#fff8eb",
        darker: "#D16614"
    },
    yellow:{
        regular: "#FFE53B",
    },
    purple:{
        regular: "#BC8DF3",
    },
    pink:{
        regular: "#E21C55",
        lighter: "#CD2683",
    },
    cyan:{
        regular: "#58AFFD",
        lighter: "#37DBE0",
    },
    menu:{
        regular: "#425E7C",
        darker: "#15181C"
    },
    white: "#FFFFFF",
    black: "#000000",
    crmNavBackground : '#000000'
})

export default colors;