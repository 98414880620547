import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import ArrayHistory from './components/ArrayHistory';
import Typography from '../../../ui/typography/Typography';
import request from '../../../../js/utils/fetch';

class ImportHistory extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            used: props.used
        };
    }

    componentDidMount(){
        try {
            request(`${process.env.REACT_APP_API}/scheduler/crons`,  'get', false).then(
                (data) => {
                    if(data.success){
                        this.setState({rows: data.crons})
                    }
                }
            )
        } catch(e) {
            console.log(e);
        }
    }

    render() {
        return (
            this.state.rows ? 
            (
                <div style={{width: '100%'}}>
                    { this.state.used === 'page' ? 
                        ( <Typography variant="h1">Historique des tâches</Typography> ) 
                        : 
                        ( <Typography variant="h4" style={{fontWeight: 'bold'}}>Historique des tâches</Typography> )
                    }
                    <ArrayHistory rows={this.state.rows} style={{padding: 0, margin: this.state.used === "page" ? '16px 32px' : '8px', width: this.state.used === "page" ? 'calc(100% - 64px)' : 'calc(100% - 16px)'}} />
                </div>
            )
        : null
        );
    }
    goTo = route => {
        this.props.history.push(route);
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
    };
};

export default withRouter(connect(mapStateToProps, null)(ImportHistory));
