import React from 'react';
import {Grid, TextField, Tooltip} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '../../../../ui/button/Button';
import colors from '../../../../../config/theme/colors';
import { v4 as uuidv4 } from 'uuid';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CardCustom from '../../../../layouts/Card/CardCustom';
import DeleteIcon from '@material-ui/icons/Delete';
import styled from 'styled-components';
import TypographyCustom from '../../../../ui/typography/Typography';

const TextFieldCustom = styled(TextField)`
    input, .MuiSelect-select{
        font-size: 16px;
        padding: 16px;
        @media screen and (max-width: 1450px){
            font-size: 14px;
            padding: 14px;
        }
        @media screen and (max-width: 1280px){
            font-size: 13px;
            padding: 13px;
        }
        @media screen and (max-width: 1000px){
            font-size: 13px;
            padding: 13px;
        }
    }
    & > p{
        margin: 0;
    }
`;
class OfferPopupSendTo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            errorMessage : null,
            numberError: 0,
            // seeError: false,
        }
    }
    

    checkError = (value = null) => {
        let val     = value === null ? this.props.listContacts : value;
        let error   = 0;
        let message = null;
        const emailRegex    = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        for (let value of val){
            if (value.name === "" || value.name.length === 0 ) {
                error++;
                value.errorName   = true;
                message = 'Vous devez remplir ce champ';
            }
            if (value.firstname === "" || value.firstname.length === 0 ){
                error++;
                value.errorFirstName   = true;
                message = 'Vous devez remplir ce champ';
            }
            if (value.mail === "" || value.mail.length === 0){
                error++;
                value.errorMail   = true;
                message = 'Vous devez remplir ce champ';
            }else if (!emailRegex.test(value.mail)){
                error++;
                value.errorMail   = true;
                message = 'Cette adresse email est invalide';
            }
        }
        if (error > 0){
            this.setState({
                errorMessage: message,
                numberError: error,
            })
            this.props.stateCallback('listSendContact', val);
            return true;
        }else{
            this.setState({
                numberError: error,
            })
            return false;
        }
    }

    componentDidMount(){
        if (this.props.listContacts.length === 0){
            this.props.listContacts.push({
                new: true,
                name: '',
                firstname: '',
                mail: '',
                id: uuidv4(),
            });
            this.props.stateCallback('listSendContact', this.props.listContacts);
        }
    }

    render() {
        const { open, listContacts } = this.props;
        return (
            <Dialog
            open={open}
            onClose={() => this.props.handleToggleDialog('openPopup')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Envoi pour signature</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Ajoutez vos contacts pour la signature: 
                    </DialogContentText>
                    {
                        listContacts.map((row, i) => {
                            if (row.deleted)
                                return null;
                            return (
                                <Grid key={i} item xs={12} style={{ marginBottom: 15 }}>
                                    <CardCustom paddingbottom={"16px"} paddingtop="16px">
                                        <Grid container direction="row" spacing={0}>
                                            <Grid item xs={12} style={{marginBottom: 8}}>
                                                Contact N°{i + 1} :
                                            </Grid>
                                            <Grid container alignItems="center" direction="row">
                                                <Grid item xs={10}>
                                                    <Grid container>
                                                        <Grid item xs={6} style={{ paddingRight: 20 }}>
                                                            <TextFieldCustom
                                                                variant="outlined"
                                                                color="secondary"
                                                                value={row.name}
                                                                fullWidth
                                                                onChange={e => {
                                                                    row.name = e.target.value;
                                                                    row.errorName = false;
                                                                    this.props.stateCallback('listSendContact', listContacts);
                                                                    this.checkError(listContacts);
                                                                }}
                                                                type='text'
                                                                placeholder={'Nom'}
                                                                error={row.errorName}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextFieldCustom
                                                                variant="outlined"
                                                                color="secondary"
                                                                value={row.firstname}
                                                                fullWidth
                                                                onChange={e => {
                                                                    row.firstname = e.target.value;
                                                                    row.errorFirstName = false;
                                                                    this.props.stateCallback('listSendContact', listContacts);
                                                                    this.checkError(listContacts);
                                                                }}
                                                                type='text'
                                                                placeholder={'Prénom'}
                                                                error={row.errorFirstName}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} style={{marginTop: 8}}>
                                                            <TextFieldCustom
                                                                variant="outlined"
                                                                color="secondary"
                                                                value={row.mail}
                                                                fullWidth
                                                                onChange={e => {
                                                                    row.mail = e.target.value;
                                                                    row.errorMail = false;
                                                                    this.props.stateCallback('listSendContact', listContacts);
                                                                    this.checkError(listContacts);
                                                                }}
                                                                type='mail'
                                                                placeholder={'Email'}
                                                                error={row.errorMail}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Grid container justify="center">
                                                        {
                                                            i !== 0 ?
                                                                <Grid item xs={2} style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center'
                                                                }}>
                                                                    <Tooltip title="Supprimer">
                                                                        <DeleteIcon 
                                                                            style={{ color: '#FF6565', cursor: 'pointer' }}
                                                                            onClick={() => {
                                                                                this.props.stateCallback('listSendContact', listContacts.filter(e => e.id !== row.id))
                                                                            }} 
                                                                        />
                                                                    </Tooltip>
                                                                </Grid>
                                                            : null
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {
                                            this.state.numberError >= 1 ? 
                                                row.errorName || row.errorFirstName || row.errorMail ? 
                                                    <TypographyCustom variant="body2" colortext={colors.red.regular} style={{marginTop: 8, width: "100%"}}>{this.state.numberError > 1 ? 'Veuillez remplir ces champs' : this.state.errorMessage}</TypographyCustom>
                                                : null
                                            : null
                                        }
                                    </CardCustom>
                                </Grid>
                            );
                        })
                    }
                    {
                        listContacts.length < 5 ?
                            <div 
                                style={{
                                    height: 21,
                                    color: '#59C870',
                                    textDecoration: 'underline',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    listContacts.push({
                                        // new: true,
                                        name: '',
                                        firstname: '',
                                        mail: '',
                                        id: uuidv4(),
                                    });
                                    this.props.stateCallback('listSendContact', listContacts);
                                }}
                            >
                                <AddCircleIcon fontSize="small" style={{ marginRight: 10 }} />
                                <span>Ajouter une personne</span>
                            </div>
                        : null
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.props.handleToggleDialog('openPopup')} color={colors.grey.regular} bgcolor={colors.white} bgcolorhover={colors.grey.lighter.hue900} border={`1px solid ${colors.grey.regular}`}>
                        Annuler
                        </Button>
                    <Button 
                        onClick={() => {
                            if (this.checkError()){
                                console.log('ERROR')
                            }else{
                                this.props.mutation()
                            }
                        }} 
                        bgcolor={colors.blue.lighter.hue300}
                        color={colors.white}
                        bgcolorhover={colors.blue.darker.hue300}
                        colorhover={colors.white} 
                        autoFocus
                    >
                        Envoyer
                        </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default OfferPopupSendTo;