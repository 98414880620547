import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";

import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';

import CardAssets from '../../../layouts/Card/cardContent/CardAssets';
import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import EmptyCard from "../../../ui/empty-card/EmptyCard";
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import TabCategories from '../products/components/TabCategories';
import CardCustom from '../../../layouts/Card/CardCustom';

import colors from '../../../../config/theme/colors';
import EmptyPermanent from '../../../../assets/pictos/empty-picto/empty_permanents.png';

import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_ERROR } from '../../../../js/constants/alert-types';
import { eventService } from '../../../../js/services/event.service';
import { ROUTE_CHANNELS_CMS, ROUTE_CHANNELS_MARKETPLACES, ROUTE_CHANNELS_SOCIALS, ROUTE_CHANNELS_RETAILERS, ROUTE_CHANNELS_MAILER, ROUTE_ENGINE_ASSETS, ROUTE_HOME } from '../../../../js/constants/route-names';

import { withApollo } from 'react-apollo';
import { GET_ASSET_CATEGORIES, GET_ASSETS_PAGINATION, GET_ASSETS_CURSOR, ADD_ASSET } from '../../../../queries/assets'
import { GET_EAV_TYPES , GET_ATTRIBUTES_BY_TYPE } from '../../../../queries/attributes'
import { GET_ALL_CHANNELS } from '../../../../queries/channels'
import { GET_CATALOGS } from '../../../../queries/catalogs'
import styled from 'styled-components';
import moment from 'moment'

import { hasRights } from '../../../../js/utils/rights';
import { BUILDER, BUILDER_ASSETS, VIEW, CREATE } from '../../../../js/constants/constant-rights';
import _ from 'lodash';
import TraductionSelect from '../../../layouts/TopPanel/TraductionSelect';

const PaginationCustom = styled(Pagination)`
    ul{
        justify-content: center; 
        margin-top: 24px;
    }
`;

const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
`;


class EngineAssets extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            categories      : [],
            filteredAssets  : null,
            filteredCat     : [],
            openForm        : false,
            openFormAdd     : false,
            openTypesForm   : false,
            openGamesForm   : false,
            dataLayout      : null,
            assetsAddData   : null,
            assetType       : null,
            typeOf          : null,
            assetTypesData  : null,
            assetGamesData  : null,
            hasAssets       : null,
            activeAddButton : true,
            currentLang     : props.locales[0].node.code,
            eavTypeId       : null,
            paramIdentifier : null,
            paramStartAt    : null,
            paramUrl        : null,
            paramEndAt      : null,
            paramStatus     : true,
            errors          : {},
            ready           : false,
            page            : 1,
            nbperpage       : 6,
            countPage       : 0,
            cursor          : false,
            searchValue     : '',
            arrayCatFilter  : []
        };

        this.typingTimer = null;
        this.typeTesting = 'asset';
    }

    prepareChannels(){
        this.props.client.query({
            query: GET_ALL_CHANNELS,
        }).then(result =>{
            let getChannelsNotSystem = result.data.channels.edges.filter(e => e.node.isSystem === false)
            this.setState({
                allChannelsData: getChannelsNotSystem,
            })
        });
    }
    
    handleGetEavTypes() {
        this.props.client.query({
            query: GET_EAV_TYPES,
        }).then(result =>{
            let getEavAsset     = result.data.eavTypes.edges.find(e => e.node.code === 'asset');
            let getAssetEavId   = getEavAsset.node.id;

            this.setState({ 
                eavTypeId: getAssetEavId,     
            }, () => {
                this.handleGetAttributesAsset(); 
            });
        });
    }

    handleGetAttributesAsset(){
        this.props.client.query({
            query: GET_ATTRIBUTES_BY_TYPE,
            variables: {id: this.state.eavTypeId}
        }).then(result =>{
            let getAllAttributes = result.data.eavType.attributes.edges
            this.setState({
                customAttributes: getAllAttributes
            })
            this.prepareAttributeValues()
        });
    }

    prepareAttributeValues() {
        for (let locale of this.props.locales) {
            let values = {};

            for (let attribute of this.state.customAttributes) {
                if (attribute.node.attributeType.input === 'select') {
                    values[attribute.node.identifier] = attribute.node.attributeOptions.edges[0].node.id;
                }
            }

            this.setState({
                [locale.node.code]: values
            });
        }
    }
    getTypes = (categorie) => {
        let arrayOfType = []
        for (let type of categorie){
            if (type.node.hasFeed && type.node.identifier !== 'jeu' && type.node.identifier !== 'image_recognizer'){
                arrayOfType.push(type.node.id)
            }
        }
        return arrayOfType
    }

    handleGetAssetCategories(){
        const getAssetsCategories = GET_ASSET_CATEGORIES;
        this.props.client.query({
            query: getAssetsCategories,
        }).then(result =>{
            let catArray = []
            let typesArray = []
            let filterAssetTypes = _.cloneDeep(result.data.assetCategories.edges)
            for (let assetCat of filterAssetTypes){
                let filter = assetCat.node.assetTypes.edges.filter(e => e.node.isActive)
                assetCat.node.assetTypes.edges = filter
            }
            let getCMS = filterAssetTypes.find(e => e.node.identifier === "cms")
            let getRS = filterAssetTypes.find(e => e.node.identifier === "social")
            let getOnline = filterAssetTypes.find(e => e.node.identifier === "online")
            let getWebsite = getOnline?.node.assetTypes.edges.filter(e => e.node.identifier === 'minisite' || e.node.identifier === 'flipbook' || e.node.identifier === 'flipbook_with_cart' || e.node.identifier === 'newsletter' || e.node.identifier === 'jeu')
            if (getOnline){
                getOnline.node.assetTypes.edges = getWebsite
            }
            catArray.push(getOnline,getCMS, getRS)
            catArray = catArray.filter(e => e.node.assetTypes.edges.length > 0)

            for (let cat of catArray){
                for (let type of cat.node.assetTypes.edges){
                    if (type.node.hasFeed && type.node.identifier !== 'image_recognizer'){
                        typesArray.push(type.node.id)
                    }
                }
            }
            this.setState({
                categories: catArray,  
                assetTypeList: typesArray, 
                allTypesList: typesArray, 
            },()=>{
                this.handleGetAssets(); 
            });
        });
        
    }
    
    handleMediaPicker=(selected,stateName)=>{
        
        this.handleInputChange(stateName,selected,null,this.state.currentLang);  
    }

    handleGetCursorsProducts = () => {
        this.props.client.query({
            query: GET_ASSETS_CURSOR,
            fetchPolicy: 'no-cache'
        }).then(result => {
            this.setState({
                listCursors: result.data.assets.edges
            });
        });
    }

    handleGetAssets = () => {
        return new Promise((resolve, reject) => {
            let variables = 
            {
                "nbperpage": this.state.nbperpage, 
                "assetType_list": this.state.assetTypeList, 
            };
            if(this.state.cursor && this.state.listCursors && this.state.cursor !== this.state.listCursors[0].cursor){
                variables.cursor = this.state.cursor;
            }
            this.props.client.query({
                query: GET_ASSETS_PAGINATION,
                variables,
                fetchPolicy:'no-cache'
            }).then(result =>{
                this.setState({
                    countPage: Math.ceil(result.data.assets.totalCount / this.state.nbperpage),
                    listAssets: result.data.assets.edges,
                    ready: true,
                });
                this.props.stopLoading();
                resolve();
            });
        });
    }

    handleChangeTab = (event, newValue, id, category) => {
        let listOfTypes = [];
        this.props.startLoading();
        if (id && id.includes("asset_categories")){
            if (category.assetTypes.edges.length > 0){
                for(let type of category.assetTypes.edges){
                    // if (type.node.identifier !== 'jeu'){
                        listOfTypes.push(type.node.id)
                    // }
                }
                this.setState({
                    idActiveCat: event.id,
                    assetTypeList: listOfTypes,
                }, () => this.handleGetAssets());
            }
        }else if(id) {
            this.setState({
                idActiveCat: category,
                assetTypeList: [id],
            }, () => this.handleGetAssets());
        } else {
            this.setState({
                assetTypeList: this.state.allTypesList,
                activeCategorie: ['Tout voir'],
                noResult: false
            }, () => {
                this.handleChange(null, true);
                this.handleGetAssets();
            });
        }
    };

    handleError = (e) => {
        this.props.snack(ALERT_ERROR, 'Une erreur est survenue');

        this.props.stopLoading();

        if (e.graphQLErrors) {
            for (let error of e.graphQLErrors) {
                console.error('ERROR', `${error.message} =>`, error.debugMessage);
            }
        }
    };

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;

        errors[stateName] = error;

        this.setState({ errors });
    };

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }

        return false;
    };

    resetState() {
        this.prepareAttributeValues();
        this.setState({
            catalogSelected : null,
            channelSelected : null,
            paramIdentifier : null,
            paramStartAt    : null,
            paramEndAt      : null,
            paramStatus     : true,
            paramUrl        : null,
            assetGameType   : null,
        });
    }

    handlerMutation = async () => {
        try {
            if (this.hasErrors()) {
                this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                this.setState({ seeErrors: true });
                return eventService.fire();
            }

            
            this.props.startLoading();
            let variables = {
                'identifier': this.state.paramIdentifier,
                'assetType': this.state.assetType,
                'catalog': this.state.catalogSelected,
                'channel': this.state.channelSelected,
                'startAt': this.state.paramStartAt,
                'status': this.state.paramStatus,
                'updatedAt': moment().format(),
            }
            if(this.state.paramUrl !== null){
                variables.url = this.state.paramUrl;
            }
            if(this.state.assetGameType){
                variables.assetGameType = this.state.assetGameType;
            }
            const ADD_ASSET_RESULT = await this.props.client.mutate({
                mutation: ADD_ASSET,
                variables
            });


            await this.saveAttributes(ADD_ASSET_RESULT.data.createAsset.asset.id);

            this.handleSuccess();
        } catch(e) {
            this.handleError(e);
        }
    };

    copyArrayOfObjects = array => array.map(a => ({...a})); // be careful, only breaks references at objects level
        
    handleChange = (e)=>{
        let newList     = [];        
        let currentList = this.state.filteredCat;

        if(e){
            if (e.target.value !== ""){   

                newList=currentList.filter(item =>{
                    let target  = item[0].node.identifier.toLowerCase();
                    let search  = e.target.value.toLowerCase();
                    return target.includes(search);
                })

                this.setState({
                    filteredAssets:newList
                })
                
            }

            else{
                this.setState({
                    filteredAssets:this.state.filteredCat
                })
            }
        }
        
    }
    
    handleRouteRedirection(assetType){
        let redirectionRoute = '';
        switch(assetType){
            case 'Instore':
                redirectionRoute = ROUTE_CHANNELS_RETAILERS
            break;
            case 'Online':
                redirectionRoute = ROUTE_CHANNELS_MARKETPLACES
            break;
            case 'Réseaux sociaux':
                redirectionRoute = ROUTE_CHANNELS_SOCIALS
            break;
            case 'Boutique en ligne':
                redirectionRoute = ROUTE_CHANNELS_CMS
            break;
            case 'CMS':
                redirectionRoute = ROUTE_CHANNELS_CMS
            break;
            case 'Mailer':
                redirectionRoute = ROUTE_CHANNELS_MAILER
            break;
            default:
                return null;
        }
        this.setState({
            currentAssetRoute : redirectionRoute
        })
    }

    handleToggleDrawer = (drawer, type) => {

        if(drawer === 'form'){

            this.setState({ 
                openForm        : !this.state.openForm,
                activeAddButton : !this.state.activeAddButton
            });

        }

        if(drawer === 'openTypesForm'){

            this.setState({ 
                openTypesForm : !this.state.openTypesForm
            });

        }
        if(drawer === 'openGamesForm'){
            this.setState({ 
                openGamesForm : !this.state.openGamesForm,
                assetType     : type ? type.id : null
            });

        }

        if(drawer === 'openFormAdd'){

            this.setState({ 
                openFormAdd : !this.state.openFormAdd,
                errors: {},
            });       

        }
    }

    handleDrawerWidthChange = (width) => {

        this.setState({ 
            drawerWidthModified : width
        });
    }

    handleButtonGroupChange = (stateName, value) => {
        this.setState({
            [stateName]: value
        });
    };

    handleLang = (event) => {
        this.setState({ currentLang: event.target.value });
        this.forceUpdate();
    };

    initCatalogs = () => {
        this.props.client.query({
            query: GET_CATALOGS,
            fetchPolicy: 'no-cache'
        }).then(result =>{
            this.listCatalogs = result.data.catalogs.edges;

            this.setState({
                getAllCatalogs: this.listCatalogs
            });
        });
    };

    changePage = (event, page) => {
        this.props.startLoading();
        let index = 0;
        if(page > 1){
            index = ( page * this.state.nbperpage ) - this.state.nbperpage - 1
        }
        this.setState({
            cursor: this.state.listCursors[index].cursor,
            page: page
        },()=>{
            this.handleGetAssets();
        });
    };

    componentDidMount() {
        const getRights = hasRights(BUILDER, BUILDER_ASSETS, VIEW)
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }else{
            this.handleGetCursorsProducts();
            this.handleGetAssetCategories();
            this.handleGetEavTypes();
            this.prepareChannels();
            this.initCatalogs();
        }
    }
    

    render() {
        return (
            <div>
                <TopPanel 
                    icomoon="icon-permanent"
                    colorIcomoon={colors.blue.lighter.hue300}
                    title="Gérer la diffusion des assets" 
                    subtitle="Gestion des posts de vos assets (création / modification / suppression)" 
                    handlerAdd={() => this.goTo(ROUTE_ENGINE_ASSETS, true)} 
                    textAdd={hasRights(BUILDER, BUILDER_ASSETS, CREATE) ? "Ajouter un permanent" : null}
                    // handlerImport={() => this.goTo(ROUTE_ENGINE_ASSETS)} 
                    // textImport="Voir vos campagnes" 
                    // searchHandler={this.handleChange} 
                    gradientColor1={colors.menu.regular} 
                    gradientColor2={colors.menu.darker}
                    windowWidth={this.props.windowWidth}
                    // openForm={this.state.openForm}
                    buttonAvailable={this.state.ready}
                />
                <Grid container alignItems={this.props.windowWidth > 600 ? "center" : "flex-start"} direction={this.props.windowWidth > 600 ? 'row' :  'column-reverse'} style={{background: 'white', borderBottom: `1px solid ${colors.grey.lighter.hue800}`}}>
                    <Grid item lg={9} md={9} sm={8} xs={12} style={{position: 'relative'}}>
                        <TabCategories 
                            categories          = {this.state.categories} 
                            handleChangeTab     = {this.handleChangeTab} 
                            handleGetAll        = {this.handleGetAllAssets} 
                            handleChangeSubcat  = {this.handleChangeSubcat}
                            isDiffusion         = {true}
                        />
                    </Grid>
                    <Grid item lg={3} md={3} sm={4} xs={12}>
                        <TraductionSelect 
                            currentLang={this.state.currentLang} 
                            handleLang={this.handleLang} 
                            locales={this.props.locales}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="stretch"
                    spacing={2}
                    style={{marginTop: 16, width: "100%"}}
                >   
                    {
                        
                        this.state.listAssets ? (
                            this.state.listAssets.length>0 ?
                            (this.state.listAssets.map((item, index)=>
                                <GridCustom item lg={4} md={6} xs={12} key={`ListAssets${index}`}>
                                    <CardCustom paddingbottom={0} paddingtop={0} style={{width: "100%"}} contentpadding={'0 0px 10px 0px'}>
                                        <CardAssets asset={item} inputCard={true} image={item.categoryLogo} currentLang={this.state.currentLang} isDiffusion={true}></CardAssets>
                                    </CardCustom>
                                </GridCustom>
                            )) 
                            :
                            !this.state.hasAssets ?
                                <EmptyCard 
                                    title={hasRights(BUILDER, BUILDER_ASSETS, CREATE) ? "Vous n'avez pas encore configuré d'asset permanent" : "Vous n'avez pas les droits pour ajouter un asset permanent"} 
                                    subtitle={hasRights(BUILDER, BUILDER_ASSETS, CREATE) ? "Cliquez sur le bouton ci-dessous pour en créer un" : "Faite une demande auprès d'un administrateur"} 
                                    textButton={hasRights(BUILDER, BUILDER_ASSETS, CREATE) ? "Créer un permanent" : null}
                                    picto={EmptyPermanent} 
                                    onClick={() => this.goTo(ROUTE_ENGINE_ASSETS, true)}
                                    openForm={this.state.openForm}
                                    xsImg={this.state.openForm ? 4 : 2}
                                />
                            :
                                <EmptyCard title={"Désolé nous n'avons pas trouvé de résultats à votre recherche"} subtitle={"Essayez avec d'autres critères"} picto={EmptyPermanent}  xsImg={this.state.openForm ? 4 : 2} />
                        ): 
                        <PageLoader />

                    }

                </Grid>
                {
                    this.state.ready && this.state.countPage > 1 ? 
                        (<PaginationCustom onChange={(event, page) => {this.changePage(event, page)}} page={this.state.page} count={this.state.countPage} color="primary" />)
                    :
                        null
                }   
            </div>
        );
    }

    goTo = (route, isOpen) => {
        this.props.history.push({
            pathname : route,
            state: {openForm : isOpen}
        });
    };
}

const mapDispatchToProps = dispatch => {
    return {
      startLoading: () => dispatch({ type: START_LOADING }),
      stopLoading: () => dispatch({ type: STOP_LOADING }),
      snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
    };
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(EngineAssets)));
