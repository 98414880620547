import React, { useEffect }  from 'react';

import { Grid, InputLabel } from '@material-ui/core';
import Button from '../../../ui/button/Button';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import InputBuilder from '../../../ui/form/InputBuilder';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import DialogModal from '../../../ui/dialog/DialogModal';

import { withApollo } from 'react-apollo';
import { DELETE_CURRENCY } from '../../../../queries/currencies';

const InputLabelCustom = styled(InputLabel)`
    color: ${colors.blue.regular};
    margin-top: ${props => props.margintop || 0};
    margin-bottom: 24px;
`;
const GridFlexCenter = styled(Grid)`
    display: flex;
    align-items: center;
`;

function CardCurrencies(props){
    const currency = props.currency;
    const [open, setOpen] = React.useState(false);
    const [libelle, setLibelle] = React.useState(currency.libelle);
    const [code, setCode] = React.useState(currency.code);

    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    const deleteCurrency = (id) => {
        props.client.query({
            query: DELETE_CURRENCY,
            fetchPolicy: 'no-cache',
            variables: {id: id}
        }).then(result =>{
            props.handleRemove();
            handleClose();
        });
    }
    
    useEffect(() => {
        if(currency.code !== code || currency.libelle !== libelle){
            props.handleInputChange({
                id: currency.id,
                data: {
                    libelle,
                    code
                }
            })
        }
    }, [code, libelle]);

    const selectCurrencies = {
        type: 'text',
        label: 'Nom de la devise',
        helper: 'Nom de la devise',
        required: true,
        stateName: 'libelle',
        disabled: props.disabled,
        value: ''
    };
    const codeCurrencie = {
        type: 'text',
        label: 'Code de la devise',
        helper: 'Code de la devise',
        required: true,
        stateName: 'code',
        disabled: props.disabled,
        value: ''
    };

    return(
        <Grid container direction="row" spacing={0}>
            <Grid item lg={4} sm={6} xs={12}>
                <GridFlexCenter container direction="column" justify="center" spacing={0}>
                    <Grid container direction="row" spacing={1}>
                        <GridFlexCenter item xs={2}>
                            <InputLabelCustom>Choix</InputLabelCustom>
                        </GridFlexCenter>
                        <InputBuilder xs={9} value={libelle} input={selectCurrencies} stateCallback={(evt) => {
                            setLibelle(evt.target.value);
                        }} /> 
                    </Grid>
                </GridFlexCenter>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
                <GridFlexCenter container direction="column" justify="center" spacing={0}>
                    <Grid container direction="row" spacing={1}>
                        <GridFlexCenter item xs={3}>
                            <InputLabelCustom>Code Devise</InputLabelCustom>
                        </GridFlexCenter>
                        <InputBuilder xs={9} value={code} input={codeCurrencie} stateCallback={(evt) => {
                            setCode(evt.target.value);
                        }} /> 
                    </Grid>
                </GridFlexCenter>
            </Grid>
            {
                props.handleRemove !== null ?
                    <Grid item lg={4} xs={12} style={{justifyContent: 'flex-end', display: 'flex', alignItems: 'flex-start'}}>
                        <Button style={{margin: props.windowWidth < 1400 ? 0 : null}} color={colors.red.regular} colorhover={colors.red.darker} bgcolor="transparent" bgcolorhover="transparent" onClick={handleClickOpen}><DeleteOutlineIcon/> Supprimer la devise</Button>                    
                    </Grid>
                : null
            }

            <DialogModal 
                open={open} 
                title={`Êtes-vous sûr de vouloir supprimer cette devise ?`}
                secondaryAction={handleClose} secondarycolor={colors.grey.regular} secondarybgcolor={colors.white} secondarybgcolorhover={colors.grey.lighter.hue900} secondaryborder={`1px solid ${colors.grey.regular}`}
                primaryAction={props.type === 'existing' ? () => {deleteCurrency(currency.id)} : () => {props.handleRemove(); handleClose()}}
                primarybgcolor={colors.red.regular} primarybgcolorhover={colors.red.darker} primaryText="Supprimer"
            >
                Si vous supprimez cette devise celle-ci ne sera plus accessible. Si vous ne l'utilisez plus mais que vous ne souhaitez pas la supprimer, annulez la suppression et déclarer cette devise comme inactive.
            </DialogModal>
        </Grid>
    );
}


export default withApollo(CardCurrencies);