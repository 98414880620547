import React from 'react';
import { Grid, Tooltip, TextField} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import CardCustom from '../../../layouts/Card/CardCustom';
import CardProduct from '../../../layouts/Card/cardContent/CardProduct';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import styled from 'styled-components';
import { ROUTE_PRODUCTS_LIST_DETAIL, ROUTE_PRODUCTS_ADD, ROUTE_HOME } from '../../../../js/constants/route-names';
import colors from '../../../../config/theme/colors';
import { withApollo } from 'react-apollo';
import { GET_PRODUCTS_PAGINATION, SEARCH_PRODUCTS_SKU, SEARCH_PRODUCTS_SKU_BY_CAT } from '../../../../queries/products';
import { GET_CATEGORIES_LIGHT } from '../../../../queries/categories';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import importProductsTypesConfig from './config/importProductsTypes.config';
import importProductsConfig from './config/importProducts.config';
import importProductsApiConfig from './config/importProductsAPI.config';
import exportProductsConfig from './config/exportProducts.config';
import importFichier from '../../../../assets/pictos/icon-import-fichier.svg';
import importAPI from '../../../../assets/pictos/icon-import-api.svg';
import importFlux from '../../../../assets/pictos/icon-import-flux.svg';
import EmptyProduct from '../../../../assets/pictos/empty-picto/empty_products.png';
import EmptyCard from "../../../ui/empty-card/EmptyCard";
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import request from '../../../../js/utils/fetch';
import { hasRights } from '../../../../js/utils/rights';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_SUCCESS, ALERT_ERROR } from '../../../../js/constants/alert-types';
import { eventService } from '../../../../js/services/event.service';
import Pagination from '@material-ui/lab/Pagination';
import TablePagination from '@material-ui/core/TablePagination';

import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import CardProductList from '../../../layouts/Card/cardContent/CardProductList';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TraductionSelect from '../../../layouts/TopPanel/TraductionSelect';
import { PRODUCTS, PRODUCTS_PRODUCTS, VIEW, CREATE, IMPORT, EXPORT } from '../../../../js/constants/constant-rights';
import TreeCategories from '../../../ui/tree-view/TreeCategories';

const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
    // @media screen and (max-width: 1400px){
    //     padding: 16px 8px 8px!important;
    // }
`;

const PaginationCustom = styled(Pagination)`
    ul{
        justify-content: center; 
        margin-top: 24px;
    }
`;
const ViewModuleIconCustom = styled(ViewModuleIcon)`
    fill: ${colors.grey.regular};
    cursor: pointer;
    &.active{
        fill: ${colors.blue.darker.hue300};
        cursor: inherit;
    }
`;
const ViewListIconCustom = styled(ViewListIcon)`
    fill: ${colors.grey.regular};
    cursor: pointer;
    &.active{
        fill: ${colors.blue.darker.hue300};
        cursor: inherit;
    }
`;
const TextFieldCustom = styled(TextField)`
    input, .MuiSelect-select{
        font-size: 16px;
        padding: 8px;
    }
    & > p{
        margin: 0;
    }
`;
const TableCellCustom = styled(TableCell)`
    border: none;
    display: inline-block;
    width: calc(100% / 9);
    padding: 3px 16px;
`

const TablePaginationStyle = styled(TablePagination)`
    .MuiToolbar-root{
        padding: 0;
    }
    .MuiTablePagination-spacer{
        display: none;
    }
`;

const styles = theme => ({
});

const useStylesBootstrap = makeStyles(theme => ({
    arrow: {
        color: colors.blue.darker.hue300,
    },
    tooltip: {
        backgroundColor: colors.blue.darker.hue300,
        fontSize: 14,
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

class ListProducts extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            currentLang: props.locales[0].node.code,
            openForm: false,
            openFormImport: false,
            openFormExport: false,
            catalogFormData : {},
            ready: false,
            page: 0,
            nbperpage: 6,
            countPage: 0,
            cursor: null,
            cursorLast: null,
            skulist: [],
            searchValue: '',
            searchValueSku: '',
            arrayCatFilter: [],
            viewMode: 'card',
            exportName: '',
            exportLang: props.locales[0].node.code,
            exportType: 'csv',
            importTypeFile: 'csv',
            importUrl : '',
            errors: {},
            dataImportLayout: importProductsConfig,
            searchStatus: 'all',
        }
        this.errors = {}
        this.handleChange = this.handleChange.bind(this);
        this.handleSearchStatus = this.handleSearchStatus.bind(this);
        this.handleSearchSku = this.handleSearchSku.bind(this);
        this.typingSearchTimer = null;
    }

    // handleRecursiveCat = (breadcrumb) => {
    //     this.state.activeCategorie.push('Home');
    //     for (let categorie of breadcrumb){
    //         let translatedLibelle = categorie.categoryDatas.filter(e => e.attribute.identifier === 'category_name')
    //         translatedLibelle = translatedLibelle.find(e => e.locale.code === this.state.currentLang)
    //         if (translatedLibelle){
    //             this.state.activeCategorie.push(` > ${translatedLibelle.value}`);
    //         }else{
    //             this.state.activeCategorie.push(` > ${categorie.libelle}`);
    //         }
    //     }
    // };

    handleSearch = () => {
        this.props.startLoading();
        let value = this.state.searchValue;
        if (value !== "") {
            this.props.client.query({
                query: SEARCH_PRODUCTS_SKU,
                variables: {"needle": value},
                fetchPolicy: 'no-cache'
            }).then(result =>{
                let stockSku = this.state.skulist;
                this.setState({
                    skulist: [],
                    page: 0,
                    cursor: null,
                    cursorLast: null,
                }, () => {
                    if(this.state.activeCategorie.length <= 1){
                        for(let item of result.data.productDatas.edges){
                            if(this.state.skulist.indexOf(item.node.product.id) === -1){
                                this.state.skulist.push(item.node.product.id);
                            }
                        }
                    }
                    else{
                        for(let id of stockSku){
                            let check = false;
                            for(let item of result.data.productDatas.edges){
                                if(id === item.node.product.id && !check){
                                    check = true;
                                }
                            }
                            if(check){
                                this.state.skulist.push(id);
                            }
                        }
                    }
                });
                if(this.state.skulist.length === 0){
                    this.setState({noResult: true});
                    this.props.stopLoading();
                }
                else{
                    this.setState({noResult: false},() => {
                        this.handleGetProducts();
                    });
                }
            });
        } else {
            this.setState({
                skulist: [],
                noResult: false,
                cursor: null,
                cursorLast: null,
            },() => {
                if(this.state.activeCategorie.length <= 1){
                    this.handleGetProducts();
                }
                else{
                    this.handleChangeTab(this.state.idActiveCat);
                }
            });
        }
    }

    handleChange(e, value) {
        clearTimeout(this.typingSearchTimer);
        if(e?.target?.value){
            this.setState({searchValue: e.target.value});
            this.typingSearchTimer = setTimeout(() => {
                this.handleSearch();
            }, 500);
        } 
        else if(value){
            this.handleSearch();
        }
        else {
            this.setState({
                skulist: [],
                noResult: false,
                searchValue: '',
                cursor: null,
                cursorLast: null,
            }, () => {
                if(this.state.activeCategorie.length <= 1){
                    this.handleGetProducts();
                }
                else{
                    this.handleChangeTab(this.state.idActiveCat, this.state.breadcrumb);
                }
            });
        }
    }

    handleSearchStatus(e, value) {
        this.setState({
            cursor: null,
            cursorLast: null,
            searchStatus: e.target.value
        }, () => this.handleGetProducts(true));
    }

    handleSearchSku(e, value) {
            this.setState({
                cursor: null,
                cursorLast: null,
                searchValueSku: e.target.value
            }, () => this.handleGetProducts()
        );
    }

    getChildrenCat = (id) => {
        for(let categorie of this.state.categories) {
            if(categorie.id === id) {
                for(let child of categorie?.childrens){
                    this.state.arrayCatFilter.push(child.id);
                }
            }
        }
    }

    handleChangeTab = (id, breadcrumb) => {
        this.props.startLoading();
        if(id) {
            this.setState({
                idActiveCat: id,
                arrayCatFilter: [id],
                breadcrumb
            }, () => {
                this.getChildrenCat(id);

                this.props.client.query({
                    query: SEARCH_PRODUCTS_SKU_BY_CAT,
                    variables: {"idlist": this.state.arrayCatFilter},
                    fetchPolicy: 'no-cache'
                }).then(result =>{
                    this.setState({
                        skulist: [],
                        page: 0,
                        cursor: null,
                        cursorLast: null,
                    }, () => {
                        for(let item of result.data.categories){
                            for(let product of item.products.edges){
                                if(this.state.skulist.indexOf(product.node.id) === -1){
                                    this.state.skulist.push(product.node.id);
                                }
                            }
                        }
                        if(this.state.searchValue && this.state.searchValue !== ''){
                            this.handleChange(null, true);
                        }
                        else{
                            if(this.state.skulist.length === 0){
                                this.setState({noResult: true});
                                this.props.stopLoading();
                            }
                            else{
                                this.setState({noResult: false},() => {
                                    this.handleGetProducts();
                                })
                            }
                        }
                    });
                });
            });
            this.setState({activeCategorie: []}, () => {
                // this.handleRecursiveCat(breadcrumb)
            });
        } else {
            this.setState({
                activeCategorie: ['Tout voir'],
                noResult: false,
                cursor: null,
                cursorLast: null,
            }, () => {
                this.handleChange(null, true);
            });
        }
    };

    handleGetProducts(noCursor) {
        return new Promise((resolve, reject) => {
            const getProducts = GET_PRODUCTS_PAGINATION;
            let variables = 
            {
                "nbperpage": this.state.nbperpage, 
            };
            if (!noCursor){
                if(this.state.cursor){
                    variables.cursor = this.state.cursor;
                }
                if(this.state.cursorLast){
                    variables.cursorLast = this.state.cursorLast;
                }
            }
            if (this.state.searchValueSku){
                variables.sku = this.state.searchValueSku
            }
            if(this.state.skulist.length > 0){
                variables.idList = this.state.skulist.map(e => parseInt(e.replace('/api/products/', '')));
            }
            if (this.state.searchStatus && this.state.searchStatus !== 'all'){
                if (this.state.searchStatus === 'active'){
                    variables.status = true
                }else{
                    variables.status = false
                }
            }
            this.props.client.query({
                query: getProducts,
                variables,
                fetchPolicy: 'no-cache'
            }).then(result =>{
                this.listProducts = result.data.products.edges;
                this.setState({
                    cursor: result.data.products.pageInfo.startCursor,
                    cursorLast: result.data.products.pageInfo.endCursor,
                    countPage: result.data.products.totalCount
                })
                request(`${process.env.REACT_APP_API}/completude/`, 'get', false).then(
                    (data) => {
                        if(data.success){
                            for( var i = 0; i < this.listProducts.length; i++){
                                let productSku = this.listProducts[i].node.sku;
                                let infoStats = data.products[productSku];
                                this.listProducts[i].node.stats = {
                                    infoStats
                                };
                            }
                            this.forceUpdate();
                        }
                    }

                );
                if ( process.env.REACT_APP_MODE_SPREAD === "hub"){
                    request(`${process.env.REACT_APP_API}/stockCompletude`, 'get', false).then(
                        (data) => {
                            if(data.success){
                                for( var i = 0; i < this.listProducts.length; i++){
                                    let productSku = this.listProducts[i].node.sku;
                                    let infoStocks = data.products[productSku].stock;
                                    let allInfoStocks = data.products[productSku];
                                    this.listProducts[i].node.stocks = {
                                        infoStocks,
                                        allInfoStocks,
                                    };
                                }
                                this.forceUpdate();
                            }
                        }

                    );
                }
                this.setState({
                    listProducts: this.listProducts
                });
                this.props.stopLoading();
                resolve();
            });
        });
    }

    handleGetCategories() {
        return new Promise((resolve, reject) => {
            request(`${process.env.REACT_APP_API}/categories-list/product`, 'get').then(
                (data) => {
                    if(data.success){
                        this.setState({
                            categories: data.datas,
                            activeCategorie: ['Tout voir']
                        });
                    }
                    resolve();
                }
            );
        });
    }

    componentDidMount() {
        const getRights = hasRights(PRODUCTS, PRODUCTS_PRODUCTS, VIEW)
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }
        this.getAll();

        request(`${process.env.REACT_APP_API}/products-count/catalog`, 'get', false).then(
            (data) => {
                if(data.success){
                    this.setState({
                        nbCatalog: data
                    });
                }
            }
        );
        request(`${process.env.REACT_APP_API}/products-count/media`, 'get', false).then(
            (data) => {
                if(data.success){
                    this.setState({
                        nbImageTotal: data.details
                    });
                }
            }
        );
    }

    async getAll() {
        await this.handleGetProducts();
        await this.handleGetCategories();
        this.setState({ ready: true });
    }

    handleToggleDrawer = (stateDrawer) => {
        if (stateDrawer === "openFormExport"){
            this.setState({
                [stateDrawer] : !this.state[stateDrawer],
                exportName: '',
                exportLang: this.state.currentLang,
                exportType: 'csv',
            })
        }
        this.setState({ 
            [stateDrawer] : !this.state[stateDrawer]
        });
    };

    handleFormImport = (type, title) => {

        let separator = {
            type: 'select',
            label: 'Séparateur',
            translated: false,
            helper: {
                text: `Choisir le séparateur`,
                link: false,
            },
            required: false,
            stateName: 'importSep',
            value: [
                { value: ',', label: ',' },
                { value: ';', label: ';' },
                { value: '|', label: '|' }
            ]
        }
        let setupUploadFile;
        let setupUploadFileExcel;
        let setupUploadFileZip;
        let setupMapping;

        setupUploadFile = {
            type: 'uploadFile',
            label: 'Importer votre fichier CSV',
            translated: false,
            required: false,
            stateName: `upload_CSV`,
            downloadType: 'product',
        };

        setupUploadFileZip = {
            type: 'uploadFile',
            label: 'Importer votre fichier ZIP',
            translated: false,
            required: false,
            typeUpload: 'zip',
            stateName: `upload_ZIP`,
        };

        setupUploadFileExcel = {
            type: 'uploadFile',
            label: 'Importer votre fichier Excel',
            translated: false,
            required: false,
            typeUpload: 'xls',
            stateName: `upload_CSV`,
            downloadType: 'product',
        };
        
        setupMapping = {
            type: 'mapper',
            label: '',
            translated: false,
            required: false,
            mapOn: `upload_CSV`,
            stateName: `mapper`,
            staticOptions: [
                {
                    id: 'sku',
                    label: 'Sku'
                }, {
                    id: 'price',
                    label: 'Price'
                },
                {
                    id: 'stock',
                    label: 'Stock'
                }
            ]
        };

        let configFormImport = this.state.dataImportLayout;
        configFormImport.formConfig.children[0].optionsInputs = [];
        configFormImport.formConfig.children[1].optionsInputs = [];

        if (title === "excel"){
            configFormImport.formConfig.children[0].optionsInputs.push(setupUploadFileExcel);
        }else{
            configFormImport.formConfig.children[0].optionsInputs.push(separator);
            configFormImport.formConfig.children[0].optionsInputs.push(setupUploadFile);
            configFormImport.formConfig.children[0].optionsInputs.push(setupUploadFileZip);
        }
        configFormImport.formConfig.children[1].optionsInputs.push(setupMapping);

        this.setState({
            dataImportLayout: configFormImport,
            catalogFormData: {
                type,
                title
            },
            mapper: [],
            headers: title === 'api' ? '' : null,
            upload_CSV: null,
            upload_ZIP: null,
            importSep: ',',
            importValues: {},
            importLang: this.props.locales[0].node.id,
            importType: title,
            importUrl: title === 'api' ? '' : null,
        }, () => this.handleToggleDrawer('openFormImport'));
    };

    handleLang = (event) => {
        this.setState({ currentLang: event.target.value }, () => {
            eventService.fire();

            this.forceUpdate();
        });
    };

    handlerMutation = async () => {
        this.props.startLoading();

        let importConfig = {
            "url": `${process.env.REACT_APP_API_ROOT}${this.state.importType === "excel" ? `/medias/${this.state.upload_CSV?.medias?.mediaObject.filePath}` : this.state.upload_CSV?.medias?.contentUrl ?? null}`,
            "mapper": this.state.mapper,
            "eavType": this.props.attributes.eavTypes.find(e => e.node.code === 'product').node.id,
            "locale": this.state.importLang,
            "delimiter": this.state.importSep,
            "media": this.state.upload_ZIP ? `/api/media-objects/${this.state.upload_ZIP.medias.id}` : null,
        };

        try {
            // todo thomas
            await request(`${process.env.REACT_APP_API_ROOT}/api/file-imports`, 'post', importConfig, undefined, true);
            this.props.snack(ALERT_SUCCESS, `Votre fichier a été importé avec succès, son intégration dans SpreadSuite sera exécutée lors du passage du CRON (Tâche automatique).`,6000);
            this.getAll();
            this.handleToggleDrawer('openForm');
            this.handleToggleDrawer('openFormImport');
            this.props.stopLoading();
        } catch(e) {
            this.props.snack(ALERT_ERROR, `L'import a échoué !`);
            this.props.stopLoading();
        }
    };

    handlerMutationExport = async () => {
        this.props.startLoading();
        if (this.hasErrors()) {
            this.props.stopLoading();
            this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
            return eventService.fire();
        }
        try {
            let data = new FormData();
            data.append('name', this.state.exportName);
            data.append('local', this.state.exportLang);
            data.append('format', this.state.exportType);

            let urlencoded = new URLSearchParams(data).toString();

            request(`${process.env.REACT_APP_API}/export/excel/products?${urlencoded}`, 'get', null, 'application/x-www-form-urlencoded')
                .then(async (data) => {
                    if (data.success){
                        window.open(`${process.env.REACT_APP_API_ROOT}/medias/export/${data.mediaObject.filePath + '.' + (this.state.exportType === 'xls' ? this.state.exportType + 'x' : this.state.exportType)}`, '_blank');
                    }
                    this.props.stopLoading();
                    this.props.snack(ALERT_SUCCESS, `L'exportation a réussi !`);
                });
        } catch(e) {
            this.props.snack(ALERT_ERROR, `L'exportation a échoué !`);
            this.props.stopLoading();
        }
        this.handleToggleDrawer('openFormExport')
    };

    stateCallback = (stateName, value, custom, translated, callback) => {
        if(stateName === 'importSep'){
            this.setState({
                upload_CSV: null,
                upload_ZIP: null
            })
        }
        this.setState({
            [stateName]: value?.target?.value ?? value
        }, callback);
    };

    changePage = (event, page) => {
        this.props.startLoading();
        let index = 0;
        if(page > 1){
            index = ( page * this.state.nbperpage ) - this.state.nbperpage - 1
        }
        this.setState({
            cursor: page > this.state.page ? this.state.cursorLast : null,
            cursorLast: page > this.state.page ? null : this.state.cursor,
            page: page
        },()=>{
            this.handleGetProducts();
        });
    };

    handleChangePerPage = (event) => {
        this.setState({
            nbperpage: event.target.value
        }, this.changeViewMode(this.state.viewMode))
    }

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;

        errors[stateName] = error;

        this.setState({ errors });
    };

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }

        return false;
    };

    changeViewMode = (mode) => {
        this.props.startLoading();
        if (mode === "list"){
            this.setState({
                nbperpage : this.state.nbperpage === 6 || 18 || 30 ? 10 : this.state.nbperpage,
                viewMode : 'list',
                page: 0,
                cursor: null,
                cursorLast: null,
            }, () => this.handleGetProducts())
        }else{
            this.setState({
                nbperpage : 6,
                viewMode : 'card',
                page: 0,
                cursor: null,
                cursorLast: null,
            }, () => this.handleGetProducts())
        }
    }

    render() {
        const headCells = [
            { id: 'image', align: "center", style: null, disablePadding: true, label: 'Image' },
            { id: 'sku', style: null, disablePadding: false, label: 'Sku' },
            { id: 'nom', style: process.env.REACT_APP_MODE_SPREAD !== "hub" ? null : {width: 'calc(100%/3.5)'}, disablePadding: false, label: 'Nom' },
            { id: 'categories', style: process.env.REACT_APP_MODE_SPREAD !== "hub" ? null : {width: 'calc(100%/4)'}, disablePadding: false, label: 'Catégorie(s)' },
            { id: 'nbCatalogs', align: "center", style: null, disablePadding: false, label: process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'Catalogue(s)' : 'Présence dans les entrepôts'},
            { id: 'status', align: "center", style: {width: 'calc(100%/9)'}, disablePadding: false, label: 'Status' },
            process.env.REACT_APP_MODE_SPREAD !== "hub" ?
            { id: 'completion', style: {width: 'calc(100%/9) / 1.25'}, disablePadding: false, label: 'Complétude' } : null,
            process.env.REACT_APP_MODE_SPREAD !== "hub" ?
            { id: 'nbImages',align: "center", style: {width: 'calc(100%/9)'}, disablePadding: false, label: 'Nombre d\'images' } : null,
            process.env.REACT_APP_MODE_SPREAD !== "hub" ?
            { id: 'button', style: {width: 'calc(100%/9)'}, disablePadding: false, label: '' } : null,
        ];
        let productBypage = [];
        this.state.viewMode === 'list' ? productBypage = [10,20,50] : productBypage = [6,18,30];
        const { classes } = this.props;

        return (
            <div>
                <TopPanel 
                    icomoon="picto-produit"
                    colorIcomoon={colors.blue.lighter.hue300}
                    title={process.env.REACT_APP_MODE_SPREAD !== "hub" ? "Gérer les Produits" : "Liste de vos Produits"}
                    subtitle={process.env.REACT_APP_MODE_SPREAD !== "hub" ? "Liste de vos produits (création / modification / suppression)" : "Liste de vos produits ainsi que leurs stocks"}
                    handlerAdd={() => this.goTo(ROUTE_PRODUCTS_ADD)} 
                    textAdd={process.env.REACT_APP_MODE_SPREAD !== "hub" ? hasRights(PRODUCTS, PRODUCTS_PRODUCTS, CREATE) ? "Ajouter un produit" : null : null}
                    handlerImport={() => this.handleToggleDrawer('openForm')} 
                    textImport={process.env.REACT_APP_MODE_SPREAD !== "hub" ? hasRights(PRODUCTS, PRODUCTS_PRODUCTS, IMPORT) ? "Importer des produits" : null : null}
                    searchHandler={this.handleSearchSku}
                    searchPlaceholder="Recherche par SKU"
                    secondSearchHandler={this.handleChange}
                    secondSearchPlaceholder="Recherche par nom/description"
                    searchStatusHandler={this.handleSearchStatus}
                    currentStatus={this.state.searchStatus}
                    gradientColor1={colors.menu.regular} 
                    gradientColor2={colors.menu.darker}
                    windowWidth={this.props.windowWidth}
                    openForm={this.state.openForm}
                    buttonAvailable={this.state.ready}
                    // currentLang={this.state.currentLang} 
                    // handleLang={this.handleLang} 
                    // locales={this.props.locales}
                />
                {
                    (this.state.listProducts && this.state.listProducts.length > 0) ? 
                    (
                        <>
                            <div style={{background: 'white', borderBottom: `1px solid ${colors.grey.lighter.hue800}`}}>
                                <Grid container alignItems={this.props.windowWidth > 600 ? "center" : "flex-start"} direction={this.props.windowWidth > 600 ? 'row' :  'column-reverse'}>
                                    {/* <Grid item lg={9} md={9} sm={8} xs={12}>
                                        <TabCategories categories={this.state.categories} rootid={this.state.rootid} handleChangeTab={this.handleChangeTab} currentLang={this.state.currentLang}/>
                                    </Grid> */}
                                    {
                                        process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <TraductionSelect 
                                                    currentLang={this.state.currentLang} 
                                                    handleLang={this.handleLang} 
                                                    locales={this.props.locales}
                                                />
                                            </Grid>
                                        : null
                                    }
                                </Grid>
                            </div>
                            <div>
                                <Grid container direction="row" alignItems="center" style={{marginTop: 12}}>
                                    {/* <Grid item lg={10} md={9} sm={9} xs={9}>
                                        <Typography variant="body2" style={{color: colors.blue.lighter.hue300}}>{this.state.activeCategorie}</Typography>
                                    </Grid> */}
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Grid container justify="flex-end" alignItems="center">
                                            <BootstrapTooltip title="Vue carte" className={clsx(classes.tooltip)}>
                                                <ViewModuleIconCustom 
                                                style={{ fontSize: 30 }} 
                                                className={this.state.viewMode === "card" ? 'active': null}
                                                onClick={this.state.viewMode === "card" ? null : () => this.changeViewMode('card')}
                                                />
                                            </BootstrapTooltip>
                                            <BootstrapTooltip title="Vue liste" className={clsx(classes.tooltip)}>
                                                <ViewListIconCustom 
                                                style={{ fontSize: 30, marginLeft: 8 }} 
                                                className={this.state.viewMode === "list" ? 'active': null}
                                                onClick={this.state.viewMode === "list" ? null : () => this.changeViewMode('list')}
                                                />
                                            </BootstrapTooltip>

                                            {
                                                hasRights(PRODUCTS, PRODUCTS_PRODUCTS, EXPORT) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                                                    <BootstrapTooltip title="Cliquez pour exporter les produits" className={clsx(classes.tooltip)}>
                                                        <GetAppOutlinedIcon 
                                                        style={{ fontSize: 30, marginLeft: 8, cursor:'pointer', fill: colors.blue.darker.hue300 }} 
                                                        onClick={() => this.handleToggleDrawer('openFormExport')}
                                                        />
                                                    </BootstrapTooltip>
                                                : null
                                            }
                                            
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </>
                    ) : null
                }
                <Grid container direction="row" justify="center" style={{paddingTop: 8}} spacing={0}>
                    <Grid item md={2} sm={3} xs={3} style={{marginTop: 8, paddingRight: 16}}>
                        <TreeCategories query={GET_CATEGORIES_LIGHT} resultVar={"categories"} typeCategory={"product"} callbackCategorie={this.handleChangeTab} currentLang={this.state.currentLang}/>
                    </Grid>
                    <Grid item md={10} sm={9} xs={9}>
                        <Grid container direction="row" spacing={2} style={{marginTop: this.state.viewMode === "list" ? 8 : 0, marginBottom: this.state.viewMode === "list" ? 16 : 0}}>
                            {
                                this.state.viewMode === "list" ? 
                                <GridCustom item xs={12} key={`HeaderList`} style={{
                                    padding: '3px 16px',
                                    position: "sticky",
                                    top: 80,
                                    zIndex: 10,
                                    paddingTop: 20,
                                    marginTop: -20,
                                    background: "#fafafa"
                                }}>
                                    <CardCustom style={{width: "100%", height: "100%",  padding: "0"}} cardContentStyle={{height: "100%"}} contentpadding={'0'} hovercard={false}>
                                        <TableContainer component={Paper}>
                                            <Table aria-label="headerList">
                                                <TableHead>
                                                    <TableRow>
                                                        {headCells.filter(e => e!== null).map((headCell) => (
                                                            <TableCellCustom
                                                                key={headCell.id}
                                                                align={headCell.align ? headCell.align : 'left'}
                                                                style={{width: `${headCell.style?.width}`}}
                                                            >
                                                                {headCell.label}
                                                            </TableCellCustom>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                            </Table>
                                        </TableContainer>
                                    </CardCustom>
                                </GridCustom>
                                : null
                            }
                            {
                                !this.state.ready ? (<PageLoader />) : (this.state.listProducts && this.state.listProducts.length > 0 && !this.state.noResult) ? (
                                    this.state.listProducts.map((product, index) => {
                                        let truncAttrGroupId = product.node.attributeGroup.id.replace('/api/attribute-groups/', '');
                                        if(this.state.viewMode === "card"){
                                            return(
                                                <GridCustom item lg={4} md={6} sm={6} xs={12} key={`ListProduct${index}`}>
                                                    <CardCustom style={{width: "100%", height: "100%"}} cardContentStyle={{height: "100%"}} hovercard={true}>
                                                        <CardProduct 
                                                            currentLang={this.state.currentLang} 
                                                            nbCatalog={this.state.nbCatalog?.products[product.node.sku] ?? 0} 
                                                            nbImageTotal={this.state.nbImageTotal[truncAttrGroupId]} 
                                                            product={product.node} 
                                                            index={index} 
                                                            routeProduct={() => this.goTo(ROUTE_PRODUCTS_LIST_DETAIL.replace(':id', product.node.sku),product.node.id)} 
                                                            windowWidth={this.props.windowWidth}
                                                        />
                                                    </CardCustom>
                                                </GridCustom>
                                            )
                                        }else{
                                            return(
                                                <GridCustom item xs={12} key={`ListProduct${index}`} style={{padding: '3px 16px'}}>
                                                    <CardCustom style={{width: "100%", height: "100%",  padding: "0"}} cardContentStyle={{height: "100%"}} contentpadding={'0'} hovercard={false}>
                                                        <CardProductList
                                                            currentLang={this.state.currentLang} 
                                                            nbCatalog={this.state.nbCatalog?.products[product.node.sku] ?? 0} 
                                                            nbImageTotal={this.state.nbImageTotal[truncAttrGroupId]} 
                                                            product={product.node} 
                                                            index={index} 
                                                            routeProduct={() => this.goTo(ROUTE_PRODUCTS_LIST_DETAIL.replace(':id', product.node.sku),product.node.id)} 
                                                            windowWidth={this.props.windowWidth}
                                                        />
                                                    </CardCustom>
                                                </GridCustom>
                                            )
                                        }
                                        
                                    })
                                )
                                : (this.state.noResult) ?
                                (<EmptyCard title={"Aucun résultat pour cette recherche"} textButton={hasRights(PRODUCTS, PRODUCTS_PRODUCTS, CREATE) ? "Ajouter un produit" : null} onClick={() => this.goTo(ROUTE_PRODUCTS_ADD)} picto={EmptyProduct} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} mtImg={2} />)
                                : 
                                (<EmptyCard title={hasRights(PRODUCTS, PRODUCTS_PRODUCTS, CREATE) ? "Vous n'avez pas encore configuré de produit" : "Vous n'avez pas les droits pour ajouter un produit"} subtitle={hasRights(PRODUCTS, PRODUCTS_PRODUCTS, CREATE) ? "Ajouter un produit en cliquant ci-dessous" : "Faite une demande auprès d'un administrateur"} textButton={hasRights(PRODUCTS, PRODUCTS_PRODUCTS, CREATE) ? "Ajouter un produit" : null} onClick={() => this.goTo(ROUTE_PRODUCTS_ADD)} picto={EmptyProduct} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />)
                            }
                        </Grid>
                        {
                            this.state.ready && !this.state.noResult ?
                                (
                                    <TablePaginationStyle
                                        count={this.state.countPage}
                                        page={this.state.page}
                                        onChangePage={(event, page) => {this.changePage(event, page)}}
                                        color="primary"
                                        component="div"
                                        rowsPerPage={this.state.nbperpage}
                                        rowsPerPageOptions={productBypage}
                                        labelDisplayedRows={ (from=this.state.pagePresentations) => (`${from.from}-${from.to === -1 ? from.count : from.to} sur ${from.count}`)}
                                        onChangeRowsPerPage={this.handleChangePerPage}
                                        labelRowsPerPage="Produits par page :"
                                    />
                                ) : null
                        }
                    </Grid>
                </Grid>

                <LayoutBuilder 
                    opened={this.state.openForm} 
                    forClose={() => this.handleToggleDrawer('openForm')} 
                    dataLayout={importProductsTypesConfig} 
                    drawerWidth={this.props.drawerWidth} 
                    dataCard={[
                        {
                            'libelle': 'Importer via un',
                            'bicoloreText': 'flux',
                            'colorhover': '#6EAED1',
                            'picto': importFlux,
                            'disabled': true,
                            'textButton': 'Importer',
                            'description': 'Votre import produits facilité en renseignant simplement votre flux', 
                            'catalogDescription' : 'Veuillez compléter les champs ci-dessous',
                            'onClick': () => this.handleFormImport(null, 'flux')
                        },
                        {
                            'libelle': 'Importer via une',
                            'bicoloreText': 'API',
                            'colorhover': '#6EAED1',
                            'picto': importAPI,
                            'disabled': false,
                            'textButton': 'Importer',
                            'description': 'Votre import produits facilité en renseignant simplement votre API', 
                            'catalogDescription': 'Veuillez compléter les champs ci-dessous',
                            'onClick': () => this.handleFormImport(null, 'api')
                        },
                        {
                            'libelle': 'Importer un',
                            'bicoloreText': 'fichier',
                            'colorhover': '#6EAED1',
                            'picto': importFichier,
                            'disabled': false,
                            'textButton': 'Importer',
                            'description': 'Votre import produits facilité en important simplement votre fichier', 
                            'catalogDescription': 'Veuillez compléter les champs ci-dessous',
                            'onClick': () => this.handleFormImport(null, 'fichier')
                        },
                        {
                            'libelle': 'Importer un',
                            'bicoloreText': 'fichier Excel',
                            'colorhover': '#6EAED1',
                            'picto': importFichier,
                            'disabled': false,
                            'textButton': 'Importer',
                            'description': 'Votre import produits facilité en important simplement votre fichier excel', 
                            'catalogDescription': 'Veuillez compléter les champs ci-dessous',
                            'onClick': () => this.handleFormImport(null, 'excel')
                        }
                    ]}
                />

                <LayoutBuilder
                    opened={this.state.openFormImport} 
                    icomoon={'ico-import-fichier'}
                    forClose={() => this.handleToggleDrawer('openFormImport')}  
                    dataLayout={this.state.importType === 'api' ? importProductsApiConfig : this.state.dataImportLayout}
                    drawerWidth={this.state.drawerWidth}
                    handleCancel={this.handleCancel}
                    handlerMutation={this.handlerMutation} 
                    allState={this.state} 
                    stateCallback={this.stateCallback}
                    stepperButtonDisabled={[() => (
                        this.state.importType === 'api' ? this.state.importUrl === '' : this.state.upload_CSV === null )
                        , null
                    ]}
                    stepperButtonAction={[
                        null, 
                        () => {
                            let error = 0;
                            let requiredValueMapper;
                            if(this.state.requiredValueMapper){
                                let i = 0;
                                requiredValueMapper = this.state.requiredValueMapper;
                                for(let item of this.state.requiredValueMapper){
                                    for(let value of this.state.mapper){
                                        if(value && value.length > 0){
                                            for(let val of value){
                                                if(val === item.id){
                                                    error++;
                                                    requiredValueMapper[i].checked = true;
                                                }
                                            }
                                        }
                                    }
                                    i++;
                                }
                            }
                            if(error === this.state.requiredValueMapper.length){
                                return true;
                            } else {
                                this.setState({requiredValueMapper}, () => {
                                    this.props.snack(ALERT_ERROR, this.errorMapper());
                                })
                                return false;
                            }
                        }
                    ]}
                    backStepperButtonAction={[null, null, null]}
                />      
                
                <LayoutBuilder 
                    opened={this.state.openFormExport} 
                    icomoon={'ico-import-fichier'}
                    forClose={() => this.handleToggleDrawer('openFormExport')}  
                    dataLayout={exportProductsConfig(this.props.locales, 'products')} 
                    drawerWidth={this.state.drawerWidth}
                    handleCancel={this.handleCancel}
                    handlerMutation={this.handlerMutationExport} 
                    allState={this.state} 
                    stateCallback={this.stateCallback}
                    validateButton={true}
                    errorCallback={this.handleFormError}
                    checkError={() => {}}
                />      

            </div>
        );
    }

    errorMapper = () => {
        let string = '';
        let i = 0;
        for(let value of this.state.requiredValueMapper){
            if(!value.checked){
                if(i > 0){
                    string += ', ';
                }
                else{
                    string+= ' ';
                }
                string += `"${value.label}"`;
                i++;
            }
        }
        if(i===1){
            return `Le champ ${string} est requis`;
        }
        else{
            return `Les champs${string} sont requis`;
        }
    }

    goTo = (route, id) => {
        this.props.history.push({
            pathname : route,
            state: { productId : id }
        });
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
        attributes: state.attributes
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};

export default withApollo(withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ListProducts))));