import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { withApollo } from 'react-apollo';

import { SNACK, START_LOADING, STOP_LOADING } from '../../../../js/constants/action-types';
import { SET_GUIDELINE } from '../../../../js/constants/action-types';
import { ROUTE_BRAND_GUIDELINE } from '../../../../js/constants/route-names';

import { GET_ATTR_GROUPE_WITH_ATTRIBUTES } from "../../../../queries/attributes";
import { 
    GET_SYSTEM_TYPOS, 
    GET_MARKUPS, 
    ADD_GUIDELINE, 
    ADD_COLOR, 
    ADD_TYPO, 
    ADD_TYPO_DATA, 
    ADD_GUIDELINE_DATA 
} from "../../../../queries/brand_guideline";

import guidelineForm from './config/guideline';
import colors from '../../../../config/theme/colors';

import { Box, Grid } from '@material-ui/core';
import CardCustom from '../../../layouts/Card/CardCustom';
import CardMedia from '../../../layouts/Card/cardContent/CardMedia';
import CardColor from '../../../layouts/Card/cardContent/CardColor';
import Typography from '../../../ui/typography/Typography';

import Pagination from '@material-ui/lab/Pagination';

import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';

import Empty from '../../../../assets/pictos/empty-picto/empty_guidelines.png';
import pictoExternalLink from '../../../../assets/pictos/out.svg';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import PaletteOutlinedIcon from '@material-ui/icons/PaletteOutlined';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import TitleIcon from '@material-ui/icons/Title';

import slugify from 'slugify';
import styled from 'styled-components';

const Link = styled.a`
    color: ${colors.blue.lighter.hue300};
    &:hover{
        color: ${colors.blue.regular};
    }
`;
const BoxCustom = styled(Box)`
    width: auto;
`;
const ReturnLink = styled(Typography)`
    color: ${colors.blue.lighter.hue300};
    width: 70px;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
`;
const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem;
`;
const ContainerImage = styled(Box)`
    overflow: hidden;
    background-color: ${props => props.color ? props.color : colors.blue.lighter.hue900}; 
    background-image: url(${props => props.imagechanged ? props => props.backgroundimage : props.backgroundimage});
    background-position: center;
    background-repeat: no-repeat;
    height: 175px;
`;
const Logo = styled(Box)`
    background-color: white;
    background-image: url(${props => props.imagechanged ? props => props.backgroundimage : props.backgroundimage});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 50%;
    height: 170px;
    width: 170px;
    box-shadow: 0px 0px 10px #1622421A;
    margin: auto;
    margin-top: -90px;
`;
const BoxPart = styled(Box)`
    background: ${colors.grey.lighter.hue980};
    margin-bottom: 32px; 
`;
const DivTitle = styled.div`
    display: flex;
    align-items: center;
    padding: 32px;
    svg{
        margin-right: 12px;
    }
`;
const PaginationCustom = styled(Pagination)`
    ul{
        justify-content: center; 
        margin-top: 24px;
    }
`;

class GuidelineAdd extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            currentLang: this.props.locales[0].node.code,
            dataAddLayout: guidelineForm,
            openForm: false,
            openModal: false,
            editForm: 'add',
            listTypographySystems: null,
            listMarkups: null,
            listColors: [],
            nbperpage: 4,
            page: 1,
            countPage: 0,
            pageDocument: 1,
            countPageDocument: 0,
        };
    }

    handleMediaModal = (media,index,isNav=false) =>{
        let modalData = {
            ...media.node,
            index : index
        }

        if(!isNav){
            this.setState({
                openModal : !this.state.openModal,
            })  
        }
        this.setState({
            modalData : modalData
        })
    }

    handleMediaPicker=(selected,stateName)=>{
        this.handleInputChange(stateName,selected,null);  
    };

    handleInputChange = (stateName, evt, custom ) => {
        const value = evt?.target?.value ?? evt;
        this.setValue(stateName, value);
        this.forceUpdate();
    };

    setValue = (stateName, value) => {
        this.setState({
            [stateName]: value,
        }, () => {
            if(stateName === 'listColors'){
                let countPage = this.state.countPage;
                this.setState({countPage: Math.ceil(this.state.listColors.length / 4)}, () => {
                    if(countPage !== this.state.countPage){
                        this.setState({page: this.state.countPage});
                    }
                })
            }
            if(stateName === 'listDocument'){
                let countPageDocument = this.state.countPageDocument;
                this.setState({countPageDocument: Math.ceil(this.state.listDocument.length / 4)}, () => {
                    if(countPageDocument !== this.state.countPageDocument){
                        this.setState({page: this.state.countPageDocument});
                    }
                })
            }
        });
    };

    changePage = (event, page) => {
        let index = 0;
        if(page > 1){
            index = ( page * this.state.nbperpage ) - this.state.nbperpage - 1
        }
        this.setState({
            page: page
        });
    };

    changePageDocument = (event, page) => {
        let index = 0;
        if(page > 1){
            index = ( page * this.state.nbperpage ) - this.state.nbperpage - 1
        }
        this.setState({
            pageDocument: page
        });
    };

    handleToggleDrawer = () => {
        this.setState({ 
            openForm : !this.state.openForm,
        });
    };

    handleSetupForm = () => {
        let allAttr = this.state.attr.attributes.edges;
        let dataAddLayout = this.state.dataAddLayout
        dataAddLayout.formConfig.children[0].optionsInputs = [];

        let inputName = {
            type: 'text',
            label: 'Nom de la marque',
            value: '',
            translated: false,
            helper: {
                text: 'Nom de la marque',
                link: false,
            },
            required: true,
            stateName: 'guideline_name',
        };

        dataAddLayout.formConfig.children[0].optionsInputs.push(inputName)

        /* let inputImageDescription = {
            type: 'mediaPicker',
            label: 'Image Description',
            value: null,
            translated: false,
            helper: {
                text: 'Image Description',
                link: false,
            },
            required: false,
            stateName: 'imageDescription',
            handleMediaPicker :  this.handleMediaPicker,
        }; */

        this.setState({
            dataAddLayout,
        });

        for(let attr of allAttr){
            let identifier = attr.node.identifier;
            let childrenTab = 0;
            let label;
            let type;
            let value;
            if(attr.node.translation){
                for(let trans of attr.node.translation.translationDatas.edges){
                    if(trans.node.locale.code === this.state.currentLang){
                        label = trans.node.value;
                    }
                }
            }
            else{
                label = identifier;
            }
            if(identifier.search(/image|logo/i)>=0){
                type = 'mediaPicker';
            }
            else if(identifier.search(/url|name/i)>=0){
                type = 'text';
            }
            else{
                type = 'text';
                if(identifier.search(/typo/i)>=0){
                    type='select';
                    value=this.state.listElements;
                }
            }
            let input = {
                type: type,
                label: label,
                value: value,
                translated: false,
                helper: {
                    text: label,
                    link: false,
                },
                required: false,
                stateName: attr.node.identifier,
                handleMediaPicker : type === 'mediaPicker' ? this.handleMediaPicker :null,
            };

            this.state.dataAddLayout.formConfig.children[childrenTab].optionsInputs.push(input);

            /* if(type === 'mediaPicker'){
                inputImageDescription.stateName = attr.node.identifier;
                this.state.dataAddLayout.formConfig.children[childrenTab].optionsInputs.push(inputImageDescription);
            } */
        }
        this.setState({openForm: true})
    };

    getAttributes = () => {
        const getAttributes = GET_ATTR_GROUPE_WITH_ATTRIBUTES;
        this.props.client.query({
            query: getAttributes,
            fetchPolicy: 'no-cache'
        }).then(result =>{
            let attrWithGroup = result.data.attributeGroups.edges;
            for(let attrGroup of attrWithGroup){
                if(attrGroup.node.identifier === "guideline"){
                    this.setState({
                        attr: attrGroup.node
                    }, () => {
                        this.handleSetupForm()
                    });
                    if(this.state.attr){
                        let allAttr = this.state.attr.attributes.edges;
                        for(let attr of allAttr){
                            this.setState({
                                [attr.node.identifier]: null
                            });
                        }
                    }
                }
            }
        });
    };

    handlerMutation = () => {
        this.props.startLoading();
        let query = ADD_GUIDELINE;
        let identifier = slugify(this.state.guideline_name, {replacement :'_', lower: true, remove: /[^\w\-\s]+/g});
        let variables = {
            "identifier": identifier,
            "attributeGroup": this.state.attr.id,
            "libelle": this.state.guideline_name,
            "status": true
        };
        let documents = [];
                
        if(this.state.listDocument && this.state.listDocument.length > 0){
            for(let doc of this.state.listDocument){
                if(doc.id){
                    documents.push(doc.id);
                }
            }
        }
                
        if(documents.length > 0){
            variables.documents = documents;
        }

        this.props.client.mutate({
            mutation: query,
            variables,
        }).then(result => {
            this.setState({
                idGuideline: result.data.createGuideline.guideline.id
            }, async () => {
                await this.handlerSaveColors();
                await this.handlerSaveTypo();
                await this.handlerSaveGuidelineData();
                this.props.stopLoading();
                this.goTo(ROUTE_BRAND_GUIDELINE);
            })
        })
    };

    handlerSaveTypo = () => {
        return new Promise(async (resolve, reject) => {
            if(this.state.listTypos && this.state.listTypos.length > 0){
                for(let typo of this.state.listTypos){
                    let identifierTypo = `${typo.identifier}_${slugify(this.state.guideline_name, {replacement :'_', lower: true, remove: /[^\w\-\s]+/g})}`
                    let variables = {
                        "identifier": identifierTypo,
                        "guideline": this.state.idGuideline,
                        "typographySystem": typo.id
                    }
                    this.props.client.mutate({
                        mutation: ADD_TYPO,
                        variables,
                    }).then(result => {
                        let i = 0;

                        for(let markup of this.state.listMarkups){
                            let variables = {
                                "typography": result.data.createTypography.typography.id,
                                "markup": markup.node.id,
                                "px": typo.markup[i]
                            }

                            this.props.client.mutate({
                                mutation: ADD_TYPO_DATA,
                                variables,
                            })

                            i++;
                        }
                    })
                }
            }
            resolve();
        });
    };

    handlerSaveColors = () => {
        return new Promise(async (resolve, reject) => {
            if(this.state.listColors && this.state.listColors.length > 0){
                for(let color of this.state.listColors){
                    let variables = {
                        "codeHexa": color.hex,
                        "codeR": color.rgb.r,
                        "codeG": color.rgb.g, 
                        "codeB": color.rgb.b,
                        "guideline": this.state.idGuideline
                    }
                    variables.markups = color.markups;

                    this.props.client.mutate({
                        mutation: ADD_COLOR,
                        variables,
                    })
                }
            }
            resolve();
        });
    };

    handlerSaveGuidelineData = () => {
        return new Promise(async (resolve, reject) => {
            for(let attr of this.state.attr.attributes.edges){
                if(this.state[attr.node.identifier]){
                    let variables =  {
                        "guideline": this.state.idGuideline,
                        "attribute": attr.node.id, 
                        "locale": this.props.locales[0].node.id
                    }
                    if(attr.node.attributeType.input === 'file'){
                        variables.media = this.state[attr.node.identifier].id;
                    }else{
                        variables.value = this.state[attr.node.identifier];
                    }
                    
                    this.props.client.mutate({
                        mutation: ADD_GUIDELINE_DATA,
                        variables,
                    })
                }
            }
            resolve();
        });
    };

    componentDidMount = () => {
        this.props.client.query({
            query: GET_SYSTEM_TYPOS,
            fetchPolicy: 'no-cache'
        }).then(result =>{
            this.setState({ listTypographySystems: result.data.typographySystems.edges })
            this.getAttributes();
        });
        this.props.client.query({
            query: GET_MARKUPS,
            fetchPolicy: 'no-cache'
        }).then(result =>{
            this.setState({ listMarkups: result.data.markups.edges })
        });
    };

    render() {
        return (
            <div style={{width: this.state.openForm ? `calc(100% - ((50% - ${this.props.drawerWidth}px / 2) + (${this.props.drawerWidth}px / 2)))` : "100%", transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms'}}>
                <Grid container direction="column" justify="center" spacing={0} style={{marginTop: 24}}>
                    <GridCustom item xs={12}>
                        <BoxCustom onClick={this.props.history.goBack} style={{marginBottom: 16}}>
                            <ReturnLink variant={'body2'}>&lt; Retour</ReturnLink>
                        </BoxCustom>
                    </GridCustom>
                    <CardCustom cardContentStyle={{padding: 0}}>
                        <ContainerImage 
                            height={245}
                            style={{backgroundSize: this.state.imageDescription ? 'cover' : 'contain' }}
                            color={this.state.listColors.length > 0 ? this.state.listColors[0].hex : null}
                            backgroundimage={this.state.imageDescription ? `${process.env.REACT_APP_MEDIAS}/${this.state.imageDescription.filePath}` : null}
                            imagechanged={this.state.imageDescription ? this.state.imageDescription.changed : null}
                        />
                        <Logo backgroundimage={this.state.guideline_logo ? `${process.env.REACT_APP_MEDIAS}/${this.state.guideline_logo.filePath}` : Empty} />
                        <Box style={{padding: 16}}>
                            <Box style={{marginBottom: 16}}>
                                <Typography variant="h2" colortext={colors.blue.regular} style={{fontSize: 30, lineHeight: '40px', textAlign: 'center'}} component="div">
                                    <Box fontWeight="bold">
                                        {this.state.guideline_name ? this.state.guideline_name : 'Nom de la marque'}
                                    </Box>
                                </Typography>
                                <Typography variant="body1" component="div" style={{lineHeight: "32px", textAlign: 'center'}}>
                                    {this.state.guideline_url ? 
                                        (
                                            <Link href={this.state.guideline_url} target="_blank">
                                                {this.state.guideline_url}
                                                <img src={pictoExternalLink} alt="Voir le site" style={{display: 'inline-block', height: 13, width: 13, marginLeft: 13}} />
                                            </Link>
                                        )
                                        : null
                                    }
                                </Typography>
                                <Typography variant="body1" component="div" style={{lineHeight: "32px", textAlign: 'center', color: colors.grey.regular}}>
                                    {this.state.guideline_description ? this.state.guideline_description : 'Description'}
                                </Typography>
                            </Box>
            
                            {
                                this.state.guideline_logo ? (
                                    <BoxPart>
                                        <DivTitle>
                                            <InsertEmoticonIcon style={{color: colors.blue.regular}} />
                                            <Typography variant="body1" component="div" style={{lineHeight: "32px", textAlign: 'center', color: colors.blue.regular}}>
                                                <Box fontWeight="bold">
                                                    Logo
                                                </Box>
                                            </Typography>
                                        </DivTitle>
                                        <div style={{padding: 16}}>
                                            <CardCustom style={{width: 'calc(50% - 22px)', margin: '0 10px', display: 'inline-block'}} cardContentStyle={{padding: 8}}>
                                                <CardMedia media={this.state.guideline_logo} modal={this.handleMediaModal}></CardMedia>
                                            </CardCustom>
                                        </div>
                                    </BoxPart>
                                ) : null
                            }
            
                            {
                                this.state.listColors && this.state.listColors?.length > 0 ? (
                                    <BoxPart>
                                        <DivTitle>
                                            <PaletteOutlinedIcon style={{color: colors.blue.regular}} />
                                            <Typography variant="body1" component="div" style={{lineHeight: "32px", textAlign: 'center', color: colors.blue.regular}}>
                                                <Box fontWeight="bold">
                                                    Couleurs
                                                </Box>
                                            </Typography>
                                        </DivTitle>
                                        <div style={{padding: '0 16px 16px'}}>
                                            {
                                                this.state.listColors.map((color, index) => (
                                                    this.state.nbperpage * this.state.page - this.state.nbperpage <= index && this.state.nbperpage * this.state.page > index ? (
                                                        <CardCustom style={{width: 'calc(50% - 22px)', margin: 10, display: 'inline-block'}} cardContentStyle={{padding: 0}} key={`card-color-${index}`}>
                                                            <CardColor color={color.hex ? color.hex : null}></CardColor>
                                                        </CardCustom>
                                                    ) : null
                                                ))
                                            }
                                            {
                                                this.state.listColors.length > 4 ? (
                                                    <PaginationCustom onChange={(event, page) => {this.changePage(event, page)}} page={this.state.page} count={this.state.countPage} color="primary" />
                                                ) : null
                                            }
                                        </div>
                                    </BoxPart>
                                ) : null
                            }
            
                            {   
                                this.state.listTypos && this.state.listTypos?.length > 0 ? (
                                    <BoxPart>
                                        <DivTitle>
                                            <TitleIcon style={{color: colors.blue.regular}} />
                                            <Typography variant="body1" component="div" style={{lineHeight: "32px", textAlign: 'center', color: colors.blue.regular}}>
                                                <Box fontWeight="bold">
                                                    Typography
                                                </Box>
                                            </Typography>
                                        </DivTitle>
                                        <div>
                                            {
                                                this.state.listTypos.map((typo, index) => (
                                                    <CardCustom style={{width: 'calc(100% - 50px)', margin: '0 25px', display: 'inline-block'}} cardContentStyle={{padding: 16}} key={`card-typo-${index}`}>
                                                        <span>{typo.libelle}</span>
                                                        {
                                                            this.state.listMarkups.map((markup, index) => (
                                                                <div style={{position: 'relative', borderTop: `1px solid ${colors.grey.lighter.hue900}`, display: 'flex', flexDirection: 'column' }} key={`typo-markup-${index}`}>
                                                                    <p style={{fontSize: 16, margin: "10px 0 2px", lineHeight: '1.3', color: colors.grey.regular }}>{markup.node.libelle} : {typo.markup[index]}px</p>
                                                                    <p style={{ fontFamily: typo.value, fontSize: typo.markup[index], margin: "2px 0 8px", lineHeight: '1.3', height: typo.markup[index] * 1.3, overflow: 'hidden', transition: 'all ease 0.5s' }}>{markup.node.libelle} : A Visual Type Scale</p>
                                                                </div>
                                                            ))
                                                        }
                                                    </CardCustom>
                                                ))
                                            }
                                        </div>
                                    </BoxPart>
                                ) : null
                            }
            
                            {
                                this.state.listDocument && this.state.listDocument?.length > 0 && (this.state.listDocument?.length >= 1 && this.state.listDocument[0].id !== "") ? (
                                    <BoxPart>
                                        <DivTitle>
                                            <PermMediaIcon style={{color: colors.blue.regular}} />
                                            <Typography variant="body1" component="div" style={{lineHeight: "32px", textAlign: 'center', color: colors.blue.regular}}>
                                                <Box fontWeight="bold">
                                                    Documents
                                                </Box>
                                            </Typography>
                                        </DivTitle>
                                        <div style={{padding: 16}}>
                                            {
                                                this.state.listDocument.map((document, index) => (
                                                    this.state.nbperpage * this.state.pageDocument - this.state.nbperpage <= index && this.state.nbperpage * this.state.pageDocument > index ? (
                                                        document.id !== '' ? (
                                                            <CardCustom style={{width: 'calc(50% - 22px)', margin: '0 10px', display: 'inline-block'}} cardContentStyle={{padding: 8}} key={`card-documents-${index}`}>
                                                                <CardMedia media={document} modal={this.handleMediaModal}></CardMedia>
                                                            </CardCustom>
                                                        ) : null
                                                    ) : null
                                                ))
                                            }
                                            {
                                                this.state.listDocument.length > 4 ? (
                                                    <PaginationCustom onChange={(event, page) => {this.changePageDocument(event, page)}} page={this.state.pageDocument} count={this.state.countPageDocument} color="primary" />
                                                ) : null
                                            }
                                        </div>
                                    </BoxPart>
                                ) : null
                            }
                        </Box>            
                    </CardCustom>
                </Grid>
                <LayoutBuilder
                    icomoon={"icon-dashboard"}
                    opened={this.state.openForm} 
                    forClose={() => {this.goTo(ROUTE_BRAND_GUIDELINE)}}
                    dataLayout={this.state.dataAddLayout} 
                    allState={this.state} 
                    stateCallback={this.handleInputChange}
                    handlerMutation={this.handlerMutation}
                    currentLang={this.state.currentLang}
                    hideInput={this.state.inputHidden}
                    validateButton={true}
                    drawerWidth={this.props.drawerWidth}
                />
            </div>
        );
    };

    goTo = route => {
        this.props.history.push(route);
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setGuideline: (guideline) => dispatch({ type: SET_GUIDELINE, payload: { guideline }}),
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(GuidelineAdd)));
