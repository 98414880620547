import {COMPONENT} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';
import ProductSelectorByCategories from '../components/ProductSelectorByCategories'
export default function ProductSelectorConfig(){
var obj =
    {
        titleForm: 'Ajouter des produits',
        subTitleForm: 'Ajouter des produits à cette catégorie',
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type : COMPONENT,
            component : ProductSelectorByCategories
        }
    }
    return obj;

}
