import {FORM} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';

const EditUser =
    {
        titleForm: 'Modifier un utilisateur',
        subTitleForm: "Veuillez compléter les champs ci-dessous pour modifier l'utilisateur",
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: FORM,
            children:[
                {
                    optionsInputs:[
                        {
                            type: 'oneImage',
                            label: 'Photo de profil',
                            translated: false,
                            helper:  {
                                text: 'Choisir une image de profil',
                                link: false,
                            },
                            required: false,
                            stateName: 'image'
                        },
                        {
                            type: 'text',
                            label: 'Nom',
                            helper: {
                                text: 'Indiquez le nom ici',
                                link: false,
                            },
                            required: true,
                            stateName: 'lastname',
                        },
                        {
                            type: 'text',
                            label: 'Prénom',
                            helper: {
                                text: 'Indiquez le prénom ici',
                                link: false,
                            },
                            required: true,
                            stateName: 'firstname',
                        },
                        {
                            type: 'text',
                            email: true,
                            label: 'Adresse email',
                            helper: {
                                text: 'Indiquez l\'adresse email',
                                link: false,
                            },
                            required: true,
                            stateName: 'email',
                        },
                        {
                            type: 'text',
                            label: 'Téléphone',
                            helper: {
                                text: 'Indiquez le numéro de téléphone ici',
                                link: false,
                            },
                            required: false,
                            stateName: 'phone',
                        },
                        {
                            type: 'select',
                            label: 'Rôle',
                            disabled: true,
                            helper: {
                                text: 'Choississez un rôle',
                                link: false,
                            },
                            required: true,
                            stateName: 'roles',
                            value: [
                                {
                                    value: 'super_admin',
                                    label: 'Super Administrateur',
                                },
                                {
                                    value: 'admin',
                                    label: 'Administrateur',
                                },
                                {
                                    value: 'ROLE_USER',
                                    label: 'Utilisateur',
                                },
                                
                            ]
                        },
                        
                        {
                            type: 'switch',
                            label: 'Actif',
                            helper: {
                                label: 'État',
                                text: 'Indiquez l\'état',
                                link: false,
                            },
                            required: true,
                            stateName: 'isActive',
                            value: [
                                {
                                    value: true,
                                    label: 'Actif',
                                },
                                {
                                    value: false,
                                    label: 'Inactif',
                                },
                            ]
                        },
                        {
                            type: 'switch',
                            label: 'Modifier le mot de passe',
                            stateName: 'editPassword',
                            value: [
                                {
                                    value: true,
                                    label: 'Actif',
                                },
                                {
                                    value: false,
                                    label: 'Inactif',
                                },
                            ]
                        },

                        {
                            type: 'switch',
                            label: 'Notifier le mot de passe par email',
                            helper: {
                                text: 'Notification',
                                link: false,
                            },
                            required: true,
                            stateName: 'notif',
                            value: [
                                {
                                    value: true,
                                    label: 'Par mail',
                                },
                                {
                                    value: false,
                                    label: 'Afficher à l\'écran',
                                },
                            ]
                        },

                        {
                            type: 'switch',
                            label: 'Générer le mot de passe',
                            helper: {
                                text: 'Génération',
                                link: false,
                            },
                            required: true,
                            stateName: 'generate',
                            value: [
                                {
                                    value: true,
                                    label: 'Par Spreadsuite',
                                },
                                {
                                    value: false,
                                    label: 'Par moi',
                                },
                            ]
                        },
                        {
                            type: 'password',
                            label: 'Nouveau mot de passe',
                            helper: {
                                text: 'Entrez le mot de passe',
                                link: false,
                            },
                            required: true,
                            stateName: 'plainPassword',
                        },
                        {
                            type: 'password',
                            confirm: true,
                            label: 'Confirmer le mot de passe',
                            helper: {
                                text: 'Répétez le mot de passe',
                                link: false,
                            },
                            required: true,
                            stateName: 'plainPasswordRepeat',
                        },
                    ]
                },
            ]
        }
    }

export default EditUser;