import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, List, ListItem, Checkbox } from '@material-ui/core';
import styled from 'styled-components';
import colors from '../../../config/theme/colors';
import notFound from '../../../assets/images/not-found.png';
import ButtonCustom from '../../ui/button/Button';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

import * as Pagination from '../../../js/utils/pagination';
import PageLoader from "../../ui/loadings/page-loader/PageLoader";

const ListCustom = styled(List)`
    padding: 24px;
    display: block;
    max-height: 100%;
`;
const ListItemCustom = styled(ListItem)`
    padding: 4px 8px 12px 8px !important;
    cursor: pointer;
`;
const CheckboxCustom = styled(Checkbox)`
    justify-content: flex-start;
    position: absolute;
    background: white;
    border-radius: 5px;
    width: 35px;
    height: 27px;
    margin-top: 10px;
    svg{
        fill: ${colors.blue.lighter.hue300}
    }
    &:hover{
        background: white!important;
        svg{
            fill: ${colors.blue.lighter.hue150}
        }
    }
    input{
        width: 40px;
    }
`;
const ContainerImage = styled(Grid)`
    min-height: 150px;
    max-height: 150px;
    overflow: hidden;
    flex-wrap: nowrap;
`;
const ImageProduct = styled.img`
    object-fit: contain;
`;
const TextProduct = styled(Typography)`
    text-align: center;
    margin-top: -10px;
    font-size: 12px;
`;


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        maxHeight: 400,
        overflow: "auto",
        height: "100%",
    },
}));


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3} style={{paddingRight: 0}}>
                    <Typography style={{paddingRight: 24}}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export default function CheckboxItems(props) {
    const classes = useStyles();
    const [activeCategorie, setActiveCategorie] = React.useState('');
    const [load, setLoad] = React.useState(true);
    const [productsList, setProductsList] = React.useState('');

    const changePage = async(newPage)=>{
        let pagination = await Pagination.changePage(props.pagination,newPage);
        let custom = {paginationCheckboxItem : pagination};
        props.stateCallback(null, custom)
    }

    const changePerPage = async (perPage) => {
        let pagination = await Pagination.updatePerPage(props.pagination,perPage);
        let custom = {paginationCheckboxItem : pagination};
        props.stateCallback(null, custom)
    }

    const handleToggle = (value) => {
        if(props.selectedProductByCategorie[props.selectedCategorie]){
            const currentIndex  = props.selectedProductByCategorie[props.selectedCategorie]?.indexOf(value.node.id);
            const newChecked    = [...props.selectedProductByCategorie[props.selectedCategorie]];
            
            if (currentIndex === -1) {
                newChecked.push(value.node.id);
            } else {
                newChecked.splice(currentIndex, 1);
            }
    
            props.setSelectedProducts(newChecked);
        }
    };
    
    useEffect(() => {
        setLoad(props.allState.load)
    }, [props.allState]);

    useEffect(() => {
        if(props.selectedCategorie){
            let categorie = props.allState.allCategories.find(element => element.id === props.selectedCategorie);
            setActiveCategorie(categorie)
        }
    }, [props.selectedCategorie]);


    return (
        <div style={{padding: 0}}>
            <ListCustom dense className={classes.root}>
                <Grid container direction="row">
                    <Grid item xs={12} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        { activeCategorie?.libelle ? activeCategorie.libelle : null }
                        { props.selectedCategorie ? <ButtonCustom onClick={props.addProducts}>Ajouter des produits </ButtonCustom> : null}
                    </Grid>
                    {
                        !props.productByCategorie[props.selectedCategorie] ? 
                            <div>Veuillez choisir une catégorie</div> 
                        : load ? (<PageLoader /> )
                        : props.productByCategorie[props.selectedCategorie].length > 0 ? 
                            props.productByCategorie[props.selectedCategorie].map((value, index) => {
                                const labelId   = `checkbox-list-secondary-label-${value}`;
                                const datas     = value.node.productDatas?.edges;
                                if(datas){
                                    let allNames    = datas.filter(e => e.node.attribute.identifier === 'product_name');
                                    let name        = allNames.find(e => e.node.locale.code === props.currentLang);
                    
                                    let allImages   = datas.filter(e => e.node.attribute.identifier === 'product_image');
                                    let image       = allImages.find(e => e.node.locale.code === props.currentLang);

                                    let nameValue   = name ? name.node.value : ((allNames[0]?.node.value ?? null) || value.node.identifier);
                                    let imageValue  = image ? image.node.media?.filePath ?? '' : allImages[0]?.node.media?.filePath ?? null;

                                    return (
                                        <Grid item xs={3} key={`ItemCheckbox${index}`}>
                                            <ListItemCustom onClick={() => handleToggle(value)}>
                                                <Grid container direction="column">
                                                    <CheckboxCustom
                                                        edge="start"
                                                        checked={props.selectedProductByCategorie[props.selectedCategorie]?.indexOf(value.node.id) !== -1 ? false : true}
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                    <ContainerImage container justify={"center"}>
                                                        <ImageProduct src={!imageValue ? notFound : `${process.env.REACT_APP_MEDIAS}/${imageValue}`} width="100%" alt={`Image product ${index}`} />
                                                    </ContainerImage>
                                                    <TextProduct variant ={'body2'}>
                                                        {nameValue}
                                                    </TextProduct>
                                                </Grid>
                                            </ListItemCustom>
                                        </Grid>
                                    );
                                }
                                else if(!datas){
                                    return (<div>Aucun produit pour cette catégorie</div>);
                                }
                            }) 
                        : <div>Aucun produit pour cette catégorie</div>
                    }
                    {
                        !props.productByCategorie[props.selectedCategorie] || load ? 
                            null 
                        : props.productByCategorie[props.selectedCategorie].length > 0 ? 
                            <Pagination.CursorPagination
                                label = 'Produits par page'
                                pagination = {props.pagination}
                                type = "table"
                                changePageCallback = {changePage}
                                perPageOptions = {[8,12]}
                                rowLabel="Produits par page :"
                                changePerPageCallback={changePerPage}
                            />
                        : null
                    }
                </Grid>
            </ListCustom>
        </div>
    );
}