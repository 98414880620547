import React from 'react';
import {Grid, Box} from '@material-ui/core';
import { withRouter } from 'react-router';
import { withApollo } from 'react-apollo';
import { connect } from "react-redux";

import Typography from '../../../ui/typography/Typography';
import colors from '../../../../config/theme/colors';

import CardCustom from '../../../layouts/Card/CardCustom';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import feedAddConfig from './config/feeds/feedAdd.config';
import Builder from '../../../../builder/builder';
import request from '../../../../js/utils/fetch';
import { GET_ASSET_BY_ID } from '../../../../queries/assets';
import styled from 'styled-components';
import 'moment-timezone';
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";


import { getDefaultConfig } from '../../../../builder/utils/data';
import { Pages } from '../../../../builder/shareable/types';

const ReturnLink = styled(Typography)`
    color: ${colors.blue.lighter.hue300};
    width: 70px;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
`

class DiffusionPermanentPosts extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            filtered: [],
            ready: false,
            openFormFeedAdd: true,
            postType: 'classic',
            idAsset: this.props.location.state.assetId,
            config: null
        };
    }

    componentDidMount() {
        this.handleGetAssetDatas();
    }

    handleButtonGroupChange = (stateName, value) => {
        this.setState({
            [stateName]: value
        }, () => {
            this.initValues();
        });
    };

    handleToggleDrawer = (stateDrawer, changeWidth) => {
        this.setState({ 
            [stateDrawer]: !this.state[stateDrawer],
            errors: {},
        });

        if (changeWidth) {
            this.handleDrawerWidthChange(300);
        } 
        
    };

    getFeed() {
        return new Promise((resolve) => {
            //---- get feed
            let channelIdentifier   = this.state.assetData.channel.identifier;
            let channelType         = this.state.assetData.channel.attributeGroup.identifier;

            let pathChannelType;
            let listAction;

            if (channelType === 'facebook') {
                pathChannelType     = 'facebook-page';
                listAction          = 'posts';
            } else if (channelType === 'twitter') {
                pathChannelType     = 'twitter';
                listAction          = 'tweets';
            } else if (channelType === 'wordpress') {
                pathChannelType     = 'wordpress';
                listAction          = 'posts';
            }

            this.setState({
                requesting: true
                }, () => {
                request(`${process.env.REACT_APP_API}/assets/${pathChannelType}/${channelIdentifier}/${listAction}`, 'get', {}, 'application/json', true)
                .then(async (data) => {
                    //--- parse response to get posts
                    if (channelType === 'facebook') {
                        this.setState({posts:data.posts});
                    } else if (channelType === 'twitter') {
                        this.setState({posts:data[0]});
                    } else if (channelType === 'wordpress') {
                        this.setState({posts:data.posts});
                    }

                    resolve();
                });
            });
        })
    }

    setValue = (stateName, value, translated) => {
        if (translated) {
            let values = this.state[this.state.currentLang];

            if (!values) {
                values = {};
            }

            values[stateName] = value;

            this.setState({
                [this.state.currentLang]: values,
            });
        } else{
            this.setState({
                [stateName]: value,
            }, () => {
                this.updateBuilderValues();
            });
        }
    };

    getDataInfo = () => {
        let getTitleData = '';
        let getDescriptionData = '';
        let getImageData = '';
        if (this.state.postType === 'product'){
            getTitleData         = this.state.postProduct?.productDatas.edges.find(e => e.node.attribute.identifier === 'product_name').node.value;
            getDescriptionData   = this.state.postProduct?.productDatas.edges.find(e => e.node.attribute.identifier === 'product_description').node.value;
            getImageData         = this.state.postProduct?.productDatas.edges.find(e => e.node.attribute.identifier === 'product_image').node.media;
        }else if(this.state.postType === 'content'){
            getTitleData         = this.state.postContent?.contentDatas.edges.find(e => e.node.attribute.identifier === 'content_title').node.value;
            getDescriptionData   = this.state.postContent?.contentDatas.edges.find(e => e.node.attribute.identifier === 'content_description').node.value;
            getImageData        = this.state.postContent?.contentDatas.edges.find(e => e.node.attribute.identifier === 'content_image').node.media;
        }
        return {
            name: getTitleData,
            description: getDescriptionData,
            image: getImageData
        };
    };

    handleInputChange = (stateName, evt, custom, translated ) => {
        const value = evt?.target?.value ?? evt;
        this.setValue(stateName, value, translated);
    };

    handleMediaPicker = (selected,stateName) => {
        this.handleInputChange(stateName, selected, null, this.state.currentLang);  
    };

    handleGetAssetDatas = () => {
        this.setState({
            filteredAssets: null
        });

        const getAssetsByType = GET_ASSET_BY_ID;

        this.props.client.query({
            query: getAssetsByType,
            variables: { id: this.state.idAsset },
            fetchPolicy: 'no-cache'
        }).then(GET_ASSET_BY_ID_RESULT => {
            let currentAsset = GET_ASSET_BY_ID_RESULT.data.asset;
            
            this.setState({
                assetData: currentAsset,
                imageAsset: currentAsset.assetType.logo,
                catalogAsset: currentAsset.catalog,
                ready: true
            }, () => {
                this.initValues();
            });
        })
    }

    initValues = () => {
        let channelType = this.state.assetData.channel.attributeGroup.identifier;
        let config = {};

        switch(channelType) {
            case Pages.WORDPRESS:
                config[Pages.WORDPRESS] = getDefaultConfig(channelType);
                this.setState({
                    ...this.extractBuilderValues(config),
                    config: {
                        params: {},
                        ...config
                    }
                });
                break;
            case Pages.FACEBOOK:
                config[Pages.FACEBOOK] = getDefaultConfig(channelType);

                if (this.state.postType === 'product' || this.state.postType === 'content') {
                    let image = config[Pages.FACEBOOK].elements.find(e => e.key === 'image');
                    image.hide = false;
                    image.noAction = true;

                    let link = config[Pages.FACEBOOK].elements.find(e => e.key === 'link');
                    link.data.blocks.content.inputs.value.value = this.state.postType === 'product' ? 'Titre du produit' : 'Titre du contenu';
                    link.hide = false;
                }

                this.setState({
                    ...this.extractBuilderValues(config),
                    config: {
                        params: {},
                        ...config
                    }
                });
                break;
            default: return;
        }
    };

    extractBuilderValues = (config) => {
        let channelType = this.state.assetData.channel.attributeGroup.identifier;

        switch(channelType) {
            case Pages.WORDPRESS:
                return {
                    paramText: config[Pages.WORDPRESS].elements.find(e => e.key === 'title').data.blocks.content.inputs.value.value,
                    paramText2: config[Pages.WORDPRESS].elements.find(e => e.key === 'content').data.blocks.content.inputs.value.value
                };
            case Pages.FACEBOOK:
                return {
                    paramImage: config[Pages.FACEBOOK].elements.find(e => e.key === 'image').data.blocks.image.inputs.value.value,
                    paramLink: config[Pages.FACEBOOK].elements.find(e => e.key === 'link').data.blocks.content.inputs.value.value,
                    paramText: config[Pages.FACEBOOK].elements.find(e => e.key === 'content').data.blocks.content.inputs.value.value,
                };
            default: return {};
        }
    };

    updateBuilderValues = () => {
        let channelType = this.state.assetData.channel.attributeGroup.identifier;
        let dataInfo = this.getDataInfo();
        let config = { ...this.state.config };

        switch(channelType) {
            case Pages.FACEBOOK:
                config[Pages.FACEBOOK].elements.find(e => e.key === 'image').data.blocks.image.inputs.value.value        = this.state.postType === 'classic' ? this.state.paramImage : dataInfo.image;
                config[Pages.FACEBOOK].elements.find(e => e.key === 'link').data.blocks.content.inputs.value.value       = this.state.postType === 'classic' ? this.state.paramLink : dataInfo.name;
                config[Pages.FACEBOOK].elements.find(e => e.key === 'content').data.blocks.content.inputs.value.value    = this.state.postType === 'classic' ? this.state.paramText : dataInfo.description;

                if (this.state.postType === 'classic') {
                    config[Pages.FACEBOOK].elements.find(e => e.key === 'image').hide = this.state.paramLink ? true : false;
                    config[Pages.FACEBOOK].elements.find(e => e.key === 'link').hide = this.state.paramLink ? false : true;
                }

                break;
            case Pages.WORDPRESS:
                config[Pages.WORDPRESS].elements.find(e => e.key === 'title').data.blocks.content.inputs.value.value      = this.state.postType === 'classic' ? this.state.paramText : dataInfo.name;
                config[Pages.WORDPRESS].elements.find(e => e.key === 'content').data.blocks.content.inputs.value.value    = this.state.postType === 'classic' ? this.state.paramText2 : dataInfo.description;
                break;
            default: return;
        }

        this.updateBuilder(config);
    }

    updateBuilder = (config) => {
        let newConfig = { ...config };

        this.setState({ 
            config: newConfig,
            ...this.extractBuilderValues(newConfig)
        });
    };
    
    render() {
        const assetData = this.state.assetData;

        return (
            <div>
                {this.state.ready && assetData ? 
                    <>
                        <CardCustom style={{width: this.state.openFormFeedAdd ? `calc(100% - ((50% - ${this.props.drawerWidth}px / 2) + (${this.props.drawerWidth}px / 2) + 32px))` : "100%", marginTop: 8, transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms'}}>
                            <Grid container justify={'space-between'} alignItems='flex-start'>
                                <Box onClick={this.props.history.goBack} style={{marginBottom: 25}}>
                                    <ReturnLink variant={'body2'}>&lt; Retour</ReturnLink>
                                </Box>

                                {
                                    this.state.config && <Builder 
                                        onlyPreview={false} 
                                        onlyLeft={true} 
                                        noToolbar={true} 
                                        assetData={assetData} 
                                        pageToEdit={assetData.channel.attributeGroup.identifier}
                                        externalConfig={this.state.config}
                                        updateExternalConfig={this.updateBuilder}
                                    />
                                }
                                
                            </Grid>
                        </CardCustom>
                    </>
                : <PageLoader />}

                { this.state.ready && assetData?.assetType.hasFeed === true
                    ?
                    <>
                        <LayoutBuilder
                            opened                  = {this.state.openFormFeedAdd}
                            dataLayout              = {feedAddConfig(this.state.disabledLink, this.state.disabledImage, assetData, this.handleMediaPicker, this.state.postType)} 
                            forClose                = {() => {this.handleToggleDrawer('openFormFeedAdd')}}  
                            image                   = {this.state.imageAsset}
                            allState                = {this.state} 
                            stateCallback           = {this.handleInputChange} 
                            handlerMutation         = {this.addFeed} 
                            handleCancel            = {this.props.history.goBack} 
                            validateButton          = {true}
                            handleButtonGroupChange ={this.handleButtonGroupChange}
                            catalogDatas            = {this.state.catalogAsset}
                        />

                    </>
                    :null
                }
            </div>
        );
    }

    addFeed = async () => {
        //---- add feed
        let channelIdentifier = this.state.assetData.channel.identifier;
        let channelType = this.state.assetData.channel.attributeGroup.identifier;
        let postType = this.state.postType;

        let pathChannelType;
        let createAction;
        let bodyDatas;
        let imageUrl=null;
        if(this.state.paramImage){
            imageUrl=process.env.REACT_APP_API_ROOT+'/medias/'+this.state.paramImage.filePath
                    
        }

        if(channelType === 'facebook'){
            pathChannelType = 'facebook-page';
            createAction = 'posts';
            debugger;
            if(postType === 'classic'){
                bodyDatas = {
                    message: this.state.paramText,
                    //--- link or imageUrl
                    link: this.state.paramLink ? this.state.paramLink : null,
                    imageUrl: this.state.paramImage ? imageUrl : null,
                };
            }else if(postType === 'product'){
                let getTitleProduct = this.state.postProduct?.productDatas.edges.find(e => e.node.attribute.identifier === 'product_name').node.value;
                let getDescriptionProduct = this.state.postProduct?.productDatas.edges.find(e => e.node.attribute.identifier === 'product_description').node.value;
                let publication = getTitleProduct + '\n' + getDescriptionProduct;
                let getImageProduct = this.state.postProduct?.productDatas.edges.find(e => e.node.attribute.identifier === 'product_image').node.media.filePath;
                imageUrl= process.env.REACT_APP_API_ROOT+'/medias/'+getImageProduct
                bodyDatas = {
                    message: publication,
                    //--- link or imageUrl
                    link: this.state.paramLink ? this.state.paramLink : null,
                    imageUrl: getImageProduct ? imageUrl : null,
                };
            } else if (postType === 'content'){
                let getTitleContent = this.state.postContent?.contentDatas.edges.find(e => e.node.attribute.identifier === 'content_title').node.value;
                let getDescriptionContent = this.state.postContent?.contentDatas.edges.find(e => e.node.attribute.identifier === 'content_description').node.value;
                let publication = getTitleContent + '\n' + getDescriptionContent;
                let getImageContent = this.state.postContent?.contentDatas.edges.find(e => e.node.attribute.identifier === 'content_image').node.media.filePath;
                imageUrl= process.env.REACT_APP_API_ROOT+'/medias/'+getImageContent
                bodyDatas = {
                    message: publication,
                    //--- link or imageUrl
                    link: this.state.paramLink ? this.state.paramLink : null,
                    imageUrl: getImageContent ? imageUrl : null,
                };
            }
            

        } else if(channelType === 'twitter'){
            pathChannelType = 'twitter';
            createAction = 'tweets';
            let mediasUrl=[];
            if(postType === 'classic'){
                let medias =[this.state.paramTwitterFirstMedia,this.state.paramTwitterSecondMedia,this.state.paramTwitterThirdMedia,this.state.paramTwitterFourthMedia];
                for(let media of medias){
                    if(media !== null){
                        mediasUrl.push(process.env.REACT_APP_API_ROOT+'/medias/'+media.filePath);
                    }
                }
                
                bodyDatas = {
                    message: this.state.paramText.toString().substring(0,279),
                    //---- 4 images max
                    medias: mediasUrl
                };
            } else if(postType === 'product'){
                let getTitleProduct = this.state.postProduct?.productDatas.edges.find(e => e.node.attribute.identifier === 'product_name').node.value;
                let getDescriptionProduct = this.state.postProduct?.productDatas.edges.find(e => e.node.attribute.identifier === 'product_description').node.value;
                let publication = getTitleProduct + '\n' + getDescriptionProduct;
                let getImageProduct = this.state.postProduct?.productDatas.edges.find(e => e.node.attribute.identifier === 'product_image').node.media.filePath
                mediasUrl.push(process.env.REACT_APP_API_ROOT+'/medias/'+getImageProduct);
                bodyDatas = {
                    message: publication.toString().substring(0,279),
                    //---- 4 images max
                    medias: mediasUrl
                };
            } else if (postType === 'content'){
                let getTitleContent = this.state.postContent?.contentDatas.edges.find(e => e.node.attribute.identifier === 'content_title').node.value;
                let getDescriptionContent = this.state.postContent?.contentDatas.edges.find(e => e.node.attribute.identifier === 'content_description').node.value;
                let publication = getTitleContent + '\n' + getDescriptionContent;
                let getImageContent = this.state.postContent?.contentDatas.edges.find(e => e.node.attribute.identifier === 'content_image').node.media.filePath;
                mediasUrl.push(process.env.REACT_APP_API_ROOT+'/medias/'+getImageContent);
                bodyDatas = {
                    message: publication.toString().substring(0,279),
                    //---- 4 images max
                    medias: mediasUrl
                };
            }
        } else if(channelType === 'wordpress'){
            pathChannelType = 'wordpress';
            createAction = 'posts';
            let mediasUrl=[];
            if(postType === 'classic'){
                bodyDatas = {
                    titre: this.state.paramText,
                    message: this.state.paramText2,
                };
            } else if (postType === 'product'){
                let getTitleProduct = this.state.postProduct?.productDatas.edges.find(e => e.node.attribute.identifier === 'product_name').node.value;
                let getDescriptionProduct = this.state.postProduct?.productDatas.edges.find(e => e.node.attribute.identifier === 'product_description').node.value;
                // let publication = getTitleProduct + '\n' + getDescriptionProduct;
                let getImageProduct = this.state.postProduct?.productDatas.edges.find(e => e.node.attribute.identifier === 'product_image').node.media.filePath
                mediasUrl.push(process.env.REACT_APP_API_ROOT+'/medias/'+getImageProduct);
                bodyDatas = {
                    titre: getTitleProduct,
                    message: getDescriptionProduct.toString(),
                    medias: mediasUrl
                };
            } else if (postType === 'content'){
                let getTitleContent = this.state.postContent?.contentDatas.edges.find(e => e.node.attribute.identifier === 'content_title').node.value;
                let getDescriptionContent = this.state.postContent?.contentDatas.edges.find(e => e.node.attribute.identifier === 'content_description').node.value;
                let getImageContent = this.state.postContent?.contentDatas.edges.find(e => e.node.attribute.identifier === 'content_image').node.media.filePath;
                mediasUrl.push(process.env.REACT_APP_API_ROOT+'/medias/'+getImageContent);
                bodyDatas = {
                    titre: getTitleContent,
                    message: getDescriptionContent.toString(),
                    medias: mediasUrl
                };
            }
        }

        this.setState({
            requesting: true,
            }, () => {
                this.props.startLoading();

                request(`${process.env.REACT_APP_API}/assets/${pathChannelType}/${channelIdentifier}/${createAction}`, 'POST', bodyDatas, 'application/json', true).then(async (data) => {
                    if (data) {
                        await this.getFeed();
                        this.props.stopLoading();
                        this.props.snack(ALERT_SUCCESS, `${this.state.assetData.assetType.identifier === 'twitter' ? 'Tweet' : 'Post'} ajouté ! `);          
                    } else {
                        await this.getFeed();
                        this.props.stopLoading();
                        this.props.snack(ALERT_ERROR, 'Il y a eu une erreur, veuillez réessayer plus tard.');
                    }

                    this.handleToggleDrawer('openFormFeedAdd');
                }).catch(e => {
                    this.props.stopLoading();
                    this.props.snack(ALERT_ERROR, 'Il y a eu une erreur, veuillez réessayer plus tard.');
                });
        });
    }

    goTo = (route, id) => {
        this.props.history.push({
            pathname : route,
            state: {assetId : id}
        });
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};

export default withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(DiffusionPermanentPosts)));
