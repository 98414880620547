import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { Box } from '@material-ui/core';
import Typography from '../../../ui/typography/Typography';
import CardCustom from '../../../layouts/Card/CardCustom';
import CardAssetsPage from '../../../layouts/Card/cardContent/CardAssetsPage';
import imgNotFound from '../../../../assets/images/not-found.png';
import { SNACK } from '../../../../js/constants/action-types';
import { ALERT_ERROR } from '../../../../js/constants/alert-types';
import { ROUTE_HOME } from '../../../../js/constants/route-names';
import { hasRights } from '../../../../js/utils/rights';
import { BUILDER, BUILDER_ASSETS, VIEW, UPDATE } from '../../../../js/constants/constant-rights';

class EngineAssetsDetailsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            asset: this.props.location.state?.asset ? this.props.location.state.asset : null,
            minisite: this.props.location.state?.minisite ? this.props.location.state.minisite : null,
            minisitePageTypes: this.props.location.state?.minisitePageTypes ? this.props.location.state.minisitePageTypes : null
        };

    }

    SettingDatas = (data, type) => {
        let imageAsset = null;
        const requireImages = require.context('../../../../assets/images', true);
        try{
            if (data.node.assetGamePageType){
                imageAsset    = requireImages('./' + data.node.assetGamePageType.image);
            }else{
                if(type === 'flipbook'){
                    if(data.node.assetMinisitePageType.identifier === 'home'){
                        let image = data.node.assetMinisitePageType.image;
                        image = image.replace('minisite', 'flipbook');
                        imageAsset    = requireImages('./' + image);
                    }
                    else{
                        imageAsset    = requireImages('./' + data.node.assetMinisitePageType.image);
                    }
                }else{
                    imageAsset    = requireImages('./' + data.node.assetMinisitePageType.image);
                }
            }
        } catch(e){
            imageAsset=imgNotFound;
        }
        this.setState({
            content:{
                title: data.node.libelle,
                status: data.node.status === '0' ? 'disabled' : 'completed',
                image: typeof imageAsset === "string" ? imageAsset : imageAsset?.default,
                page: data.node.identifier,
                updatedAt: data.node.updatedAt
            }
        })
    }

    componentDidMount(){
        const getRights = hasRights(BUILDER, BUILDER_ASSETS, VIEW)
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }else{
            if(this.state.minisite){
                this.SettingDatas(this.state.minisite, this.state.asset.assetType.identifier)
            }
        }
    }

    render() {
        return (
            <div>
                <Typography variant="h1">
                    Détails de la page
                </Typography>
                <Box pt={3}>
                {
                    this.state.content ? 
                    (
                        <CardCustom>
                            <CardAssetsPage content={this.state.content} asset={this.state.asset} page={this.state.minisite} goTo={this.goToId} canUpdate={hasRights(BUILDER, BUILDER_ASSETS, UPDATE)} />
                        </CardCustom>
                    ) : null
                }
                </Box>
            </div>
        );
    }

    goTo = route => {
        this.props.history.push(route);
    };
    goToId = (route, id) => {
        this.props.history.push({
            pathname : route,
            state: {assetId : id}
        });
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EngineAssetsDetailsPage));
