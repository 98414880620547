import { FormInputTypes } from '../../../shareable/types';
import { getImageDefault, getDefaultTextarea, getDefaultButton, getDefaultText } from '../../helpers';

export default {
    name: 'Section principale',
    inputs:{
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#F0F7FA',
        },
        backgroundImage: {
            label: 'Image de fond',
            type: FormInputTypes.IMAGE,
            value: null
        }
    },
    blocks: {
        logo: getImageDefault(),
        title: getDefaultTextarea("Titre", "Grand Jeu Concours", true, '#53839D', 'rgba(255,255,255,0)', 'initial', null, 50),
        subtitle: getDefaultTextarea("Sous-titre", "Sous-titre", true, "#53839D", 'rgba(255,255,255,0)', 'initial', null, 25),
        button: getDefaultButton("Boutton", "C'est parti", 20, '#F0F7FA', '#53839D', 1, 'rgba(255,255,255,1)', 0),
        labelEmail: getDefaultText("Libellé de demande d'email", "", 20, '#53839D'),
        labelConsent: getDefaultText("Libellé de consentement", "J'accepte le réglement du jeu", 20, '#53839D', 'rgba(255,255,255,0)', 'initial'),
    }
};