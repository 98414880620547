import { 
    START_LOADING, 
    STOP_LOADING, 
    SET_USER,
    ADD_PRODUCT, 
    SNACK,
    STOP_SNACK,
    SET_LOCALES,
    SET_ATTRIBUTES,
    SET_GUIDELINE,
    SET_NAVIGATION
} from "../../constants/action-types";

const initialState = {
    loading: false,
    guideline: null,
    user: null,
    navigation: null,
    products: [],
};

let user        = localStorage.getItem('AUTH_USER');
let locales     = localStorage.getItem('LOCALES');
let attributes  = localStorage.getItem('ATTRIBUTES');
let guideline   = localStorage.getItem('GUIDELINE');
let navigation   = localStorage.getItem('NAVIGATION');

if (user)       initialState.user = JSON.parse(user);
if (locales)    initialState.locales = JSON.parse(locales);
if (attributes) initialState.attributes = JSON.parse(attributes);
if (guideline)  initialState.guideline = JSON.parse(guideline);
if (navigation)  initialState.navigation = JSON.parse(navigation);

function rootReducer(state = initialState, action) {

    if (action.type === START_LOADING) {
        document.body.classList.add('loading');

        return Object.assign({}, state, {
            loading: true
        });
    }

    if (action.type === STOP_LOADING) {
        document.body.classList.remove('loading');

        return Object.assign({}, state, {
            loading: false
        });
    }

    if (action.type === SET_USER) {
        return Object.assign({}, state, {
            user: action.payload.user
        });
    }

    if (action.type === ADD_PRODUCT) {
        let products    = state.products;
        let product     = action.payload.product;

        products.push(product);
        
        return Object.assign({}, state, {
            products
        });
    }

    if (action.type === SNACK) {
        return Object.assign({}, state, {
            snacking: true,
            snackMsg: action.payload.message,
            snackType: action.payload.type,
            snackDuration: action.payload.duration,
        });
    }

    if (action.type === STOP_SNACK) {
        return Object.assign({}, state, {
            snacking: false
        });
    }

    if (action.type === SET_LOCALES) {
        return Object.assign({}, state, {
            locales: action.payload.locales
        });
    }

    if (action.type === SET_ATTRIBUTES) {
        return Object.assign({}, state, {
            attributes: action.payload.attributes
        });
    }

    if (action.type === SET_GUIDELINE) {
        return Object.assign({}, state, {
            guideline: action.payload.guideline
        });
    }
    if (action.type === SET_NAVIGATION) {
        return Object.assign({}, state, {
            navigation: action.payload.navigation
        });
    }

    return state;
}

export default rootReducer;