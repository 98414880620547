import React from 'react';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { withApollo } from 'react-apollo';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import CardCustom from '../../../layouts/Card/CardCustom';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import formAddModel from './config/addModelPresentations.config';
import Button from '../../../ui/button/Button';
import Typography from '../../../ui/typography/Typography';
import { Grid, Box } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import NoImage from '../../../../assets/images/not-found.png';
import { ROUTE_HOME, ROUTE_BUILDER_MODEL_PRESENTATION } from '../../../../js/constants/route-names';
import { eventService } from '../../../../js/services/event.service';
import colors from '../../../../config/theme/colors';
import EmptyProduct from '../../../../assets/pictos/empty-picto/empty_products.png';
import EmptyCard from "../../../ui/empty-card/EmptyCard";
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import * as moment from "moment";
import { GET_MODEL_PRESENTATIONS, ADD_MODEL_PRESENTATION, UPDATE_MODEL_PRESENTATION, DELETE_MODEL_PRESENTATION } from '../../../../queries/crm_presentations';
import styled from 'styled-components';
import Pagination from '@material-ui/lab/Pagination';
import { hasRights } from '../../../../js/utils/rights';
import { CRM_OFFERS, CRM_OFFERS_PROJECTS, CREATE, UPDATE, DELETE, VIEW} from '../../../../js/constants/constant-rights';


const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 1400px){
        padding: 16px 8px 8px!important;
    }
`;

class ListModelsPresentations extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            currentLang: props.locales[0].node.code,
            maxImageNumber : 0,
            title: '',
            openForm: false,
            openDialog: false,
            errors: {},
            secondErrors: {},
            seeErrors: false,
            models: [],
            imageModel: null,
        }
    }

    componentDidMount() {
        const getRights = hasRights(CRM_OFFERS, CRM_OFFERS_PROJECTS, VIEW)
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }else{
            this.initModels();
        }
    }

    addModele = () => {
        this.setState({
            formType: 'add',
            title: null,
            imageModel: null,
        }, () => this.handleToggleDrawer())
    }

    editModel = (data) => {
        this.setState({
            formType: 'edit',
            idModel: data.id,
            title: data.title,
            imageModel: data.media,
        }, () => this.handleToggleDrawer())
    }

    handleToggleDrawer = (drawer) => {
        this.setState({ 
            openForm : !this.state.openForm,
        });
        this.setState({ 
            seeErrors: false
        });
    };

    handleToggleDialog = () => {
        this.setState({ 
            openDialog : !this.state.openDialog
        });
    };

    handleLang = (event) => {
        this.setState({ currentLang: event.target.value });
        this.forceUpdate();
    };

    handleChange = (e, type)=>{
        return new Promise((resolve, reject) => {
            this.setState({
                ready: false
            })
            clearTimeout(this.typingSearchTimer);
            this.setState({searchValue: e.target.value});
            this.typingSearchTimer = setTimeout(() => {
                this.handleGetModels()
            }, 500);
            resolve();
        })
    }

    handleGetModels = () => {
        return new Promise((resolve, reject) => {
            this.props.client.query({
                query: GET_MODEL_PRESENTATIONS,
                // variables,
                fetchPolicy:'no-cache'
            }).then(result =>{
                this.setState({
                    models: result.data.modelPresentations.edges,
                });
                if (result.data.modelPresentations.edges.length > 0){
                    this.setState({
                        isEmpty: false,
                    })
                }else{
                    this.setState({
                        isEmpty: true
                    })
                }
                this.setState({
                    ready: true
                })
                this.props.stopLoading();
                resolve();
            })
        })
    }

    setValue = (stateName, value, translated) => {
        this.setState({
            [stateName]: value,
        });
    };

    handleInputChange = (stateName, evt, custom, translated ) => {
        const value = evt?.target?.value ?? evt;
        this.setValue(stateName, value, translated);
    };

    resetState() {
        this.setState({
            title: null,
            imageModel: null,
            errors: {}
        });
    }

    handleButtonGroupChange = (stateName, value) => {
        this.setState({
            [stateName]: value
        });
    };

    deleteMutation = () => {
        let query = null;
        let variables = null;
        this.setState({
            formType: 'delete'
        })

        query = DELETE_MODEL_PRESENTATION;
        variables = { id: this.state.idModel };
        this.props.client.mutate({
            mutation: query,
            variables
        }).then(() => {
            this.handleSuccess();
        });
    };

    handleMediaPicker=(selected,stateName)=>{
        this.handleInputChange(stateName,selected,null,this.state.currentLang);
    }

    handleError = (e) => {
        this.props.snack(ALERT_ERROR, 'Une erreur est survenue');

        this.props.stopLoading();

        if (e.graphQLErrors) {
            for (let error of e.graphQLErrors) {
                console.error('ERROR', `${error.message} =>`, error.debugMessage);
            }
        }
    };

    handleSuccess = async () => {
        this.setState({
            ready: false
        })
        await this.initModels();
        if(this.state.formType === "edit"){
            this.props.snack(ALERT_SUCCESS, 'Modèle modifié !');
        }else if(this.state.formType === "add"){
            this.props.snack(ALERT_SUCCESS, 'Modèle crée !');
        }else{
            this.props.snack(ALERT_SUCCESS, 'Modèle supprimé !');
            this.handleToggleDialog();
        }
        this.setState({
            openForm: false
        })
        this.resetState();
        this.props.stopLoading();
    };

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;

        errors[stateName] = error;

        this.setState({ errors });
    };

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }

        return false;
    };

    handleNextStep = () =>{
        if (this.hasErrors()) {
            this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
            this.setState({ seeErrors: true });
            eventService.fire();
            return false;
        }

        return true;
    }

    handlerMutation = async () => {
        try {
            if (this.hasErrors()) {
                this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                this.setState({ seeErrors: true });
                return eventService.fire();
            }

            this.props.startLoading();
            if (this.state.formType === "add"){
                await this.props.client.mutate({
                    mutation: ADD_MODEL_PRESENTATION,
                    variables:{
                        'title': this.state.title,
                        'media': this.state.imageModel.id,
                    }
                })
            }else if (this.state.formType === "edit"){
                await this.props.client.mutate({
                    mutation: UPDATE_MODEL_PRESENTATION,
                    variables:{
                        'id': this.state.idModel,
                        'title': this.state.title,
                        'media': this.state.imageModel.id,
                    }
                })
            }

            this.handleSuccess();
        } catch(e) {
            this.handleError(e);
        }
    };

    render() {
        return (
            <div>
                <TopPanel 
                    icomoon="picto-produit"
                    colorIcomoon={colors.blue.lighter.hue300}
                    title="Gérer les modèles offres commerciales" 
                    subtitle="Gestion de vos modèles offres commerciales (création / modification / suppression)" 
                    handlerAdd={() => this.addModele()} 
                    textAdd={hasRights(CRM_OFFERS, CRM_OFFERS_PROJECTS, CREATE) ? "Ajouter un modèle" : null}
                    // searchHandler={this.handleChange} 
                    gradientColor1={colors.menu.regular} 
                    gradientColor2={colors.menu.darker}
                    windowWidth={this.props.windowWidth}
                    openForm={this.state.openForm}
                    buttonAvailable={this.state.ready}
                    hasBorder="true"
                    // currentLang={this.state.currentLang} 
                    // handleLang={this.handleLang} 
                    // locales={this.props.locales}
                />
                <Grid container direction="column" justify="center" style={{paddingTop: 8}} spacing={0}>
                    <Grid container direction="row" spacing={2} style={{marginTop: 0, marginBottom: 0}}>
                        {
                            this.state.ready ?
                                this.state.models.length > 0 ?
                                    this.state.models.map((model, index) => {
                                        return(
                                            <GridCustom item lg={4} md={6} xs={12} key={`model${index}`}>
                                                <CardCustom style={{width: "100%", height: "100%",  padding: 0}} cardContentStyle={{height: "100%", padding: 0}} hovercard={true}>
                                                    <div style={{padding: 16, height: "100%"}}>
                                                        {
                                                            model.node.media ? 
                                                                <div style={{maxHeight: 160, height:'160px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                                    <img style={{maxHeight: 160}} src={`${process.env.REACT_APP_MEDIAS}/${model.node.media.filePath}`}/>
                                                                </div>
                                                            : null
                                                        }
                                                        <Typography variant="h4" colortext={colors.blue.regular} style={{display: 'flex', justifyContent: 'space-between'}} component="div">
                                                            <Box fontWeight="bold">
                                                                {model.node.title}
                                                            </Box>
                                                        </Typography>
                                                        {
                                                            hasRights(CRM_OFFERS, CRM_OFFERS_PROJECTS, UPDATE) ?
                                                                <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: "flex-end", marginTop: 16, flex: 1}}>
                                                                    <Button 
                                                                        text={"Voir le modèle"}
                                                                        bgcolor={colors.blue.lighter.hue300} 
                                                                        color={colors.white} 
                                                                        bgcolorhover={colors.blue.darker.hue300}  
                                                                        colorhover={colors.white}  
                                                                        border={`1px solid ${colors.blue.lighter.hue300}`}
                                                                        onClick={() => {
                                                                            if (!model)
                                                                                return;

                                                                            window.open(`${window.location.origin}${ROUTE_BUILDER_MODEL_PRESENTATION.replace(':alias', model.node.alias)}`, '_blank');
                                                                        }}
                                                                        arrow="right"
                                                                        style={{marginRight: 8}}
                                                                    /> 
                                                                    <Button onClick={() => this.editModel(model.node)}>Modifier</Button>
                                                                </div>
                                                            : null
                                                        }
                                                    </div>
                                                </CardCustom>
                                            </GridCustom>
                                        )
                                    })
                                :<EmptyCard title={hasRights(CRM_OFFERS, CRM_OFFERS_PROJECTS, CREATE) ? "Vous n'avez pas encore configuré de modèle d'offre commerciale" : "Vous n'avez aucun droit de création sur cette page"} subtitle={hasRights(CRM_OFFERS, CRM_OFFERS_PROJECTS, CREATE) ? "Cliquez sur le bouton ci-dessous pour en ajouter un" : "Faite une demande auprès d'un administrateur"} textButton={hasRights(CRM_OFFERS, CRM_OFFERS_PROJECTS, CREATE) ? "Ajouter un modèle" : null} onClick={() => {this.addModele()}} picto={EmptyProduct} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />
                            : <PageLoader />
                        }
                    </Grid>
                </Grid>
                {
                    this.state.ready ?
                    (
                        <LayoutBuilder 
                            opened={this.state.openForm}
                            // image={this.state.imageForm}
                            // icomoon={this.state.content.picto}
                            forClose={() => {this.handleToggleDrawer()}} 
                            dataLayout={formAddModel(this.state.currentLang,this.handleMediaPicker,this.state.formType)} 
                            handlerMutation={this.handlerMutation}
                            drawerWidth={this.props.drawerWidth}
                            stateCallback={this.handleInputChange}
                            backStepperButtonAction={[
                                () => {
                                    this.setState({ errors: {} });
                                }, 
                                () => {
                                    this.setState({ errors: {} });
                                },
                                () => {
                                    this.setState({ errors: {} });
                                },
                                null
                            ]}
                            stepperButtonAction={[
                                this.handleNextStep,
                                this.handleNextStep,
                            ]}
                            errorCallback={this.handleFormError}
                            validateButton={true}
                            deleteMutation={this.state.formType === 'edit' && hasRights(CRM_OFFERS, CRM_OFFERS_PROJECTS, DELETE) ? () => this.handleToggleDialog() : null}
                            deleteText={this.state.formType === 'edit' ? 'Supprimer' : null}
                            allState={this.state}
                        />
                    ) : ''
                }
                <Dialog
                        open={this.state.openDialog}
                        onClose={this.handleToggleDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">Êtes-vous sûr de vouloir supprimer ce modèle d'offre ?</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Si vous supprimez ce modèle celui-ci ne sera plus accessible. Si vous ne souhaitez pas le supprimer, annulez la suppression en cliquant sur annuler.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleToggleDialog} color={colors.grey.regular} bgcolor={colors.white} bgcolorhover={colors.grey.lighter.hue900} border={`1px solid ${colors.grey.regular}`}>
                                Annuler
                            </Button>
                            <Button onClick={this.deleteMutation} bgcolor={colors.red.regular} bgcolorhover={colors.red.darker} autoFocus>
                                Supprimer
                            </Button>
                        </DialogActions>
                    </Dialog>
            </div>
        );
    }
    handleMediaPicker=(selected,stateName)=>{
        this.handleInputChange(stateName,selected,null,this.state.currentLang);
    }

    async initModels() {
        await this.handleGetModels();
    }

    goTo = (route, id, target) => {
        let variables = {};
        variables[target] = id;
        this.props.history.push({
            pathname : route,
            state: variables
        });
    };

}

const mapDispatchToProps = dispatch => {
    return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes, 
        locales: state.locales,
    };
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(ListModelsPresentations)));
