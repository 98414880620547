import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import RulesBlock from '../RulesBlock/RulesBlock';
import Div from '../../../../shared/components/CustomDiv';


const FooterBook = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    let {
        preview = false,
        id = null, 
        data = null,
        noSticky,
        inputCallback = null,
    } = props;

    const clickRules = () => {
        setIsOpen(true)
        if (document.getElementById('flipBook')){
            document.getElementById('flipBook').style.zIndex = "2";
        }
        if (document.getElementsByClassName('postCardWrapper')){
            document.getElementsByClassName('postCardWrapper')[0].style.zIndex = "3";
        }
    }

    let image = data.inputs['backgroundImage'].value;
    return (
        <>
            {
                isOpen ? 
                    <RulesBlock isOpen={isOpen} onClose={() => setIsOpen(false)}/>
                : null
            }
            <footer
                onClick={() => inputCallback(id)}
                style={{
                    backgroundColor: data.inputs['backgroundColor'].value,
                    backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                    backgroundSize: '100%',
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    position: 'relative',
                }}
            >
                { !preview && props.children}
                <div className="banner">
                    <Div data={data} preview={preview} component={id} block={'footerText'} inputCallback={inputCallback} nopadding={true} />
                </div>
            </footer>
        </>
    );
};

FooterBook.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingClass: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default FooterBook;
