import React  from 'react';
import { withRouter } from "react-router";
import { ROUTE_LOGIN } from '../../../js/constants/route-names';
import { connect } from "react-redux";

import styled from 'styled-components';
import {Typography, Grid, Container, Box} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
//import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { START_LOADING, STOP_LOADING, SET_USER, SNACK } from '../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../js/constants/alert-types';

import request from '../../../js/utils/fetch';
import ButtonStyled from "../../ui/button/Button";
import logo from '../../../assets/images/logo.png';
import FormBuilder from '../../ui/form/FormBuilder';
import colors from '../../../config/theme/colors';

// import LegoLogo from '../../../assets/images/logo_lego.svg';
// import SinfinLogo from '../../../assets/images/logo_sinfin.png';

import './Login.scss';
import { default as ForgotPasswordTemplate } from '../../../email/forgetPassword';

const inputsLogin = [
  {
    type: 'text',
    label: 'Adresse e-mail',
    helper: {
      text: '',
      link: false
    },
    required: true,
    stateName: 'email',
    disabled: false,
  }
]

const ContainerLogin = styled(Grid)`
  // top: 48%;
  // position: relative;
  // transform: translateY(-50%);
`
const ContainerCustom = styled(Container)`
  background: #fff;
  box-shadow: 0px 0px 5px rgba(0,0,0,0.1);
  padding: 2rem 2rem 3rem 2rem !important;
  position: relative;
  left: auto;
  margin: auto;
`
const ReturnLink = styled(Typography)`
    color: ${colors.blue.lighter.hue300};
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
`

const ButtonCustom = styled(ButtonStyled)`
  margin:auto;
`
// const CheckboxCustom = styled(Grid)`
//   margin-top: -40px;
// `

// const CheckboxStyled = styled(Checkbox)`
//   border-radius: 5px;
// `
const SpanColor = styled.span`
    color: ${colors.blue.lighter.hue300};
    font-weight: bold;
`
// const GridCustom = styled(Grid)`
//     margin-top: 0.5rem;
// `
// const LinkText = styled.a`
//     color: ${colors.blue.lighter.hue300} !important;
//     cursor: pointer;
//     text-decoration: underline;
//     font-size: 14px;
// `;
// const CardCustom = styled(Card)`
//   box-shadow: 0px 0px 5px #1622421A;
//   border-radius: 5px;
//   cursor:pointer;
// `;
// const CardContentStyled = styled(CardContent)`
//   padding: 1rem !important;
// `;
// const TypographyCustom = styled(Typography)`
//     color: ${colors.blue.lighter.hue300} !important;
//     font-weight: normal;
// `;
// const WorkspaceName = styled(Typography)`
//     font-weight: bold;
// `;

class ForgetPassword extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            email: 'scukic@sinfin.fr',
            password: '#Sinfin#10',
            checked: false,
            open: false,
        }
    }

    goTo = (route) => {
        this.props.history.push(route);
    };

    handleCheck = () => {
        this.setState({
            checked: !this.state.checked,
        });
    };

    handleInputChange = (stateName, evt) => {
        const value = evt.target.value;

        this.setState({
            ...this.state,
            [stateName]: value
        });
    };

    componentDidMount() {
      document.addEventListener("keydown", this.handleKeyPress, false);
    }
  
    componentWillUnmount(){
      document.removeEventListener("keydown", this.handleKeyPress, false);
    }

    handleKeyPress = (event) => {
      if(event.key === 'Enter'){
        this.onSubmit()
      }
    }

    onSubmit = () => {
      if (!this.state.email || !/(.+)@(.+){2,}\.(.+){2,}/.test(this.state.email))
        return this.props.snack(ALERT_ERROR, 'Veuillez renseigner une adresse email valide');

      this.props.startLoading();

      request(`${process.env.REACT_APP_API}/forgot-password/request`, 'post', {
        username: this.state.email
      }).then((data) => {
        request(`${process.env.REACT_APP_API}/sender-emails`, 'post', {
          sender: "no-reply@sinfin.fr",
          receiver: this.state.email,
          subject: "Réinitialisation de mot de passe",
          content: ForgotPasswordTemplate(`${process.env.REACT_APP_PUBLIC}/password/reset/${data.token}`, `${process.env.REACT_APP_PUBLIC}/img/logo.png`)
        }).then(() => {
          this.props.snack(ALERT_SUCCESS, 'Vous allez recevoir un email pour réinitialiser votre mot de passe');
          this.goTo(ROUTE_LOGIN);
        }).finally(() => {
          this.props.stopLoading();
        });
      });
    };

    render() {
        return (
    
            <Grid container justify={"center"} className={"login"}>
            <AppBar
                position="fixed"
                color={"primary"}
                className={"appBar"}
            >
                <Toolbar className={"toolbar"}>
                    <img className={"logoSpread"} src={logo} height="25px" width="225px" alt="logo-spread" />
                </Toolbar>
            </AppBar>
            <ContainerLogin item xs={8}>
                <ContainerCustom maxWidth={"sm"}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Box onClick={this.props.history.goBack} style={{marginTop: -15}}>
                                <ReturnLink variant={'body2'}>&lt; Retour</ReturnLink>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid style={{marginTop: '2rem'}} container justify="center">
                        <img src={logo} height={48} alt="logo-spread"/>
                        <Box mt={6} textAlign={'center'} style={{maxWidth: '100%'}}>
                            <Typography variant={'h3'}>Mot de passe oublié</Typography>
                            <Typography variant={'body1'} style={{marginTop: '0.5rem'}}>Pour <SpanColor>réinitialiser votre mot de passe,</SpanColor><br/> saisissez l'adresse e-mail que vous utilisez<br/> pour votre compte <SpanColor>SpreadSuite.</SpanColor></Typography>
                        </Box>
                        <FormBuilder optionsInputs={inputsLogin} stateCallback={this.handleInputChange} allState={this.state} backgroundColor={'white'} padding={'32px 32px 8px'} />
                        <ButtonCustom bgColor={colors.green.regular} text="Recevoir mon lien" className="button" onClick={this.onSubmit} />
                    </Grid>
                </ContainerCustom>
              {/* <Box mt={2} mb={4}>
                <Typography variant="body2">Vous ne connaissez pas l’URL de votre espace de travail ? <LinkText variant="body2">Trouver votre espace de travail</LinkText></Typography>
              </Box>
              <Box mb={4} textAlign={"center"}>
                <TypographyCustom variant="h3">Vous êtes déjà connecté(e) à cet espace de travail :</TypographyCustom>
              </Box>
              <GridCustom container justify={"space-between"}>
                  <Grid item xs={6}>
                    <Box mr={2}>
                      <CardCustom>
                        <CardContentStyled>
                          <Grid container alignItems={"center"}>
                            <Grid item xs={4}>
                              <Box mr={2}>
                                <img src={LegoLogo} width={"100%"}/>
                              </Box>
                            </Grid>
                            <Grid item xs={8}>
                              <Grid container justify={"space-between"} alignItems={"center"}>
                                <WorkspaceName variant={"h4"}>LEGO®</WorkspaceName>
                                <ArrowForwardIosRoundedIcon fontSize="small"  />
                              </Grid>
                            </Grid>
                          </Grid>
                        </CardContentStyled>
                      </CardCustom>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box ml={2}>
                      <CardCustom>
                        <CardContentStyled>
                          <Grid container alignItems={"center"}>
                            <Grid item xs={4}>
                              <Box mr={2}>
                                <img src={SinfinLogo} width={"100%"}/>
                              </Box>  
                            </Grid>
                            <Grid item xs={8}>
                              <Grid container justify={"space-between"} alignItems={"center"}>
                                <WorkspaceName variant={"h4"}>Sinfin</WorkspaceName>
                                <ArrowForwardIosRoundedIcon fontSize="small"  />
                              </Grid>
                            </Grid>
                          </Grid>
                        </CardContentStyled>
                      </CardCustom>
                    </Box>
                  </Grid>
              </GridCustom> */}
            </ContainerLogin>
          </Grid>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    setUser: (user) => dispatch({ type: SET_USER, payload: { user }}),
    snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
  }
}

const mapStateToProps = state => {
  return {
    loading: state.loading
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgetPassword));
