import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { MenuTypes } from '../shareable/types';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import { v4 as uuidv4 } from 'uuid';
import Button from '../../components/ui/button/Button';
import colors from '../../config/theme/colors';
import Fab from '@material-ui/core/Fab';
import { red } from '@material-ui/core/colors';
import styled from 'styled-components';

import { getTraductionAttributs } from '../../js/utils/functions';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: 15,
    '&>div':{
      '&>div':{
        margin: '16px 0',
        boxShadow: 'inherit',
        '&:first-child':{
            marginTop: 0,
        },
        '&:before':{
            content: 'inherit',
        }
      }
  }
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: '24px'
  },
  dragIndicator: {
    marginRight: 10,
    cursor: 'pointer'
  },
  formControl: {
    marginBottom: theme.spacing(1),
    width: '100%'
  },
  Accordion: {
    flexDirection: 'column',
    background: '#F8F8F8',
  },
  input: {
    marginBottom: theme.spacing(1),
    borderRadius:0,
    '& > div':{
      borderRadius:0,
      '& > div':{
        borderRadius:0,
      },
    },
  },
  actionBar: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 10
  },
  delete: {
    marginTop: theme.spacing(1),
    width: 150,
    alignSelf: 'flex-end'
  }
}));

const DeleteButton = withStyles(theme => ({
  root: {
      color: red[500],
      backgroundColor: 'transparent',
      minHeight: 10,
      alignSelf: 'flex-end',
      textTransform: 'inherit',
      borderRadius: 0,
      width: 'auto',
      boxShadow: 'inherit',
      '&:hover': {
          color: red[700],
          backgroundColor: 'transparent',
      }
  },
}))(Fab);

const AccordionSummaryCustom = styled(AccordionSummary)`
    background: #F8F8F8;
    &>div{
        align-items: center;
        p{
            color: ${colors.menu.regular};
        }
        svg{
            fill: ${colors.regular};
        }
    }
`;


const DragHandle = sortableHandle(() => {
  const classes = useStyles();
  return <MenuIcon className={classes.dragIndicator} />;
});

const SortableItem = SortableElement(({ items, onChange, panelChange, expanded, item, id, categories }) => {
  const classes = useStyles();

  return <Accordion expanded={expanded === `panel-${id}`} onChange={panelChange(`panel-${id}`)} style={{borderRadius: '0!important', boxShadow: 'inherit'}}>
    <AccordionSummaryCustom expandIcon={<ExpandMoreIcon />}>
      <DragHandle />
      <Typography className={classes.heading}>{item.name}</Typography>
    </AccordionSummaryCustom>
    <AccordionDetails className={classes.Accordion}>
      <FormControl variant="outlined" className={classes.formControl}>
        <Typography gutterBottom style={{fontSize: 14}}>
          Type
        </Typography>
        <Select
          id="type-select"
          onChange={(event) => {  
            item.value = null;
            item.type = event.target.value;
            onChange(items);
          }}
          disabled={item.readonly}
          value={item.type}
          style={{borderRadius: 0}}
        >
          <MenuItem value={MenuTypes.LINK}>Lien</MenuItem>
          <MenuItem value={MenuTypes.CATEGORY}>Catégorie</MenuItem>
        </Select>
      </FormControl>
      
      <Typography gutterBottom style={{fontSize: 14}}>
        Nom
      </Typography>
      <TextField 
        variant="outlined" 
        value={item.name}
        className={classes.input}
        onChange={(event) => {
          item.name = event.target.value;
          onChange(items);
        }}
        style={{borderRadius: 0}}
      />

      { 
        item.type === MenuTypes.LINK 
          ? (
            <>
              <Typography gutterBottom style={{fontSize: 14}}>
                Lien
              </Typography>
              <TextField 
                variant="outlined" 
                value={item.value}
                disabled={item.readonly}
                className={classes.input}
                onChange={(event) => {
                  item.value = event.target.value;
                  onChange(items);
                }}
                style={{borderRadius: 0}}
              />
            </>
          ): null
      }

      { 
        item.type === MenuTypes.CATEGORY 
          ? (
            <FormControl variant="outlined" className={classes.formControl}>
              <Typography gutterBottom style={{fontSize: 14}}>
                Catégorie
              </Typography>
              <Select
                id="category-select"
                onChange={(event) => {  
                  item.value = event.target.value;
                  onChange(items);
                }}
                disabled={item.readonly}
                value={item.value}
                style={{borderRadius: 0}}
              >
                { categories.map((category, i) => (
                  <MenuItem key={i} value={category.value}>{ category.label }</MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null
      }

      {
        item.readonly ? null : (
          <DeleteButton color="secondary" size="small" aria-label="delete" className={classes.button} onClick={() => { onChange(items.filter(e => e.id !== item.id));}}>
              <DeleteIcon style={{width: 16}} />
              Supprimer
          </DeleteButton>
        )
      }
    </AccordionDetails>
  </Accordion>
});

const SortableList = SortableContainer(({ items, onChange, panelChange, expanded, categories }) => {
  return (
    <div>
      {items.map((value, index) => (
        <SortableItem 
          items={items} 
          onChange={onChange} 
          panelChange={panelChange}
          key={`item-${index}`} 
          item={value} 
          index={index}
          id={index}
          expanded={expanded}
          categories={categories}
        />
      ))}
    </div>
  );
});

export default function MenuConfigurator({ value, onChange, catalog, locales }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const convertToOption = (e, level) => ({
    id: e.id,
    value: e.id,
    label: level + (getTraductionAttributs('category_name', e.categoryDatas, locales[0].node.code) || e.libelle),
    parent: e.parent
  });

  const populate = (parent) => {
    parent.children = categories.filter(e => e.parent && e.parent.id === parent.id);

    for (let child of parent.children) {
      populate(child);
    }
  };

  const addCat = (cat, level = '_') => {
    options.push(convertToOption(cat, level));

    for (let sub of cat.children) {
      addCat(sub, level + '_');
    }
  };

  let categories  = catalog.categories;
  let tree        = categories.filter(e => e.parent === null);

  for (let root of tree) {
    populate(root);
  }

  let options = [];

  for (let root of tree) {
    addCat(root)
  }

  const panelChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const onSortEnd = ({oldIndex, newIndex}) => {
    onChange(arrayMove(value, oldIndex, newIndex));
  };

  const addMenuItem = () => {
    value.push({
      type: MenuTypes.LINK,
      id: uuidv4(),
      name: '[nouveau]',
      value: '/'
    });

    onChange(value);
  };

  return (
    <div className={classes.root}>
      <SortableList 
        items={value} 
        onChange={onChange} 
        onSortEnd={onSortEnd} 
        panelChange={panelChange} 
        expanded={expanded}
        useDragHandle 
        categories={options}
        helperClass='sortable-helper'
      />
      <div className={classes.actionBar}>
          <Button
              label="Ajouter"
              style={{backgroundColor: colors.menu.regular, color: "white", boxShadow: `0px 3px 0px 0px ${colors.menu.darker}`, textTransform: 'initial', width: '100%'}}
              onClick={addMenuItem}
          >
              <AddIcon style={{fontSize: 16, fill: colors.white, marginRight: 7.5}} /> <span style={{fontSize: 17}}>Ajouter une entrée</span>
          </Button>
      </div>
    </div>
  );
}
