import {Grid} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

import PageLoader from '../../../../ui/loadings/page-loader/PageLoader';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import SearchBar from '../../../../ui/search/SearchBar';
import Button from '../../../../ui/button/Button';
import Typography from '../../../../ui/typography/Typography';
import colors from '../../../../../config/theme/colors';
import CardCompany from '../../../../layouts/Card/cardContent/CardCompany';
import {ROUTE_CRM_CONTACTS_SOCIETIES} from '../../../../../js/constants/route-names';

import CardCustom from '../../../../layouts/Card/CardCustom';       
import React from 'react';

const styles = makeStyles((theme)=>({
    root : {
        padding : '30px 20px'

    },
    customersContainer:{
        paddingTop : 30
    },
}));

const PaginationCustom = styled(Pagination)`
    ul{
        justify-content: center; 
        margin-top: 24px;
    }
`;


export default function SelectCompanies(props){
    console.log('props', props)
    props = props.customProps;
    const classes       = styles();
    
    return(
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <Grid container justify="flex-end" style={{paddingBottom : 10}}>
                    <Grid item xs={6} >
                        <SearchBar onChange={props.handleSearch} placeholder={props.isOffer ? "Rechercher une offre" : "Rechercher une société"}></SearchBar>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid
                    container
                    direction="row"
                    spacing={3}
                    alignItems="stretch"
                    className={classes.customersContainer}
                >
                    {props.companies?.length > 0 ? 
                        props.companies.map((company)=>
                            <Grid item lg={6} md={12} xs={12}>
                                <CardCustom style={{width: "100%", height: "100%", padding: 0, paddingBottom: 16}} cardContentStyle={{padding: 0}}>
                                    <CardCompany currentLang={props.currentLang} data={company} isOffer={props.isOffer} select={true} inputCard = {true} hasCursor={true} handleSelectCompany ={props.handleSelectCompany} uniqueSelect={props.uniqueSelect} selectedCompanies={props.selectedCompanies} currentCompanies = {props.currentCompanies}/>
                                </CardCustom>
                            </Grid>
                        )
                    :
                        // <PageLoader/>
                        <Grid container direction="column" alignItems="center" justify="center">
                            <Typography colorText={colors.red.regular} style={{marginBottom: 16}}>Aucune société créée</Typography>
                            <Button onClick={() => props.goTo(ROUTE_CRM_CONTACTS_SOCIETIES, true)}>Créer une société</Button>
                        </Grid>
                    }

                    {props.paginationSettings?.count > 1 ? 
                        <Grid item xs={12}>
                            <Grid container justify="center">
                                <PaginationCustom page={props.paginationSettings.page} count={props.paginationSettings.count} onChange={(event, page) => {props.paginationSettings.changePage(event, page)}}></PaginationCustom> 
                            </Grid>
                        </Grid>                        
                    : null}
                </Grid>
            </Grid>
        </Grid>
    )
}