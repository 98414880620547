import { v4 as uuidv4 } from "uuid";
import { FormInputTypes, EditableTypes, ItemTypes } from '../../../shareable/types';
import { getDefaultText, getDefaultRules, getImageDefault } from '../../helpers';

export default {
    name: "Section d'explication",
    inputs:{
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#FFFFFF'
        },
        backgroundImage: {
            label: 'Image de fond',
            type: FormInputTypes.IMAGE,
            value: null
        },
    },
    blocks: {
        explanation: {
            id: uuidv4(),
            name: "Bloc d'explication",
            type: EditableTypes.MINI_BLOCKS,
            inputs: {
                backgroundColor: {
                    label: 'Couleur de fond',
                    type: FormInputTypes.COLOR,
                    value: '#F0F7FA'
                },
                backgroundImage: {
                    label: 'Image de fond',
                    type: FormInputTypes.IMAGE,
                    value: null
                },
                border: {
                    type: FormInputTypes.GROUP,
                    label: "Border",
                    inputs: {
                        borderColor: {
                            type: FormInputTypes.COLOR,
                            value: '#000',
                            label: "Couleur de la bordure"
                        },
                        borderWidth: {
                            type: FormInputTypes.SLIDER,
                            value: 0,
                            label: "Largeur de la bordure :",
                            params: {max: 5, min: 0, step: 1}
                        },
                        borderRadius: {
                            type: FormInputTypes.SLIDER,
                            value: 0,
                            label: "Arrondi des angles :",
                            params: {max: 50, min: 0, step: 1}
                        }
                    }
                },
            },
            blocks:{
                rulesTitle: getDefaultText("Titre des règles de jeu", "Comment jouer ?", 35, "#53839D", 'rgba(255,255,255,0)'),
                rulesTitleImage: getImageDefault(),
                rules:{
                    title: "Règles",
                    name: "Règles",
                    type: EditableTypes.MULTIPLE_BLOCKS,
                    subtype: ItemTypes.RULE,
                    limit: 3,
                    config: [
                        getDefaultRules("","Règle 1"),
                        getDefaultRules("","Règle 2"),
                        getDefaultRules("","Règle 3"),
                    ]
                },
                dotationsTitle: getDefaultText("Titre des dotations", "Des lots exceptionnels à gagner !", 35, "#53839D", 'rgba(255,255,255,0)'),
                dotationsTitleImage: getImageDefault(), 
                dotations: getImageDefault(),
            }
        },
        image: getImageDefault(),
    }
};
