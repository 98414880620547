import React, { useState } from "react";

const ThreeImagesRow = ({
    preview = false,
    id = null,
    data = null,
    inputCallback = null,
    children = null
}) => {
    const [hover, setHover] = useState(false);

    const styles = {
        backgroundColor: !preview ? '#eee' : '#fff',
        marginBottom: 20,
        width: '100%',
        boxSizing: 'border-box'
    };

    let image1 = data.blocks["image1"].inputs.image?.value;
    let image2 = data.blocks["image2"].inputs.image?.value;
    let image3 = data.blocks["image3"].inputs.image?.value;

    return (
        <div className="builder-template-scope" style={styles} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { !preview && hover && children }

            <div className="container three-images-row">
                <div className="row">
                    <div className={`col-4 image`}>
                        <img src={typeof image1 === 'string' ? image1 : `${process.env.REACT_APP_MEDIAS}/${image1.filePath}`} alt="first" />
                    </div>

                    <div className={`col-4 image`}>
                        <img src={typeof image2 === 'string' ? image2 : `${process.env.REACT_APP_MEDIAS}/${image2.filePath}`} alt="second" />
                    </div>

                    <div className={`col-4 image`}>
                        <img src={typeof image3 === 'string' ? image3 : `${process.env.REACT_APP_MEDIAS}/${image3.filePath}`} alt="third" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ThreeImagesRow;
