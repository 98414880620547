import React from 'react';
import { Grid } from '@material-ui/core';

import InputBuilder from '../../ui/form/InputBuilder';


export default function TraductionSelect(props) {
    const selectLang = {
        type: 'select',
        label: 'Langue',
        helper: 'Langue',
        required: false,
        stateName: 'currentLang',
        value: 
            props.locales.map((locale) => {
                return(
                    {
                        value: locale.node.code,
                        label: locale.node.libelle
                    }
                )
            })
    };
    return(
        <Grid item xs={12}>
            <Grid container justify={'flex-end'} style={{marginBottom: -16}}>
                <InputBuilder value={props.currentLang} input={selectLang} stateCallback={props.handleLang}  style={{marginBottom: '0 !important'}}/> 
            </Grid>
        </Grid>
    );
}