import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import '../../../shareable/components/assets/game/scss/style.scss';
import styled from 'styled-components';

const useStyles = makeStyles({
    root: {
        width: '100%',
    }
});

const Div = styled.div`
    section, div.typeSection{
        background-size: ${props => props.widthWindow < 1600 && !props.noRight ? '1500px!important' : null};
    }
`;


export default ({ config, params, getComponent, preview, noRight }) => {
    const classes = useStyles();
    const widthWindow = window.innerWidth;
    return (
        <Div widthWindow={widthWindow * 75 / 100} className={classes.root} noRight={noRight} id="builder-template-scope-game" style={{backgroundImage: params?.bg?.value ? (typeof(params.bg?.value) === 'string' ? `url(${params.bg?.value})` : `url(${process.env.REACT_APP_MEDIAS}/${params.bg.value.filePath})` ) : null }}>
            { config.elements.map(getComponent) }
        </Div>
    );
};