import React from "react";
import Pg from '../../../../shared/components/CustomParagraph';
import H2 from '../../../../shared/components/CustomH2';
import H3 from '../../../../shared/components/CustomH3';

export default ({ data, preview, component, block, inputCallback, className }) => {
    var dotation = data.blocks[block];
    return( 
        <div class="dotations">
            <div>
                {
                    !data.blocks.dotationsTitleImage?.inputs?.value?.value  ? (
                        <H2 data={data} preview={preview} component={component} block={'dotationsTitle'} inputCallback={inputCallback} />
                    ) : (
                        <img class="img_center"
                            src={
                                typeof data.blocks.dotationsTitleImage?.inputs?.value?.value === 'string' && data.blocks.dotationsTitleImage?.inputs?.value?.value !== '' ? 
                                    data.blocks.dotationsTitleImage.inputs.value.value : 
                                    data.blocks.dotationsTitleImage?.inputs?.value?.value?.filePath ? 
                                        ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.dotationsTitleImage.inputs.value.value.filePath}` 
                                        : `http://via.placeholder.com/300x80/FFFFFF/53839D?text=Titre`
                                }
                            alt="Dotations"
                        />
                    )

                }
            </div>
            <div class="list unique">
                <div class="dotation" onClick={() => inputCallback(component, block)}>
                    <div>
                        <img 
                            className="img_center"
                            src={
                                typeof dotation.inputs.value.value === 'string' && dotation.inputs.value.value !== '' ? 
                                    dotation.inputs.value.value : 
                                    dotation.inputs.value?.value?.filePath ? 
                                        ` ${process.env.REACT_APP_MEDIAS}/${dotation.inputs.value.value.filePath}` 
                                        : `http://via.placeholder.com/600x270/FFFFFF/53839D?text=Dotations`
                            }
                            alt={`Dotations`}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
};