import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";

import { Grid, Box, CircularProgress } from '@material-ui/core';
import CardCustom from '../../../layouts/Card/CardCustom';
import CardMedia from '../../../layouts/Card/cardContent/CardMedia';
import Typography from '../../../ui/typography/Typography';
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import { ROUTE_MEDIAS_CATEGORIES, ROUTE_MEDIAS_LIST, ROUTE_MEDIAS_TAGS, ROUTE_HOME } from '../../../../js/constants/route-names';
import { withApollo } from 'react-apollo';
import request from '../../../../js/utils/fetch';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import colors from '../../../../config/theme/colors';
import IcomoonReact from "icomoon-react";
import iconSet from "../../../../assets/selection.json";
import SliderDashboard from "../../../layouts/Slider/SliderDashboard";
import Image1 from '../../../../../src/assets/images/dashboard/Visuels-Dashboard-connecteur-RS.png';
import Image2 from '../../../../../src/assets/images/dashboard/Visuels-Dashboard-connecteur-marketplace.png';
import { v4 as uuidv4 } from 'uuid';
import DataBar from './components/DataBar';
import MediasTags from './MediasTags';

import { GET_MEDIAS_IMAGES } from '../../../../queries/medias';

import { ALLOWED,VIDEOS,IMAGES, CSV } from '../../../../js/constants/medias-types';

import { hasRights } from '../../../../js/utils/rights';
import { MEDIAS, MEDIAS_DASHBOARD, VIEW } from '../../../../js/constants/constant-rights';
import { ALERT_ERROR } from '../../../../js/constants/alert-types';
import { SNACK } from '../../../../js/constants/action-types';

class DashboardMedias extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            stockageSize: 50000000000,
            warningStorage: false,
            stockageCat:[
                {
                    graphSize:0,
                    name:'Image',
                    graphColor:'#58AFFD',
                    show:false,
                    mimetypes:IMAGES,

                },                
                {
                    graphSize:0,
                    name:'Fichiers Vidéos',
                    graphColor:'#1A2543',
                    show:false,
                    mimetypes:VIDEOS,
                },
                {
                    graphSize:0,
                    name:'Fichiers CSV',
                    graphColor:'#B490ED',
                    show:false,
                    mimetypes:CSV,
                },
            ],
        };
        this.availablesMedias=[
            {
                id:'image',
                mime:IMAGES,
            },
            {
                id:'video',
                mime:VIDEOS,
            }
        ]
        
    }
    
    getLastUpdate=async()=>{
        return new Promise(async (resolve, reject) => {
            await this.props.client.query({
                query:GET_MEDIAS_IMAGES ,
                fetchPolicy:'no-cache',
                variables : {
                    first:1,
                    nbperpage: 8,
                    types : ALLOWED
                }
            }).then(result=>{
                this.setState({lastMediaList: result.data.mediaObjects.edges});
            })
        });
    }

    getStorage=async()=>{
        request(`${process.env.REACT_APP_API}/media-objects-size`).then(async (data) => {
            let result=data.response;

            let storages=this.state.stockageCat;
            let storageStats=[];
            for( let storage of storages){
                let size=0;
                for (let mimetype of storage.mimetypes){ 
                    if(typeof result[mimetype] !== 'undefined'){
                        size=size+result[mimetype];

                    }
                }
                storageStats.push({
                    ...storage,
                    size:size,
                    show:true
                })
                
            }

            this.setState({
                stockageCat:storageStats
            },()=>{
                this.handleGetStorageSize();
            })
        })
    }

    handleGetStorageSize(){
        let globalStockage=this.state.stockageSize;
        let files=this.state.stockageCat;
        let newList=[];
        let remainginSize=this.state.stockageSize;
        for (let file of files){
            let usedStockage=(100*file.size)/globalStockage;
            file['graphSize']=usedStockage;
            newList.push(file);
            remainginSize=remainginSize-file.size;
        }
        let warning=((remainginSize/globalStockage)<=0.1)?true:false;
        remainginSize=Math.round(remainginSize*100+Number.EPSILON)/100;
        this.setState({
            stockageCat:newList,
            remainginSize:remainginSize,    
            warningStorage:warning
        });
    }

    handleGetInfos= async()=>{
        this.getStorage();
    }

    goTo = (route, status) => {

        this.props.history.push({
            pathname : route,
            state: {formOpen : status}
        });
    };

    initDashboard = () => {
        request(`${process.env.REACT_APP_API}/dashboards/stats/media`, 'get', false).then(
            (data) => {
                if(data.success){
                    this.setState({
                        medias: data.datas
                    });
                }
            }
        );
    }

    componentDidMount(){
        const getRights = hasRights(MEDIAS, MEDIAS_DASHBOARD, VIEW)
        
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }else{
            this.getLastUpdate();
            this.initDashboard();
            this.handleGetInfos();
        }
    }

    render() {
        const sliderData = [
            {
                title: 'Pourquoi créer un média ?',
                text: 'Créer un ou plusieurs médias est essentiel, cela va vous permettre de les ajouter à vos catalogues, vos produits, vos contenus, vos mini-sites... En ajoutant des médias vous aurez un réel gain de temps car vous n’aurez plus à chercher dans votre ordinateur, tous vos médias seront directement rangés et classés dans vos catégories.',
                image: Image1,
                id: uuidv4(),
                buttons: [
                    {
                        text: 'Créer un média',
                        action: () => this.goTo(ROUTE_MEDIAS_LIST, true),
                    },
                ]
            },
            {
                title: 'Pourquoi créer une catégorie ?',
                text: 'Créer des catégories va vous permettre de vous organiser et de trouver vos médias plus rapidement. Vos médias seront donc catégorisés et vous pourrez facilement les ajouter à vos catalogues, vos produits, vos contenus...',
                image: Image2,
                id: uuidv4(),
                buttons: [
                    {
                        text: 'Créer une catégorie',
                        action: () => this.goTo(ROUTE_MEDIAS_CATEGORIES, true),
                    },
                ]
            },
            {
                title: 'Pourquoi créer un tag ?',
                text: 'Ajouter un ou plusieurs tag va vous permettre de pouvoir les ajouter plus rapidement par la suite sur vos catalogues ou mini sites.\nLe # est dans l’air du temps, entrez donc un maximum de tag sur SpreadSuite.',
                image: Image2,
                id: uuidv4(),
                buttons: [
                    {
                        text: 'Créer un tag',
                        action: () => this.goTo(ROUTE_MEDIAS_TAGS, true),
                    },
                ]
            }
        ];
        let stats = [];
        let lastDatas = [];
        
        if (this.state.medias){
            stats = 
            [
                {
                    'icon': 'icon-medias',
                    'data': this.state.medias.nbContents,
                    'dataColor': colors.blue.regular,
                    'subtitle': 'Total Médias',
                },
                {
                    'icon': 'picto-categorie',
                    'data': this.state.medias.nbCategories,
                    'dataColor': colors.blue.regular,
                    'subtitle': 'Total Catégories',
                },
                {
                    'icon': 'picto-label',
                    'data': this.state.medias.nbTags,
                    'dataColor': colors.blue.regular,
                    'subtitle': 'Total Tags',
                },
            ];
            lastDatas = [
            {
                'icon': 'csv',
                'data': this.state.medias.nbMediasByType.csv,
                'dataColor': colors.blue.regular,
                'subtitle': 'Médias de type CSV',
            },
            {
                'icon': 'picto-media',
                'data': this.state.medias.nbMediasByType['image/jpeg'],
                'dataColor': colors.blue.regular,
                'subtitle': 'Médias de type JPEG',
            },
            {
                'icon': 'picto-media',
                'data': this.state.medias.nbMediasByType['image/png'],
                'dataColor': colors.blue.regular,
                'subtitle': 'Médias de type PNG',
            },
        ]
        }
        return (
            stats && lastDatas ?
                <div>
                    <TopPanel 
                        icomoon="icon-dashboard" 
                        colorIcomoon={colors.blue.lighter.hue300}
                        title="Bienvenue sur le dashboard" 
                        strongText="Médias"
                        subtitle="Petite phrase de description OU heure de Mise à jour" 
                        gradientColor1={colors.menu.regular} 
                        gradientColor2={colors.menu.darker} 
                        openForm={this.state.openForm}
                        hasBorder={true}
                        stats={stats}
                    />
                    <Grid container direction="column" justify="center" spacing={0} style={{marginTop: 24}}>
                        <Grid item xs={12}>
                            <Typography variant="h4" style={{fontWeight: 'bold'}}>Dernières Datas</Typography>
                            <Grid container direction="row" style={{width: '100%', margin: 0, marginTop: 8}} spacing={2}>
                                {
                                    lastDatas.map((data, index) => {
                                        return(
                                            <Grid key={`LastDatas${index}`} style={{marginRight: 16, marginBottom: 16}}>
                                                <CardCustom>
                                                    <Grid container justify="space-between" alignItems="center">
                                                        <Grid>
                                                            <Grid container direction="row" alignItems="center">
                                                                <Box mr={2}>
                                                                    <IcomoonReact iconSet={iconSet} color="#6EAED1" size={25} icon={data.icon} />
                                                                </Box>
                                                                <Typography variant="h4">{data.subtitle}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Box ml={2}>
                                                            <Grid container justify="flex-end">
                                                            {
                                                                data.data || data.data === 0 ?
                                                                    <Typography variant="h2" colortext={colors.blue.lighter.hue150} style={{fontSize: 23}}>{data.data}</Typography>
                                                                : <CircularProgress size={"18px"} color={"inherit"}/>
                                                            }
                                                            </Grid>
                                                        </Box>
                                                    </Grid>
                                                </CardCustom>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Grid>
                        <Grid item xl={sliderData.length > 1 ? 12 : 6} lg={sliderData.length > 1 ? 12 : 8} md={sliderData.length > 1 ? 12 : 8} xs={12} style={{marginTop: 12}}>
                            <Typography variant="h4" style={{fontWeight: 'bold'}}>Slider d'actions</Typography>
                            <Grid container direction="row" style={{marginTop: 8}}>
                                <CardCustom contentpadding="16px 0 0 0">
                                    <SliderDashboard sliderData={sliderData} windowWidth={this.props.windowWidth}/>
                                </CardCustom>
                            </Grid>
                        </Grid>

                        <DataBar datas={this.state.stockageCat} remaining={this.state.remainginSize} warning={this.state.warningStorage}></DataBar>
                                
                        {
                            this.state.lastMediaList ? (
                                <Grid item xs={12} style={{marginTop: 12}}>
                                    <Typography variant="h4" style={{fontWeight: 'bold', paddingTop: 24, paddingBottom: 8}}>Derniers médias uploadés</Typography>
                                    <Grid container direction="row">
                                        {
                                            this.state.lastMediaList.map((media,index) => 
                                                <Grid item xs={12} sm={6} md={6} lg={3} key={`media-item-${index}`}>
                                                    <CardCustom contentpadding={0} paddingbottom={'0px'} style={{margin: 8, marginLeft: (index % 4 === 0) ? 0 : 8}}>
                                                        <CardMedia isDashboard={true} callback={this.getLastUpdate} allMedias={this.state.lastMediaList} media={media} currentIndex={index} handleEdit={this.editMedia}></CardMedia>
                                                    </CardCustom>
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            ) : null
                        }

                        <Grid item xs={12} style={{marginTop: 12}}>
                            <Typography variant="h4" style={{fontWeight: 'bold', paddingTop: 24, paddingBottom: 8}}>Liste des tags</Typography>
                            <Grid container direction="row">
                                <CardCustom width={"100%"}>
                                    <MediasTags notPage={true} />
                                </CardCustom>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            : <PageLoader />
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        // startLoading: () => dispatch({ type: START_LOADING }),
        // stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(DashboardMedias)));
