import React from 'react';
import { withApollo } from 'react-apollo';
import styled from 'styled-components';

import colors from '../../../../../config/theme/colors'
import {VIDEOS} from '../../../../../js/constants/medias-types'

import {GET_CHANNEL_BY_ID} from '../../../../../queries/channels';

import DropboxChooser from 'react-dropbox-chooser';
import {Grid,Box,Checkbox,Chip,LinearProgress,Dialog,DialogTitle,DialogContent,DialogActions} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';
import Pagination from '@material-ui/lab/Pagination';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import Typography from '../../../../ui/typography/Typography';
import ButtonCustom from '../../../../ui/button/Button';
import CardCustom from '../../../../layouts/Card/CardCustom';

import {loadGoogleDrive,loadOneDrive} from '../../../../../js/utils/mediasAPI';
import { SNACK } from '../../../../../js/constants/action-types';
import { ALERT_ERROR } from '../../../../../js/constants/alert-types';
import { connect } from "react-redux";

const MediasContainer = styled(Grid)`
    background-color:${colors.grey.lighter.hue980};
    padding:20px;
    margin-top : 20px;
    margin-bottom:20px;
`
const CheckboxCustom = styled(Checkbox)`
    justify-content: flex-start;
    width: 42px;
    &:hover{
        background: unset !important;
    }
    input{
        width: 40px;
    }
    svg{
        fill: ${colors.blue.lighter.hue300}
    }
`  

const ImageContainer = styled(Box)`
    width:100%;
    background-size : cover;
    background-image:url('${props=>props.image}');
    background-position:center;
    height :100%;
    filter:blur(${props=>props.isvideo === 'true' ? 3 : 0}px);
`
let TypeChip = styled(Chip)`
    background: ${props => props.type === 'PNG'? colors.blue.lighter.hue300 : (props.type === "JPEG" || props.type ==="JPG") ? colors.orange.regular : 'black'};
    color: white;
    font-weight: bold;
`
const Title=styled(Typography)`
    transition :color .5s;
    width:100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow:ellipsis;
    font-size:13px;
    text-align:left;
`

const MediaDetailContainer = styled(Grid)`
    padding:40px;
`
const CardMedia = props =>{
    let isVideo     = props.isvideo;
    let type        = props.type;
    let media       = props.media;
    const [checked,setChecked]=React.useState(true);
    const handleCheck=()=>{
        setChecked(!checked);
        props.handleSelect(!checked,media.id);
    }
    return(
        <Grid container> 
            <Grid item xs={12}>
                <CheckboxCustom
                    edge="start"
                    checked={checked}
                    onClick={handleCheck}
                />
            </Grid>
            <Grid item xs={12}>
                <Grid 
                    container 
                    alignItems='center' 
                    justify='center' 
                    style= {{cursor:'pointer',marginBottom:20,overflow:'hidden',height:200}}
                    onClick={()=>props.handleDetails(media)}
                >
                    <ImageContainer 
                        image   = {isVideo ? media.thumbnail : media.image} 
                        isvideo = {isVideo ? true : false} 
                    />

                    {isVideo ? <PlayCircleFilledIcon style={{position:'absolute',fontSize:60,color:colors.blue.regular}}/>:null}
                </Grid>
            </Grid>
            
                
            <Grid item xs={8}>
                <Title variant="h4">{media.name}</Title>
            </Grid>
            <Grid item xs={4}>
                <Grid container justify="flex-end">
                    <TypeChip type={type} label={type}></TypeChip>
                </Grid>
            </Grid>
        </Grid>        
    )



}
class MediaImporter extends React.Component{
    constructor(props){
        super(props)
        this.state={
            channelID : this.props.allState.channelSelected,
            medias : this.props.allState.mediasToImport? this.props.allState.mediasToImport:[],
            selectedMedias:this.props.allState.mediasToImport? this.props.allState.mediasToImport:[],
            nbperpage:6,
            currentPage : 1,
            openMediaDetails:false,
            mediasType:[
                {
                    mime : 'image/',
                    extensions : ['png','jpeg','svg','webp','jpg']
                },
                {
                    mime : 'video/',
                    extensions : ['webm','mp4','mpeg']
                }
            ]
        }

        
    }

    changePage = page =>{
        this.setState({
            currentPage:page,
        })
    }

    arrayBufferToBase64=( buffer ) =>{
        var binary = '';
        var bytes = new Uint8Array( buffer );
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode( bytes[ i ] );
        }
        return window.btoa( binary );
    }

    getImageFromLink=(id,options, url)=>{
        return new Promise((resolve,reject)=>{

            let xhr = new XMLHttpRequest();
    
            xhr.responseType = "arraybuffer";
            // Parametre alt=media permet de récuperer le content du fichier
            if (url){
                xhr.open('GET', url , true);
            }else{
                xhr.open('GET','https://www.googleapis.com/drive/v3/files/'+ id +'?alt=media', true);
            }
    
            xhr.setRequestHeader("Authorization", "Bearer " + options.oauthToken);
    
            xhr.onload = function (e) {
                
                if (xhr.readyState === 4 && xhr.status === 200) {

                    let base64String = this.arrayBufferToBase64(xhr.response);
                    resolve(base64String);
                    
                }
            }.bind(this);
    
            xhr.onerror = function (e) {
                reject(xhr.statusText);
            };
            xhr.send();
        })
    
    
    }
    
    onSuccess = async (files,identifier,options)=>{
        this.setState({currentPage:1})
        let results;
        let currentMedias = this.state.medias.map(media=>media.id);

        switch(identifier){
            case 'dropbox':
                results = files.filter(file=>!file.isDir && !currentMedias.includes(file.id))
                results = results.map(file=>{
                    let mediasTypes = this.state.mediasType;
                    let fileExtension = file.name.substr(file.name.length - 5)
                    let fileMimeType;
                    for (let type of mediasTypes){
                        let mime = type.mime
                        for(let extension of type.extensions){
                            if(fileExtension.includes(extension)){
                                fileMimeType = mime + extension;
                            }
                        } 
                    }
                    return{
                        name:file.name,
                        image : file.link,
                        type : fileMimeType,
                        thumbnail : file.thumbnailLink,
                        id:file.id,
                        fromLink : true
                    }                       

                })
                break;
            
            case 'googledrive' :
                this.setState({
                    mediasPending : files.length,
                    mediasUploaded : 0,
                    openImportModal:true,
                })
                results = files.filter(file=>!file.isDir && !currentMedias.includes(file.id))
                results = await Promise.all(results.map(async media=>{
                    let thumbnail;
                    // let isAllowed = ["image/png","image/jpeg","image/jpg","video/mpeg","video/webm","video/mp4"].includes(media.mimeType);
                    let isVideo = ["video/mpeg","video/webm","video/mp4"].includes();
                    let image = await this.getImageFromLink(media.id,options);
                    image = 'data:'+media.mimeType+';base64,'+image;
                    if(isVideo){
                        thumbnail = '';
                    }
                    else{
                        thumbnail = image;
                    }

                    this.setState({
                        mediasUploaded:this.state.mediasUploaded + 1,
                    }) 
        
                    return{
                        id : media.id,
                        name : media.name,
                        type : media.mimeType,
                        fromLink : true,
                        image : image,
                        thumbnail : thumbnail,
                    }                
                    
                    
        
                }))

                break;

            case 'oneDrive' :
                this.setState({
                    mediasPending : files.value.length,
                    mediasUploaded : 0,
                    openImportModal:true,
                })
                results = files.value.filter(file=>!file.isDir && !currentMedias.includes(file.id))
                results = await Promise.all(results.map(async file=>{
                    let mediasTypes = this.state.mediasType;
                    let fileExtension = file.name.substr(file.name.length - 5)
                    let fileMimeType;
                    for (let type of mediasTypes){
                        let mime = type.mime
                        for(let extension of type.extensions){
                            if(fileExtension.includes(extension)){
                                fileMimeType = mime + extension;
                            }
                        } 
                    }
                    let image = await this.getImageFromLink(file.id,options, file["@content.downloadUrl"]);
                    image = 'data:'+fileMimeType+';base64,'+image;
                    this.setState({
                        mediasUploaded:this.state.mediasUploaded + 1,
                    }) 

                    return{
                        name:file.name,
                        image : image,
                        type : fileMimeType,
                        thumbnail : image,
                        id:file.id,
                        fromLink : true
                    }                       
        
                }))

                break;
            default:
                return null;

        }
        this.setState({
            medias : this.state.medias.concat(results),
            selectedMedias : this.state.medias.concat(results),
            openImportModal:false,
        },()=>this.props.stateCallback('mediasToImport',this.state.selectedMedias))
   
    }

    ImportButton = props =>{
        switch(props.channelType){
            case 'dropbox':
                let appKey = this.state.channel.channelDatas.edges.find(data=>data.node.attribute.identifier === 'db_app_key').node.value;
                return(
                    <DropboxChooser 
                        appKey={appKey}
                        success={files => this.onSuccess(files,'dropbox')}
                        cancel={() =>{}}
                        multiselect={true}
                        extensions={['.png','.mp4','.jpeg','.jpg']} 
                        linkType="direct">

                        <ButtonCustom icomoon="picto-dropbox" bgcolor="#007ADE" bgcolorhover="#0162b1" fontsize={16} onClick={()=>{}}>Importer depuis Dropbox</ButtonCustom>      
                    </DropboxChooser>
                )
            case 'googledrive':
                return(
                    <ButtonCustom onClick={()=>loadGoogleDrive(this.state.channel,this.onSuccess)}>Importer depuis Gdrive</ButtonCustom>
                )
            case 'onedrive':
                return(
                    <ButtonCustom onClick={()=>loadOneDrive(this.state.channel,this.onSuccess, this.props.snack(ALERT_ERROR, "Une erreur s'est produite merci de réessayer ultérieurement"))}>Importer depuis OneDrive</ButtonCustom>
                )
            default:
                return(
                    <Typography>default</Typography>
                )
        }
    }

    MediaDetail = media =>{
        let type=this.getTypeFromMime(media.type);
        let isVideo = VIDEOS.includes(media.type);
        return(
            <React.Fragment>
                <Typography variant="h5" style={{width:'100%',marginBottom:20}}>
                    <Box style={{cursor:'pointer'}} onClick={()=>this.handleToggleDrawer('openMediaDetails')}>X Annuler</Box>
                </Typography>
            <Typography variant="h3" style={{marginBottom:20}}>{media.name}</Typography>
            {isVideo ?
                <video controls autoPlay style={{width:'100%'}}>

            
                <source src={media.image}
                        type={media.type}/>
            
                Sorry, your browser doesn't support embedded videos.
                </video>
            
                :
                <img src={media.image} style={{width:'100%'}}/>
            }    
            <TypeChip type={type} label={type} style={{marginTop:20}}></TypeChip>            
            </React.Fragment>


        )
    }

    getTypeFromMime = type =>{
        var res=type.substring(type.indexOf('/')+1).toUpperCase();
        return res;
    }

    initImport(){
        this.props.client.query({
            query : GET_CHANNEL_BY_ID,
            variables:{id:this.state.channelID}
        }).then(result=>{
            this.setState({
                channel : result.data.channel,
                channelType : result.data.channel.attributeGroup.identifier,
                isReady : true,
            })
        })
    }

    handleSelect = (checked,id) =>{
        let newList = this.state.selectedMedias;
        if(!checked){
            newList=newList.filter(media=>media.id !== id);
        }
        else{
            let media=this.state.medias.find(media=>media.id === id);
            newList.push(media);
        }
        this.setState({
            selectedMedias : newList
        },()=>{
            this.props.stateCallback('mediasToImport',this.state.selectedMedias)   
        })
    }

    handleMediaDetails = media =>{
        this.setState({mediaToShow : media})
        this.handleToggleDrawer('openMediaDetails',true);
    }

    handleToggleDrawer = (stateDrawer,value) => {
        this.setState({ 
            [stateDrawer]: value ? value : !this.state[stateDrawer]
        });

    };
    componentDidMount(){
        this.initImport();
        
    }


    render(){
        let countPage = Math.ceil(this.state.medias.length / this.state.nbperpage);
        let currentMedias = this.state.medias?.slice((this.state.currentPage-1)*this.state.nbperpage,this.state.nbperpage*this.state.currentPage)
        const normalise = value => (value - 0) * 100 / (this.state.mediasPending - 0);

        return(
            <Grid container>
                <div style={{width:this.state.openMediaDetails ? '40%' : '100%',transition:'all .5s'}}>
                <Grid container>
                    <Grid item xs={12}>             
                        <Grid container justify="center" style={{padding:20}}>
                        {this.state.isReady?
                            this.ImportButton({'channelType':this.state.channelType})
                        : <CircularProgress style={{color:colors.blue.darker.hue300}} size={30}/>}
                        </Grid>    
                        
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h3" style={{marginTop:20}}>Médias sélectionnés ({this.state.selectedMedias.length}) : </Typography>
                        <MediasContainer container spacing={2}>
                            {currentMedias && currentMedias?.length>0 ? 
                                currentMedias.map((media,index)=>{

                                    let type=this.getTypeFromMime(media.type);
                                    let isvideo = VIDEOS.includes(media.type);
                                    return(
                                        <Grid item xs={this.state.openMediaDetails? 12 : 4} key={`media-${index}`}>
                                            <CardCustom contentpadding="20px" paddingbottom="20px">
                                                <CardMedia isvideo={isvideo} handleSelect={this.handleSelect} media={media} type={type} handleDetails={this.handleMediaDetails}/>
                                            </CardCustom>
                                        </Grid>                             
                                    )
                                })
                            :
                                <Typography variant="h4" style={{width:'100%',textAlign:'center'}}>Aucun média importé</Typography>
                            }

                            {countPage>1 ?
                                <Grid item xs={12}>
                                    <Grid container justify="center">
                                        <Pagination page={this.state.currentPage} count={countPage} onChange={(event,page)=>{this.changePage(page)}}/>                                         
                                    </Grid>
                            
                                </Grid>

                            :null}
                        </MediasContainer>

                        
                    </Grid>
    
                </Grid>
                </div>
                <div style={{width:this.state.openMediaDetails ? '60%' : '100%',transition:'all .5s',display:this.state.openMediaDetails ? 'block': 'none'}}>
                    <MediaDetailContainer container>
                        {this.state.mediaToShow ? 
                            this.MediaDetail(this.state.mediaToShow)
                        
                        :null}
                        
                    </MediaDetailContainer>
                </div>

                {this.state.openImportModal ? 
                    <Dialog
                    onClose={()=>{}}
                    open = {this.state.openImportModal}
                    fullWidth={true}
                    maxWidth='sm'
                    >
                        <DialogTitle id="max-width-dialog-title">Import en cours</DialogTitle>
                        <DialogContent>
                            <Grid container justify="center">
                                
                                <Typography variant="h4" style={{width:'100%',textAlign:'center'}}>{this.state.mediasUploaded} / {this.state.mediasPending} medias uploadés</Typography>
                                <LinearProgress variant="determinate" value={normalise(this.state.mediasUploaded)} style={{width:'80%',height:6,backgroundColor:colors.blue.lighter.hue300,marginTop:20}}/>
                            </Grid>                            
                        </DialogContent>
                        <DialogActions/>

                    </Dialog>
                :null}
            </Grid>

        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};

export default withApollo(connect(null, mapDispatchToProps)(MediaImporter));

