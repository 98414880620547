import PropTypes from "prop-types";
import React, { useState } from "react";
import Link from 'next/link';
import { useRouter } from 'next/router';

const LinkCustom = (props) => {
    if (props.spread) {
        return <>
            { props.children }
        </>
    } else {
        return <Link href={props.href} as={props.as} shallow={true}>{ props.children }</Link>
    }
}

const IconGroup = ({
  iconWhiteClass,
  data = null,
  spread = false,
  products = []
}) => {
    const router = useRouter();
    const [search, setSearch] = useState('');
    const [display, setDisplay] = useState(false);

    const handleClick = e => {
        setDisplay(!display);
        // e.currentTarget.nextSibling.classList.toggle("active");
    };

    const triggerMobileMenu = () => {
        const offcanvasMobileMenu = document.querySelector(
            "#offcanvas-mobile-menu"
        );

        offcanvasMobileMenu.classList.add("active");
    };

  return (
    <div
      className={`header-right-wrap ${iconWhiteClass ? iconWhiteClass : ""}`}
    >
      { !(data.hasSearch?.value ?? true) ? null : (
        <div className="same-style header-search d-none d-lg-block">
          <button className="search-active" onClick={e => handleClick(e)}>
            <i className="pe-7s-search" />
          </button>
          <div className={`search-content ${display ? 'active' : ''}`}>
            <form action="#" onSubmit={(e) => {
                e.preventDefault();
                
                if (!spread)
                  router.push(`search?s=${search}`);
  
                setDisplay(false);
                setSearch('');
            }}>
              <input type="text" placeholder="Rechercher..." value={search} onChange={e => {
                  setSearch(e.target.value);
              }} />
              <button className="button-search">
                <i className="pe-7s-search" />
              </button>
            </form>
          </div>
        </div>
      )}

      { !(data.hasWishlist?.value ?? true) ? null : (
        <div className="same-style header-wishlist">
          <LinkCustom spread={spread} href={`/wishlist`}>
              <a>
                  <i className="pe-7s-like" />
                  <span className="count-style">
                      { products?.length ?? 0 }
                  </span>
              </a>
          </LinkCustom>
        </div>
      ) }
      
      <div className="same-style mobile-off-canvas d-block d-lg-none">
        <button
          className="mobile-aside-button"
          onClick={() => triggerMobileMenu()}
        >
          <i className="pe-7s-menu" />
        </button>
      </div>
    </div>
  );
};

IconGroup.propTypes = {
  iconWhiteClass: PropTypes.string
};

export default IconGroup;
