import React from 'react';
import { Grid } from '@material-ui/core';
import Typography from '../../../../ui/typography/Typography';
import PageLoader from '../../../../ui/loadings/page-loader/PageLoader'
import colors from '../../../../../config/theme/colors';
import styled from 'styled-components';
import CardCustom from '../../CardCustom';
import * as moment from 'moment';
import CardHeader from '@material-ui/core/CardHeader';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import LanguageIcon from '@material-ui/icons/Language';
import imgNotFound from '../../../../../assets/images/not-found.png';

//ICONES FB
import likeReaction         from '../../../../../assets/pictos/reaction-01.svg'
import loveReaction         from '../../../../../assets/pictos/reaction-02.svg'
import laughReaction        from '../../../../../assets/pictos/reaction-03.svg'
import surprisedReaction    from '../../../../../assets/pictos/reaction-04.svg'
import sadReaction          from '../../../../../assets/pictos/reaction-05.svg'
import angryReaction        from '../../../../../assets/pictos/reaction-06.svg'

const FeedItem = styled(Grid)`
    margin-bottom:30px;
    img{
        width:100%;
    }
`

const SocialCounter = styled(Grid)`
    color:${colors.blue.lighter.hue150}
`

const PostDate = styled(Typography)`
    float:right;
    color:#6EAED1;
`

const MessagePost = styled(Typography)`
    min-height: 60px;
    max-height: 60px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
`

const SeeMore = styled(Typography)`
    color:${colors.blue.lighter.hue300};
    text-align: center;
    cursor: pointer;
`

const SocialsIcon=styled.div`
    width:25px;
    height:25px;
    border-radius:50%;
    background:red;
    display:flex;
    border:3px solid white;
    transform : scale(1);
    transition:all .5s;
    &:hover{
        transform : scale(1.1); 
    }
`
export default class CardFacebook extends React.Component{
    constructor(props){
        super(props)
        this.state={
            numberOfItems : 3
        }         

        this.facebookIcons={
            Like        : likeReaction,
            Love        : loveReaction,
            Haha        : laughReaction,
            Wow         : surprisedReaction,
            Sorry         : sadReaction,
            Grr      : angryReaction
    
    
        }   
    }

    handleClick() {
        this.setState({numberOfItems: this.state.numberOfItems + 3})
    }

    render() {
        
        let getImageType = (asset) => {
            const images = require.context('../../../../../assets/images', true);
            let image;
            try{
                image=images('./' + asset);
            } catch(e){
                image=imgNotFound;
            }
            return image;
        }

        let props = this.props;
        let posts=this.props.data;
        let img = getImageType(props.type.logo);

        return (
            <>
                {posts ? posts.slice(0, this.state.numberOfItems).map((post,index)=>
                    <FeedItem item xs={4} key={`post-${index}`}>
                        <CardCustom contentpadding={"0px 0px 20px 0px"} style={{height: "100%"}} cardContentStyle={{display: "flex", flexDirection:'column'}}>
                                <CardHeader
                                    action={
                                    <IconButton aria-label="settings" onClick={(event)=>{this.props.handleSettings(event,post.id)}}>
                                        <MoreVertIcon style={{color:'#6EAED1'}}/>
                                    </IconButton>
                                    }

                                />
                                <Grid container style={{height: "100%"}}>
                                    <Grid item xs={12} style={{maxHeight: 160,minHeight: 160, overflow: 'hidden', position: 'relative'}}>
                                        {
                                            post.image ?
                                                <img src={post.image} style={{position: "absolute", top: "50%", transform: "translateY(-50%)"}}/>
                                                : <img src={typeof img === "string" ? img : img?.default} style={{width: "100%", height: "100%", objectFit: "contain"}}/>
                                        }
                                    </Grid >                                   

                                    <Grid item xs={12} style={{padding:20,backgroundColor:colors.grey.lighter.hue980}}>
                                        <MessagePost variant="body2">
                                            {post.message}
                                        </MessagePost>
                                    </Grid>
                                    {post.url ?
                                        <SocialCounter item xs={12} style={{padding:'20px  20px'}}>
                                            <Grid container alignItems="center" direction="row">
                                                <LanguageIcon/> 
                                                <a href={post.url} style={{marginLeft:10,color:colors.blue.lighter.hue150}} target="_blank" rel="noreferrer">Lien intégré à la publication</a>
                                            </Grid>

                                        </SocialCounter>
                                        :<div style={{height: 64, width: "100%"}}></div>
                                    }
                                    <Grid item xs={12} style={{padding:'0  20px', display: 'flex', alignItems: "flex-end"}}>
                                        <Grid container space={1} alignItems="center" style={{height: 25}}>
                                            {post.likeDetails.total>0 ? 
                                            <SocialCounter item xs={10}>
                                                <Grid container alignItems="center" direction="row">
                                                    {Object.keys(post.likeDetails).map((reaction,index)=>

                                                        post.likeDetails[reaction]>0 && reaction !== 'total' ?
                                                            
                                                            <Tooltip arrow title={post.likeDetails[reaction]} key={`Reaction-${reaction}`}>
                                                                <SocialsIcon style={{backgroundImage:`url(${this.facebookIcons[reaction.replace('nb','')]})`,marginLeft:index === 0?0:-8,zIndex:10-index}}/>   
                                                            </Tooltip> 

                                                        :null                                                
                                                    )}
                                                 
                                    
                                                    <Typography variant="body2" style={{marginLeft:10}}>{post.likeDetails.total}</Typography>
                                                </Grid>                                                             
                                            </SocialCounter>                                                            
                                            : <Grid item xs={10}><Typography variant="body2">Aucune réaction sur ce post</Typography></Grid>
                                            }

                                            <Grid item xs={2} style={{flexGrow:'1'}}>
                                                <PostDate variant="body2">{moment(post.created_time).format('L')}</PostDate>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                        </CardCustom>
                    </FeedItem>                                    
                )
                : <PageLoader/>}
                {posts?.length > this.state.numberOfItems ?
                    <Grid container justify="center" style={{paddingBottom: 24}}>
                        <SeeMore variant="body2" onClick={()=> this.handleClick()}>Voir plus <br/> ...</SeeMore>
                    </Grid>
                    : null 
                }
            </>
        );
    }
}