import React from "react";
import colors from '../../../colors';


const PG = ({ data, preview, component, block, inputCallback, className, style = null}) => {

    return (
        <div 
            className={className} 
            onClick={() => inputCallback ? inputCallback(component, block) : null}
            style={{ 
                fontSize: data.blocks[block]?.inputs?.size?.value,
                color: data.blocks[block]?.inputs?.color?.value,
                backgroundColor: data.blocks[block]?.inputs?.backgroundColor?.value,
                textTransform: data.blocks[block]?.inputs?.textTransform?.value,
                textAlign: data.blocks[block]?.inputs?.textAlign?.value,
                lineHeight: '1.2',
                border: !preview ? `1px dashed ${colors.blue.lighter.hue150}` : null,
                ...style,
            }}
            dangerouslySetInnerHTML={{ __html: data.blocks[block]?.inputs?.value.value }}
        />
    );
};

export default PG;