import React from 'react';
import Button from '@material-ui/core/Button';
import './Button.scss';
import colors from '../../../config/theme/colors';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import styled from 'styled-components';
import IcomoonReact from "icomoon-react";
import iconSet from '../../../assets/selection.json'
const defaultBg         = colors.green.regular;
const defaultBgHover    = colors.green.darker;
const defaultColor      = '#fff';
const defaultFontSize   = '14px';


const ButtonCustom = styled(Button)`
    margin-top: 10px;
    margin-bottom: 10px;
    text-transform: initial;
    text-transform: ${props => props.texttransform ? props.texttransform+'!important' : 'none'};
    font-weight: bold;
    color: ${props => props.disabled ? 'rgba(0, 0, 0, 0.26)' : props.colortext ? props.colortext : defaultColor};
    background-color: ${props => props.disabled ? 'rgba(0, 0, 0, 0.12)' : props.bgcolor ? props.bgcolor : defaultBg};
    box-shadow:  ${props => props.shadowcolor ? `0px 3px 0px 0px ${props.shadowcolor}` : 'inherit'};
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    position: relative;
    top: 0;
    border: ${props => props.border ? props.border : ''};
    &:hover{
        background-color: ${props => props.disabled ? '#e0e0e0' : props.bgcolorhover ? props.bgcolorhover : defaultBgHover};
        background-color: ${props => (props.shadowcolor && props.bgcolor) ? props.bgcolor : (props.shadowcolor) ? defaultBg : ''};
        background-color: ${props => props.border && !props.bgcolorhover ? props.colortext : ''};
        color: ${props => props.colorhover ? props.colorhover : ''};
        border-color: ${props => props.border ? props.bgcolor : ''};
        top: ${props => props.shadowcolor ? '3px' : '0'};
        box-shadow:  ${props => props.shadowcolor ? `0px 0px 0px 0px ${props.shadowcolor}` : 'inherit'};
    }
    span{
        font-size: ${props => props.fontSize ? `${props.fontSize}px` : defaultFontSize};
        @media screen and (max-width: 1280px){
            font-size: 13px;
        }
        @media screen and (max-width: 1000px){
            font-size: 12px;
        }
    }
    .MuiButton-label{
        ${props => props.contentstyle ? props.contentstyle : ''};
    }
    @media screen and (max-width: 1000px){
        margin-top: 5px;
        margin-bottom: 5px;
    }
`

const OurButton = (props) => {
    const { bgcolor, bgcolorhover, color, colorhover, text, disabled, onClick, disableElevation, disableRipple, border, shadowcolor, fontsize, style, textTransform, contentStyle, className } = props;
    return (
        <ButtonCustom
            variant="contained" 
            disabled={disabled}
            onClick={() => {onClick()}}
            disableElevation={disableElevation}
            disableRipple={disableRipple}
            bgcolor={bgcolor}
            bgcolorhover={bgcolorhover}
            shadowcolor={shadowcolor}
            colortext={color}
            colorhover={colorhover}
            border={border}
            fontSize={fontsize}
            style={style}
            texttransform={textTransform}
            contentstyle={contentStyle}
            className={className}
        >
            {props.deleteIcon ? ( <DeleteIcon /> ) : null }
            {props.arrow === 'left' ? ( <ArrowBackIosRoundedIcon style={{paddingRight: 5, width: '0.8em', height: '0.8em'}}  /> ) : '' }
            { text ? text : ''}
            {props.children ? props.children : '' }
            {props.arrow === 'right' ? ( <ArrowForwardIosRoundedIcon style={{paddingLeft: 5, width: '0.8em', height: '0.8em'}}  />) : '' }
            { props.icon ==="add" ? <AddIcon style={{marginLeft:5}}/> : null }
            { props.icomoon ?  <IcomoonReact iconSet={iconSet} color={color} size={props.fontSize ? props.fontSize+5 : 19} icon={props.icomoon} style={{marginLeft:5}}/> : null }
        </ButtonCustom>
    );
};

export default OurButton