import PropTypes from "prop-types";
import React from "react";
import Cdiscount from './image/cdiscount-2.svg';
import Fnac from './image/fnac_Logo-blanc.png';
import PicWic from './image/picwic-2.png';
import Pg from '../../../../shared/components/CustomParagraph';

const HeaderBook = (props) => {
    let {
        preview = false,
        id = null, 
        data = null,
        noSticky,
        inputCallback = null,
        origin = 'LEGO'
    } = props;

    const renderMobileBanner = (origin) => {
        let backgroundBanner = null;
        let imageUrl = null;
        let notDisplayed = false;
        switch(origin){
            case 'CDISCOUNT': 
                backgroundBanner = '#293847';
                imageUrl = Cdiscount;
                break;
            case 'FNAC': 
                backgroundBanner = '#ebb300';
                imageUrl = Fnac;
                break;
            case 'PICWICTOYS': 
                backgroundBanner = '#FFD300';
                imageUrl = PicWic;
                break;
            default :
                notDisplayed = true;
                break;

        }
        if (!notDisplayed){
            return(
                <div className="originBanner" style={{background: backgroundBanner}}>
                    <img src={imageUrl} alt="Background banner"/>
                </div> 
            )
        }
    }

    let image = data.inputs['backgroundImage'].value;
    
    return (
        <section 
            className="scope-book" 
            onClick={() => inputCallback(id)}
            style={{
                backgroundColor: data.inputs['backgroundColor'].value,
                backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                backgroundSize: '100%',
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                position: 'relative',
            }}
        >
            { !preview && props.children}
            <div className="wrapper_lego">
                <img className="logo" onClick={() => inputCallback(id, 'logo')} 
                    src={
                        typeof data.blocks.logo.inputs.value?.value === 'string' ? 
                            data.blocks.logo.inputs.value.value : 
                            data.blocks.logo.inputs.value?.value?.filePath ? 
                                ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.logo.inputs.value.value.filePath}` 
                                : "http://via.placeholder.com/100x30/CCCCCC/AAAAAA?text=Logo"
                    }
                    alt="Logo Flipbook"
                />
            </div>
            {renderMobileBanner(origin)}
            <div className="banner">
                <Pg data={data} preview={preview} component={id} block={'title'} inputCallback={inputCallback} nopadding={true} />
            </div>
        </section>
    );
};

HeaderBook.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingClass: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default HeaderBook;
