import {STEPPER} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';
import SearchTags from '../../../../ui/inputs/SearchTags';
export default function mediasCategoriesConfig (categories,categorieSelected){
    
    var obj={
        langSelect: false,
        drawerType: 'swipeable',
        noForm: true,
        component: Button,
        titleForm: 'Ajouter un média',
        subTitleForm: 'Veuillez compléter les champs ci-dessous pour créer votre média',
        textButtonValidate: 'Créer',
        formConfig:{
            type:STEPPER,
            finalStep: {
                title: 'Bientôt prêt !',
                subtitle: 'Votre media est presque prêt, encore une étape ! ',
                textButton: 'Importer le média'
            },
            children:[ 
                {
                    labelName: 'Média',
                    isOptionnal: false,
                    optionsInputs:[
                        {
                            type:'oneImage',
                            label: 'Média',
                            translated: false,
                            helper: {
                                text: 'Média',
                                link: false,
                            },
                            required: true,
                            stateName: 'formImage',
                        },                            
                        {
                            type:'text',
                            label: 'Nom du média',
                            translated: false,
                            helper: {
                                text: 'Nom du média',
                                link: false,
                            },
                            required: true,
                            stateName: 'fileName',
                        },
                        {
                            type:'text',
                            label: 'Texte alternatif',
                            translated: false,
                            helper: {
                                text: 'Aide à la SEO et à l\'accessibilité',
                                link: false,
                            },
                            required: false,
                            stateName: 'fileAlt',
                        },
                        {
                            type:'textarea',
                            label: 'Description',
                            translated: false,
                            helper: {
                                text: 'Description du média',
                                link: false,
                            },
                            required: false,
                            stateName: 'fileDescription',
                        },
                        {
                            type: 'selectTree',
                            multiselect : true,
                            label: 'Dossier',
                            translated: false,
                            required:true,
                            helper: {
                                text: 'Indiquez le dossier de destination du média',
                                link: false,
                            },
                            stateName: 'parent',
                            data: (() => {
                                let data = categories.filter(e => e.node.parent === null);
                                let populateChildren = (cats, parent) => {
                                    console.log(parent.node.id, categorieSelected)
                                    let catSelected         = categorieSelected.find(e => e.node?.id === parent.node.id) || '/api/media-object-categories/'+categorieSelected[0] === parent.node.id ? parent.node.id : null;
                                    parent.value            = parent.node.id;
                                    parent.label            = parent.node.libelle;
                                    parent.children         = cats.filter(e => e.node.parent !== null && e.node.parent.id === parent.node.id);
                                    parent.checked          = catSelected ? true : false;
                                    parent.expanded         = true;

                                    
                                    for (let child of parent.children){
                                        populateChildren(cats, child);                                            
                                    }
                                };

                                for (let parent of data){
                                    populateChildren(categories, parent); 
                                }
                                return data;
                            })()
                        }
                    ]   
                },
                {
                    labelName : 'Tags',
                    isOptionnal : false,
                    component : SearchTags,
                }
            ]
        }
    }
    return obj;
}