import React from 'react';
import { withApollo } from 'react-apollo';
import { withStyles } from '@material-ui/core';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_SUCCESS, ALERT_ERROR } from '../../../../js/constants/alert-types';
import { connect } from "react-redux";
import styled from 'styled-components';


import colors from '../../../../config/theme/colors';

import { Grid,Avatar } from '@material-ui/core';
import PermMediaIcon from '@material-ui/icons/PermMedia';

import Typography from '../../../ui/typography/Typography';
import TopPanel from '../../../layouts/TopPanel/TopPanel'
import CardCustom from '../../../layouts/Card/CardCustom'
import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import ButtonCustom from '../../../ui/button/Button';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import EmptyCard from "../../../ui/empty-card/EmptyCard";
import EmptyTag from '../../../../assets/pictos/empty-picto/empty_tags.png';


import { GET_TAGS,ADD_TAG,UPDATE_TAG,DELETE_TAG } from '../../../../queries/medias'
import mediasTagsConfig from './config/mediasTags.config';

import DialogModal from '../../../ui/dialog/DialogModal';
import { withRouter } from 'react-router';

import { ROUTE_HOME } from '../../../../js/constants/route-names';
import { hasRights } from '../../../../js/utils/rights';
import { MEDIAS, MEDIAS_TAGS, VIEW, CREATE, DELETE, UPDATE } from '../../../../js/constants/constant-rights';


const styles = theme => ({
    customAvatar: {
        backgroundColor: colors.blue.lighter.hue300,
        marginRight : 20,
        display:'flex'
    },

    moreOptions:{
        position:'absolute',
        right: -10,
        top: -10,
        fontSize:15
    },
});


const Container = styled(Grid)`
    flex-direction: row;
    align-items: center;
    display: flex;
    position: relative;
`;
const Content = styled(Grid)`
    flex-direction : column;
    flex-grow:1;
    overflow:auto;
`;


class MediasTags extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            openTagForm : this.props.history.location?.state?.formOpen,
            tag:'',
            formAction:'add',
            openDeleteModal :false,
        }
    }

    //#region UTILS
    handleInputChange = (stateName, evt) => {
        const value = evt?.target?.value ?? evt;
        
        this.setState({
            ...this.state,
            [stateName]: value
        });
    }
    //#endregion

    //#region TAGS
    getAllTags =()=>{
        return new Promise((resolve,reject)=>{
            this.props.client.query({
                query : GET_TAGS,
                fetchPolicy: 'no-cache',

            }).then(result=>{
                let tags = result.data.mediaObjectTags.edges;
                tags = tags.sort(function(a, b){
                    if(a.node.tag < b.node.tag) { return -1; }
                    if(a.node.tag > b.node.tag) { return 1; }
                    return 0;
                })
                this.setState({
                    tags : tags,
                    isReady:true,
                })
                resolve()
            })
        })
    }

    editTag = (tag) =>{
        this.setState({
            currentTag : tag,
            tag:tag.tag
        },()=>{
            this.handleToggleDrawer('openTagForm','edit')
        })
    }

    saveTag = () =>{
        let action = this.state.formAction;
        let mutation = action === 'add' ? ADD_TAG : UPDATE_TAG;
        let variables={
            tag : this.state.tag
        }

        if(action === 'edit')
            variables.id = this.state.currentTag.id

        this.props.client.mutate({
            mutation : mutation,
            variables:variables
        }).then(async result=>{
            this.props.startLoading();
            this.handleToggleDrawer('openTagForm')
            await this.getAllTags();
            this.props.stopLoading();
            this.props.snack(ALERT_SUCCESS,action === 'add' ? 'Tag ajouté' : 'Tag modifié');


        })
    }

    deleteTag = async() =>{
        let tag = this.state.currentTag;
        this.props.startLoading();

        await this.props.client.mutate({
            mutation : DELETE_TAG,
            variables:{
                id:tag.id
            }
        })
        await this.getAllTags()
        this.handleToggleDrawer('openTagForm')
        this.setState({
            openDeleteModal : false
        })

        this.props.stopLoading();
        this.props.snack(ALERT_SUCCESS,'Tag supprimé');
    }
    //#endregion

    //#region FORM
    handleToggleDrawer(form,action='add'){
        
        this.setState({ 
            [form] : !this.state[form],
            formAction:action,
            tag : action === 'add' ? '' : this.state.tag

        });    
    }

    handleDelete = (deleteAction = 'deleteTag')=>{

        this.setState({
            deleteAction : deleteAction,
            openDeleteModal : !this.state.openDeleteModal
        })

    }
    //#endregion
    async initPage(){
        await this.getAllTags();
    }

    componentDidMount(){
        const getRights = hasRights(MEDIAS, MEDIAS_TAGS, VIEW)
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }
        this.initPage()
    }

    render(){
        const { classes } = this.props;
        let lettersList = [];
        return(
            <div style={{width: '100%'}}>
                {
                    !this.props.notPage ? (
                        <TopPanel
                            icomoon         = "picto-label" 
                            colorIcomoon    = {colors.blue.lighter.hue300} 
                            title           = "Gérer les tags" 
                            subtitle        = "Gestion des tags de médias" 
                            gradientColor1  = {colors.menu.regular} 
                            gradientColor2  = {colors.menu.darker}  
                            handlerAdd      = {() => this.handleToggleDrawer('openTagForm','add')} 
                            textAdd         = {hasRights(MEDIAS, MEDIAS_TAGS, CREATE) ? "Ajouter un tag" : null}
                            buttonAvailable = {this.state.isReady?!this.state.openTagForm:false}
                            hasBorder       = {true}
                            /*handlerImport   = {() => this.handleFormImport()} 
                            textImport      = "Importer des médias"*/
                        />
                    ) : null
                }

                <Grid container spacing={2} style={{width: 'calc(100% + 8px)', marginTop: 16}}>
                    {this.state.isReady ? 
                        this.state.tags?.length >0 ?    
                            this.state.tags.map((tag,index)=>{
                                let letter = tag.node.tag.charAt(0)
                                let avatar= isNaN(letter) ? letter.toUpperCase() : '#';
                                let letterSection;
                                if(!lettersList.includes(avatar)){
                                    lettersList.push(avatar);
                                    letterSection = avatar;
                                }
                                return(
                                    <React.Fragment key ={`Container-tag-${index}`}>
                                        {   letterSection ?
                                            (
                                                <Grid item xs={12} key={`letter-${letterSection}`}>
                                                    <Typography variant="h3">{letterSection.toUpperCase()}</Typography> 
                                                </Grid>
                                            ) : null
                                        }
                                        <Grid item lg ={this.props.windowWidth > 1700 ? 3 : 4} md={4} sm={4} xs={4} key={`tag-${index}`}>
                                            <CardCustom  paddingbottom="16px" style={{boxShadow:  this.props.notPage ? 'inherit' : null, border: this.props.notPage ? '2px solid #f1f1f1' : null}}>
                                                <Container>
                                                    {/* <IconButton className={classes.moreOptions} sty>
                                                        <MoreVertIcon />
                                                    </IconButton> */}
                                                    <Avatar className={classes.customAvatar}>{avatar}</Avatar>
                                                    <Content item style={{display: 'flex', flexDirection: this.props.windowWidth > 850 ? 'row' : 'column', justifyContent: 'space-between'}}>
                                                        <Grid container style={{alignItems: 'center', justifyContent: this.props.windowWidth > 850 ? null : 'space-between'}}>
                                                            <Typography variant="h4" style={{fontWeight:'bold', maxWidth: 130, width: 'auto', lineHeight: '22px'}}>{tag.node.tag}</Typography>
                                                            <div style={{marginLeft: 8}}>
                                                                <Grid container alignItems="center">
                                                                <PermMediaIcon style={{marginRight:8}}/>
                                                                    <Typography variant="body2">
                                                                        {tag.node.mediaObjects.totalCount}
                                                                    </Typography>  
                                                                </Grid>
                                                            </div>
                                                        </Grid>
                                                        {
                                                            hasRights(MEDIAS, MEDIAS_TAGS, UPDATE) ?
                                                                <Grid container style={{width: 'auto'}}>
                                                                    <Grid item xs={12}>
                                                                        <Grid container alignItems="center" justify={this.props.windowWidth > 850 ? "space-between" : 'flex-end'} style={{marginTop:5, width: 'auto'}}>
                                                                            <ButtonCustom onClick={()=>this.editTag(tag.node)}>Modifier</ButtonCustom>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            : null
                                                        }
                                                    </Content>     
                                                </Container>
                                            </CardCustom>                                        
                                        </Grid>
                                    </React.Fragment>
                                )
                            })
                        : <EmptyCard 
                                title={"Pas de résultat"} 
                                subtitle={"Cliquez sur le bouton ci-dessous pour en ajouter un"}
                                textButton={"Ajouter un tag"} 
                                picto={EmptyTag} 
                                onClick={() => this.handleToggleDrawer('openTagForm','add')}
                                openForm={this.state.openTagForm}
                                xsImg={this.state.openForm ? 4 : 2}
                            />  
                        // <Typography>Pas de résultat</Typography>
                    :<PageLoader/>}
                </Grid>

                {this.state.openTagForm ?
                    <LayoutBuilder
                        icomoon         = "picto-label"
                        opened          = {this.state.openTagForm} 
                        forClose        = {() => {this.handleToggleDrawer('openTagForm')}}
                        dataLayout      = {mediasTagsConfig(this.state.formAction)}
                        allState        = {this.state} 
                        stateCallback   = {this.handleInputChange}
                        handlerMutation = {this.saveTag}
                        validateButton  = {true}
                        deleteMutation  = {hasRights(MEDIAS, MEDIAS_TAGS, DELETE) && this.state.formAction !== 'add' ? this.handleDelete : null}
                        deleteText      = {'Supprimer ce tag'}
                    
                        /*errorCallback={this.handleFormError}*/
                    />
                :null}


                {this.state.openDeleteModal ? 
                    <DialogModal
                        open            = {this.state.openDeleteModal}
                        secondaryAction = {this.handleDelete}
                        primaryAction   = {this.deleteTag}
                        title           = 'Supprimer ce média'
                    >
                        <Typography variant="body2">Êtes-vous sûr de vouloir supprimer ce média ? <strong>Cette action est irréversible</strong></Typography>
                    </DialogModal>
                :null}
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};
export default withStyles(styles)(withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(MediasTags))));