import React from 'react';
import {Grid, Box} from '@material-ui/core';
import Stats from '../../../layouts/Stats/Stats';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import Typography from '../../../ui/typography/Typography';
import colors from '../../../../config/theme/colors';
import CardCustom from '../../../layouts/Card/CardCustom';
import CardActions from '../../../layouts/Card/cardContent/CardActions';
import LineCharts from '../../../layouts/Stats/LineCharts';
import ListStats from '../../../layouts/Stats/ListStats';
import { eventService } from '../../../../js/services/event.service';
import Auchan from '../../../../assets/images/retailers/Auchan.png'
import {ROUTE_CHANNELS_RETAILERS, ROUTE_PRODUCTS_ATTRIBUTES, ROUTE_PRODUCTS_LIST } from '../../../../js/constants/route-names';
import { SNACK } from '../../../../js/constants/action-types';
import { ALERT_ERROR } from '../../../../js/constants/alert-types';
import { hasRights } from '../../../../js/utils/rights';
import { DASHBOARD, DASHBOARD_RETAILERS, VIEW } from '../../../../js/constants/constant-rights';
import { ROUTE_HOME } from '../../../../js/constants/route-names';


var date = ['Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre'];
var allDatas = [
    {
        data: [
            Math.round(Math.random() * 1000),
            Math.round(Math.random() * 2000),
            Math.round(Math.random() * 3000),
            Math.round(Math.random() * 4000),
            Math.round(Math.random() * 5000),
            Math.round(Math.random() * 5000),
            Math.round(Math.random() * 5000),
            Math.round(Math.random() * 5000)],
        color: '#147FAB',
        title: 'Auchan',
    },
    {
        data: [
            Math.round(Math.random() * 500),
            Math.round(Math.random() * 700),
            Math.round(Math.random() * 900),
            Math.round(Math.random() * 1000),
            Math.round(Math.random() * 3000),
            Math.round(Math.random() * 2000),
            Math.round(Math.random() * 1000),
            Math.round(Math.random() * 3000)],
        color: '#E51C52',
        title: 'Target',
    },
    {
        data: [
            Math.round(Math.random() * 300),
            Math.round(Math.random() * 200),
            Math.round(Math.random() * 500),
            Math.round(Math.random() * 600),
            Math.round(Math.random() * 1000),
            Math.round(Math.random() * 800),
            Math.round(Math.random() * 900),
            Math.round(Math.random() * 3000)],
        color: colors.menu.regular,
        title: 'Veepee',
    }
]

var retailersStats = [
    {
        name: 'Auchan',
        view: 320,
        price: 2116,
        dataColor: '#318FB5',
        img: Auchan
    },
    {
        name: 'Auchan',
        view: 320,
        price: 2116,
        dataColor: '#878FAB',
        img: Auchan
    },
    {
        name: 'Target',
        view: 320,
        price: 2116,
        dataColor: '#A1CCDD'
    },
    {
        name: 'Veepee',
        view: 320,
        price: 2116,
        dataColor: '#D8D8E3'
    },
]

class StatsRetailers extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            filtered: [],
            stockageSize:4,
            remainginSize:4,
            stockageCat:[
                {
                    size:0.3,
                    graphSize:0,
                    name:'Fichiers Vidéos',
                    graphColor:'#1A2543'
                },
                {
                    size:1,
                    graphSize:0,
                    name:'Image',
                    graphColor:'#58AFFD'
                },
                {
                    size:2,
                    graphSize:0,
                    name:'Fichiers 360',
                    graphColor:'#37DBE0'
                },

            ]
        };
        this.stats = 
        [
            {
                'icon': 'picto-media',
                'data': 24,
                'dataColor': colors.pink.regular,
                'subtitle': 'Images uploadés',
                'gradientColor1': colors.pink.regular,
                'gradientColor2': colors.pink.lighter,
                'notification':{
                    'notificationColor': colors.green.regular
                }
            },
            {
                'icon': 'picto-3d',
                'data': 0,
                'dataColor': colors.pink.regular,
                'subtitle': 'Fichiers 3D uploadés',
                'gradientColor1': colors.pink.regular,
                'gradientColor2': colors.pink.lighter,
                'notification':{
                    'notificationColor': colors.red.darker
                }
            },
            {
                'icon': 'picto-video',
                'data': 1,
                'dataColor': colors.pink.regular,
                'subtitle': 'Vidéos uploadés',
                'gradientColor1': colors.pink.regular,
                'gradientColor2': colors.pink.lighter,
                'notification':{
                    'notificationColor': colors.orange.regular
                }
            },
            {
                'icon': 'picto-media',
                'data': '24/06/2020',
                'dataColor': colors.pink.regular,
                'subtitle': 'Dernier upload',
                'gradientColor1': colors.pink.regular,
                'gradientColor2': colors.pink.lighter,
                'notification':{
                    'notificationColor': colors.orange.regular
                }
            },
            {
                'icon': 'picto-produit',
                'data': 25,
                'dataColor': colors.pink.regular,
                'subtitle': 'Produits actifs',
                'gradientColor1': colors.pink.regular,
                'gradientColor2': colors.pink.lighter,
                'notification':{
                    'notificationColor': colors.green.regular
                }
            },
        ];
        this.actionsCard = 
        [
            {
                'icon': 'picto-retailer',
                'title': 'Voir mes retailers',
                'subtitle': 'Gérez l’ensemble de vos retailers (création / modification / suppression)', 
                'marginTop': 0,
                'gradientColor1': colors.menu.regular,
                'gradientColor2': colors.menu.darker,
                'route': ROUTE_CHANNELS_RETAILERS
            },
            {
                'icon': 'picto-attribut',
                'title': 'Gérer les attributs',
                'subtitle': 'Gérez l’ensemble de vos attributs (création / modification / suppression)',
                'marginTop': 16,
                'gradientColor1': colors.pink.regular,
                'gradientColor2': colors.pink.lighter,
                'route': ROUTE_PRODUCTS_ATTRIBUTES
            },
            {
                'icon': 'picto-produit',
                'title': 'Gérer mes produits',
                'subtitle': 'Gérez l’ensemble de vos produits (création / modification / suppression)',
                'marginTop': 16,
                'gradientColor1': colors.cyan.regular,
                'gradientColor2': colors.cyan.lighter,
                'route': ROUTE_PRODUCTS_LIST
            },
            
        ];
    }

    handleGetStorageSize(){
        let globalStockage=this.state.stockageSize;
        let files=this.state.stockageCat;
        let newList=[];
        let remainginSize=this.state.stockageSize;
        for (let file of files){
            let usedStockage=(100*file.size)/globalStockage;
            file['graphSize']=usedStockage;
            newList.push(file);
            remainginSize=Math.round((remainginSize-file.size)*100+Number.EPSILON)/100;
        }
        this.setState({
            stockageCat:newList,
            remainginSize:remainginSize
        })
    }

    componentDidMount() {
        const getRights = hasRights(DASHBOARD, DASHBOARD_RETAILERS, VIEW)
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }else{
            this.setState({
                filtered: this.props.products
            });
            this.handleGetStorageSize();
        }
    }

    handleSelectPie = (event) => {
        this.setState({ selectedDataPie: event.target.value }, () => {
            eventService.fire();
        });
    };
    

    render() {
        return (
            <div>
                <Typography variant="h1">
                        Mes statistiques - Par Retailers
                </Typography>
                <Typography variant="body1" component="div">
                    <Box pt={3} pb={2}>
                        Dernières Datas
                    </Box>
                </Typography>
                <Grid container direction="row">
                    <Grid container>
                        {this.stats.map((stat, index) =>
                            (
                                <Grid item lg={2} key={`statsGrid${index}`}>
                                    <Stats stat={stat} />
                                </Grid>
                            )
                        )}
                    </Grid>
                </Grid>
                <Grid container direction="column" justify="center" spacing={0}>
                    <Grid container direction="row" spacing={4}>
                        <Grid item lg={7} md={7} xs={7}>
                            <Grid item xs={12}>
                                <Typography variant="body1" component="div">
                                    <Box pt={3} pb={2}>
                                        Activation par retailers
                                    </Box>
                                </Typography>
                                <Box>
                                    <CardCustom paddingtop={'48px'} contentpadding={'0 32px'}>
                                        <LineCharts xAxisData={date} yAxisData={allDatas} handleSelectPie={this.handleSelectPie}/>
                                    </CardCustom>
                                </Box>
                            </Grid>
                            <Grid item xs={12} style={{paddingTop: 24}}>
                                <Box>
                                    <Box>
                                        <CardCustom paddingtop={'32px'} paddingbottom={'32px'} contentpadding={'0 32px'}>
                                            <ListStats listStats={retailersStats}/>
                                        </CardCustom>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item lg={3} md={5} xs={5}>
                            <Typography variant="body1" component="div">
                                    <Box pt={3} pb={2}>
                                        Que voulez vous faire ?
                                    </Box>
                                </Typography>
                            <Grid container>
                                {this.actionsCard.map((action, index) =>
                                    (
                                        <Grid key={`CardActions${index}`} style={{marginRight: this.props.windowWidth < 1400 ? '8px' : '0px', width: '100%'}}>
                                            <CardCustom style={{marginTop: this.props.windowWidth < 1400 ? '0px' : action.marginTop, background: `transparent linear-gradient(118deg, ${action.gradientColor1} 0%, ${action.gradientColor2} 100%) 0% 0% no-repeat padding-box`}}>
                                                <CardActions action={action} goTo={this.goToAction} />
                                            </CardCustom>
                                        </Grid>
                                    )
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
    goTo = route => {
        this.props.history.push(route);
    };
    
    goToAction = (route) => {
        this.props.history.push(route);
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StatsRetailers));
