import React from 'react';

import { withRouter } from 'react-router';
import { connect } from "react-redux";

import colors from '../../../../config/theme/colors';

import TopPanel from '../../../layouts/TopPanel/TopPanel';
import EmptyCard from '../../../ui/empty-card/EmptyCard';
import EmptySocials from '../../../../assets/pictos/empty-picto/empty_rs.png';
import { ROUTE_ENGINE_ASSETS} from '../../../../js/constants/route-names';


class DiffusionSocials extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            filtered: [],
            ready: true,
        };
    }

    componentDidMount() {
        this.setState({
            filtered: this.props.products
        });
    }
    

    render() {
        return (
            <div>
                <TopPanel 
                    icomoon="picto-rs"
                    colorIcomoon={colors.blue.lighter.hue300}
                    title="Gérer la diffusion des réseaux sociaux" 
                    subtitle="Gestion des posts de vos réseaux sociaux (création / modification / suppression)" 
                    // handlerAdd={() => this.goTo(ROUTE_PRODUCTS_ADD)} 
                    textAdd="Ajouter un réseau social" 
                    handlerImport={() => this.goTo(ROUTE_ENGINE_ASSETS)} 
                    textImport="Voir vos réseaux sociaux" 
                    // searchHandler={this.handleChange} 
                    gradientColor1={colors.menu.regular} 
                    gradientColor2={colors.menu.darker}
                    windowWidth={this.props.windowWidth}
                    // openForm={this.state.openForm}
                    buttonAvailable={this.state.ready}
                    // currentLang={this.state.currentLang} 
                    // handleLang={this.handleLang} 
                    locales={this.props.locales}
                />
                <EmptyCard 
                    title={"Vous n'avez pas encore configuré de réseau social"} 
                    subtitle={"Cliquez sur le bouton ci-dessous pour en ajouter un"} 
                    textButton={"Créer un réseau social"} 
                    picto={EmptySocials} 
                    onClick={() => this.handleToggleDrawer('openForm')}
                    openForm={this.state.openForm}
                />
            </div>
        );
    }
    goTo = route => {
        this.props.history.push(route);
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
    };
};

export default withRouter(connect(mapStateToProps, null)(DiffusionSocials));
