import moment from "moment";
import React from 'react';
import { connect } from "react-redux";
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import DeleteIcon from '@material-ui/icons/Delete';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import colors from "../../config/theme/colors";
import { client } from "../..";
import DialogModal from "../../components/ui/dialog/DialogModal";
import * as Pagination from '../../js/utils/pagination';
import {
    ADD_MODEL_COMPONENT,
    DELETE_MODEL_COMPONENT,
    GET_MODEL_COMPONENTS,
    UPDATE_MODEL_COMPONENT
} from "../../queries/crm_modeles";
import { TextField } from "../inputs";
import { SNACK, START_LOADING, STOP_LOADING } from "../../js/constants/action-types";
import { ALERT_ERROR, ALERT_SUCCESS } from "../../js/constants/alert-types";

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
};

export const get = async () => {
    return await client.query({
        query: GET_MODEL_COMPONENTS,
        fetchPolicy: "no-cache"
    });
};

export const create = async (identifier, name, data) => {
    const ADD_MODEL_COMPONENT_RESULT = await client.mutate({
        mutation: ADD_MODEL_COMPONENT,
        variables: {
            identifier,
            libelle: name,
            content: JSON.stringify(data),
            createdAt: moment().format('YYYY-MM-DD')
        }
    });

    return ADD_MODEL_COMPONENT_RESULT.data.createModelComponent.modelComponent;
};

export const update = async (identifier, name, data) => {
    await client.mutate({
        mutation: UPDATE_MODEL_COMPONENT,
        variables: {
            identifier,
            libelle: name,
            content: JSON.stringify(data)
        }
    });
};

export const remove = async (id) => {
    await client.mutate({
        mutation: DELETE_MODEL_COMPONENT,
        variables: {
            id
        }
    });
};

class SnapshotListing extends React.Component {

    state = {
        snaps: null,
        name: '',
        pagination: {
            page: 0,
            perPage: 5
        }
    };

    componentDidMount() {
        this.getSnapshots();
    }

    onChange = (event) => {
        this.setState({ id: event.target.value });
    };

    onValidate = () => {
        let snap = this.state.snaps.find(e => e.node.id === this.state.id);
        this.props.onValidate(snap);
    };

    getSnapshots = async (reset = false) => {
        let pageInfos = await Pagination.paginationQueryData(reset ? { page: 0, perPage: 5 } : this.state.pagination);
        
        this.props.startLoading();
        
        const result = await client.query({
            query: GET_MODEL_COMPONENTS,
            variables: { ...pageInfos },
            fetchPolicy: 'no-cache'
        });
        
        let pagination = await Pagination.updatePaginationData(this.state.pagination, result.data.modelComponents);

        this.setState({
            pagination,
            snaps: result.data.modelComponents.edges
        });

        this.props.stopLoading();
    };

    render() {
        return (
            <DialogModal
                open={true}
                title={`Choisir un snapshot`}
                notText={true}
                primarybgcolor={colors.green.regular}
                primarybgcolorhover={colors.green.darker}
                primaryText="Valider"
                primaryAction={this.onValidate}
                secondaryAction={this.props.onClose}
                onClose={this.props.onClose}
            >
                <div style={{
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    {
                        this.state.snaps ? this.state.snaps.length === 0 ? <span>Aucun snapshot</span> : (
                            <>
                                <FormControl component="fieldset" style={{ width: '100%' }}>
                                    <RadioGroup value={this.state.id} onChange={this.onChange}>
                                        {
                                            this.state.snaps?.map((e, i) => (
                                                <div>
                                                    <FormControlLabel key={i} value={e.node.id} control={<Radio />} label={`${e.node.libelle} (${moment(e.node.createdAt).format('DD/MM/YYYY')})`} />
                                                    <IconButton color="secondary" onClick={async () => {
                                                        this.props.startLoading();

                                                        await remove(e.node.id);
                                                        await this.getSnapshots(true);

                                                        this.props.snack(ALERT_SUCCESS, 'Snapshot supprimé !');
                                                    }}>
                                                        <DeleteIcon style={{ color: red[500] }} fontSize="small" />
                                                    </IconButton>
                                                </div>
                                            ))
                                        }
                                    </RadioGroup>
                                </FormControl>

                                <Pagination.CursorPagination
                                    label='Snapshots par page'
                                    pagination={this.state.pagination}
                                    type="table"
                                    load={true}
                                    changePageCallback={async (newPage) => {
                                        let pagination = await Pagination.changePage(this.state.pagination, newPage);
                                        this.setState({ pagination }, this.getSnapshots);
                                    }}
                                />
                            </>
                        ) : null
                    }
                </div>
            </DialogModal>
        )
    }
};

class SnapshotCreation extends React.Component {

    state = {
        name: ''
    };

    onChange = (event) => {
        this.setState({ name: event.target.value });
    };

    onValidate = async () => {
        this.props.startLoading();

        try {
            await create(this.props.identifier, this.state.name, this.props.data);
            this.props.snack(ALERT_SUCCESS, 'Snapshot créé !');
            this.props.onClose();
            this.setState({ name: '' });
        } catch (e) {
            this.props.snack(ALERT_ERROR, 'Erreur de création :(');
        } finally {
            this.props.stopLoading();
        }
    };

    render() {
        return (
            <DialogModal
                open={true}
                title="Création d'un snapshot"
                notText={true}
                primarybgcolor={colors.green.regular}
                primarybgcolorhover={colors.green.darker}
                primaryText="Créer"
                primaryAction={this.onValidate}
                secondaryAction={this.props.onClose}
                onClose={this.props.onClose}
            >
                <TextField
                    key={`snapshot-name`}
                    label="Nom"
                    placeholder="Nom du snapshot"
                    type="text"
                    value={this.state.name}
                    onChange={this.onChange}
                    variant="standard"
                    onKeyPress={(e) => {
                        if (e.key === 'Enter')
                            this.onValidate();
                    }}
                />
            </DialogModal>
        )
    }
};

export const SnapshotListingModal = connect(null, mapDispatchToProps)(SnapshotListing);
export const SnapshotCreationModal = connect(null, mapDispatchToProps)(SnapshotCreation);