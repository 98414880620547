import { FormInputTypes } from '../../../shareable/types';
import { getDefaultText, getImageDefault } from '../../helpers';

export default {
    name: 'Header',
    inputs: {
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#FFFFFF'
        }
    },
    blocks: {
        title: getDefaultText("Titre", "Jeu concours", 16, "#53839D"),
        logo: getImageDefault(),
    }
};