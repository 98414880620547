import {FORM} from '../../../../../../js/constants/form-types';
import Button from '../../../../../ui/button/Button';


export default function feedAdd (disabledLink=false,disabledImage=false,assetData,handleMediaPicker=()=>{},postType){
    var obj=
        {
            titleForm:`Ajouter un post ${assetData.assetType.libelle}`,
            subTitleForm: `Ajouter un post ${assetData.assetType.libelle}`,
            /*langSelect: true,*/
            drawerType: 'drawer',
            noForm: true,
            component: Button,
            textButtonValidate: 'Ajouter le post',
            formConfig: {
                type: FORM,
                
                children: []
            },

        }    
    var children=[];

    switch(assetData.assetType.identifier) {
        case 'facebook':
            let facebookPost = [
                {
                    type: 'buttonGroup',
                    label: 'Type de post',
                    helper: {
                        label: 'Type de post',
                        text: 'Indiquez le type de post voulu',
                        link: false,
                    },
                    required: true,
                    stateName: 'postType',
                    value: [
                        {
                            value: 'classic',
                            label: 'Classique',
                        },
                        {
                            value: 'product',
                            label: 'Produit',
                        },
                        {
                            value: 'content',
                            label: 'Contenu',
                        },
                    ]
                }
            ]
            if (postType === 'classic'){
                facebookPost.push(
                    {
                        type: 'mediaPicker',
                        handleMediaPicker : handleMediaPicker,
                        stateName: 'paramImage',
                        label: 'Image de la publication',
                        helper: {
                            text: 'Vous pouvez ajouter un lien OU une image à votre publication',
                            link: false,
                        },
                        required: false,
                        disabled : disabledImage,
                    },   
                    {
                        type: 'text',
                        label : 'Lien externe',
                        required : false,
                        stateName : 'paramLink',
                        helper: {
                            text: 'Vous pouvez ajouter un lien OU une image à votre publication',
                            link: false,
                        },
                        disabled : disabledLink,
                    },
                    {
                        type: 'textareaSimple',
                        label : 'Publication',
                        required : false,
                        stateName : 'paramText',
                        helper: {
                            text: 'Rédigez ici votre publication',
                            link: false,

                        },
                    } 
                )
            } else if (postType === 'product'){
                facebookPost.push( {
                    type: 'productSelector',
                    label : 'Produit',
                    required : false,
                    stateName : 'postProduct',
                    helper: {
                        text: 'Veuillez choisir un produit',
                        link: false
                    }
                });
            } else {
                facebookPost.push( {
                    type: 'productSelector',
                    label : 'Contenu',
                    required : false,
                    stateName : 'postContent',
                    contentSelector : true,
                    helper: {
                        text: 'Veuillez choisir un contenu',
                        link: false
                    }
                });
            }
            children = [
                {
                    labelName: 'Principal',
                    isOptionnal: false,
                    optionsInputs: facebookPost
                }
            ] 
            break;

        case 'twitter':
            let twitterPost = [
                {
                    type: 'buttonGroup',
                    label: 'Type de post',
                    helper: {
                        label: 'Type de post',
                        text: 'Indiquez le type de post voulu',
                        link: false,
                    },
                    required: true,
                    stateName: 'postType',
                    value: [
                        {
                            value: 'classic',
                            label: 'Classique',
                        },
                        {
                            value: 'product',
                            label: 'Produit',
                        },
                        {
                            value: 'content',
                            label: 'Produit',
                        },
                    ]
                }
            ]
            if (postType === 'classic'){
                twitterPost.push(
                    {
                        type: 'textareaSimple',
                        label : 'Publication',
                        required : false,
                        stateName : 'paramText',
                        helper: {
                            text: 'Moins de 280 caractère',
                            link: false,
                            charCount:true,
                            maxChar:280
                        },

                    },
                    {
                        type: 'mediaPicker',
                        label: 'Image #1',
                        helper: {
                            text: 'Vous pouvez ajouter un lien OU une image à votre publication',
                            link: false,
                        },
                        required: false,
                        stateName: 'paramTwitterFirstMedia',
                        disabled : disabledImage,
                        handleMediaPicker: handleMediaPicker,
                    },   
                    {
                        type: 'mediaPicker',
                        handleMediaPicker: handleMediaPicker,

                        label: 'Image #2',
                        helper: {
                            text: 'Vous pouvez ajouter un lien OU une image à votre publication',
                            link: false,
                        },
                        required: false,
                        stateName: 'paramTwitterSecondMedia',
                        disabled : disabledImage,
                    }, 
                    {
                        type: 'mediaPicker',
                        handleMediaPicker: handleMediaPicker,

                        label: 'Image #3',
                        helper: {
                            text: 'Vous pouvez ajouter un lien OU une image à votre publication',
                            link: false,
                        },
                        required: false,
                        stateName: 'paramTwitterThirdMedia',
                        disabled : disabledImage,
                    }, 
                    {
                        type: 'mediaPicker',
                        handleMediaPicker: handleMediaPicker,

                        label: 'Image #4',
                        helper: {
                            text: 'Vous pouvez ajouter un lien OU une image à votre publication',
                            link: false,
                        },
                        required: false,
                        stateName: 'paramTwitterFourthMedia',
                        disabled : disabledImage,
                    }, 
                )
            } else if (postType === 'product') {
                twitterPost.push( {
                    type: 'productSelector',
                    label : 'Produit',
                    required : false,
                    stateName : 'postProduct',
                    helper: {
                        text: 'Veuillez choisir un produit',
                        link: false
                    }
                });
            }else {
                twitterPost.push( {
                    type: 'productSelector',
                    label : 'Contenu',
                    required : false,
                    stateName : 'postContent',
                    contentSelector : true,
                    helper: {
                        text: 'Veuillez choisir un contenu',
                        link: false
                    }
                });
            }
            
            children = [
                {
                    labelName: 'Principal',
                    isOptionnal: false,
                    optionsInputs: twitterPost
                }
            ] 
        break;
            
        case 'wordpress':
            let wordpressPost = [
                {
                    type: 'buttonGroup',
                    label: 'Type de post',
                    helper: {
                        label: 'Type de post',
                        text: 'Indiquez le type de post voulu',
                        link: false,
                    },
                    required: true,
                    stateName: 'postType',
                    value: [
                        {
                            value: 'classic',
                            label: 'Classique',
                        },
                        {
                            value: 'product',
                            label: 'Produit',
                        },
                        {
                            value: 'content',
                            label: 'Contenu',
                        },
                    ]
                }
            ]

            if (postType === 'classic'){
                wordpressPost.push(
                    {
                        type: 'text',
                        label : 'Titre',
                        required : true,
                        stateName : 'paramText',
                        helper: {
                            text: 'Titre de votre article',
                            link: false,
                        },
                    },
                    {
                        type: 'textarea',
                        label : 'Contenu',
                        required : true,
                        stateName : 'paramText2',
                        helper: {
                            text: 'Contenu de votre article',
                            link: false,
                        },
                    },
                )
            } else if (postType === 'product') {
                wordpressPost.push( {
                    type: 'productSelector',
                    label : 'Produit',
                    required : false,
                    stateName : 'postProduct',
                    helper: {
                        text: 'Veuillez choisir un produit',
                        link: false
                    }
                });
            } else {
                wordpressPost.push( {
                    type: 'productSelector',
                    label : 'Contenu',
                    required : false,
                    stateName : 'postContent',
                    contentSelector : true,
                    helper: {
                        text: 'Veuillez choisir un contenu',
                        link: false
                    }
                });
            }

            children = [
                {
                    labelName: 'Principal',
                    isOptionnal: false,
                    optionsInputs: wordpressPost 
                }
            ] 
        break;  
            
        default: children = [];
    }

    obj.formConfig.children=children;
    


    return obj;
}
