import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Link from 'next/link';
import RulesBlock from "../RulesBlock/RulesBlock";
import Pg from '../../../../shared/components/CustomParagraph';
import Div from '../../../../shared/components/CustomDiv';
import Button from '../../../../shared/components/CustomLink';

/* const LinkCustom = (props) => {
    if (props.spread) {
        return(
            <>
                { props.children }
            </>
        );
    } else {
        return <Link href={props.href} as={props.as} shallow={true}>{ props.children }</Link>
    }
}
 */
const PostCardBook = (props) => {
    const [hover, setHover] = useState(false);
    const [hoverBlock, setHoverBlock] = useState(false);
    const [openRules, setOpenRules] = useState(false);

    let {
        id = null, 
        data = null,
        /* noSticky, */
        inputCallback = null,
        preview = false,
        /* spread = false */
    } = props;

    useEffect(() => {
        if(window.location.href.indexOf('?credentials') > 0){
            setOpenRules(true);
        }
    }, [])

    const clickRules = () => {
        setOpenRules(true)
        if (document.getElementById('flipBook')){
            document.getElementById('flipBook').style.zIndex = "2";
        }
        document.getElementsByClassName('postCardWrapper')[0].style.zIndex = "3";
    }

    let image = data.inputs['backgroundImage'].value;
    let dataExplanation = data.blocks.explanation; 
    let image2 = dataExplanation.inputs['backgroundImage'].value;
    let border = null;
    if (dataExplanation.inputs.border) {
        border = dataExplanation.inputs.border;
    }

    return (
        <>
            <div 
                className="postCardWrapper" 
                style={{
                    backgroundColor: data.inputs['backgroundColor'].value,
                    backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    position: 'relative',
                }}
                onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
            >
                { !preview && props.toolbarSection }
                <div 
                    className="container" 
                    style={{top: dataExplanation.inputs.fullWidth.value === 'fullwidth' ? 0 : null, maxWidth: dataExplanation.inputs.fullWidth.value === 'fullwidth' ? 'none' : null}}
                    onMouseEnter={() => {setHover(false); setHoverBlock(true);}} onMouseLeave={() => {setHover(true); setHoverBlock(false);}}
                >
                    { !preview && hoverBlock && props.toolbarBlock }
                    <div 
                        className="cardWrapper"
                        style={{
                            backgroundColor: dataExplanation.inputs['backgroundColor'].value,
                            backgroundImage: typeof image2 === 'string' ? `url(${image2})` : image2?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image2.filePath})` : 'none',
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            position: 'relative',
                            borderStyle: 'solid',
                            borderWidth: border?.inputs?.borderWidth?.value ? border.inputs.borderWidth.value : '0px',
                            borderColor: border?.inputs?.borderColor?.value ? border.inputs.borderColor.value : null,
                            borderRadius: border?.inputs?.borderRadius?.value ? border.inputs.borderRadius.value : null,
                            paddingTop: dataExplanation.inputs.fullWidth.value === 'fullwidth' ? 0 : null,
                            paddingLeft: typeof dataExplanation?.blocks.image.inputs.value.value !== 'string' || !dataExplanation?.blocks.image.inputs.value?.value?.filePath ? 0 : null,
                        }}
                    >
                        <div className="left-block">
                            <div className="title_postcard">
                                <Pg key={`${id}-0`} data={dataExplanation} preview={preview} component={id} block={'rulesTitle'} inputCallback={inputCallback} nopadding={true} />
                            </div>
                            <Pg key={`${id}-1`} data={dataExplanation} className="instructions" preview={preview} component={id} block={'rules'} inputCallback={inputCallback} nopadding={true} />
                            {
                                dataExplanation.blocks.button.inputs.value.value ?
                                (
                                    <div className="wrapper_button">
                                        <Button data={dataExplanation} preview={preview} component={id} block={'button'} className="as_button" inputCallback={inputCallback} nopadding={true} />
                                    </div>
                                ) : null
                            }
                        </div>
                        {
                            typeof dataExplanation?.blocks.image.inputs.value.value === 'string' || dataExplanation?.blocks.image.inputs.value?.value?.filePath ?
                            (
                                <div className="right-block" style={{marginTop: dataExplanation.inputs.fullWidth.value === 'fullwidth' ? 0 : null}}>
                                    <img onClick={inputCallback}
                                        src={
                                            typeof dataExplanation?.blocks.image.inputs.value.value === 'string' ? 
                                            dataExplanation.blocks.image.inputs.value.value : 
                                            dataExplanation?.blocks.image.inputs.value?.value?.filePath ? 
                                                    ` ${process.env.REACT_APP_MEDIAS}/${dataExplanation.blocks.image.inputs.value.value.filePath}` 
                                                    : "http://via.placeholder.com/200x64/FFFFFF/AAAAAA?text=Titre"
                                            }
                                        alt="Décoration du jeu"
                                    />
                                </div>
                            ) : null
                        }
                    </div>
                </div>
                <div className="container">
                    <div className="wrapper_bottom_card">
                        <div className="date_tirage">
                            <Div data={data} preview={preview} component={id} block={'explanationSupp'} inputCallback={inputCallback} nopadding={true} />
                        </div>
                        <div className="rules" onClick={() => clickRules()}>Règlement du jeu</div>
                    </div>
                    <div className="copyright">
                        <Div data={data} preview={preview} component={id} block={'copyright'} inputCallback={inputCallback} nopadding={true} />
                    </div>
                </div>
            </div>
            {
                openRules ? 
                    <RulesBlock isOpen={openRules} onClose={() => setOpenRules(false)}/>
                : null
            }
        </>
    );
};

PostCardBook.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingClass: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default PostCardBook;
