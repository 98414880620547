import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import Button from '../../../ui/button/Button';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import NoImage from '../../../../assets/images/not-found.png';
import { getTraductionAttributs } from '../../../../js/utils/functions';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';


const ContainerCard = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`
const ButtonCustom = styled(Button)`
    margin: 0;
    text-transform: unset;
    font-weight: bold;
`
const Title = styled(Typography)`
    font-weight: bold;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`

const ContentAttr = styled(Typography)`
    margin-top: 10px;
`

const ContainerButton = styled(Box)`
    display: flex;
    justify-content: flex-end;

`
const ContentWrapper = styled.div`
    color: ${colors.grey.regular};
    h2{
        line-height: 20px;
        font-size: 16px; 
    }
    h3{
        line-height: 20px;
    }
    p{
        // max-height: 160px;
        // overflow: hidden;
        line-height: initial;
    }
`


export default function CardContent(props){
    //const [name, setName] = React.useState('');
    const content = props.content;
    const attributes = content.contentDatas.edges;

    let name = '';
    let description = '';
    let image= '';
    // let getImageByTranslation = '';
    // let statsCompletion = product.stats?.infoStats.percentByLangs[props.currentLang] 
    //                         ? product.stats.infoStats.percentByLangs[props.currentLang] 
    //                         : product.stats?.infoStats.percentByLangs[0] 
    //                             ? product.stats.infoStats.percentByLangs[0] 
    //                             : 0;
    
    for(let attribute of attributes){
        if(attribute.node.attribute.isSystem){
            name = getTraductionAttributs('content_title', attributes, props.currentLang);
            description = getTraductionAttributs('content_description', attributes, props.currentLang);
            const regex = /(<p>&lt;product)(.*?)(&gt;<\/p>)/g
            const regexBis = /(<p>&lt;product)(.*?)(&gt; <\/p>)/g
            let removeProductCard = description.match(regex) || description.match(regexBis)
            if (removeProductCard?.length > 0){
                for(let product of removeProductCard){
                    description = description.replace(product, "");
                }
            }
            if(attribute.node.attribute.identifier === 'content_image' && image === ''){
                image = getTraductionAttributs('content_image', attributes, props.currentLang, 'image');
            }
        }
    }

    const goTo = props.routeContent;
    return(
        <ContainerCard pt={2} pb={1}>
            <Grid container style={{flex: '1 0 auto'}}>
                <Grid item xs={12}>
                    {/* <PostTitle variant="h2" style={{padding:'0 20px'}}>
                        {post.title.rendered}
                    </PostTitle> */}
                    <Box mb={2} style={{width: '100%',display: 'flex',alignItems: 'center', maxHeight: 200,minHeight: 200, overflow: 'hidden'}}>
                        <Grid container alignItems="center" justify="center">
                            {
                                image !== '' && image ?  (
                                    <img src={`${process.env.REACT_APP_MEDIAS}/${image.filePath}`} width="100%" alt={name} />
                                ) : 
                                (
                                    <img src={NoImage} width="100%" alt={name}/>
                                )
                            }
                        </Grid>
                    </Box>
                    <Title variant="h4">{name}</Title>
                    <ContentWrapper>
                        <HTMLEllipsis
                        unsafeHTML={description}
                        maxLine='4'
                        ellipsis='...'
                        basedOn='letters'
                        />
                    </ContentWrapper>
                </Grid>
                {
                    content.contentCategories ? (
                        <ContentAttr variant="body1"><strong>{content.contentCategories > 1 ? `Catégories: `: `Catégorie: `}</strong>{content.contentCategories.map((categorie, index) =>
                            {
                                if(categorie.catalog === null){
                                    if (categorie.contentCategoryDatas.edges.length > 0){
                                        let getCategorieByLang = getTraductionAttributs('category_name', categorie.contentCategoryDatas.edges, props.currentLang)
                                        return(
                                            index !== 0 ? ` / ${getCategorieByLang}` : `${getCategorieByLang}`
                                        )
                                    }else{
                                        return(
                                            categorie.libelle
                                        )
                                    }
                                    
                                }else{
                                    return null;
                                }                           
                            }
                        )}</ContentAttr>
                    ) : ''
                }
            </Grid>
            {
                goTo ?
                    <ContainerButton pt={2} style={{justifyContent: "flex-end"}}>
                        <ButtonCustom disableElevation={true} text="Voir le contenu" arrow="right" bgColor={colors.green.regular}  onClick={goTo} />
                    </ContainerButton>
                : null
            }
        </ContainerCard>
    );
}