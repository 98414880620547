import React, {useEffect} from 'react';
import { Box, Typography } from '@material-ui/core';
import Button from '../../../ui/button/Button';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import { getTraductionAttributs } from '../../../../js/utils/functions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { ROUTE_CRM_CONTACTS_SOCIETIES_DETAILS } from '../../../../js/constants/route-names';
import * as moment from 'moment';
import 'moment-timezone';
import { withRouter } from 'react-router';

import emptyCompany from '../../../../assets/images/emptyCompany.jpg';

const ContainerCard = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`
const ButtonCustom = styled(Button)`
    margin: 0;
    text-transform: unset;
    font-weight: bold;
`
const Title = styled(Typography)`
    font-weight: bold;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`

const TableCellCustom = styled(TableCell)`
    border: none;
    display: inline-block;
    width: calc(100% / 9);
    padding: 3px 16px;
`

function CardCompanyList(props){
    //const [name, setName] = React.useState('');
    let libelle;
    let image;
    let type;
    // let url;
    // let address;
    // let companyId        = !props.isDetail ? props.data.node.id : props.data.id;
    
    const [companyData, setCompanyData] = React.useState(null);
    const [startDate, setStartDate] = React.useState(null);

    const goTo = (route, id) => {

        props.history.push({
            pathname : route,
            state: {idCompany : id}
        });
    };

    useEffect(() => {
        if (!props.isDetail){
            if (companyData !== props.data.node){
                let toDateStart = moment(props.data.node.createdAt).format('L');
                setCompanyData(props.data.node)
                setStartDate(toDateStart)
            }            
        }else{
            if (companyData !== props.data){
                let toDateStart = moment(props.data.createdAt).format('L');
                setCompanyData(props.data)
                setStartDate(toDateStart)
            }               
        }
    });
    const attributes = !props.isDetail ? props.data.node?.companyDatas?.edges : props.data.companyDatas?.edges;

    libelle = getTraductionAttributs('company_name', attributes, props.currentLang);
    type = getTraductionAttributs('company_type', attributes, props.currentLang);
    image =getTraductionAttributs('company_image', attributes, props.currentLang,'image')?.filePath;  
    return(
        <ContainerCard>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableBody>
                        <TableRow key={'rowProduct'}>
                            <TableCellCustom component="th" scope="row" align="center" style={{width: 'calc((100%/9) * 1.5)'}}>
                                {
                                    image && image !== '' ?  (
                                        <img src={`${process.env.REACT_APP_MEDIAS}/${image}`} alt={libelle} style={{maxHeight: 40}}/>
                                    ) : 
                                    (
                                        <img src={emptyCompany} style={{maxHeight: 40}} alt={libelle}/>
                                    )
                                }
                            </TableCellCustom>
                            <TableCellCustom align="center">
                                <Typography variant="body2">{type ? type : 'N/A'}</Typography>
                            </TableCellCustom>
                            <TableCellCustom style={{width: 'calc((100%/9) * 2)'}}>
                                <Title variant="body1">{libelle ? libelle : 'N/A'}</Title>
                            </TableCellCustom>
                            <TableCellCustom style={{width: 'calc(100%/9)'}}>
                                <Typography variant="body1">{startDate}</Typography>
                            </TableCellCustom>
                            <TableCellCustom align="center">
                                <Typography variant="body1">{props.data.node.customers?.totalCount}</Typography>
                            </TableCellCustom>
                            <TableCellCustom align="center">
                                <Typography variant="body1">{props.data.node.children?.totalCount}</Typography>
                            </TableCellCustom>
                            <TableCellCustom align="right" style={{width: 'calc((100%/9) * 1.5)'}}>
                                <ButtonCustom disableElevation={true} text="Voir la société" arrow="right" bgColor={colors.green.regular}  onClick={() => goTo(ROUTE_CRM_CONTACTS_SOCIETIES_DETAILS.replace(':id', companyData.libelle), companyData.id)} />
                            </TableCellCustom>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </ContainerCard>
    );
}

export default withRouter(CardCompanyList);