import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import colors from '../../config/theme/colors';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        margin: '15px 0',
        overflow: 'inherit',
        boxShadow: 'inherit',
        borderRadius: 0,
        '& > .MuiCardHeader-root':{
            '& span':{
                color: colors.blue.regular,
            },
            '& svg':{
                fill: colors.blue.regular,
            },
        },
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        })
    },
    expandOpen: {
        transform: 'rotate(180deg)'
    }
}));

export default function CustomCard ({ block, cardInputs, propExpanded = true }) {
    const classes = useStyles();
    const [ expanded, setExpanded ] = useState(propExpanded);

    return (
        <Card className={classes.root}>
            <CardHeader
                subheader={block.name}
                action={
                    <IconButton 
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        onClick={() => setExpanded(!expanded)}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                }
            />
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    { cardInputs }
                </CardContent>
            </Collapse>
        </Card>
    );
};
