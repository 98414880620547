import React,{useState, useEffect} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { gql, useMutation } from '@apollo/react-hooks';
import { connect } from "react-redux";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import Chip from '@material-ui/core/Chip';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import { orange, red } from '@material-ui/core/colors';
import styled from 'styled-components';
import SearchBarProductDynamic from '../../components/ui/inputs/SearchBarProductDynamic';
import Cropper from 'react-cropper';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import { UPDATE_FLIPBOOK_PAGE } from '../../queries/asset_flipbooks';
import { SNACK } from "../../js/constants/action-types";
import { ALERT_SUCCESS, ALERT_ERROR } from "../../js/constants/alert-types";

const BoxButtonUpdate = styled.div`
    .MuiChip-root{
        background: #59C870;
        color: white;
        border-radius: 4px;
        &:hover{
            background: #4CAA5F;
        }
    }
`;

const WrapperCropper = styled.div`
    max-width: 80%;
    margin: auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    &:focus{
        outline: none;
    }
`;

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    cardHeader: {
        color: '#000'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    rulePanel: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    actionBar: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 10
    },
    updateButton: {
        '.MuiChip-root': {
            background: 'red'
        }
    }
}));

const stylesCropper = {
    root: {
        width: '100%',
        border: '1px solid lightgrey',
        borderRadius: '6px',
        height: 150,
        display: 'flex', 
        justifyContent: 'center',
        alignItems: 'center',
        background: '#fff',
        margin: '0 auto',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        flexDirection: 'column'
    },
    cropperContainer: {
        position: 'relative',
    },
    cropper: {
        height: '80vh', 
        width: '100%',
        marginTop: 16
    },
    cropperButton: {
        position: 'absolute',
        top: 10,
        left: 10
    },
    cropperButtonCancel: {
        backgroundColor: red[500],
        position: 'absolute',
        top: 10,
        right: 10
    },
    validateButton: {
        position: 'absolute',
        top: 10,
        left: 60
    }
};

const DeleteButton = withStyles(theme => ({
    root: {
        color: '#fff !important',
        borderRadius: '50% !important',
        backgroundColor: red[500],
        width: 28,
        height: 28,
        minHeight: 10,
        alignSelf: 'flex-end',
        '&:hover': {
            backgroundColor: red[700],
        }
    },
}))(Fab);

class CropperComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            cropper: null,
            values: props?.values ? false : true,
            cropperModify: false,
            cropperOpen: false,
            openModal: false,
        };
    }

    validateCrop = (value) => {
        this.props.page.parsedHtmlContent[this.props.indexCrop].crop.value = {
            imageData: this.state.cropper?.getImageData(),
            canvasData: this.state.cropper?.getCanvasData(),
            cropBoxData: this.state.cropper?.getCropBoxData(),
            dataCrop: this.state.cropper?.getData()
        }

        this.setState({
            cropper: null,
            cropperOpen: false,
            cropperModify: false
        })
        this.toggleOpenModal();
        this.props.updateFlipbook(this.props.allPages);
        this.props.update()
    }

    modifyCrop = () => {
        this.setState({
            cropperOpen: true,
            cropperModify: true
        });
        this.toggleOpenModal();
    }

    cancelCrop = () => {
        this.setState({
            cropper: null,
            cropperOpen: false,
            cropperModify: false,
            openModal: false,
        })
    }

    onCropperInit = (cropper) => {
        this.setState({cropper})
    };

    toggleOpenModal = () => {
        this.setState({
            openModal: !this.state.openModal
        })
    };

    render() {
        return (
            <div style={stylesCropper.cropperContainer}>
                {
                    <>
                        <Modal
                            open={this.state.openModal}
                            onClose={this.toggleOpenModal}
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                        >
                            <WrapperCropper>
                                <Cropper
                                    src={`${process.env.REACT_APP_MEDIAS}/${this.props.page.media.filePath}`}
                                    style={stylesCropper.cropper}
                                    // initialAspectRatio={16 / 9}
                                    checkCrossOrigin={false}
                                    // crossOrigin={'anonymous'}
                                    checkOrientation={false}
                                    guides={true}
                                    // crop={crop.bind(this)}
                                    onInitialized={this.onCropperInit}
                                    // preview=".img-preview"
                                    viewMode={2}
                                    autoCrop={true}
                                    autoCropArea={1}
                                    responsive={true}
                                    background={true}
                                    minCropBoxHeight={10}
                                    minCropBoxWidth={10}
                                    cropend={() => {
                                        if (this.state.cropper)
                                            console.log({
                                                imageData: this.state.cropper.getImageData(),
                                                canvasData: this.state.cropper.getCanvasData(),
                                                cropBoxData: this.state.cropper.getCropBoxData(),
                                                dataCrop: this.state.cropper.getData()
                                            });
                                    }}
                                />

                                <Fab 
                                    color="success" 
                                    aria-label="add" 
                                    size={'small'}
                                    style={stylesCropper.cropperButton}
                                    onClick={() => {
                                        this.validateCrop();
                                    }} 
                                >
                                    <CheckIcon />
                                </Fab> 
                                {
                                    this.state.cropperModify ?
                                        <Fab 
                                            color="secondary" 
                                            aria-label="add" 
                                            size={'small'}
                                            style={stylesCropper.cropperButtonCancel}
                                            onClick={() => {
                                                this.cancelCrop();
                                            }} 
                                        >
                                            <CancelIcon />
                                        </Fab>
                                    : null
                                }
                            </WrapperCropper>
                        </Modal>
                        <Chip 
                            onClick={() => {
                                this.modifyCrop();
                            }}
                            style={{marginTop: 16}}
                        
                            label="Modifier crop"
                            icon={<EditIcon />}
                        />  
                    </>
                }
            </div>
        );
    }
}

function PageCreator({ data, pagesFlipbook, buildFormInput, products, update, catalog, snack, updateFlipbook }){

    const [allPages, setAllPages] = useState(null);
    const [updatePageQGL] = useMutation(UPDATE_FLIPBOOK_PAGE, 
            {onCompleted: () => {snack(ALERT_SUCCESS, 'Page mise à jour !');}, 
            onError: () => snack(ALERT_ERROR, 'Erreur lors de la mise à jour')});


    const ExpansionPanelSummaryCustom = styled(ExpansionPanelSummary)`
        &>div{
            justify-content: space-between;
            align-items: center;
        }
    `;
    const ColorCrop = styled.div`
        margin-left: 8px;
        width: 40px;
        height: 30px;
        opacity: 0.6;
    `;
    const classes = useStyles();

    const onSelect = (value, page, indexCrop) => {
        page.parsedHtmlContent[indexCrop].product.value = value;
        update();
    };
    // const addPage = () => {
    //     let newPage = {
    //         image: {
    //             label: 'Image de la page',
    //             type: 'image',
    //             value: null
    //         },
    //         crops: [],
    //     }
    //     data.pages.push(newPage);
    //     update();
    // };
    // const deletePage = (index) => {
    //     data.pages.splice(index, 1);
    //     update();
    // };
    useEffect(() => {
        // let pages = [];
        setAllPages(pagesFlipbook);
    }, [])

    const addCrop = (page) => {
        let newCrop = {
            product: {
                label: 'Selection du produit',
                type: 'productSelector',
                value: null
            },
            crop: {
                label: 'Crop d\'image ',
                type: 'crop',
                value: null
            },
        }
        if (page.parsedHtmlContent){
            page.parsedHtmlContent.push(newCrop)
        }else {
            page.parsedHtmlContent = [newCrop]
        }
        update();
    };

    const deleteCrop = (page, index) => {
        page.parsedHtmlContent.splice(index, 1)
        update();
    };

    const updatePage = (page) => {
        let variables = null;

        variables = {
            id: '/api/asset-flipbook-pages/' + page.id,
            // media: '/api/mediaObjects/' + page.media.id,
            htmlContent: JSON.stringify(page.parsedHtmlContent)
        }
        updatePageQGL({variables})
        // const {loading, error, data} = useQuery(query, {variables: variables})
        return null;
    };

    let arrayCropColors = ['#cbeef3', '#26547c', '#06d6a0', '#1d3461', '#c2aff0','#EA8C55', '#c75146', '#ad2e24', '#81171b', '#540804']
    
    return (
        <div className={classes.root}>
            {
                allPages?.length > 0 ?
                allPages.map((page, indexPage) => {
                    let inputMedia = {
                        label: 'Image de la page',
                        type: 'image',
                        value: page?.media
                    }
                    return (
                        <ExpansionPanel>
                            <ExpansionPanelSummaryCustom
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.heading}>Page {page.position}</Typography>
                                
                            </ExpansionPanelSummaryCustom>
                            <ExpansionPanelDetails className={classes.rulePanel}>
                                {
                                    buildFormInput(inputMedia, page.position)
                                }
                                {
                                    page.parsedHtmlContent?.length > 0 ?
                                    page.parsedHtmlContent.map((crop, indexCrop) => {
                                        return(
                                            <ExpansionPanel style={{width: '100%'}}>
                                                <ExpansionPanelSummaryCustom
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography className={classes.heading}>Crop {indexCrop + 1}</Typography>
                                                    
                                                </ExpansionPanelSummaryCustom>
                                                <ExpansionPanelDetails className={classes.rulePanel}>
                                                    <SearchBarProductDynamic 
                                                        currentLang={'fr_FR'}
                                                        onSelect={(value) => onSelect(value, page, indexCrop)}
                                                        catalog={catalog}
                                                        selected={crop.product.value}
                                                        static={true}
                                                        products={products}
                                                        displayPrices={true}
                                                    />
                                                    <CropperComponent key={'crop' + indexCrop} values={crop.crop.value} data={pagesFlipbook} page={page} indexCrop={indexCrop} update={update} allPages={allPages} updateFlipbook={updateFlipbook} />
                                                    <div style={{display: 'flex', marginTop: 16, alignItems: 'center'}}>Couleur du crop : <ColorCrop style={{background: arrayCropColors[indexCrop]}}/></div>
                                                    <DeleteButton color="secondary" size="small" aria-label="delete" className={classes.button} 
                                                        onClick={() => {deleteCrop(page, indexCrop)}}
                                                    >
                                                        <DeleteIcon style={{width: 16}} />
                                                    </DeleteButton>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        )
                                    })
                                    : 'Pas encore de crop'
                                }
                                {
                                    <div className={classes.actionBar}>
                                        <Chip
                                            label="Ajouter un crop"
                                            onClick={() => addCrop(page)}
                                            icon={<AddIcon />}
                                        />
                                    </div>
                                }

                                {
                                    <BoxButtonUpdate>
                                        <Chip
                                            label="Mettre à jour la page"
                                            onClick={
                                                () => updatePage(page)
                                            }
                                            // icon={<AddIcon />}
                                        />
                                    </BoxButtonUpdate>
                                }
                                {/* <DeleteButton color="secondary" size="small" aria-label="delete" className={classes.button} 
                                    onClick={() => {deletePage(indexPage)}}
                                >
                                    <DeleteIcon style={{width: 16}} />
                                </DeleteButton> */}
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    )
                }) 
                : 'Pas encore de page'
            }
            {/* {
                <div className={classes.actionBar}>
                    <Chip
                        label="Ajouter une page"
                        onClick={() => addPage()}
                        icon={<AddIcon />}
                    />
                </div>
            } */}
        </div>
    )
};
const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};
export default connect(null, mapDispatchToProps)(PageCreator)