import React from 'react';
import { Grid, Typography, Box, Tooltip } from '@material-ui/core';
import colors from '../../../config/theme/colors';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';


const TypographyCustom = styled(Typography)`
    font-size: 14px;
    font-weight: bold;
    padding-left: 0.5rem;
    line-height: 16px;
    // color: ${props => props.colortext === 'ONLINE' ? colors.green.regular : props.colortext === 'DISABLED' ? colors.grey.lighter.hue600 : props.colortext === 'ERROR' || props.colortext === 'INACTIF' ? colors.red.regular : colors.orange.regular};
    color: ${props => props.colortext ? colors.green.regular : colors.grey.lighter.hue600};
`;
const CircleStatus = styled.div`
    // background-color: ${props => props.colorcircle === 'ONLINE' ? colors.green.regular : props.colorcircle === 'DISABLED' ? colors.grey.lighter.hue600 : props.colorcircle === 'ERROR' ? colors.red.regular : colors.orange.regular};
    background-color: ${props => props.colorcircle ? colors.green.regular : process.env.REACT_APP_MODE_SPREAD === "hub" ? colors.red.regular : colors.grey.lighter.hue600};
    border-radius: 50%;
    width: 14px;
    height: 14px;
`;

export default function StatusInfo(props){
    const useStylesBootstrap = makeStyles(theme => ({
        arrow: {
            color: props.status ? colors.green.regular : colors.grey.lighter.hue600,
        },
        tooltip: {
            backgroundColor: props.status ? colors.green.regular : colors.grey.lighter.hue600,
            fontSize: 14,
            padding: 10,
            textAlign: 'left',
        },
    }));
    
    function BootstrapTooltip(props) {
        const classes = useStylesBootstrap();
        return <Tooltip arrow classes={classes} {...props} />;
    }
    return (
        <Grid container justify={props.justify ? props.justify : 'flex-start'} alignItems="center" direction="row" style={{
            width: props.width
        }}>
            {
                !props.noText ?
                    <>
                        <Box>
                            <Grid container alignItems="center">
                                <CircleStatus colorcircle={props.status}/>
                            </Grid>
                        </Box>
                        <Box>
                            <Grid container alignItems="center">
                                <TypographyCustom variant={"body2"} colortext={props.status ? 1 : 0}>
                                    {props.status
                                        ? props.statusText 
                                            ? props.statusText 
                                            : process.env.REACT_APP_MODE_SPREAD === "hub" ? "EN STOCK" : "ACTIF" 
                                        : process.env.REACT_APP_MODE_SPREAD === "hub" ? "RUPTURE" : 'INACTIF'}
                                </TypographyCustom>
                            </Grid>
                        </Box>
                    </>
                :
                    <BootstrapTooltip 
                        title={
                            <span key={`status-text`} style={{display: 'block'}}>
                                {
                                    props.status
                                        ? props.statusText 
                                            ? props.statusText 
                                            : process.env.REACT_APP_MODE_SPREAD === "hub" ? "EN STOCK" : "ACTIF" 
                                    : process.env.REACT_APP_MODE_SPREAD === "hub" ? "RUPTURE" : 'INACTIF'
                                }
                            </span>
                        } 
                        arrow
                    >
                        <Box>
                            <Grid container alignItems="center">
                                <CircleStatus colorcircle={props.status}/>
                            </Grid>
                        </Box>
                    </BootstrapTooltip>
            }
        </Grid>
    );
};