import React from "react";

export default ({ data, preview, component, block, inputCallback, className, type = null, fullwidth = false, style = null }) => {
    let border = null;
    if (data.blocks[block].inputs.border) {
        border = data.blocks[block].inputs.border;
    }
    let image = data.blocks[block]?.inputs['backgroundImage']?.value;
    return (
        <a
            href={data.blocks[block].inputs.link?.value}
            className={className}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => inputCallback(component, block)} 
            style={{
                backgroundColor: data.blocks[block].inputs.backgroundColor.value,
                backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                padding: typeof image === 'string' || image?.filePath ? `10px ${data.blocks[block].inputs.size?.value + 10}px` : '5px 10px',
                fontSize: data.blocks[block].inputs.size.value,
                color: data.blocks[block].inputs.color.value,
                lineHeight: '2',
                borderStyle: 'solid',
                borderWidth: border?.inputs?.borderWidth?.value ? border.inputs.borderWidth.value : '0px',
                borderColor: border?.inputs?.borderColor?.value ? border.inputs.borderColor.value : null,
                borderRadius: border?.inputs?.borderRadius?.value ? border.inputs.borderRadius.value : null,
                width: fullwidth && border?.inputs?.borderWidth?.value ? `calc(100% - ${border.inputs.borderWidth.value*2}px)` : fullwidth ? '100%' : null,
                ...style,
            }}
            type={type}
        >
            { data.blocks[block].inputs.value.value }
        </a>
    );
};