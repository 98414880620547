// React
import React, { useEffect, Fragment } from "react";
import { useParams, withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import ReactDOMServer from 'react-dom/server';

// Material ui
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Tabs, Tab, Typography, Fab } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import Tooltip from '@material-ui/core/Tooltip';
import { ControlPoint, Visibility, VisibilityOff } from '@material-ui/icons';
import TuneIcon from '@material-ui/icons/Tune';
import StyleIcon from '@material-ui/icons/Style';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import RefreshIcon from '@material-ui/icons/Refresh';
import SaveIcon from '@material-ui/icons/Save';
import FlipToFrontIcon from '@material-ui/icons/FlipToFront';
import FormatShapesIcon from '@material-ui/icons/FormatShapes';
import MenuIcon from '@material-ui/icons/Menu';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CloseIcon from '@material-ui/icons/Close';
import LinkIcon from '@material-ui/icons/Link';

// DND
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

// Styles
import "./builder.scss";
import './shareable/components/assets/minisite/scss/style.scss';
import './shareable/components/assets/offer/scss/style.scss';
import './shareable/components/shared/scss/style.scss';

// Utils
import _ from 'lodash';
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { CopyWithJSON } from "../js/utils/copy";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Select from "react-select";
import colors from "../config/theme/colors";
import styled from "styled-components";
import { Context } from "./shareable/store";
import makeAnimated from "react-select/animated";
import { refreshPhasesOnOffers, savePhases } from "../components/screens/dashboardCRM/offers/utils/OfferHelpers";
import { getTotals } from "../components/screens/dashboardCRM/offers/components/Calculator";
import { getDefaultData, getDefaultSlide, getDefaultConfig } from './utils/data';
import { getDefaultDotations, getDefaultDotationsBF, getDefaultDotationsMoreDetails, getDefaultRules, getDefaultRulesInlineFlex, getDefaultRulesBF, getDefaultSimpleSlide, getDefaultSocial, getOfferStats, getOfferPole, getOfferLeader, getOfferInformations, getOfferListText } from './utils/helpers';

// GQL
import { GET_ASSET_BY_IDENTIFIER, UPDATE_ASSET } from "../queries/assets";
import { UPDATE_MINISITE_PAGE } from '../queries/asset_minisites';
import { UPDATE_GAME_PAGE } from '../queries/asset_games';
import { ADD_MODELE, ADD_PHASE_MODELE, ADD_TACHE_PHASE_MODELE, DELETE_PHASE_MODELE, UPDATE_MODELE, UPDATE_PHASE_MODELE, UPDATE_TACHE_PHASE_MODELE, DELETE_TACHE_PHASE_MODELE, DELETE_MODELE } from "../queries/crm_modeles";
import { ADD_FOOTER_OFFER, ADD_OFFER, ADD_OFFER_FOOTER_TAX, DELETE_OFFER, UPDATE_FOOTER_OFFER, UPDATE_OFFER, UPDATE_OFFER_FOOTER_TAX } from "../queries/crm_offers";
import { GET_MODEL_PRESENTATION_BY_ALIAS, GET_PRESENTATION_BY_ALIAS, UPDATE_MODEL_PRESENTATION, UPDATE_PRESENTATION } from "../queries/crm_presentations";
import { GET_AGENCE_POLES, GET_AGENCE_TARIFS, GET_AGENCE_TAX_RATES, GET_PAYMENT_DEADLINES, GET_PAYMENT_TERMS } from "../queries/crm_agence";
import request from '../js/utils/fetch';

// Constants
import { START_LOADING, STOP_LOADING, SNACK } from "../js/constants/action-types";
import { ROUTE_BUILDER } from "../js/constants/route-names";
import { ALERT_ERROR, ALERT_INFO, ALERT_SUCCESS } from "../js/constants/alert-types";

// Components 
import Button from '../components/ui/button/Button';

// Internal
import { ProductSelector, MultipleBlockConfig, MiniBlockConfig, PageCreator, FinderConfig, OfferConfig } from "./components";
import { TextField, ColorPicker, SliderInput, SelectInput, SwitchInput, ButtonGroupInput, Textarea, ImagePicker, MenuConfigurator, SocialSelector } from "./inputs";
import Toolbar from './shareable/tools/Toolbar';
import { ItemTypes, FormInputTypes, MenuTypes, Pages, EditableTypes, SocialNetworks } from "./shareable/types";
import Draggable from "./draggables/draggable";
import Droppable from "./droppables/droppable";

// Emails 
import EmailBase from '../email/base';
import FlipbookSuccessEmail from '../email/flipbook/emailSuccess';
import MinisiteContactEmail from '../email/minisite/emailContact';

// Templates
import GiftFinderTemplate from "./templates/assets/minisite/giftFinder";
import MagentoTemplate from "./templates/assets/magento/base";
import Wordpress from "./templates/wordpress";
import Facebook from "./templates/facebook";
import EmailTemplate from "./templates/email";

import MinisiteTemplate from "./templates/assets/minisite/base";
import {
    TabProduct,
    Header,
    Slider,
    SectionTitle,
    ImageRow,
    Landing,
    Product,
    Contact,
    Wishlist,
    Image,
    Text,
    SliderWithRightImages,
    SliderMultiple,
    ThreeImagesRow,
    ThreeImagesGrid,
    BlocText,
    GiftFinder,
} from "./shareable/components/assets/minisite/components/index";

import PagesGame from "./templates/assets/game/homepage";
import {
    HeaderGame,
    PreHeaderGame,
    FooterGame,
    HomeGame,
    HomeCompleteFormGame,
    LoginGame,
    IndexGame,
    ResultsWinGame,
    ResultsLooseGame,
    AlreadyPlayedGame,
    EndedGame,
    NotStartedGame,
    ExplanationsGame,
    ExplanationsACVGame,
    ExplanationsBFGame,
    ExplanationsMoreDetailsGame,
    DotationsMoreDetailsSection,
    DotationsIFR,
    RedirectOn,
    RedirectOn2,
    RedirectOn3,
    RedirectOn4,
    BlocBasicGame,
    BlocBasic2Game,
    RulesBloc,
    
    AmazingBlocGame
} from "./shareable/components/assets/game/components/index";

import PagesBook from "./templates/assets/book/homepage";
import {
    HeaderBook,
    FooterBook,
    FlipBook,
    PostCardBook,
    RecapBlockBook,
    ResultScreenBook,
    TopHomepageBook,
    BannerLinkBook
} from "./shareable/components/assets/book/components/index";

import PresentationTemplate from "./templates/presentation";
import {
    OfferFrontPage,
    OfferSectionTitle,
    OfferAboutSlide,
    TripleBlockList,
    TripleBlock,
    TripleBlockText,
    TextWithImage,
    TextWithImageSimple,
    Devis
} from "./shareable/components/assets/offer/components/index";

// Images
import logo from "../assets/images/logo-spread-white.svg";
import imgNotFound from '../assets/images/not-found.png';

// Snapshots
import { SnapshotListingModal, SnapshotCreationModal } from "./modules/Snapshots";

const animatedComponents = makeAnimated();

const SelectCustom = styled(Select)`
    &>div{
        border-radius: 0;
        border: 0;
        div{
            &[class*=singleValue]{
                color: white;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            &[class*=IndicatorsContainer]{
                position: relative;
                &>div{
                    color: white;
                    padding-right: 16px;
                }
                &>span{
                    margin-bottom: 5px;
                    margin-top: 5px;
                    width: 1px;
                    height: calc(100% - 10px);
                    right: 0;
                    position: absolute;
                }
            }
        }
    }
`;

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: colors.blue.regular,
    },
    tooltip: {
        backgroundColor: colors.blue.regular,
    },
}));

const BootstrapTooltip = (props) => {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
};

// Main

const BuilderComponent = ({
    client,
    history,
    snack,
    attributes,
    locales,
    onlyPreview = false,
    onlyLeft = false,
    noToolbar = false,
    presentation = false,
    presentationModel = false,
    assetData,
    pageToEdit,
    externalConfig,
    updateExternalConfig
}) => {
    const height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    const classes = useStyles();

    // ROUTING
    let { assetId, pageId, alias } = useParams();
    const PAGE_TO_EDIT = pageToEdit || pageId;

    // HOOKS
    const [currentTab, setTab] = React.useState(0);
    const [currentComponent, setComponent] = React.useState(null);
    const [currentBlock, setBlock] = React.useState(null);
    const [preview, setPreview] = React.useState(onlyPreview);
    const [asset, setAsset] = React.useState(null);
    const [assetType, setAssetType] = React.useState(null);
    const [assetToken, setAssetToken] = React.useState(null);
    const [catalog, setCatalog] = React.useState(null);
    const [localConfig, setLocalConfig] = React.useState(null);
    const [apiConfig, setApiConfig] = React.useState(null);
    const [pagesFlipbook, setPagesFlipbook] = React.useState(null);
    const [varianteFlipbook, setVarianteFlipbook] = React.useState(null);
    const [ready, setReady] = React.useState(false);
    const [noRight, setNoRight] = React.useState(false);
    const [openChangerPage, setOpenChangerPage] = React.useState(false);
    const [products, updateProducts] = React.useState([]); // used for context (minisite wishlist)
    const [offer, setOffer] = React.useState(null);

    // Offer
    const [poles, setPoles] = React.useState(null);
    const [tarifs, setTarifs] = React.useState(null);
    const [taxes, setTaxes] = React.useState(null);
    const [payments, setPayments] = React.useState(null);
    const [deadlines, setDeadlines] = React.useState(null);

    // Snapshots
    const [snapModalOpen, setSnapModalOpen] = React.useState(false);
    const [snapListModalOpen, setSnapListModalOpen] = React.useState(false);
    const [snapIdentifier, setSnapIdentifier] = React.useState('');
    const [snapData, setSnapData] = React.useState(null);
    const [snapKey, setSnapKey] = React.useState(null);

    let config = externalConfig || localConfig;

    // ComponentDidMount
    useEffect(() => {
        if (assetData) {
            // External use 

            setCatalog(assetData.catalog);
            setAsset(assetData);
            setReady(true);
        } else if (presentation) {
            // CRM presentation

            initPresentation();
        } else if (assetId) {
            // Classic asset use

            client.query({
                query: GET_ASSET_BY_IDENTIFIER,
                variables: { identifier: assetId },
                fetchPolicy: "no-cache"
            }).then(async GET_ASSET_BY_IDENTIFIER_RESULT => {
                let currentAsset = GET_ASSET_BY_IDENTIFIER_RESULT.data.assets.edges[0].node;
                setCatalog(currentAsset.catalog);
                setAsset(currentAsset);
                setAssetType(currentAsset.assetType.identifier);
                setAssetToken(currentAsset.token);

                localStorage.setItem('products', []); // context related

                let config = {
                    params: currentAsset.assetType.identifier === "gift_finder"
                        ? null
                        : !currentAsset.content
                            ? getDefaultParams(currentAsset.assetType.identifier)
                            : cleanParams(JSON.parse(currentAsset.content), currentAsset)
                };

                if (currentAsset.assetType.identifier !== "gift_finder" && currentAsset.assetType.identifier !== "magento") {
                    // minisite || jeu || flipbook

                    let page = (currentAsset.assetType.identifier === "minisite" || currentAsset.assetType.identifier === "newsletter" || currentAsset.assetType.identifier === "flipbook")
                        ? currentAsset.assetMinisitePages.edges.find(e => e.node.assetMinisitePageType.identifier === PAGE_TO_EDIT)
                        : currentAsset.assetType.identifier === "jeu"
                            ? currentAsset.assetGamePages.edges.find(e => e.node.assetGamePageType.identifier === PAGE_TO_EDIT)
                            : null;

                    if (currentAsset.assetType.identifier === "minisite")
                        getMinisiteEmails(currentAsset, config);

                    config[PAGE_TO_EDIT] = page.node.content
                        ? cleanConfig(JSON.parse(page.node.content))
                        : getDefaultPageConfig(PAGE_TO_EDIT, currentAsset.assetType.identifier);
                } else {
                    // gift_finder || magento

                    config[PAGE_TO_EDIT] = currentAsset.content
                        ? JSON.parse(currentAsset.content)
                        : getDefaultPageConfig(PAGE_TO_EDIT, currentAsset.assetType.identifier);
                }

                if (currentAsset.assetType.identifier === "minisite" || currentAsset.assetType.identifier === "newsletter" || currentAsset.assetType.identifier === "flipbook" || currentAsset.assetType.identifier === "gift_finder") {
                    let data = new FormData();
                    data.append('token', currentAsset.token);

                    let apiConfig = await request(`${process.env.REACT_APP_API}/asset/config`, 'post', data, 'multipart/form-data');
                    setApiConfig(apiConfig.asset);

                    if (currentAsset.assetType.identifier === "flipbook") {
                        apiConfig.asset.flipbook.map((page) => { page.parsedHtmlContent = JSON.parse(page.html_content || null) });
                        setPagesFlipbook(apiConfig.asset.flipbook);
                        setVarianteFlipbook(config?.params?.variante?.value);
                    }
                }

                setLocalConfig(config);
                setReady(true);
            });
        }

        if (externalConfig)
            return;
    }, []);

    // ComponentDidUpdate
    useEffect(() => {
        if (assetType === "flipbook") {
            let variante = config?.params?.variante?.value;

            if (varianteFlipbook && variante && variante !== varianteFlipbook) {
                setVarianteFlipbook(variante);
                config[PAGE_TO_EDIT] = getDefaultPageConfig(PAGE_TO_EDIT, asset.assetType.identifier, variante);
                setLocalConfig(config);
            }
        }
    }, [localConfig]);

    // Presentation 

    const initPresentation = async () => {
        const process = (response) => {
            let result = presentationModel
                ? response.data.modelPresentations.edges[0]?.node
                : response.data.presentations.edges[0]?.node;

            if (!result)
                return setReady(true);

            setOffer(result);
            let config = {
                params: null,
            };

            let data = null;

            try {
                data = result.htmlContent ? cleanConfig(JSON.parse(result.htmlContent)) : null;
            } catch (e) {
                console.log('Error parsing invalid json');
            }

            config[Pages.PRESENTATION] = data
                ? refreshPhasesOnOffers(result, data, !presentationModel)
                : {
                    elements: [
                        {
                            type: ItemTypes.OFFER_FRONT_PAGE,
                            key: uuidv4(),
                            data: getDefaultData(ItemTypes.OFFER_FRONT_PAGE)
                        }
                    ]
                };

            setLocalConfig(config);
            setReady(true);
        };

        let poles = await client.query({ query: GET_AGENCE_POLES, fetchPolicy: "no-cache" });
        let tarifs = await client.query({ query: GET_AGENCE_TARIFS, fetchPolicy: "no-cache" });
        let taxes = await client.query({ query: GET_AGENCE_TAX_RATES, fetchPolicy: "no-cache" });
        let payments = await client.query({ query: GET_PAYMENT_TERMS, fetchPolicy: "no-cache" });
        let deadlines = await client.query({ query: GET_PAYMENT_DEADLINES, fetchPolicy: "no-cache" });

        setPoles(poles?.data.agencyPoles.edges);
        setTarifs(tarifs?.data.agencyRates.edges);
        setTaxes(taxes?.data.agencyTaxRates.edges);
        setPayments(payments?.data.agencyPaymentTerms.edges);
        setDeadlines(deadlines?.data.agencyPaymentDeadlines.edges);

        if (presentationModel) {
            client.query({
                query: GET_MODEL_PRESENTATION_BY_ALIAS,
                variables: { alias },
                fetchPolicy: "no-cache"
            }).then(async GET_MODEL_PRESENTATION_BY_ALIAS_RESULT => process(GET_MODEL_PRESENTATION_BY_ALIAS_RESULT));
        } else {
            client.query({
                query: GET_PRESENTATION_BY_ALIAS,
                variables: { alias },
                fetchPolicy: "no-cache"
            }).then(async GET_PRESENTATION_BY_ALIAS_RESULT => process(GET_PRESENTATION_BY_ALIAS_RESULT));
        }
    };

    const exportLink = () => {
        snack(ALERT_SUCCESS, 'Lien copié !');
    };

    // Minisite

    const getMinisiteEmails = (asset, config) => {
        let page = asset.assetMinisitePages.edges.find(e => e.node.assetMinisitePageType.identifier === Pages.EMAIL_CONTACT);

        if (!page)
            return;

        config[Pages.EMAIL_CONTACT] = page.node.content
            ? cleanConfig(JSON.parse(page.node.content))
            : getDefaultPageConfig(PAGE_TO_EDIT, asset.assetType.identifier);
    };

    // Config

    const cleanConfig = (config) => {
        let elements = config?.elements;

        if (!elements)
            return config;

        for (let element of elements) {
            let defaultConfig = getDefaultData(element.type);

            if (!defaultConfig)
                continue;

            // inputs

            if (!element?.data?.inputs)
                element.data.inputs = {};

            for (let defaultInputName in defaultConfig.inputs) {
                let input = element.data.inputs[defaultInputName];

                if (!input) {
                    // not found
                    input = defaultConfig.inputs[defaultInputName];
                } else if (input.type === FormInputTypes.IMAGE) {
                    input.isCroppable = true;
                }
            }

            // blocks 

            if (!element?.data?.block)
                element.data.block = {};

            for (let defaultBlockName in defaultConfig.blocks) {
                if (!element.data.blocks[defaultBlockName]) {
                    // not found
                    element.data.blocks[defaultBlockName] = defaultConfig.blocks[defaultBlockName];
                } else {
                    // block inputs

                    let block = element.data.blocks[defaultBlockName];
                    let defaultBlock = defaultConfig.blocks[defaultBlockName];

                    if (!block?.inputs)
                        block.inputs = {};

                    for (let blockInputName in defaultBlock.inputs) {
                        let input = block.inputs[blockInputName];

                        if (!input) {
                            // not found
                            input = defaultBlock.inputs[blockInputName];
                        } else if (input.type === FormInputTypes.IMAGE) {
                            input.isCroppable = true;
                        }
                    }
                }
            }
        }

        return config;
    };

    const cleanParams = (config, currentAsset) => {
        if (!config)
            return config;

        let defaultConfig = getDefaultParams(currentAsset.assetType.identifier);

        for (let defaultInputName in defaultConfig) {
            if (!config[defaultInputName]) {
                // not found
                config[defaultInputName] = defaultConfig[defaultInputName];
            }
        }

        return config;
    };

    const updateConfig = () => {
        let newConfig = { ...config };
        externalConfig
            ? updateExternalConfig(newConfig)
            : setLocalConfig(newConfig);
    };

    const getDefaultPageConfig = (page, type, custom) => {
        if (type === "flipbook" || type === "newsletter") {
            switch (page) {
                case 'home': return {
                    elements: [
                        {
                            type: ItemTypes.HEADER_BOOK,
                            key: "page-header",
                            data: getDefaultData(ItemTypes.HEADER_BOOK)
                        },
                        {
                            type: ItemTypes.TOP_HOMEPAGE_BOOK,
                            key: "top-homepage-book",
                            data: getDefaultData(ItemTypes.TOP_HOMEPAGE_BOOK)
                        },
                        {
                            type: ItemTypes.POSTCARD_BOOK,
                            key: "postcard-book",
                            data: getDefaultData(ItemTypes.POSTCARD_BOOK)
                        },
                        {
                            type: ItemTypes.FOOTER_BOOK,
                            key: "page-footer",
                            data: getDefaultData(ItemTypes.FOOTER_BOOK)
                        },
                    ]
                };
                case 'flipbook':
                    let variante = custom || (config?.params?.menu?.value ?? "wishlist");
                    if (variante === "cart") {
                        return {
                            elements: [
                                // insert new components around flipbook
                                {
                                    type: ItemTypes.FLIPBOOK_WITH_CART,
                                    key: "flipbook",
                                    data: getDefaultData(ItemTypes.FLIPBOOK_WITH_CART)
                                },
                                {
                                    type: ItemTypes.POSTCARD_BOOK,
                                    key: "postcard-book",
                                    data: getDefaultData(ItemTypes.POSTCARD_BOOK)
                                },
                                {
                                    type: ItemTypes.FOOTER_BOOK,
                                    key: "page-footer",
                                    data: getDefaultData(ItemTypes.FOOTER_BOOK)
                                },
                            ]
                        };
                    }
                    else {
                        return {
                            elements: [
                                {
                                    type: ItemTypes.HEADER_BOOK,
                                    key: "page-header",
                                    data: getDefaultData(ItemTypes.HEADER_BOOK)
                                },
                                {
                                    type: ItemTypes.FLIPBOOK,
                                    key: "flipbook",
                                    data: getDefaultData(ItemTypes.FLIPBOOK)
                                },
                                {
                                    type: ItemTypes.POSTCARD_BOOK,
                                    key: "postcard-book",
                                    data: getDefaultData(ItemTypes.POSTCARD_BOOK)
                                },
                                {
                                    type: ItemTypes.FOOTER_BOOK,
                                    key: "page-footer",
                                    data: getDefaultData(ItemTypes.FOOTER_BOOK)
                                },
                            ]
                        }
                    }
                case 'register': return {
                    elements: [
                        {
                            type: ItemTypes.HEADER_BOOK,
                            key: "page-header",
                            data: getDefaultData(ItemTypes.HEADER_BOOK)
                        },
                        {
                            type: ItemTypes.RECAP_BLOCK_BOOK,
                            key: "recap-book",
                            data: getDefaultData(ItemTypes.RECAP_BLOCK_BOOK)
                        },
                        {
                            type: ItemTypes.POSTCARD_BOOK,
                            key: "postcard-book",
                            data: getDefaultData(ItemTypes.POSTCARD_BOOK)
                        },
                        {
                            type: ItemTypes.FOOTER_BOOK,
                            key: "page-footer",
                            data: getDefaultData(ItemTypes.FOOTER_BOOK)
                        },
                    ]
                };
                case 'register_success': return {
                    elements: [
                        {
                            type: ItemTypes.HEADER_BOOK,
                            key: "page-header",
                            data: getDefaultData(ItemTypes.HEADER_BOOK)
                        },
                        {
                            type: ItemTypes.RESULTS_BOOK,
                            key: "results-book",
                            data: getDefaultData(ItemTypes.RESULTS_BOOK)
                        },
                        {
                            type: ItemTypes.BANNER_LINK_BOOK,
                            key: "banner-link-book",
                            data: getDefaultData(ItemTypes.BANNER_LINK_BOOK)
                        },
                        {
                            type: ItemTypes.FOOTER_BOOK,
                            key: "page-footer",
                            data: getDefaultData(ItemTypes.FOOTER_BOOK)
                        },
                    ]
                };
                case Pages.EMAIL_SUCCESS_BOOK:
                    return {
                        html: <FlipbookSuccessEmail data={getDefaultData(ItemTypes.EMAIL_SUCCESS_BOOK)} id={uuidv4()} />,
                        elements: [
                            {
                                type: "email_register",
                                data: getDefaultData(ItemTypes.EMAIL_SUCCESS_BOOK),
                                key: "email_register",
                            }
                        ]
                    };
            }
        } else {
            switch (page) {
                case Pages.GIFT_FINDER: return {
                    elements: [
                        {
                            type: ItemTypes.GIFT_FINDER,
                            key: uuidv4(),
                            data: getDefaultData(ItemTypes.GIFT_FINDER)
                        }
                    ]
                };
                case Pages.MAGENTO: return {
                    elements: [
                        {
                            type: ItemTypes.PLACEHOLDER,
                            key: uuidv4(),
                            placeholderConfig: {
                                text: "Entête de page",
                                height: 250,
                                bgColor: '#ccc',
                                color: '#9a9a9a',
                                fullWidth: true
                            }
                        }, {
                            type: ItemTypes.SLIDER_WITH_RIGHT_IMAGES,
                            key: uuidv4(),
                            data: getDefaultData(ItemTypes.SLIDER_WITH_RIGHT_IMAGES)
                        }, {
                            type: ItemTypes.SLIDER_MULTIPLE,
                            key: uuidv4(),
                            data: getDefaultData(ItemTypes.SLIDER_MULTIPLE)
                        }, {
                            type: ItemTypes.PLACEHOLDER,
                            key: uuidv4(),
                            placeholderConfig: {
                                text: "Bandeau produits",
                                height: 200,
                                bgColor: '#ccc',
                                color: '#9a9a9a',
                                fullWidth: false
                            }
                        }, {
                            type: ItemTypes.THREE_IMAGES_ROW,
                            key: uuidv4(),
                            data: getDefaultData(ItemTypes.THREE_IMAGES_ROW)
                        }, {
                            type: ItemTypes.THREE_IMAGES_GRID,
                            key: uuidv4(),
                            data: getDefaultData(ItemTypes.THREE_IMAGES_GRID)
                        }, {
                            type: ItemTypes.BLOC_TEXT,
                            key: uuidv4(),
                            data: getDefaultData(ItemTypes.BLOC_TEXT)
                        }, {
                            type: ItemTypes.PLACEHOLDER,
                            key: uuidv4(),
                            placeholderConfig: {
                                text: "Pied de page",
                                height: 200,
                                bgColor: '#ccc',
                                color: '#9a9a9a',
                                fullWidth: true,
                                noBottomMargin: true
                            }
                        }
                    ]
                };
                case Pages.PRESENTATION: return {
                    elements: [
                        {
                            type: ItemTypes.OFFER_FRONT_PAGE,
                            key: uuidv4(),
                            data: getDefaultData(ItemTypes.OFFER_FRONT_PAGE)
                        },
                    ]
                };
                case Pages.HOME: return {
                    elements: [
                        {
                            type: ItemTypes.SLIDER,
                            key: uuidv4(),
                            data: getDefaultData(ItemTypes.SLIDER)
                        },
                    ]
                };
                case Pages.LANDING: return {
                    elements: [
                        {
                            type: ItemTypes.PAGE_LANDING,
                            key: uuidv4(),
                            data: getDefaultData(ItemTypes.PAGE_LANDING)
                        }
                    ]
                };
                case Pages.PRODUCT_DETAILS: return {
                    elements: [
                        {
                            type: ItemTypes.PAGE_PRODUCT,
                            key: uuidv4(),
                            data: getDefaultData(ItemTypes.PAGE_PRODUCT)
                        }
                    ]
                };
                case Pages.CONTACT: return {
                    elements: [
                        {
                            type: ItemTypes.PAGE_CONTACT,
                            key: uuidv4(),
                            data: getDefaultData(ItemTypes.PAGE_CONTACT)
                        }
                    ]
                };
                case Pages.WISHLIST: return {
                    elements: [
                        {
                            type: ItemTypes.PAGE_WISHLIST,
                            key: uuidv4(),
                            data: getDefaultData(ItemTypes.PAGE_WISHLIST)
                        }
                    ]
                };
                case Pages.EMAIL_CONTACT: return {
                    data: getDefaultData(ItemTypes.EMAIL_CONTACT),
                    pageType: Pages.EMAIL_CONTACT,
                    isEmail: true,
                    html: null
                };
                case 'wordpress': return getDefaultConfig(Pages.WORDPRESS);
                case 'facebook': return getDefaultConfig(Pages.FACEBOOK);
                case 'home_game':
                    return {
                        elements: [
                            {
                                type: ItemTypes.HEADER_GAME,
                                key: "page-header",
                                data: getDefaultData(ItemTypes.HEADER_GAME)
                            },
                            {
                                type: ItemTypes.HOME_GAME,
                                key: "page-home",
                                data: getDefaultData(ItemTypes.HOME_GAME)
                            },
                            {
                                type: ItemTypes.EXPLANATIONS_GAME,
                                key: "page-explanations",
                                data: getDefaultData(ItemTypes.EXPLANATIONS_GAME)
                            },
                            {
                                type: ItemTypes.DOTATIONS,
                                key: "page-dotations",
                                data: getDefaultData(ItemTypes.DOTATIONS)
                            },
                            {
                                type: ItemTypes.RULES,
                                key: "page-rules",
                                data: getDefaultData(ItemTypes.RULES)
                            },
                            {
                                type: ItemTypes.FOOTER_GAME,
                                key: "page-footer",
                                data: getDefaultData(ItemTypes.FOOTER_GAME)
                            },
                        ]
                    };
                case 'login_game': return {
                    elements: [
                        {
                            type: ItemTypes.HEADER_GAME,
                            key: "page-header",
                            data: getDefaultData(ItemTypes.HEADER_GAME)
                        },
                        {
                            type: ItemTypes.LOGIN_GAME,
                            key: "page-login",
                            data: getDefaultData(ItemTypes.LOGIN_GAME)
                        },
                        {
                            type: ItemTypes.EXPLANATIONS_GAME,
                            key: "page-explanations",
                            data: getDefaultData(ItemTypes.EXPLANATIONS_GAME)
                        },
                        {
                            type: ItemTypes.DOTATIONS,
                            key: "page-dotations",
                            data: getDefaultData(ItemTypes.DOTATIONS)
                        },
                        {
                            type: ItemTypes.RULES,
                            key: "page-rules",
                            data: getDefaultData(ItemTypes.RULES)
                        },
                        {
                            type: ItemTypes.FOOTER_GAME,
                            key: "page-footer",
                            data: getDefaultData(ItemTypes.FOOTER_GAME)
                        },
                    ]
                };
                case 'index_game': return {
                    elements: [
                        {
                            type: ItemTypes.HEADER_GAME,
                            key: "page-header",
                            data: getDefaultData(ItemTypes.HEADER_GAME)
                        },
                        {
                            type: ItemTypes.INDEX_GAME,
                            key: "page-index",
                            data: getDefaultData(ItemTypes.INDEX_GAME)
                        },
                        {
                            type: ItemTypes.EXPLANATIONS_GAME,
                            key: "page-explanations",
                            data: getDefaultData(ItemTypes.EXPLANATIONS_GAME)
                        },
                        {
                            type: ItemTypes.DOTATIONS,
                            key: "page-dotations",
                            data: getDefaultData(ItemTypes.DOTATIONS)
                        },
                        {
                            type: ItemTypes.RULES,
                            key: "page-rules",
                            data: getDefaultData(ItemTypes.RULES)
                        },
                        {
                            type: ItemTypes.FOOTER_GAME,
                            key: "page-footer",
                            data: getDefaultData(ItemTypes.FOOTER_GAME)
                        },
                    ]
                };
                case 'results_win_game':
                    return {
                        elements: [
                            {
                                type: ItemTypes.HEADER_GAME,
                                key: "page-header",
                                data: getDefaultData(ItemTypes.HEADER_GAME)
                            },
                            {
                                type: ItemTypes.RESULTS_WIN_GAME,
                                key: "page-results",
                                data: getDefaultData(ItemTypes.RESULTS_WIN_GAME)
                            },
                            {
                                type: ItemTypes.REDIRECT_ON_GAME,
                                key: "page-redirect-on",
                                data: getDefaultData(ItemTypes.REDIRECT_ON_GAME)
                            },
                            {
                                type: ItemTypes.FOOTER_GAME,
                                key: "page-footer",
                                data: getDefaultData(ItemTypes.FOOTER_GAME)
                            },
                        ]
                    };
                case 'results_loose_game': return {
                    elements: [
                        {
                            type: ItemTypes.HEADER_GAME,
                            key: "page-header",
                            data: getDefaultData(ItemTypes.HEADER_GAME)
                        },
                        {
                            type: ItemTypes.RESULTS_LOOSE_GAME,
                            key: "page-results",
                            data: getDefaultData(ItemTypes.RESULTS_LOOSE_GAME)
                        },
                        {
                            type: ItemTypes.REDIRECT_ON_GAME,
                            key: "page-redirect-on",
                            data: getDefaultData(ItemTypes.REDIRECT_ON_GAME)
                        },
                        {
                            type: ItemTypes.FOOTER_GAME,
                            key: "page-footer",
                            data: getDefaultData(ItemTypes.FOOTER_GAME)
                        },
                    ]
                };
                case 'alreadyplayed_game': return {
                    elements: [
                        {
                            type: ItemTypes.HEADER_GAME,
                            key: "page-header",
                            data: getDefaultData(ItemTypes.HEADER_GAME)
                        },
                        {
                            type: ItemTypes.ALREADYPLAYED_GAME,
                            key: "page-alreadyplayed",
                            data: getDefaultData(ItemTypes.ALREADYPLAYED_GAME)
                        },
                        {
                            type: ItemTypes.FOOTER_GAME,
                            key: "page-footer",
                            data: getDefaultData(ItemTypes.FOOTER_GAME)
                        },
                    ]
                };
                case 'ended_game': return {
                    elements: [
                        {
                            type: ItemTypes.HEADER_GAME,
                            key: "page-header",
                            data: getDefaultData(ItemTypes.HEADER_GAME)
                        },
                        {
                            type: ItemTypes.ENDED_GAME,
                            key: "page-ended",
                            data: getDefaultData(ItemTypes.ENDED_GAME)
                        },
                        {
                            type: ItemTypes.FOOTER_GAME,
                            key: "page-footer",
                            data: getDefaultData(ItemTypes.FOOTER_GAME)
                        },
                    ]
                };
                case 'not_started_game': return {
                    elements: [
                        {
                            type: ItemTypes.HEADER_GAME,
                            key: "page-header",
                            data: getDefaultData(ItemTypes.HEADER_GAME)
                        },
                        {
                            type: ItemTypes.NOT_STARTED_GAME,
                            key: "page-not-started",
                            data: getDefaultData(ItemTypes.NOT_STARTED_GAME)
                        },
                        {
                            type: ItemTypes.FOOTER_GAME,
                            key: "page-footer",
                            data: getDefaultData(ItemTypes.FOOTER_GAME)
                        },
                    ]
                };
                default: return null;
            }
        }
    };

    const getDefaultParams = (type) => {
        if (type === "minisite" || type === "newsletter") {
            return {
                logo: {
                    id: "main-logo",
                    name: "Logo",
                    type: FormInputTypes.IMAGE,
                    value: "https://via.placeholder.com/200x100"
                },
                menu: {
                    id: "header-menu",
                    name: "Menu",
                    type: FormInputTypes.MENU,
                    value: [
                        {
                            type: MenuTypes.LINK,
                            readonly: true,
                            id: "home",
                            name: "Accueil",
                            value: "/"
                        },
                        {
                            type: MenuTypes.LINK,
                            readonly: true,
                            id: "contact",
                            name: "Contact",
                            value: "/contact"
                        }
                    ]
                },
                emailContact: {
                    id: "emailContact",
                    name: "Email de contact",
                    type: FormInputTypes.TEXT,
                    value: ""
                },
                hasContact: {
                    id: "has-contact",
                    name: "Formulaire de contact",
                    type: FormInputTypes.SWITCH,
                    value: true
                },
                hasSearch: {
                    id: "has-search",
                    name: "Recherche",
                    type: FormInputTypes.SWITCH,
                    value: true
                },
                hasWishlist: {
                    id: "has-wishlist",
                    name: "Wishlist",
                    type: FormInputTypes.SWITCH,
                    value: true
                },
                moto: {
                    id: "moto",
                    name: "Slogan",
                    type: FormInputTypes.TEXTAREA,
                    value: "Lorem ipsum"
                },
                copyright: {
                    id: "copyright",
                    name: "Copyright",
                    type: FormInputTypes.TEXT,
                    value: "© 2020 [Votre entreprise]. All Rights Reserved."
                },
                social: {
                    id: "social-networks",
                    name: "Réseaux sociaux",
                    type: FormInputTypes.SOCIAL,
                    value: [
                        { icon: SocialNetworks.FACEBOOK, value: "//facebook.com" },
                        { icon: SocialNetworks.TWITTER, value: "//twitter.com" },
                        { icon: SocialNetworks.INSTAGRAM, value: "//instagram.com" },
                        { icon: SocialNetworks.PINTEREST, value: "" },
                        { icon: SocialNetworks.YOUTUBE, value: "" },
                        { icon: SocialNetworks.LINKEDIN, value: "" },
                    ]
                },
                numberOne: {
                    name: 'Numéro (1)',
                    type: FormInputTypes.TEXT,
                    value: "+33 0 00 00 00 00"
                },
                numberTwo: {
                    name: 'Numéro (2)',
                    type: FormInputTypes.TEXT,
                    value: "+33 0 00 00 00 00"
                },
                webOne: {
                    name: 'Website (1)',
                    type: FormInputTypes.TEXT,
                    value: "google.com",
                    placeholder: 'Texte'
                },
                webOneLink: {
                    type: FormInputTypes.TEXT,
                    value: "//google.com",
                    placeholder: 'Lien'
                },
                webTwo: {
                    name: 'Website (2)',
                    type: FormInputTypes.TEXT,
                    value: "google.com",
                    placeholder: 'Texte'
                },
                webTwoLink: {
                    type: FormInputTypes.TEXT,
                    value: "//google.com",
                    placeholder: 'Lien'
                },
                addressOne: {
                    name: 'Adresse (1)',
                    type: FormInputTypes.TEXT,
                    value: "1400 Park Avenue Emeryville"
                },
                addressTwo: {
                    name: 'Adresse (2)',
                    type: FormInputTypes.TEXT,
                    value: "CA 94608 United States"
                },
                footerColor: {
                    id: "footer-color",
                    name: "Couleur du pied de page",
                    type: FormInputTypes.COLOR,
                    value: '#000'
                },
            }
        } else if (type === "flipbook") {
            return {
                variante: {
                    type: FormInputTypes.SELECT,
                    label: "Variante de catalogue",
                    value: 'wishlist',
                    params: [{ label: 'Wishlist', value: 'wishlist' }, { label: 'Ajout au panier', value: 'cart' }]
                },
            }
        } 
        else if (type === 'jeu'){
            return {
                bg: {
                    id: "global-background",
                    name: "Fond entier",
                    type: FormInputTypes.IMAGE,
                    value: null,
                },
            }
        } else {
            return null;
        }
    };

    const buildFormInput = (input, id, first = null) => {
        const onChange = (e) => {
            input.value = e?.target?.value ?? e;
            updateConfig();
        };

        switch (input.type) {
            case FormInputTypes.TEXTAREA:
                return <TextField
                    key={`${FormInputTypes.TEXT}-${id}`}
                    label={input.label}
                    type="text"
                    value={input.value}
                    onChange={onChange}
                    variant="outlined"
                    multiline
                    rows={5}
                />
            case FormInputTypes.WYSIWYG:
                return <Textarea
                    key={`${FormInputTypes.TEXTAREA}-${id}`}
                    value={input.value}
                    onChange={onChange}
                />
            case FormInputTypes.TEXT:
                return <TextField
                    key={`${FormInputTypes.TEXT}-${id}`}
                    label={input.label}
                    placeholder={input.placeholder}
                    type="text"
                    value={input.value}
                    onChange={onChange}
                    variant="standard"
                />
            case FormInputTypes.DATE:
                return <TextField
                    key={`${FormInputTypes.DATE}-${id}`}
                    label={input.label}
                    placeholder={input.placeholder}
                    type="date"
                    value={input.value}
                    onChange={onChange}
                    variant="standard"
                />
            case FormInputTypes.NUMBER:
                return <TextField
                    key={`${FormInputTypes.NUMBER}-${id}`}
                    label={input.label}
                    type="number"
                    value={input.value}
                    onChange={onChange}
                    variant="outlined"
                />
            case FormInputTypes.LINK:
                return <TextField
                    key={`${FormInputTypes.LINK}-${id}`}
                    label="Lien"
                    type="text"
                    value={input.value}
                    onChange={onChange}
                    variant="outlined"
                />
            case FormInputTypes.COLOR:
                if (input.value === null) return null;

                return (
                    <ColorPicker
                        key={`${FormInputTypes.COLOR}-${id}`}
                        label={input.label}
                        color={input.value}
                        first={first}
                        onChange={(color) => {
                            input.value = color.rgb ? `rgba(${color.rgb.r},${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})` : color;
                            let newConfig = { ...config };
                            setLocalConfig(newConfig);
                        }}
                    />
                );
            case FormInputTypes.SLIDER:
                return <SliderInput
                    key={`${FormInputTypes.SLIDER}-${id}`}
                    label={input.label}
                    value={input.value}
                    params={input.params}
                    onChange={(value) => {
                        input.value = value;
                        updateConfig();
                    }}
                />
            case FormInputTypes.SELECT:
                if (input.value) {
                    return <SelectInput
                        key={`${FormInputTypes.SELECT}-${id}`}
                        label={input.label}
                        value={input.value}
                        params={input.params}
                        onChange={(value) => {
                            if (input.special) {
                                let i = 0;

                                for (let element of localConfig[PAGE_TO_EDIT].elements) {
                                    if (element.type === value.type) {
                                        localConfig[PAGE_TO_EDIT].elements.splice(i, 1, value);
                                        setLocalConfig(localConfig);
                                        updateConfig();
                                    }
                                    i++;
                                }
                            } else {
                                input.value = value;
                                updateConfig();
                            }
                        }}
                    />
                } else {
                    return null;
                }
            case FormInputTypes.SWITCH:
                return <SwitchInput
                    key={`${FormInputTypes.SWITCH}-${id}`}
                    label={input.label}
                    value={input.value ? true : false}
                    onChange={(value) => {
                        input.value = value;
                        updateConfig();
                    }}
                />
            case FormInputTypes.BUTTON_GROUP:
                return <ButtonGroupInput
                    key={`${FormInputTypes.SWITCH}-${id}`}
                    label={input.label}
                    value={input.value}
                    params={input.params}
                    onChange={(value) => {
                        input.value = value;
                        updateConfig();
                    }}
                />
            case FormInputTypes.IMAGE:
                return <>
                    { input.label && <Typography gutterBottom>{input.label}</Typography>}
                    <ImagePicker
                        key={`${FormInputTypes.IMAGE}-${id}`}
                        value={input.value}
                        valueRef={input.valueRef}
                        isCroppable={input.isCroppable}
                        onChange={(value, ref) => {
                            input.value = value;
                            input.valueRef = ref ?
                                typeof ref === 'string'
                                    ? ref : { ...ref }
                                : null;

                            updateConfig();
                        }}
                    />
                </>
            case FormInputTypes.MENU:
                return <MenuConfigurator
                    key={`${FormInputTypes.MENU}-${id}`}
                    value={input.value}
                    catalog={catalog}
                    locales={locales}
                    onChange={(value) => {
                        input.value = value;
                        updateConfig();
                    }}
                />
            case FormInputTypes.PRODUCTS:
                return <ProductSelector
                    key={`${FormInputTypes.PRODUCTS}-${id}`}
                    catalog={catalog}
                    products={apiConfig.products}
                    label={input.label}
                    value={input.value}
                    onChange={(value) => {
                        input.value = value;
                        updateConfig();
                    }}
                />
            case FormInputTypes.SOCIAL:
                return <SocialSelector
                    key={`${FormInputTypes.SOCIAL}-${id}`}
                    value={input.value}
                    onChange={(value) => {
                        input.value = value;
                        updateConfig();
                    }}
                />
            case FormInputTypes.GROUP:
                let inputs = [];

                inputs.push(<h3 style={{ fontSize: 16, fontWeight: 'initial', color: colors.grey.regular }}>{input.label}</h3>);

                _.mapKeys(input.inputs, function (input, index) {
                    inputs.push(buildFormInput(input, index));
                });

                return inputs;
            default: return null;
        }
    };

    const buildForm = () => {
        let pageConfig = config[PAGE_TO_EDIT];

        let element = pageConfig.isEmail
            ? pageConfig // gets email config
            : pageConfig.elements.find(e => e.key === currentComponent); // gets component config

        // component not found
        if (!element)
            return null;

        // all inputs to display
        let inputs = [];
        let inputsConfig = [];
        let specialInput = {};

        if (assetType === 'jeu' || assetType === 'flipbook') {
            let i = 0;
            specialInput.type = FormInputTypes.SELECT;
            specialInput.special = true;
            specialInput.label = "Récupérer le configuration de la page :";
            specialInput.value = true;
            specialInput.params = [];

            if (assetType === 'jeu') {
                for (let page of asset.assetGamePages.edges) {
                    let namePage = page.node.assetGamePageType.identifier;
                    let content = JSON.parse(page.node.content);

                    if (content && namePage !== PAGE_TO_EDIT) {
                        for (let item of content.elements) {
                            if (item.type === element.type) {
                                specialInput.params[i] = { label: getPageName(namePage), value: item };
                                i++;
                            }
                        }
                    }
                }
            } else {
                for (let page of asset.assetMinisitePages.edges) {
                    let namePage = page.node.assetMinisitePageType.identifier;
                    let content = JSON.parse(page.node.content);

                    if (content && namePage !== PAGE_TO_EDIT) {
                        for (let item of content.elements) {
                            if (item.type === element.type) {
                                specialInput.params[i] = { label: getPageName(namePage), value: item };
                                i++;
                            }
                        }
                    }
                }
            }
        }

        // list component inputs if no block is selected
        if (!currentBlock && element.data?.inputs) {
            if (element.data.name) {
                inputs.push(
                    <h2 style={{ color: colors.blue.regular, width: '100%', padding: '10px 0', marginBottom: 6, fontSize: 16 }}>
                        {element.data.name} - {getPageName(PAGE_TO_EDIT)}
                    </h2>
                );
            }

            if (specialInput && specialInput.params && specialInput.params.length > 0) {
                inputsConfig.push(buildFormInput(specialInput, -1));
            }

            _.mapKeys(element.data.inputs, function (input, i) {
                if (element.type === ItemTypes.OFFER_DEVIS && input.hideIfModel && presentationModel)
                    return;

                inputsConfig.push(buildFormInput(input, i, true));

                if (input.type === FormInputTypes.SWITCH && input.value && input.conditionalInputs) {
                    _.mapKeys(input.conditionalInputs, function (conditionalInput, j) {
                        inputsConfig.push(buildFormInput(conditionalInput, j, true));
                    });
                }
            });

            inputs.push(
                <div className="specificConfig" style={{ width: 'calc(100% - 32px)', padding: 16, background: colors.blue.lighter.hue900 }}>
                    <Typography className={classes.heading} style={{ color: colors.blue.lighter.hue300, paddingBottom: 12 }}>{element.data.name} - Configuration du bloc</Typography>
                    <div style={{ background: 'white', padding: 16 }}>
                        {inputsConfig}
                    </div>
                </div>
            );
        }

        if (currentBlock) {
            let block = element.data.blocks[currentBlock];

            if (block?.type === EditableTypes.MULTIPLE_BLOCKS) {
                return (
                    <>
                        { element.type === ItemTypes.FLIPBOOK_WITH_CART && <PageCreator
                            data={element.data}
                            pagesFlipbook={pagesFlipbook}
                            buildFormInput={buildFormInput}
                            products={apiConfig.products}
                            update={updateConfig}
                            catalog={catalog}
                            updateFlipbook={updateFlipbook}
                        />}
                        <h2 style={{ color: colors.blue.regular, width: '100%', padding: '10px 0', marginBottom: 6, fontSize: 16 }}>
                            {block.name} - {getPageName(PAGE_TO_EDIT)}
                        </h2>
                        <MultipleBlockConfig datas={block} buildFormInput={buildFormInput} addBlock={addBlock} deleteBlock={deleteBlock} />
                    </>
                );
            }

            if (block?.type === EditableTypes.MINI_BLOCKS) {
                return (
                    <>
                        { element.type === ItemTypes.FLIPBOOK_WITH_CART && <PageCreator
                            data={element.data}
                            pagesFlipbook={pagesFlipbook}
                            buildFormInput={buildFormInput}
                            products={apiConfig.products}
                            update={updateConfig}
                            catalog={catalog}
                            updateFlipbook={updateFlipbook}
                        />}
                        <h2 style={{ color: colors.blue.regular, width: '100%', padding: '10px 0', marginBottom: 6, fontSize: 16 }}>
                            {block.name} - {getPageName(PAGE_TO_EDIT)}
                        </h2>
                        <MiniBlockConfig datas={block} buildFormInput={buildFormInput} addBlock={addBlock} deleteBlock={deleteBlock} updateConfig={updateConfig} />
                    </>
                );
            }
        }

        for (let blockName in element.data?.blocks) {
            let loopBlock = element.data.blocks[blockName];

            if (currentBlock && currentBlock !== blockName && loopBlock.type !== EditableTypes.MINI_BLOCKS)
                continue;

            if (loopBlock?.type === EditableTypes.MINI_BLOCKS) {
                let block = element.data.blocks[blockName].blocks[currentBlock];
                let configForm = [];

                if (block) {
                    configForm.push(
                        <h2 style={{ color: colors.blue.regular, width: '100%', padding: '10px 0', marginBottom: 6, fontSize: 16 }}>
                            {block.name}
                        </h2>
                    );

                    if (block.type === EditableTypes.MULTIPLE_BLOCKS) {
                        return (
                            <>
                                { element.type === ItemTypes.FLIPBOOK_WITH_CART && <PageCreator
                                    data={element.data}
                                    pagesFlipbook={pagesFlipbook}
                                    buildFormInput={buildFormInput}
                                    products={apiConfig.products}
                                    update={updateConfig}
                                    catalog={catalog}
                                    updateFlipbook={updateFlipbook}
                                />}
                                <h2 style={{ color: colors.blue.regular, width: '100%', padding: '10px 0', marginBottom: 6, fontSize: 16 }}>
                                    {block.name} - {getPageName(PAGE_TO_EDIT)}
                                </h2>
                                <MultipleBlockConfig datas={block} buildFormInput={buildFormInput} addBlock={addBlock} deleteBlock={deleteBlock} />
                            </>
                        );
                    }

                    let index = 0;
                    for (let inputName in block.inputs) {
                        let input = block.inputs[inputName];
                        configForm.push(buildFormInput(input, `${block.id}-${index}`));
                        index++;
                    }

                    inputs.push(
                        <div style={{ width: 'calc(100% - 32px)', padding: 16, background: colors.blue.lighter.hue900 }}>
                            <div style={{ background: 'white', padding: 16 }}>
                                {configForm}
                            </div>
                        </div>
                    );
                }
            } else {
                let block = element.data.blocks[blockName];
                let configForm = [];

                configForm.push(
                    <h2 style={{ color: colors.blue.regular, width: '100%', padding: '10px 0', marginBottom: 6, fontSize: 16 }}>
                        {block.name}
                    </h2>
                );

                if (loopBlock.type === EditableTypes.MULTIPLE_BLOCKS)
                    configForm.push(<MultipleBlockConfig datas={loopBlock} buildFormInput={buildFormInput} addBlock={addBlock} deleteBlock={deleteBlock} />);

                if (block.type === EditableTypes.FINDER) {
                    configForm.push(<FinderConfig
                        key={`${EditableTypes.FINDER}-${block.id}`}
                        value={block.config}
                        categories={apiConfig.categories}
                        locales={locales}
                        attributes={attributes}
                        onChange={(config) => {
                            block.config = config;
                            updateConfig();
                        }}
                    />);
                }

                let index = 0;

                for (let inputName in block.inputs) {
                    let input = block.inputs[inputName];
                    configForm.push(buildFormInput(input, `${block.id}-${index}`));
                    index++;
                }

                inputs.push(
                    <div style={{ width: 'calc(100% - 32px)', padding: 16, background: colors.blue.lighter.hue900 }}>
                        <div style={{ background: 'white', padding: 16 }}>
                            {configForm}
                        </div>
                    </div>
                );
            }

            if (element.type === ItemTypes.TAB_PRODUCT) {
                return (
                    <>
                        { inputs}
                        <ProductSelector
                            catalog={catalog}
                            products={apiConfig.products}
                            value={element.data.products}
                            onChange={(all) => {
                                element.data.products = all;
                                updateConfig();
                            }}
                        />
                    </>
                );
            }
        }

        if (element.type === ItemTypes.FLIPBOOK_WITH_CART) {
            return (
                <>
                    { inputs}
                    <PageCreator
                        data={element.data}
                        pagesFlipbook={pagesFlipbook}
                        buildFormInput={buildFormInput}
                        products={apiConfig.products}
                        update={updateConfig}
                        catalog={catalog}
                        updateFlipbook={updateFlipbook}
                    />
                </>
            );
        }

        if (element.type === ItemTypes.OFFER_DEVIS) {
            return (
                <>
                    { inputs}
                    <OfferConfig
                        data={element.data}
                        update={updateConfig}
                        isModel={presentationModel}
                        listDeadlines={deadlines}
                        listTaxes={taxes}
                        listAgencePoles={poles}
                        listPayments={payments}
                        listTarifs={tarifs}
                    />
                </>
            );
        }

        return inputs;
    };

    const buildGeneralInputs = () => {
        let inputs = [];
        let configForm = [];

        for (let name in config.params) {
            let param = config.params[name];

            if (!param)
                continue;

            if (param.name)
                configForm.push(
                    <h2 key={param.name} style={{ color: colors.blue.regular, width: '100%', padding: '10px 0', marginBottom: 6, fontSize: 16 }}>
                        {param.name}
                    </h2>
                );

            configForm.push(buildFormInput(param, param.id));
        }

        inputs.push(
            <div style={{ width: 'calc(100% - 32px)', padding: 16, background: colors.blue.lighter.hue900 }}>
                <div style={{ width: '100%', display: 'flex' }}>
                    <FormatShapesIcon style={{ fill: colors.blue.lighter.hue300 }} />
                    <Typography className={classes.heading} style={{ color: colors.blue.lighter.hue300, paddingBottom: 12, paddingLeft: 10 }}>Paramètres</Typography>
                </div>
                <div style={{ background: 'white', padding: 16 }}>
                    {configForm}
                </div>
            </div>
        );

        return inputs;
    };

    const inputCallback = (component, block = null) => {
        setComponent(component);
        setBlock(block);
        setTab(1);
        setNoRight(false);
    };

    const changeTab = (event, newValue) => setTab(newValue);

    // COMPONENTS

    const getEmail = (element) => {
        switch (element.pageType) {
            case Pages.EMAIL_CONTACT:
                return <MinisiteContactEmail data={element.data} spread={false} />;
        }
    };

    const getComponent = (element, index, html = null) => {
        const OfferToolbar = (
            <Toolbar 
                name={element.data?.name} 
                onEdit={() => inputCallback(element.key)} 
                onDelete={() => deleteComponent(element.key)} 
                snapable={true} 
                onSnap={() => {
                    setSnapIdentifier(element.type);
                    setSnapData(element.data);
                    setSnapModalOpen(true);
                }}
                onStyle={() => {
                    setSnapIdentifier(element.type);
                    setSnapListModalOpen(true);
                    setSnapKey(element.key);
                }}
            />
        );

        switch (element.type) {
            case ItemTypes.PLACEHOLDER:
                return (<Fragment key={element.key}>
                    <div className={`builder-placeholder ${element.placeholderConfig.fullWidth ? 'fullwidth' : ''}`} style={{
                        height: element.placeholderConfig.height,
                        backgroundColor: element.placeholderConfig.bgColor,
                        marginBottom: element.placeholderConfig.noBottomMargin ? 0 : 20
                    }}>
                        <span style={{ color: element.placeholderConfig.color }}>{element.placeholderConfig.text}</span>
                    </div>
                    <TypedDroppable type={ItemTypes.ALL} id={element.key} index={index} />
                </Fragment>)
            case ItemTypes.HEADER:
                return (<Fragment key={element.key}>
                    <Header
                        id={element.key}
                        noSticky={preview}
                        data={config.params}
                        categories={apiConfig.categories}
                        spread={true}
                    />
                    <TypedDroppable type={ItemTypes.HEADER} id={element.key} index={index} />
                </Fragment>)
            case ItemTypes.SECTION_TITLE:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <SectionTitle
                            id={element.key}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            update={updateConfig}
                            spread={true}
                        >
                            <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                        </SectionTitle>
                        <TypedDroppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                    </DndProvider>
                </Fragment>)
            case ItemTypes.SLIDER:
                return (<Fragment key={element.key}>
                    <Slider
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                    </Slider>
                    <TypedDroppable type={ItemTypes.SLIDER} id={element.key} index={index} />
                </Fragment>)
            case ItemTypes.SLIDER_WITH_RIGHT_IMAGES:
                return (<Fragment key={element.key}>
                    <SliderWithRightImages
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                    </SliderWithRightImages>
                    <TypedDroppable type={ItemTypes.SLIDER_WITH_RIGHT_IMAGES} id={element.key} index={index} />
                </Fragment>)
            case ItemTypes.SLIDER_MULTIPLE:
                return (<Fragment key={element.key}>
                    <SliderMultiple
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        spread={true}
                        inputCallback={inputCallback}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                    </SliderMultiple>
                    <TypedDroppable type={ItemTypes.SLIDER_MULTIPLE} id={element.key} index={index} />
                </Fragment>)
            case ItemTypes.THREE_IMAGES_ROW:
                return (<Fragment key={element.key}>
                    <ThreeImagesRow
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        spread={true}
                        inputCallback={inputCallback}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                    </ThreeImagesRow>
                    <TypedDroppable type={ItemTypes.THREE_IMAGES_ROW} id={element.key} index={index} />
                </Fragment>)
            case ItemTypes.THREE_IMAGES_GRID:
                return (<Fragment key={element.key}>
                    <ThreeImagesGrid
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        spread={true}
                        inputCallback={inputCallback}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                    </ThreeImagesGrid>
                    <TypedDroppable type={ItemTypes.THREE_IMAGES_GRID} id={element.key} index={index} />
                </Fragment>)
            case ItemTypes.BLOC_TEXT:
                return (<Fragment key={element.key}>
                    <BlocText
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                    </BlocText>
                    <TypedDroppable type={ItemTypes.BLOC_TEXT} id={element.key} index={index} />
                </Fragment>)
            case ItemTypes.IMAGE_ROW:
                return (<Fragment key={element.key}>
                    <ImageRow
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        update={updateConfig}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                    </ImageRow>
                    <TypedDroppable type={ItemTypes.IMAGE_ROW} id={element.key} index={index} />
                </Fragment>)
            case ItemTypes.TAB_PRODUCT:
                return (<Fragment key={element.key}>
                    <TabProduct
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        update={updateConfig}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                    </TabProduct>

                    <TypedDroppable type={ItemTypes.TAB_PRODUCT} id={element.key} index={index} />
                </Fragment>)
            case ItemTypes.PAGE_LANDING:
                return (<Fragment key={element.key}>
                    <Landing
                        id={element.key}
                        preview={preview}
                        noToolbar={noToolbar}
                        data={element.data}
                        params={config.params}
                        inputCallback={inputCallback}
                        catalog={catalog}
                        products={apiConfig.products}
                        categories={apiConfig.categories}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} />
                    </Landing>
                    <TypedDroppable type={ItemTypes.PAGE_LANDING} id={`${element.key}-bottom`} index={index} />
                </Fragment>)
            case ItemTypes.PAGE_PRODUCT:
                return (<Fragment key={element.key}>
                    <Product
                        id={element.key}
                        preview={preview}
                        noToolbar={noToolbar}
                        data={element.data}
                        params={config.params}
                        inputCallback={inputCallback}
                        catalog={catalog}
                        attributes={apiConfig.attributes}
                        product={apiConfig.products[0]}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} />
                    </Product>
                    <TypedDroppable type={ItemTypes.PAGE_PRODUCT} id={`${element.key}-bottom`} index={index} />
                </Fragment>)
            case ItemTypes.PAGE_CONTACT:
                return (<Fragment key={element.key}>
                    <Contact
                        id={element.key}
                        preview={preview}
                        noToolbar={noToolbar}
                        data={element.data}
                        params={config.params}
                        inputCallback={inputCallback}
                        emailTemplate={config[Pages.EMAIL_CONTACT]?.html}
                        assetToken={assetToken}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} />
                    </Contact>
                    <TypedDroppable type={ItemTypes.PAGE_CONTACT} id={`${element.key}-bottom`} index={index} />
                </Fragment>)
            case ItemTypes.PAGE_WISHLIST:
                return (<Fragment key={element.key}>
                    <Wishlist
                        id={element.key}
                        preview={preview}
                        noToolbar={noToolbar}
                        data={element.data}
                        inputCallback={inputCallback}
                        products={apiConfig.products}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} />
                    </Wishlist>
                    <TypedDroppable type={ItemTypes.PAGE_WISHLIST} id={`${element.key}-bottom`} index={index} />
                </Fragment>)
            case ItemTypes.TEXT:
                return !element.hide && (<Fragment key={element.key}>
                    <Text
                        id={element.key}
                        preview={preview}
                        noToolbar={noToolbar}
                        data={element.data}
                        inputCallback={inputCallback}
                        onDelete={deleteComponent}
                        catalog={catalog}
                        attributes={attributes}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                    </Text>
                    <TypedDroppable type={ItemTypes.TEXT} id={`${element.key}-bottom`} index={index} />
                </Fragment>)
            case ItemTypes.TEXT_HTML:
                return !element.hide && (<Fragment key={element.key}>
                    <Text
                        id={element.key}
                        preview={preview}
                        noToolbar={noToolbar}
                        data={element.data}
                        inputCallback={inputCallback}
                        onDelete={deleteComponent}
                        catalog={catalog}
                        attributes={attributes}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                    </Text>
                    <TypedDroppable type={ItemTypes.TEXT_HTML} id={`${element.key}-bottom`} index={index} />
                </Fragment>)
            case ItemTypes.IMAGE:
                return !element.hide && (<Fragment key={element.key}>
                    <Image
                        id={element.key}
                        preview={preview}
                        noToolbar={noToolbar}
                        data={element.data}
                        inputCallback={inputCallback}
                        onDelete={deleteComponent}
                        update={updateConfig}
                        catalog={catalog}
                        attributes={attributes}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                    </Image>
                    <TypedDroppable type={ItemTypes.IMAGE} id={`${element.key}-bottom`} index={index} />
                </Fragment>)
            case ItemTypes.HEADER_GAME:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <TypedDroppable type={ItemTypes.HEADER_GAME} id={element.key - 1} index={index - 1} />
                        <HeaderGame
                            asset={asset}
                            id={element.key}
                            noSticky={preview}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                        >
                            <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                        </HeaderGame>
                        <TypedDroppable type={ItemTypes.HEADER_GAME} id={element.key} index={index} />
                    </DndProvider>
                </Fragment>)
            case ItemTypes.PRE_HEADER_GAME:
                return (<Fragment key={element.key}>
                    <PreHeaderGame
                        id={element.key}
                        noSticky={preview}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                    </PreHeaderGame>
                </Fragment>)
            case ItemTypes.FOOTER_GAME:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <TypedDroppable type={ItemTypes.FOOTER_GAME} id={element.key - 1} index={index - 1} />
                        <FooterGame
                            id={element.key}
                            noSticky={preview}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            update={updateConfig}
                        >
                            <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} />
                        </FooterGame>
                    </DndProvider>
                </Fragment>);
            case ItemTypes.HOME_GAME:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <HomeGame
                            id={element.key}
                            noSticky={preview}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            update={updateConfig}
                        >
                            <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                        </HomeGame>
                        <TypedDroppable type={ItemTypes.HOME_GAME} id={element.key} index={index} />
                    </DndProvider>
                </Fragment>)
            case ItemTypes.HOME_COMPLETE_FORM_GAME:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <TypedDroppable type={ItemTypes.HOME_COMPLETE_FORM_GAME} id={element.key - 1} index={index - 1} />
                        <HomeCompleteFormGame
                            id={element.key}
                            noSticky={preview}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            update={updateConfig}
                        >
                            <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                        </HomeCompleteFormGame>
                    </DndProvider>
                </Fragment>)
            case ItemTypes.LOGIN_GAME:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <LoginGame
                            id={element.key}
                            noSticky={preview}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            update={updateConfig}
                            toolbarSection={<Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} />}
                            toolbarBlock={<Toolbar name={element.data.blocks.middleBox.name} onEdit={() => inputCallback(element.key, 'middleBox')} />}
                        />
                    </DndProvider>
                </Fragment>)
            case ItemTypes.INDEX_GAME:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <IndexGame
                            id={element.key}
                            noSticky={preview}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            update={updateConfig}
                            toolbarSection={<Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} />}
                            toolbarBlock={<Toolbar name={element.data.blocks.middleBox.name} onEdit={() => inputCallback(element.key, 'middleBox')} />}
                        >
                        </IndexGame>
                    </DndProvider>
                </Fragment>)
            case ItemTypes.RESULTS_WIN_GAME:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <ResultsWinGame
                            id={element.key}
                            noSticky={preview}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            update={updateConfig}
                            toolbarSection={<Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} />}
                            toolbarBlock={<Toolbar name={element.data.blocks.middleBox.name} onEdit={() => inputCallback(element.key, 'middleBox')} />}
                            toolbarBlockSocial={<Toolbar name={element.data.blocks.socialBlock.name} onEdit={() => inputCallback(element.key, 'socialBlock')} />}
                        />
                        <TypedDroppable type={ItemTypes.RESULTS_WIN_GAME} id={element.key} index={index} />
                    </DndProvider>
                </Fragment>)
            case ItemTypes.RESULTS_LOOSE_GAME:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <ResultsLooseGame
                            id={element.key}
                            noSticky={preview}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            update={updateConfig}
                            toolbarSection={<Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} />}
                            toolbarBlock={<Toolbar name={element.data.blocks.middleBox.name} onEdit={() => inputCallback(element.key, 'middleBox')} />}
                            toolbarBlockSocial={<Toolbar name={element.data.blocks.socialBlock.name} onEdit={() => inputCallback(element.key, 'socialBlock')} />}
                        />
                        <TypedDroppable type={ItemTypes.RESULTS_LOOSE_GAME} id={element.key} index={index} />
                    </DndProvider>
                </Fragment>)
            case ItemTypes.ALREADYPLAYED_GAME:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <AlreadyPlayedGame
                            id={element.key}
                            noSticky={preview}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            update={updateConfig}
                            toolbarSection={<Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />}
                            toolbarBlock={<Toolbar name={element.data.blocks.middleBox.name} onEdit={() => inputCallback(element.key, 'middleBox')} />}
                        />
                    </DndProvider>
                </Fragment>)
            case ItemTypes.ENDED_GAME:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <EndedGame
                            id={element.key}
                            noSticky={preview}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            update={updateConfig}
                            toolbarSection={<Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />}
                            toolbarBlock={<Toolbar name={element.data.blocks.middleBox.name} onEdit={() => inputCallback(element.key, 'middleBox')} />}
                        />
                    </DndProvider>
                </Fragment>)
            case ItemTypes.NOT_STARTED_GAME:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <NotStartedGame
                            id={element.key}
                            noSticky={preview}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            update={updateConfig}
                            toolbarSection={<Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />}
                            toolbarBlock={<Toolbar name={element.data.blocks.middleBox.name} onEdit={() => inputCallback(element.key, 'middleBox')} />}
                        />
                    </DndProvider>
                </Fragment>)
            case ItemTypes.REDIRECT_ON_GAME:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <TypedDroppable type={ItemTypes.REDIRECT_ON_GAME} id={element.key - 1} index={index - 1} />
                        <RedirectOn
                            id={element.key}
                            noSticky={preview}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            update={updateConfig}
                        >
                            <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                        </RedirectOn>
                    </DndProvider>
                </Fragment>)
            case ItemTypes.REDIRECT_ON_GAME2:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <TypedDroppable type={ItemTypes.REDIRECT_ON_GAME2} id={element.key - 1} index={index - 1} />
                        <RedirectOn2
                            id={element.key}
                            noSticky={preview}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            update={updateConfig}
                        >
                            <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                        </RedirectOn2>
                    </DndProvider>
                </Fragment>)
            case ItemTypes.REDIRECT_ON_GAME3:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <TypedDroppable type={ItemTypes.REDIRECT_ON_GAME3} id={element.key - 1} index={index - 1} />
                        <RedirectOn3
                            id={element.key}
                            noSticky={preview}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            update={updateConfig}
                        >
                            <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                        </RedirectOn3>
                    </DndProvider>
                </Fragment>)
            case ItemTypes.REDIRECT_ON_GAME4:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <TypedDroppable type={ItemTypes.REDIRECT_ON_GAME4} id={element.key - 1} index={index - 1} />
                        <RedirectOn4
                            id={element.key}
                            noSticky={preview}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            update={updateConfig}
                        >
                            <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                        </RedirectOn4>
                    </DndProvider>
                </Fragment>)
            case ItemTypes.EXPLANATIONS_GAME:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <ExplanationsGame
                            id={element.key}
                            noSticky={preview}
                            update={updateConfig}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            toolbarSection={<Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />}
                            toolbarBlock={<Toolbar name={element.data.blocks.explanation.name} onEdit={() => inputCallback(element.key, 'explanation')} />}
                        >
                        </ExplanationsGame>
                    </DndProvider>
                </Fragment>)
            case ItemTypes.EXPLANATIONS_ACV_GAME:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <ExplanationsACVGame
                            id={element.key}
                            noSticky={preview}
                            update={updateConfig}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            toolbarSection={<Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />}
                            toolbarBlock={<Toolbar name={element.data.blocks.explanation.name} onEdit={() => inputCallback(element.key, 'explanation')} />}
                        >
                        </ExplanationsACVGame>
                    </DndProvider>
                </Fragment>)
            case ItemTypes.EXPLANATIONS_BF_GAME:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <ExplanationsBFGame
                            id={element.key}
                            noSticky={preview}
                            update={updateConfig}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            toolbarSection={<Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />}
                            toolbarBlock={<Toolbar name={element.data.blocks.explanation.name} onEdit={() => inputCallback(element.key, 'explanation')} />}
                        >
                        </ExplanationsBFGame>
                    </DndProvider>
                </Fragment>)
            case ItemTypes.EXPLANATIONS_MORE_DETAILS_GAME:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <ExplanationsMoreDetailsGame
                            id={element.key}
                            noSticky={preview}
                            update={updateConfig}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            toolbarSection={<Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />}
                            toolbarBlock={<Toolbar name={element.data.blocks.explanation.name} onEdit={() => inputCallback(element.key, 'explanation')} />}
                        >
                        </ExplanationsMoreDetailsGame>
                    </DndProvider>
                </Fragment>)
            case ItemTypes.AMAZING_GAME:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <AmazingBlocGame
                            id={element.key}
                            noSticky={preview}
                            update={updateConfig}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            toolbarSection={<Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />}
                            toolbarBlock={<Toolbar name={element.data.blocks.insideBlock.name} onEdit={() => inputCallback(element.key, 'insideBlock')} />}
                        >
                            <TypedDroppable type={ItemTypes.AMAZING_GAME} id={element.data.blocks.insideBlock.id} index={index} inside={true} />
                        </AmazingBlocGame>
                    </DndProvider>
                </Fragment>)
            case ItemTypes.BASIC_BLOC:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <BlocBasicGame
                            id={element.key}
                            noSticky={preview}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                        >
                            <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                        </ BlocBasicGame>
                        <TypedDroppable type={ItemTypes.BASIC_BLOC} id={element.key} index={index} />
                    </DndProvider>
                </Fragment>)
            case ItemTypes.BASIC_BLOC2:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <BlocBasic2Game
                            id={element.key}
                            noSticky={preview}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                        >
                            <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                        </ BlocBasic2Game>
                        <TypedDroppable type={ItemTypes.BASIC_BLOC} id={element.key} index={index} />
                    </DndProvider>
                </Fragment>)
            case ItemTypes.HEADER_BOOK:
                return (<Fragment key={element.key}>
                    <HeaderBook
                        id={element.key}
                        noSticky={preview}
                        update={updateConfig}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} />
                    </HeaderBook>
                </Fragment>)
            case ItemTypes.FOOTER_BOOK:
                return (<Fragment key={element.key}>
                    <FooterBook
                        id={element.key}
                        noSticky={preview}
                        update={updateConfig}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} />
                    </FooterBook>
                </Fragment>)
            case ItemTypes.TOP_HOMEPAGE_BOOK:
                return (<Fragment key={element.key}>
                    <TopHomepageBook
                        id={element.key}
                        noSticky={preview}
                        update={updateConfig}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} />
                    </TopHomepageBook>
                </Fragment>)
            case ItemTypes.POSTCARD_BOOK:
                return (<Fragment key={element.key}>
                    <PostCardBook
                        id={element.key}
                        noSticky={preview}
                        update={updateConfig}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                        toolbarSection={<Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} />}
                        toolbarBlock={<Toolbar name={element.data.blocks.explanation.name} onEdit={() => inputCallback(element.key, 'explanation')} />}
                    >
                    </PostCardBook>
                </Fragment>)
            case ItemTypes.FLIPBOOK:
                return (<Fragment key={element.key}>
                    <FlipBook
                        id={element.key}
                        noSticky={preview}
                        update={updateConfig}
                        preview={preview}
                        pagesFlipbook={pagesFlipbook}
                        data={element.data}
                        categories={apiConfig.categories}
                        products={apiConfig.products}
                        inputCallback={inputCallback}
                        params={config.params}
                        spread={true}
                        toolbarSection={<Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} />}
                        toolbarSubSection={<Toolbar name={element.data.blocks.subBlock.name} onEdit={() => inputCallback(element.key, 'subBlock')} />}
                        toolbarGiftBlock={<Toolbar name={element.data.blocks.giftBlock.name} onEdit={() => inputCallback(element.key, 'giftBlock')} />}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} />
                    </FlipBook>
                </Fragment>)
            case ItemTypes.FLIPBOOK_WITH_CART:
                return (<Fragment key={element.key}>
                    <FlipBook
                        id={element.key}
                        noSticky={preview}
                        update={updateConfig}
                        preview={preview}
                        pagesFlipbook={pagesFlipbook}
                        data={element.data}
                        categories={apiConfig.categories}
                        products={apiConfig.products}
                        inputCallback={inputCallback}
                        params={config.params}
                        spread={true}
                        toolbarHeader={<Toolbar name={element.data.blocks.header.name} onEdit={() => inputCallback(element.key, 'header')} />}
                        toolbarSection={<Toolbar name={element.data.blocks.flipbook.name} onEdit={() => inputCallback(element.key)} />}
                    />
                </Fragment>)
            case ItemTypes.RECAP_BLOCK_BOOK:
                return (<Fragment key={element.key}>
                    <RecapBlockBook
                        id={element.key}
                        noSticky={preview}
                        update={updateConfig}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                        redirect={'#'}
                        emailTemplate={FlipbookSuccessEmail()}
                        toolbarSection={<Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} />}
                        toolbarBlock={<Toolbar name={element.data.blocks.explanation.name} onEdit={() => inputCallback(element.key, 'explanation')} />}
                        toolbarBlockRecap={<Toolbar name={element.data.blocks.recap.name} onEdit={() => inputCallback(element.key, 'recap')} />}
                    >
                    </RecapBlockBook>
                </Fragment>)
            case ItemTypes.RESULTS_BOOK:
                return (<Fragment key={element.key}>
                    <ResultScreenBook
                        id={element.key}
                        noSticky={preview}
                        update={updateConfig}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                        toolbarSection={<Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} />}
                        toolbarBlock={<Toolbar name={element.data.blocks.result.name} onEdit={() => inputCallback(element.key, 'result')} />}
                    >
                    </ResultScreenBook>
                </Fragment>)
            case ItemTypes.BANNER_LINK_BOOK:
                return (<Fragment key={element.key}>
                    <BannerLinkBook
                        id={element.key}
                        noSticky={preview}
                        update={updateConfig}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} />
                    </BannerLinkBook>
                </Fragment>)
            case ItemTypes.GIFT_FINDER:
                return (<Fragment key={element.key}>
                    <GiftFinder
                        id={element.key}
                        update={updateConfig}
                        inputCallback={inputCallback}
                        preview={preview}
                        data={element.data}
                        categories={apiConfig.categories}
                        products={apiConfig.products}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} />
                    </GiftFinder>
                    <TypedDroppable type={ItemTypes.GIFT_FINDER} id={`${element.key}-bottom`} index={index} />
                </Fragment>)
            case ItemTypes.EMAIL_SUCCESS_BOOK:
                return (<FlipbookSuccessEmail data={element.data} id={uuidv4()} />);
            case ItemTypes.OFFER_SECTION_TITLE:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <OfferSectionTitle
                            id={element.key}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            update={updateConfig}
                            spread={true}
                        >
                            { OfferToolbar }
                        </OfferSectionTitle>
                        <TypedDroppable type={ItemTypes.OFFER_SECTION_TITLE} id={element.key} index={index} />
                    </DndProvider>
                </Fragment>)
            case ItemTypes.OFFER_FRONT_PAGE:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <OfferFrontPage
                            id={element.key}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            update={updateConfig}
                            spread={true}
                        >
                           { OfferToolbar }
                        </OfferFrontPage>
                    </DndProvider>
                    <TypedDroppable type={ItemTypes.OFFER_FRONT_PAGE} id={element.key} index={index} />
                </Fragment>)
            case ItemTypes.OFFER_ABOUT_SLIDE:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <OfferAboutSlide
                            id={element.key}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            update={updateConfig}
                            spread={true}
                            toolbarBlock={<Toolbar name={element.data.blocks.descriptionBlock.name} onEdit={() => inputCallback(element.key, 'descriptionBlock')} />}
                        >
                           { OfferToolbar }
                        </OfferAboutSlide>
                        <TypedDroppable type={ItemTypes.OFFER_ABOUT_SLIDE} id={element.key} index={index} />
                    </DndProvider>
                </Fragment>)
            case ItemTypes.OFFER_TRIPLE_BLOCK_LIST:
                return (<Fragment key={element.key}>
                    <TripleBlockList
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        update={updateConfig}
                        spread={true}
                    >
                        { OfferToolbar }
                    </TripleBlockList>
                    <TypedDroppable type={ItemTypes.OFFER_TRIPLE_BLOCK_LIST} id={element.key} index={index} />
                </Fragment>)
            case ItemTypes.OFFER_TRIPLE_BLOCK:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <TripleBlock
                            id={element.key}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            update={updateConfig}
                            spread={true}
                        >
                            { OfferToolbar }
                        </TripleBlock>
                        <TypedDroppable type={ItemTypes.OFFER_TRIPLE_BLOCK} id={element.key} index={index} />
                    </DndProvider>
                </Fragment>)
            case ItemTypes.OFFER_TRIPLE_BLOCK_TEXT:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <TripleBlockText
                            id={element.key}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            update={updateConfig}
                            spread={true}
                        >
                            { OfferToolbar }
                        </TripleBlockText>
                        <TypedDroppable type={ItemTypes.OFFER_TRIPLE_BLOCK_TEXT} id={element.key} index={index} />
                    </DndProvider>
                </Fragment>)
            case ItemTypes.OFFER_TEXT_WITH_IMAGE:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <TextWithImage
                            id={element.key}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            update={updateConfig}
                            spread={true}
                            toolbarBlock={<Toolbar name={element.data.blocks.descriptionBlock.name} onEdit={() => inputCallback(element.key, 'descriptionBlock')} />}
                        >
                            { OfferToolbar }
                        </TextWithImage>
                        <TypedDroppable type={ItemTypes.OFFER_TEXT_WITH_IMAGE} id={element.key} index={index} />
                    </DndProvider>
                </Fragment>)
            case ItemTypes.OFFER_TEXT_WITH_IMAGE_SIMPLE:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <TextWithImageSimple
                            id={element.key}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            update={updateConfig}
                            spread={true}
                        >
                            { OfferToolbar }
                        </TextWithImageSimple>
                        <TypedDroppable type={ItemTypes.OFFER_TEXT_WITH_IMAGE_SIMPLE} id={element.key} index={index} />
                    </DndProvider>
                </Fragment>)
            case ItemTypes.OFFER_DEVIS:
                return (<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <Devis
                            id={element.key}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            update={updateConfig}
                            listTaxes={taxes}
                            spread={true}
                        >
                            <Toolbar name={element.data?.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} snapable={false} />
                        </Devis>
                        <TypedDroppable type={ItemTypes.OFFER_DEVIS} id={element.key} index={index} />
                    </DndProvider>
                </Fragment>)
            default:
                return null;
        }
    };

    const addComponent = (type, index) => {
        setLocalConfig(config => {
            config[PAGE_TO_EDIT].elements.splice(index, 0, {
                type,
                key: uuidv4(),
                data: getDefaultData(type)
            });

            let newConfig = { ...config };
            return newConfig;
        });
    };

    const addComponentInside = (type, index) => {
        let item = _.clone(config[PAGE_TO_EDIT].elements[index-1]);
        let insideBlock = item.data.blocks.insideBlock?.blocks;
        if(insideBlock[type]){
            insideBlock.push(getDefaultData(type));
        }
        else{
            insideBlock.push(getDefaultData(type));
        }
        item.data.blocks.insideBlock.blocks = insideBlock;

        setLocalConfig(config => {
            config[PAGE_TO_EDIT].elements.splice((index-1), 1, item);
            let newConfig = { ...config };
            return newConfig;
        });
    };

    const deleteComponent = (id) => {
        config[PAGE_TO_EDIT].elements = config[PAGE_TO_EDIT].elements.filter(e => e.key !== id);
        updateConfig();
    };

    // DND

    const TypedDroppable = ({ id, type, index, inside = false }) => {
        return  <Droppable
                    key={`${id}-drop`}
                    addComponent={inside ? addComponentInside : addComponent }
                    accept={extractAcceptsFromType(type)}
                    index={index + 1}
                />
    };

    const extractAcceptsFromType = (type) => {
        let minisiteBase = [
            ItemTypes.SLIDER,
            ItemTypes.SLIDER_MULTIPLE,
            ItemTypes.SLIDER_WITH_RIGHT_IMAGES,
            ItemTypes.TAB_PRODUCT,
            ItemTypes.SECTION_TITLE,
            ItemTypes.BLOC_TEXT,
            ItemTypes.IMAGE_ROW,
            ItemTypes.THREE_IMAGES_GRID,
            ItemTypes.THREE_IMAGES_ROW,
            ItemTypes.IMAGE,
            ItemTypes.TEXT,
            ItemTypes.GIFT_FINDER
        ];

        let offerBase = [
            ItemTypes.OFFER_SECTION_TITLE,
            ItemTypes.OFFER_FRONT_PAGE,
            ItemTypes.OFFER_ABOUT_SLIDE,
            ItemTypes.OFFER_TRIPLE_BLOCK_LIST,
            ItemTypes.OFFER_TRIPLE_BLOCK,
            ItemTypes.OFFER_TRIPLE_BLOCK_TEXT,
            ItemTypes.OFFER_TEXT_WITH_IMAGE,
            ItemTypes.OFFER_TEXT_WITH_IMAGE_SIMPLE,
            ItemTypes.OFFER_DEVIS
        ];

        switch (type) {
            case ItemTypes.ALL: return minisiteBase.concat(offerBase);
            case ItemTypes.SLIDER:
            case ItemTypes.SLIDER_MULTIPLE:
            case ItemTypes.SLIDER_WITH_RIGHT_IMAGES:
            case ItemTypes.TAB_PRODUCT:
            case ItemTypes.SECTION_TITLE:
            case ItemTypes.BLOC_TEXT:
            case ItemTypes.IMAGE_ROW:
            case ItemTypes.THREE_IMAGES_GRID:
            case ItemTypes.THREE_IMAGES_ROW:
            case ItemTypes.IMAGE:
            case ItemTypes.TEXT:
            case ItemTypes.PAGE_CONTACT:
            case ItemTypes.PAGE_WISHLIST:
            case ItemTypes.PAGE_LANDING:
            case ItemTypes.PAGE_PRODUCT:
                return minisiteBase;
            case ItemTypes.FOOTER:
                return [];
            case ItemTypes.OFFER_SECTION_TITLE:
            case ItemTypes.OFFER_FRONT_PAGE:
            case ItemTypes.OFFER_ABOUT_SLIDE:
            case ItemTypes.OFFER_TRIPLE_BLOCK_LIST:
            case ItemTypes.OFFER_TRIPLE_BLOCK:
            case ItemTypes.OFFER_TRIPLE_BLOCK_TEXT:
            case ItemTypes.OFFER_TEXT_WITH_IMAGE:
            case ItemTypes.OFFER_TEXT_WITH_IMAGE_SIMPLE:
            case ItemTypes.OFFER_DEVIS:
                return offerBase;
            case ItemTypes.HOME_GAME:
                return [
                    ItemTypes.HOME_COMPLETE_FORM_GAME,
                    ItemTypes.EXPLANATIONS_GAME,
                    ItemTypes.EXPLANATIONS_ACV_GAME,
                    ItemTypes.EXPLANATIONS_BF_GAME,
                    ItemTypes.EXPLANATIONS_MORE_DETAILS_GAME,
                    ItemTypes.AMAZING_GAME,
                    ItemTypes.PRE_HEADER_GAME,
                ];
            case ItemTypes.HOME_COMPLETE_FORM_GAME:
                return [
                    ItemTypes.PRE_HEADER_GAME
                ];
            case ItemTypes.PRE_HEADER_GAME:
                return [
                    ItemTypes.HOME_COMPLETE_FORM_GAME
                ];
            case ItemTypes.HEADER_GAME:
                return [
                    ItemTypes.PRE_HEADER_GAME,
                ];
            case ItemTypes.AMAZING_GAME:
                return [
                    ItemTypes.TITLE_GAME,
                    ItemTypes.PARAGRAPH_GAME,
                    ItemTypes.RULES_AMAZING_GAME,
                    ItemTypes.RULES_INLINE_FLEX_AMAZING_GAME,
                    ItemTypes.DOTATIONS_ONE_IMAGE,
                    ItemTypes.BUTTON_GAME,
                ];
            case ItemTypes.FOOTER_GAME:
                return [
                    ItemTypes.HOME_COMPLETE_FORM_GAME,
                    ItemTypes.HOME_GAME,
                    ItemTypes.BASIC_BLOC,
                    ItemTypes.BASIC_BLOC2,
                    ItemTypes.FOOTER_GAME,
                    ItemTypes.REDIRECT_ON_GAME,
                    ItemTypes.REDIRECT_ON_GAME2,
                    ItemTypes.REDIRECT_ON_GAME3,
                    ItemTypes.REDIRECT_ON_GAME4,
                    ItemTypes.AMAZING_GAME,
                ];
            case ItemTypes.BASIC_BLOC:
                return [
                    ItemTypes.BASIC_BLOC,
                    ItemTypes.BASIC_BLOC2,
                    ItemTypes.EXPLANATIONS_GAME,
                    ItemTypes.EXPLANATIONS_ACV_GAME,
                    ItemTypes.EXPLANATIONS_BF_GAME,
                    ItemTypes.EXPLANATIONS_MORE_DETAILS_GAME,
                    ItemTypes.AMAZING_GAME,
                ];
            case ItemTypes.BASIC_BLOC2:
                return [
                    ItemTypes.BASIC_BLOC,
                    ItemTypes.BASIC_BLOC2,
                    ItemTypes.EXPLANATIONS_GAME,
                    ItemTypes.EXPLANATIONS_ACV_GAME,
                    ItemTypes.EXPLANATIONS_BF_GAME,
                    ItemTypes.EXPLANATIONS_MORE_DETAILS_GAME,
                    ItemTypes.AMAZING_GAME,
                ];
            case ItemTypes.REDIRECT_ON_GAME:
                return [
                    ItemTypes.BASIC_BLOC,
                    ItemTypes.BASIC_BLOC2,
                ];
            case ItemTypes.REDIRECT_ON_GAME2:
                return [
                    ItemTypes.BASIC_BLOC,
                    ItemTypes.BASIC_BLOC2,
                ];
            case ItemTypes.REDIRECT_ON_GAME3:
                return [
                    ItemTypes.BASIC_BLOC,
                    ItemTypes.BASIC_BLOC2,
                ];
            case ItemTypes.REDIRECT_ON_GAME4:
                return [
                    ItemTypes.BASIC_BLOC,
                    ItemTypes.BASIC_BLOC2,
                ];
            case ItemTypes.EXPLANATIONS_GAME:
                return [
                    ItemTypes.EXPLANATIONS_GAME,
                    ItemTypes.EXPLANATIONS_ACV_GAME,
                    ItemTypes.EXPLANATIONS_BF_GAME
                ];
            case ItemTypes.RESULTS_WIN_GAME:
                return [
                    ItemTypes.EXPLANATIONS_ACV_GAME,
                    ItemTypes.EXPLANATIONS_BF_GAME,
                    ItemTypes.EXPLANATIONS_MORE_DETAILS_GAME,
                    ItemTypes.AMAZING_GAME,
                ];
            case ItemTypes.RESULTS_LOOSE_GAME:
                return [
                    ItemTypes.EXPLANATIONS_ACV_GAME,
                    ItemTypes.EXPLANATIONS_BF_GAME,
                    ItemTypes.EXPLANATIONS_MORE_DETAILS_GAME,
                    ItemTypes.AMAZING_GAME,
                ];
            default:
                return [
                    ItemTypes.SECTION_TITLE,
                    ItemTypes.IMAGE_ROW,
                    ItemTypes.SLIDER,
                    ItemTypes.TAB_PRODUCT
                ];
        }
    };

    // MULTIPLE BLOCKS
    
    const addBlock = (datas) => {
        switch (datas.subtype) {
            case ItemTypes.SLIDER:
                datas.config.push(CopyWithJSON(getDefaultSlide()));
                updateConfig();
                break;
            case ItemTypes.SLIDER_WITH_RIGHT_IMAGES:
                datas.config.push(CopyWithJSON(getDefaultSimpleSlide()));
                updateConfig();
                break;
            case ItemTypes.SLIDER_MULTIPLE:
                datas.config.push(CopyWithJSON(getDefaultSimpleSlide("https://via.placeholder.com/230x85")));
                updateConfig();
                break;
            case ItemTypes.DOTATION:
                datas.config.push(CopyWithJSON(getDefaultDotations()));
                updateConfig();
                break;
            case ItemTypes.DOTATION_MORE_DETAILS:
                datas.config.push(CopyWithJSON(getDefaultDotationsMoreDetails()));
                updateConfig();
                break;
            case ItemTypes.DOTATION_BF:
                datas.config.push(CopyWithJSON(getDefaultDotationsBF()));
                updateConfig();
                break;
            case ItemTypes.RULE:
                datas.config.push(CopyWithJSON(getDefaultRules()));
                updateConfig();
            break;
            case ItemTypes.RULES_INLINE_FLEX_AMAZING_GAME:
                datas.config.push(CopyWithJSON(getDefaultRulesInlineFlex()));
                updateConfig();
            break;
            case ItemTypes.RULE_BF:
                datas.config.push(CopyWithJSON(getDefaultRulesBF()));
                updateConfig();
                break;
            case ItemTypes.SOCIAL:
                datas.config.push(CopyWithJSON(getDefaultSocial()));
                updateConfig();
                break;
            case ItemTypes.OFFER_STATS:
                datas.config.push(CopyWithJSON(getOfferStats()));
                updateConfig();
                break;
            case ItemTypes.OFFER_TRIPLE_BLOCK_LIST_POLE:
                datas.config.push(CopyWithJSON(getOfferPole()));
                updateConfig();
                break;
            case ItemTypes.OFFER_TRIPLE_BLOCK_LIST_LEADER:
                datas.config.push(CopyWithJSON(getOfferLeader()));
                updateConfig();
                break;
            case ItemTypes.OFFER_TRIPLE_BLOCK_INFORMATIONS:
                datas.config.push(CopyWithJSON(getOfferInformations()));
                updateConfig();
                break;
            case ItemTypes.OFFER_TRIPLE_BLOCK_LIST_TEXT:
                datas.config.push(CopyWithJSON(getOfferListText()));
                updateConfig();
                break;
            default: return null;
        }
    };

    const deleteBlock = (datas, i) => {
        datas.config.splice(i, 1);
        updateConfig();
    };

    // SPECIFIC
    const updateFlipbook = (newValues) => {
        setPagesFlipbook(newValues)
    };

    // SAVING

    const save = async () => {
        snack(ALERT_INFO, 'Sauvegarde...');
        setPreview(true);

        if (presentation) {
            let elements = config[PAGE_TO_EDIT]?.elements;
            let allModels = [];

            for (let element of elements) {
                if (element.type === ItemTypes.OFFER_DEVIS) {
                    if (!element.offer) {
                        if (presentationModel) {
                            // CREATE OFFER MODEL

                            const ADD_MODELE_RESULT = await client.mutate({
                                mutation: ADD_MODELE,
                                variables: {
                                    'name': element.data.inputs?.offerName?.value,
                                    'description': element.data.inputs?.offerDescription?.value,
                                    'recurring': element.data.inputs?.offerRecurring?.value,
                                    'recurringStartDate': element.data.inputs?.offerRecurring?.conditionalInputs?.offerRecurringStartDate?.value,
                                    'recurringInvoiceDay': element.data.inputs?.offerRecurring?.conditionalInputs?.offerRecurringInvoiceDay?.value,
                                    'recurringDelay': element.data.inputs?.offerRecurring?.conditionalInputs?.offerRecurringDelay?.value
                                }
                            });

                            element.offer = ADD_MODELE_RESULT.data.createModel.model.id;

                            allModels.push(element.offer);

                            await savePhases(element.offer, element.data.phases, client, false);
                        } else {
                            // CREATE OFFER

                            const ADD_OFFER_RESULT = await client.mutate({
                                mutation: ADD_OFFER,
                                variables: {
                                    'title': element.data.inputs?.offerName?.value,
                                    'description': element.data.inputs?.offerDescription?.value,
                                    'poNumber': element.data.inputs?.offerNumberPO?.value,
                                    'status': 'processing',
                                    'createdAt': moment().format('YYYY-MM-DD'),
                                    'validityDate': element.data.inputs?.offerValidityDate?.value,
                                    'recurring': element.data.inputs?.offerRecurring?.value,
                                    'recurringStartDate': element.data.inputs?.offerRecurring?.conditionalInputs?.offerRecurringStartDate?.value,
                                    'recurringInvoiceDay': element.data.inputs?.offerRecurring?.conditionalInputs?.offerRecurringInvoiceDay?.value,
                                    'recurringDelay': element.data.inputs?.offerRecurring?.conditionalInputs?.offerRecurringDelay?.value
                                }
                            });

                            element.offer = ADD_OFFER_RESULT.data.createOffer.offer.id;

                            const ADD_FOOTER_OFFER_RESULT = await client.mutate({
                                mutation: ADD_FOOTER_OFFER,
                                variables: {
                                    'offer': element.offer,
                                    'discountFixed': element.data.footer?.discountFixed,
                                    'discountPercent': element.data.footer?.discountPercent,
                                    'advancePayment': element.data.footer?.advancePayment,
                                    'paymentTerm': element.data.footer?.paymentTerm,
                                    'paymentDeadline': element.data.footer?.paymentDeadline,
                                    'comment': element.data.footer?.comment
                                }
                            });

                            element.data.footer.id = ADD_FOOTER_OFFER_RESULT.data.createOfferFooter.offerFooter.id;
            
                            let { totals } = getTotals(element.data.phases, taxes);
            
                            for (let tax of taxes) {
                                let amount = totals.find(e => e.tax.id === tax.node.id);
            
                                await client.mutate({
                                    mutation: ADD_OFFER_FOOTER_TAX,
                                    variables: {
                                        'offerFooter': ADD_FOOTER_OFFER_RESULT.data.createOfferFooter.offerFooter.id,
                                        'tax': tax.node.id,
                                        'amount': amount.total
                                    }
                                });
                            }

                            allModels.push(element.offer);

                            await savePhases(element.offer, element.data.phases, client, true);
                        }
                    } else {
                        if (presentationModel) {
                            // UPDATE OFFER MODEL

                            await client.mutate({
                                mutation: UPDATE_MODELE,
                                variables: {
                                    'id': element.offer,
                                    'name': element.data.inputs?.offerName?.value,
                                    'description': element.data.inputs?.offerDescription?.value,
                                    'recurring': element.data.inputs?.offerRecurring?.value,
                                    'recurringStartDate': element.data.inputs?.offerRecurring?.conditionalInputs?.offerRecurringStartDate?.value,
                                    'recurringInvoiceDay': element.data.inputs?.offerRecurring?.conditionalInputs?.offerRecurringInvoiceDay?.value,
                                    'recurringDelay': element.data.inputs?.offerRecurring?.conditionalInputs?.offerRecurringDelay.value
                                }
                            });
                        } else {
                            // UPDATE OFFER

                            await client.mutate({
                                mutation: UPDATE_OFFER,
                                variables: {
                                    'id': element.offer,
                                    'title': element.data.inputs?.offerName?.value,
                                    'description': element.data.inputs?.offerDescription?.value,
                                    'poNumber': element.data.inputs?.offerNumberPO?.value,
                                    'validityDate': element.data.inputs?.offerValidityDate?.value,
                                    'status': 'processing',
                                    'recurring': element.data.inputs?.offerRecurring?.value,
                                    'recurringStartDate': element.data.inputs?.offerRecurring?.conditionalInputs?.offerRecurringStartDate?.value,
                                    'recurringInvoiceDay': element.data.inputs?.offerRecurring?.conditionalInputs?.offerRecurringInvoiceDay?.value,
                                    'recurringDelay': element.data.inputs?.offerRecurring?.conditionalInputs?.offerRecurringDelay.value
                                }
                            });

                            await client.mutate({
                                mutation: UPDATE_FOOTER_OFFER,
                                variables: {
                                    'id': element.data.footer?.id,
                                    'discountFixed': element.data.footer?.discountFixed,
                                    'discountPercent': element.data.footer?.discountPercent,
                                    'advancePayment': element.data.footer?.advancePayment,
                                    'paymentTerm': element.data.footer?.paymentTerm,
                                    'paymentDeadline': element.data.footer?.paymentDeadline,
                                    'comment': element.data.footer?.comment
                                }
                            });
            
                            let { totals } = getTotals(element.data.phases, taxes);
            
                            let existingTaxes = element.data.footer?.taxes ?? [];
                            let allTaxes = [];
            
                            for (let tax of taxes) {
                                let amount  = totals.find(e => e.tax.id === tax.node.id);
                                let found   = existingTaxes.find(e => e === tax.node.id);
            
                                if (found) {
                                    // Tax exists

                                    await client.mutate({
                                        mutation: UPDATE_OFFER_FOOTER_TAX,
                                        variables: {
                                            'id': found,
                                            'offerFooter': element.data.footer?.id,
                                            'tax': tax.node.id,
                                            'amount': amount.total
                                        }
                                    });

                                    allTaxes.push(found);
                                } else {
                                    // Tax doesn't exist    

                                    const ADD_OFFER_FOOTER_TAX_RESULT = await client.mutate({
                                        mutation: ADD_OFFER_FOOTER_TAX,
                                        variables: {
                                            'offerFooter': element.data.footer?.id,
                                            'tax': tax.node.id,
                                            'amount': amount.total
                                        }
                                    });  

                                    allTaxes.push(ADD_OFFER_FOOTER_TAX_RESULT.data.createOfferFooterTax.offerFooterTax.id);
                                }
                            }

                            element.data.footer.taxes = allTaxes;
                        }

                        allModels.push(element.offer);

                        await savePhases(element.offer, element.data.phases, client, !presentationModel);
                    }
                }
            }

            updateConfig();

            let toDelete = presentationModel
                ? offer.models.edges.filter(e => !allModels.find(f => f === e.node.id))
                : offer.offers.edges.filter(e => !allModels.find(f => f === e.node.id));

            for (let model of toDelete) {
                try {
                    await client.mutate({
                        mutation: presentationModel ? DELETE_MODELE : DELETE_OFFER,
                        variables: { id: model.node.id }
                    });
                } catch (e) {
                    console.log(e);
                }
            }
            console.log('offer builder', offer)
            if (presentationModel) {
                await client.mutate({
                    mutation: UPDATE_MODEL_PRESENTATION,
                    variables: {
                        id: offer.id,
                        htmlContent: JSON.stringify(config[PAGE_TO_EDIT]),
                        models: allModels
                    },
                });
            } else {
                await client.mutate({
                    mutation: UPDATE_PRESENTATION,
                    variables: {
                        id: offer.id,
                        htmlContent: JSON.stringify(config[PAGE_TO_EDIT]),
                        offers: allModels,
                        status: offer.status === "processing" ? 'ready' : offer.status
                    },
                });
            }

            snack(ALERT_SUCCESS, 'Configuration sauvegardée !');
        } else if (asset.assetType.identifier === "minisite" || asset.assetType.identifier === "newsletter" || asset.assetType.identifier === "flipbook") {
            let page = asset.assetMinisitePages.edges.find(e => e.node.assetMinisitePageType.identifier === PAGE_TO_EDIT);

            if (config[PAGE_TO_EDIT].isEmail) {
                let markup = ReactDOMServer.renderToStaticMarkup(getEmail(config[PAGE_TO_EDIT]));
                config[PAGE_TO_EDIT].html = EmailBase(markup);
            }

            await client.mutate({
                mutation: UPDATE_MINISITE_PAGE,
                variables: {
                    id: page.node.id,
                    content: JSON.stringify(config[PAGE_TO_EDIT]),
                    status: 'Ready'
                },
            });

            await client.mutate({
                mutation: UPDATE_ASSET,
                variables: {
                    id: asset.id,
                    content: JSON.stringify(config.params)
                },
            });

            snack(ALERT_SUCCESS, 'Configuration sauvegardée !');
        } else if (asset.assetType.identifier === "jeu") {
            
            let page = asset.assetGamePages.edges.find(e => e.node.assetGamePageType.identifier === PAGE_TO_EDIT);

            let html = `<div id="builder-template-scope-game" style="${config.params?.bg?.value ? (typeof(config.params.bg?.value) === 'string' ? `background-image: url(${config.params.bg?.value})` : `background-image: url(${process.env.REACT_APP_MEDIAS}/${config.params.bg.value.filePath})` ) : null }">`;
            for (let element of config[PAGE_TO_EDIT].elements) {
                let markup = ReactDOMServer.renderToStaticMarkup(getComponent(element));
                html += markup;
            }
            html += '</div>'

            await client.mutate({
                mutation: UPDATE_GAME_PAGE,
                variables: {
                    id: page.node.id,
                    content: JSON.stringify(config[PAGE_TO_EDIT]),
                    htmlContent: html,
                    status: 'Ready'
                },
            });

            if(config.params){
                await client.mutate({
                    mutation: UPDATE_ASSET,
                    variables: {
                        id: asset.id,
                        content: JSON.stringify(config.params)
                    },
                });
            }

            snack(ALERT_SUCCESS, 'Configuration sauvegardée !');
        } else if (asset.assetType.identifier === "gift_finder") {
            await client.mutate({
                mutation: UPDATE_ASSET,
                variables: {
                    id: asset.id,
                    content: JSON.stringify(config[PAGE_TO_EDIT])
                },
            });

            snack(ALERT_SUCCESS, 'Configuration sauvegardée !');
        }
    };

    const reinit = async () => {
        if (presentation) {
            if (presentationModel) {
                await client.mutate({
                    mutation: UPDATE_MODEL_PRESENTATION,
                    variables: {
                        id: offer.id,
                        htmlContent: null
                    },
                });
            } else {
                await client.mutate({
                    mutation: UPDATE_PRESENTATION,
                    variables: {
                        id: offer.id,
                        htmlContent: null
                    },
                });
            }
        } else if (asset.assetType.identifier === "minisite" || asset.assetType.identifier === "newsletter" || asset.assetType.identifier === "flipbook") {
            let page = asset.assetMinisitePages.edges.find(e => e.node.assetMinisitePageType.identifier === PAGE_TO_EDIT);

            await client.mutate({
                mutation: UPDATE_MINISITE_PAGE,
                variables: {
                    id: page.node.id,
                    content: null,
                    status: '0'
                },
            });

            await client.mutate({
                mutation: UPDATE_ASSET,
                variables: {
                    id: asset.id,
                    content: null
                },
            });

            config[PAGE_TO_EDIT] = getDefaultPageConfig(PAGE_TO_EDIT, asset.assetType.identifier);

            snack(ALERT_SUCCESS, 'Configuration réinitialisée !');
        } else if (asset.assetType.identifier === "jeu") {
            let page = asset.assetGamePages.edges.find(e => e.node.assetGamePageType.identifier === PAGE_TO_EDIT);
            let html = '';

            for (let element of config[PAGE_TO_EDIT].elements) {
                let markup = ReactDOMServer.renderToStaticMarkup(getComponent(element));
                html += markup;
            }

            await client.mutate({
                mutation: UPDATE_GAME_PAGE,
                variables: {
                    id: page.node.id,
                    content: null,
                    status: '0'
                },
            });

            config[PAGE_TO_EDIT] = getDefaultPageConfig(PAGE_TO_EDIT, asset.assetType.identifier);

            snack(ALERT_SUCCESS, 'Configuration réinitialisée !');
        } else if (asset.assetType.identifier === "gift_finder") {
            await client.mutate({
                mutation: UPDATE_ASSET,
                variables: {
                    id: asset.id,
                    content: null
                },
            });

            snack(ALERT_SUCCESS, 'Configuration réinitialisée !');
        }
    };

    // RENDERING

    const getTemplate = (page) => {
        if (assetType === "flipbook" || assetType === "newsletter") {
            switch (page) {
                case Pages.HOMEPAGE_BOOK:
                case Pages.FLIPBOOK:
                case Pages.REGISTER_BOOK:
                case Pages.REGISTER_SUCCESS_BOOK:
                    return <PagesBook
                        config={config[page]}
                        params={config.params}
                        getComponent={getComponent}
                        preview={preview}
                        catalog={catalog}
                        attributes={attributes}
                    />;
                case Pages.EMAIL_SUCCESS_BOOK:
                    // let html = config[page].html;
                    let element = {
                        type: config[page].elements[0].type,
                        data: config[page].elements[0].data
                    };

                    return (
                        <div style={{ height: '100%' }}>
                            {
                                !preview ? (
                                    <Toolbar name={config[page].elements[0].data.name} noBorder={true} onEdit={() => inputCallback(Pages.EMAIL_SUCCESS_BOOK)} />
                                ) : null
                            }
                            {getComponent(element, 0)}
                        </div>
                    );
                default: return null;
            }
        } else {
            switch (page) {
                case Pages.HOME:
                case Pages.LANDING:
                case Pages.PRODUCT_DETAILS:
                case Pages.CONTACT:
                case Pages.WISHLIST:
                    return <MinisiteTemplate
                        config={config[page]}
                        params={config.params}
                        getComponent={getComponent}
                        preview={preview}
                        data={apiConfig}
                        attributes={attributes}
                        Droppable={TypedDroppable}
                        spread={true}
                    />;
                case Pages.EMAIL_CONTACT:
                case Pages.EMAIL_WISHLIST:
                    return (
                        <EmailTemplate name={config[page].data.name} preview={preview} onEdit={() => inputCallback(ItemTypes.EMAIL_CONTACT)}>
                            <MinisiteContactEmail data={config[page].data} spread={true} key={ItemTypes.EMAIL_CONTACT} id={uuidv4()} />
                        </EmailTemplate>
                    );
                case Pages.MAGENTO:
                    return <MagentoTemplate
                        config={config[page]}
                        params={config.params}
                        getComponent={getComponent}
                        preview={preview}
                        data={apiConfig}
                        attributes={attributes}
                        Droppable={TypedDroppable}
                        spread={true}
                    />;
                case Pages.FACEBOOK:
                    return <Facebook
                        config={config[page]}
                        params={config.params}
                        getComponent={getComponent}
                        preview={preview}
                        catalog={catalog}
                        attributes={attributes}
                    />;
                case Pages.WORDPRESS:
                    return <Wordpress
                        config={config[page]}
                        params={config.params}
                        getComponent={getComponent}
                        preview={preview}
                        catalog={catalog}
                        attributes={attributes}
                    />;
                case Pages.GIFT_FINDER:
                    return <GiftFinderTemplate
                        config={config[page]}
                        params={config.params}
                        getComponent={getComponent}
                        preview={preview}
                        data={apiConfig}
                        attributes={attributes}
                        spread={true}
                    />;
                case Pages.HOMEPAGE_GAME:
                    return <PagesGame
                        config={config[page]}
                        params={config.params}
                        getComponent={getComponent}
                        preview={preview}
                        catalog={catalog}
                        attributes={attributes}
                        noRight={noRight}
                    />;
                case Pages.LOGIN_GAME:
                    return <PagesGame
                        config={config[page]}
                        params={config.params}
                        getComponent={getComponent}
                        preview={preview}
                        catalog={catalog}
                        attributes={attributes}
                        noRight={noRight}
                    />;
                case Pages.INDEX_GAME:
                    return <PagesGame
                        config={config[page]}
                        params={config.params}
                        getComponent={getComponent}
                        preview={preview}
                        catalog={catalog}
                        attributes={attributes}
                        noRight={noRight}
                    />;
                case Pages.RESULTS_WIN_GAME:
                    return <PagesGame
                        config={config[page]}
                        params={config.params}
                        getComponent={getComponent}
                        preview={preview}
                        catalog={catalog}
                        attributes={attributes}
                        noRight={noRight}
                    />;
                case Pages.RESULTS_LOOSE_GAME:
                    return <PagesGame
                        config={config[page]}
                        params={config.params}
                        getComponent={getComponent}
                        preview={preview}
                        catalog={catalog}
                        attributes={attributes}
                        noRight={noRight}
                    />;
                case Pages.ALREADYPLAYED_GAME:
                    return <PagesGame
                        config={config[page]}
                        params={config.params}
                        getComponent={getComponent}
                        preview={preview}
                        catalog={catalog}
                        attributes={attributes}
                        noRight={noRight}
                    />;
                case Pages.ENDED_GAME:
                    return <PagesGame
                        config={config[page]}
                        params={config.params}
                        getComponent={getComponent}
                        preview={preview}
                        catalog={catalog}
                        attributes={attributes}
                        noRight={noRight}
                    />;
                case Pages.NOT_STARTED_GAME:
                    return <PagesGame
                        config={config[page]}
                        params={config.params}
                        getComponent={getComponent}
                        preview={preview}
                        catalog={catalog}
                        attributes={attributes}
                        noRight={noRight}
                    />;
                case Pages.PRESENTATION:
                    return <PresentationTemplate
                        config={config[page]}
                        params={config.params}
                        getComponent={getComponent}
                        preview={preview}
                        Droppable={TypedDroppable}
                        spread={true}
                    />;
                default: return null;
            }
        }
    };

    const getPageName = (identifier) => {
        switch (identifier) {
            case 'home': return "Page d'accueil";
            case 'landing': return "Landing produits";
            case 'product_details': return "Page produit";
            case 'contact': return "Page de contact";
            case 'wishlist': return "Wishlist";
            case 'home_game': return "Page d'accueil du jeu";
            case 'login_game': return "Identification du joueur";
            case 'index_game': return "Index du jeu";
            case 'game_game': return "Page de jeu";
            case 'results_win_game': return "Résultat - Gagné";
            case 'results_loose_game': return "Résultat - Perdu";
            case 'alreadyplayed_game': return "Déjà joué";
            case 'ended_game': return "Jeu terminé";
            case 'not_started_game': return "Jeu non commencé ";
            case 'flipbook': return "Flipbook";
            case 'register': return "Demande enregistement";
            case 'register_success': return "Enregistrement réussi";
            case 'email_register': return "Email de succès";
            case "email_contact": return "Email contact";
            case "email_wishlist": return "Email wishlist";
            case Pages.PRESENTATION: return "Présentation";
        }
    };

    const getImageType = (asset) => {
        const images = require.context('../assets/images', true);
        let image;
        try {
            image = images('./' + asset);
        } catch (e) {
            image = imgNotFound;
        }
        return image;
    }

    const changePage = (identifier) => {
        setTab(0);
        setComponent(null);
        setBlock(null);
        setOpenChangerPage(false);

        let page = (asset.assetType.identifier === "minisite" || asset.assetType.identifier === "newsletter" || asset.assetType.identifier === "flipbook")
            ? asset.assetMinisitePages.edges.find(e => e.node.assetMinisitePageType.identifier === identifier)
            : asset.assetType.identifier === "jeu"
                ? asset.assetGamePages.edges.find(e => e.node.assetGamePageType.identifier === identifier)
                : null;

        config[identifier] = page.node.content
            ? JSON.parse(page.node.content)
            : getDefaultPageConfig(identifier, asset.assetType.identifier);

        setLocalConfig(config);

        history.push({
            pathname: ROUTE_BUILDER.replace(":assetId", assetId).replace(":pageId", identifier),
            state: { page: identifier }
        });
    }

    let pages = [];
    let pagesImg = 0;

    if (asset?.assetMinisitePages.edges.length > 0) {
        pages = asset?.assetMinisitePages.edges.map((page, i) => ({
            value: page.node.assetMinisitePageType.identifier,
            label: getPageName(page.node.assetMinisitePageType.identifier),
            image: getImageType(page.node.assetMinisitePageType.image),
        })) ?? [];

        for (let page of pages) {
            if (page.image !== imgNotFound) {
                pagesImg++;
            }
        }
    }

    else if (asset?.assetGamePages.edges.length > 0) {
        pages = asset?.assetGamePages.edges.map((page, i) => ({
            value: page.node.assetGamePageType.identifier,
            label: getPageName(page.node.assetGamePageType.identifier),
            image: getImageType(page.node.assetGamePageType.image),
        })) ?? [];

        for (let page of pages) {
            if (page.image !== imgNotFound) {
                pagesImg++;
            }
        }
    }

    let tabStyle = {
        maxWidth: 100,
        minWidth: 100
    };

    return (
        <div className="builder" id="builder">
            <div className="left" id="preview" style={{ height, width: (onlyLeft || noRight) ? "100%" : presentation ? "70%" : "80%" }}>
                <div className="top-bar" style={{ display: onlyLeft ? "none" : "flex", width: (onlyLeft || noRight) ? "calc(100% - 85px)" : "calc(100% - 15px)" }}>
                    <img src={logo} alt="logo-spread" />
                    {
                        pages.length ? (
                            <>
                                {
                                    pagesImg === pages.length ?
                                        (
                                            <div className="changerPage">
                                                <div className="selecter" onClick={() => setOpenChangerPage(!openChangerPage)}>
                                                    <p>{getPageName(PAGE_TO_EDIT)}</p>
                                                    {
                                                        openChangerPage ? (
                                                            <KeyboardArrowUpIcon />
                                                        ) : (
                                                            <KeyboardArrowDownIcon />
                                                        )
                                                    }
                                                </div>
                                                {
                                                    openChangerPage ? (
                                                        <div className="dynamicChangerPage">
                                                            <CloseIcon style={{ fill: 'white' }} onClick={() => setOpenChangerPage(!openChangerPage)} />
                                                            {
                                                                pages && asset ?
                                                                    (
                                                                        pages.map((page, index) => {
                                                                            return (
                                                                                <div onClick={() => changePage(page.value)}>
                                                                                    <img
                                                                                        style={{ width: (pages.length % 3 === 0 || (pages.length < 6 && pages.length !== 4)) ? '26vw' : '25vw', height: pages.length < 9 ? '40vh' : '30vh' }}
                                                                                        src={typeof page.image === "string" ? page.image : page.image?.default}
                                                                                    />
                                                                                    <p>{page.label}</p>
                                                                                </div>

                                                                            )
                                                                        })
                                                                    ) : null
                                                            }
                                                        </div>
                                                    ) : null
                                                }
                                            </div>
                                        ) :
                                        (
                                            <SelectCustom
                                                closeMenuOnSelect={true}
                                                hideSelectedOptions={false}
                                                defaultValue={pages.find(e => e.value === PAGE_TO_EDIT)}
                                                options={pages}
                                                isMulti={false}
                                                isSearchable={false}
                                                placeholder="Changer de page..."
                                                noOptionsMessage={() => "Aucun résultat"}
                                                components={{ ...animatedComponents }}
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        width: 220,
                                                        background: 'transparent',
                                                        color: 'white',
                                                    }),
                                                    menu: (provided, state) => ({
                                                        ...provided,
                                                        zIndex: 150,
                                                        padding: 0
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        color: 'white',
                                                    })
                                                }}
                                                onChange={(item) => changePage(item.value)}
                                            />
                                        )
                                }
                            </>
                        ) : null
                    }
                    <Button className="builder-save-button" onClick={reinit} bgcolor={'#AAAAAA'} bgcolorhover={'#888888'}><RefreshIcon /> Réinitialiser</Button>
                    <Fab color="primary" size="small" className="builder-preview-button" onClick={() => setPreview(!preview)} style={{ marginLeft: 10, marginRight: 5 }}>{preview ? <Visibility /> : <VisibilityOff />}</Fab>
                    
                    { (presentation || presentationModel) && (
                        <CopyToClipboard text={`https://presentation.sinfin.fr/${offer?.alias}`} onCopy={exportLink}>
                            <Button className="builder-link-button" colortext="white" bgcolor="transparent" style={{ position: 'absolute', right: 190, top: -5 }}><LinkIcon /></Button> 
                        </CopyToClipboard>
                    )}

                    <Button className="builder-save-button" onClick={save} colortext="white" bgcolor="transparent" style={{ position: 'absolute', right: 30, top: -5 }}><SaveIcon /> Sauvegarder</Button>
                    <Fab color="primary" size="small" className="builder-expand-button" onClick={() => setNoRight(!noRight)}><ArrowForwardIosIcon style={{ transform: noRight ? 'rotate(180deg)' : '' }} /></Fab>
                    {(onlyLeft || noRight) ? <MenuIcon style={{ position: 'absolute', right: -50 }} /> : null}
                </div>

                <div className="content" style={{ top: onlyLeft ? 0 : 46, height: (onlyLeft || noRight) ? "100%" : "calc(100% - 46px)" }}>
                    <Context.Provider value={{ products, update: (products) => updateProducts(products) }}>
                        {ready && (presentation ? offer : catalog) ? getTemplate(PAGE_TO_EDIT) : <p className="builder-loading">Chargement...</p>}
                    </Context.Provider>
                </div>
            </div>

            <div className="right" style={{ height, display: (onlyLeft || noRight) ? "none" : "flex", width: presentation ? "30%" : "20%" }}>
                <AppBar position="static" style={{ zIndex: 0, background: '#F7F7F7', boxShadow: 'inherit' }}>
                    <Tabs value={currentTab} onChange={changeTab} centered variant="fullWidth">
                        <Tab style={tabStyle} icon={<BootstrapTooltip title="Composants" style={{ fontSize: 12 }}><ControlPoint style={{ fill: colors.blue.regular }} /></BootstrapTooltip>} id={`simple-tab-${0}`} aria-controls={`simple-tabpanel-${0}`} />
                        <Tab style={tabStyle} icon={<BootstrapTooltip title="Apparence" style={{ fontSize: 12 }}><StyleIcon style={{ fill: colors.blue.regular }} /></BootstrapTooltip>} id={`simple-tab-${1}`} aria-controls={`simple-tabpanel-${1}`} />
                        {
                            assetType === "minisite" || assetType === "newsletter" || assetType === "flipbook" || assetType === "jeu" ?
                                (
                                    <Tab style={tabStyle} icon={<BootstrapTooltip title="Paramètres" style={{ fontSize: 12 }}><TuneIcon style={{ fill: colors.blue.regular }} /></BootstrapTooltip>} id={`simple-tab-${2}`} aria-controls={`simple-tabpanel-${2}`} />
                                ) : null
                        }
                    </Tabs>
                </AppBar>

                <div className="right-content">
                    <div
                        id={`simple-tabpanel-${0}`}
                        value={currentTab}
                        index={0}
                        style={{ display: currentTab === 0 ? 'block' : 'none', background: colors.white, padding: 20 }}
                    >
                        <h2 style={{ color: colors.blue.regular, width: '100%', padding: '10px 0', marginBottom: 6, fontSize: 16 }}>
                            Composants - {getPageName(PAGE_TO_EDIT)}
                        </h2>
                        {
                            currentTab === 0 ?
                                (
                                    <div className="first-panel" style={{ backgroundColor: colors.blue.lighter.hue900 }}>
                                        <div style={{ width: '100%', display: 'flex' }}>
                                            <FlipToFrontIcon style={{ fill: colors.blue.lighter.hue300 }} />
                                            <Typography className={classes.heading} style={{ color: colors.blue.lighter.hue300, paddingBottom: 12, paddingLeft: 10 }}>Liste des composants</Typography>
                                        </div>
                                        {
                                            presentation && (
                                                <>
                                                    <Draggable type={ItemTypes.OFFER_FRONT_PAGE} />
                                                    <Draggable type={ItemTypes.OFFER_SECTION_TITLE} />
                                                    <Draggable type={ItemTypes.OFFER_ABOUT_SLIDE} />
                                                    <Draggable type={ItemTypes.OFFER_TRIPLE_BLOCK_LIST} />
                                                    <Draggable type={ItemTypes.OFFER_TRIPLE_BLOCK} />
                                                    <Draggable type={ItemTypes.OFFER_TRIPLE_BLOCK_TEXT} />
                                                    <Draggable type={ItemTypes.OFFER_TEXT_WITH_IMAGE} />
                                                    <Draggable type={ItemTypes.OFFER_TEXT_WITH_IMAGE_SIMPLE} />
                                                    <Draggable type={ItemTypes.OFFER_DEVIS} />
                                                </>
                                            )
                                        }

                                        {
                                            assetType === "minisite" ?
                                                (
                                                    <>
                                                        <Draggable type={ItemTypes.SLIDER} />
                                                        <Draggable type={ItemTypes.SLIDER_MULTIPLE} />
                                                        <Draggable type={ItemTypes.SLIDER_WITH_RIGHT_IMAGES} />
                                                        <Draggable type={ItemTypes.TAB_PRODUCT} />
                                                        <Draggable type={ItemTypes.IMAGE_ROW} />
                                                        <Draggable type={ItemTypes.SECTION_TITLE} />
                                                        <Draggable type={ItemTypes.THREE_IMAGES_GRID} />
                                                        <Draggable type={ItemTypes.THREE_IMAGES_ROW} />
                                                        <Draggable type={ItemTypes.BLOC_TEXT} />
                                                        <Draggable type={ItemTypes.GIFT_FINDER} />
                                                    </>
                                                )
                                            :
                                            assetType === "flipbook" || assetType === "newsletter" ?
                                                <Alert severity="warning" style={{ width: "calc(100% - 40px)", marginTop: 10, marginBottom: 0 }}>
                                                    <AlertTitle>Attention</AlertTitle>
                                                    Aucun <strong>composant</strong> disponible pour le flipbook
                                                </Alert>
                                            :
                                            assetType === "gift_finder" ?
                                                <Alert severity="warning" style={{ width: "calc(100% - 40px)", marginTop: 10, marginBottom: 0 }}>
                                                    <AlertTitle>Attention</AlertTitle>
                                                    Aucun <strong>composant</strong> disponible pour le gift finder
                                                </Alert>
                                            :
                                                assetType === "jeu" ? (
                                                    <>
                                                        <Draggable type={ItemTypes.PRE_HEADER_GAME} />
                                                        <Draggable type={ItemTypes.HOME_GAME}>
                                                            <Draggable type={ItemTypes.HOME_COMPLETE_FORM_GAME} />
                                                        </Draggable>
                                                        <Draggable type={ItemTypes.EXPLANATIONS_GAME}>
                                                            <Draggable type={ItemTypes.EXPLANATIONS_ACV_GAME} />
                                                            <Draggable type={ItemTypes.EXPLANATIONS_BF_GAME} />
                                                            <Draggable type={ItemTypes.EXPLANATIONS_MORE_DETAILS_GAME} />
                                                        </Draggable>
                                                        <Draggable type={ItemTypes.AMAZING_GAME}>
                                                            <Draggable type={ItemTypes.TITLE_GAME} />
                                                            <Draggable type={ItemTypes.PARAGRAPH_GAME} />
                                                            <Draggable type={ItemTypes.RULES_AMAZING_GAME} />
                                                            <Draggable type={ItemTypes.RULES_INLINE_FLEX_AMAZING_GAME} />
                                                            <Draggable type={ItemTypes.DOTATIONS_ONE_IMAGE} />
                                                            <Draggable type={ItemTypes.BUTTON_GAME} />
                                                        </Draggable>
                                                        <Draggable type={ItemTypes.BASIC_BLOC}>
                                                            <Draggable type={ItemTypes.BASIC_BLOC2} />
                                                        </Draggable>
                                                        <Draggable type={ItemTypes.REDIRECT_ON_GAME}>
                                                            <Draggable type={ItemTypes.REDIRECT_ON_GAME3} />
                                                            <Draggable type={ItemTypes.REDIRECT_ON_GAME4} />
                                                        </Draggable>
                                                    </>
                                                )
                                            : 
                                                null
                                        }
                                    </div>
                                ) 
                            : 
                                null
                        }
                    </div>

                    <div id={`simple-tabpanel-${1}`} value={currentTab} style={{
                        background: 'white',
                        display: currentTab === 1 ? 'block' : 'none'
                    }} index={1}>
                        {
                            currentTab === 1
                                ? <div className="second-panel">
                                    {currentComponent
                                        ? buildForm()
                                        : (
                                            <Alert severity="warning" style={{ width: "calc(100% - 40px)", marginTop: 10, marginBottom: 30 }}>
                                                <AlertTitle>Attention</AlertTitle>
                                                Aucun <strong>composant</strong> sélectionné
                                            </Alert>
                                        )
                                    }
                                </div>
                            : 
                                null
                        }
                    </div>

                    <div id={`simple-tabpanel-${2}`} value={currentTab} index={2} style={{ display: currentTab === 2 ? 'block' : 'none', background: 'white' }}>
                        {
                            currentTab === 2 ?
                                (
                                    <div className="third-panel">
                                        <h2 style={{ color: colors.blue.regular, width: '100%', padding: '10px 0', marginBottom: 6, fontSize: 16 }}>
                                            Paramètres - {getPageName(PAGE_TO_EDIT)}
                                        </h2>
                                        {buildGeneralInputs()}

                                        {/* { assetType === "flipbook" && <PageCreator
                                        data={element.data}
                                        pagesFlipbook={pagesFlipbook}
                                        buildFormInput={buildFormInput}
                                        products={apiConfig.products}
                                        update={updateConfig}
                                        catalog={catalog}
                                        updateFlipbook={updateFlipbook}
                                    /> } */}
                                    </div>
                                ) 
                            :
                                null
                        }
                    </div>
                </div>
            </div>

            {/* Snapshots */}

            {
                snapModalOpen && (
                    <SnapshotCreationModal 
                        identifier={snapIdentifier}
                        data={snapData}
                        onClose={() => setSnapModalOpen(false)}
                    />
                )
            }

            {
                snapListModalOpen && (
                    <SnapshotListingModal 
                        identifier={snapIdentifier}
                        onClose={() => setSnapListModalOpen(false)}
                        onValidate={(snap) => {
                            if (!snap) return;
                            
                            for (let element of config[PAGE_TO_EDIT].elements) {
                                if (element.key === snapKey) {
                                    element.data = JSON.parse(snap.node.content);
                                    updateConfig();
                                    setSnapListModalOpen(false);
                                    snack(ALERT_SUCCESS, "Snapshot appliqué !");
                                } 
                            }
                        }}
                    />
                )
            }
        </div>
    )
};

// Redux

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
};

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes,
        locales: state.locales,
    };
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(BuilderComponent)));
