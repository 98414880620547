import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { Grid } from '@material-ui/core';
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import EmptyCard from "../../../ui/empty-card/EmptyCard";
import Empty from '../../../../assets/pictos/empty-picto/empty_guidelines.png';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import CardCustom from '../../../layouts/Card/CardCustom';
import CardGuideline from '../../../layouts/Card/cardContent/CardGuideline';
import TopPanel from '../../../layouts/TopPanel/TopPanel';

import { SNACK, START_LOADING, STOP_LOADING } from '../../../../js/constants/action-types';

import * as moment from "moment";

import { withApollo } from 'react-apollo';
import { GET_ATTR_GROUPE_WITH_ATTRIBUTES } from "../../../../queries/attributes";
import { GET_BRAND_GUIDELINE, ADD_GUIDELINE, UPDATE_GUIDELINE, ADD_GUIDELINE_DATA, UPDATE_GUIDELINE_DATA} from "../../../../queries/brand_guideline";
import { SET_GUIDELINE } from '../../../../js/constants/action-types';
import colors from '../../../../config/theme/colors';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import guidelineForm from './config/guideline';
import slugify from 'slugify';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class GuidelineBrand extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            currentLang: this.props.locales[0].node.code,
            changedLang: false,
            openSnack: false,
            openForm: false,
            edited: false,
            editForm: 'add',
            image: '', 
            preferredLangcode: 'fr',
            isActive: true,
            notif: false,
            loading: true,
            generate: false,
            isBlocked: false, 
            createdAt: moment().format(), 
            updatedAt: moment().format(),
            inputHidden: [],
            attr: {},
            isEmpty: false,
            dataAddLayout: guidelineForm,
            content:{
                emptyTitle: "Vous n’avez pas encore paramétrer votre guideline",
                emptySubtitle: "Cliquez ci-dessous commencer la configuration",
                emptyTxtBtn: "Créer ma guideline",
                emptyPicto: Empty,
            },
        }
        this.guideline = {
            image: ''
        }
    }

        
    handleLang = (event) => {
        this.setState({ currentLang: event.target.value, changedLang: true });
        this.forceUpdate();
    };

    setValue = (stateName, value, translated) => {
        if (translated) {
            if(this.state.changedLang){
                let values = this.state[this.state.currentLang];
                if (!values) {
                    values = {};
                }
                values[stateName] = value;
                this.setState({
                    [this.state.currentLang]: values,
                });
            }
            else{
                for(let locale of this.props.locales){
                    let values = this.state[locale.node.code];
                    if (!values) {
                        values = {};
                    }
                    values[stateName] = value;
                    this.setState({
                        [locale.node.code]: values,
                    });
                }
            }
        } else{
            this.setState({
                [stateName]: value,
            });
        }
    }
    handleMediaPicker=(selected,stateName)=>{
        this.handleInputChange(stateName,selected,null,this.state.currentLang);  
    }
    handleInputChange = (stateName, evt, custom, translated ) => {
        const value = evt?.target?.value ?? evt;
        this.setValue(stateName, value, translated);
    }

    getGuideline = () => {
        const getGuideline = GET_BRAND_GUIDELINE;
        this.props.client.query({
            query: getGuideline,
            fetchPolicy: 'no-cache'
        }).then(result =>{
            if(result.data.guidelines.edges.length === 0){
                this.setState({isEmpty: true, editForm: 'add'})
            }
            else{
                this.setState({guideline: result.data.guidelines.edges, idGuideline: result.data.guidelines.edges[0].node.id})
                for(let locale of this.props.locales){
                    this.setState({editForm: 'edit'})
                    let guideline = this.state.guideline[0].node;
                    for(let attr of guideline.guidelineData.edges){
                        if(attr.node.locale.id === locale.node.id){
                            let value;
                            if(attr.node.attribute.identifier.search(/color/i)>=0){
                                value = {"hex": attr.node.value};
                            }
                            else if(attr.node.attribute.identifier.search(/image|logo/i)>=0){
                                value = attr.node.media?.filePath;
                            }
                            else{
                                value = attr.node.value;
                            }
                            // let getAttr = this.state[locale.node.code][attr.node.attribute.identifier]
                            this.setState({
                                [this.state[locale.node.code][attr.node.attribute.identifier]] : value
                            })
                        }
                    }
                    this.props.setGuideline(guideline);
                    localStorage.setItem('GUIDELINE', JSON.stringify(guideline));
                }
            }
            this.setState({loading: false});
        });
    }

    handlerMutation = () => {
        this.props.startLoading();
        let query = null;
        let variables = null;
        switch(this.state.editForm){
            case 'edit' :
                query = UPDATE_GUIDELINE;
                variables = 
                {
                    "id" : this.state.guideline[0].node.id,
                    "libelle": this.state[this.state.currentLang].guideline_name,
                }
            break;
            case 'add' :
                let identifier = slugify(this.state[this.state.currentLang].guideline_name, {replacement :'_', lower: true, remove: /[^\w\-\s]+/g});
                query = ADD_GUIDELINE;
                variables = 
                {
                    "identifier": identifier,
                    "attributeGroup": this.state.attr.id,
                    "libelle": this.state[this.state.currentLang].guideline_name,
                }
            break;
            default:
                return null
        }
        this.props.client.mutate({
            mutation: query,
            variables,
        }).then(result => {
            if(this.state.editForm === 'add'){
                this.setState({idGuideline: result.data.createGuideline.guideline.id})
            }
            this.handlerSaveGuidelineData();
        })
    }
    handlerSaveGuidelineData = () => {
        return new Promise(async (resolve, reject) => {
            if(this.state.editForm === "add"){
                for (let locale of this.props.locales){
                    for(let item of this.state.attr.attributes.edges){
                        let value = this.state[locale.node.code][item.node.identifier];
                        let variables = {
                            "guideline": this.state.idGuideline,
                            "attribute": item.node.id,
                            "locale": locale.node.id
                        };
                        if(item.node.identifier.search(/color/i)>=0){
                            variables.value = value.hex;
                        }
                        else if(item.node.identifier.search(/image|logo/i)>=0){
                            variables.value = value.id;
                        }
                        else{
                            variables.value = value;
                        }
                        await this.props.client.mutate({
                            mutation: ADD_GUIDELINE_DATA,
                            variables
                        });
                    }
                }
            }
            else if(this.state.editForm === "edit"){
                for (let locale of this.props.locales){
                    for(let item of this.state.attr.attributes.edges){
                        if(this.state[locale.node.code][item.node.identifier]){
                            let i = 0;
                            let value = this.state[locale.node.code][item.node.identifier];

                            for(let guidelineData of this.state.guideline[0].node.guidelineData.edges){
                                if(item.node.id === guidelineData.node.attribute.id && locale.node.id === guidelineData.node.locale.id){
                                    i++;
                                    let variables = {
                                        "guideline": this.state.idGuideline,
                                        "attribute": item.node.id,
                                        "locale": locale.node.id,
                                        "id": guidelineData.node.id
                                    };
                                    if(item.node.identifier.search(/color/i)>=0){
                                        variables.value = value.hex;
                                    }
                                    else if(item.node.identifier.search(/image|logo/i)>=0){
                                        variables.media = value.id;
                                    }
                                    else{
                                        variables.value = value;
                                    }
                                    await this.props.client.mutate({
                                        mutation: UPDATE_GUIDELINE_DATA,
                                        variables
                                    });
                                }
                            }
                            if(i===0){
                                let variables = {
                                    "guideline": this.state.idGuideline,
                                    "attribute": item.node.id,
                                    "locale": locale.node.id
                                };
                                if(item.node.identifier.search(/color/i)>=0){
                                    variables.value = value.hex;
                                }
                                else if(item.node.identifier.search(/image|logo/i)>=0){
                                    variables.value = value.id;
                                }
                                else{
                                    variables.value = value;
                                }

                                await this.props.client.mutate({
                                    mutation: ADD_GUIDELINE_DATA,
                                    variables
                                });
                            }
                            
                        }
                    }
                }
            }
            this.setState({
                openForm: !this.state.openForm
            })
            this.props.stopLoading();
            this.getGuideline();
            resolve();
        });
    }


    handleSetupForm = () => {
        let allAttr = this.state.attr.attributes.edges;
        this.setState({
            [this.state.dataAddLayout.formConfig.children[0].optionsInputs] : []
        })
        for(let attr of allAttr){
            let identifier = attr.node.identifier;
            let label;
            let type;
            let value;
            if(attr.node.translation){
                for(let trans of attr.node.translation.translationDatas.edges){
                    if(trans.node.locale.code === this.state.currentLang){
                        label = trans.node.value;
                    }
                }
            }
            else{
                label = identifier;
            }
            if(identifier.search(/color/i)>=0){
                type = 'colorPicker';
                value = '#ffffff';
            }
            else if(identifier.search(/image|logo/i)>=0){
                type = 'mediaPicker';
            }
            else{
                type = 'text';
            }
            let input = {
                type: type,
                label: label,
                value: value,
                translated: true,
                helper: {
                    text: label,
                    link: false,
                },
                required: false,
                stateName: attr.node.identifier,
                handleMediaPicker : type === 'mediaPicker' ? this.handleMediaPicker :null,
            };
            for(let locale of this.props.locales){
                if(this.state.isEmpty){
                    this.setState({
                        [locale.node.code]: {}
                    }, () => {
                        this.setState({
                            [this.state[locale.node.code][attr.node.identifier]] : null
                        })
                    });
                }
            }
            this.state.dataAddLayout.formConfig.children[0].optionsInputs.push(input);
        }
    }

    getAttributes = () => {
        const getAttributes = GET_ATTR_GROUPE_WITH_ATTRIBUTES;
        this.props.client.query({
            query: getAttributes,
            fetchPolicy: 'no-cache'
        }).then(result =>{
            let attrWithGroup = result.data.attributeGroups.edges;
            for(let attrGroup of attrWithGroup){
                if(attrGroup.node.identifier === "guideline"){
                    this.setState({
                        attr: attrGroup.node
                    }, () => {
                        this.handleSetupForm();
                    })
                }
            }
        });
    };
    
    handleToggleDrawer = () => {
        this.setState({ 
            openForm : !this.state.openForm,
            edited: true
        }, () => {
            if(!this.state.openForm && this.state.isEmpty){
                let allAttr = this.state.attr.attributes.edges;
                for(let attr of allAttr){
                    for(let locale of this.props.locales){
                        this.setState({
                            [locale.node.code]: {}
                        }, () => {
                            this.setState({
                                [this.state[locale.node.code][attr.node.identifier]] : null
                            })
                        });
                    }
                }
            }
            else if(!this.state.openForm && !this.state.isEmpty){
                this.getGuideline();
                this.handleSetupForm();
            }
        });
    };

    initData = () => {
        return new Promise(async (resolve, reject) => {
            await this.getAttributes();
            await this.getGuideline();
            resolve();
        })
    }
    componentDidMount = () => {
        for(let locale of this.props.locales){
            this.setState({[locale.node.code]: {}});
        }
        this.getAttributes();
        this.getGuideline();
    }

    render() {
        return (
            <>
                <div style={{width: this.state.openForm ? `calc(100% - ((50% - ${this.props.drawerWidth}px / 2) + (${this.props.drawerWidth}px / 2) + 32px))` : !this.state.edited && this.state.isEmpty ? `100%` : '50%', marginTop: 16, transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms'}}>
                    <TopPanel 
                        icomoon="icon-dashboard"
                        colorIcomoon={colors.blue.lighter.hue300}
                        title="Guideline" 
                        subtitle="Gestion de votre image de marque" 
                        gradientColor1={colors.menu.regular} 
                        gradientColor2={colors.menu.darker}
                        openForm={this.state.openForm}
                        windowWidth={this.props.windowWidth}
                        currentLang={this.state.currentLang} 
                        handleLang={this.handleLang} 
                        locales={this.props.locales}
                    />
                    {
                        this.state.loading ? (
                            <PageLoader />
                        )
                        :
                        this.state.isEmpty && !this.state.edited ? this.state.openForm ? (
                            <Grid container direction="column" justify="center" spacing={0} style={{paddingBottom: 24}}>
                                <Grid container direction="row" spacing={4}>
                                    <Grid item xs={12}>
                                        <CardCustom>
                                            <CardGuideline allState={this.state} onClick={() => {this.handleToggleDrawer()}} />
                                        </CardCustom>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                        : 
                        (
                            <EmptyCard title={this.state.content.emptyTitle} subtitle={this.state.content.emptySubtitle} textButton={this.state.content.emptyTxtBtn} onClick={() => {this.handleToggleDrawer()}} picto={this.state.content.emptyPicto} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />
                        ) 
                        : 
                        (
                            <Grid container direction="column" justify="center" spacing={0} style={{paddingBottom: 24}}>
                                <Grid container direction="row" spacing={4}>
                                    <Grid item xs={12}>
                                        <CardCustom>
                                            <CardGuideline allState={this.state} onClick={() => {this.handleToggleDrawer()}} />
                                        </CardCustom>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    }
                    <LayoutBuilder 
                        icomoon={"icon-dashboard"}
                        opened={this.state.openForm} 
                        forClose={this.handleToggleDrawer} 
                        dataLayout={this.state.dataAddLayout} 
                        allState={this.state} 
                        stateCallback={this.handleInputChange}
                        handlerMutation={this.handlerMutation}
                        currentLang={this.state.currentLang}
                        handleLang={this.handleLang}
                        hideInput={this.state.inputHidden}
                        validateButton={true}
                        drawerWidth={this.props.drawerWidth}
                    />
                    <Snackbar open={this.state.openSnack} autoHideDuration={3000} onClose={this.handleClose}>
                        <Alert onClose={this.handleClose} severity={'success'}>
                            Le compte a bien été mis à jour !
                        </Alert>
                    </Snackbar>
                </div>
            </>
        );
    }
    goTo = (route) => {
        this.props.history.push({
            pathname : route,
        });
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        users: state.users,
        locales: state.locales,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setGuideline: (guideline) => dispatch({ type: SET_GUIDELINE, payload: { guideline }}),
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(GuidelineBrand)));