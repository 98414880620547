import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import styled from 'styled-components';
import colors from '../../../../config/theme/colors';

const TabsCustom = styled(Tabs)`
  // margin-bottom: 16px;
  button{
    text-transform: initial;
    & > span:first-child{
      flex-direction: row;
      svg{
      // margin-left:15px;  
      margin-bottom:0px!important;
      // display:block;
      }
      // flex-direction: row-reverse;
      // align-items:center;
    }
  }
  .MuiSvgIcon-fontSizeSmall{
    font-size: 30px;
    fill: ${colors.blue.lighter.hue300};
  }
  &> div > span{
    background-color: ${colors.blue.lighter.hue300};
    height: 3px;
    &.MuiTouchRipple-root{
      background-color: transparent;

    }
  }
`;

const TabCustom = styled(Tab)`
  min-width: inherit;
  padding: 6px 18px;
  
`;

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        boxShadow: 'inherit',
        background: 'white',
        borderBottom: `1px solid ${colors.grey.lighter.hue800}`,
    },
});

export default function TabCategories(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);    

    const handleChange = (event, newValue) => {
        setValue(newValue); 
    }
    
    return (
        <Paper className={classes.root}>
            <TabsCustom
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant         = "scrollable"
                scrollButtons   = "auto"
            >
                <TabCustom onClick={(event) => { props.handleChangeTab(event, 0, false, 'Tout voir' );}} label='Tout voir' />
                { 
                props.categories.map((categorie, index) => {
                    return(
                        <TabCustom 
                            key={`TabCategorie ${index}`} 
                            onClick={(event) => { props.handleChangeTab(event, index + 1, categorie.attributeGroup.id, categorie.libelle );}} 
                            label={categorie.libelle} 
                        />
                    )
                })
                }
            </TabsCustom>
        </Paper>
    );
}