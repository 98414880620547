import { gql } from 'apollo-boost';

//----- Récupération des utilisateurs
export const GET_USERS = gql`
{
  users{
    edges{
      node{
        id
        email
        roles
        firstname
        lastname
        phone
        image
        preferredLangcode
        isActive
        isBlocked
        lastLogin
        userRole{
          id
        	identifier
          libelle
        }
      }
    }
  }
}
`;

//------ Récupération des emails des utilisateurs
export const GET_USERS_EMAIL = gql`
{
  users{
    edges{
      node{
        email
      }
    }
  }
}
`;

export const GET_USER_BY_ID = gql`
query user($id: ID!) {
  user(id: $id) {
    id
    email
    roles
    firstname
    lastname
    phone
    image
    preferredLangcode
    isActive
    isBlocked
    lastLogin
    userRole{
      id
      identifier
      libelle
    }
  }
}
`;


//----- Ajouter un utilisateur
/*
  Exemple de variables
  {
    "email": "rdelon+demo@sinfin.fr",
    "plainPassword": "#Sinfin#10",
    "roles": {},
    "firstname": "Romain",
    "lastname": "DELON",
    "phone": "0102030405",
    "preferredLangcode": "fr",
    "isActive": true,
    "isBlocked": false,
    "createdAt": "2020-03-25",
    "updatedAt": "2020-03-25",
    "userRole": "/api/user-roles/3"
  }
*/
export const ADD_USER = gql`
mutation AddUser($email: String!, $plainPassword: String!, $roles: Iterable!, $firstname: String!, $lastname: String!, $phone: String, $image: String, $preferredLangcode: String, $isActive: Boolean!, $isBlocked: Boolean!, $createdAt: String!, $updatedAt: String!, $userRole: String){
	createUser(input: {email: $email, plainPassword: $plainPassword, roles: $roles, firstname: $firstname, lastname: $lastname, phone: $phone, image: $image, preferredLangcode: $preferredLangcode, isActive: $isActive, isBlocked: $isBlocked, createdAt: $createdAt, updatedAt: $updatedAt, userRole: $userRole}){
    user{
        email
        roles
        firstname
        lastname
        phone
        image
        preferredLangcode
        isActive
        isBlocked
        createdAt
        updatedAt
        userRole {
          id
          identifier
          libelle
        }
      }
  }  
}`;

//----- Modifier un utilisateur
/*
  Exemple de variables
  {
    "id" : "/api/users/13",
    "plainPassword": "#Sinfin#10",
    "firstname": "Test",
    "updatedAt": "2020-03-25"
  }
*/
export const UPDATE_USER = gql`
mutation UpdateUser($id: ID!, $email: String, $plainPassword: String, $roles: Iterable, $firstname: String, $lastname: String, $phone: String, $image: String, $preferredLangcode: String, $isActive: Boolean, $isBlocked: Boolean, $createdAt: String, $updatedAt: String!, $userRole: String){
	updateUser(input: {id: $id, email: $email, plainPassword: $plainPassword, roles: $roles, firstname: $firstname, lastname: $lastname, phone: $phone, image: $image, preferredLangcode: $preferredLangcode, isActive: $isActive, isBlocked: $isBlocked, createdAt: $createdAt, updatedAt: $updatedAt, userRole: $userRole}){
    user{
        id
        email
        roles
        firstname
        lastname
        phone
        image
        preferredLangcode
        isActive
        isBlocked
        createdAt
        updatedAt
        userRole{
          id
          identifier
          libelle
        }
    }
  }  
}`;

//----- Supprimer un utilisateur
/*
 Exemple de variables
  {
    "id" : "/api/users/2"
  }
*/
export const DELETE_USER = gql`
mutation DeleteUser($id: ID!){
	deleteUser(input: {id:$id}){
    user{
      id
    }
  }  
}`;

//----- Récupérer le token d'un utilisateur
/*
 Exemple de variables
  {
    "id" : "/api/users/13"
  }
*/
export const GET_USER_TOKEN = gql`
query userToken($id: ID!) {
  user(id: $id) {
    id
  	userToken{
      id
  	}
  }
}
`;

//----- Supprimer le token d'un utilisateur
/*
 Exemple de variables
  {
    "id" : "/api/user-tokens/5"
  }
*/
export const DELETE_USER_TOKEN = gql`
mutation DeleteUserToken($id: ID!) {
  deleteUserToken(input: {id:$id}) {
    userToken{
      id
    }
  }
}
`;

//----- Récupération des ROLES

export const GET_ROLES = gql`
{
  userRoles{
    edges{
      node{
        id
        identifier
        libelle
      }
    }
  }
}`

//----- Récupération des ROLES / ACCESS
/*
  Exemple de variables
  {
    "hasParent": false
  }
*/
export const GET_ACL = gql`
query userRoles ($hasParent: Boolean) {
  userRoles{
    edges{
      node{
        id
        identifier
        libelle
        userAccess(exists:{parent:$hasParent}){
          edges{
            node{
              id
							identifier
              parent{
                id
                identifier
              }
              children {
                edges{
                  node{
                    id
                    identifier
                    children{
                      edges{
                        node{
                          identifier
                          id
                    		}
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;


//----- Ajouter un USER ROLE
/*
  Exemple de variables
  {
    "identifier":"test"
  }
*/
export const ADD_USER_ROLE = gql`
mutation AddUserRole($identifier: String!, $libelle: String!, $parent: String, $children: [String], $users: [String], $userAccess: [String]){
	createUserRole(input: {identifier: $identifier, libelle: $libelle, parent: $parent, children: $children, users: $users, userAccess: $userAccess}){
    userRole{
        id
        identifier
        libelle
    }
  }  
}`;

//----- Modifier un USER ROLE
/*
  Exemple de variables
  {
    "id": "/api/user-roles/10",
    "identifier":"test edited"
  }
*/
export const UPDATE_USER_ROLE = gql`
mutation UpdateUserRole($id: ID!, $identifier: String, $libelle: String!, $parent: String, $children: [String], $users: [String], $userAccess: [String]){
	updateUserRole(input: {id: $id, identifier: $identifier, libelle: $libelle, parent: $parent, children: $children, users: $users, userAccess: $userAccess}){
    userRole{
        id
        identifier
        libelle
    }
  }  
}`;


//----- supprimer un USER ROLE
/*
  Exemple de variables
  {
    "id": "/api/user-roles/10"
  }
*/
export const DELETE_USER_ROLE = gql`
mutation DeleteUserRole($id: ID!){
	deleteUserRole(input: {id: $id}){
    userRole{
        id
    }
  }  
}`;


//----- Ajouter un USER ACCESS
/*
  Exemple de variables
  {
    "identifier":"view"
  }
*/
export const ADD_USER_ACCESS = gql`
mutation AddUserAccess($identifier: String!, $parent: String, $children: [String]){
	createUserAccess(input: {identifier: $identifier, parent: $parent, children: $children}){
    userAccess{
        id
        identifier
    }
  }  
}`;

//----- Modifier un USER ACCESS
/*
  Exemple de variables
  {
    "id": "/api/user-accesses/383",
    "identifier":"view_test_edited"
  }
*/
export const UPDATE_USER_ACCESS = gql`
mutation UpdateUserAccess($id: ID!, $identifier: String,  $parent: String, $children: [String]){
	updateUserAccess(input: {id: $id, identifier: $identifier, parent: $parent, children: $children}){
    userAccess{
        id
        identifier
    }
  }  
}`;



//----- supprimer un USER ACCESS
/*
  Exemple de variables
  {
    "id": "/api/user-accesses/383"
  }
*/
export const DELETE_USER_ACCESS = gql`
mutation DeleteUserAccess($id: ID!){
	deleteUserAccess(input: {id: $id}){
    userAccess{
        id
    }
  }  
}`;