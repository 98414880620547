import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import Button from '../../../ui/button/Button';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import NoImage from '../../../../assets/images/not-found.png';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getTraductionAttributs } from '../../../../js/utils/functions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import * as moment from 'moment';

const ContainerCard = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;
const ButtonCustom = styled(Button)`
    margin: 0;
    text-transform: unset;
    font-weight: bold;
`;
const Title = styled(Typography)`
    text-transform: capitalize
`;
const Sku = styled(Typography)``;
const SpanColor = styled.span`
    color: ${colors.blue.lighter.hue300};
    font-weight: bold;
`;
const TableCellCustom = styled(TableCell)`
    border: none;
    display: inline-block;
    width: calc(100% / 8);
    padding: 3px 16px;
`;
const CircularProgressCustom = styled(CircularProgress)`
    color: ${props => props.value > 30 
            ? props.value > 65 
                ? colors.green.regular : "#f6d607"
            : colors.red.regular};
    margin-left: ${props => props.marginleft};
`;

export default function CardOrderList(props){
    const orders = props.orders;

    return(
        <ContainerCard>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableBody>
                        <TableRow key={'rowOrders'}>
                            <TableCellCustom>
                                <Typography variant="body1">{orders.number}</Typography>
                            </TableCellCustom>
                            <TableCellCustom>
                                <Sku variant="body1">{moment(orders.createdAt).format('DD/MM/YYYY HH:mm:ss')}</Sku>
                            </TableCellCustom>
                            <TableCellCustom>
                                <Typography variant="body1"><span style={{textTransform: "uppercase"}}>{orders.shippingName}</span> {orders.shippingFirstname}</Typography>
                            </TableCellCustom>
                            <TableCellCustom>
                                    <Typography variant="body1">Entrepot Troyes</Typography>
                            </TableCellCustom>
                            <TableCellCustom>
                                    <Typography variant="body1">{orders.source}</Typography>
                            </TableCellCustom>
                            <TableCellCustom>
                                    <Typography variant="body1">{orders.sourceNumber}</Typography>
                            </TableCellCustom>
                            <TableCellCustom>
                                <Typography variant="body1">{orders.totalAmount} €</Typography>
                            </TableCellCustom>
                            <TableCellCustom>
                                    <Title variant="body1">{orders.status}</Title>
                            </TableCellCustom>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </ContainerCard>
    );
}