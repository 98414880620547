import Stepper from '@material-ui/core/Stepper';
import styled from 'styled-components';
import colors from '../../../config/theme/colors';

const StepperCustom = styled(Stepper)`
        padding: 0;
        border: 1px solid ${colors.grey.lighter.hue900};
        background: ${colors.white};
        .MuiStep{
        &-horizontal{
            flex: 1 1 auto;
            border-radius: 0 20px 20px 0;
            padding: 5px;
            background-color: ${colors.blue.lighter.hue300};
            height: 34px;
            span{
                color: ${colors.white};
                width: auto;
                padding: 0;
            }
            svg{
                circle{
                    fill: ${colors.blue.lighter.hue300};
                }
                text{
                    fill: ${colors.white};
                }
            }
            .MuiStepLabel-horizontal{
                justify-content: center;
            }
            .MuiStepLabel-labelContainer > span {
                display: inline-block;
            }
            &:last-child{
                border-radius: 0;
            }
            @media screen and (max-width: 1450px){
                padding: 5px 0 5px 2px;
                span{
                    font-size: 12px;
                }
                .MuiStepLabel-horizontal{
                    justify-content: initial;
                }
            }
            @media screen and (max-width: 1280px){
                padding: 2px 0 2px 2px;
                height: 28px;
            }
        }
        &-completed{
            border-radius: inherit;
            span{
                color: ${colors.white};
                &.MuiStepLabel-iconContainer{
                    position: relative;
                    background-color: white;
                    border-radius: 50px;
                    max-width: 20px;
                    max-height: 20px;
                }
            }
            @media screen and (max-width: 1000px){
                &>span{
                    margin: 2px;
                    max-width: 20px;
                }
                span.MuiStepLabel-labelContainer{
                    display: none;
                }
            }
        }
        &Connector-horizontal.Mui-disabled{
            &+div{
                background-color: ${colors.white}; 
                svg{
                    circle{
                        fill: ${colors.white};
                    }
                    text{
                        fill: ${colors.grey.lighter.hue600};
                    }
                }
                span{
                    color: ${colors.grey.lighter.hue600};
                }
            }
        }
        &Connector-active + div {
            background-color: ${colors.blue.lighter.hue300};
            span{
                color: ${colors.white};
            }
        }  
        &Connector-horizontal{
            display: none;
        }
        &Icon-completed{
            border-radius: 50px;
            height: 23px;
            width: 23px;
            position: relative;
            top: -2px;
            left: -2px;
            path{
                color: ${colors.blue.lighter.hue300};
            }
        }
    }
    button{
        color: ${colors.blue.regular};
        position: relative;
        z-index: 9;
        text-transform: inherit;
        font-weight: bold;
        font-size: 14px;
        opacity: 1;
    }
    .MuiTabs-indicator{
        top: 0;
        height: 100%;
        border-top: 2px solid ${colors.blue.lighter.hue300};
        background-color: ${colors.grey.lighter.hue980}
    }
`;

export default StepperCustom;