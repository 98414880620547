import React from 'react';
import { connect } from "react-redux";
import { Grid, MenuItem } from '@material-ui/core';
import Typography from '../../../ui/typography/Typography';
import DialogModal from '../../../ui/dialog/DialogModal'
import Button from '../../../ui/button/Button';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import CardCustom from '../CardCustom';
import CardHeader from '@material-ui/core/CardHeader';
import Menu from '@material-ui/core/Menu';

import Avatar from '@material-ui/core/Avatar';
import CardFacebook from './feeds/CardFacebook'
import CardTwitter from './feeds/CardTwitter'
import CardWp from './feeds/CardWp'

const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem;
`;

const CustomAvatar = styled(Avatar)`
    width: 70px;
    height: 70px;
    padding: 5px;
    img{
        object-fit: contain;
    }
`;

class CardFeeds extends React.Component{
    constructor(props){
        super(props)
        this.state={
            openSettings:false,
            settingsAnchorEl:null,
            openDeletePostDialog:false,
            hasMenu:false,
            demo : true,
            toDelete : null,
            buttonText : null,
        }         
    }
    handleOpenSettings= (event,id=null) => {

        this.setState({
            settingsAnchorEl    :event.currentTarget,
            toDelete            : id,
        },()=>{

            this.handleToggleSettings();
        });
        
    };

    handleToggleSettings = () => {
        this.setState({
            openSettings:!this.state.openSettings
        });
    };
    handleToggleDeletePostDialog=()=>{
        this.setState({
            openDeletePostDialog    : !this.state.openDeletePostDialog,
        })
    }

    renderSwitch(param){
        switch(param.assetType.identifier){
            case 'facebook':
                return <CardFacebook data={this.props.posts} handleSettings={this.handleOpenSettings} type={param.assetType}/>
            
            case 'twitter':
                return <CardTwitter data={this.props.posts} handleSettings={this.handleOpenSettings} type={param.assetType}/>
    
            case 'wordpress':
                return <CardWp data={this.props.posts} handleSettings={this.handleOpenSettings} type={param.assetType}/>
            default:
                return null;         
        }
    }

    renderButton(param){
        switch(param.assetType.identifier){

            case 'facebook':
                return <Button onClick={() => window.open(`${this.props.data.prodUrl ? this.props.data.prodUrl : this.props.data.stageUrl}`,'_blank')}>Voir le compte</Button>
            
            case 'twitter':
                return <Button onClick={() => window.open(`${this.props.data.prodUrl ? this.props.data.prodUrl : this.props.data.stageUrl}`,'_blank')}>Voir le compte</Button>
    
            case 'wordpress':
                return <Button onClick={() => window.open(`${this.props.data.prodUrl ? this.props.data.prodUrl : this.props.data.stageUrl}`,'_blank')}>Voir le blog</Button>
            default:
                return null;    
        }
    }


    render() {
        let asset=this.props.data;
        let imageGuideline = this.props.guideline.guidelineData.edges.find(e => e.node.attribute.identifier === "guideline_logo")
        return (
            <GridCustom item xs={12}>
                <CardCustom paddingbottom={0} paddingtop={0} style={{width: "100%"}} contentpadding={'16px 32px'}>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item>
                            <CardHeader
                                avatar={
                                    <CustomAvatar aria-label="recipe" src={`${process.env.REACT_APP_API_ROOT}/medias/${imageGuideline.node.media.filePath}`} />
                                }
                                title={this.props.guideline.libelle}
                                subheader={`Votre feed ${asset.assetType.libelle}`}
                            />
                        </Grid>
                        <Grid item>
                            {asset.prodUrl || asset.stageUrl ? 
                                this.renderButton(asset)
                                : null
                            }
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" spacing={2} style={{paddingTop:20,background:colors.grey.lighter.hue980}}>
                        {this.renderSwitch(asset)}
                    </Grid>
                
                </CardCustom>
                <Menu
                    id="long-menu"
                    anchorEl={this.state.settingsAnchorEl}
                    keepMounted
                    open={this.state.openSettings}
                    onClose={this.handleToggleSettings}
                    

                >
                    {this.state.demo!==true ?
                    <MenuItem>
                        Editer
                    </MenuItem>
                    :null}
                    <MenuItem onClick={this.handleToggleDeletePostDialog}>
                        Supprimer
                    </MenuItem>
                </Menu>                        
                <DialogModal
                    open            = {this.state.openDeletePostDialog}
                    secondaryAction = {()=>{
                        this.handleToggleDeletePostDialog();
                        this.handleToggleSettings()
                    }}
                    primaryAction   = {()=>{
                        this.props.handleDelete(this.state.toDelete);
                        this.handleToggleSettings();
                        this.handleToggleDeletePostDialog()
                    }}
                    title           = 'Supprimer ce post' 
                >
                    <Typography variant="body2">Êtes-vous sûr de vouloir supprimer ce post <strong>Cette action est irréversible</strong></Typography>
                </DialogModal>
            </GridCustom>
        );
    }
}

const mapStateToProps = state => {
    return {
        guideline: state.guideline,
    };
};

export default connect(mapStateToProps)(CardFeeds);