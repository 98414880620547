import React from "react";
import colors from '../../../colors';
import Rules from '../../assets/game/components/amazing/Rules';
import RulesInlineFlex from '../../assets/game/components/amazing/RulesInlineFlex';
import DotationsOneImage from '../../assets/game/components/amazing/DotationsOneImage';
import Button from './CustomButton';

const AmazingBlock = ({ data, preview }) => {
    switch (data.key) {
        case 'title': 
            return (
                <div style={{border: !preview ? `1px dashed ${colors.blue.lighter.hue150}` : null}}>
                    <p 
                        className={data.inputs.decoration.value} 
                        key={data.key}
                        data-color={data.inputs.color?.value}
                        style={{
                            fontSize: data.inputs.size?.value,
                            lineHeight: '1.2',
                            color: data.inputs.color?.value,
                            '--tooltip-color': data.inputs.color?.value,
                            backgroundColor: data.inputs.backgroundColor?.value,
                            textTransform: data.inputs.textTransform?.value,
                            textAlign: data.inputs.textAlign?.value,
                            margin: 'auto',
                            marginTop: data.inputs.margin.inputs.marginTop.value,
                            marginBottom: data.inputs.margin.inputs.marginBottom.value,
                            borderStyle: 'solid',
                            borderWidth: data.inputs.border.inputs?.borderWidth?.value ? data.inputs.border.inputs.borderWidth.value : '0px',
                            borderColor: data.inputs.border.inputs?.borderColor?.value ? data.inputs.border.inputs.borderColor.value : null,
                            borderRadius: data.inputs.border.inputs?.borderRadius?.value ? data.inputs.border.inputs.borderRadius.value : null,
                            width: `${data.inputs['width'].value}%`,
                            maxWidth: data.inputs['maxWidth'].value,
                            paddingTop: data.inputs.padding.inputs.paddingTop.value,
                            paddingBottom: data.inputs.padding.inputs.paddingBottom.value,
                        }}
                        dangerouslySetInnerHTML={{ __html: data.inputs.value.value }}
                    />
                </div>
            );
        case 'paragraph': 
            return (
                <div style={{border: !preview ? `1px dashed ${colors.blue.lighter.hue150}` : null}}>
                    <p
                        key={data.key}
                        data-color={data.inputs.color?.value}
                        style={{
                            fontSize: data.inputs.size?.value,
                            lineHeight: '1.2',
                            color: data.inputs.color?.value,
                            '--tooltip-color': data.inputs.color?.value,
                            backgroundColor: data.inputs.backgroundColor?.value,
                            textTransform: data.inputs.textTransform?.value,
                            textAlign: data.inputs.textAlign?.value,
                            margin: 'auto',
                            marginTop: data.inputs.margin.inputs.marginTop.value,
                            marginBottom: data.inputs.margin.inputs.marginBottom.value,
                            width: `${data.inputs['width'].value}%`,
                            maxWidth: data.inputs['maxWidth'].value,
                            paddingTop: data.inputs.padding.inputs.paddingTop.value,
                            paddingBottom: data.inputs.padding.inputs.paddingBottom.value,
                        }}
                        dangerouslySetInnerHTML={{ __html: data.inputs.value.value }}
                    />
                </div>
            );
        case 'rules': 
            return ( <Rules data={data} preview={preview} block={'rules'} /> );
        case 'rules-inline-flex': 
            return ( <RulesInlineFlex data={data} preview={preview} block={'rules'} /> );
        case 'dotations-one-image':
            return ( <DotationsOneImage data={data} preview={preview} block={'dotations'} />)
        case 'button':
            return (
                <button 
                    className={'actionButton'} 
                    style={{
                        fontSize: data.inputs.size.value,
                        color: data.inputs.color.value,
                        backgroundColor: data.inputs.backgroundColor.value,
                        backgroundRepeat: 'no-repeat',
                        lineHeight: '2',
                        borderStyle: 'solid',
                        borderWidth: data.inputs.border?.inputs?.borderWidth?.value ? data.inputs.border.inputs.borderWidth.value : '0px',
                        borderColor: data.inputs.border?.inputs?.borderColor?.value ? data.inputs.border.inputs.borderColor.value : null,
                        borderRadius: data.inputs.border?.inputs?.borderRadius?.value ? data.inputs.border.inputs.borderRadius.value : null,
                        padding: `${data.inputs?.paddingVertical?.value ?? 5}px ${data.inputs?.paddingHorizontal?.value ?? 10}px`,
                        textTransform: data.inputs?.textTransform?.value,
                        fontWeight: 'normal'
                    }}
                >
                    { data.inputs.value.value }
                </button> 
            )
        default: return null;
    }
};

export default AmazingBlock;