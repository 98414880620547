import React from 'react';
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import 'moment/locale/fr';
import { withRouter } from "react-router";
import { matchPath } from "react-router";
import { withStyles } from '@material-ui/core/styles';

import Drawer from '@material-ui/core/Drawer';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';

import iconSet from "../../assets/selection.json";
import IcomoonReact from "icomoon-react";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import styled from 'styled-components';
import colors from '../../config/theme/colors';
import './DrawerLeft.scss';
import { SET_NAVIGATION } from '../../js/constants/action-types';
import setUpDrawer from './drawer.config.js';
import _ from 'lodash';


const isCRM = process.env.REACT_APP_MODE_CRM === 'true';

var drawerWidth = 300;

if(window.innerWidth <= 1600 && window.innerWidth>1400){
    drawerWidth = 240;
}
else if(window.innerWidth <= 1400 && window.innerWidth > 1000){
    drawerWidth = 180;
}
else{
    drawerWidth = 240;
}

const styles = theme => ({
    root: {
        display: 'flex',
        '&>main': {
        },
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: `${drawerWidth}px`,
        flexShrink: 0,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerOpen: {
        width: `${drawerWidth}px`,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        background:isCRM ? colors.crmNavBackground : colors.white,
        border: '0px',
        overflowX: `hidden`,
        marginTop: isCRM ? 0 : 84,
        height: isCRM ?'100%': `calc(100% - 84px)`,
        paddingTop : isCRM ? 120 : 0,
        boxShadow: `3px 0px 22px ${colors.grey.lighter.hue800}`,
        ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
            background: 'rgba(0,0,0,0)',
            boxShadow: 'initial',
            marginTop: isCRM ? 0 : 64,
            height: isCRM ?'100%': `calc(100% - 64px)`,
        }
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        background: isCRM ? colors.crmNavBackground : colors.white,
        border: '0px',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(0),
        },
        marginTop: isCRM ? 0 : 84,
        height: isCRM ?'100%': `calc(100% - 84px)`,
        paddingTop : isCRM ? 120 : 0,
        boxShadow: `3px 0px 22px ${colors.grey.lighter.hue800}`,
        '& .MuiAccordionSummary-expandIcon': {
            display: 'none',
        },
        '& .MuiListItemText-root': {
            display: 'none',
        },
        '& p': {
            whiteSpace: 'pre-wrap',
            textAlign: 'center',
            width: '100%',
            fontSize: 13
        },
        '& .activePanel':{
            borderLeft:'none!important'
        },
        ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
            background: 'rgba(0,0,0,0)',
            boxShadow: 'initial',
            marginTop: isCRM ? 0 : 64,
            height: isCRM ?'100%': `calc(100% - 64px)`,
        }
    },
    itemOpen: {
        padding: `0.5rem 1.5rem 0.5rem 2.5rem`,
    },
    itemClose: {
        padding: `0.5rem 1rem 0.5rem 2.2rem`,
        width: `auto`,
    },
    itemTextOpen: {
        display: `block`,
    },
    itemTextClose: {
        display: `none`,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
});

const AccordionCustom = styled(Accordion)`
    box-shadow: none;
    margin-bottom: 0 !important;
    margin-top: 0.5rem !important;
    background: transparent;
    &:before{
        display: none;
    }
    @media screen and (max-width: 1400px){
        margin-top: 0.3rem !important;
    }
`;

const AccordionSummaryCustom = styled(AccordionSummary)`
    padding: 0 15px;
    &> .MuiAccordionSummary-content{
        margin: 15px 0;
    }
    &.Mui-expanded{
        min-height: inherit;
    }
    svg{
        color: ${isCRM ? colors.white : colors.blue.regular};
    }
    @media screen and (max-width: 1400px){
        padding: 0 10px;
        &> .MuiAccordionSummary-content{
            margin: 10px 0;
        }
    }
`;

const AccordionDetailsCustom = styled(AccordionDetails)`
    padding: 0;
`;

const ListCustom = styled(List)`
    width: 100%;
    padding: 0;
`;

const ListItemCustom = styled(ListItem)`
    &:hover{
        // background-color: rgba(0,0,0,0.1);
        background-color: ${isCRM ? `#a6cde3`: colors.blue.lighter.hue900};

    }
    @media screen and (max-width: 1400px){
        padding-left: 16px!important;
        max-width: 100%;
    }
`;

const TypographyCustom = styled(Typography)`
    color: ${isCRM ? colors.white : colors.blue.regular};
`;

const ListItemTextCustom = styled(ListItemText)`
    span{
        color: ${isCRM ? colors.white : colors.blue.regular};
        font-size: 15px;
        @media screen and (max-width: 1400px){
            font-size: 13px;
            line-height: 18px;
        }
    }
`;

const ListItemIconCustom = styled(ListItemIcon)`
    svg{
        color: ${colors.white};
        border-radius: 5px;
        border: ${isCRM ? `none`:`1px solid ${colors.blue.regular}`};
        background: ${isCRM ? `none` : `white`};
        ${ isCRM ?
        `path{
            fill : ${colors.white} !important
        }`
        :null}
    }
    @media screen and (max-width: 1400px){
        min-width: 45px;
    }
`;

const DrawerCustom = styled(Drawer)`
    width: ${props => props.drawerwidth ? props.drawerwidth : null}px;
    transition: all 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    box-shadow: 3px 0px 22px #d4d9dc;
    &#closed{
        @media screen and (max-width: 1400px){
            .MuiListItem-gutters{
                padding-left: 30px!important;
            }
        }
    }
    .MuiDrawer-paper{
        width: ${props => props.drawerwidth ? `${props.drawerwidth}px` : null};
    }
    .MuiListItem-root{
        width: ${props => props.drawerwidth ? `${props.drawerwidth}px` : null};
    }
`;

const SwipeableDrawerCustom = styled(SwipeableDrawer)`
    .MuiPaper-root{
        position: relative;
        width: calc(100% - 2px);
    }
`;

const useStylesBootstrap = makeStyles(theme => ({
    arrow: {
        color: colors.blue.regular,
    },
    tooltip: {
        backgroundColor: colors.blue.regular,
        fontSize: 14,
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

class DrawerLeft extends React.Component {
    state = {
        expanded: null,
        expandedSub: null,
        menuItems: null,
    };

    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };

    handleChangeSub = panel => (event, expandedSub) => {
        this.setState({
            expandedSub: expandedSub ? panel : false,
        });
    };

    goTo = route => {
        this.props.history.push(route);
    };

    checkPath = (path) => {
        let pathFound = false;
        for(let item of this.state.menuItems){
            if(item.allRoutes.indexOf(path)> -1 || (!item.isHome && path.includes(item.route)))  {
                pathFound = true
                this.setState({
                    expanded: item.route
                })
            }
        }if(!pathFound){
            this.setState({
                expanded: null
            })
        }
    };

    isPathChildren = (path,childrens) =>{
        for(let children of childrens){
            const match = matchPath(path, {
                path: children,
                exact: true,
                strict: false
            });
            if(match){
                return true;
            }          
        }
        return false;
    };

    componentDidUpdate(prevProps){
        if(prevProps.location.pathname !== this.props.location.pathname){
            this.checkPath(this.props.location.pathname)
            this.setState({
                menuItems : setUpDrawer(isCRM)
            })
        }
    };

    componentDidMount(){
        this.setState({
            menuItems : setUpDrawer(isCRM)
        }, () => {
            this.props.setNavigation(this.props.location.pathname)
            this.checkPath(this.props.location.pathname)
        })
    };

    render(){
        const { props } = this;
        const { expanded, menuItems, expandedSub } = this.state;
        const { classes } = this.props;
        if(props.windowWidth <= 1000){
            return(
                <SwipeableDrawerCustom
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: props.opened,
                        [classes.drawerClose]: !props.opened,
                    })}
                    open={props.opened}
                    style={{zIndex: 100}}
                    onClose={() => {return null}}
                    onOpen={() => {return null}}
                >
                    { menuItems ? 
                        menuItems.map((item, index) => {
                            if (!item.childrens.every(_.isNull)){
                                if (!item.notAccordion){
                                    return(
                                        <AccordionCustom
                                            expanded={expanded === item.route}
                                            onChange={this.handleChange(item.route)} 
                                            TransitionProps={{ unmountOnExit: true }}
                                            key={`MenuItem${index}`}
                                        >
                                            <AccordionSummaryCustom
                                                expandIcon={<ExpandMoreIcon />}
                                                className={ item.allRoutes.indexOf(props.location.pathname) > -1 || (!item.isHome && props.location.pathname.includes(item.route)) ? 'activePanel' : ''}
                                            >
                                                <TypographyCustom variant="body2">{item.name}</TypographyCustom>
                                            </AccordionSummaryCustom>
                                            <AccordionDetailsCustom>
                                                <ListCustom>
                                                {
                                                    item.childrens.map((children,index) =>{
                                                        if (children !== null){
                                                            return(
                                                                <BootstrapTooltip 
                                                                    title={children.name} 
                                                                    placement="right" 
                                                                    disableHoverListener={props.opened ? true : false} 
                                                                    className={clsx(classes.tooltip)} 
                                                                    key={`ChildItem${index}`}
                                                                >
                                                                    <ListItemCustom button onClick={() => {this.goTo(children.route); props.openCallback()}} className={
                                                                        clsx(classes.drawer, {
                                                                            [classes.itemOpen]: props.opened,
                                                                            [classes.itemClose]: !props.opened,
                                                                        },
                                                                        props.location.pathname === children.route || (children.childrens?this.isPathChildren(props.location.pathname,children.childrens):false)? 'activeNav' : '')
                                                                    }>
                                                                        <ListItemIconCustom>
                                                                            <IcomoonReact iconSet={iconSet} color={colors.blue.regular} size={30} icon={children.icone} />
                                                                        </ListItemIconCustom>
                                                                        <ListItemTextCustom primary={children.name} />
                                                                    </ListItemCustom>
                                                                </BootstrapTooltip>
                                                            )
                                                        }else{
                                                            return null
                                                        }
                                                    })
                                                }
                                                </ListCustom>
                                            </AccordionDetailsCustom>
                                        </AccordionCustom>
                                    )
                                }else{
                                    return(
                                        <AccordionCustom
                                            TransitionProps={{ unmountOnExit: true }}
                                            key={`MenuItem${index}`}
                                            onClick={() => {this.goTo(item.route)}}
                                        >
                                            <AccordionSummaryCustom
                                                className={ item.allRoutes.indexOf(props.location.pathname) > -1 || (!item.isHome && props.location.pathname.includes(item.route)) ? 'activePanel' : ''}
                                            >
                                                <TypographyCustom variant="body2">{item.name}</TypographyCustom>
                                            </AccordionSummaryCustom>
                                        </AccordionCustom>
                                    )
                                }
                            }else{
                                return null
                            }
                        })
                    : null } 
                </SwipeableDrawerCustom>
            )
        }
        else{
            return(
                <DrawerCustom
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: props.opened,
                        [classes.drawerClose]: !props.opened,
                    })}
                    id={!props.opened ? 'closed': ''}
                    variant={'permanent'}
                    drawerwidth={props.drawerWidth}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: props.opened,
                            [classes.drawerClose]: !props.opened,
                        }),
                    }}
                    style={{width: props.drawerWidth}}
                >
                    { menuItems ? 
                        menuItems.map((item, index) => {
                            // console.log('item', item)
                            if (!item.childrens.every(_.isNull)){
                                if (!item.notAccordion){
                                    return(
                                        <AccordionCustom
                                            expanded={expanded === item.route}
                                            onChange={this.handleChange(item.route)} 
                                            TransitionProps={{ unmountOnExit: true }}
                                            key={`MenuItem${index}`}
                                        >
                                            <AccordionSummaryCustom
                                                expandIcon={<ExpandMoreIcon />}
                                                className={ item.allRoutes.indexOf(props.location.pathname) > -1 || (!item.isHome && props.location.pathname.includes(item.route)) ? 'activePanel' : ''}
                                            >
                                                <TypographyCustom variant="body2">{item.name}</TypographyCustom>
                                            </AccordionSummaryCustom>
                                            <AccordionDetailsCustom>
                                                <ListCustom>
                                                {
                                                    item.childrens.map((children,index) =>{
                                                        // console.log('children', children)
                                                        if (children !== null){
                                                            // console.log('children', children.isAccordion)
                                                            if (children.isAccordion){
                                                                return(
                                                                    <AccordionCustom
                                                                        expanded={expandedSub === children.route}
                                                                        onChange={this.handleChangeSub(children.route)} 
                                                                        TransitionProps={{ unmountOnExit: true }}
                                                                        key={`MenuItem${index}`}
                                                                    >
                                                                        <AccordionSummaryCustom
                                                                            expandIcon={<ExpandMoreIcon />}
                                                                            className={ children.allRoutes.indexOf(props.location.pathname) > -1 || (!children.isHome && props.location.pathname.includes(children.route)) ? 'activePanel' : ''}
                                                                        >
                                                                            <TypographyCustom variant="body2">{children.name}</TypographyCustom>
                                                                        </AccordionSummaryCustom>
                                                                        <AccordionDetailsCustom>
                                                                            <ListCustom>
                                                                                {
                                                                                    children.childrens.map((childrenSub,index) =>{
                                                                                        return(
                                                                                            <BootstrapTooltip 
                                                                                            title={childrenSub.name} 
                                                                                            placement="right" 
                                                                                            disableHoverListener={props.opened ? true : false} 
                                                                                            className={clsx(classes.tooltip)} 
                                                                                            key={`ChildItem${index}`}
                                                                                            >
                                                                                                <ListItemCustom button onClick={() => {this.goTo(childrenSub.route)}} className={
                                                                                                    clsx(classes.drawer, {
                                                                                                        [classes.itemOpen]: props.opened,
                                                                                                        [classes.itemClose]: !props.opened,
                                                                                                    },
                                                                                                    props.location.pathname === childrenSub.route || (childrenSub.childrens?this.isPathChildren(props.location.pathname,childrenSub.childrens):false)? 'activeNav' : '')
                                                                                                }>
                                                                                                    <ListItemIconCustom>
                                                                                                        <IcomoonReact iconSet={iconSet} color={colors.blue.regular} size={30} icon={childrenSub.icone} />
                                                                                                    </ListItemIconCustom>
                                                                                                    <ListItemTextCustom primary={childrenSub.name} />
                                                                                                </ListItemCustom>
                                                                                            </BootstrapTooltip>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </ListCustom>
                                                                        </AccordionDetailsCustom>
                                                                    </AccordionCustom>
                                                                )
                                                            }else{
                                                                return(
                                                                    <BootstrapTooltip 
                                                                    title={children.name} 
                                                                    placement="right" 
                                                                    disableHoverListener={props.opened ? true : false} 
                                                                    className={clsx(classes.tooltip)} 
                                                                    key={`ChildItem${index}`}
                                                                    >
                                                                        <ListItemCustom button onClick={() => {this.goTo(children.route)}} className={
                                                                            clsx(classes.drawer, {
                                                                                [classes.itemOpen]: props.opened,
                                                                                [classes.itemClose]: !props.opened,
                                                                            },
                                                                            props.location.pathname === children.route || (children.childrens?this.isPathChildren(props.location.pathname,children.childrens):false)? 'activeNav' : '')
                                                                        }>
                                                                            <ListItemIconCustom>
                                                                                <IcomoonReact iconSet={iconSet} color={colors.blue.regular} size={30} icon={children.icone} />
                                                                            </ListItemIconCustom>
                                                                            <ListItemTextCustom primary={children.name} />
                                                                        </ListItemCustom>
                                                                    </BootstrapTooltip>
                                                                )
                                                            }

                                                        }else{
                                                            return null
                                                        }
                                                    })
                                                }
                                                </ListCustom>
                                            </AccordionDetailsCustom>
                                        </AccordionCustom>
                                    )
                                }else{
                                    return(
                                        <AccordionCustom
                                            TransitionProps={{ unmountOnExit: true }}
                                            key={`MenuItem${index}`}
                                            onClick={() => {this.goTo(item.route)}}
                                        >
                                            <AccordionSummaryCustom
                                                className={ item.allRoutes.indexOf(props.location.pathname) > -1 || (!item.isHome && props.location.pathname.includes(item.route)) ? 'activePanel' : ''}
                                            >
                                                <TypographyCustom variant="body2">{item.name}</TypographyCustom>
                                            </AccordionSummaryCustom>
                                        </AccordionCustom>
                                    )
                                }
                            }else{
                                return null
                            }
                        })
                    : null } 
                </DrawerCustom>
            );
        }
    };
}

const mapDispatchToProps = dispatch => {
    return {
        setNavigation: (navigation) => dispatch({ type: SET_NAVIGATION, payload: { navigation }}),
    }
}

const mapStateToProps = state => {
    return {
        navigation: state.navigation,
    };
};

export default withRouter(withStyles(styles)(connect(mapStateToProps,mapDispatchToProps)(DrawerLeft)));