import { gql } from 'apollo-boost';

//----- Récupération des produits


export const GET_CUSTOMERS_TOTAL = gql`
{
  customers{
    totalCount
  }
}
`;

export const SEARCH_CUSTOMERS = gql`
query customerDataSearch ($attribute: String, $needle: String) {
  customerDatas(attribute: $attribute, value: $needle) {
    totalCount
    edges {
      node {
        id
        value
        customer{
          id
          sku
          status
          companies {
            edges {
              node {
                id
                libelle
                companyDatas {
                  edges {
                    node {
                      value
                      attribute {
                        identifier
                      }
                      media {
                        filePath
                      }
                      locale {
                        code
                      }
                    }
                  }
                }
              }
            }
          }
          customerDatas {
            edges {
              node {
                value
                id
                attributeOption {
                  id
                  identifier
                  translation {
                    translationDatas {
                      edges {
                        node {
                          locale {
                            id
                            code
                          }
                          value
                        }
                      }
                    }
                  }
                }
                attribute {
                  id
                  identifier
                  isSystem
                  translation {
                    id
                    translationDatas {
                      edges {
                        node {
                          id
                          value
                          locale {
                            id
                            code
                          }
                        }
                      }
                    }
                  }
                  attributeType {
                    id
                    input
                  }
                }
                media {
                  id
                  filePath
                  type
                  size
                  category {
                    edges {
                      node {
                        id
                        libelle
                      }
                    }
                  }
                }
                locale {
                  id
                  code
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

//----- Récupération des produits pour le sélecteur
export const GET_SELECT_CUSTOMERS = gql`
{
  customers(first: 50){
    edges{
      node{
        id
        companies{
          edges{
            node{
              id
              libelle
              companyDatas{
                edges{
                  node{
                    value
                    attribute{
                      identifier
                    }
                    media{
                      filePath
                    }
                    locale{
                      code
                    }
                  }
                }
              }
            }
          }
        }
        customerDatas{
          edges{
            node{
              value
              attribute{
                identifier
              }
              media{
                filePath
              }
              locale{
                code
              }
            }
          }
        }
      }
    }
  }
}
`;

//----- Récupération des données d'un client
/*
Exemple de variables
  {
    "id" : "/api/customers/1"
  }
*/
export const GET_CUSTOMER_DATA = gql`
query customerDatas($id: ID!) {
  customer(id: $id) {
    id
    sku
    status
    attributeGroup{
      id
    }
    companies{
      edges{
        node{
          id
          libelle
          companyDatas{
            edges{
              node{
                id
                value
                attribute{
                  id
                  identifier
                  attributeType {
                    id
                    input
                  }
                }
                locale{
                  id
                  code
                }
              }
            }
          }
        }
      }
    }
    customerDatas{
      edges{
        node{
          value
          id
          attributeOption{
            id
            identifier
            translation{
              translationDatas{
                edges{
                  node{
                    locale{
                      id
                      code
                    }
                    value
                  }
                }
              }
            }
          }
          attribute{
            id
            identifier
            isSystem
            translation{
              id
              translationDatas{
                edges{
                  node{
                    id
                    value
                    locale{
                      id
                      code
                    }
                  }
                }
              }
            }
            attributeType {
              id
              input
            }
          }
          media{
            id
            filePath
            type
            size
            category{
              edges{
                node{
                  id
                  libelle
                }
              }
            }
          }
          locale{
            id
            code
          }
        }
      }
    }
  }
}
`;

//----- Ajouter un client
/*
Exemple de variables
  {
    "sku" : "Produit de démo",
    "attributeGroup" : "/api/customer_attribute_groups/1",
    "companies": [
      "/api/companies/5",
      "/api/companies/6"
    ]
  }
*/
export const ADD_CUSTOMER = gql`
mutation AddCustomer($sku: String, $attributeGroup: String!,  $companies: [String], $createdAt: String!, $updatedAt: String!, $status: String){
	createCustomer(input: {sku:$sku, attributeGroup:$attributeGroup, companies:$companies, createdAt:$createdAt, updatedAt:$updatedAt, status:$status}){
    customer{
      id
      status
      attributeGroup{
        id
      }
      companies {
        edges{
          node{
            id
          }
        }
      }
    }
  }  
}`;
  
//----- Modifier un client
/*
Exemple de variables
  {
    "id" : "/api/customers/14",
    "attributeGroup" : "/api/customer_attribute_groups/1",
    "companies": [
      "/api/companies/5",
      "/api/companies/6",
      "/api/companies/11"
    ]
  }
*/
export const UPDATE_CUSTOMER = gql`
mutation UpdateCustomer($id: ID!, $sku: String, $attributeGroup: String,  $companies: [String], $status: String){
	updateCustomer(input: {id: $id, sku:$sku, attributeGroup:$attributeGroup, companies:$companies, status:$status}){
    customer{
      id
      sku
      attributeGroup{
        id
      }
      companies {
        edges{
          node{
            id
          }
        }
      }
    }
  }  
}`;

//----- Supprimer un client
/*
Exemple de variables
  {
    "id" : "/api/customers/1"
  }
*/
export const DELETE_CUSTOMER = gql`
mutation DeleteCustomer($id: ID!){
	deleteCustomer(input: {id:$id}){
    customer{
      id
    }
  }  
}`;


//--------------------
//----- DONNEES PRODUITS 
//--------------------

//----- Ajouter une valeur d'attribut à un client

/*
Exemple de variables
{
  "value": "TEST",
	"customer": "/api/customers/1",
  "attribute": "/api/attributes/12", 
	"locale": "/api/locales/2"
}
*/
export const ADD_CUSTOMER_DATA = gql`
mutation AddCustomerData($value: String, $customer: String!, $attribute: String!, $locale: String, $attributeOption: String, $media: String){
	createCustomerData(input: {value:$value, customer:$customer, attribute:$attribute, locale:$locale, attributeOption:$attributeOption, media:$media}){
    customerData{
      id
      value
      customer{
        id
      }
      attribute{
        id
      }
      locale{
        id
      }
      media{
        id
      }
      attributeOption{
        id
      }
    }
  }  
}`;


//----- Modifier une valeur d'attribut d'un client
/*
Exemple de variables
{
	"id": "/api/customer-datas/68",
  "value": "TEST edition",
	"customer": "/api/customers/1",
  "attribute": "/api/attributes/12", 
	"locale": "/api/locales/2"
}
*/
export const UPDATE_CUSTOMER_DATA = gql`
mutation UpdateCustomerData($id: ID!, $value: String, $customer: String!, $attribute: String!, $locale: String, $attributeOption: String, $media: String){
	updateCustomerData(input: {id:$id, value:$value, customer:$customer, attribute:$attribute, locale:$locale, attributeOption:$attributeOption, media:$media}){
    customerData{
      id
      value
      customer{
        id
      }
      attribute{
        id
      }
      locale{
        id
      }
      media{
        id
      }
      attributeOption{
        id
      }
    }
  }  
}`;

//----- Supprimer une valeur d'attribut d'un client
/*
Exemple de variables
  {
    "id" : "/api/customer-datas/68"
  }
*/
export const DELETE_CUSTOMER_DATA = gql`
mutation DeleteCustomerData($id: ID!){
	deleteCustomerData(input: {id:$id}){
    customerData{
      id
    }
  }  
}`;

/*
  Exemple de variables

  {
    "status":"lead"
  }
*/
export const GET_CUSTOMERS = gql`  
query getCustomer($id:Int, $companies:String, $status:String,$nbperpage: Int, $cursor: String,$id_list:[Int]) {
  customers(first: $nbperpage, after: $cursor,id:$id, companies:$companies, status:$status,id_list:$id_list){
    totalCount
    edges{
      node{
        id
        sku
        status
        companies{
          edges{
            node{
          		libelle
              id
              companyDatas{
                edges{
                  node{
                    id
                    value
                    attribute{
                      id
                      identifier
                      attributeType {
                        id
                        input
                      }
                    }
                    media{
                      id
                      filePath
                      type
                      size
                      category{
                        edges{
                          node{
                            id
                            libelle
                          }
                        }
                      }
                    }
                    locale{
                      id
                      code
                    }
                  }
                }
              }
            }
          }
        }
        customerDatas{
          edges{
            node{
              value
              id
              attributeOption{
                id
                identifier
                translation{
                  translationDatas{
                    edges{
                      node{
                        locale{
                          id
                          code
                        }
                        value
                      }
                    }
                  }
                }
              }
              attribute{
                id
                identifier
                isSystem
                translation{
                  id
                  translationDatas{
                    edges{
                      node{
                        id
                        value
                        locale{
                          id
                          code
                        }
                      }
                    }
                  }
                }
                attributeType {
                  id
                  input
                }
              }
              media{
                id
                filePath
                type
                size
                category{
                  edges{
                    node{
                      id
                      libelle
                    }
                  }
                }
              }
              locale{
                id
                code
              }
            }
          }
        }
      }
    }
  }
}`;

export const GET_CUSTOMERS_COUNT_ONLY = gql`  
query getCustomer($id:Int, $companies:String, $status:String) {
  customers(id:$id, companies:$companies, status:$status){
    totalCount
  }
}`;

export const GET_CUSTOMERS_CURSOR = gql`
{
  customers{
    totalCount
    edges{
      cursor
    }
  }
}`;


export const GET_CUSTOMERS_COMPANIES = gql`
{
  customers{
    edges{
      node{
        id
        status
        companies{
          edges{
            node{
              id
            }
          }
        }
      }
    }
  }
}
`;