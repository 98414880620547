import React from 'react';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { withApollo } from 'react-apollo';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import CardCustom from '../../../layouts/Card/CardCustom';
import CardContactDetails from '../../../layouts/Card/cardContent/CardContactDetails';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import * as moment from "moment";
import { Grid } from '@material-ui/core';
import InputBuilder from '../../../ui/form/InputBuilder';
import { ROUTE_CRM_CONTACTS_LIST, ROUTE_CRM_CONTACTS_CLIENTS, ROUTE_CRM_CONTACTS_LEADS, ROUTE_HOME} from '../../../../js/constants/route-names';
import { eventService } from '../../../../js/services/event.service';
import { GET_ATTR_GROUPE_WITH_ATTRIBUTES } from '../../../../queries/attributes';
import { SEARCH_COMPANIES, GET_COMPANIES_CURSOR, GET_COMPANIES_PAGINATION} from '../../../../queries/companies';
import {GET_CUSTOMERS_COMPANIES,ADD_CUSTOMER,UPDATE_CUSTOMER,ADD_CUSTOMER_DATA,UPDATE_CUSTOMER_DATA,GET_CUSTOMERS, DELETE_CUSTOMER} from '../../../../queries/customers';
import contactAdd from './config/contactAdd.config';
import DialogModal from '../../../ui/dialog/DialogModal';
import Typography from '../../../ui/typography/Typography';
import { hasRights } from '../../../../js/utils/rights';
import { CRM_CONTACT, CRM_CONTACT_CLIENTS, CRM_CONTACT_CONTACTS, CRM_CONTACT_LEADS, CREATE, UPDATE, DELETE, VIEW} from '../../../../js/constants/constant-rights';

class ContactAdd extends React.Component{
    constructor(props){
        super(props)
        this.goBack.bind(this);
        this.state={
            currentLang: props.locales[0].node.code,
            customerAddForm : false,
            errors:{},
            companies:{
                current : [],
                toAdd:[],
                toRemove : [],
            },
            nbperPageCompanies: 6,
            customerStatus : props.location.state.typeContact ? props.location.state.typeContact:'contact', 
            contactId : props.location.state.contactId ? props.location.state.contactId : null,
            pictoContact: "icon-contacts",
            edit : props.edit ? props.edit:false,
            formSettings:{
                page:1,
                count:1,
            }
        }
    }

    handleLang = (event) => {
        this.setState({ currentLang: event.target.value });
        this.forceUpdate();
    };

    handleNextStep = () =>{
        if (this.hasErrors()) {
            this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
            this.setState({ seeErrors: true });
            eventService.fire();
            return false;
        }

        return true;
    }

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;

        errors[stateName] = error;

        this.setState({ errors });
    };

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }

        return false;
    };

    handleToggleDrawer = (stateDrawer,reset=false) => {
        this.setState({ 
            [stateDrawer]: !this.state[stateDrawer]
        });

        if(reset){
            this.resetState()
        }
    };

    handleToggleModal = (stateModal)=>{
            this.setState({ 
                [stateModal]: !this.state[stateModal]
            });
        }

    handleDeleteCustomer = ()=>{
        let customer = this.state.currentCustomer.node.id;
        let status = this.state.customerStatus;
        this.props.startLoading();
        this.props.client.mutate({
            mutation : DELETE_CUSTOMER,
            variables : {id : customer}
        }).then(result=>{
            this.props.stopLoading();
            let route;
            switch(status){
                case 'client': 
                    route = ROUTE_CRM_CONTACTS_CLIENTS;
                
                break;
                case 'lead': 
                    route = ROUTE_CRM_CONTACTS_LEADS;
                
                break;
                case 'contact': 
                    route =ROUTE_CRM_CONTACTS_LIST;
                
                break;
                default:
                    return null;
            }
            this.props.history.push({
                pathname : route,
                state: {hasMessage:true,message : 'Contact supprimé'}
            });
        })
    }

    handleMediaPicker=(selected,stateName,translated)=>{
        this.handleInputChange(stateName,selected,null,translated);  
    }
    handleInputChange = (stateName, evt, custom, translated ) => {
        const value = evt?.target?.value ?? evt;
        this.setValue(stateName, value,translated);
    };
    setValue = (stateName, value, translated) => {

        if (translated) {
            let values = this.state[this.state.currentLang];

            if (!values) {
                values = {};
            }
            values[stateName] = value;
            this.setState({
                [this.state.currentLang]: values,
            });               
            
        } else {
            this.setState({
                [stateName]: value,
            });
        }
    };

    getCustomers(){
        return new Promise((resolve,reject)=>{
            this.props.client.mutate({
                mutation: GET_CUSTOMERS_COMPANIES,
                fetchPolicy: 'no-cache'
            }).then(result=>{
                let customersCompanies=result.data.customers.edges.map((customer)=>{
                    let companysId = customer.node.companies.edges.map((company)=>{
                        return company.node.id;
                    })
                    return {...customer,companysId}
                })
                resolve(customersCompanies);
            })
        })
    }
    handleChangeCompanies = (e,value)=>{
        this.setState({
            currentPage : 1,
            allCompanies : null,
        })
        clearTimeout(this.typingSearchTimer);
        if(e?.target?.value){
            this.setState({searchValueCompanies: e.target.value});
            this.typingSearchTimer = setTimeout(() => {
                this.handleSearchCompanies();
            }, 500);
        } 
        else{
            this.setState({activeSearchCompanies:false},()=>this.handleGetCompanies())
        }
    }

    handleSearchCompanies = () =>{
        let value = this.state.searchValueCompanies;
        if (value !== "") {
            this.props.client.query({
                query: SEARCH_COMPANIES,
                variables: {"needle": value,"attribute":null},
                fetchPolicy: 'no-cache'
            }).then(result =>{
                let companies = result.data.companyDatas.edges.map(
                    e=>{
                        return parseInt(e.node.company.id.replace('/api/companies/',''),10)
                    }
                );
                let listID=[];
                let uniqueCompanies = companies.filter(company=>{
                    if(!listID.includes(company)){
                        listID.push(company);
                        return company;
                    }else{
                        return company
                    }
                })
                this.setState({
                    activeSearchCompanies :uniqueCompanies
                },()=>this.handleGetCompanies())
            })
        }
    }

    handleGetCompaniesCursors  = ()=>{
        this.props.client.query({
            query: GET_COMPANIES_CURSOR,
            fetchPolicy: 'no-cache'
        }).then(result => {
            this.setState({
                listCursors: result.data.companies.edges
            }, () => {
                this.handleGetCompanies();
            });
        });
    }
    
    handleGetCompanies = async()=>{
        let variables = {
            nbperpage : this.state.nbperPageCompanies
        }
        if(this.state.cursor && this.state.listCursors !== this.state.listCursors[0].cursor){

            variables.cursor = this.state.cursor;
        }
        if(this.state.activeSearchCompanies){
            variables.id_list = this.state.activeSearchCompanies
        }
        console.log('handleGetCompanies',variables)
        this.props.client.query({
            query: GET_COMPANIES_PAGINATION,
            variables : variables,
            fetchPolicy: 'no-cache'
        }).then(result => {
            let formSettings = this.state.formSettings;
            let companies = result.data.companies.edges;
            formSettings.count = Math.ceil(result.data.companies.totalCount / this.state.nbperPageCompanies);
            this.setState({
                allCompanies : companies,
                formSettings : formSettings
            })
        })
    }
    changePage = (event, page) => {
        // this.props.startLoading();
        
        let index = 0;
        
        if(page > 1){
            index = ( page * this.state.nbperPageCompanies ) - this.state.nbperPageCompanies - 1
            console.log('indexe',index)
            console.log('cursors',this.state.listCursors)

        }
        let formSettings = this.state.formSettings;
        formSettings.page = page
        this.setState({
            allCompanies:null,
            cursor: this.state.listCursors[index].cursor,
            formSettings : formSettings
        },()=>{
            this.handleGetCompanies();
        });
    };

    prepareAttributes = ()=>{
        return new Promise(async (resolve, reject) => {
            let isSystemAttributes  = this.props.attributes.customer.attributes.edges.filter(e => e.node.isSystem);
            this.setState({
                isSystemAttributes,
                customAttributes : this.props.attributes.customer.attributes.edges,
            },()=>{
                resolve();
            })
        })
    }
    handleSelectCompany = (checked,company,currentAction='add') =>{
        let companies = this.state.companies
        if(checked){
            companies.toAdd.push(company)
        }
        else{
            let index = companies.toAdd.indexOf(company);
            companies.toAdd.splice(index,1)
        }
        this.setState({
            companies : companies
        })
    }

    handleGetCurrentCustomer = ()=>{
        return new Promise((resolve,reject)=>{
            let contactId = parseInt(this.state.contactId.replace('/api/customers/',''),10);
            this.props.client.mutate({
                mutation: GET_CUSTOMERS,
                fetchPolicy: 'no-cache',
                variables : {id:contactId}
            }).then(result=>{
                let currentCustomer = result.data.customers.edges[0]
                let currentCompanies = currentCustomer.node.companies.edges.map(company => company.node.id);
                let companies = this.state.companies;
                companies.toAdd = companies.current = currentCompanies;
                this.setState({
                    currentCustomer : currentCustomer,
                    customerStatus : currentCustomer.node.status,
                    companies : companies,
                    customerEmail : currentCustomer.node.sku
                })
                resolve()
            })
        })
    }

    initForm = async()=>{
        if(this.state.edit){
            await this.handleGetCurrentCustomer()
        }else{
            const getRights = hasRights(CRM_CONTACT, this.state.accessType, CREATE)
            if (!getRights){
                this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
                this.goTo(ROUTE_HOME);
            }
        }
        await this.prepareAttributes();
        await this.prepareAttributeValues();
        this.setState({ isReady : true });

        if(!this.state.edit){
            this.handleToggleDrawer('contactAddForm');
        }

        let formSettings = this.state.formSettings;

        formSettings.changePage = this.changePage;
        formSettings.handleSearch = this.handleChangeCompanies;

        this.handleGetCompaniesCursors();
        this.props.stopLoading();
    }


    getTraductionAttributs(attributeIdentifier, data, lang, type) {
        if (!data.length) return null;
    
        let nameAttributes  = data.filter(e => e.node.attribute.identifier === attributeIdentifier);
        let getTraduction   = nameAttributes.find(e => e.node.locale.code === lang);

        return type !== 'image'
            ? getTraduction?.node.value?
                {
                    value :getTraduction?.node.value,
                    id:getTraduction?.node.id
                } 
            : null
            : getTraduction?.node.media?
                {
                    value :getTraduction?.node.media,
                    id:getTraduction?.node.id
                } 
            : null;
    };

    
    prepareAttributeValues() {
        return new Promise((resolve,reject)=>{
            for (let locale of this.props.locales) {
                let values = {};
                for (let attribute of this.state.customAttributes) {
                    if (attribute.node.attributeType.input === 'select') {
                        if (attribute.node.attributeOptions.edges.length){
                            values[attribute.node.identifier] = attribute.node.attributeOptions.edges[0].node.id
                        }
                    }
                    if(this.state.edit){
                        let translated = this.getTraductionAttributs(attribute.node.identifier,this.state.currentCustomer.node.customerDatas.edges,locale.node.code,attribute.node.attributeType.input)
                        if(translated){
                            values[attribute.node.identifier]=translated.value;
                        }
                    }
                }       
                
                this.setState({
                    [locale.node.code]: {...values},
    
                });
            }
            resolve()
        })

    }
    getAttributesGroup = (type) =>{
        return new Promise((resolve,reject)=>{
            this.props.client.query({
                query: GET_ATTR_GROUPE_WITH_ATTRIBUTES,
                fetchPolicy: "no-cache",
            }).then(result=>{     
                let attrGroup = result.data.attributeGroups.edges.find(group => group.node.identifier === type)?.node.id;
                console.log(result.data.attributeGroups.edges)
                resolve(attrGroup);
            })
        })
    }
    handleMutation= async() =>{
        this.props.startLoading();
        let attributesGroup = await this.getAttributesGroup('customer');
        let variables = {
            attributeGroup : attributesGroup,
            status : this.state.customerStatus,
            sku : this.state.customerEmail,
            createdAt: moment().format('YYYY-MM-DD'),
            updatedAt: moment().format('YYYY-MM-DD'),
            companies : this.state.companies.toAdd

        }
        if(this.state.edit){
            variables.id = this.state.contactId;
            let UPDATE_CONTACT_RESULT = await this.props.client.mutate({
                mutation : UPDATE_CUSTOMER,
                fetchPolicy: "no-cache",
                variables : variables,
            })

            await this.saveAttributes(UPDATE_CONTACT_RESULT.data.updateCustomer.customer.id);        
        }
        else{
            let ADD_CONTACT_RESULT = await this.props.client.mutate({
                mutation : ADD_CUSTOMER,
                fetchPolicy: "no-cache",
                variables : variables,
            })

            await this.saveAttributes(ADD_CONTACT_RESULT.data.createCustomer.customer.id);            
        }

        this.props.snack(ALERT_SUCCESS, 'Contact ajouté !');

        this.props.stopLoading()

        let content;
        switch(variables.status){
            case 'client': content = {
                route: this.goTo(ROUTE_CRM_CONTACTS_CLIENTS)
            }
            break;
            case 'lead': content = {
                route: this.goTo(ROUTE_CRM_CONTACTS_LEADS)
            }
            break;
            case 'contact': content = {
                route: this.goTo(ROUTE_CRM_CONTACTS_LIST)
            }
            break;
            default:
                return null;
        }
        this.setState({
            content: content,
        });
    }

    goBack(){
        this.props.history.goBack();
    }

    saveAttributes(company){

        return new Promise(async (resolve, reject) => {
            let attributes = this.state.isSystemAttributes;
            let getProductData = [];
            for (let attribute of attributes) {
                for (let locale of this.props.locales) { 
                    let formValue   = this.state[locale.node.code][attribute.node.identifier];
                    let isMedia     = attribute.node.attributeType.input === 'image' || attribute.node.attributeType.input === 'file';
                    let translated;
                    if(this.state.edit)
                        translated = this.getTraductionAttributs(attribute.node.identifier,this.state.currentCustomer.node.customerDatas.edges,locale.node.code,attribute.node.attributeType.input)
                    else {
                        translated = null
                    }
                    if (formValue) {

                        let variables = {
                            "attributeOption": attribute.node.attributeType.input === 'select' ? formValue : null,
                            "customer": company, 
                            "attribute": attribute.node.id,
                            "locale": locale.node.id
                        };

                        if (isMedia){
                            variables.media = formValue.id;
                        }
                        
                        if (!isMedia)
                            if (attribute.node.attributeType.input !== 'select')
                                    variables.value = formValue;
                        
                        if(this.state.edit && translated){
                            variables.id = translated.id;
                            let resultMutation = await this.props.client.mutate({
                                mutation: UPDATE_CUSTOMER_DATA,
                                variables
                            })
                            getProductData.push(resultMutation.data.updateCustomerData.customerData)
                        }
                        else{
                            let resultMutation = await this.props.client.mutate({
                                mutation: ADD_CUSTOMER_DATA,
                                variables
                            })
                            getProductData.push(resultMutation.data.createCustomerData.customerData)                            
                        }
                    }
                }
            }
            resolve(getProductData);
        });
    }

    checkAccess() {
        let type = null;
        switch(this.state.customerStatus){
            case 'contact' :
                type = CRM_CONTACT_CONTACTS;
            break;
            case 'client' :
                type = CRM_CONTACT_CLIENTS;
            break;
            case 'lead' :
                type = CRM_CONTACT_LEADS;
            break;
            default:
                return null;
        }
        const getRights = hasRights(CRM_CONTACT, type, VIEW)
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }else{
            this.setState({
                accessType: type,
            })
        }
    }

    async componentDidMount(){
        await this.checkAccess();
        this.initForm();
    }
    
    render() {
        const selectLang = {
            type: 'select',
            label: 'Langue',
            helper: 'Langue',
            required: false,
            stateName: 'currentLang',
            value: this.props.locales.map((locale) => {
                return(
                    {
                        value: locale.node.code,
                        label: locale.node.libelle
                    }
                )
            })
        };
        return (
            <div style={{width: this.state.contactAddForm || this.state.customerAddForm ? `calc(100% - ((50% - ${this.props.drawerWidth}px / 2) + (${this.props.drawerWidth}px / 2)))` : "100%", transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms'}}>
                <Grid container justify={'flex-end'}>
                    <Grid item xs={5}>
                        <Grid container justify={'flex-end'}>
                            <InputBuilder value={this.state.currentLang} input={selectLang} stateCallback={this.handleLang} /> 
                        </Grid>
                    </Grid>
                </Grid>

                <CardCustom style={{margin: '0 32px', paddingLeft: 16}}>
                    <CardContactDetails 
                        allStates={this.state} 
                        currentLang = {this.state.currentLang} 
                        langs={this.props.locales} 
                        handleToggleDrawer={this.handleToggleDrawer} 
                        isEdit={hasRights(CRM_CONTACT, this.state.accessType, UPDATE) ? this.state.edit : null}
                    />
                </CardCustom>
                
                {
                    this.state.isReady ? 
                    (
                        <LayoutBuilder
                            icomoon="icon-contacts"
                            opened={this.state.contactAddForm} 
                            forClose={this.state.edit ? () => this.handleToggleDrawer('contactAddForm') : () => this.props.history.goBack()} 
                            handlerSetup={()=>{}} 
                            dataLayout={contactAdd(this.state.currentLang,this.state.customAttributes,this.handleMediaPicker,this.state.allCompanies,this.handleSelectCompany,this.state.companies.current,this.state.formSettings, this.state.edit, this.goTo)} 
                            drawerWidth={this.props.drawerWidth}     
                            allState={this.state}    
                            stateCallback={this.handleInputChange} 
                            errorCallback={this.handleFormError}    
                            stepperButtonAction={[
                                this.handleNextStep,
                                this.handleNextStep,
                            ]}
                            handlerMutation = {this.handleMutation}
                            currentLang={this.state.currentLang}
                            handleLang={this.handleLang}
                            deleteMutation = {this.state.edit && hasRights(CRM_CONTACT, this.state.accessType, DELETE) ? () => this.handleToggleModal('openDeleteModal') : null}
                            deleteText = {'Supprimer ce contact'}
                            validateButton = {true}
                        />            
                    )
                    : null
                }

                <DialogModal
                    open = {this.state.openDeleteModal}
                    secondaryAction = {()=>this.handleToggleModal('openDeleteModal')}
                    primaryAction = {()=>this.handleDeleteCustomer()}
                    title = 'Supprimer ce contact'
                >
                    <Typography variant="body2">Êtes-vous sûr de vouloir supprimer ce contact ? <strong>Cette action est irréversible.</strong></Typography>
                </DialogModal>

            </div>
        );
    }

    goTo = (route, status) => {
        this.props.history.push({
            pathname : route,
            state: {openForm : status}
        });
    };
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes, 
        locales: state.locales,
    };
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(ContactAdd)));
