import { FormInputTypes } from '../../../shareable/types';
import { getDefaultText, getDefaultTextarea } from '../../helpers';

export default {
    paddingTop: 95,
    paddingBottom: 90,
    name: 'Section de titre',
    inputs: {
        backgroundImage: {
            label: 'Image de fond',
            type: FormInputTypes.IMAGE,
            value: null
        },
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#fffff'
        }
    },
    blocks: {
        title: getDefaultText("Titre", "Lorem ipsum", 16, "#666", "rgba(0, 0, 0, 0)"),
        subTitle: getDefaultText("Sous-titre", "Lorem ipsum dolor sit amet", 30, "#433f3f", "rgba(0, 0, 0, 0)"),
        desc: getDefaultTextarea("Description", "Donec laoreet orci eget nulla consectetur, vel accumsan dui fermentum.", null, "#433f3f", "rgba(0, 0, 0, 0)")
    }
};