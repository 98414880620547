import { v4 as uuidv4 } from "uuid";
import { FormInputTypes, EditableTypes } from '../../../shareable/types';
import { getDefaultText, getDefaultTextarea, getImageDefault, getDefaultLink } from '../../helpers';

export default {
    name: "Section du flipbook",
    inputs:{
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#F0F7FA'
        },
        // backgroundImage: {
        //     label: 'Image de fond',
        //     type: FormInputTypes.IMAGE,
        //     value: null
        // },
    },
    blocks: {
        title: getDefaultText("Titre", "Remplissez votre liste de cadeaux", 40, "#53839D", "rgba(0,0,0,0)", 'initial', 'center'),
        subtitle: getDefaultText("Sous-titre", "pour vous inscrire au tirage au sort et tentez de remporter des cadeaux",  20, "#53839D", "rgba(0,0,0,0)", 'initial', 'center'),
        subBlock: {
            id: uuidv4(),
            name: "Sous bloc du flipbook",
            type: EditableTypes.MINI_BLOCKS,
            inputs: {
                backgroundColor: {
                    label: 'Couleur de fond',
                    type: FormInputTypes.COLOR,
                    value: '#fff'
                },
                backgroundColor2: {
                    label: 'Couleur de fond flipbook',
                    type: FormInputTypes.COLOR,
                    value: '#F2F2F2'
                },
                // backgroundColorGift: {
                //     label: 'Couleur de fond cadeaux',
                //     type: FormInputTypes.COLOR,
                //     value: '#CF2E2566'
                // },
                // backgroundColorGiftTexte: {
                //     label: 'Couleur de texte cadeaux',
                //     type: FormInputTypes.COLOR,
                //     value: '#CF2E25'
                // },
            },
            blocks:{
                title: getDefaultText("Sous-titre du flipbook", "Explorez le catalogue", 20, "#000", null, 'initial', 'center', null),
                ruleTitle: getDefaultText("Titre des règles", "Cliquez sur le set de votre choix pour ajouter 4 produits à votre liste.", 16, "#CF2E25", null, 'initial', 'center', null),
                ruleSubtitle: getDefaultText("Sous-titre des règles", "Utilisez les flèches pour parcourir le catalogue", 16, "#000", null, 'initial', 'center', null),
                // icon: getImageDefault(null, "Image de fond des cadeaux"),
                button: getDefaultLink("Bouton", "Valider ma liste", null, 16, '#BABABB', 'transparent', null, 1, '#BABABB', 27),
            }
        },
        giftBlock: {
            id: uuidv4(),
            name: "Bloc des cadeaux",
            type: EditableTypes.MINI_BLOCKS,
            inputs: {
                backgroundColorGift: {
                    label: 'Couleur de fond cadeaux',
                    type: FormInputTypes.COLOR,
                    value: '#CF2E2566'
                },
                backgroundColorGiftTexte: {
                    label: 'Couleur de texte cadeaux',
                    type: FormInputTypes.COLOR,
                    value: '#CF2E25'
                },
            },
            blocks:{
                icon: getImageDefault(null, "Image de fond des cadeaux"),
            }
        }
    }
};
