import React from "react";
import colors from '../../../colors';

export default ({ data, preview, component, block, inputCallback, className, white = false, hover = false, nopadding = false, forInput = null}) => {
    let style = null;
    if(!nopadding){
        style = {
            paddingTop: 10,
            paddingBottom: 18,
            paddingLeft: 10,
            paddingRight: 10
        };
    }

    return (
        <label 
            className={className} 
            onClick={() => inputCallback(component, block)}
            style={{ 
                ...style,
                fontSize: data.blocks[block].inputs.size?.value,
                color: data.blocks[block].inputs.color?.value,
                lineHeight: '1.2',
                textTransform: data.blocks[block].inputs.textTransform?.value,
                textAlign: data.blocks[block].inputs.textAlign?.value,
                border: !preview ? `1px dashed ${colors.blue.lighter.hue150}` : null
            }}
            for={forInput}
            dangerouslySetInnerHTML={{ __html: data.blocks[block].inputs.value.value }}
        />
    );
};