import { FormInputTypes } from '../../../shareable/types';
import { getImageDefault, getDefaultTextarea, getDefaultLink } from '../../helpers';

export default {
    name: 'Section principale',
    inputs:{
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#FFFFFF',
        },
        backgroundImage: {
            label: 'Image de fond',
            type: FormInputTypes.IMAGE,
            value: null
        }
    },
    blocks: {
        text: getDefaultTextarea("Titre", "L'aventure ne s'arrête pas là !", true, "#53839D", "rgba(0,0,0,0)", 'initial', 'center', 40),
        button: getDefaultLink("Boutton", "Découvrir !", null, 20, '#fff', '#53839D', null, 0, '#fff', 0),
    }
};