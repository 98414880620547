import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDrop } from 'react-dnd';
import Div from '../../../../shared/components/CustomDiv';

const FooterGame = (props) => {
    const [scroll, setScroll] = useState(0);
    const [hover, setHover] = useState(false);
    const [headerTop, setHeaderTop] = useState(0);

    const handleScroll = () => {
        setScroll(window.scrollY);
    };

    const [collectedProps, drop] = useDrop({
        accept: ['media'],
        drop: (item) => {
            data.inputs['backgroundImage'].value = item.media.node;
            update();
        }
    });


    let {
        preview = false,
        id = null, 
        data = null,
        noSticky,
        inputCallback = null,
        update = null,
    } = props;

    let image = data.inputs['backgroundImage'].value;

    return (
        <footer 
            ref={drop}
            style={{
                backgroundColor: data.inputs['backgroundColor'].value,
                backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'top center',
                position: 'relative'
            }}
            onClick={() => inputCallback(id)}
            onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
        >
            { !preview && hover && props.children }
            <Div data={data} className="footer" preview={preview} component={id} block={'footerText'} inputCallback={inputCallback} nopadding={true} />
        </footer>
    );
};

FooterGame.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingClass: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default FooterGame;
