import React from "react";
import colors from '../../../colors';

const PG = ({ data, preview, component, block, inputCallback, className, white = false, hover = false, nopadding = false, style = null }) => {
    let padding = null;
    if (!nopadding) {
        padding = {
            paddingTop: 10,
            paddingBottom: 18,
            paddingLeft: 10,
            paddingRight: 10
        };
    }
    
    let image = data.blocks[block].inputs['backgroundImage']?.value;

    return (
        <p 
            className={className} 
            onClick={() => inputCallback ? inputCallback(component, block) : null}
            key={block}
            style={{ 
                ...padding,
                ...style,
                fontSize: data.blocks[block].inputs.size?.value,
                color: data.blocks[block].inputs.color?.value,
                backgroundColor: data.blocks[block].inputs.backgroundColor?.value,
                backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                textTransform: data.blocks[block].inputs.textTransform?.value,
                textAlign: data.blocks[block].inputs.textAlign?.value,
                lineHeight: typeof image === 'string' || image?.filePath ? '2': '1.2', 
                padding: typeof image === 'string' || image?.filePath ?  `10px ${data.blocks[block].inputs.size?.value}px` : null,
                border: !preview ? `1px dashed ${colors.blue.lighter.hue150}` : null,
                // fontFamily: 'Open Sans',
            }}
            dangerouslySetInnerHTML={{ __html: data.blocks[block].inputs.value.value }}
        />
    );
};

export default PG;