import React from 'react';
import styled from 'styled-components';
import colors from '../../../../../config/theme/colors';
import Button from '../../../../ui/button/Button';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import LinkIcon from '@material-ui/icons/Link';

const DivColor = styled.div`
    border-radius: 5px 5px 0 0!important;
    border: 1px solid lightgrey!important;
    background: white;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
`;
const DivMarkups = styled.div`
    border-radius:  0 0 5px 5px!important;
    border: 1px solid lightgrey!important;
    border-top: 0!important;
    background: white;
    width: 100%;
    padding: 6px 16px;
    &>div:first-child{
        display: flex;
        align-items: center;
        padding: 6px 0px;
    }
`;
const SelectCustom = styled(Select)`
    &:before{
        content: inherit;
    }
    &:after{
        content: inherit!important;
    }
`;
const InputCustom = styled(Input)`
    border: 1px solid ${colors.grey.lighter.hue900};
    border-radius: 5px;
    padding-left: 4px;
    &:before{
        content: inherit!important;
    }
    &:after{
        content: inherit!important;
    }
`;

class TypoPicker extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            displayTypoPicker: this.props.allState.editForm && this.props.allState.editForm === 'edit' ?  false : true,
            typo: this.props.typo || this.props.typo.typographySystem.id,
            markups: [],
            listMarkups: this.props.listMarkups,
            listTypographySystems: this.props.listTypographySystems,
            defaultBaseSize: 16,
            defaultRatio: 1.25,
            listRatio: [1.125, 1.25, 1.333, 1.414, 1.5],
            link: false,
        };
        this.typingTimer = null;
    }

    handleClick = () => {
        this.setState({ displayTypoPicker: !this.state.displayTypoPicker })
    };

    handleClose = () => {
        this.setState({ displayTypoPicker: false })
    };

    handleChange = (event) => {
        let typo = event.target.value;
        let temporaryTypo = this.state.typo;
        for(let typoSystem of this.state.listTypographySystems){
            if(typo === typoSystem.node.id){
                temporaryTypo.id = typoSystem.node.id;
                temporaryTypo.identifier = typoSystem.node.identifier;
                temporaryTypo.libelle = typoSystem.node.libelle;
                temporaryTypo.value = typoSystem.node.value;
                temporaryTypo.changed = true;
            }
        }

        this.setState({
            typo: temporaryTypo,
        }, () => {
            this.props.handleChangeComplete(this.state.typo)
        });
    };

    handleChangeRatio = (event) => {
        let ratio = event.target.value;
        this.setState({
            defaultRatio: ratio
        }, () => {
            let typo = this.state.typo;
            let array = [];
            let value = 0;
            let i = 0;
            for(let item of this.state.listMarkups){
                if(value > 0){
                    value = value * this.state.defaultRatio;
                    if(value > 100){
                        value = 100 - (this.state.listMarkups.length - i)
                    }
                }
                else{
                    value = this.state.defaultBaseSize / this.state.defaultRatio;
                }
                array.push(Math.trunc(value));
                i++
            }
            typo.markup = array.reverse();
            typo.changed = true;
            this.setState({
                typo
            }, () => {
                this.props.handleChangeComplete(this.state.typo)
            });
        });
    };

    handleSizeChange = (event, index, min, max) => {
        let typo = this.state.typo;
        let value = parseInt(event.target.value);

        if(this.state.link){
            if(value < typo.markup[index]){
                let i = 0; 
                for(let markup of typo.markup.reverse()){
                    if (markup - 1 < 10) {
                        typo.markup[i] = 10;
                    }
                    else if (typo.markup[i - 1] && typo.markup[i - 1] >= markup - 1 ) {
                        typo.markup[i] = typo.markup[i - 1] + 1;
                    } else {
                        typo.markup[i] = markup - 1;
                    }
                    i++;
                }
                typo.markup.reverse()
            } else if(value > typo.markup[index]){
                let i = 0; 
                for(let markup of typo.markup){
                    if (markup + 1 > 100) {
                        typo.markup[i] = 100;
                    }
                    else if (typo.markup[i - 1] && typo.markup[i - 1] <= markup + 1 ) {
                        typo.markup[i] = typo.markup[i - 1] - 1;
                    } else {
                        typo.markup[i] = markup + 1;
                    }
                    i++;
                }
            }
            typo.changed = true;
            this.setState({
                typo
            }, () => {
                clearTimeout(this.typingTimer);
                this.typingTimer = setTimeout(() => {this.props.handleChangeComplete(this.state.typo)}, 500)
            });
        } else {
            if (value < min) {
                typo.markup[index] = min;
            } else if (value > max) {
                typo.markup[index] = max;
            } else if(!value || value === ""){
                typo.markup[index] = min;
            } else {
                typo.markup[index] = value;
            }
            typo.changed = true;
            this.setState({
                typo
            }, () => {
                clearTimeout(this.typingTimer);
                this.typingTimer = setTimeout(() => {this.props.handleChangeComplete(this.state.typo)}, 500)
            });
        }
    };

    componentDidUpdate = (prevProps) => {
        if(this.props.typo !== prevProps.typo){
            this.setState({typo: this.props.typo})
        }
    };

    componentDidMount = () => {
        this.setState({typo: this.props.typo})
    };
    
    render() {
        return (
            this.state.typo ? (
                this.state.displayTypoPicker ? (
                    <div style={{width: '100%', position: 'relative'}}>
                        <div>
                            <DivColor style={{display: 'flex', justifyContent: 'space-between'}}>
                                <FormControl>
                                    <SelectCustom
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={this.state.typo.id}
                                        onChange={(e) => this.handleChange(e)}
                                    >
                                        {this.state.listTypographySystems.map((typoSystem, index) => (
                                            <MenuItem value={typoSystem.node.id} key={`typopicker-select-${index}`}>{typoSystem.node.libelle}</MenuItem>
                                        ))}
                                    </SelectCustom>
                                </FormControl>
                                <FormControl style={{display: 'flex', alignItems: 'center', flexDirection: 'row'}}>
                                    <LinkIcon style={{marginRight: 10, color: this.state.link ? colors.blue.lighter.hue300 : colors.blue.regular }} onClick={() => this.setState({link: !this.state.link})} />
                                    <SelectCustom
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={this.state.defaultRatio}
                                        onChange={(e) => this.handleChangeRatio(e)}
                                    >
                                        {this.state.listRatio.map((ratio, index) => (
                                            <MenuItem value={ratio} key={`typopicker-ratio-${index}`}>{ratio}</MenuItem>
                                        ))}
                                    </SelectCustom>
                                </FormControl>
                            </DivColor>
                            <DivMarkups>
                                {
                                    this.state.listMarkups ? (
                                        <>
                                            {
                                                this.state.listMarkups.map((markup, index) => (
                                                    <div style={{position: 'relative', minHeight: this.state.typo.markup[0] * 1.3 > 50 ? this.state.typo.markup[0] * 1.3 : 50, display: 'flex', alignItems: 'center' }} key={`typopicker-markup-${index}`}>
                                                        <p style={{ fontFamily: this.state.typo.value, fontSize: this.state.typo.markup[index], margin: 0, lineHeight: '1.3', height: this.state.typo.markup[index] * 1.3, overflow: 'hidden', transition: 'all ease 0.5s' }}>{markup.node.libelle} : A Visual Type Scale</p>
                                                        <div style={{position: 'absolute', background: 'white', right: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', height: this.state.typo.markup[0] * 1.3, padding: '0 0 0 25px'}}>
                                                            <InputCustom
                                                                id="outlined-basic" 
                                                                variant="outlined" 
                                                                value={this.state.typo.markup[index]} 
                                                                style={{width: 50}} 
                                                                size="small" 
                                                                onChange={(e) => {this.handleSizeChange(e, index, this.state.typo.markup[index + 1] ? this.state.typo.markup[index + 1] + 1 : 10, this.state.typo.markup[index - 1] ? this.state.typo.markup[index - 1] - 1 : 100)}}
                                                                inputProps={{
                                                                    step: 1,
                                                                    min: this.state.typo.markup[index + 1] ? this.state.typo.markup[index + 1] + 1 : 10,
                                                                    max: this.state.typo.markup[index - 1] ? this.state.typo.markup[index - 1] - 1 : 100,
                                                                    type: 'number'
                                                                }}
                                                            />
                                                            <span style={{marginLeft: 8}}>px</span>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            <Button onClick={ this.handleClose } bgcolor={colors.white} bgcolorhover={colors.blue.lighter.hue300} color={colors.blue.lighter.hue300} colorhover={colors.white} border={`1px solid ${colors.blue.lighter.hue300}`} fontsize={14} style={{width: '100%'}}>Valider</Button>
                                            <Button onClick={ () => {this.props.deleteTypo()} } bgcolor={colors.white} bgcolorhover={colors.white} color={colors.red.regular} colorhover={colors.red.darker} fontsize={12} style={{width: '100%', margin: 0}}>
                                                <HighlightOffIcon style={{fontSize: '1rem', marginRight: 5}} /> 
                                                Supprimer
                                            </Button>
                                        </>
                                    ) : null
                                }
                            </DivMarkups>
                        </div> 
                    </div>
                ) : (
                    <div style={{width: '100%', position: 'relative'}} onClick={() => {this.handleClick()}}>
                        <DivColor style={{cursor: 'pointer'}}>
                            <p>{this.state.typo.libelle}</p>
                            <p style={{color: colors.blue.lighter.hue300, textDecoration: 'underline', position: 'absolute', right: 30}}>Modifier</p>
                        </DivColor>
                        <DivMarkups>
                            {
                                this.state.listMarkups ? (
                                    <>
                                        {
                                            this.state.listMarkups.map((markup, index) => (
                                                <p style={{ fontFamily: this.state.typo.value, margin: 0, lineHeight: '1.3', fontSize: 20}} key={`typopicker-markup-${index}`}>{markup.node.libelle} : {this.state.typo.markup[index]}px</p>
                                            ))
                                        }
                                    </>
                                ) : 'Aucune valeur'
                            }
                        </DivMarkups>
                    </div>
                )
            ) : null
        )
    };
}

export default TypoPicker;