import React from 'react';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { withApollo } from 'react-apollo';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import CardCustom from '../../../layouts/Card/CardCustom';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import formAddDevis from './config/addDevis.config';
import Button from '../../../ui/button/Button';
import Typography from '../../../ui/typography/Typography';
import { Grid, Box } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ROUTE_CRM_OFFERS_MODELES, ROUTE_HOME } from '../../../../js/constants/route-names';

import { eventService } from '../../../../js/services/event.service';
import colors from '../../../../config/theme/colors';
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import { SEARCH_COMPANIES, GET_COMPANIES_CURSOR, GET_COMPANIES_PAGINATION} from '../../../../queries/companies';

import styled from 'styled-components';
import { GET_MODELE_BY_ID, ADD_MODELE, UPDATE_MODELE, DELETE_MODELE, ADD_PHASE_MODELE, ADD_TACHE_PHASE_MODELE, UPDATE_PHASE_MODELE, DELETE_PHASE_MODELE, UPDATE_TACHE_PHASE_MODELE, DELETE_TACHE_PHASE_MODELE } from '../../../../queries/crm_modeles';
import { GET_AGENCE_POLES, GET_AGENCE_TARIFS, GET_AGENCE_TAX_RATES, GET_PAYMENT_TERMS, GET_PAYMENT_DEADLINES } from '../../../../queries/crm_agence';
import OfferDisplay from './components/OfferDisplay';
import { hasRights } from '../../../../js/utils/rights';
import { CRM_OFFERS, CRM_OFFERS_MODELES, CREATE, UPDATE, DELETE, VIEW} from '../../../../js/constants/constant-rights';

const ReturnLink = styled(Typography)`
    color: ${colors.blue.lighter.hue300};
    width: 70px;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
`

class DevisDetails extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            currentLang: props.locales[0].node.code,
            modeleId: props.location.state?.modeleId,
            imageDevis: null,
            name: null,
            description: null,
            formType: null,
            openForm: false,
            errors: {},
            seeErrors: false,
            modelePhases: [],
            isModel: true
        };
    }

    componentDidMount() {
        const getRights = hasRights(CRM_OFFERS, CRM_OFFERS_MODELES, VIEW)
        const getRights2 = hasRights(CRM_OFFERS, CRM_OFFERS_MODELES, CREATE)
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }else if (!this.state.offerId){
            if(!getRights2){
                this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
                this.goTo(ROUTE_HOME);
            }else{
                this.initDevis();
            }
        }else{
            this.initDevis();
        }
    }
    
    // deleteProject = () => {
    //     handleToggleDialog()
    // }

    handleToggleDrawer = (drawer) => {
        this.setState({ 
            openForm : !this.state.openForm,
        });
        this.setState({ 
            seeErrors: false
        });
    };

    handleToggleDialog = () => {
        this.setState({ 
            openDialog : !this.state.openDialog
        });
    };

    handleLang = (event) => {
        this.setState({ currentLang: event.target.value });
        this.forceUpdate();
    };

    handleGetCompanies = async()=>{
        let variables = {
            nbperpage : this.state.nbperPageCompanies
        }
        if(this.state.cursor && this.state.listCursors !== this.state.listCursors[0].cursor){

            variables.cursor = this.state.cursor;
        }
        if(this.state.activeSearchCompanies){
            variables.id_list = this.state.activeSearchCompanies
        }
        this.props.client.query({
            query: GET_COMPANIES_PAGINATION,
            variables : variables,
            fetchPolicy: 'no-cache'
        }).then(result => {
            let formSettings = this.state.formSettings;
            let companies = result.data.companies.edges;
            formSettings.count = Math.ceil(result.data.companies.totalCount / this.state.nbperPageCompanies);
            this.setState({
                allCompanies : companies,
                formSettings : formSettings
            })
        })
    }

    handleSelectCompany = (checked,company,currentAction='add') =>{
        let companies = this.state.companies
        companies.toAdd = company
        // if(checked){
        //     companies.toAdd.push(company)
        // }
        // else{
        //     let index = companies.toAdd.indexOf(company);
        //     companies.toAdd.splice(index,1)
        // }
        this.setState({
            companies : companies
        })
    }

    changePage = (event, page) => {
        // this.props.startLoading();
        let index = 0;
        
        if(page > 1){
            index = ( page * this.state.nbperPageCompanies ) - this.state.nbperPageCompanies - 1
        }
        let formSettings = this.state.formSettings;
        formSettings.page = page
        this.setState({
            allCompanies:null,
            cursor: this.state.listCursors[index].cursor,
            formSettings : formSettings
        },()=>{
            this.handleGetCompanies();
        });
        

    };

    setValue = (stateName, value, translated) => {
        this.setState({
            [stateName]: value,
        });
    };

    handleInputChange = (stateName, evt, custom, translated ) => {
        const value = evt?.target?.value ?? evt;
        this.setValue(stateName, value, translated);
    };

    resetState() {
        this.setState({
            name: null,
            description: null,
            imageDevis: null,
            errors: {}
        });
    }

    handleButtonGroupChange = (stateName, value) => {
        this.setState({
            [stateName]: value
        });
    };

    deleteMutation = () => {
        let query = null;
        let variables = null;
        this.setState({
            formType: 'delete'
        })

        query = DELETE_MODELE;
        variables = { id: this.state.modeleId };
        this.props.client.mutate({
            mutation: query,
            variables
        }).then(() => {
            this.handleSuccess();
        });
    };

    copy(array) {
        let newArray = [];
        
        for (let elem of array)
            newArray.push(Object.assign({}, elem));

        return newArray;
    }

    copyArrayOfObjects = array => array.map(a => ({...a}));

    handleMediaPicker=(selected,stateName)=>{
        this.handleInputChange(stateName,selected,null,this.state.currentLang);
    }

    handleError = (e) => {
        this.props.snack(ALERT_ERROR, 'Une erreur est survenue');

        this.props.stopLoading();

        if (e.graphQLErrors) {
            for (let error of e.graphQLErrors) {
                console.error('ERROR', `${error.message} =>`, error.debugMessage);
            }
        }
    };

    handleSuccess = async () => {
        this.setState({
            ready: false
        })
        if(this.state.formType === "edit"){
            this.props.snack(ALERT_SUCCESS, 'Modèle d\'offre modifié !');
            await this.initDevis();
            this.handleToggleDrawer();
            this.resetState();
        }else if(this.state.formType === "add"){
            this.props.snack(ALERT_SUCCESS, 'Modèle d\'offre crée !');
            this.goTo(ROUTE_CRM_OFFERS_MODELES);
        }else{
            this.props.snack(ALERT_SUCCESS, 'Modèle d\'offre supprimé !');
            this.goTo(ROUTE_CRM_OFFERS_MODELES);
            // this.handleToggleDialog();
        }
        this.props.stopLoading();
    };

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;

        errors[stateName] = error;

        this.setState({ errors });
    };

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }

        return false;
    };

    handleNextStep = () =>{
        if (this.hasErrors()) {
            this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
            this.setState({ seeErrors: true });
            eventService.fire();
            return false;
        }

        return true;
    }

    savePhaseTasks = async (taches, phaseId, taskId) => {
        let index = 0;

        for (let tache of taches) {
            if (tache.deleted) {
                if (!tache.new) {
                    await this.props.client.mutate({
                        mutation: DELETE_TACHE_PHASE_MODELE,
                        variables:{ 'id': tache.id }
                    });
                }

                continue;
            }

            if (tache.new) {
                const ADD_TACHE_PHASE_MODELE_RESULT = await this.props.client.mutate({
                    mutation: ADD_TACHE_PHASE_MODELE,
                    variables: {
                        'name': tache.name,
                        'phase': phaseId,
                        'price': tache.price,
                        'quantity': tache.qty,
                        'total': tache.total,
                        'reference': tache.ref,
                        'tax': tache.tva,
                        'task': taskId ? taskId : null,
                        'discountFixed': tache.typeRemise === '%' ? 0 : tache.remise,
                        'discountPercent': tache.typeRemise === '%' ? tache.remise : 0,
                        'poles': tache.poles,
                        'position': index
                    }
                });

                tache.id = ADD_TACHE_PHASE_MODELE_RESULT.data.createModelTask.modelTask.id;
                
                if (taskId === null)
                    await this.savePhaseTasks(tache.taches, phaseId, tache.id);
            } else if (tache.updated) {
                await this.props.client.mutate({
                    mutation: UPDATE_TACHE_PHASE_MODELE,
                    variables: {
                        'id': tache.id,
                        'name': tache.name,
                        'phase': phaseId,
                        'price': tache.price,
                        'quantity': tache.qty,
                        'total': tache.total,
                        'reference': tache.ref,
                        'tax': tache.tva,
                        'discountFixed': tache.typeRemise === '%' ? 0 : tache.remise,
                        'discountPercent': tache.typeRemise === '%' ? tache.remise : 0,
                        'poles': tache.poles,
                        'position': index
                    }
                });

                if (taskId === null)
                    await this.savePhaseTasks(tache.taches, phaseId, tache.id);
            } else if (taskId === null) {
                await this.savePhaseTasks(tache.taches, phaseId, tache.id);
                await this.saveTaskPosition(tache.id, index);
            }

            index++;
        }
    };

    savePhasePosition = async (id, position) => {
        await this.props.client.mutate({
            mutation: UPDATE_PHASE_MODELE,
            variables: {
                'id': id,
                'position': position,
            }
        });
    };

    saveTaskPosition = async (id, position) => {
        await this.props.client.mutate({
            mutation: UPDATE_TACHE_PHASE_MODELE,
            variables: {
                'id': id,
                'position': position,
            }
        });
    };

    savePhases = async (model) => {
        let index = 0;

        for (let phase of this.state.phases) {
            if (phase.deleted) {
                if (!phase.new) {
                    // Delete a phase in cascade

                    await this.props.client.mutate({
                        mutation: DELETE_PHASE_MODELE,
                        variables: { 'id': phase.id }
                    });
                }

                continue;
            }

            if (phase.new) {
                // Add a new phase

                const ADD_PHASE_MODELE_RESULT = await this.props.client.mutate({
                    mutation: ADD_PHASE_MODELE,
                    variables: {
                        'name': phase.name,
                        'model': model,
                        'position': index
                    }
                });

                phase.id = ADD_PHASE_MODELE_RESULT.data.createModelPhase.modelPhase.id;

                await this.savePhaseTasks(phase.taches, phase.id, null);
            } else if (phase.updated) {
                // Update a phase

                await this.props.client.mutate({
                    mutation: UPDATE_PHASE_MODELE,
                    variables: {
                        'id': phase.id,
                        'name': phase.name,
                        'position': index
                    }
                });

                await this.savePhaseTasks(phase.taches, phase.id, null);
            } else {
                await this.savePhaseTasks(phase.taches, phase.id, null);
                await this.savePhasePosition(phase.id, index);
            }

            index++;
        }
    };

    handlerMutation = async () => {
        try {
            if (this.hasErrors()) {
                this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                this.setState({ seeErrors: true });
                return eventService.fire();
            }

            this.props.startLoading();

            if (this.state.formType === "add") {
                const ADD_MODELE_RESULT = await this.props.client.mutate({
                    mutation: ADD_MODELE,
                    variables: {
                        'name': this.state.name,
                        'description': this.state.description,
                        'media': this.state.imageDevis.id,
                        // 'company': this.state.companies.toAdd,
                    }
                });

                await this.savePhases(ADD_MODELE_RESULT.data.createModel.model.id);
            } else if (this.state.formType === "edit") {
                await this.props.client.mutate({
                    mutation: UPDATE_MODELE,
                    variables: {
                        'id': this.state.modeleId,
                        'name': this.state.name,
                        'description': this.state.description,
                        'media': this.state.imageDevis.id,
                        // 'company': this.state.companies.toAdd,
                    }
                });

                await this.savePhases(this.state.modeleId);
            }

            this.handleSuccess();
        } catch(e) {
            this.handleError(e);
        }
    };

    render() {
        const { phases, listTaxes, name, description, imageDevis } = this.state;
        
        return (
            <div style={{width: this.state.openForm ? `calc(100% - ((50% - ${this.props.drawerWidth}px / 2) + (${this.props.drawerWidth}px / 2) + 32px))` : "calc(100% - 32px)", marginLeft: 16, marginTop: 8, transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms'}}>
                <Grid container style={{padding: 0}}>
                    <Box onClick={this.props.history.goBack} style={{marginTop: 16, marginBottom: 16}}>
                        <ReturnLink variant={'body2'}>&lt; Retour</ReturnLink>
                    </Box>
                </Grid>
                {
                    this.state.ready ?
                        <CardCustom style={{paddingLeft: 16}}>
                            { listTaxes && <OfferDisplay 
                                phases={phases} 
                                listTaxes={listTaxes} 
                                isModel={true} 
                                model={{
                                    name, 
                                    description,
                                    imageDevis
                                }}
                            /> }
                            {
                                hasRights(CRM_OFFERS, CRM_OFFERS_MODELES, UPDATE) ?
                                    <Grid container justify="flex-end">
                                        <Button onClick={() => this.handleToggleDrawer()} disabled={this.state.openForm ? 'disabled' : ''}>Modifier</Button>
                                    </Grid>
                                : null
                            }
                        </CardCustom>
                    : <PageLoader />
                }
                {
                    this.state.ready ?
                    (
                        <LayoutBuilder 
                            opened={this.state.openForm}
                            // image={this.state.imageForm}
                            // icomoon={this.state.content.picto}
                            forClose={this.state.formType === "edit" ? () => {this.handleToggleDrawer()} : this.props.history.goBack} 
                            dataLayout={formAddDevis(this.state.currentLang,this.handleMediaPicker,this.state.allCompanies,this.handleSelectCompany,this.state.companies,this.state.formSettings, this.state.formType)} 
                            handlerMutation={this.handlerMutation}
                            drawerWidth={this.props.drawerWidth}
                            stateCallback={this.handleInputChange}
                            backStepperButtonAction={[
                                () => {
                                    this.setState({ errors: {} });
                                }, 
                                () => {
                                    this.setState({ errors: {} });
                                },
                                () => {
                                    this.setState({ errors: {} });
                                },
                                null
                            ]}
                            stepperButtonAction={[
                                this.handleNextStep,
                                this.handleNextStep,
                            ]}
                            errorCallback={this.handleFormError}
                            validateButton={true}
                            deleteMutation={this.state.formType === 'edit' && hasRights(CRM_OFFERS, CRM_OFFERS_MODELES, DELETE) ? () => this.handleToggleDialog() : null}
                            deleteText={this.state.formType === 'edit' ? 'Supprimer' : null}
                            allState={this.state}
                        />
                    ) : ''
                }
                <Dialog
                        open={this.state.openDialog}
                        onClose={this.handleToggleDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">Êtes-vous sûr de vouloir supprimer ce projet ?</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Si vous supprimez ce projet celui-ci ne sera plus accessible. Si vous ne souhaitez pas le supprimer, annulez la suppression en cliquant sur annuler.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleToggleDialog} color={colors.grey.regular} bgcolor={colors.white} bgcolorhover={colors.grey.lighter.hue900} border={`1px solid ${colors.grey.regular}`}>
                                Annuler
                            </Button>
                            <Button onClick={this.deleteMutation} bgcolor={colors.red.regular} bgcolorhover={colors.red.darker} autoFocus>
                                Supprimer
                            </Button>
                        </DialogActions>
                    </Dialog>
            </div>
        );
    }

    handleMediaPicker=(selected,stateName)=>{
        this.handleInputChange(stateName,selected,null,this.state.currentLang);
    }
    
    handleGetCompaniesCursors  = ()=>{
        this.props.client.query({
            query: GET_COMPANIES_CURSOR,
            fetchPolicy: 'no-cache'
        }).then(result => {
            this.setState({
                listCursors: result.data.companies.edges
            }, () => {
                this.handleGetCompanies();
            });
        });
    }

    handleChangeCompanies = (e,value)=>{
        this.setState({
            currentPage : 1,
            allCompanies : null,
        })
        clearTimeout(this.typingSearchTimer);
        if(e?.target?.value){
            this.setState({searchValueCompanies: e.target.value});
            this.typingSearchTimer = setTimeout(() => {
                this.handleSearchCompanies();
            }, 500);
        } 
        else{
            this.setState({activeSearchCompanies:false},()=>this.handleGetCompanies())
        }
    }

    handleSearchCompanies = () =>{
        let value = this.state.searchValueCompanies;
        if (value !== "") {
            this.props.client.query({
                query: SEARCH_COMPANIES,
                variables: {"needle": value,"attribute":null},
                fetchPolicy: 'no-cache'
            }).then(result =>{
                let companies = result.data.companyDatas.edges.map(e=>parseInt(e.node.company.id.replace('/api/companies/',''),10));
                let listID=[];
                let uniqueCompanies = companies.filter(company=>{
                    if(!listID.includes(company)){
                        listID.push(company);
                        return company;
                    }else{
                        return null;
                    }
                })
                this.setState({
                    activeSearchCompanies :uniqueCompanies
                },()=>this.handleGetCompanies())
                
            })
            
        }
    }

    initPoles() {
        return new Promise((resolve, reject) => {
            this.props.client.query({
                query: GET_AGENCE_POLES,
                fetchPolicy: 'no-cache'
            }).then(result =>{
                this.setState({
                    listAgencePoles: result.data.agencyPoles.edges
                });
                resolve();
            });
        });
    }

    initTarifs() {
        return new Promise((resolve, reject) => {
            this.props.client.query({
                query: GET_AGENCE_TARIFS,
                fetchPolicy: 'no-cache'
            }).then(result =>{
                this.setState({
                    listTarifs: result.data.agencyRates.edges
                });
                resolve();
            });
        });
    }

    initTaxes() {
        return new Promise((resolve, reject) => {
            this.props.client.query({
                query: GET_AGENCE_TAX_RATES,
                fetchPolicy: 'no-cache'
            }).then(result =>{
                this.setState({
                    listTaxes: result.data.agencyTaxRates.edges
                });
                resolve();
            });
        });
    }

    initPayments() {
        return new Promise((resolve, reject) => {
            this.props.client.query({
                query: GET_PAYMENT_TERMS,
                fetchPolicy: 'no-cache'
            }).then(result =>{
                this.setState({
                    listPayments: result.data.agencyPaymentTerms.edges
                });
                resolve();
            });
        });
    }

    initDeadlines() {
        return new Promise((resolve, reject) => {
            this.props.client.query({
                query: GET_PAYMENT_DEADLINES,
                fetchPolicy: 'no-cache'
            }).then(result =>{
                this.setState({
                    listDeadlines: result.data.agencyPaymentDeadlines.edges
                });
                resolve();
            });
        });
    }

    async initDevis() {
        this.initPoles();
        this.initTarifs();
        this.initTaxes();
        this.initPayments();
        this.initDeadlines();

        if (this.state.modeleId){
            this.props.client.query({
                query: GET_MODELE_BY_ID,
                fetchPolicy: "no-cache",
                variables : { id: this.state.modeleId }
            }).then(GET_MODELE_DATA_RESULT => {
                this.setState({
                    ready: true,
                    formType: 'edit',
                    model: GET_MODELE_DATA_RESULT.data.model,
                    name: GET_MODELE_DATA_RESULT.data.model.name,
                    description: GET_MODELE_DATA_RESULT.data.model.description,
                    imageDevis: GET_MODELE_DATA_RESULT.data.model.media,
                    modelePhases: GET_MODELE_DATA_RESULT.data.model.modelPhases.edges,
                    phases: this.convertPhases(GET_MODELE_DATA_RESULT.data.model.modelPhases.edges)
                }, hasRights(CRM_OFFERS, CRM_OFFERS_MODELES, UPDATE) ? () => this.handleToggleDrawer() : null)
            });
        } else {
            this.setState({
                ready: true,
                formType: 'add',
                openForm: true,
                phases: []
            })
        }
    }

    convertTask = (task) => {
        return {
            id: task.node.id,
            qty: task.node.quantity,
            price: task.node.price,
            tva: task.node.tax?.id,
            remise: task.node.discountFixed || task.node.discountPercent,
            typeRemise: task.node.discountFixed ? '€' : '%',
            total: task.node.total,
            name: task.node.name,
            ref: task.node.reference,
            poles: task.node.pole.edges.map(e => e.node.id),
            taches: []
        };
    };

    convertPhases = (phases) => {
        let newOnes = [];

        phases.sort((a, b) => {
            let aPosition = a.node.position || 0;
            let bPosition = b.node.position || 0;

            return aPosition > bPosition ? 1 : -1;
        });

        for (let phase of phases) {
            let item = {
                id: phase.node.id,
                name: phase.node.name,
                position: phase.node.position,
                taches: []
            };

            let parents = phase.node.modelTasks.edges.filter(e => e.node.task === null);

            for (let parent of parents) {
                let formatedParent = this.convertTask(parent);
                let children = phase.node.modelTasks.edges.filter(e => e.node.task !== null && e.node.task.id === formatedParent.id);

                children.sort((a, b) => {
                    let aPosition = a.node.position || 0;
                    let bPosition = b.node.position || 0;
    
                    return aPosition > bPosition ? 1 : -1;
                });

                for (let child of children) {
                    let formatedChild = this.convertTask(child);
                    formatedParent.taches.push(formatedChild);
                }

                item.taches.push(formatedParent);
            }

            newOnes.push(item);
        }

        return newOnes;
    };

    goTo = (route, id, target) => {
        let variables = {};
        variables[target] = id;
        this.props.history.push({
            pathname : route,
            state: variables
        });
    };

}

const mapDispatchToProps = dispatch => {
    return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes, 
        locales: state.locales,
    };
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(DevisDetails)));
