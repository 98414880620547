import React from "react";

export default ({ data, preview, block }) => {
    var dotation = data;
    return(
        <div class="list unique">
            <div class="dotation" >
                <div>
                    <img 
                        className="img_center"
                        src={
                            typeof dotation.inputs.image.value === 'string' && dotation.inputs.image.value !== '' ? 
                                dotation.inputs.image.value : 
                                dotation.inputs.image?.value?.filePath ? 
                                    ` ${process.env.REACT_APP_MEDIAS}/${dotation.inputs.image.value.filePath}` 
                                    : `http://via.placeholder.com/600x270/FFFFFF/53839D?text=Dotations`
                        }
                        alt={`Dotations`}
                    />
                </div>
            </div>
        </div>
    )
};
