import React from "react";
import Pg from '../../../../../shared/components/CustomParagraph';
import H1 from '../../../../../shared/components/CustomH1';

const MiddleBoxWin = (props) => {
    let { data, preview, inputCallback, component = null, hover, toolbarBlock } = props;
    let image = data.inputs['backgroundImage'].value;
    
    return(
        <div
            style={{
                backgroundColor: data.inputs['backgroundColor'].value,
                backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                backgroundPosition: 'bottom center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                position: 'relative',
                paddingBottom: typeof image === 'string' || image?.filePath ? 30 : 10,
            }}
        >
            { !preview && hover && toolbarBlock }
            <img 
                className="lot" 
                onClick={() => inputCallback(component, 'imageDotation')} 
                src={data.blocks.imageDotation.inputs.value?.value?.filePath  ? ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.imageDotation.inputs.value.value.filePath}` : typeof(data.blocks.imageDotation.inputs.value.value) === "string" ? data.blocks.imageDotation.inputs.value.value : "http://via.placeholder.com/300x250/EFF7FC/53839D?text=Lot"} 
            />
            <Pg data={data} preview={preview} component={component} block={'text'} inputCallback={inputCallback} nopadding={true} />
            <Pg data={data} preview={preview} component={component} block={'subText'} inputCallback={inputCallback} nopadding={true} />
            <H1 data={data} preview={preview} component={component} block={'nameDotation'} inputCallback={inputCallback} className="lot_text" nopadding={true} />
            <Pg data={data} preview={preview} component={component} block={'text2'} inputCallback={inputCallback} nopadding={true} />
            {
                data.blocks.reglement.inputs.value.value ? (
                    <a className="reglement">
                        <Pg data={data} preview={preview} component={component} block={'reglement'} inputCallback={inputCallback} nopadding={true} />
                    </a>
                ) : null
            }
        </div>
    )
};


export default MiddleBoxWin;