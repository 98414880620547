import React from 'react';
import {Grid} from '@material-ui/core';

import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import DialogModal from '../../../ui/dialog/DialogModal';
import CardCustom from '../../../layouts/Card/CardCustom';
import CardSetup from '../../../layouts/Card/cardContent/CardSetup';
import TopPanel from '../../../layouts/TopPanel/TopPanel';

import { withRouter } from 'react-router';
import { connect } from "react-redux";
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import fetch from '../../../../js/utils/fetch';
import axios from '../../../../js/utils/axios';

import { withApollo } from 'react-apollo';

import {
    GET_RETAILERS,
    ADD_RETAILER,
    UPDATE_RETAILER,
    DELETE_RETAILER,
    ADD_RETAILER_DATA,
    UPDATE_RETAILER_DATA,
    DELETE_RETAILER_DATA
} from '../../../../queries/retailers';

import { GET_ATTR_GROUPE_WITH_ATTRIBUTES, GET_ATTR_GROUPE_LIGHT } from '../../../../queries/attributes';

import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_SUCCESS, ALERT_ERROR } from '../../../../js/constants/alert-types';

import { eventService } from '../../../../js/services/event.service';

import EmptyRetailers from '../../../../assets/pictos/empty-picto/empty_retailers.png';
import EmptyFlux from '../../../../assets/pictos/empty-picto/empty_flux.png';
import EmptyCard from "../../../ui/empty-card/EmptyCard";
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import retailersAddConfig from './config/retailersAdd.config';

import request from '../../../../js/utils/fetch';
import slugify from 'slugify';

import { hasRights } from '../../../../js/utils/rights';
import { ROUTE_HOME } from '../../../../js/constants/route-names';
import { 
    BRAND,
    BRAND_FLOW,
    CONNECTORS,
    CONNECTORS_RETAILERS,
    VIEW, 
    CREATE,
    UPDATE,
    DELETE,
} from '../../../../js/constants/constant-rights';
import _ from 'lodash';

import CronNotifications from './components/CronNotifications';


const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
`;

class ListRetailers extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            filtered: [],
            attrGroup: false,
            openForm: false,
            editForm: 'add',
            isEmpty : false,
            emptyPicto: null,
            openDialogRemove: false,
            isInternal: props.isInternal,
            content:  
                props.type === "retailer" ? 
                    {
                        title: "Gérer les retailers",
                        subtitle: "Gestion de vos retailers (création / modification / suppression)",
                        picto: "picto-retailer",
                        txtBtn: "Ajouter un retailer",
                        titleForm: "Ajouter un retailer",
                        emptyTitle: "Vous n’avez pas encore configuré de retailers",
                        emptySubtitle: "Cliquez sur le bouton ci-dessous pour en ajouter un",
                        emptyTxtBtn: "Ajouter un retailer",
                        emptyPicto: EmptyRetailers,
                    }
                :
                props.type === "flow" ?
                    {
                        title: "Gérer mes flux",
                        subtitle: "Gestion de vos flux (création / modification / suppression)",
                        picto: "icon-flux",
                        txtBtn: "Ajouter un flux",
                        titleForm: "Ajouter un flux",
                        emptyTitle: "Vous n’avez pas encore configuré de flux",
                        emptySubtitle: "Cliquez sur le bouton ci-dessous pour en ajouter un",
                        emptyTxtBtn: "Ajouter un nouveau flux",
                        emptyPicto: EmptyFlux,
                    }
                :
                    {
                        title: "Gérer mes marques",
                        subtitle: "Gestion de vos marques (création / modification / suppression)",
                        picto: "icon-business",
                        txtBtn: "Ajouter une marque",
                        titleForm: "Ajouter une marque",
                        emptyTitle: "Vous n’avez pas encore configuré de marque",
                        emptySubtitle: "Cliquez sur le bouton ci-dessous pour en ajouter une",
                        emptyTxtBtn: "Ajouter un nouvelle marque",
                        emptyPicto: EmptyRetailers,
                    },
            dataAddLayout: retailersAddConfig,
            importLang: this.props.locales[0].node.id,
            currentLang: this.props.locales[0].node.code,
            errors: {},
            seeErrors: false,
            inputHidden: [],
            importFile: null,
            attrTypeFile: [],
            attrTypeUrl: [],
            attrTypeFtp: [],
            ready: false,
            drawerCron: false,
            typeTesting: {
                type: 'retailer',
                testingState: ['libelle', 'attributeIdentifier'],
                testingTypingState: 'libelle',
                identifierState: 'attributeIdentifier'
            }
        };
        this.handleChange = this.handleChange.bind(this);
        this.typingTimer = null;
    }
    
    handleLang = (event) => {
        this.setState({ currentLang: event.target.value });
        this.forceUpdate();
    };
    
    handleChange(e) {
        let currentList = [];
        let newList = [];
        if (e.target.value !== "") {
            currentList = this.state.retailers;
            newList = currentList.filter(item => {
                const lc = item.node.libelle.toLowerCase();
                const filter = e.target.value.toLowerCase();
                return lc.includes(filter);
            });
        } else {
            newList = this.state.retailers;
        }
        this.setState({
            filtered: newList
        });
    }

    doneTyping = (stateName) => {
        let typeTesting = this.state.typeTesting;
        if(stateName === typeTesting.testingTypingState){
            this.setState({
                [typeTesting.identifierState]: slugify(this.state[typeTesting.testingTypingState], {replacement :'_', lower: true, remove: /[^\w\-\s]+/g}),
            });
        }
        
        if(this.state[typeTesting.identifierState]){
            request(`${process.env.REACT_APP_API}/unique/${typeTesting.type}/${this.state[typeTesting.identifierState]}`, 'get').then(
                (data) => {
                    if(data.success){
                        eventService.fire({stateName: typeTesting.identifierState, errorMessage: 'Cet identifiant est déjà utilisé et n\'est donc pas valide.'});
                    }
                }
            );
        }
        this.forceUpdate();
    };

    checkIdentifier = (stateName) => {
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(() => {this.doneTyping(stateName)}, 500);
    };

    stateCallback = (stateName, value, custom, translated, callback) => {
        if (translated) {
            let values = this.state[this.state.currentLang];

            if (!values) {
                values = {};
            }

            values[stateName] = value?.target?.value ?? value;
            this.setState({[this.state.currentLang]: values});
        }
        else{
            this.setState({
                [stateName]: value?.target?.value ?? value
            }, callback);
        }
        if(this.state.editForm === 'add' && this.state.typeTesting.testingState.includes(stateName))
            this.checkIdentifier(stateName)
    };

    handleButtonGroupChange = (stateName, value) => {
        this.setState({
            [stateName]: value
        });
    };

    handlerSetup = () => {
        if(this.state.editForm === 'edit' && this.props.type === "brand"){
            this.props.startLoading();
        }
        this.props.client.query({
            query: GET_ATTR_GROUPE_WITH_ATTRIBUTES,
            fetchPolicy: 'no-cache',
        }).then(result =>{
            //RECUPERATION MAPPER
            let mapper = [];
            this.setState({importSep: ","});

            if(this.state.editForm === 'edit' && this.props.type === "brand"){
                request(`${process.env.REACT_APP_API}/retailers/${this.state.editIdentifier}/mapper`, 'get', false).then(
                    (data) => {
                        this.setState({existentMapper: data.mapper, importSep: data.delimiter});
                        this.props.stopLoading();
                    }
                );
            }
            else{
                this.setState({existentMapper: null});
            }

            //CONFIG DES COMPOSANTS STATICS
            const switchType = {
                type: 'select',
                label: 'Importer par',
                translated: false,
                helper: {
                    text: `Choisir le type d'import`,
                    link: false,
                },
                required: true,
                disabled: this.props.type === "brand" ? true : false,
                stateName: 'importFile',
                value: [
                    {
                        value: 'file',
                        label: 'Fichier',
                    },
                    {
                        value: 'url',
                        label: 'Url',
                    },
                    {
                        value: 'ftp',
                        label: 'FTP',
                    }
                ]
            };

            const separator = {
                type: 'select',
                label: 'Séparateur',
                translated: false,
                helper: {
                    text: `Choisir le séparateur`,
                    link: false,
                },
                required: false,
                stateName: 'importSep',
                value: [
                    { value: ',', label: ',' },
                    { value: ';', label: ';' },
                    { value: '|', label: '|' }
                ]
            };

            const setupUploadFile = {
                type: 'uploadFile',
                translated: true,
                required: false
            };

            const staticOptions = [
                {
                    id: 'sku',
                    label: 'Sku'
                },
                {
                    id: 'price',
                    label: 'Price'
                },
                {
                    id: 'specialPrice',
                    label: 'Special Price'
                },
                {
                    id: 'specialPriceFrom',
                    label: 'Special Price From Date'
                },
                {
                    id: 'specialPriceTo',
                    label: 'Special Price To Date'
                },
                {
                    id: 'stock',
                    label: 'Stock'
                },
                {
                    id: 'ecopart',
                    label: 'Eco-part'
                },
                {
                    id: 'url',
                    label: 'URL'
                }
            ];

            const setupMapping = {
                type: this.props.type === 'brand' ? 'mapper-multiple' : 'mapper',
                translated: true,
                required: false,
            };

            const setupInputText = {
                type: 'text',
                translated: true,
                helper: { link: false },
                required: false,
            };

            this.setState({importFile: ""})

            for(let attrGroup of result.data.attributeGroups.edges){
                let testType = this.props.type === 'flow' ? 'retailer' : this.props.type;
                if(attrGroup.node.identifier === testType){
                    let getLayoutAdd = this.state.dataAddLayout;
                    this.setState({attrGroupId: attrGroup.node.id, attr: attrGroup.node.attributes});

                    //INIT STEP 2 & 3 
                    getLayoutAdd.formConfig.children[1].optionsInputs = [];
                    getLayoutAdd.formConfig.children[2].optionsInputs = [];

                    //PUSH switch type d'import de fichier 
                    getLayoutAdd.formConfig.children[1].optionsInputs.push(switchType);
                    
                    //Initialisation des mapper par locale
                    for (let locale of this.props.locales) {
                        this.setState({ [locale.node.code]: {} });
                        let localeState = this.state[locale.node.code];
                        for(let attr of attrGroup.node.attributes.edges){
                            if(attr.node.attributeType.input === 'file'){
                                localeState[`upload_${attr.node.identifier}`] = null;
                                localeState[`mapper_${attr.node.identifier}`] = mapper;
                            }
                            else if(attr.node.attributeType.input === 'text'){
                                localeState[attr.node.identifier] = null;
                                localeState[`mapper_text_${attr.node.identifier}`] = mapper;
                            }
                            this.setState({
                                [locale.node.code] : localeState
                            })
                        }
                    }

                    this.setState({
                        dataAddLayout: getLayoutAdd
                    })

                    // SELECT Type File
                    for(let attr of attrGroup.node.attributes.edges){
                        if(attr.node.attributeType?.input === "select"){
                            let retailer_file_type = {
                                type: 'select',
                                label: 'Type de fichier',
                                translated: false,
                                helper: {
                                    text: `Choisir le type de fichier`,
                                    link: false,
                                },
                                required: false,
                                stateName: 'retailer_file_type',
                                value: []
                            }

                            for(let item of attr.node.attributeOptions.edges){
                                if(retailer_file_type.value.length === 0){
                                    this.setState({retailer_file_type: item.node.identifier})
                                }
                                retailer_file_type.value.push({value: item.node.identifier, label: item.node.translation.translationDatas.edges[0].node.value})
                            }

                            let configFormAdd = this.state.dataAddLayout;
                            configFormAdd.formConfig.children[1].optionsInputs.push(retailer_file_type);
                            
                            this.setState({
                                dataAddLayout: configFormAdd
                            });
                        }
                    }
                    this.state.dataAddLayout.formConfig.children[1].optionsInputs.push(separator);
                    
                    for(let attr of attrGroup.node.attributes.edges){
                        //LABEL ATTRIBUTION
                        let label = '';
                        if(attr.node.translation.translationDatas){
                            label = attr.node.translation.translationDatas.edges[0].node.value;
                        }
                        else{
                            label = attr.node.identifier
                        } 

                        if(attr.node.attributeType.input === "file"){
                            if(this.state.editForm === "add"){
                                let uploadFile = _.clone(setupUploadFile);
                                uploadFile.stateName = `upload_${attr.node.identifier}`;
                                uploadFile.label = label;

                                let mapping = _.clone(setupMapping);
                                mapping.label = label;
                                mapping.mapOn = `upload_${attr.node.identifier}`;
                                mapping.stateName = `mapper_${attr.node.identifier}`;
                                if(this.props.type !== 'brand'){
                                    mapping.staticOptions = staticOptions;
                                }

                                this.state.attrTypeFile.push(`upload_${attr.node.identifier}`);
                                this.state.attrTypeFile.push(`mapper_${attr.node.identifier}`);
                                
                                let configFormAdd = this.state.dataAddLayout;
                                configFormAdd.formConfig.children[1].optionsInputs.push(uploadFile);
                                configFormAdd.formConfig.children[2].optionsInputs.push(mapping);
    
                                this.setState({
                                    dataAddLayout: configFormAdd
                                });
                            }
    
                            else if(this.state.editForm === "edit"){
                                for(let retailer of this.state.retailers){
                                    if(retailer.node.id === this.state.editId){
                                        for(let attribute of retailer.node.retailerData.edges){
                                            if(attribute.node.attribute.id === attr.node.id && attribute.node.media.filePath){
                                                this.state[attribute.node.locale.code][`upload_${attr.node.identifier}`] = {file:{name:attribute.node.media.filePath}};
                                            }
                                        }
                                    }
                                }
                                let uploadFile = _.clone(setupUploadFile);
                                uploadFile.stateName = `upload_${attr.node.identifier}`;
                                uploadFile.label = label;
                                
                                let mapping = _.clone(setupMapping);
                                mapping.label = label;
                                mapping.mapOn = `upload_${attr.node.identifier}`;
                                mapping.stateName = `mapper_${attr.node.identifier}`;

                                if(this.props.type !== 'brand'){
                                    mapping.staticOptions = staticOptions;
                                }

                                let configFormAdd = this.state.dataAddLayout;
                                configFormAdd.formConfig.children[1].optionsInputs.push(uploadFile);
                                configFormAdd.formConfig.children[2].optionsInputs.push(mapping);

                                this.state.attrTypeFile.push(`upload_${attr.node.identifier}`);
                                this.state.attrTypeFile.push(`mapper_${attr.node.identifier}`);
                                
                                this.setState({ 
                                    dataAddLayout: configFormAdd, 
                                });
                            }
                        }

                        else if(attr.node.attributeType.input === "text"){
                            if(this.state.editForm === "add"){
                                let inputText = _.clone(setupInputText);
                                inputText.helper.text = label;
                                inputText.label = label;
                                inputText.stateName = attr.node.identifier;
                                
                                let mapping = _.clone(setupMapping);
                                mapping.label = label;
                                mapping.mapOn = `${attr.node.identifier}`;
                                mapping.stateName = `mapper_text_${attr.node.identifier}`;
                                if(this.props.type !== 'brand'){
                                    mapping.staticOptions = staticOptions;
                                }

                                let configFormAdd = this.state.dataAddLayout;

                                if(attr.node.identifier.match(/url/g)){
                                    this.state.attrTypeUrl.push(attr.node.identifier);
                                    this.state.attrTypeUrl.push(`mapper_text_${attr.node.identifier}`);
                                    configFormAdd.formConfig.children[1].optionsInputs.push(inputText);
                                    configFormAdd.formConfig.children[2].optionsInputs.push(mapping);
                                }

                                else if(attr.node.identifier.match(/ftp/g)){
                                    this.state.attrTypeFtp.push(attr.node.identifier);
                                    configFormAdd.formConfig.children[1].optionsInputs.push(inputText);

                                    if(attr.node.identifier.match(/filename/g)){
                                        this.state.attrTypeFtp.push(`mapper_text_${attr.node.identifier}`);
                                        configFormAdd.formConfig.children[2].optionsInputs.push(mapping);
                                    }
                                }
                                this.setState({ dataAddLayout: configFormAdd });
                            }
                            else if(this.state.editForm === 'edit'){
                                for(let retailer of this.state.retailers){
                                    if(retailer.node.id === this.state.editId){
                                        for(let attribute of retailer.node.retailerData.edges){
                                            if(attribute.node.value !== null){
                                                this.state[attribute.node.locale.code][attribute.node.attribute.identifier] = attribute.node.value;
                                            }
                                        }
                                    }
                                }
                                
                                let inputText = _.clone(setupInputText);
                                inputText.helper.text = label;
                                inputText.label = label;
                                inputText.stateName = attr.node.identifier;

                                let mapping = _.clone(setupMapping);
                                mapping.label = label;
                                mapping.mapOn = `${attr.node.identifier}`;
                                mapping.stateName = `mapper_text_${attr.node.identifier}`;
                                if(this.props.type !== 'brand'){
                                    mapping.staticOptions = staticOptions;
                                }

                                let configFormAdd = this.state.dataAddLayout;

                                if(attr.node.identifier.match(/url/g)){
                                    this.state.attrTypeUrl.push(attr.node.identifier);
                                    this.state.attrTypeUrl.push(`mapper_text_${attr.node.identifier}`);
                                    configFormAdd.formConfig.children[1].optionsInputs.push(inputText);
                                    configFormAdd.formConfig.children[2].optionsInputs.push(mapping);
                                }

                                else if(attr.node.identifier.match(/ftp/g)){
                                    this.state.attrTypeFtp.push(attr.node.identifier);
                                    configFormAdd.formConfig.children[1].optionsInputs.push(inputText);
                                    if(attr.node.identifier.match(/filename/g)){
                                        this.state.attrTypeFtp.push(`mapper_text_${attr.node.identifier}`);
                                        configFormAdd.formConfig.children[2].optionsInputs.push(mapping);
                                    }
                                }

                                this.setState({
                                    dataAddLayout: configFormAdd,
                                });
                            }
                        }
                    }
                }   
            }

            this.setState({importFile: this.props.type === "brand" ? 'ftp' : 'file'});
        });
    }

    handlerSaveRetailerData = () => {
        return new Promise(async (resolve, reject) => {
            if(this.state.editForm === "add"){
                for (let locale of this.props.locales){
                    for(let item of this.state.attr.edges){
                        if(this.state.importFile === 'file'){
                            if(this.state[locale.node.code][`upload_${item.node.identifier}`] && this.state[locale.node.code][`upload_${item.node.identifier}`].medias){
                                let value = this.state[locale.node.code][`upload_${item.node.identifier}`].medias.id;
    
                                let variables = {
                                    "retailer": this.state.idNewRetailer,
                                    "attribute": item.node.id,
                                    "locale": locale.node.id
                                };
                                    
                                variables.media = `/api/media-objects/${value}`;
    
                                await this.props.client.mutate({
                                    mutation: ADD_RETAILER_DATA,
                                    variables
                                });
                            }
                        }
                        else {
                            if(this.state[locale.node.code][item.node.identifier]){
                                let value = this.state[locale.node.code][item.node.identifier];
    
                                let variables = {
                                    "retailer": this.state.idNewRetailer,
                                    "attribute": item.node.id,
                                    "locale": locale.node.id,
                                    "value": value,
                                };

                                await this.props.client.mutate({
                                    mutation: ADD_RETAILER_DATA,
                                    variables
                                });
                            }
                        }
                    }
                }
            }

            else if(this.state.editForm === "edit"){
                let editRetailerData = {};
                for(let retailer of this.state.retailers){
                    if(retailer.node.id === this.state.editId){
                        editRetailerData = retailer.node.retailerData.edges;
                    }
                }

                for (let locale of this.props.locales){
                    for(let item of this.state.attr.edges){
                        if(this.state.importFile === 'file'){
                            if(this.state[locale.node.code][`upload_${item.node.identifier}`] && this.state[locale.node.code][`upload_${item.node.identifier}`].medias){
                                let value = this.state[locale.node.code][`upload_${item.node.identifier}`].medias.id;
                                let variables = {
                                    "retailer": this.state.editId,
                                    "attribute": item.node.id,
                                    "locale": locale.node.id,
                                    "media": `/api/media-objects/${value}`
                                };
        
                                let update = false;
                                for(let attribute of editRetailerData){
                                    if(attribute.node.attribute.identifier === item.node.identifier && attribute.node.locale.code === locale.node.code){
                                        update = attribute.node.id;
                                    }
                                }

                                if(update){
                                    variables.id = update;
                                    await this.props.client.mutate({
                                        mutation: UPDATE_RETAILER_DATA,
                                        variables
                                    });
                                }

                                else{
                                    await this.props.client.mutate({
                                        mutation: ADD_RETAILER_DATA,
                                        variables
                                    });
                                }
                            }
                        }
                        else {
                            if(this.state[locale.node.code][item.node.identifier]){
                                let value = this.state[locale.node.code][item.node.identifier];
                                let variables = {
                                    "retailer": this.state.editId,
                                    "attribute": item.node.id,
                                    "locale": locale.node.id,
                                    "value": value
                                };
                                let update = false;
                                for(let attribute of editRetailerData){
                                    if(attribute.node.attribute.identifier === item.node.identifier && attribute.node.locale.code === locale.node.code){
                                        update = attribute.node.id;
                                    }
                                }

                                if(update){
                                    variables.id = update;
                                    await this.props.client.mutate({
                                        mutation: UPDATE_RETAILER_DATA,
                                        variables
                                    });
                                }

                                else{
                                    await this.props.client.mutate({
                                        mutation: ADD_RETAILER_DATA,
                                        variables
                                    });
                                }
                            }
                        }
                    }
                }
            }
            this.setState({
                openForm: !this.state.openForm
            })
            this.props.stopLoading();
            this.handleGetRetailers();
            resolve();
        });
    }

    handlerMutation = () => {
        this.props.startLoading();
        let query = null;
        let variables = null;
        switch(this.state.editForm){
            case 'edit' :
                query = UPDATE_RETAILER;
                variables = 
                {
                    "id": this.state.editId,
                    "identifier": this.state.attributeIdentifier,
                    "description": this.state.description,
                    "libelle": this.state.libelle,
                    "attributeGroup": this.state.attrGroupId,
                    "isInternal": this.state.isInternal,
                }
                
            break;
            case 'add' :
                query = ADD_RETAILER;
                variables = 
                {
                    "identifier": this.state.attributeIdentifier,
                    "description": this.state.description,
                    "libelle": this.state.libelle,
                    "attributeGroup": this.state.attrGroupId,
                    "isInternal": this.state.isInternal,
                }
            break;
        }
        if(this.state.image){
            variables.logo = this.state.image.data
        }
        this.props.client.mutate({
            mutation: query,
            variables,
        }).then(result => {
            if(this.state.editForm === 'add'){
                this.setState({
                    idNewRetailer: result.data.createRetailer.retailer.id
                },() => {
                    this.handlerSaveRetailerData();
                    this.handlerImportFiles();
                });
            }
            else if(this.state.editForm === "edit"){
                this.setState({
                    idNewRetailer: this.state.editId
                },() => {
                    this.handlerSaveRetailerData();
                    this.handlerImportFiles();
                });
            }
        })
    }

    handlerFtpFile = async () => {
        for( let locale of this.props.locales){
            if(this.state[locale.node.code].retailer_ftp_host && this.state[locale.node.code].retailer_ftp_port && this.state[locale.node.code].retailer_ftp_username && this.state[locale.node.code].retailer_ftp_password && this.state[locale.node.code].retailer_ftp_path && this.state[locale.node.code].retailer_ftp_filename){
                let formData = new FormData();
                formData.append('retailer_ftp_host', this.state[locale.node.code].retailer_ftp_host)
                formData.append('retailer_ftp_port', this.state[locale.node.code].retailer_ftp_port)
                formData.append('retailer_ftp_username', this.state[locale.node.code].retailer_ftp_username)
                formData.append('retailer_ftp_password', this.state[locale.node.code].retailer_ftp_password)
                formData.append('retailer_ftp_path', this.state[locale.node.code].retailer_ftp_path)
                formData.append('retailer_ftp_filename', this.state[locale.node.code].retailer_ftp_filename)
                formData.append('retailer_file_type', this.state.retailer_file_type)
                
                try {
                    request(`${process.env.REACT_APP_API_ROOT}/api/retailer/ftp/`, 'post', formData, 'multipart/form-data').then(
                        (data) => {
                            this.state[locale.node.code].retailer_ftp_filename_import = data.fileUrl;
                        }
                    );
                    this.props.snack(ALERT_SUCCESS, `Succès !`);
                } catch(e) {
                    this.props.snack(ALERT_ERROR, `Echec !`);
                }
            }
        }
    }

    getHeaderFtp = (item, locale) => {
        return new Promise(async (resolve, reject) => {
            let mapOn = item.node.identifier + "_import";

            let headersFormData = new FormData();
            headersFormData.append('csv', this.state[locale.node.code][mapOn]);
            headersFormData.append('separator', this.state.importSep);
            
            let resultHeaders = await axios(`${process.env.REACT_APP_API_ROOT}/api/export/csv/structure`, 'post', headersFormData);

            let mapper = [];
            let mapping = this.state[locale.node.code][`mapper_text_${item.node.identifier}`];

            for(let header of resultHeaders.message){
                mapper.push(mapping[header])
            }

            let importConfig = {
                "url": this.state[locale.node.code][mapOn],
                "mapper": mapper,
                "eavType": this.props.attributes.eavTypes.find(e => e.node.code === 'retailer').node.id,
                "locale": locale.node.id,
                "retailer": this.state.idNewRetailer,
                "delimiter": this.state.retailer_file_type === "retailer_file_type_csv" ?  this.state.importSep : null,
            };
            
            try {
                // todo thomas
                await fetch(`${process.env.REACT_APP_API_ROOT}/api/file-imports`, 'post', importConfig, undefined, true);
                this.props.snack(ALERT_SUCCESS, `L'import a réussi !`);
                this.props.stopLoading();
            } catch(e) {
                this.props.snack(ALERT_ERROR, `L'import a échoué !`);
                this.props.stopLoading();
            }
            resolve();
        });
    }

    getHeaderUrl = (item, locale) => {
        return new Promise(async (resolve, reject) => {
            let headersFormData = new FormData();
            headersFormData.append('csv', this.state[locale.node.code][item.node.identifier]);
            headersFormData.append('separator', this.state.importSep);
            
            let resultHeaders = await axios(`${process.env.REACT_APP_API_ROOT}/api/export/csv/structure`, 'post', headersFormData);

            let mapper = [];
            let mapping = this.state[locale.node.code][`mapper_text_${item.node.identifier}`];
            for(let header of resultHeaders.message){
                mapper.push(mapping[header])
            }

            let importConfig = {
                "url": this.state[locale.node.code][item.node.identifier],
                "mapper": mapper,
                "eavType": this.props.attributes.eavTypes.find(e => e.node.code === 'retailer').node.id,
                "locale": locale.node.id,
                "retailer": this.state.idNewRetailer,
                "delimiter": this.state.importSep,
            };
            
            try {
                // todo thomas
                await fetch(`${process.env.REACT_APP_API_ROOT}/api/file-imports`, 'post', importConfig, undefined, true);
                this.props.snack(ALERT_SUCCESS, `L'import a réussi !`);
                this.props.stopLoading();
            } catch(e) {
                this.props.snack(ALERT_ERROR, `L'import a échoué !`);
                this.props.stopLoading();
            }
            resolve();
        });
    }

    handlerImportFiles = async () => {
        for( let locale of this.props.locales){
            for(let item of this.state.attr.edges){
                if(this.state.importFile === 'file'){
                    if(item.node.identifier && this.state[locale.node.code][`upload_${item.node.identifier}`]){
                        if(this.state[locale.node.code][`mapper_${item.node.identifier}`]?.length !== [] && this.state[locale.node.code][`upload_${item.node.identifier}`] !== null && this.state[locale.node.code][`upload_${item.node.identifier}`].headers){
                            let mapper = [];
                            let mapping = this.state[locale.node.code][`mapper_${item.node.identifier}`];
                            for(let header of this.state[locale.node.code][`upload_${item.node.identifier}`].headers){
                                mapper.push(mapping[header])
                            }
                            let importConfig = {
                                "url": `${process.env.REACT_APP_API_ROOT}${this.state[locale.node.code][`upload_${item.node.identifier}`].medias?.contentUrl ?? null}`,
                                "mapper": mapper,
                                "eavType": this.props.attributes.eavTypes.find(e => e.node.code === 'retailer').node.id,
                                "locale": locale.node.id,
                                "retailer": this.state.idNewRetailer,
                                "delimiter": this.state.importSep,
                            };
                            try {
                                // todo thomas
                                await fetch(`${process.env.REACT_APP_API_ROOT}/api/file-imports`, 'post', importConfig, undefined, true);
                                this.props.snack(ALERT_SUCCESS, `L'import a réussi !`);
                                this.props.stopLoading();
                            } catch(e) {
                                this.props.snack(ALERT_ERROR, `L'import a échoué !`);
                                this.props.stopLoading();
                            }
                        }
                    }
                }
                else if(this.state.importFile === 'ftp') {
                    if(item.node.identifier.match(/ftp_filename/g) && this.state[locale.node.code][item.node.identifier]){
                        this.getHeaderFtp(item, locale);
                    }
                }
                else if(this.state.importFile === "url"){
                    if(item.node.identifier.match(/url/g) && this.state[locale.node.code][item.node.identifier]){
                        this.getHeaderUrl(item, locale);
                    }
                }
            }
        }
    }

    handleGetAttributGroup = () => {
        return new Promise(async (resolve, reject) => {
            this.props.client.query({
                query: GET_ATTR_GROUPE_LIGHT,
                variables: {"is_internal": this.state.isInternal},
                fetchPolicy: "no-cache"
            }).then(result => {
                for(let item of result.data.attributeGroups.edges){
                    if(item.node.identifier === this.props.type){
                        this.setState({idAttrGroup: item.node.id, attrGroup: true});
                    }
                }
                resolve();
            });
        });
    }

    handleGetRetailers = async () => {
        if(!this.state.idAttrGroup || !this.state.attrGroup){
            await this.handleGetAttributGroup();
        }
        this.props.client.query({
            query: GET_RETAILERS,
            variables: {"is_internal": this.state.isInternal, "attributeGroup": this.state.idAttrGroup },
            fetchPolicy: "no-cache"
        }).then(result => {
            this.setState({
                retailers: result.data.retailers.edges,
                filtered: result.data.retailers.edges,
                categories: [],
                ready: true,
            });
            if(result.data.retailers.edges.length === 0){
                this.setState({
                    isEmpty: true
                })
            }else{
                this.setState({
                    isEmpty: false
                })
            }
        })
    }

    deleteRetailerDatas = () => {
        return new Promise(async (resolve, reject) => {
            for(let retailer of this.state.retailers){
                if(retailer.node.id === this.state.editId){
                    for(let attr of retailer.node.retailerData.edges){
                        await this.props.client.mutate({
                            mutation: DELETE_RETAILER_DATA,
                            variables: {"id": attr.node.id}
                        })
                    }
                }
            }
            resolve();
        });
    }

    deleteChannel = () => {
        this.props.client.mutate({
            mutation: DELETE_RETAILER,
            variables: {"id": this.state.editId},
        }).then(result =>{
            this.handleGetRetailers();
            this.handleToggleDrawer();
            this.setState({ 
                openDialogRemove: false
            });
        });
    }
    
    deleteMutation = async () => {
        this.props.startLoading();
        await this.deleteRetailerDatas();
        await this.deleteChannel(); 
        await this.props.stopLoading();
    }

    editChannel = async (id, identifier) => {
        this.setState({
            editForm: "edit",
            editId: id,
            editIdentifier: identifier,
        }, () => {
            this.props.type === "retailer" ? 
                this.state.dataAddLayout.titleForm = "Modifier le retailer"            
            : this.props.type === "flow" ?
                this.state.dataAddLayout.titleForm = "Modifier le flux"
            :
                this.state.dataAddLayout.titleForm = "Modifier la marque";
            this.handlerSetup(this.state.editId);
            this.handleToggleDrawer();
        });
    }

    handleToggleDialog = () => {
        this.setState({openDialogRemove : !this.state.openDialogRemove});
    }
    
    handleToggleDrawerCron = () => {
        this.setState({drawerCron: false});
    }

    getListCronByRetailer = (id, retailer) => {
        this.setState({
            listCrons: null,
            drawerCron: true,
            retailerCron: retailer
        });

        try {
            request(`${process.env.REACT_APP_API}/scheduler/retailer/${id}`, 'get', false).then(
                (data) => {
                    if(data.success){
                        this.setState({listCrons: data.crons})
                    }
                }
            )
        } catch(e) {
            console.log(e);
        }
    }

    handleToggleDrawer = () => {
        this.setState({ 
            openForm : !this.state.openForm,
            libelle: undefined,
            description: undefined,
            image: undefined,
        }, () => {
            if(this.state.editForm === "edit" && this.state.openForm){
                for(let retailer of this.state.retailers){
                    if(retailer.node.id === this.state.editId){
                        this.setState({
                            libelle: retailer.node.libelle,
                            description: retailer.node.description,
                            image: {changed: false, data: retailer.node.logo},
                            attributeIdentifier: retailer.node.identifier
                        });
                    }
                }
                let children = 0;
                for(let item of this.state.dataAddLayout.formConfig.children){
                    let optionsInputs = 0;
                    for(let input of item.optionsInputs){
                        if(input.stateName === "attributeIdentifier"){
                            let getLayoutAdd = this.state.dataAddLayout
                            getLayoutAdd.formConfig.children[children].optionsInputs[optionsInputs].disabled = true
                            this.setState({
                                dataAddLayout: getLayoutAdd
                            });
                        }
                        optionsInputs++;
                    }
                    children++;
                }
            }
            else if(this.state.editForm === "add" && this.state.openForm){
                this.setState({
                    libelle: undefined,
                    description: undefined,
                    image: undefined,
                    attributeIdentifier: undefined,
                });
                this.handlerSetup();
                let children = 0;
                for(let item of this.state.dataAddLayout.formConfig.children){
                    let optionsInputs = 0;
                    for(let input of item.optionsInputs){
                        if(input.stateName === "attributeIdentifier"){
                            let getLayoutAdd = this.state.dataAddLayout
                            getLayoutAdd.formConfig.children[children].optionsInputs[optionsInputs].disabled = false
                            this.setState({
                                dataAddLayout: getLayoutAdd
                            });
                        }
                        optionsInputs++;
                    }
                    children++;
                }
            }

            if(!this.state.openForm){
                this.setState({
                    editForm: "add",
                });
                this.state.dataAddLayout.titleForm = this.props.type === "retailer" ? "Ajouter un retailer" : this.props.type === "flow" ? "Ajouter un flux" : "Ajouter une marque";
            }
        });
    }

    handleFormImport = (type, title) => {
        this.setState({
            catalogFormData: {
                type,
                title
            },
            mapper: [],
            media: null,
            headers: [],
            importSep: ',',
            importValues: {},
            importLang: false
        });
    };

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }
        return false;
    };

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;
        errors[stateName] = error;
        this.setState({ errors });
    };

    componentDidMount(){
        let mainRight = null;
        let secondaryRight = null;

        if (this.props.isInternal){
            mainRight = BRAND; 
            secondaryRight = BRAND_FLOW; 
            this.setState({
                mainRight,
                secondaryRight,
            })
        }else{
            mainRight = CONNECTORS; 
            secondaryRight = CONNECTORS_RETAILERS; 
            this.setState({
                mainRight,
                secondaryRight,
            })
        }
        const getRightsStatus = hasRights(mainRight, secondaryRight, VIEW)
        if (!getRightsStatus){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }
        this.handleGetRetailers();
        let finalStep = 
        {
            picto: EmptyRetailers,
            title: 'Et voilà !',
            subtitle: 'Votre nouveau compte est prêt à être connecté à SpreadSuite !',
            textButton: 'Connecter'
        }
        let getLayoutAdd = this.state.dataAddLayout;
        getLayoutAdd.formConfig.finalStep = finalStep;
        getLayoutAdd.titleForm = this.state.content.titleForm;
        this.setState({
            dataAddLayout: getLayoutAdd
        });
    }
    
    componentDidUpdate(prevProps, prevState){
        let mainRight = null;
        let secondaryRight = null;
        if(this.props.isInternal !== prevProps.isInternal || this.props.type !== prevProps.type){
            if (this.props.isInternal){
                mainRight = BRAND; 
                secondaryRight = BRAND_FLOW; 
                this.setState({
                    mainRight,
                    secondaryRight,
                })
            }else{
                mainRight = CONNECTORS; 
                secondaryRight = CONNECTORS_RETAILERS; 
                this.setState({
                    mainRight,
                    secondaryRight,
                })
            }
            this.setState({
                filtered: [],
                retailer: [],
                openForm: false,
                editForm: 'add',
                isInternal: this.props.isInternal,
                attrGroup: false,
                content:   this.props.type === "retailer" ? 
                        {
                            title: process.env.REACT_APP_MODE_SPREAD !== "hub" ? "Gérer les retailers" : "Gérer les entrepôts",
                            subtitle: process.env.REACT_APP_MODE_SPREAD !== "hub" ? "Gestion de vos retailers (création / modification / suppression)" : "Gérer vos entrepôts (création / modification / suppression)",
                            picto: "picto-retailer",
                            txtBtn: process.env.REACT_APP_MODE_SPREAD !== "hub" ? "Ajouter un retailer" : null,
                            titleForm: "Ajouter un retailer",
                            emptyTitle: "Vous n’avez pas encore configuré de retailers",
                            emptySubtitle: "Cliquez sur le bouton ci-dessous pour en ajouter un",
                            emptyTxtBtn: "Ajouter un retailer",
                            emptyPicto: EmptyRetailers,
                        }
                    : this.props.type === "flow" ?
                        {
                            title: "Gérer mes flux",
                            subtitle: "Gestion de vos flux (création / modification / suppression)",
                            picto: "icon-flux",
                            txtBtn: "Ajouter un flux",
                            titleForm: "Ajouter un flux",
                            emptyTitle: "Vous n’avez pas encore configuré de flux",
                            emptySubtitle: "Cliquez sur le bouton ci-dessous pour en ajouter un",
                            emptyTxtBtn: "Ajouter un nouveau flux",
                            emptyPicto: EmptyFlux,
                        }
                    :
                        {
                            title: process.env.REACT_APP_MODE_SPREAD !== "hub" ? "Gérer mes marques" : "ERP",
                            subtitle: process.env.REACT_APP_MODE_SPREAD !== "hub" ? "Gestion de vos marques (création / modification / suppression)" : "Gestion des ERP (création / modification / suppression)",
                            picto: "icon-business",
                            txtBtn: process.env.REACT_APP_MODE_SPREAD !== "hub" ? "Ajouter une marque" : null,
                            titleForm: "Ajouter une marque",
                            emptyTitle: "Vous n’avez pas encore configuré de marque",
                            emptySubtitle: "Cliquez sur le bouton ci-dessous pour en ajouter une",
                            emptyTxtBtn: "Ajouter un nouvelle marque",
                            emptyPicto: EmptyRetailers,
                        },
            }, () => {
                this.handleGetRetailers();
                let finalStep = 
                {
                    picto: EmptyRetailers,
                    title: 'Et voilà !',
                    subtitle: 'Votre nouveau compte est prêt à être connecté à SpreadSuite !',
                    textButton: 'Connecter'
                }
                let getLayoutAdd = this.state.dataAddLayout;
                getLayoutAdd.formConfig.finalStep = finalStep;
                getLayoutAdd.titleForm = this.state.content.titleForm;
                this.setState({
                    dataAddLayout: getLayoutAdd
                });
            })
        }
        if(this.state.importFile !== prevState.importFile){
            let inputHidden = []; 
            if(this.state.importFile === 'file'){
                this.setState({
                    inputHidden: []
                }, () => {
                    for(let item of this.state.attrTypeUrl){
                        inputHidden.push(item)
                    }
                    for(let item of this.state.attrTypeFtp){
                        inputHidden.push(item);
                    }
                    inputHidden.push('retailer_file_type');
                });
            }
            else if(this.state.importFile === 'url'){
                this.setState({
                    inputHidden: []
                }, () => {
                    
                    for(let item of this.state.attrTypeFile){
                        inputHidden.push(item)
                    }
                    for(let item of this.state.attrTypeFtp){
                        inputHidden.push(item);
                    }
                    inputHidden.push('retailer_file_type');
                })
            }
            else if(this.state.importFile === 'ftp') {
                this.setState({
                    inputHidden: []
                }, () => {
                    for(let item of this.state.attrTypeUrl){
                        inputHidden.push(item)
                    }
                    for(let item of this.state.attrTypeFile){
                        inputHidden.push(item);
                    }
                });
                if(this.state.retailer_file_type !== "retailer_file_type_csv"){
                    this.setState({importSep: ','});
                    inputHidden.push("importSep");
                }
            }
            this.setState({inputHidden})
        }

        if(this.state.retailer_file_type !== prevState.retailer_file_type){
            let inputHidden = []; 
            if(this.state.retailer_file_type === "retailer_file_type_csv"){
                this.setState({
                    inputHidden: []
                }, () => {
                    for(let item of this.state.attrTypeUrl){
                        inputHidden.push(item)
                    }
                    for(let item of this.state.attrTypeFile){
                        inputHidden.push(item);
                    }
                    
                });
            }
            else{
                this.setState({
                    inputHidden: [],
                    importSep: ',',
                }, () => {
                    for(let item of this.state.attrTypeUrl){
                        inputHidden.push(item)
                    }
                    for(let item of this.state.attrTypeFile){
                        inputHidden.push(item);
                    }
                    inputHidden.push("importSep");
                });
            }
            this.setState({inputHidden})
        }
        
    }  

    render() {
        return (
            <div>
                {this.state.content ?
                    (
                        <TopPanel 
                            icomoon={this.state.content.picto} 
                            colorIcomoon={colors.blue.lighter.hue300} 
                            title={this.state.content.title} 
                            subtitle={this.state.content.subtitle} 
                            gradientColor1={colors.menu.regular} 
                            gradientColor2={colors.menu.darker} 
                            handlerAdd={() => {this.handleToggleDrawer()}} 
                            textAdd={hasRights(this.state.mainRight, this.state.secondaryRight, CREATE) ? this.state.isEmpty ? null : this.state.retailers ? this.state.content.txtBtn : null : null} 
                            searchHandler={(this.state.isEmpty || !this.state.retailers) ? null : this.handleChange} 
                            searchStyle={this.state.isEmpty ? {paddingTop: 16}: ''} 
                            openForm={this.state.openForm}
                            buttonAvailable={this.state.openForm ? false : true}
                            windowWidth={this.props.windowWidth} 
                            noResult={!this.state.filtered.length > 0}
                            hasBorder={true}
                        />
                    ) : null
                }
                <Grid container direction="column" justify="center" spacing={0} style={{width: this.state.openForm && this.state.isEmpty ? `calc(100% - ((50% - ${this.props.drawerWidth}px / 2) + (${this.props.drawerWidth}px / 2) + 32px))` : "100%", transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms', marginTop: 16}}>
                    <Grid container direction="row" spacing={2}>
                        {this.state.isEmpty ? 
                            <EmptyCard title={hasRights(this.state.mainRight, this.state.secondaryRight, CREATE) ? this.state.content.emptyTitle : this.state.mainRight === BRAND ? "Vous n'avez pas les droits pour ajouter un flux" : "Vous n'avez pas les droits pour ajouter un retailer"} subtitle={hasRights(this.state.mainRight, this.state.secondaryRight, CREATE) ? this.state.content.emptySubtitle : "Faite une demande auprès d'un administrateur"} textButton={hasRights(this.state.mainRight, this.state.secondaryRight, CREATE) ? this.state.content.emptyTxtBtn : null} onClick={() => {this.handleToggleDrawer()}} picto={this.state.content.emptyPicto} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />
                        :
                            this.state.ready ? 
                                <>
                                    {
                                        this.state.filtered.length > 0 ? (
                                            this.state.filtered.map((type, index) =>
                                                <GridCustom item lg={4} sm={6} xs={12} key={`ListRetailers${index}`}>
                                                    <CardCustom paddingbottom={0} paddingtop={0} style={{width: "100%"}} contentpadding={'0 0px 10px 0px'}>
                                                        <CardSetup type={type} textButton={'Set up'} handlerEdit={hasRights(this.state.mainRight, this.state.secondaryRight, UPDATE) && process.env.REACT_APP_MODE_SPREAD !== "hub" ? () => this.editChannel(type.node.id, type.node.identifier) : null} cronListOpening={this.getListCronByRetailer} isRetailer={true}/>
                                                    </CardCustom>
                                                </GridCustom>
                                            )
                                        )
                                        : (<EmptyCard title={"Aucun résultat pour cette recherche"} textButton={hasRights(this.state.mainRight, this.state.secondaryRight, CREATE) && process.env.REACT_APP_MODE_SPREAD !== "hub" ? this.state.content.emptyTxtBtn : null} onClick={() => {this.handleToggleDrawer('form')}} picto={this.state.content.emptyPicto} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />) 
                                    }
                                </>
                            : (<PageLoader />)
                        }
                    </Grid>
                </Grid>
                {
                    this.state.dataAddLayout ?
                    (
                        <LayoutBuilder 
                            opened={this.state.openForm}
                            icomoon={ this.props.type === "retailer" ? 'picto-retailer' : this.props.type === "flow" ? 'icon-flux' : 'icon-business'} gradientColor1={colors.menu.regular} gradientColor2={colors.menu.darker}
                            forClose={() => {this.handleToggleDrawer()}} 
                            dataLayout={this.state.dataAddLayout} 
                            drawerWidth={this.props.drawerWidth}
                            stateCallback={this.stateCallback}
                            handleButtonGroupChange={this.handleButtonGroupChange}
                            stepperButtonAction={[
                                () => {
                                    if (this.hasErrors()) {
                                        this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                                        this.setState({ seeErrors: true });
                                        eventService.fire();
                                        return false;
                                    }
                                    return true;
                                }, 
                                () => {
                                    this.handlerFtpFile();
                                    if (this.hasErrors()) {
                                        this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                                        this.setState({ seeErrors: true });
                                        eventService.fire();
                                        return false;
                                    }
                                    return true;
                                }, null
                            ]}
                            errorCallback={this.handleFormError}
                            stepperSkip={3}
                            validateButton={true}
                            deleteMutation={hasRights(this.state.mainRight, this.state.secondaryRight, DELETE) && this.state.editForm === 'edit' ? this.handleToggleDialog : null}
                            stepperButtonDisabled={[null, null, null]}
                            deleteText={this.state.editForm === 'edit' ? 'Supprimer' : null}
                            handlerMutation={() => {this.handlerMutation()}}
                            allState={this.state} 
                            currentLang={this.state.currentLang}
                            handleLang={this.handleLang}
                            hideInput={this.state.inputHidden}
                            windowWidth={this.props.windowWidth}
                        />
                    ) : null
                }
                <DialogModal 
                    open={this.state.openDialogRemove} 
                    title={`Êtes-vous sûr de vouloir supprimer ${ this.props.type === "retailer" ? " ce retailer " : this.props.type === "flow" ? " ce flux " : " cette marque " }?`}
                    secondaryAction={this.handleToggleDialog} secondarycolor={colors.grey.regular} secondarybgcolor={colors.white} secondarybgcolorhover={colors.grey.lighter.hue900} secondaryborder={`1px solid ${colors.grey.regular}`}
                    primaryAction={this.deleteMutation} primarybgcolor={colors.red.regular} primarybgcolorhover={colors.red.darker} primaryText="Supprimer"
                >
                    Si vous supprimez{ this.props.type === "retailer" ? " ce retailer celui-ci " : this.props.type === "flow" ? " ce flux celui-ci " : " cette marque celle-ci "}ne sera plus accessible. Si vous ne souhaitez pas le supprimer, annulez la suppression en cliquant sur annuler.
                </DialogModal>
                <CronNotifications closeDrawer={()=> this.setState({drawerCron: false})} drawerCron={this.state.drawerCron} retailerCron={this.state.retailerCron} listCrons={this.state.listCrons} />
            </div>
        );
    }

    goTo = route => {
        this.props.history.push(route);
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        typeOf: state.typeOf,
        locales: state.locales,
        attributes: state.attributes
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};

export default withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(ListRetailers)));