import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import * as moment from 'moment';
import Badge from '@material-ui/core/Badge';
import colors from '../../../../../config/theme/colors';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import CheckIcon from '@material-ui/icons/Check';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import Avatar from '@material-ui/core/Avatar';

const WrapperButton = styled.a`
    border-radius: 4px;
    color: white;
    padding: 4px 12px;
    width: 190px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    background: ${colors.blue.lighter.hue150};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 210px;
    &:hover{
        background: ${colors.blue.regular};
    }
    &.error{
        background: ${colors.orange.regular};
        &:hover{
            background: ${colors.orange.darker};
        }
    }
`;

const BadgeCustom = styled(Badge)`
    display: block;
    padding: 0;
    &>span{
        background-color: ${props => props.bgcolor ? props.bgcolor : colors.white};
        color: ${props => props.bgcolor ? colors.white : colors.black};
        left: -50px;
        position: absolute;
        right: initial;
        width: 100px;
    }
`;

const columns = [
    { id: 'status', label: 'Status', minWidth: 100 },
    { id: 'type', label: 'Catégorie', minWidth: 170 },
    { id: 'name', label: 'Nom', minWidth: 170 },
    { id: 'typeCron', label: 'Type de Cron', minWidth: 170 },
    { id: 'errorFile', label: "Fichier", minWidth: 210 },
    { id: 'date', label: "Date", minWidth: 10, align: 'center'}
];

function createData(status, type, name, typeCron, errorFile, date) {
    return { status, type, name, typeCron, errorFile, date};
}

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 'calc(100vh - 300px)',
    },
});

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: colors.blue.regular,
    },
    tooltip: {
        backgroundColor: colors.blue.regular,
        fontSize: 16
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

export default function ArrayHistory(props) {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rows, setRows] = React.useState([]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        if(props.rows){
            let temporaryRows= [];
            for(let row of props.rows){
                temporaryRows.push(
                    createData(
                        row.status, 
                        row.details.eavType,
                        {  
                            name: row.details.retailer, 
                            logo: row.details.retailer_logo
                        },
                        row.type,
                        {
                            file: (row.details.errorFile ? row.details.errorFile : row.details.file ? row.details.file : null), 
                            type: (row.details.errorFile ? 'error' : row.details.file ? 'file' : null)
                        }, 
                        `Début le : ${moment(row.start).format('DD/MM/YYYY HH:MM:SS')} ${row.end ? (`, Terminé le : ${moment(row.end).format('DD/MM/YYYY HH:MM:SS')}`) : ''}`
                    )
                );
            }
            setRows(temporaryRows);
        }
    }, [props.rows]);

    return (
        <Paper className={classes.root} style={props.style}>
        <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>
                {columns.map((column) => (
                    <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth}}
                    >
                        <div style={{display: 'inline-flex', alignItems: 'center'}}>
                            {column.label}
                        </div>
                    </TableCell>
                ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    rows ? (
                        rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={`${row.code} ${index}`}>
                                {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                        <TableCell key={column.id} align={column.align} style={{padding: "6px 16px"}} >
                                            {
                                                column.id === 'status' ?
                                                    (
                                                        <BadgeCustom
                                                            bgcolor={
                                                                row.status === "success" ? 
                                                                    colors.green.regular 
                                                                : row.status === "pending" ? 
                                                                    colors.orange.regular 
                                                                : row.status === "skipped" ? 
                                                                    colors.grey.regular
                                                                : row.status === "running" ?
                                                                    colors.blue.lighter.hue300
                                                                :
                                                                    colors.red.regular
                                                            } 
                                                            badgeContent={
                                                                row.status === "success" ? 
                                                                    "Succès" 
                                                                : row.status === "pending" ? 
                                                                    "En attente" 
                                                                : row.status === "skipped" ?
                                                                    "Ignoré"
                                                                : row.status === "running" ?
                                                                    "En cours"
                                                                :
                                                                    "Erreur"
                                                            } 
                                                        />
                                                    )
                                                : column.id === 'errorFile' ?
                                                    value.file ?
                                                        (
                                                            <WrapperButton className={value.type} href={value.type === "error" ? `${process.env.REACT_APP_API_ROOT}/${value.file}` : `${value.file}`} target="_blank" download>
                                                                {value.type === "error" ? 
                                                                    (
                                                                        <ReportProblemOutlinedIcon style={{marginRight: 12}} />
                                                                    ) : <CheckIcon style={{marginRight: 12}} />
                                                                }
                                                                {value.type === "error" ? 
                                                                    (
                                                                        "Télécharger le rapport"
                                                                    ) : "Télécharger le fichier"
                                                                }
                                                            </WrapperButton>
                                                        )
                                                    : null
                                                : column.id === 'name' ? 
                                                    (
                                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                                            {
                                                                value.logo ? (
                                                                    <Avatar style={{marginRight: 10, width: 30, height: 30}} src={`${process.env.REACT_APP_API_ROOT}/${value.logo}`} />
                                                                ) : value.name ? (
                                                                    <Avatar style={{marginRight: 10, width: 30, height: 30}}>{value.name.charAt(0)}</Avatar>
                                                                ) : null
                                                            }
                                                            <span>{value.name}</span>
                                                        </div>
                                                    )
                                                : column.id === 'date' ? 
                                                    (
                                                        <BootstrapTooltip title={value ? (<span dangerouslySetInnerHTML={{__html: value.replace(',', '<br />')}} />) : null} 
                                                            style={{fontSize: 20}}
                                                        >
                                                            <QueryBuilderIcon />
                                                        </BootstrapTooltip>
                                                    )
                                                : value
                                            }
                                        </TableCell>
                                    );
                                })}
                                </TableRow>
                            );
                        })
                    ) : null
                }
            </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelDisplayedRows={ (from=page) => (`${from.from}-${from.to === -1 ? from.count : from.to} sur ${from.count}`)}
            labelRowsPerPage="Nombre par page :"
        />
        </Paper>
    );
}
