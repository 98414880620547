import {CARD} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';

const AssetsConfig = 
    {
        langSelect: false,
        drawerType: 'swipeable',
        noForm: true,
        component: Button,
        formConfig: {
            type: CARD,

        },

    }

export default AssetsConfig;