import { v4 as uuidv4 } from "uuid";
import { FormInputTypes, EditableTypes, ItemTypes } from '../shareable/types';

export const getDefaultTextSimple = (name, value, size, color, textAlign = null) => ({
    type: EditableTypes.SHORT_TEXT,
    name,
    id: uuidv4(),
    inputs: {
        value: {
            type: FormInputTypes.TEXT,
            label: "Changer le texte",
            value
        },
        size: {
            type: FormInputTypes.SLIDER,
            value: size,
            label: "Taille du texte",
            params: {max: 100, min: 8, step: 2},
        },
        color: {
            type: FormInputTypes.COLOR,
            value: color,
            label: "Couleur du texte"
        },
        textAlign: {
            type: FormInputTypes.SELECT,
            label: "Alignement du texte",
            value: textAlign,
            params: [{label: 'Initial', value: 'initial'}, {label: 'Centré', value: 'center'}, {label: 'Droite', value: 'end'}]
        }
    },
});

export const getDefaultText = (name, value, size, color, backgroundColor = null, textTransform = null, textAlign = null, backgroundImage = null) => {
    let inputs = {
        size: {
            type: FormInputTypes.SLIDER,
            value: size,
            label: "Taille du texte",
            params: {max: 100, min: 8, step: 2},
        },
        color: {
            type: FormInputTypes.COLOR,
            value: color,
            label: "Couleur du texte"
        },
        backgroundColor: {
            type: FormInputTypes.COLOR,
            value: backgroundColor,
            label: "Couleur de fond"
        },
        backgroundImage: {
            label: 'Image de fond',
            type: FormInputTypes.IMAGE,
            value: backgroundImage,
            isCroppable: true
        },
        textTransform: {
            type: FormInputTypes.SELECT,
            label: "Transformation du texte",
            value: textTransform,
            params: [{label: 'Initial', value: 'initial'}, {label: 'Tout Majuscule', value: 'uppercase'}, {label: 'Tout Minuscule', value: 'lowercase'}]
        },
        textAlign: {
            type: FormInputTypes.SELECT,
            label: "Alignement du texte",
            value: textAlign,
            params: [{label: 'Initial', value: 'initial'}, {label: 'Centré', value: 'center'}, {label: 'Droite', value: 'end'}]
        }
    };
    
    let optional = {};

    if (value !== null) {
        optional.value = {
            type: FormInputTypes.TEXT,
            label: "Changer le texte",
            value
        };
    }

    inputs = { ...optional, ...inputs };
    
    return {
        type: EditableTypes.SHORT_TEXT,
        name,
        id: uuidv4(),
        inputs,
    };
};

export const getDefaultTextarea = (name, value, html, color = null, backgroundColor = null, textTransform = null, textAlign = null, size = 14) => {
    let inputs = {
        value: {
            type: html ? FormInputTypes.WYSIWYG : FormInputTypes.TEXTAREA,
            value
        }
    };

    if (!html) {
        inputs.size = {
            type: FormInputTypes.SLIDER,
            value: size,
            label: "Taille du texte",
            params: {max: 100, min: 8, step: 2},
        };
    }
    
    inputs = {
        ...inputs,
        color: {
            type: FormInputTypes.COLOR,
            value: color,
            label: "Couleur du texte"
        },
        backgroundColor: {
            type: FormInputTypes.COLOR,
            value: backgroundColor,
            label: "Couleur de fond"
        },
        textTransform: {
            type: FormInputTypes.SELECT,
            label: "Transformation du texte",
            value: textTransform,
            params: [{label: 'Initial', value: 'initial'}, {label: 'Tout Majuscule', value: 'uppercase'}, {label: 'Tout Minuscule', value: 'lowercase'}]
        },
        textAlign: {
            type: FormInputTypes.SELECT,
            label: "Alignement du texte",
            value: textAlign,
            params: [{label: 'Initial', value: 'initial'}, {label: 'Centré', value: 'center'}, {label: 'Droite', value: 'end'}]
        }
    }

    return {
        type: EditableTypes.LONG_TEXT,
        name,
        id: uuidv4(),
        inputs
    }
};

export const getDefaultSlide = (
    img = "https://via.placeholder.com/1920x720", 
    subtitle = "Lorem ipsum", 
    title = "LOREM IPSUM", 
    text = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tincidunt, lorem nec faucibus luctus."
) => ({
    subtitle: "Subtitle",
    text: "Text",
    id: 0,
    blocks: {
        image: {
            type: EditableTypes.IMAGE,
            name: "Image",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: img
                }
            }
        },
        title: getDefaultText("Titre", title, 18, "#fff", "rgba(0, 0, 0, 0)"),
        subtitle: getDefaultText("Sous-titre", subtitle, 72, "#fff", "rgba(0, 0, 0, 0)"),
        text: getDefaultText("Texte", text, 22, "#fff", "rgba(0, 0, 0, 0)")
    }
});

export const getDefaultSimpleSlide = (
    img = "https://via.placeholder.com/925x460", 
    link = "https://google.com"
) => ({
    subtitle: "Subtitle",
    text: "Text",
    id: 0,
    inputs: {
        image: {
            type: FormInputTypes.IMAGE,
            value: img
        },
        link: {
            type: FormInputTypes.LINK,
            value: link
        }
    }
});

export const textDefault = (value = "Lorem ipsum dolor sit amet, consectetur adipisicing elit.", html) => ({
    paddingTop: 95,
    paddingBottom: 90,
    blocks: {
        content: getDefaultTextarea("Content", value, html),
    }
});

export const imageDefault = (value = "Lorem ipsum dolor sit amet, consectetur adipisicing elit.") => ({
    paddingTop: 95,
    paddingBottom: 90,
    name: "Image",
    type: EditableTypes.MINI_BLOCKS,
    blocks: {
        image: {
            type: EditableTypes.IMAGE,
            name: "Image",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: null
                }
            }
        }
    }
});

export const getImageDefault = (value = null, name = "Image", isCroppable = true) => ({
    type: EditableTypes.IMAGE,
    name: name,
    id: uuidv4(),
    inputs: {
        value: {
            type: FormInputTypes.IMAGE,
            value,
            isCroppable
        }
    }
});

export const getDefaultButton = (name, value, size, color, backgroundColor = null, borderWidth = null, borderColor = null, borderRadius = null, backgroundImage, paddingVertical = 10, paddingHorizontal = 20, textTransform = 'initial') => ({
    type: EditableTypes.SHORT_TEXT,
    name,
    id: uuidv4(),
    key: 'button',
    inputs: {
        value: {
            type: FormInputTypes.TEXT,
            value
        },
        size: {
            type: FormInputTypes.SLIDER,
            value: size,
            params: {max: 100, min: 8, step: 2},
            label: "Taille du texte",
        },
        paddingVertical: {
            type: FormInputTypes.SLIDER,
            value: paddingVertical,
            params: {max: 50, min: 2, step: 2},
            label: "Padding vertical",
        },
        paddingHorizontal: {
            type: FormInputTypes.SLIDER,
            value: paddingHorizontal,
            params: {max: 50, min: 2, step: 2},
            label: "Padding horizontal",
        },
        textTransform: {
            type: FormInputTypes.SELECT,
            label: "Transformation du texte",
            value: textTransform,
            params: [{label: 'Initial', value: 'initial'}, {label: 'Tout Majuscule', value: 'uppercase'}, {label: 'Tout Minuscule', value: 'lowercase'}]
        },
        color: {
            type: FormInputTypes.COLOR,
            value: color,
            label: "Couleur du texte"
        },
        backgroundColor: {
            type: FormInputTypes.COLOR,
            value: backgroundColor,
            label: "Couleur de fond"
        },
        backgroundImage: {
            label: 'Image de fond',
            type: FormInputTypes.IMAGE,
            value: backgroundImage
        },
        border: {
            type: FormInputTypes.GROUP,
            label: "Border",
            inputs: {
                borderColor: {
                    type: FormInputTypes.COLOR,
                    value: borderColor,
                    label: "Couleur de la bordure"
                },
                borderWidth: {
                    type: FormInputTypes.SLIDER,
                    value: borderWidth,
                    label: "Largeur de la bordure :",
                    params: {max: 5, min: 0, step: 1}
                },
                borderRadius: {
                    type: FormInputTypes.SLIDER,
                    value: borderRadius,
                    label: "Arrondi des angles :",
                    params: {max: 50, min: 0, step: 1}
                }
            }
        },
    },
});

export const getDefaultLink = (name, value, link, size, color, backgroundColor, backgroundImage, borderWidth = null, borderColor = null, borderRadius = null) => ({
    type: EditableTypes.SHORT_TEXT,
    name,
    id: uuidv4(),
    inputs: {
        value: {
            type: FormInputTypes.TEXT,
            value
        },
        link:{
            type: FormInputTypes.LINK,
            link
        },
        size: {
            type: FormInputTypes.SLIDER,
            value: size,
            params: {max: 100, min: 8, step: 2},
            label: "Taille du texte",
        },
        color: {
            type: FormInputTypes.COLOR,
            value: color,
            label: "Couleur du texte"
        },
        backgroundColor: {
            type: FormInputTypes.COLOR,
            value: backgroundColor,
            label: "Couleur de fond"
        },
        backgroundImage: {
            label: 'Image de fond',
            type: FormInputTypes.IMAGE,
            value: backgroundImage
        },
        border: {
            type: FormInputTypes.GROUP,
            label: "Border",
            inputs: {
                borderColor: {
                    type: FormInputTypes.COLOR,
                    value: borderColor,
                    label: "Couleur de la bordure"
                },
                borderWidth: {
                    type: FormInputTypes.SLIDER,
                    value: borderWidth,
                    label: "Largeur de la bordure :",
                    params: {max: 5, min: 0, step: 1}
                },
                borderRadius: {
                    type: FormInputTypes.SLIDER,
                    value: borderRadius,
                    label: "Arrondi des angles :",
                    params: {max: 50, min: 0, step: 1}
                }
            }
        },
    },
});

export const getDefaultRules = (
    img = null, 
    text = "Règle"
) => ({
    blocks: {
        image: {
            type: EditableTypes.IMAGE,
            name: "Image",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: img
                }
            }
        },
        text: getDefaultTextarea("Texte", text, 17, "#53839D", 'rgba(0,0,0,0)','initial', null, ),
    }
});

export const getDefaultRulesInlineFlex = () => ({
    inputs:{
        maxWidth: {
            type: FormInputTypes.SLIDER,
            value: 1080,
            label: "Taille maximale (px)",
            params: {max: 1920, min: 360, step: 10},
        },
        width: {
            type: FormInputTypes.SLIDER,
            value: 90,
            label: "Taille (%)",
            params: {max: 100, min: 20, step: 1},
        },
    },
    blocks: {
        number: getDefaultTextarea("Numéro", "//n", true, "#53839D", 'rgba(0,0,0,0)', 'initial', 'initial', 30),
        title: getDefaultTextarea("Titre", "Titre", true, "#53839D", 'rgba(0,0,0,0)', 'initial', 'initial', 20),
        descr: getDefaultTextarea("Description", "Description", true, "#53839D", 'rgba(0,0,0,0)', 'initial', 'initial', 14)
    }
});


export const getDefaultRulesBF = (
    img = null, 
    text = "Règle"
) => ({
    blocks: {
        text: getDefaultTextarea("Texte", text, 17, "#53839D", 'rgba(0,0,0,0)','initial', null, )
    }
});

export const getDefaultDotationsBF = (
    img = null, 
    text = "Lots",
) => ({
    blocks: {
        logo: {
            type: EditableTypes.IMAGE,
            name: "Logo",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: img
                }
            }
        },
        image: {
            type: EditableTypes.IMAGE,
            name: "Image",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: img
                }
            }
        },
        text: getDefaultText("Texte", text, 17, "#53839D"),
    },
});


export const getDefaultDotations = (
    img = null, 
    text = "Lots",
) => ({
    blocks: {
        image: {
            type: EditableTypes.IMAGE,
            name: "Image",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: img
                }
            }
        },
        text: getDefaultText("Texte", text, 17, "#53839D"),
    },
});

export const getDefaultDotationsMoreDetails = (
    img = null, 
    text = "Lots",
) => ({
    blocks: {
        blockCustom: {
            name: "Personnalisation du bloc",
            inputs: {
                backgroundColor: {
                    label: 'Couleur de fond',
                    type: FormInputTypes.COLOR,
                    value: '#DEEDF5'
                },
                backgroundImage: {
                    label: 'Image de fond',
                    type: FormInputTypes.IMAGE,
                    value: null
                },
                border: {
                    type: FormInputTypes.GROUP,
                    label: "Border",
                    inputs: {
                        borderColor: {
                            type: FormInputTypes.COLOR,
                            value: '#000',
                            label: "Couleur de la bordure"
                        },
                        borderWidth: {
                            type: FormInputTypes.SLIDER,
                            value: 0,
                            label: "Largeur de la bordure :",
                            params: {max: 5, min: 0, step: 1}
                        },
                        borderRadius: {
                            type: FormInputTypes.SLIDER,
                            value: 0,
                            label: "Arrondi des angles :",
                            params: {max: 50, min: 0, step: 1}
                        }
                    }
                },
            },
        },
        image: {
            type: EditableTypes.IMAGE,
            name: "Image",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: img
                }
            }
        },

        title: getDefaultText("Titre dotation", 'Titre', 40, "#53839D"),
        subtitle: getDefaultText("Sous-titre dotation", 'Sous-titre', 25, "#53839D"),
        text: getDefaultText("Texte", text, 15, "#53839D"),
        
    },
});

export const getDefaultSocial = (
    img = null, 
    link = null
) => ({
    blocks: {
        image: {
            type: EditableTypes.IMAGE,
            name: "Image",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: img
                }
            }
        },
        link: {
            type: EditableTypes.SHORT_TEXT,
            name: 'Lien du réseaux social',
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.TEXT,
                    value: null,
                },
            },
        }
    }
});


export const getOfferStats = (
    preValue = null, 
    value = null,
    description = null,
) => ({
    blocks: {
        value: {
            type: EditableTypes.SHORT_TEXT,
            name: 'Data',
            id: uuidv4(),
            inputs: {
                preValue: {
                    label: 'Indication sur la valeur',
                    type: FormInputTypes.TEXT,
                    value: preValue,
                },
                value: {
                    label: 'Valeur',
                    type: FormInputTypes.TEXT,
                    value: value,
                },
                description: {
                    label: 'Description',
                    type: FormInputTypes.TEXT,
                    value: description,    
                }
            },
        },
    }
});


export const getOfferPole = () => ({
    blocks: {
        title: {
            type: EditableTypes.SHORT_TEXT,
            name: 'Titre',
            id: uuidv4(),
            inputs: {
                value: {
                    label: 'Valeur',
                    type: FormInputTypes.TEXT,
                    value: 'Nom du pôle',
                },
            },
        },
        leadersBlocks: {
            id: uuidv4(),
            title: "Leader",
            name: "Leader",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.OFFER_TRIPLE_BLOCK_LIST_LEADER,
            limit: 3,
            config: [
                getOfferLeader(),
            ]
        },
        moreInfo: {
            type: EditableTypes.SHORT_TEXT,
            name: "Plus d'informations",
            id: uuidv4(),
            inputs: {
                value: {
                    label: "Valeur",
                    type: FormInputTypes.TEXT,
                    value: null,
                },
            },
        },
    }
});

export const getOfferLeader = () => ({
    blocks: {
        leader: {
            type: EditableTypes.SHORT_TEXT,
            name: 'Information leader',
            id: uuidv4(),
            inputs: {
                name: {
                    label: 'Prénom Nom',
                    type: FormInputTypes.TEXT,
                    value: 'Prénom Nom',
                },
                poste: {
                    label: 'Poste occupé',
                    type: FormInputTypes.TEXT,
                    value: 'Poste occupé',
                },
                image: {
                    label: 'Photo',
                    type: FormInputTypes.IMAGE,
                    value: null,
                },
            },
        },
    }
});


export const getOfferInformations = () => ({
    blocks: {
        info: {
            type: EditableTypes.SHORT_TEXT,
            name: 'Information leader',
            id: uuidv4(),
            inputs: {
                image: {
                    label: 'Image',
                    type: FormInputTypes.IMAGE,
                    value: null,
                    isCroppable: true
                },
                title: {
                    label: 'Titre',
                    type: FormInputTypes.TEXT,
                    value: 'Titre',
                },
                colorTitle: {
                    label: 'Couleur du titre',
                    type: FormInputTypes.COLOR,
                    value: '#000',
                },
                description: {
                    label: 'Description',
                    type: FormInputTypes.TEXTAREA,
                    value: 'Description',
                },
            },
        },
    }
});

export const getOfferListText = (text) => ({
    blocks: {
        info: {
            type: EditableTypes.SHORT_TEXT,
            name: 'Liste texte',
            id: uuidv4(),
            inputs: {
                value: {
                    label: 'Contenu de la liste',
                    type: FormInputTypes.WYSIWYG,
                    value: text,
                }
            }
        }
    }
});

export const getOfferListLogos = () => ({
    blocks: {
        info: {
            type: EditableTypes.SHORT_TEXT,
            name: 'Information leader',
            id: uuidv4(),
            inputs: {
                logo: {
                    label: 'Logo',
                    type: FormInputTypes.IMAGE,
                    value: null,
                },
            },
        },
    }
});