import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import styled from 'styled-components';
import colors from '../../../../../config/theme/colors';
import TabContainer from './TabContainer';

const TabsCustom = styled(Tabs)`
  // margin-bottom: 16px;
  max-width: 87.5%;
  button{
    text-transform: initial;
    & > span:first-child{
      flex-direction: row;
      svg{
      // margin-left:15px;  
      margin-bottom:0px!important;
      // display:block;
      }
      // flex-direction: row-reverse;
      // align-items:center;
    }
  }
  .MuiSvgIcon-fontSizeSmall{
    font-size: 30px;
    fill: ${colors.blue.lighter.hue300};
  }
  &> div > span{
    height: 3px;
    background-color: ${colors.blue.lighter.hue300};
    &.MuiTouchRipple-root{
      background-color: transparent;

    }
  }
`;

const TabCustom = styled(Tab)`
  min-width: inherit;
  padding: 6px 18px;
`;

const NavLevel1 = styled.div`
  margin-bottom: 25px;
  -webkit-box-flex: 0;
  flex: 0 1 auto;
  width: 20%;
  padding-right: 20px;
`;

const NavLevel2 = styled.div`
  margin-left: 8px
`;

const TextNav1 = styled.div`
  cursor: pointer;
  font-weight: bold;
  &:hover{
    color: ${colors.blue.lighter.hue300}
  }
`;
const TextNav2 = styled.div`
  cursor: pointer;
  &:hover{
    color: ${colors.blue.lighter.hue300}
  }
`;

const DropdownContainer = styled.div`
  padding-top: 15px;
  margin-top: -15px;
  // box-shadow: 0 2038px 0 2038px rgba(0,0,0,.8);
  position: absolute; 
  z-index: 80;
  width: 100%;
  top: 75px;
  // height: 320px!important;
`;
const WrapperContent = styled.div`
  background: white;
  padding: 10px 20px;
  padding-top: 32px;
  width: 100%;
  height: 100%;
  box-shadow: 0px 9px 22px #D4D9DC;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  max-height: 680px !important;
  overflow: auto;
`
const SpanColor = styled.span`
  color: ${colors.blue.lighter.hue300};
`

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    boxShadow: 'inherit',
    background: 'white',
    position: 'relative'
  },
});

export default function TabCategories(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0); 
  const [isOpen, setIsOpen] = React.useState(false); 
  const [categorieOpened, setCategorieOpened] = React.useState(null); 
  const [indexMain, setIndexMain] = React.useState(null); 

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleBreadCrumb = (mainCat, subCat, subCat2) => {
    let newArray = []
    if(mainCat)
      newArray.push(mainCat)
    if(subCat)
      newArray.push(subCat)
    if(subCat2)
      newArray.push(subCat2)

    return newArray
  }

  const handleOpen = (categorie, index) => {
    setIsOpen(true)
    if (categorie){
      setCategorieOpened(categorie)
      setIndexMain(index)
    }
  }

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Paper className={classes.root} style={props.style ? props.style : null}>
      <TabsCustom
        value={value}
        // onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant         = "scrollable"
        scrollButtons   = "auto"
        // activeKey
      >
        <TabCustom onClick={(event) => { props.handleChangeTab(event, 0, false, 'Tout voir' );handleChange(null, 0)}} label='Tout voir' />
        {
          props.categories ?
            props.categories.map((categorie, index) => {
              return(
                <TabContainer 
                  key={`TabCustom-${categorie.libelle}-${index}`} 
                  onClick={(event) => props.handleChangeTab(event, index + 1, categorie.id, categorie, handleBreadCrumb(categorie) )} 
                  categorie={categorie} 
                  categories={props.categories} 
                  currentLang={props.currentLang} 
                  handleChangeTab={props.handleChangeTab} 
                  level={0}
                  handleChange={handleChange}
                  index={index}
                  setCategorieOpened={setCategorieOpened}
                  handleOpen={handleOpen}
                  handleClose={handleClose}
                  handleBreadCrumb={handleBreadCrumb}
                  typeContent={props.typeContent}
                  isMedia={props.isMedia}
                  isDiffusion={props.isDiffusion}
                />
              )
            }) 
          : ''
        }
      </TabsCustom>
      <DropdownContainer style={{display: isOpen ? 'flex' : 'none'}} 
        onMouseEnter = { () => handleOpen() }
        onMouseLeave = { () => handleClose() }
      >
        <WrapperContent>
          {
            props.isDiffusion ? 
              categorieOpened?.assetTypes.edges.map((sub, i) => {
                  let subCatName = sub.categoryDatas?.filter(e => e.attribute.identifier === "category_name");
                  subCatName = subCatName?.find(e => e.locale.code === props.currentLang)?.value
                  return(
                      <NavLevel1 key={`NavLevel-${sub.id}-${i}`}>
                        <TextNav1 onClick={(event) => {props.handleChangeTab(event, indexMain, sub.node.id, sub, handleBreadCrumb(categorieOpened, sub)); handleChange(null, indexMain)}}>
                          <>{subCatName ? subCatName : sub.node.libelle}</>
                        </TextNav1>
                      </NavLevel1>
                  )
              })
            :
            categorieOpened?.childrens.map((sub, i) => {
                let subCatName = sub.categoryDatas?.filter(e => e.attribute.identifier === "category_name");
                subCatName = subCatName?.find(e => e.locale.code === props.currentLang)?.value
                return(
                    <NavLevel1 key={`NavLevel-${sub.id}-${i}`}>
                      <TextNav1 onClick={(event) => {props.handleChangeTab(event, indexMain, sub.id, sub, handleBreadCrumb(categorieOpened, sub)); handleChange(null, indexMain)}}>
                        <>{subCatName ? subCatName : sub.libelle} <SpanColor>({props.typeContent ? sub.contentsTotal : props.isMedia ? sub.mediasTotal : sub.productsTotal})</SpanColor></>
                      </TextNav1>
                      {
                        sub.childrens.length > 0 ?
                          <NavLevel2 >
                            {
                              sub.childrens.map((sub2, i2) => {
                                let sub2CatName = sub2.categoryDatas?.filter(e => e.attribute.identifier === "category_name");
                                sub2CatName = sub2CatName?.find(e => e.locale.code === props.currentLang)?.value
                                return(
                                  <TextNav2
                                    key={`TextNav2-${sub2.id}-${i2}`} 
                                    onClick={(event) => {props.handleChangeTab(event, indexMain, sub2.id, sub2, handleBreadCrumb(categorieOpened, sub, sub2) ); handleChange(null, indexMain)}}
                                  >
                                    <>{sub2CatName ? sub2CatName : sub2.libelle} <SpanColor>({props.typeContent ? sub2.contentsTotal : props.isMedia ? sub2.mediasTotal : sub2.productsTotal})</SpanColor></>
                                  </TextNav2>
                                )
                              })
                            }
                          </NavLevel2>
                        : null
                      }
                    </NavLevel1>
                )
            })
          }
        </WrapperContent>
      </DropdownContainer>
    </Paper>
  );
}